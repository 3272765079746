// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useState } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import Button, { ButtonVariant } from '../../common/components/Button';
import ConfirmModal from '../../common/components/ConfirmModal';
import DataTable, { DataTableHeader, DataTableRow } from '../../common/components/DataTable';
import TablePlaceholder from '../../common/components/TablePlaceholder';
import { Sort } from '../../common/enums';
import HealthCheckService from '../../common/services/animalHealthCheckService';
import { IconDelete, IconEdit, IconManage } from '../../common/utilities';
import Logger from '../../logger/logger';
import { QueryKey } from '../../shared/enums';
import HealthCheckModal from './HealthCheckModal';

interface Props {
  filters?: Record<string, string | undefined>;

  /**
   * Number of events to show.
   */
  initialLimit?: number;

  /**
   * Number of rows to skip.
   */
  initialOffset?: number;

  /**
   * Table sort direction.
   */
  initialSortDirection?: Sort;

  /**
   * Table sort field.
   */
  initialSortField?: string;
}

/**
 * Table of diagnosis regimes.
 */
const HealthCheckTable = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const logger = useInjection<Logger>(TYPES.logger);
  const healthCheckService = useInjection<HealthCheckService>(TYPES.animalHealthCheckService);

  const history = useHistory();

  const [deleteConfirmModalErrorMessage, setDeleteConfirmModalErrorMessage] = useState<string>('');
  const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState<boolean>(false);
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [editId, setEditId] = useState<number>(0);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();

  const [limit, setLimit] = useState<number>(props.initialLimit ?? 25);
  const [offset, setOffset] = useState<number>(props.initialOffset ?? 0);
  const [sortField, setSortField] = useState<string>(props.initialSortField ?? 'name');
  const [sortDirection, setSortDirection] = useState<Sort>(
    props.initialSortDirection ?? Sort.Ascending,
  );

  const cleanFilters: Record<string, string> = {};

  if (props.filters !== undefined) {
    Object.keys(props.filters).forEach((key) => {
      const propsFilters = props.filters as Record<string, string>;
      cleanFilters[key] = propsFilters[key] ?? '';
    });
  }

  const query = useQuery(
    [QueryKey.AnimalHealthChecks, limit, offset, cleanFilters, sortField, sortDirection],
    () =>
      healthCheckService.list({
        ...cleanFilters,
        ...{
          limit: String(limit),
          offset: String(offset),
          sortField: sortField,
          sortDirection: String(sortDirection),
        },
      }),
    { keepPreviousData: true },
  );

  const queryClient = useQueryClient();

  const deleteItem = async (): Promise<void> => {
    if (!deleteId) {
      return;
    }
    setIsDeleting(true);
    try {
      await healthCheckService.delete(deleteId);
      await queryClient.invalidateQueries(QueryKey.AnimalHealthChecks);
      setDeleteConfirmModalVisible(false);
    } catch (err) {
      logger.error('This item failed to be deleted', err);
      setDeleteConfirmModalErrorMessage('This item could not be deleted.');
    }
    setIsDeleting(false);
  };

  const showEditModal = (id: number): void => {
    setEditModalVisible(true);
    setEditId(id);
  };

  const showDeleteConfirmModal = (id: number): void => {
    setDeleteConfirmModalVisible(true);
    setDeleteId(id);
  };

  if (query.isLoading) {
    return <TablePlaceholder />;
  }

  if (query.isError) {
    return <AlertError message={t('common|animalRetrievalError')} />;
  }

  const headers: DataTableHeader[] = [
    {
      name: 'name',
      label: t('Name'),
      infoTooltip: t('The name of this health check'),
      classNameMobile: 'col-12',
    },
    {
      name: 'type',
      label: t('Type'),
      hideMobile: true,
    },
    { name: 'actions', sortable: false, label: 'Actions', hideMobile: true },
  ];

  const data: DataTableRow[] = !query.data
    ? []
    : query.data.map((row) => ({
        id: String(row.id),
        name: row.name,
        type: row.type,
        actions: (
          <>
            <div onClick={(e): void => e.stopPropagation()}>
              <Dropdown as={ButtonGroup}>
                <Button
                  onClick={(): void => showEditModal(row.id)}
                  size="sm"
                  variant="outline-primary"
                >
                  <IconManage className={'me-1'} /> {t('Manage')}
                </Button>
                <Dropdown.Toggle size="sm" split variant="outline-primary" />
                <Dropdown.Menu align="end">
                  <Dropdown.Item
                    disabled={row.isDefault}
                    onClick={(): void => showEditModal(row.id)}
                  >
                    <IconEdit className={'me-1'} /> {t('Edit')}
                  </Dropdown.Item>
                  <Dropdown.Item
                    disabled={row.isDefault}
                    onClick={(): void => showDeleteConfirmModal(row.id)}
                  >
                    <IconDelete className={'me-1'} />
                    {t('Delete')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </>
        ),
      }));
  return (
    <>
      <DataTable
        className={'mt-3'}
        data={data}
        headers={headers}
        isLoading={query.isLoading}
        isPreviousData={query.isPreviousData}
        limit={limit}
        messageNoData={t('healthCheckTable|noPenMessage')}
        offset={offset}
        onLimitChange={(newLimit): void => setLimit(newLimit)}
        onOffsetChange={(newOffset): void => setOffset(newOffset)}
        onRowClick={(row): void => history.push(`/diagnosis-regimes/${row.id}`)}
        onSortDirectionChange={(newSortDirection): void => setSortDirection(newSortDirection)}
        onSortFieldChange={(newSortField): void => setSortField(newSortField)}
        sortDirection={sortDirection}
        sortField={sortField}
      />
      {editModalVisible && (
        <HealthCheckModal editId={editId} onClose={(): void => setEditModalVisible(false)} />
      )}
      <ConfirmModal
        busy={isDeleting}
        cancelLabel={t('Cancel')}
        cancelOnClick={(): void => setDeleteConfirmModalVisible(false)}
        errorMessage={deleteConfirmModalErrorMessage}
        okLabel={t(`Yes, delete this {{value}}`, { value: 'health check' })}
        okOnClick={deleteItem}
        okVariant={ButtonVariant.Danger}
        title={t(`Delete this {{value}}`, { value: 'health check' })}
        visible={deleteConfirmModalVisible}
      >
        {t('Are you sure you want to delete this {{value}}?', { value: 'regime' })}
      </ConfirmModal>
    </>
  );
};

export default HealthCheckTable;
