// Copyright © 2024 CATTLEytics Inc.

import { useContext, useMemo } from 'react';
import { Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import Button from '../../common/components/Button';
import ButtonCreate from '../../common/components/ButtonCreate';
import AuthContext from '../../common/store/auth-context';
import { isSiteAdminOrAbove } from '../../common/utilities';
import { api } from '../../common/utilities/api';
import { ApiResourceV1, HttpMethod, QueryKey, Shift } from '../../shared';

type Props = {
  onManageShifts: () => void;
  onSchedule: () => void;
};

export function ShiftScheduleButton({ onManageShifts, onSchedule }: Props): JSX.Element {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const { data } = useQuery<Shift[]>(
    [QueryKey.Shifts],
    () => api(HttpMethod.Get, ApiResourceV1.Shifts),
    {},
  );

  const hasShifts = useMemo(() => {
    return data && data.length > 0;
  }, [data]);

  if (!isSiteAdminOrAbove(auth)) return <></>;

  return (
    <Stack className="align-items-end" direction="horizontal" gap={2}>
      {hasShifts && (
        <ButtonCreate
          label={t('Schedule User')}
          onClick={onSchedule}
          size="sm"
          style={{ margin: 0 }}
        />
      )}
      <Button onClick={onManageShifts} size="sm">
        {t('Manage Shifts')}
      </Button>
    </Stack>
  );
}
