// Copyright © 2023 CATTLEytics Inc.

import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { ApiResourceV1, NextId, QueryKey } from '../../shared';
import { api, incrementLastNumberInString } from '../utilities';

interface Return {
  incrementNextPrimaryTag: () => void;
  nextPrimaryTag: string;
}

interface Options {
  queryEnabled?: boolean;
}

/**
 * Hook to get the next Primary Tag value from the api call.
 */
export const useNextPrimaryTag = ({ queryEnabled = true }: Options = {}): Return => {
  const [nextPrimaryTag, setNextPrimaryTag] = useState<string>('1');

  const animalReferenceNextIdQuery = useQuery<NextId | undefined>(
    [QueryKey.AnimalReferences, 'nextId'],
    () => api('GET', `${ApiResourceV1.AnimalReferences}/next-id`),
    { enabled: queryEnabled },
  );

  useEffect(() => {
    const data = animalReferenceNextIdQuery.data;
    console.debug(`next-id`, data);
    if (data) {
      setNextPrimaryTag(data.nextId);
    }
  }, [animalReferenceNextIdQuery.data, setNextPrimaryTag]);

  const incrementNextPrimaryTag = useCallback(
    () => setNextPrimaryTag((prev) => incrementLastNumberInString(prev)),
    [setNextPrimaryTag],
  );

  return { nextPrimaryTag, incrementNextPrimaryTag } as const;
};
