// Copyright © 2024 CATTLEytics Inc.

import { useContext, useMemo } from 'react';
import { ModalProps, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ButtonModalCancel from '../common/components/ButtonModalCancel';
import Modal from '../common/components/Modal';
import AuthContext from '../common/store/auth-context';
import { isSiteAdminOrAbove } from '../common/utilities';
import { UserSchedule } from '../shared';
import { ManageSchedulesForm } from './ManageSchedulesForm';
import { ManageSchedulesUserForm } from './ManageSchedulesUserForm';

type Props = Pick<ModalProps, 'onClose'> & {
  schedules?: UserSchedule[];
};

export function ManageSchedulesModal({ onClose, schedules }: Props): JSX.Element {
  const { t } = useTranslation();
  const title = useMemo(() => t('manageSchedulesModal|title'), [t]);
  const auth = useContext(AuthContext);
  const isAdmin = isSiteAdminOrAbove(auth);

  if (!schedules?.length) {
    return <></>;
  }

  const mySchedules = schedules.filter((s) => s.userId === auth.userId);

  return (
    <Modal
      footer={
        <>
          <ButtonModalCancel label={t('manageSchedulesModal|closeLabel')} onClick={onClose} />
        </>
      }
      onClose={onClose}
      size={'lg'}
      title={title}
      visible={true}
    >
      {isAdmin && mySchedules.length > 0 ? (
        <>
          <Tabs defaultActiveKey="manage">
            <Tab eventKey="manage" title="Manage">
              <ManageSchedulesForm onNoSchedules={onClose} schedules={schedules} />
            </Tab>
            <Tab eventKey="personal" title="Personal">
              <ManageSchedulesUserForm schedules={mySchedules} />
            </Tab>
          </Tabs>
        </>
      ) : isAdmin ? (
        <ManageSchedulesForm onNoSchedules={onClose} schedules={schedules} />
      ) : (
        <ManageSchedulesUserForm schedules={mySchedules} />
      )}
    </Modal>
  );
}
