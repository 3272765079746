// Copyright © 2023 CATTLEytics Inc.

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import React from 'react';

import Spinner from '../common/components/Spinner';
import { getEnv } from '../common/utilities';
import { CheckoutForm } from './CheckoutForm';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePublishableKey = getEnv('STRIPE_API_PUBLISHABLE_KEY');
if (!stripePublishableKey) {
  throw new Error('Stripe publishable key is not set.');
}
//const testMode = stripePublishableKey.slice(3, 7) === 'test';
const stripePromise = loadStripe(stripePublishableKey);

interface Props {
  promoCode?: string;
  stripeClientSecret?: string;
  stripeSubscriptionId?: string;
}

/**
 * Handles submission of credit card information to Stripe.
 */
export const Payment = (props: Props): JSX.Element => {
  const options: StripeElementsOptions = {
    clientSecret: props.stripeClientSecret,
    appearance: { theme: 'stripe' },
  };

  return (
    <>
      {!props.stripeClientSecret && (
        <div className={'d-flex justify-content-center'}>
          <Spinner />
        </div>
      )}
      {props.stripeClientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm promoCode={props.promoCode} />
        </Elements>
      )}
    </>
  );
};
