// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React from 'react';
import { Placeholder } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { TYPES } from '../../../types';
import Modal from '../../common/components/Modal';
import DiagnosisRegime from '../../common/entities/diagnosisRegime';
import DiagnosisRegimeDetailService from '../../common/services/diagnosisRegimeDetailService';
import DiagnosisRegimeService from '../../common/services/diagnosisRegimeService';
import { DiagnosisRegimeDetail } from '../../shared';
import { QueryKey } from '../../shared/enums';

/**
 * Component's input properties.
 */
interface Props extends WithTranslation {
  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Diagnosis regime we want to preview.
   */
  diagnosisRegimeId: number;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;
}

/**
 * Modal to display a preview of a diagnosis regime.
 */
const DiagnosisRegimePreviewModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const diagnosisRegimeService = useInjection<DiagnosisRegimeService>(TYPES.diagnosisRegimeService);
  const diagnosisRegimeDetailService = useInjection<DiagnosisRegimeDetailService>(
    TYPES.diagnosisRegimeDetailService,
  );

  const queryDr = useQuery<DiagnosisRegime | undefined>(
    [QueryKey.DiagnosisRegimes, 'get', props.diagnosisRegimeId],
    () => diagnosisRegimeService.get(props.diagnosisRegimeId ?? -1),
  );

  const queryDrd = useQuery<DiagnosisRegimeDetail[]>(
    [QueryKey.DiagnosisRegimeDetails, props.diagnosisRegimeId],
    () => diagnosisRegimeDetailService.list({ diagnosisRegimeId: String(props.diagnosisRegimeId) }),
  );

  const placeholder = (
    <Placeholder animation={'glow'}>
      <Placeholder xs={6} />
      <Placeholder className={'mb-5'} size={'lg'} xs={12} />
      <Placeholder xs={6} />
      <Placeholder className={'mb-5'} size={'lg'} xs={12} />
    </Placeholder>
  );

  const loading = queryDr.isFetching || queryDrd.isFetching;

  return (
    <Modal
      fullscreen={'md-down'}
      onClose={props.onClose}
      size={'lg'}
      title={`Preview Diagnosis Regime`}
      visible={true}
    >
      {!loading ? (
        <p>
          <strong>{queryDr.data?.name}</strong>
        </p>
      ) : (
        <p>
          <Placeholder xs={6} />
        </p>
      )}

      {!loading &&
        queryDrd.data?.map((detail) => (
          <p key={detail.id}>
            Step {detail.step}: {detail.description}
          </p>
        ))}
      {loading && <>{placeholder}</>}
    </Modal>
  );
};

export default withTranslation()(DiagnosisRegimePreviewModal);
