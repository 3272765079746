// Copyright © 2023 CATTLEytics Inc.

import { useParams } from 'react-router-dom';

import EventsCalendarTableToggle from './EventsCalendarTableToggle';

export interface RouteParams {
  /**
   * Tab key to make active on render.
   */
  tabKey: string;
  year: string;
}

const EventsTabs = (): JSX.Element => {
  const { tabKey, year } = useParams<RouteParams>();

  return <EventsCalendarTableToggle tabKey={tabKey} yearParam={year} />;
};

export default EventsTabs;
