// Copyright © 2023 CATTLEytics Inc.
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { defaultColor } from './constants';
import { TimelineNodeTooltip } from './TimelineNodeTooltip';
import { useTimeline } from './TimelineProvider';
import { EntityBase, TimelineItem } from './types';
import { useTimelineNodeEvents } from './use-timeline-node-events';

type Props<Entity extends EntityBase> = {
  item: TimelineItem<Entity>;
};

function TimelineNode<Entity extends EntityBase>({ item }: Props<Entity>): JSX.Element {
  const { t } = useTranslation();
  const { nodeDiameter, selectedEntity } = useTimeline();
  const anchor = useRef(null);
  const numberOfItems = useMemo(() => item.dateEntities.length, [item]);
  const multiple = useMemo(() => numberOfItems > 1, [numberOfItems]);
  const color = useMemo(
    () => (multiple ? defaultColor : item.dateEntities[0].color ?? defaultColor),
    [item, multiple],
  );

  const events = useTimelineNodeEvents(item.dateEntities[0]);
  const label = useMemo(() => {
    const name = item.dateEntities[0].label;
    return multiple
      ? t('{{name}} (+{{count}})', { name, count: item.dateEntities.length - 1 })
      : name;
  }, [multiple, item, t]);

  return (
    <div
      className="position-absolute d-flex flex-row align-items-center"
      {...events}
      style={{
        top: item.point,
        left: 'calc(20% - 8px)',
        right: 0,
        cursor: 'pointer',
      }}
    >
      {item.daysBetweenPoint && (
        <div
          className="position-absolute pe-none"
          style={{ fontSize: '0.8rem', top: item.daysBetweenPoint, left: '15%' }}
        >
          {t('{{days}} Days', { days: item.daysBetweenLastEvent })}
        </div>
      )}
      <div
        className="d-flex justify-content-center align-items-center"
        ref={anchor}
        style={{
          height: nodeDiameter,
          width: nodeDiameter,
          borderRadius: '50%',
          backgroundColor: 'var(--bs-body-bg)',
          border: `2px solid ${color}`,
          fontSize: '0.75rem',
        }}
      >
        {multiple ? numberOfItems : ''}
      </div>
      <div style={{ border: `1px solid ${color}`, width: '20%' }} />
      <div className="flex-grow-1 overflow-hidden text-nowrap text-truncate">{label}</div>
      <TimelineNodeTooltip
        entityItems={item.dateEntities}
        selectedEntityItem={selectedEntity}
        target={anchor.current}
      />
    </div>
  );
}

export default TimelineNode;
