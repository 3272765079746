// Copyright © 2023 CATTLEytics Inc.
import TimelineContainer from './TimelineContainer';
import { useFormattedTimelineDates } from './use-formatted-timeline-dates';
import useGetTimelineItems from './use-get-timeline-items';
import TimelineNode from './VerticalTimelineNode';

const VerticalTimeline = (): JSX.Element => {
  const { endDate, startDate } = useFormattedTimelineDates();
  const { containerRef, data } = useGetTimelineItems();

  return (
    <TimelineContainer>
      <div
        className="position-absolute"
        style={{ border: '4px solid #CCC', left: '20%', top: 1, bottom: 1 }}
      />
      <div className="position-absolute top-0 end-0 pe-2">{startDate}</div>
      <div className="my-4 flex-grow-1 w-100 position-relative " ref={containerRef}>
        {data.map((item, index) => (
          <TimelineNode item={item} key={index} />
        ))}
      </div>
      <div className="position-absolute bottom-0 end-0 pe-2">{endDate}</div>
    </TimelineContainer>
  );
};

export default VerticalTimeline;
