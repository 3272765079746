// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { Col, Placeholder, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

/**
 * Placeholder for loading table data.
 */
const TablePlaceholder = (): JSX.Element => {
  const cols = [...Array(6).keys()];
  return (
    <Placeholder animation="glow">
      <Row>
        {cols.map((num) => (
          <Col key={`head-col-${num}`} md={2}>
            <Placeholder size={'sm'} style={{ width: '100%' }} />
          </Col>
        ))}
      </Row>
      <hr className={'m-0'} />
      {[...Array(5).keys()].map((num) => (
        <Row key={`row-${num}`}>
          {cols.map((num) => (
            <Col key={`col-${num}`} md={2}>
              <Placeholder size={'xs'} style={{ width: `100%` }} />
            </Col>
          ))}
        </Row>
      ))}
    </Placeholder>
  );
};

export default withTranslation()(TablePlaceholder);
