// Copyright © 2023 CATTLEytics Inc.

import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { useQuery } from 'react-query';

import Spinner from '../../common/components/Spinner';
import { api } from '../../common/utilities';
import { Mastitis, QueryKey } from '../../shared';

const MastitisChart = (): JSX.Element => {
  const [data, setData] = useState<ChartData<'line'>>();

  const query = useQuery<Required<Mastitis>[]>([QueryKey.Mastitis], () =>
    api<Required<Mastitis>[]>('GET', '/v1/report-mastitis'),
  );

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineController,
    LineElement,
    Title,
    Tooltip,
    Legend,
  );

  interface YearMonthData {
    group1HighFreshInfections: number | null;
    group1NewInfections: number | null;
    group2HighFreshInfections: number | null;
    group2NewInfections: number | null;
    labels: string[];
  }

  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Infections',
        font: {
          size: 18,
          weight: 'bold',
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Year - Month',
          font: {
            size: 16,
          },
        },
      },
      y: {
        title: {
          display: true,
          text: 'Percentage',
          font: {
            size: 16,
          },
        },
        min: 0,
      },
    },
  };

  useEffect(() => {
    if (!query.data) {
      return;
    }

    const dataByYearMonth: Record<string, YearMonthData> = {};

    // Group data by year_month
    for (const row of query.data) {
      const { yearMonth, lactationGroup, pctNewInf, pctHighFreshInf } = row;

      //if there's no entry in dataByYearMonth[year_month] for that year_month create new one and set everything to null.
      if (!dataByYearMonth[yearMonth]) {
        dataByYearMonth[yearMonth] = {
          labels: [yearMonth],
          group1NewInfections: null,
          group1HighFreshInfections: null,
          group2NewInfections: null,
          group2HighFreshInfections: null,
        };
      }

      if (lactationGroup === '1') {
        dataByYearMonth[yearMonth].group1NewInfections = pctNewInf;
        dataByYearMonth[yearMonth].group1HighFreshInfections = pctHighFreshInf;
      } else if (lactationGroup === '1+') {
        dataByYearMonth[yearMonth].group2NewInfections = pctNewInf;
        dataByYearMonth[yearMonth].group2HighFreshInfections = pctHighFreshInf;
      }
    }

    const labels: string[] = [];
    const group1NewInfections: number[] = [];
    const group1HighFreshInfections: number[] = [];
    const group2NewInfections: number[] = [];
    const group2HighFreshInfections: number[] = [];

    // set data based on lactation group
    Object.keys(dataByYearMonth).forEach((year_month) => {
      labels.push(year_month);

      const group1NewInfection = dataByYearMonth[year_month].group1NewInfections;
      if (group1NewInfection !== null && group1NewInfection !== undefined) {
        group1NewInfections.push(group1NewInfection);
      } else {
        group1NewInfections.push(0);
      }
      const group1HighFreshInfection = dataByYearMonth[year_month].group1HighFreshInfections;
      if (group1HighFreshInfection !== null && group1HighFreshInfection !== undefined) {
        group1HighFreshInfections.push(group1HighFreshInfection);
      } else {
        group1HighFreshInfections.push(0);
      }

      const group2NewInfection = dataByYearMonth[year_month].group2NewInfections;
      if (group2NewInfection !== null && group2NewInfection !== undefined) {
        group2NewInfections.push(group2NewInfection);
      } else {
        group2NewInfections.push(0);
      }

      const group2HighFreshInfection = dataByYearMonth[year_month].group2HighFreshInfections;
      if (group2HighFreshInfection !== null && group2HighFreshInfection !== undefined) {
        group2HighFreshInfections.push(group2HighFreshInfection);
      } else {
        group2HighFreshInfections.push(0);
      }
    });

    setData({
      labels,
      datasets: [
        {
          label: 'Lactation 1 : New Infections',
          data: group1NewInfections,
          backgroundColor: 'rgb(218,165,32)',
          borderColor: 'rgb(218,165,32)',
          borderWidth: 3.5,
          pointRadius: 1,
        },
        {
          label: 'Lactation 1 : High Fresh Infections',
          data: group1HighFreshInfections,
          backgroundColor: 'rgb(255,215,0)',
          borderColor: 'rgb(255,215,0)',
          borderWidth: 3.5,
          pointRadius: 1,
        },
        {
          label: 'Lactation 1+ : New Infections',
          data: group2NewInfections,
          backgroundColor: 'rgb(0, 160, 0)',
          borderColor: 'rgb(0, 160, 0)',
          borderWidth: 3.5,
          pointRadius: 1,
        },
        {
          label: 'Lactation 1+ : High Fresh Infections',
          data: group2HighFreshInfections,
          backgroundColor: 'rgb(0, 255, 0)',
          borderColor: 'rgb(0, 255, 0)',
          borderWidth: 3.5,
          pointRadius: 1,
        },
      ],
    });
  }, [query.data]);

  return (
    <>
      {!data && <Spinner />}
      {data && <Chart data={data} options={options} type="line" />}
    </>
  );
};

export default MastitisChart;
