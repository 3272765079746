// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { ChangeEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { TYPES } from '../../../types';
import ManageListEditModal from '../../lists/components/ManageListEditModal';
import { ApiResourceV1, QueryKey } from '../../shared';
import AnimalReferenceTypeService from '../services/animalReferenceTypeService';

/**
 * Component input properties.
 */
interface Props {
  // accessibility label
  ariaLabel?: string;

  // additional class names to pass to the component.
  className?: string;

  /**
   * Whether the name and primary tag types should be omitted.
   */
  hideNameTag?: boolean;

  // form element name
  name?: string;

  // input change handler
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;

  // when add modal is closed
  onCloseAddModal?: () => void;
  // whether this field is required
  required?: boolean;
  // whether to display the add modal
  showAddModal?: boolean;

  // input value
  value: string;
}

/**
 * Form select component for animal reference types.
 */
const AnimalReferenceTypeSelect = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const animalReferenceTypeService = useInjection<AnimalReferenceTypeService>(
    TYPES.animalReferenceTypeService,
  );

  const [showAddModal, setShowAddModal] = useState<boolean>(!!props.showAddModal);
  const [previousShowAddModal, setPreviousShowAddModal] = useState<boolean>();

  const query = useQuery(
    [QueryKey.AnimalReferenceTypes],
    () => animalReferenceTypeService.list(),
    {},
  );
  const items = query.data;

  if (!!props.showAddModal !== previousShowAddModal) {
    setShowAddModal(!!props.showAddModal);
    setPreviousShowAddModal(!!props.showAddModal);
  }

  return (
    <>
      <Form.Select
        aria-label={props.ariaLabel ?? 'Animal Reference Type'}
        className={props.className}
        name={props.name ?? 'animalReferenceType'}
        onChange={props.onChange}
        required={props.required}
        value={props.value}
      >
        <option value={''}>{query.isFetching ? 'Loading...' : 'Choose a reference type'}</option>
        {items &&
          items.slice(props.hideNameTag ? 2 : 0).map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
      </Form.Select>
      {props.required && (
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      )}
      {showAddModal && (
        <ManageListEditModal
          apiResource={ApiResourceV1.AnimalReferenceTypes}
          onClose={props.onCloseAddModal ?? ((): void => undefined)}
          queryKey={QueryKey.AnimalReferenceTypes}
          typeLabel={'reference type'}
          typeName={'animalReferenceType'}
        />
      )}
    </>
  );
};

export default AnimalReferenceTypeSelect;
