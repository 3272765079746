// Copyright © 2023 CATTLEytics Inc.

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonCreate from '../../common/components/ButtonCreate';
import Page from '../../common/components/Page';
import NoteModal from './NoteModal';
import NotesList from './NotesList';

/**
 * Routable component to list notes.
 */
const NotesPage = (): JSX.Element => {
  const { t } = useTranslation();

  const [noteModalVisible, setNoteModalVisible] = useState<boolean>(false);

  return (
    <Page
      // @TODO translations
      breadcrumbs={[{ label: 'Dairy Logs', to: '/dairy-logs' }]}
      buttons={
        <ButtonCreate label={t('Create Note')} onClick={(): void => setNoteModalVisible(true)} />
      }
      title="Notes"
    >
      <NotesList />
      {noteModalVisible ? <NoteModal onClose={(): void => setNoteModalVisible(false)} /> : null}
    </Page>
  );
};

export default NotesPage;
