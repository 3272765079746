// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { ChangeEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { TYPES } from '../../../types';
import PenModal from '../../pens/components/PenModal';
import { QueryKey } from '../../shared';
import Pen from '../entities/pen';
import PenService from '../services/penService';

/**
 * Component input properties.
 */
interface Props {
  // accessibility label
  ariaLabel?: string;

  // additional class names to pass to the component.
  className?: string;

  /**
   * Whether this component should be disabled.
   */
  disabled?: boolean;

  /**
   * An array of Pen ID's to filter out of this component
   */
  filterIds?: string[];

  /**
   * Whether the first item of the select should be selected as the default.
   */
  firstItemDefault?: boolean;

  /**
   * Conditions to make the field invalid.
   *
   * @see Set when using yup schema
   */
  isInvalid?: boolean;

  // form element name
  name?: string;

  /**
   * Input change handler.
   * @param event
   */
  onChange?: (value: string) => void;

  /**
   * Select change handler
   * @param event
   */
  onChangeDom?: (event: ChangeEvent<HTMLSelectElement>) => void;

  /**
   * When add modal is closed
   */
  onCloseAddModal?: () => void;

  /**
   * Component should not be able to be modified by the user.
   * This attribute sets the disabled attribute on the component.
   */
  readOnly?: boolean;

  // whether this field is required
  required?: boolean;

  /**
   * Whether to display the add modal
   */
  showAddModal?: boolean;

  // input value
  value: string | number | undefined;
}

/**
 * Form select component for pens.
 */
const PenSelect = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const penService = useInjection<PenService>(TYPES.penService);

  const [showAddModal, setShowAddModal] = useState<boolean>(!!props.showAddModal);
  const [previousShowAddModal, setPreviousShowAddModal] = useState<boolean>();
  const [items, setItems] = useState<Pen[]>([]);

  const query = useQuery<Pen[]>([QueryKey.Pens], () => penService.list({ limit: String(1_000) }), {
    enabled: !props.disabled,
    onSuccess: (data) => {
      if (!data || data.length === 0) {
        return;
      }
      const items = props.filterIds
        ? data.filter((pen) => !(props.filterIds as string[]).includes(String(pen.id)))
        : data;
      setItems(items);
      if (props.firstItemDefault && items.length > 0 && props.onChange) {
        props.onChange(String(items[0].id));
      } else if (props.firstItemDefault && items.length > 0 && props.onChangeDom) {
        props.onChangeDom({
          target: { name: props.name ?? 'penId', value: String(items[0].id) },
        } as ChangeEvent<HTMLSelectElement>);
      }
    },
  });
  const value = props.value ?? undefined;

  if (!!props.showAddModal !== previousShowAddModal) {
    setShowAddModal(!!props.showAddModal);
    setPreviousShowAddModal(!!props.showAddModal);
  }

  //const items = query.data;
  // props.filterIds && query.data
  //   ? query.data.filter((pen) => !(props.filterIds as string[]).includes(String(pen.id)))
  //   : query.data;

  const onChangeCallback = props.onChange
    ? (e: ChangeEvent<HTMLSelectElement>): void => {
        if (props.onChange) {
          return props.onChange(e.target.value);
        }
      }
    : props.onChangeDom;

  return (
    <>
      <Form.Select
        aria-label={props.ariaLabel ?? 'Pen'}
        className={props.className}
        disabled={props.disabled ?? props.readOnly ?? false}
        isInvalid={props.isInvalid}
        name={props.name ?? 'penId'}
        onChange={onChangeCallback}
        required={props.required}
        value={value}
      >
        {props.disabled ? <option value={''}>{t('Choose a pen')}</option> : null}
        {props.firstItemDefault ? (
          <>{query.isFetching ? <option value={''}>{t('common|loading')}</option> : null}</>
        ) : (
          <option value={''}>{query.isFetching ? t('common|loading') : t('Choose a pen')}</option>
        )}
        {items &&
          items.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
              {item.description && ' - ' + item.description}
            </option>
          ))}
      </Form.Select>
      {props.children}
      {props.required ? (
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      ) : null}
      {showAddModal ? (
        <PenModal onClose={props.onCloseAddModal ?? ((): void => undefined)} />
      ) : null}
    </>
  );
};

export default PenSelect;
