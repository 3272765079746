// Copyright © 2023 CATTLEytics Inc.

import { inject, injectable } from 'inversify';

import { TYPES } from '../../../types';
import Organization from '../../common/entities/organization';
import Logger from '../../logger/logger';
import Api2Service from './api2Service';
import DataService from './dataService';

const path = '/v1/organizations';

/**
 * Service for managing organizations.
 */
@injectable()
export default class OrganizationService implements DataService<Organization> {
  private logger: Logger;

  private apiService: Api2Service;

  /**
   * Creates an instance of OrganizationService.
   *
   * @param logger Logger instance.
   * @param apiService API Service instance
   */
  constructor(
    @inject(TYPES.logger) logger: Logger,
    @inject(TYPES.api2Service) apiService: Api2Service,
  ) {
    this.logger = logger;
    this.apiService = apiService;
  }

  /**
   * Retrieve a list of organizations
   * @return Organization objects
   */
  async list(params?: Record<string, string>): Promise<Organization[]> {
    const result = await this.apiService.get<Organization[]>(path, params);
    if (!result) {
      return [];
    }

    const data = result as Organization[];
    data.forEach((item) => this.cast(item));
    return data;
  }

  async get(
    id: number | string,
    params?: Record<string, string>,
  ): Promise<Organization | undefined> {
    const result = await this.apiService.get<Organization>(`${path}/${id}`, params);
    if (!result) {
      return undefined;
    }

    const data = result as Organization;
    this.cast(data);
    return data;
  }

  async post(attributes: Record<string, string>): Promise<Organization | undefined> {
    const result = await this.apiService.post<Organization>(`${path}`, attributes);
    if (!result) {
      return undefined;
    }
    this.cast(result as Organization);
    return result;
  }

  async patch(
    id: number,
    attributes: Record<string, string | number | null>,
  ): Promise<Organization | undefined> {
    // make sure record identifier is included
    attributes.id = id;

    const result = await this.apiService.patch(`${path}/${id}`, attributes);
    if (!result) {
      return undefined;
    }

    const data = result as Organization;
    this.cast(data);
    return data;
  }

  async delete(id: number): Promise<null> {
    return await this.apiService.delete(`${path}/${id}`);
  }

  /**
   * Casts fields into javascript types.
   * @param item
   * @return cast object
   */
  cast(item: Organization): void {
    if (!item) {
      return;
    }
  }
}
