// Copyright © 2023 CATTLEytics Inc.
import { Region } from '../../../shared';

const minWidth = 80;
const minhHeight = 80;

// Copyright © 2023 CATTLEytics Inc.
const normalizeRegion = (region: Region): Region => {
  const { start: oldStart, end: oldEnd } = region;
  const invertX = oldStart.x > oldEnd.x;
  const invertY = oldStart.y > oldEnd.y;
  let start = oldStart;
  let end = oldEnd;

  if (invertX && invertY) {
    start = { x: oldEnd.x, y: oldEnd.y };
    end = { x: oldStart.x, y: oldStart.y };
  } else if (invertY) {
    start = { ...start, y: oldEnd.y };
    end = { ...end, y: oldStart.y };
  } else if (invertX) {
    start = { ...start, x: oldEnd.x };
    end = { ...end, x: oldStart.x };
  }

  const width = end.x - start.x;
  const height = end.y - start.y;

  if (width < minWidth) {
    end.x = start.x + minWidth;
  }

  if (height < minhHeight) {
    end.y = start.y + minhHeight;
  }

  return { start, end };
};

export default normalizeRegion;
