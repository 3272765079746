// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { withTranslation } from 'react-i18next';

import DefaultCalfImage from './DefaultCalfImage';
import DefaultCowImage from './DefaultCowImage';

interface Props {
  caption?: string;

  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  fallbackText: string;

  imageUrl?: string;

  isCalf?: boolean;

  /**
   * Callback for when animal image is clicked
   */
  onClick?: () => void;
}

/**
 * Component to display an animal image on an animal card
 */
const AnimalImage = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const caption = props.caption ?? props.fallbackText;

  return (
    <>
      <div
        className={`${props.className}`}
        onClick={props.onClick}
        style={{
          position: 'relative',
          height: '250px',
          width: '250px',
          backgroundColor: 'transparent',
        }}
      >
        {props.imageUrl ? (
          <>
            <img
              alt={props.fallbackText}
              className={'img-fluid rounded'}
              src={props.imageUrl}
              style={{
                objectFit: 'cover',
                height: '250px',
                width: '250px',
                backgroundColor: '#ccc',
              }}
            />
            <div
              className="bg-primary-translucent rounded-bottom"
              style={{
                paddingLeft: '0.5rem',
                paddingRight: '0.5rem',
                textAlign: 'center',
                color: 'white',
                lineHeight: '2rem',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: '2rem',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {caption}
            </div>
          </>
        ) : (
          <>
            <div
              className={'rounded d-flex justify-content-center align-items-center'}
              style={{
                border: '1px #ccc solid',
                objectFit: 'cover',
                height: '250px',
                width: '250px',
                backgroundColor: '#ccc',
                paddingBottom: '2rem',
              }}
            >
              {props.isCalf && <DefaultCalfImage style={{ width: 115, height: 115 }} />}
              {!props.isCalf && <DefaultCowImage style={{ width: 175, height: 175 }} />}
            </div>
            <div
              className="bg-primary-translucent rounded-bottom"
              style={{
                paddingLeft: '0.5rem',
                paddingRight: '0.5rem',
                textAlign: 'center',
                color: 'white',
                lineHeight: '2rem',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: '2rem',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {caption}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default withTranslation()(AnimalImage);
