// Copyright © 2023 CATTLEytics Inc.
import { add } from 'date-fns';
import {
  Dispatch,
  JSXElementConstructor,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { createGenericContext, dropTimeFromDate, formatDate } from '../../utilities';
import { defaultDateFormat, defaultNodeDiameter } from './constants';
import { DateEntity, EntityBase, TimelineDirection, TimelineNodeEventType } from './types';

type Slots<Entity extends EntityBase> = {
  horizontalTopLayover?: JSXElementConstructor<{ dateEnd?: Date; dateStart?: Date }>;
  //   node?: JSXElementConstructor<TimelineItem<Entity>>;
  tooltipContent: JSXElementConstructor<{ entity: Entity }>;
};

type TimelineContextValues<Entity extends EntityBase> = {
  dateEnd: Date;
  dateFormat: string;
  dateStart: Date;
  direction: TimelineDirection;
  entityDates: DateEntity<Entity>[];
  entityEventType: TimelineNodeEventType;
  maxDate: number | null;
  minDate: number | null;
  nodeDiameter: number;
  selectedEntity?: DateEntity<Entity> | null | undefined;
  setSelectedEntity: Dispatch<SetStateAction<DateEntity<Entity> | null | undefined>>;
  slots?: Slots<Entity>;
};

export type TimelineProviderProps<Entity extends EntityBase> = PropsWithChildren<
  Pick<
    TimelineContextValues<Entity>,
    | 'dateEnd'
    | 'dateStart'
    | 'entityDates'
    | 'entityEventType'
    | 'dateFormat'
    | 'selectedEntity'
    | 'slots'
  > & {
    direction?: TimelineDirection;
    nodeDiameter?: number;
    onEntityEvent?: (item: Entity) => void;
  }
>;

const [useTimelineContext, TimelineContextProvider] =
  createGenericContext<TimelineContextValues<any>>('Timeline');

function TimelineProvider<Entity extends EntityBase>({
  children,
  direction = 'vertical',
  dateEnd,
  dateStart,
  entityDates,
  entityEventType,
  selectedEntity: propSelectedEntity,
  dateFormat = defaultDateFormat,
  nodeDiameter = defaultNodeDiameter,
  slots,
}: TimelineProviderProps<Entity>): JSX.Element {
  const [selectedEntity, setSelectedEntity] = useState<DateEntity<Entity> | null | undefined>(null);

  const minDate = useMemo(
    () => (entityDates.length ? dropTimeFromDate(entityDates[0].date).getTime() : null),
    [entityDates],
  );
  const maxDate = useMemo(() => {
    if (!minDate) return null;
    const minDateFormat = formatDate(new Date(minDate), dateFormat);
    const maxDate = entityDates[entityDates.length - 1].date;
    const maxDateFormat = formatDate(maxDate, dateFormat);

    return dropTimeFromDate(
      minDateFormat === maxDateFormat ? add(maxDate, { days: 7 }) : maxDate,
    ).getTime();
  }, [entityDates, minDate, dateFormat]);

  useEffect(() => {
    setSelectedEntity(propSelectedEntity);
  }, [propSelectedEntity]);

  const value = useMemo(
    () => ({
      selectedEntity,
      setSelectedEntity,
      minDate,
      maxDate,
      dateFormat,
      entityEventType,
      entityDates,
      direction,
      slots,
      dateEnd,
      dateStart,
      nodeDiameter,
    }),
    [
      selectedEntity,
      setSelectedEntity,
      minDate,
      maxDate,
      dateFormat,
      entityEventType,
      entityDates,
      direction,
      slots,
      dateEnd,
      dateStart,
      nodeDiameter,
    ],
  );

  return <TimelineContextProvider value={value}>{children}</TimelineContextProvider>;
}

const useTimeline = <Entity extends EntityBase>(): TimelineContextValues<Entity> =>
  useTimelineContext() as TimelineContextValues<Entity>;

export { TimelineProvider, useTimeline };
