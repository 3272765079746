// Copyright © 2023 CATTLEytics Inc.

import 'chartjs-adapter-date-fns';

import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  ScatterController,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import { useMemo } from 'react';
import { Chart } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import Spinner from '../../common/components/Spinner';
import { api, formatDate } from '../../common/utilities';
import { ApiResourceV1, HttpMethod, QueryKey, ReportDimUpToEvent } from '../../shared';

const lineColors = [
  'rgb(255, 160, 160)', // PastelPink
  'rgb(152, 251, 152)', // PaleGreen
  'rgb(160, 160, 255)', // Pastel Purple
  'rgb(255, 215, 0)', // Gold
  'rgb(255, 160, 122)', // LightSalmon
  'rgb(255, 105, 180)', // HotPink
  'rgb(135, 206, 235)', // SkyBlue
  'rgb(127, 255, 212)', // Aquamarine
  'rgb(160, 255, 255)', // Pastel Blue
];

const FirstBredEventChart = (): JSX.Element => {
  const { t } = useTranslation();

  const history = useHistory();

  const query = useQuery<Required<ReportDimUpToEvent>[]>([QueryKey.ReportDimUpToEvent], () =>
    api<Required<ReportDimUpToEvent>[]>(HttpMethod.Get, ApiResourceV1.ReportDimUpToEvent),
  );

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LineController,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    ScatterController,
  );

  const options: ChartOptions<'scatter'> = {
    plugins: {
      legend: {
        title: {
          display: true,
          text: t('reproductionManagement|firstBredEventChart|legendTitle'),
          font: {
            size: 16,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = `${t('reproductionManagement|tooltip|lactationGroup')}: ${
              context.dataset.label
            }`;
            const dataPoint = context.dataset.data[context.dataIndex] as { primaryTag?: string };
            if (dataPoint) {
              label += `, ${t('reproductionManagement|tooltip|primaryTag')}: ${
                dataPoint.primaryTag
              }`;
            }
            if (context.parsed.y !== null) {
              label += `, ${t('reproductionManagement|tooltip|dim')}: ${context.parsed.y}`;
            }
            if (context.parsed.x !== null) {
              const bredDate = new Date(context.parsed.x as number);
              const formattedDate = formatDate(bredDate, 'MMM dd');
              label += `, ${t('reproductionManagement|tooltip|bredDate')}: ${formattedDate}`;
            }
            return label;
          },
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: t('reproductionManagement|firstBredEventChart|xAxisLabel'),
        },
        type: 'time',
        time: {
          parser: 'YYYY-MM-dd',
          displayFormats: {
            month: 'MMM yyyy',
          },
        },
        grid: {
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: t('reproductionManagement|firstBredEventChart|yAxisLabel'),
        },
        min: 0,
      },
    },
    onClick: function (evt, activeElements) {
      if (activeElements && activeElements.length > 0) {
        const chart = this as any; // Type cast to any, as Chart.js typings might not have direct access
        const datasetIndex = activeElements[0].datasetIndex;
        const dataIndex = activeElements[0].index;
        const animalId = chart.data.datasets[datasetIndex].data[dataIndex].animalId;
        // Redirect to the animal's event page using animalId
        history.push(`/animals/${animalId}/events`);
      }
    },
  };

  const data = useMemo<{
    chartData: ChartData<'scatter'>;
  } | null>(() => {
    if (!query.data) {
      return null;
    }

    const lactGroup: {
      [group: string]: { data: { animalId: number; primaryTag: string; x: number; y: number }[] };
    } = {};

    query.data.forEach((row) => {
      const { dimUpToEvent, bredDate, lactationGroup, animalId, primaryTag } = row;

      if (!lactGroup[lactationGroup]) {
        lactGroup[lactationGroup] = { data: [] };
      }
      lactGroup[lactationGroup].data.push({
        animalId: animalId,
        primaryTag: primaryTag,
        x: new Date(bredDate).getTime(),
        y: dimUpToEvent,
      });
    });

    const datasets = Object.keys(lactGroup).map((group, index) => ({
      label: group,
      data: lactGroup[group].data,
      borderColor: lineColors[index % lineColors.length],
      backgroundColor: lineColors[index % lineColors.length],
      pointRadius: 3,
    }));

    const chartData = {
      datasets: datasets,
    };

    return { chartData };
  }, [query.data]);

  return (
    <>
      {!data && <Spinner />}
      {data && <Chart data={data.chartData} options={options} type="scatter" />}
    </>
  );
};

export default FirstBredEventChart;
