// Copyright © 2023 CATTLEytics Inc.

import { useMemo } from 'react';
import { Stack } from 'react-bootstrap';

import { formatDate } from '../../../../common/utilities';
import { dateFormat } from '../../../../shared';
import { EventEntity } from '../types';

type DataItemProps = {
  label: string;
  value: string;
};

function DataItem({ label, value }: DataItemProps): JSX.Element {
  return (
    <Stack className="align-items-baseline" direction="horizontal">
      <div className="fw-bold me-1">{`${label}:`}</div>
      <div>{value}</div>
    </Stack>
  );
}

type Props = {
  entity: EventEntity;
};

function AnimalEventTooltipContent({ entity }: Props): JSX.Element {
  const date = useMemo(
    () => (entity && formatDate(entity.eventDateTime || entity.createdDate, dateFormat)) || 'N/A',
    [entity],
  );
  const notes = useMemo(() => (entity && (entity.body || entity.notes)) || 'N/A', [entity]);
  return (
    <Stack direction="vertical">
      {date && <DataItem label="Date" value={date} />}
      {notes && <DataItem label="Notes" value={notes} />}
    </Stack>
  );
}

export default AnimalEventTooltipContent;
