// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ApiResourceV1, QueryKey } from '../../shared';
import ManageListPage from './ManageListPage';

interface RouteParams {
  action: string;
}

/**
 * Routable page to manage list of animal event types.
 */
const ListsAnimalEventTypesPage = (): JSX.Element => {
  const { action } = useParams<RouteParams>();
  const { t } = useTranslation();

  return (
    <ManageListPage
      apiResource={ApiResourceV1.AnimalEventTypes}
      pageTitle={t('entity|animalEventType', { count: 2 })}
      queryKey={QueryKey.AnimalEventTypes}
      showAddModal={!!action}
      typeLabel={'event type'}
      typeName={'animalEventType'}
    />
  );
};

export default ListsAnimalEventTypesPage;
