// Copyright © 2023 CATTLEytics Inc.

import { getErrorMessage } from '../../shared';
import { getEnv } from './env';
import { sleep } from './sleep';

interface ApiOptions<RequestBodyType> {
  body?: Partial<RequestBodyType>;
  params?: Record<string, string>;
}

export const api = async <ResponseBodyType, RequestBodyType = ResponseBodyType>(
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT',
  resource: string,
  options?: ApiOptions<RequestBodyType>,
): Promise<ResponseBodyType> => {
  const debug = false;

  const { params, body } = options ?? {};

  const apiUri = getEnv('API_ENDPOINT2');
  if (!apiUri) {
    throw Error('API_ENDPOINT is not set!');
  }

  const url = new URL(resource, apiUri);

  const searchParams = params ? `?${new URLSearchParams(params).toString()}` : '';

  if (debug) {
    console.debug('API: Request URL', method, url.toString() + searchParams);
  }

  if (debug) {
    console.debug('API: Request Body', body);
  }
  try {
    await sleep(0);
    const response = await fetch(url + searchParams, {
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: body ? JSON.stringify(body) : undefined,
    });

    if (debug) {
      console.debug('API: Status', response.status);
    }
    if (response.status === 204) {
      return null as unknown as ResponseBodyType;
    }
    const json = await response.json();
    if (debug || response.status === 400) {
      console.debug('API: Response Body', JSON.stringify(json));
    }

    //console.debug('API: Set-Cookie', response.headers.get('Set-Cookie'));

    if (!response.ok) {
      if (debug) {
        console.debug('API: Not OK');
      }
      if (json && json.message) {
        throw new Error(json.message);
      }
      if (json && Array.isArray(json) && json[0].detail) {
        throw new Error(json[0].detail);
      }
      throw new Error(`HTTP error ${response.status}`);
    }

    return json;
  } catch (err) {
    throw new Error(getErrorMessage(err));
  }
};
