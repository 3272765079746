// Copyright © 2023 CATTLEytics Inc.

import { mdiProgressCheck } from '@mdi/js';
import Icon from '@mdi/react';
import { addDays, endOfDay, endOfWeek, isPast, isToday, parseISO, startOfDay } from 'date-fns';
import { useInjection } from 'inversify-react';
import React, { Fragment, useMemo, useState } from 'react';
import {
  Accordion,
  Badge,
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  Image,
  Placeholder,
  Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import Button, { ButtonVariant } from '../../common/components/Button';
import ButtonCreate from '../../common/components/ButtonCreate';
import ConfirmModal from '../../common/components/ConfirmModal';
import DataCell from '../../common/components/DataCell';
import DateTime from '../../common/components/DateTime';
import LightBoxModal from '../../common/components/LightBoxModal';
import PaginationControls from '../../common/components/PaginationControls';
import { TaskStatusIcon } from '../../common/components/TaskStatusIcon';
import { Sort, TaskPeriod } from '../../common/enums';
import TaskService from '../../common/services/taskService';
import {
  animalLinkReplacer,
  extractAnimalReferencePrimaryTag,
  IconCommentComplete,
  IconComplete,
  IconDelete,
  IconEdit,
  IconNotComplete,
  IconPriorityHighest,
  replaceMentionValuesWithNode,
} from '../../common/utilities';
import { Animal, QueryKey, Task, TaskPriority, TaskStatus } from '../../shared';
import TaskCommentModal from './TaskCommentModal';
import { TaskModal } from './TaskModal';

// cSpell:words prepopulating

interface Props {
  /**
   * Animal Object from animal card.
   */
  animal?: Animal;

  /**
   * Filter list by a single animal ID.
   */
  animalId?: number;

  /**
   * Filter list by multiple animal IDs.
   */
  animalIds?: number[];

  /**
   * Additional CSS classes to add to component.
   */
  className?: string;

  completedDateEnd?: Date;
  completedDateStart?: Date;
  /**
   * flag to hide create button
   */
  displayButton?: boolean;
  dueDateEnd?: Date;
  dueDatePeriod?: TaskPeriod;
  dueDateStart?: Date;

  /**
   * Filter task list by category.
   */
  filterByCategoryId?: number;

  /**
   * Number of tasks to show.
   */
  limit?: number;

  /**
   * Callback when list data is successfully retrieved
   * @param data
   */
  onQuerySuccess?: (data: Task[]) => void;

  onlyCompleted?: boolean;

  /**
   * Filter list by pen ID.
   */
  penId?: number;

  /**
   * Show completed
   */
  showCompleted?: boolean;

  /**
   * Show completed
   */
  showPagination?: boolean;

  /**
   * Direction to sort the list
   */
  sortDirection?: Sort;

  /**
   * Column to sort the list by
   */
  sortField?: string;
}

/**
 * List tasks.
 */
const TasksList = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const now = new Date();
  const todayStart = startOfDay(now);
  const todayEnd = endOfDay(now);
  const weekStart = addDays(startOfDay(now), 1);
  const weekEnd = addDays(endOfDay(weekStart), 7);
  const showPagination = useMemo(() => props.showPagination ?? true, [props]);

  const taskService = useInjection<TaskService>(TYPES.taskService);

  const [taskIdToDelete, setTaskIdToDelete] = useState<number>();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const [taskModalVisible, setTaskModalVisible] = useState<boolean>(false);
  const [taskCompleteWithCommentModalVisible, setTaskCompleteWithCommentModalVisible] =
    useState<boolean>(false);
  const [taskModalTaskId, setTaskModalTaskId] = useState<number>();

  const [taskDeleteModalVisible, setTaskDeleteModalVisible] = useState<boolean>(false);
  const [lightBoxImageUrl, setLightBoxImageUrl] = useState<string>('');
  const [lightBoxModalVisible, setLightBoxModalVisible] = useState<boolean>(false);

  const queryParams: Record<string, string> = {
    sortField: props.sortField ? String(props.sortField) : 'dueDate',
    sortDirection: props.sortDirection ? String(props.sortDirection) : Sort.Ascending,
  };

  if (props.animalId) {
    queryParams.animalIds = String(props.animalId);
  }
  if (props.animalIds) {
    queryParams.animalIds = props.animalIds.join(',');
  }

  if (props.filterByCategoryId) {
    queryParams.categoryId = String(props.filterByCategoryId);
  }

  if (props.dueDateStart) {
    queryParams.dueDateStart = props.dueDateStart.toISOString();
  }

  if (props.dueDateEnd) {
    queryParams.dueDateEnd = props.dueDateEnd.toISOString();
  }

  if (props.completedDateStart) {
    queryParams.completedDateStart = props.completedDateStart.toISOString();
  }

  if (props.completedDateEnd) {
    queryParams.completedDateEnd = props.completedDateEnd.toISOString();
  }

  if (props.onlyCompleted) {
    queryParams.onlyCompleted = '1';
  }

  if (props.dueDatePeriod) {
    switch (props.dueDatePeriod) {
      case TaskPeriod.Daily:
        queryParams.dueDateStart = todayStart.toISOString();
        queryParams.dueDateEnd = todayEnd.toISOString();
        break;
      case TaskPeriod.Weekly:
        queryParams.dueDateStart = weekStart.toISOString();
        queryParams.dueDateEnd = weekEnd.toISOString();
        break;
      case TaskPeriod.Whenever:
        queryParams.noDueDate = '1';
        break;
    }
  }

  queryParams.showCompleted = props.showCompleted ? '1' : '0';

  if (props.penId) {
    queryParams.penId = String(props.penId);
  }

  if (showPagination) {
    queryParams.limit = String(limit);
    queryParams.offset = String(offset);
  }

  const query = useQuery<Task[]>(
    [QueryKey.Tasks, queryParams],
    () =>
      taskService.list({
        ...queryParams,
      }),
    { onSuccess: props.onQuerySuccess, keepPreviousData: false },
  );

  const queryClient = useQueryClient();

  const mutation = useMutation(
    () => {
      if (taskIdToDelete) {
        return taskService.delete(taskIdToDelete);
      } else {
        return new Promise<null>((resolve) => resolve(null));
      }
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKey.Tasks);
      },
    },
  );

  const mutationCompleteTask = useMutation<Task | undefined, undefined, number>(
    (taskId) => {
      return taskService.patch(taskId, {
        id: taskId,
        completed: true,
        status: TaskStatus.Completed,
      });
    },
    {
      onSuccess: async () => await queryClient.invalidateQueries(QueryKey.Tasks),
    },
  );

  const mutationPendingTask = useMutation<Task | undefined, undefined, number>(
    (taskId) => {
      return taskService.patch(taskId, {
        id: taskId,
        completed: false,
        status: TaskStatus.Pending,
      });
    },
    {
      onSuccess: async () => await queryClient.invalidateQueries(QueryKey.Tasks),
    },
  );

  const mutationInProgressTask = useMutation<Task | undefined, undefined, number>(
    (taskId) => {
      return taskService.patch(taskId, {
        id: taskId,
        completed: false,
        status: TaskStatus.InProgress,
      });
    },
    {
      onSuccess: async () => await queryClient.invalidateQueries(QueryKey.Tasks),
    },
  );

  const placeholder = (
    <Accordion.Item eventKey={'loading'}>
      <Accordion.Header>
        <Placeholder animation={'glow'} className={'w-100 d-flex justify-content-between'}>
          <div className={'w-100 d-flex'}>
            <Placeholder className={'me-3'} md={2} />
            <Placeholder md={4} />
          </div>
          <Placeholder className={'me-1'} md={1} />
        </Placeholder>
      </Accordion.Header>
    </Accordion.Item>
  );

  if (query.isLoading) {
    return (
      <Fragment>
        {[...Array(5).keys()].map((key) => (
          <Fragment key={key}>{placeholder}</Fragment>
        ))}
      </Fragment>
    );
  }

  if (query.isError || !query.data) {
    return <AlertError message={'Error'} />;
  }

  const showModal = (taskId: number): void => {
    setTaskModalVisible(true);
    setTaskModalTaskId(taskId);
  };

  const showCompleteWithCommentModal = (taskId: number): void => {
    setTaskCompleteWithCommentModalVisible(true);
    setTaskModalTaskId(taskId);
  };

  const showDeleteModal = (task: Task): void => {
    setTaskDeleteModalVisible(true);
    setTaskIdToDelete(task.id);
  };

  const tasks = query.data;

  return (
    <>
      {' '}
      {/* TODO: add support too Task modal for prepopulating/adding animals */}
      {props.displayButton && (
        <div className="d-flex justify-content-end px-3">
          <ButtonCreate
            label={'Create Task'}
            onClick={(): void => {
              setTaskModalTaskId(undefined);
              setTaskModalVisible(true);
            }}
            size={'sm'}
            style={{ margin: 0 }}
          />
        </div>
      )}
      <Accordion defaultActiveKey="0" flush>
        {tasks.length === 0 && query.isFetched && (
          <p className={'text-center'}>
            <em>{t('No tasks found')}</em>
          </p>
        )}
        {tasks.map((task) => {
          const related = [];

          if (task.animalId || task.penId) {
            if (task.animalId) {
              related.push({
                link: `/animals/${task.animalId}`,
                label: `Animal`,
                value: `#${extractAnimalReferencePrimaryTag(task.animal)}`,
              });
            }

            if (task.penId) {
              related.push({
                link: `/pens/${task.penId}`,
                label: `Pen`,
                value: `#${task.penId}`,
              });
            }
          }

          const animalPrimaryTag = extractAnimalReferencePrimaryTag(task.animal);
          const isOverdue =
            task.completed === false &&
            !!task.dueDate &&
            !isToday(parseISO(task.dueDate)) &&
            isPast(parseISO(task.dueDate));
          return (
            <Accordion.Item eventKey={String(task.id)} key={task.id}>
              <Accordion.Header>
                <div className={'w-100 d-flex justify-content-between'} style={{ minWidth: 0 }}>
                  <div
                    className={`text-truncate ${isOverdue ? 'text-danger' : 'text-muted'}`}
                    style={{ minWidth: 0 }}
                  >
                    <span className={'me-3'}>
                      <TaskStatusIcon overdue={isOverdue} status={task.status} />
                    </span>
                    {animalPrimaryTag && <small className={'me-3'}>#{animalPrimaryTag}</small>}

                    {task.dueDate && isPast(endOfWeek(parseISO(task.dueDate))) && (
                      <strong className={`me-3 `}>
                        <DateTime date={task.dueDate} hideTime={true} />
                      </strong>
                    )}
                    <span>
                      {replaceMentionValuesWithNode(
                        task.description.split('\n')[0],
                        animalLinkReplacer,
                      )}
                    </span>
                  </div>

                  {!task.animalId && !task.penId && <div />}

                  <div className={'me-2'}>
                    {task.taskCategory && (
                      <Badge className={'me-3'} pill>
                        {task.taskCategory.name}
                      </Badge>
                    )}
                    {task.animalId && (
                      <Badge bg={'primary'} className={'me-3'} pill>
                        Animal
                      </Badge>
                    )}
                    {task.priority === TaskPriority.Highest && (
                      <IconPriorityHighest
                        className={'me-2'}
                        style={{
                          color: 'var(--bs-danger)',
                        }}
                      />
                    )}
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                {related && (
                  <small className={'d-flex justify-content-between'}>
                    <DataCell label={t('Status')} value={task.status} />
                    <DataCell label={t('Category')} value={task.taskCategory?.name} />
                    {task.assignedUser && (
                      <DataCell
                        label={t('Assigned')}
                        value={`${task.assignedUser.firstName} ${task.assignedUser.lastName}`}
                      />
                    )}
                    {related.map((item, index) => (
                      <DataCell
                        key={index}
                        label={item.label}
                        linkTo={item.link}
                        value={item.value}
                      />
                    ))}
                  </small>
                )}
                <hr />
                {replaceMentionValuesWithNode(task.description, animalLinkReplacer)}

                {task.imageUrlsSigned && task.imageUrlsSigned.length > 0 && (
                  <>
                    <hr />
                    <Container fluid>
                      <Row>
                        {task.imageUrlsSigned?.map((image, index) => (
                          <Col
                            className={'text-center pe-2 pb-2 pe-auto'}
                            key={`image${index}`}
                            md={3}
                            xs={4}
                          >
                            <Image
                              alt={`Figure ${index}`}
                              fluid
                              loading="lazy"
                              onClick={(): void => {
                                if (image.signedUrl) {
                                  setLightBoxModalVisible(true);
                                  setLightBoxImageUrl(image.signedUrl);
                                }
                              }}
                              src={image.signedUrl}
                              style={{ height: 100, cursor: 'pointer' }}
                              thumbnail
                            />
                          </Col>
                        ))}
                      </Row>
                    </Container>
                    {lightBoxModalVisible && (
                      <LightBoxModal
                        imageUrl={lightBoxImageUrl}
                        onClose={(): void => setLightBoxModalVisible(false)}
                      />
                    )}
                  </>
                )}
                {task.notes && (
                  <>
                    <hr />
                    <p>
                      <strong>Notes</strong>
                    </p>
                    {task.notes}
                  </>
                )}
                <hr />
                <div className={'d-flex justify-content-between align-content-center'}>
                  <DataCell label={t('Created')}>
                    <DateTime date={task.createdDate} />
                  </DataCell>

                  <DataCell label={t('Modified')}>
                    <DateTime date={task.modifiedDate} />
                  </DataCell>
                  {task.completed && task.completedDate && (
                    <DataCell label={t('Completed')}>
                      <DateTime date={task.completedDate} />
                    </DataCell>
                  )}
                  {task.completedUser && (
                    <DataCell
                      label={t('Completed by')}
                      value={`${task.completedUser.firstName} ${task.completedUser.lastName}`}
                    />
                  )}
                </div>
                <hr />
                <small className={'d-flex justify-content-between align-content-center'}>
                  <Button
                    icon={IconDelete}
                    label={t('Delete')}
                    onClick={(): void => showDeleteModal(task)}
                    size={'sm'}
                    variant={ButtonVariant.OutlineSecondary}
                  />
                  <Button
                    icon={IconEdit}
                    label={t('Edit')}
                    onClick={(): void => showModal(task.id)}
                    size={'sm'}
                    variant={ButtonVariant.OutlinePrimary}
                  />
                  <Dropdown as={ButtonGroup}>
                    {/*// not complete or complete*/}
                    {task.completed && (
                      <Button
                        busy={mutationCompleteTask.isLoading || query.isFetching}
                        disabled={mutationCompleteTask.isLoading || query.isFetching}
                        icon={IconNotComplete}
                        label={t('Not Complete')}
                        onClick={async (): Promise<Task | undefined> =>
                          await mutationPendingTask.mutateAsync(task.id)
                        }
                        size={'sm'}
                        variant={ButtonVariant.OutlinePrimary}
                      />
                    )}
                    {!task.completed && (
                      <Button
                        busy={mutationCompleteTask.isLoading || query.isFetching}
                        disabled={mutationCompleteTask.isLoading || query.isFetching}
                        icon={IconComplete}
                        label={t('Complete')}
                        onClick={async (): Promise<Task | undefined> =>
                          await mutationCompleteTask.mutateAsync(task.id)
                        }
                        size={'sm'}
                        variant={ButtonVariant.OutlinePrimary}
                      />
                    )}
                    <Dropdown.Toggle size="sm" split variant="outline-primary" />
                    <Dropdown.Menu align="end">
                      {task.status !== TaskStatus.InProgress && (
                        <Dropdown.Item
                          onClick={async (): Promise<Task | undefined> =>
                            await mutationInProgressTask.mutateAsync(task.id)
                          }
                        >
                          <Icon
                            className={'me-1'}
                            path={mdiProgressCheck}
                            style={{ width: '18px' }}
                          />
                          In Progress
                        </Dropdown.Item>
                      )}
                      {task.status === TaskStatus.InProgress && (
                        <Dropdown.Item
                          onClick={async (): Promise<Task | undefined> =>
                            await mutationPendingTask.mutateAsync(task.id)
                          }
                        >
                          <IconNotComplete className={'me-1'} />
                          Not Complete
                        </Dropdown.Item>
                      )}
                      {!task.completed && (
                        <Dropdown.Item onClick={(): void => showCompleteWithCommentModal(task.id)}>
                          <IconCommentComplete className={'me-1'} /> Complete with comment
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </small>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
        {showPagination && (
          <PaginationControls
            className="mt-3"
            disabled={query.isLoading}
            end={tasks.length < limit}
            limit={limit}
            onLimitChange={setLimit}
            onNext={(): void => setOffset((prev) => prev + limit)}
            onPrev={(): void => setOffset((prev) => Math.max(prev - limit, 0))}
            start={offset === 0}
          />
        )}
        {taskModalVisible && (
          <TaskModal
            animal={props.animal}
            onClose={(): void => setTaskModalVisible(false)}
            taskId={taskModalTaskId}
          />
        )}
        {taskCompleteWithCommentModalVisible && (
          <TaskCommentModal
            onClose={(): void => setTaskCompleteWithCommentModalVisible(false)}
            taskId={taskModalTaskId ?? 0}
          />
        )}
        {taskDeleteModalVisible && (
          <ConfirmModal
            busy={mutation.isLoading}
            cancelOnClick={(): void => setTaskDeleteModalVisible(false)}
            okOnClick={async (): Promise<void> => {
              await mutation.mutateAsync();
              setTaskDeleteModalVisible(false);
            }}
            title={'Delete task'}
            visible={true}
          >
            {t('Are you sure you want to delete this task?')}
          </ConfirmModal>
        )}
      </Accordion>
    </>
  );
};

export default TasksList;
