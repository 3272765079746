// Copyright © 2024 CATTLEytics Inc.

import React, { useState } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import AlertError from '../common/components/AlertError';
import Button from '../common/components/Button';
import { ButtonVariant } from '../common/components/Button';
import ConfirmModal from '../common/components/ConfirmModal';
import DataTable, { DataTableHeader, DataTableRow } from '../common/components/DataTable';
import DateTime from '../common/components/DateTime';
import TablePlaceholder from '../common/components/TablePlaceholder';
import AnimalGroup from '../common/entities/animalGroup';
import { Sort } from '../common/enums';
import { IconDelete, IconManage } from '../common/utilities';
import { api } from '../common/utilities';
import { QueryKey } from '../shared';
import { ApiResourceV1, HttpMethod } from '../shared';

interface Props {
  /**
   * Additional CSS classes to add to component.
   */
  className?: string;

  /**
   * Number of groups to show.
   */
  initialLimit?: number;

  /**
   * Number of groups to skip.
   */
  initialOffset?: number;

  /**
   * Table sort direction.
   */
  initialSortDirection?: Sort;

  /**
   * Table sort field.
   */
  initialSortField?: string;
}

/**
 * A table component containing animal groups.
 */
export const AnimalGroupsTable = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  const history = useHistory();

  const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState<boolean>(false);
  const [deleteConfirmModalErrorMessage, setDeleteConfirmModalErrorMessage] = useState<string>();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();

  const [limit, setLimit] = useState<number>(props.initialLimit ?? 25);
  const [offset, setOffset] = useState<number>(props.initialOffset ?? 0);
  const [sortField, setSortField] = useState<string>(props.initialSortField ?? 'name');
  const [sortDirection, setSortDirection] = useState<Sort>(
    props.initialSortDirection ?? Sort.Ascending,
  );

  const query = useQuery<AnimalGroup[]>(
    [QueryKey.AnimalGroups, limit, offset, sortField, sortDirection],
    () =>
      api<AnimalGroup[]>(HttpMethod.Get, ApiResourceV1.AnimalGroups, {
        params: {
          limit: String(limit),
          offset: String(offset),
          sortField: sortField,
          sortDirection: String(sortDirection),
        },
      }),
    { keepPreviousData: true },
  );

  const showDeleteConfirmModal = (id: number): void => {
    setDeleteConfirmModalVisible(true);
    setDeleteId(id);
  };

  const queryClient = useQueryClient();

  const deleteItem = async (): Promise<void> => {
    if (!deleteId) {
      return;
    }
    setIsDeleting(true);
    try {
      await api(HttpMethod.Delete, `${ApiResourceV1.AnimalGroups}/${deleteId}`);
      await queryClient.invalidateQueries(QueryKey.AnimalGroups);
      setDeleteConfirmModalVisible(false);
    } catch (err) {
      setDeleteConfirmModalErrorMessage(t('animalGroupsTable|deleteConfirmation|errorMessage'));
    }
    setIsDeleting(false);
  };

  if (query.isLoading) {
    return <TablePlaceholder />;
  }

  if (query.isError) {
    return (
      <AlertError
        message={t('common|unexpectedRetrievalError', {
          value: t('entity|animalGroup', { count: 2 }).toLowerCase(),
        })}
      />
    );
  }

  const headers: DataTableHeader[] = [
    {
      name: 'name',
      label: t('animalGroupsTable|nameLabel'),
      classNameMobile: 'col-12',
    },
    {
      name: 'type',
      label: t('animalGroupsTable|typeLabel'),
      classNameMobile: 'col-6',
    },
    {
      name: 'countAnimals',
      label: t('animalGroupsTable|countAnimalsLabel'),
      classNameMobile: 'col-6',
    },
    {
      name: 'modified',
      label: t('animalGroupsTable|modifiedLabel'),
      classNameMobile: 'col-12',
    },
    {
      name: 'actions',
      sortable: false,
      label: t('animalGroupsTable|actionLabel'),
      hideMobile: true,
    },
  ];

  const data: DataTableRow[] = !query.data
    ? []
    : query.data.map((row) => ({
        id: String(row.id),
        name: row.name,
        type: row.type,
        countAnimals: row.countAnimals,
        modified: <DateTime date={row.modifiedDate} />,
        actions: (
          <div onClick={(e): void => e.stopPropagation()}>
            <Dropdown as={ButtonGroup}>
              <Button
                onClick={(): void => history.push(`/animals/groups/${row.id}/manage`)}
                size="sm"
                variant="outline-primary"
              >
                <IconManage className={'me-1'} /> {t('animalGroupsTable|manageLabel')}
              </Button>
              <Dropdown.Toggle size="sm" split variant="outline-primary" />
              <Dropdown.Menu align="end">
                <Dropdown.Item
                  onClick={(): void => history.push(`/animals/groups/${row.id}/manage`)}
                >
                  <IconManage className={'me-1'} /> {t('animalGroupsTable|manageLabel')}
                </Dropdown.Item>
                <Dropdown.Item onClick={(): void => showDeleteConfirmModal(row.id)}>
                  <IconDelete className={'me-1'} />
                  {t('animalGroupsTable|deleteLabel')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),
      }));

  return (
    <div>
      <DataTable
        data={data}
        headers={headers}
        isLoading={query.isLoading}
        isPreviousData={query.isPreviousData}
        limit={limit}
        messageNoData={t('animalGroupsTable|noDataFoundMessage', { value: t('users') })}
        offset={offset}
        onLimitChange={(newLimit): void => setLimit(newLimit)}
        onOffsetChange={(newOffset): void => setOffset(newOffset)}
        onRowClick={(row): void => history.push(`/animals/groups/${Number(row.id)}/manage`)}
        onSortDirectionChange={(newSortDirection): void => setSortDirection(newSortDirection)}
        onSortFieldChange={(newSortField): void => setSortField(newSortField)}
        sortDirection={sortDirection}
        sortField={sortField}
      />

      <ConfirmModal
        busy={isDeleting}
        cancelLabel={t('animalGroupsTable|deleteConfirmation|cancelLabel')}
        cancelOnClick={(): void => setDeleteConfirmModalVisible(false)}
        errorMessage={deleteConfirmModalErrorMessage}
        okIcon={IconDelete}
        okLabel={t(`animalGroupsTable|deleteConfirmation|okLabel`, {
          value: t('entity|animalGroup').toLowerCase(),
        })}
        okOnClick={deleteItem}
        okVariant={ButtonVariant.Danger}
        title={t(`animalGroupsTable|deleteConfirmation|title`, {
          value: t('entity|animalGroup').toLowerCase(),
        })}
        visible={deleteConfirmModalVisible}
      >
        {t('animalGroupsTable|deleteConfirmation|message', {
          value: t('entity|animalGroup').toLowerCase(),
        })}
      </ConfirmModal>
    </div>
  );
};
