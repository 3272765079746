// Copyright © 2023 CATTLEytics Inc.

import loadImage from 'blueimp-load-image';

export enum ImageSize {
  Large = 'large',
  Medium = 'medium',
  Original = 'original',
  Small = 'small',
  Thumb = 'thumb',
}

export interface ImageProperties {
  height: number;
  width: number;
}

export const imageSizes: Record<string, ImageProperties> = {
  [ImageSize.Thumb]: { width: 400, height: 300 },
  [ImageSize.Small]: { width: 400, height: 300 },
  [ImageSize.Medium]: { width: 1024, height: 768 },
  [ImageSize.Large]: { width: 1920, height: 1080 },
};

export enum ImageMimeType {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
}

const getFileType = (file: File): ImageMimeType =>
  Object.values(ImageMimeType).find((type) => type === file.type) ?? ImageMimeType.JPEG;

export const resizeImage = async (
  file: File,
  imageSize: ImageSize,
  imageType?: ImageMimeType,
): Promise<File> => {
  if (imageSize === ImageSize.Original) {
    return new Promise<File>((resolve) => resolve(file));
  }

  const type = imageType ?? getFileType(file);

  const result = await loadImage(file, {
    maxWidth: imageSizes[imageSize].width,
    maxHeight: imageSizes[imageSize].height,
    orientation: true,
    canvas: true,
  });

  const canvas = result.image as unknown as HTMLCanvasElement;

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          reject();
        } else {
          resolve(new File([blob], file.name));
        }
      },
      type,
      90,
    );
  });
};
