// Copyright © 2023 CATTLEytics Inc.
import { FC } from 'react';

import cattleyticsLogoDark from '../../../assets/img/cattleytics-logo-dark-with-tagline.png';
import cattleyticsLogoLight from '../../../assets/img/cattleytics-logo-light-with-tagline.png';

const WhiteboardFooter: FC = () => {
  const colorMode = document.documentElement.dataset.bsTheme;

  return (
    <div className={'w-100'}>
      <div className={'text-center m-2'}>
        <img
          alt={'CATTLEytics'}
          src={colorMode === 'dark' ? cattleyticsLogoDark : cattleyticsLogoLight}
          style={{ height: '38px' }}
        />
      </div>
    </div>
  );
};

export default WhiteboardFooter;
