// Copyright © 2023 CATTLEytics Inc.
import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonVariant } from './Button';
import ConfirmModal from './ConfirmModal';

type ConfirmModalProps = ComponentProps<typeof ConfirmModal>;

interface Props extends Omit<ConfirmModalProps, 'okVariant' | 'i18n'> {
  value?: string;
}

const DeleteConfirmModal = ({ value, children, ...props }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ConfirmModal
      cancelLabel={t('Cancel')}
      okLabel={t(`Yes, delete this {{value}}`, { value })}
      okVariant={ButtonVariant.Danger}
      title={t(`Delete this {{value}}`, { value })}
      {...props}
    >
      {children ? children : t('Are you sure you want to delete this {{value}}?', { value })}
    </ConfirmModal>
  );
};

export default DeleteConfirmModal;
