// Copyright © 2023 CATTLEytics Inc.

import Entity from './entity';

/**
 * Represents a session entity.
 */
export default interface Session extends Entity {
  /**
   * Whether the user is authenticated.
   */
  authenticated: boolean;
}

/**
 * Returns a session entity with all required fields set.
 */
export function authDefault(): Session {
  return {
    id: 0,
    authenticated: false,
    createdDate: new Date(),
    modifiedDate: new Date(),
  };
}
