// Copyright © 2023 CATTLEytics Inc.
// Copyright © 2023 CATTLEytics Inc.
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

interface Options {
  initialLimit?: number;
}

interface Return {
  limit: number;
  offset: number;
  setLimit: Dispatch<SetStateAction<number>>;
  setOffset: Dispatch<SetStateAction<number>>;
}

export const usePaginationRequstValues = ({ initialLimit }: Options): Return => {
  const [limit, setLimit] = useState(initialLimit ?? 10);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setOffset(0);
  }, [limit, setOffset]);

  return { limit, setLimit, offset, setOffset };
};
