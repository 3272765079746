// Copyright © 2023 CATTLEytics Inc.

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from '../../common/components/Button';
import ButtonCreate from '../../common/components/ButtonCreate';
import NoteModal from './NoteModal';
import NotesList from './NotesList';

interface Props {
  /**
   * Animal ID to filter notes by
   */
  animalId?: number;

  /**
   * Additional classes to add to component
   */
  className?: string;

  /**
   * Whether to hide the title.
   * @TODO remove title from component
   */
  hideTitle?: boolean;

  /**
   * Animal primary tag
   */
  primaryTag?: string;
}

/**
 * Card style component to show a short list of notes with ability to add new note..
 */
const NotesCard = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const [noteModalVisible, setNoteModalVisible] = useState<boolean>(false);
  const [moreButtonVisible, setMoreButtonVisible] = useState<boolean>(false);
  return (
    <>
      {!props.hideTitle && (
        <div className="d-flex justify-content-end px-3">
          <ButtonCreate
            label={'Create Note'}
            onClick={(): void => {
              setNoteModalVisible(true);
            }}
            size={'sm'}
            style={{ margin: 0 }}
          />
        </div>
      )}
      <NotesList
        animalId={props.animalId}
        limit={5}
        onQuerySuccess={(data): void => setMoreButtonVisible(data.length === 5)}
        primaryTag={props.primaryTag}
      />

      {noteModalVisible ? (
        <NoteModal animalId={props.animalId} onClose={(): void => setNoteModalVisible(false)} />
      ) : null}
      {moreButtonVisible ? (
        <Link className={'d-flex justify-content-end'} to={`/notes?animal=${props.animalId}`}>
          <Button className={'text-center'} variant={'link'}>
            {t('More')}
          </Button>
        </Link>
      ) : null}
    </>
  );
};

export default NotesCard;
