// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { TYPES } from '../../../types';
import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import AlertSuccessForModal from '../../common/components/AlertSuccessForModal';
import ButtonModal from '../../common/components/ButtonModal';
import ButtonModalCancel from '../../common/components/ButtonModalCancel';
import Modal from '../../common/components/Modal';
import Required from '../../common/components/Required';
import AuthService from '../../common/services/authService';
import { IconSend } from '../../common/utilities';

/**
 * Component input properties.
 */
interface Props {
  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;
}

/**
 * A modal for a user to change passwords.
 */
const UserPasswordResetModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const authService = useInjection<AuthService>(TYPES.authService);
  const [email, setEmail] = useState<string>('');
  const [validated, setValidated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');

  useEffect(() => {
    const invalidElements = document.querySelectorAll('input.form-control:invalid');
    if (invalidElements.length > 0) {
      invalidElements[0].closest('.form-group')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [validated]);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value);
    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(false);

    if (!valid) {
      setValidated(true);
    }
  };

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();
    setSuccessMessage('');
    setErrorMessage('');
    try {
      await mutation.mutateAsync();
    } catch (err) {
      console.error('User password reset failure.', err);
      setErrorMessage(t(`userPasswordResetModal|emailExistError`));
    }
  };

  // change to newPassword api request
  const mutation = useMutation<null | undefined, Error>(
    () =>
      authService.resetPassword({
        email: email,
      }),
    {
      onSuccess: async () => {
        // clear form values
        setEmail('');
        setSuccessMessage(t('userPasswordResetModal|passwordResetInstructions'));
      },
    },
  );

  return (
    <Modal onClose={props.onClose} size={'lg'} title={t('Forgot Password?')} visible={true}>
      <Form noValidate={true} onSubmit={onFormSubmit} validated={validated}>
        <Form.Text
          id="helpBlock"
          style={{ marginBottom: '4px', fontSize: '18px', color: '#000000' }}
        >
          {t('userPasswordResetModal|enterEmailText')}
        </Form.Text>
        <FormGroup className="form-group mb-3" controlId="auth.ControlUsername">
          <Form.Label>
            {t('Email')} <Required />
          </Form.Label>
          <Form.Control
            name={'email'}
            onChange={onInputChange}
            placeholder={t('Email')}
            required
            type={'email'}
            value={email}
          />
          <Form.Control.Feedback type={'invalid'}>
            {t('userPasswordResetModal|requiredValidEmailInvalidFeedback')}
          </Form.Control.Feedback>
        </FormGroup>

        <AlertErrorForModal message={errorMessage} />
        <AlertSuccessForModal message={successMessage} />
        <div className="modal-footer modal-footer-in-form">
          <ButtonModalCancel onClick={props.onClose} />
          <ButtonModal
            className={'w-100 w-auto-sm'}
            disabled={validated}
            icon={IconSend}
            label={t('Send')}
            type={'submit'}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default UserPasswordResetModal;
