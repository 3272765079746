// Copyright © 2023 CATTLEytics Inc.

import { useMemo, useState } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import Button from '../../common/components/Button';
import { ButtonVariant } from '../../common/components/Button';
import ButtonCreate from '../../common/components/ButtonCreate';
import ConfirmModal from '../../common/components/ConfirmModal';
import DataTable, { DataTableHeader, DataTableRow } from '../../common/components/DataTable';
import Page from '../../common/components/Page';
import { Sort } from '../../common/enums';
import { api, IconDelete, IconEdit } from '../../common/utilities';
import { ApiResourceV1, Entity, HttpMethod } from '../../shared';
import ManageListEditModal from './ManageListEditModal';

/**
 * Component's input properties
 */
interface Props {
  /**
   * The API we will use for CRUD operations.
   */
  apiResource: ApiResourceV1;

  /**
   * Title of the page
   */
  pageTitle: string;

  /**
   * Base key to help determine when a query should be re-fetched
   */
  queryKey: string;

  /**
   * Ability to prevent editing function on the list
   */
  restricted?: boolean;

  /**
   * if true the add/edit modal will be shown when this component renders
   */
  showAddModal?: boolean;

  /**
   * Short entity name like "animal color" suitable for titles
   */
  typeLabel?: string;

  /**
   * Short entity name like "animalColor"
   */
  typeName: string;
}

/**
 * A non-routable generic detail page to display a list item detail and provide edit/delete links on mobile.
 */
const ManageListPage = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  const [limit, setLimit] = useState<number>(25);
  const [offset, setOffset] = useState<number>(0);
  const [sortField, setSortField] = useState<string>('name');
  const [sortDirection, setSortDirection] = useState<Sort>(Sort.Ascending);

  const [editId, setEditId] = useState<number>();
  const [editModalVisible, setEditModalVisible] = useState<boolean>(!!props.showAddModal);

  const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState<boolean>(false);
  const [deleteConfirmModalErrorMessage, setDeleteConfirmModalErrorMessage] = useState<string>();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();

  const query = useQuery<Entity[]>(
    [props.queryKey, limit, offset, sortField, sortDirection],
    () =>
      api(HttpMethod.Get, props.apiResource, {
        params: {
          limit: String(limit),
          offset: String(offset),
          sortField: sortField,
          sortDirection: String(sortDirection),
        },
      }),
    { keepPreviousData: true },
  );

  const typeLabel = props.typeLabel ?? props.typeName;

  const showEditModal = (id?: number): void => {
    setEditModalVisible(true);
    setEditId(id);
  };

  const showDeleteConfirmModal = (id: number): void => {
    setDeleteConfirmModalVisible(true);
    setDeleteId(id);
  };

  const queryClient = useQueryClient();

  const deleteItem = async (): Promise<void> => {
    if (!deleteId) {
      return;
    }
    setIsDeleting(true);
    try {
      await api(HttpMethod.Delete, `${props.apiResource}/${deleteId}`);
      await queryClient.invalidateQueries(props.queryKey);
      setDeleteConfirmModalVisible(false);
    } catch (err) {
      console.error('This item failed to be deleted', err);
      setDeleteConfirmModalErrorMessage('This item could not be deleted.');
    }
    setIsDeleting(false);
  };

  const headers: DataTableHeader[] = useMemo(
    () => [
      {
        name: 'name',
        label: t('Name'),
      },
      {
        name: 'description',
        label: t('Description'),
      },
      {
        name: 'systemDefined',
        label: t('System'),
        infoTooltip: 'Indicates whether this type is defined by the system',
      },
      { name: 'actions', sortable: false, label: 'Actions', hideMobile: true },
    ],
    [t],
  );

  const data: DataTableRow[] = useMemo(
    () =>
      !query.data
        ? []
        : query.data.map((item) => ({
            id: String(item.id),
            name: item.name,
            description: (
              <div className={'text-truncate text-truncate-clamp-2'}>{item.description}</div>
            ),
            systemDefined: item.systemDefined ? 'Yes' : '-',
            actions: !props.restricted ? (
              <div onClick={(e): void => e.stopPropagation()}>
                <Dropdown as={ButtonGroup} className={'text-nowrap'}>
                  <Button
                    disabled={item.systemDefined}
                    onClick={(): void => showEditModal(item.id)}
                    size="sm"
                    variant="outline-primary"
                  >
                    <IconEdit className={'me-1'} /> {t('Edit')}
                  </Button>
                  <Dropdown.Toggle size="sm" split variant="outline-primary" />
                  <Dropdown.Menu align="end">
                    <Dropdown.Item
                      disabled={item.systemDefined}
                      onClick={(): void => showEditModal(item.id)}
                    >
                      <IconEdit className={'me-1'} /> Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      disabled={item.systemDefined}
                      onClick={(): void => showDeleteConfirmModal(item.id)}
                    >
                      <IconDelete className={'me-1'} />
                      {t('Delete')}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ) : undefined,
          })),
    [props, query, t],
  );

  return (
    <Page
      breadcrumbs={[{ label: t('Lists'), to: '/lists' }]}
      buttons={
        !props.restricted && <ButtonCreate label={'Add'} onClick={(): void => showEditModal()} />
      }
      title={props.pageTitle}
    >
      <DataTable
        className={'mt-3'}
        data={data}
        headers={headers}
        isLoading={query.isLoading}
        isPreviousData={query.isPreviousData}
        limit={limit}
        messageNoData={`No ${props.typeLabel}s defined.`}
        offset={offset}
        onLimitChange={(newLimit): void => setLimit(newLimit)}
        onOffsetChange={(newOffset): void => setOffset(newOffset)}
        onRowClick={(row): undefined | void =>
          row.systemDefined === 'Yes' ? undefined : showEditModal(Number(row.id))
        }
        onSortDirectionChange={(newSortDirection): void => setSortDirection(newSortDirection)}
        onSortFieldChange={(newSortField): void => setSortField(newSortField)}
        sortDirection={sortDirection}
        sortField={sortField}
      />

      {editModalVisible && (
        <ManageListEditModal
          apiResource={props.apiResource}
          editId={editId}
          onClose={(): void => setEditModalVisible(false)}
          queryKey={props.queryKey}
          typeLabel={props.typeLabel}
          typeName={props.typeName}
        />
      )}
      <ConfirmModal
        busy={isDeleting}
        cancelLabel={'Cancel'}
        cancelOnClick={(): void => setDeleteConfirmModalVisible(false)}
        errorMessage={deleteConfirmModalErrorMessage}
        okLabel={t(`Yes, delete this {{value}}`, { value: t(typeLabel) })}
        okOnClick={deleteItem}
        okVariant={ButtonVariant.Danger}
        title={t(`Delete this {{value}}`, { value: t(typeLabel) })}
        visible={deleteConfirmModalVisible}
      >
        {t(`Are you sure you want to delete this {{value}}?`, { value: t(typeLabel) })}
      </ConfirmModal>
    </Page>
  );
};

export default ManageListPage;
