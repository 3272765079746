// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MdOutlineAssignment } from 'react-icons/md';
import { useQuery } from 'react-query';

import { TYPES } from '../../../types';
import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import Button, { ButtonVariant } from '../../common/components/Button';
import ButtonModal from '../../common/components/ButtonModal';
import Modal from '../../common/components/Modal';
import Spinner from '../../common/components/Spinner';
import Pen, { penDefault } from '../../common/entities/pen';
import PenService from '../../common/services/penService';
import { Region } from '../../common/types';
import { IconCancel } from '../../common/utilities';
import Logger from '../../logger/logger';
import { QueryKey } from '../../shared/enums';

const penLimit = 1000;

/**
 * Component input properties.
 */
interface Props {
  assignedPenIds?: number[];

  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * The Region object to assign to a pen.
   */
  mapRegion: Region;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;
}

/**
 * Map component for creating pen regions.
 */
const AssignPenModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const logger = useInjection<Logger>(TYPES.logger);
  const penService = useInjection<PenService>(TYPES.penService);

  const initialPen = { ...penDefault(), mapRegion: props.mapRegion };
  const [data, setData] = useState<Pen>(initialPen);

  const [validated, setValidated] = useState<boolean>(false);
  const [busy, setBusy] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const query = useQuery(
    [QueryKey.Pens, penLimit],
    () => penService.list({ limit: String(penLimit) }),
    {
      keepPreviousData: true,
    },
  );
  const pens = useMemo(() => query.data?.filter(({ mapRegion }) => !mapRegion), [query]);

  const onSelectChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const newData = { ...data };
    newData[event.target.name] = event.target.value;
    setData(newData);
  };

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setBusy(true);
    setErrorMessage('');
    try {
      await penService.patch(data.id, { mapRegion: data.mapRegion as Region });
      setBusy(false);
      props.onClose();
    } catch (err) {
      logger.error('Region failed to be assigned to pen.', err, data);
      setErrorMessage(t('Region failed to be assigned to pen.'));
      setBusy(false);
    }
  };

  return (
    <Modal onClose={props.onClose} size={'lg'} title={'Assign a pen'} visible={true}>
      <p>Assign a pen to this region.</p>
      <Form noValidate={true} onSubmit={onFormSubmit} validated={validated}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Pen</Form.Label>
          <Form.Select
            aria-label="Pen filter"
            name={'id'}
            onChange={onSelectChange}
            value={data.id}
          >
            <option>Choose a pen</option>
            {pens ? (
              pens.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))
            ) : (
              <Spinner />
            )}
          </Form.Select>
        </Form.Group>
        <AlertErrorForModal message={errorMessage} />
        <div className="modal-footer modal-footer-in-form">
          <Button
            disabled={busy}
            icon={IconCancel}
            onClick={props.onClose}
            type={'button'}
            variant={ButtonVariant.Secondary}
          >
            Cancel
          </Button>
          <ButtonModal
            busy={busy}
            disabled={busy}
            icon={MdOutlineAssignment}
            label={'Assign'}
            type={'submit'}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default AssignPenModal;
