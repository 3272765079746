// Copyright © 2023 CATTLEytics Inc.

import React, { useState } from 'react';
import { PlaceholderButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import ButtonEdit from '../../common/components/ButtonEdit';
import Page from '../../common/components/Page';
import TitlePlaceholder from '../../common/components/placeholders/TitlePlaceholder';
import { AnimalEventType } from '../../common/enums';
import { api } from '../../common/utilities';
import BreedingTable from '../../events/components/BreedingTable';
import { ApiResourceV1, HttpMethod, QueryKey, Sire } from '../../shared';
import SireModal from './SireModal';
import SireStats from './SireStats';

interface RouteParams {
  sireId: string;
}

/**
 * Sire detail component.
 */
const SireCard = (): JSX.Element => {
  const { sireId } = useParams<RouteParams>();

  const { t } = useTranslation();

  const [sireModalVisible, setSireModalVisible] = useState<boolean>(false);

  const query = useQuery<Sire | undefined>([QueryKey.Sires, sireId], () =>
    api<Sire | undefined>(HttpMethod.Get, `${ApiResourceV1.Sires}/${parseInt(sireId)}`),
  );

  const sire = query.data;

  return (
    <Page
      breadcrumbs={[{ label: 'Sires', to: '/sires' }]}
      buttons={
        query.isLoading ? (
          <PlaceholderButton style={{ width: '8rem' }} xs={2} />
        ) : (
          <ButtonEdit
            onClick={(): void => {
              setSireModalVisible(true);
            }}
          />
        )
      }
      documentTitle={sire ? sire.name : ''}
      title={sire ? sire.name : ''}
      titleLoading={query.isLoading}
      titlePlaceholder={<TitlePlaceholder />}
    >
      <SireStats sireId={parseInt(sireId)} />

      <h2>{t('sireCard|breedingTableTitle')}</h2>
      <BreedingTable eventTypeIds={[AnimalEventType.Bred]} sireId={parseInt(sireId)} />

      {sireModalVisible && (
        <SireModal editId={Number(sireId)} onClose={(): void => setSireModalVisible(false)} />
      )}
    </Page>
  );
};

export default SireCard;
