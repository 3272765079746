// Copyright © 2023 CATTLEytics Inc.

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props extends WithTranslation {
  tooltip?: string;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
}

/**
 * Displays an info icon with a tooltip on hover.
 */
const InfoTooltip = (props: React.PropsWithChildren<Props>): JSX.Element => {
  return (
    <OverlayTrigger overlay={<Tooltip>{props.tooltip}</Tooltip>} placement={props.tooltipPlacement}>
      {({ ref, ...triggerHandler }): ReactNode => (
        <span ref={ref} {...triggerHandler}>
          <FontAwesomeIcon
            icon={faInfoCircle}
            style={{ fontSize: '0.8rem', marginLeft: '0.25rem' }}
          />
        </span>
      )}
    </OverlayTrigger>
  );
};

export default withTranslation()(InfoTooltip);
