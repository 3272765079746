// Copyright © 2024 CATTLEytics Inc.

/**
 * WARNING!
 *
 * DO NOT EDIT THIS FILE
 *
 * CHANGES WILL BE OVERWRITTEN!
 *
 * Make changes in packages/shared and run npm run copy-shared-to-packages
 */

export enum RequestSwapStatus {
  Approved = 'approved',
  Pending = 'pending',
  Rejected = 'rejected',
  Unknown = 'unknown',
}
