// Copyright © 2023 CATTLEytics Inc.

import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import cattleyticsLogo from '../../assets/img/cattleytics-logo-transparent.png';
import bg from '../../assets/img/splash.jpg';
import Page from '../common/components/Page';
import { ChoosePlan } from './ChoosePlan';
import { CompleteWaiting } from './CompleteWaiting';
import { OnboardingProgress } from './OnboardingProgress';
import { Payment } from './Payment';
import { SignUpForm } from './SignUpForm';

interface RouteParams {
  stepName?: string;
}

enum Step {
  Complete = 'complete',
  Payment = 'payment',
  Plan = 'plan',
}
/**
 * Routable component to allow a user to sign up for a new site.
 */
export const SignUpPage: FC = (): JSX.Element => {
  const history = useHistory();
  const { stepName } = useParams<RouteParams>();
  const { t } = useTranslation();

  const [stepNumber, setStepNumber] = useState<number>(1);
  const [stripeClientSecret, setStripeClientSecret] = useState<string>();
  const [stripeSubscriptionId, setStripeSubscriptionId] = useState<string>();
  const [promoCode, setPromoCode] = useState<string>();

  useEffect(() => {
    if (stepName) {
      switch (stepName) {
        case Step.Plan:
          setStepNumber(2);
          break;
        case Step.Payment:
          setStepNumber(3);
          break;
        case Step.Complete:
          setStepNumber(4);
          break;
        default:
          setStepNumber(1);
          break;
      }
    }
  }, [stepName]);

  return (
    <div
      className={'vh-100 d-flex justify-content-center align-items-center'}
      style={{
        background: `url(${bg}) center center no-repeat`,
        backgroundSize: 'cover',
      }}
    >
      <Page className={'col-md-8'} hideBreadcrumbs={true} style={{ height: 'auto' }} title={''}>
        <div className={'d-flex justify-content-center'}>
          <img alt={'CATTLEytics'} src={cattleyticsLogo} style={{ width: '300px' }} />
        </div>
        <h1 className={'mt-3 mb-5 text-center'} style={{ textTransform: 'initial' }}>
          {stepNumber === 1 && t(`signUpPage|step1Title`)}
          {stepNumber === 2 && t(`signUpPage|step2Title`)}
          {stepNumber === 3 && t(`signUpPage|step3Title`)}
          {stepNumber === 4 && t(`signUpPage|step4Title`)}
        </h1>
        <OnboardingProgress className={'mt-3 mb-5 d-none d-lg-flex'} stepNumber={stepNumber} />
        {stepNumber === 1 && (
          <SignUpForm
            onComplete={(): void => {
              history.push('/sign-up/plan');
              setStepNumber(2);
            }}
          />
        )}
        {stepNumber === 2 && (
          <ChoosePlan
            onComplete={(subscriptionId, clientSecret, promoCode): void => {
              setStripeClientSecret(clientSecret);
              setStripeSubscriptionId(subscriptionId);
              setPromoCode(promoCode);

              // if we have a promo code then we skip payment (because we only allow free trial promo codes)
              if (promoCode) {
                // skip payment
                history.push('/sign-up/complete');
                setStepNumber(4);
              } else {
                history.push('/sign-up/payment');
                setStepNumber(3);
              }
            }}
          />
        )}
        {stepNumber === 3 && (
          <Payment
            promoCode={promoCode}
            stripeClientSecret={stripeClientSecret}
            stripeSubscriptionId={stripeSubscriptionId}
          />
        )}
        {stepNumber === 4 && <CompleteWaiting />}
      </Page>
    </div>
  );
};
