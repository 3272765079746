// Copyright © 2023 CATTLEytics Inc.

import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import Spinner from '../../common/components/Spinner';
import { BreedingProgram as BreedingProgramEntity, BreedingProgramType } from '../../shared';
import BreedingProgram from './BreedingProgram';
import BreedingTimelineStep from './BreedingTimelineStep';
import BreedingTimelineStepAdd from './BreedingTimelineStepAdd';

/**
 * @description Defines the input properties for the BreedingTimeline component.
 * @interface Props
 */
interface Props {
  /**
   * @description Breeding program types.
   * @type {BreedingProgramType[]}
   * @memberof Props
   */
  breedingProgramTypes: BreedingProgramType[];

  /**
   * @description Breeding programs.
   * @type {BreedingProgramEntity[]}
   * @memberof Props
   */
  breedingPrograms: BreedingProgramEntity[];

  /**
   * @description Component busy indicator.
   * @type {boolean}
   * @memberof Props
   */
  busy?: boolean;

  /**
   * @description Callback when add program is clicked.
   * @memberof Props
   */
  onAddProgram?: () => void;

  /**
   * @description Callback when delete program is clicked.
   * @memberof Props
   */
  onDeleteProgram: (programId: number) => void;

  /**
   * @description Callback when a program is drag and dropped.
   * @memberof Props
   */
  onDropProgram: (result: DragEndEvent) => void;

  /**
   * @description Callback when duplicate program button is clicked.
   * @memberof Props
   */
  onDuplicateProgram: (breedingProgram: BreedingProgramEntity) => void;

  /**
   * @description Callback when save program button is clicked.
   * @memberof Props
   */
  onSaveProgram: (breedingProgram: BreedingProgramEntity) => void;
}

/**
 * @description Breeding timeline list root component.
 * @function BreedingTimeline
 */
const BreedingTimeline = (props: Props): JSX.Element => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isDraggingId, setIsDraggingId] = useState<number | undefined>(undefined);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  if (props.busy) {
    return <Spinner />;
  }

  // if (props.breedingPrograms.length === 0) {
  //   return (
  //     <Row>
  //       <Col>{t('There are no programs defined yet.')}</Col>
  //     </Row>
  //   );
  // }

  const programIds = props.breedingPrograms.map((program) => `${program.id}`);

  let lastProgramIndex = -1;

  const programs = props.breedingPrograms.map((program: BreedingProgramEntity, index: number) => {
    lastProgramIndex = index;
    const isOver = program.id === isDraggingId;
    return (
      <Col className="mb-5" key={index} lg={6} md={12} xl={4}>
        <BreedingTimelineStep
          isDragging={isDragging}
          isOver={isOver}
          key={index}
          step={index + 1}
          stepLabel={`DIM: ${program.dim}`}
        >
          <BreedingProgram
            breedingProgram={program}
            breedingProgramTypes={props.breedingProgramTypes}
            index={index}
            isOver={isOver}
            onDeleteProgram={props.onDeleteProgram}
            onDuplicateProgram={props.onDuplicateProgram}
            onSaveProgram={props.onSaveProgram}
          />
        </BreedingTimelineStep>
      </Col>
    );
  });

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={(event: DragEndEvent): void => {
        props.onDropProgram(event);
        setIsDragging(false);
        setIsDraggingId(undefined);
      }}
      onDragOver={(event: DragOverEvent): void => {
        setIsDraggingId(event.over ? parseInt(event.over.id) : undefined);
      }}
      onDragStart={(): void => setIsDragging(true)}
      sensors={sensors}
    >
      <SortableContext items={programIds}>
        <Row>
          {programs}
          <BreedingTimelineStepAdd onAddProgram={props.onAddProgram} step={lastProgramIndex + 2} />
        </Row>
      </SortableContext>
    </DndContext>
  );
};

export default BreedingTimeline;
