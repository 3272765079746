// Copyright © 2023 CATTLEytics Inc.

import { resolve } from 'inversify-react';
import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';

import { TYPES } from '../../../types';
import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import Button, { ButtonVariant } from '../../common/components/Button';
import Modal from '../../common/components/Modal';
import type Logger from '../../logger/logger';
import { BreedingProgram, BreedingProgramType } from '../../shared';

/**
 * Defines the input properties for the BreedingProgramCreateModal component.
 */
export interface Props extends WithTranslation {
  /**
   * The breeding program (if we are editing).
   */
  breedingProgram?: BreedingProgram;

  /**
   * The breeding program types.
   */
  breedingProgramTypes: BreedingProgramType[];

  /**
   * The next available DIM.
   */
  nextDim?: number;

  /**
   * Callback when modal is closed
   */
  onClose: () => void;

  /**
   * callback to save a breeding program.
   */
  onSaveProgram: (breedingProgram: BreedingProgram) => void;
}

/**
 * Describes the state of the @see BreedingProgramCreateModal component.
 */
export interface State {
  /**
   * The breeding program we will be creating.
   */
  breedingProgram: BreedingProgram;

  /**
   * Whether or not we are in the process of making an API call.
   */
  busy: boolean;

  /**
   * An error message.
   */
  errorMessage?: string;

  /**
   * Whether or not the form in this modal is valid.
   */
  validated: boolean;
}

/**
 * Breeding programs create modal component.
 */
class BreedingProgramCreateModal extends React.Component<Props, State> {
  /**
   * Creates an instance of BreedingProgramCreateModal.
   * @param {Props} props The component's input properties and WithTranslation props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      breedingProgram: {
        id: 0,
        name: '',
        breedingPlanId: 0,
        dim: 0,
        duration: 0,
        createdDate: new Date(),
        modifiedDate: new Date(),
        isDeleted: false,
      },
      busy: false,
      validated: false,
    };
  }

  @resolve(TYPES.logger)
  private logger!: Logger;

  /**
   * Set the breeding plan and breeding program type ID's
   *   when component mounts.
   * @return {void}
   */
  public componentDidMount(): void {
    if (this.props.breedingProgram) {
      const breedingProgram: BreedingProgram = { ...this.props.breedingProgram };

      this.setState({ breedingProgram: breedingProgram });
      return;
    }

    const breedingProgram: BreedingProgram = { ...this.state.breedingProgram };

    // set the default breeding program type here since we only update the
    // breeding program state object on input
    breedingProgram.breedingProgramTypeId = this.props.breedingProgramTypes[0].id;

    if (this.props.nextDim !== undefined) {
      breedingProgram.dim = this.props.nextDim;
    }

    this.setState({ breedingProgram: breedingProgram });
  }

  /**
   * Renders the component.
   * @return {JSX.Element}
   */
  public render(): JSX.Element {
    return (
      <React.Fragment>
        <Modal
          onClose={(): void => this.props.onClose()}
          size={'lg'}
          title={
            this.props.breedingProgram
              ? this.props.t('Edit Breeding Program')
              : this.props.t('Create Breeding Program')
          }
          visible={true}
        >
          <Form noValidate={true} onSubmit={this.onFormSubmit} validated={this.state.validated}>
            <Form.Group className="mb-3" controlId="formBreedingProgramType">
              <Form.Label>{this.props.t('Breeding program type')}</Form.Label>
              <Form.Control
                aria-label={this.props.t('Breeding program type')}
                as={'select'}
                name="breedingProgramTypeId"
                onChange={this.onInputChange}
                placeholder={this.props.t('Choose a type')}
                required
                value={this.state.breedingProgram.breedingProgramTypeId}
              >
                {this.props.breedingProgramTypes.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.description}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {this.props.t('common|fieldRequiredFeedback')}
              </Form.Control.Feedback>
              <Form.Text className="text-muted">
                {this.props.t('breedingProgramCreateModal|breedingProgramTypeText')}
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBreedingProgramDuration">
              <Form.Label>{this.props.t('Breeding program duration')}</Form.Label>
              <Form.Control
                name="duration"
                onChange={this.onInputChange}
                placeholder={this.props.t('Enter a duration')}
                required
                type="number"
                value={this.state.breedingProgram.duration}
              />
              <Form.Text className="text-muted">
                {this.props.t('breedingProgramCreateModal|programLengthText')}
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {this.props.t('common|fieldRequiredFeedback')}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBreedingProgramDim">
              <Form.Label>{this.props.t('Breeding program DIM')}</Form.Label>
              <Form.Control
                name="dim"
                onChange={this.onInputChange}
                readOnly
                value={this.state.breedingProgram.dim}
              />
              <Form.Text className="text-muted">
                {this.props.t('breedingProgramCreateModal|programStartText')}
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBreedingProgramDescription">
              <Form.Label>{this.props.t('Breeding program description')}</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                onChange={this.onInputChange}
                placeholder={this.props.t('Enter a description')}
                rows={3}
                value={this.state.breedingProgram.description}
              />
              <Form.Text className="text-muted">
                {this.props.t('breedingProgramCreateModal|programDescriptionInstructions')}
              </Form.Text>
            </Form.Group>
            <AlertErrorForModal message={this.state.errorMessage} />
            <div className="modal-footer modal-footer-in-form">
              <Button
                ariaLabel={this.props.t('Cancel')}
                disabled={this.state.busy}
                onClick={(): void => this.props.onClose()}
                variant={ButtonVariant.Secondary}
              >
                {this.props.t('Cancel')}
              </Button>
              {this.props.breedingProgram ? (
                <Button
                  ariaLabel={this.props.t('Edit Breeding Program')}
                  busy={this.state.busy}
                  type="submit"
                >
                  {this.props.t('Save')}
                </Button>
              ) : (
                <Button
                  ariaLabel={this.props.t('Create Breeding Program')}
                  busy={this.state.busy}
                  type="submit"
                >
                  {this.props.t('Create')}
                </Button>
              )}
            </div>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }

  /**
   * @description Handles input changes on form elements.
   * @param {ChangeEvent<HTMLInputElement>} event The input element that has changed.
   * @returns {void}
   * @memberOf BreedingProgramCreateModal
   */
  private onInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const breedingProgram: BreedingProgram = { ...this.state.breedingProgram };
    breedingProgram[event.target.name] = event.target.value;
    this.setState({ breedingProgram: breedingProgram });
  };

  /**
   * @description Handles submission of the program create modal form.
   * @param {React.FormEvent<HTMLFormElement>} event The form that was submitted.
   * @returns {Promise<void>}
   * @memberOf BreedingProgramCreateModal
   */
  private onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    this.setState({ validated: true });

    if (!valid) {
      return;
    }

    const breedingProgram = this.state.breedingProgram;

    if (typeof breedingProgram.breedingProgramTypeId === 'string') {
      breedingProgram.breedingProgramTypeId = parseInt(
        breedingProgram.breedingProgramTypeId as string,
      );
    }

    breedingProgram.breedingProgramType = this.props.breedingProgramTypes.find(
      (type) => type.id === breedingProgram.breedingProgramTypeId,
    );

    this.setState({ busy: true, errorMessage: '' });
    try {
      await this.props.onSaveProgram(breedingProgram);
      this.setState({ busy: false });
      this.props.onClose();
    } catch (err) {
      this.logger.error('Breeding program failed to be created.', err, breedingProgram);
      this.setState({
        errorMessage: this.props.t('breedingProgramCreateModal|programCreateError'),
        busy: false,
      });
    }
  };
}

export default withTranslation()(BreedingProgramCreateModal);
