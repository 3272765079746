// Copyright © 2023 CATTLEytics Inc.

import './NavBar.scss';

import MD5 from 'crypto-js/md5';
import { useInjection } from 'inversify-react';
import React, { MouseEventHandler, useContext, useEffect, useState } from 'react';
import {
  Button,
  Button as ButtonBootstrap,
  Container,
  Dropdown,
  Nav,
  Navbar as NavbarBootstrap,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaChalkboardTeacher } from 'react-icons/fa';
import { useMutation, useQuery } from 'react-query';
import { Link, NavLink } from 'react-router-dom';

import cattleyticsLogoDark from '../../../assets/img/cattleytics-logo-dark-with-tagline.png';
import { TYPES } from '../../../types';
import SiteSwitcherModal from '../../auth/components/SiteSwitcherModal';
import LogoEnvironmentBadge from '../../common/components/LogoEnvironmentBadge';
import AuthService from '../../common/services/authService';
import AuthContext from '../../common/store/auth-context';
import {
  IconCommandLine,
  IconEdit,
  IconHelp,
  IconLink,
  IconMenu,
  IconPassword,
  IconSearch,
  IconSettings,
  IconSignOut,
  IconSwitcher,
  IconWhiteboard,
  isExtraLarge,
  isSiteAdminOrAbove,
} from '../../common/utilities';
import { api } from '../../common/utilities';
import { GettingStartedModal } from '../../onboarding/GettingStartedModal';
import { ApiResourceV1, Configuration, HttpMethod, QueryKey, User } from '../../shared';
import AppLinkModal from '../../users/components/AppLinkModal';
import UserEditProfileModal from '../../users/components/UserEditProfileModal';
import UserPasswordChangeModal from '../../users/components/UserPasswordChangeModal';
import { ChatbotModal } from './ChatbotModal';
import CommandSearchInput from './CommandSearchInput';
import NavBarNotifications from './NavBarNotifications';

interface Props {
  /**
   * Callback when nav is toggled.
   */
  onNavToggle: MouseEventHandler<HTMLButtonElement>;

  /**
   * Callback when sign out is triggered.
   */
  onSignOut: () => void;
}

/**
 * Top navigation.
 */
const NavBar = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const [searchFormVisible, setSearchFormVisible] = useState<boolean>(false);
  const [searchMode, setSearchMode] = useState<boolean>(true);
  const [siteSwitchModalVisible, setSiteSwitchModalVisible] = useState<boolean>(false);
  const [appLinkModalVisible, setAppLinkModalVisible] = useState<boolean>(false);
  const [userEditProfileModalVisible, setUserEditProfileModalVisible] = useState<boolean>(false);
  const [gettingStartedModalVisible, setGettingStartedModalVisible] = useState<boolean>(false);
  const [userPasswordChangeModalVisible, setUserPasswordChangeModalVisible] =
    useState<boolean>(false);
  const [showChatbot, setShowChatbot] = useState<boolean>(false);

  const chatbotQuery = useQuery([QueryKey.Settings, 'AI'], () =>
    api<Configuration[]>('GET', ApiResourceV1.Configuration),
  );

  const chatbotEnabled = chatbotQuery.isLoading
    ? false
    : ((chatbotQuery.data ?? []).find((config) => config.path === 'AI/Chatbot')?.value ?? false) ===
      'true';

  // get the user's profile
  const userQuery = useQuery<User>([QueryKey.Users], () =>
    api<User>(HttpMethod.Get, `${ApiResourceV1.Users}/${auth.userId}`),
  );

  // show the getting started modal if enabled in user profile
  useEffect(() => {
    if (userQuery.data) {
      if (userQuery.data.showGettingStartedModal) {
        setGettingStartedModalVisible(true);
      }
    }
  }, [userQuery.data]);

  // @TODO move to own component
  const notifications = <NavBarNotifications />;

  const authService = useInjection<AuthService>(TYPES.authService);

  // check if user password change flag is set, then always set modal to appear
  const query = useQuery<User>(
    [QueryKey.Users, auth.userId],
    () => api('GET', `v1/users/${auth.userId}`),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: true,
    },
  );

  useEffect(() => {
    if (query.data) {
      if (
        userPasswordChangeModalVisible !== query.data.passwordChangeRequired &&
        query.data.passwordChangeRequired === true
      ) {
        setUserPasswordChangeModalVisible(query.data.passwordChangeRequired);
      }
    }
  }, [query, userPasswordChangeModalVisible]);

  const signOutMutation = useMutation(() => authService.delete(1), {
    onSuccess: () => props.onSignOut(),
  });

  const signOut = async (): Promise<void> => {
    await signOutMutation.mutateAsync();
  };

  const onOpenChatbot = (): void => {
    setShowChatbot((prev) => !prev);
    // alert('hi');
  };

  return (
    <>
      <NavbarBootstrap
        bg={'dark'}
        className={'nav-nav-bar text-light'}
        collapseOnSelect
        expand={false}
        sticky={'top'}
        variant="light"
      >
        <Container className={'nav-container pe-sm-1'} fluid={true}>
          {!isExtraLarge() && (
            <ButtonBootstrap
              className={'rounded-circle'}
              onClick={(e): void => props.onNavToggle(e)}
              variant={'link'}
            >
              <IconMenu size={'1rem'} />
            </ButtonBootstrap>
          )}

          <NavbarBootstrap.Brand
            as={Link}
            className={'mx-auto my-n5 text-light d-xl-none'}
            style={{ position: 'relative', margin: '0px 0', padding: '0' }}
            to={'/dashboard'}
          >
            <img alt={'CATTLEytics'} src={cattleyticsLogoDark} style={{ height: '22px' }} />
            <LogoEnvironmentBadge fontSize={'8px'} />
          </NavbarBootstrap.Brand>

          <Nav className="mx-auto col-lg-5 my-auto form-nav">
            <CommandSearchInput searchMode={searchMode} />
          </Nav>

          {/* Mobile/small only */}
          <div className={'me-auto d-none d-sm-flex'}>
            <Link to={'/help'}>
              <ButtonBootstrap className={'rounded-circle me-3'} variant={'link'}>
                <IconHelp />
              </ButtonBootstrap>
            </Link>
            <Link to={'/whiteboard'}>
              <Button className={'rounded-circle me-3'} variant={'link'}>
                <IconWhiteboard />
              </Button>
            </Link>
            {chatbotEnabled && (
              <Button className={'rounded-circle me-3'} onClick={onOpenChatbot} variant={'link'}>
                <FaChalkboardTeacher />
              </Button>
            )}
            <Dropdown>
              <Dropdown.Toggle
                as={ButtonBootstrap}
                className={'rounded-circle navbar-top-round-button'}
                variant={'link'}
              >
                <IconSettings />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as={NavLink} to="/lists">
                  {t('Lists')}
                </Dropdown.Item>
                {isSiteAdminOrAbove(auth) && (
                  <Dropdown.Item as={NavLink} to="/settings/general">
                    {t('Settings')}
                  </Dropdown.Item>
                )}
                {isSiteAdminOrAbove(auth) && (
                  <Dropdown.Item as={NavLink} to="/import">
                    {t('Data Import')}
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <Nav className="me-auto" />
          <div className={'d-flex'}>
            <ButtonBootstrap
              className={'rounded-circle m-auto d-md-none me-2'}
              onClick={(): void => {
                if (!searchFormVisible) {
                  setSearchFormVisible(true);
                } else {
                  setSearchFormVisible(false);
                }
                setSearchMode(true);
              }}
              variant={'link'}
            >
              <IconSearch className={'icon m-auto'} />
            </ButtonBootstrap>
            <ButtonBootstrap
              className={'rounded-circle m-auto d-md-none'}
              onClick={(): void => {
                if (!searchFormVisible) {
                  setSearchFormVisible(true);
                } else {
                  setSearchFormVisible(false);
                }
                setSearchMode(false);
              }}
              variant={'link'}
            >
              <IconCommandLine className={'icon m-auto'} />
            </ButtonBootstrap>
            <Nav activeKey="/home" className="justify-content-end">
              {notifications}
            </Nav>
          </div>

          <div className="d-none d-sm-block ms-2" />

          <Dropdown className={'d-none d-sm-block'}>
            <Dropdown.Toggle
              as={ButtonBootstrap}
              className={'rounded-circle navbar-top-round-button'}
              variant={'link'}
            >
              {auth.email && (
                <img
                  alt={`${auth.firstName} ${auth.lastName}`}
                  className={'rounded-circle navbar-top-round-button'}
                  src={`https://www.gravatar.com/avatar/${MD5(auth.email)}?d=wavatar`}
                  style={{ height: '32px' }}
                />
              )}
            </Dropdown.Toggle>

            <Dropdown.Menu align={'end'}>
              <Dropdown.Item onClick={(): void => setUserEditProfileModalVisible(true)}>
                <IconEdit className={'me-1'} />
                {t('nav|navBar|profile')}
              </Dropdown.Item>
              <Dropdown.Item onClick={(): void => setUserPasswordChangeModalVisible(true)}>
                <IconPassword className={'me-1'} />
                {t('nav|navBar|password')}
              </Dropdown.Item>
              <Dropdown.Item onClick={(): void => setSiteSwitchModalVisible(true)}>
                <IconSwitcher className={'me-1'} />
                {t('nav|navBar|site')}
              </Dropdown.Item>
              <Dropdown.Item onClick={(): void => setAppLinkModalVisible(true)}>
                <IconLink className={'me-1'} />
                {t('nav|navBar|apps')}
              </Dropdown.Item>
              <Dropdown.Item eventKey="misc-sign-out" onClick={signOut}>
                <IconSignOut className={'me-1'} />
                {t('nav|navBar|signout')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Nav className="me-3" />
        </Container>

        {searchFormVisible && (
          <Nav className="mx-auto mt-3 col-10 my-auto d-md-none">
            <CommandSearchInput searchMode={searchMode} />
          </Nav>
        )}

        {siteSwitchModalVisible && (
          <SiteSwitcherModal onClose={(): void => setSiteSwitchModalVisible(false)} />
        )}
        {userPasswordChangeModalVisible && (
          <UserPasswordChangeModal onClose={(): void => setUserPasswordChangeModalVisible(false)} />
        )}
        {userEditProfileModalVisible && (
          <UserEditProfileModal onClose={(): void => setUserEditProfileModalVisible(false)} />
        )}
        {appLinkModalVisible && (
          <AppLinkModal onClose={(): void => setAppLinkModalVisible(false)} />
        )}
        {gettingStartedModalVisible && (
          <GettingStartedModal
            onClose={async (): Promise<void> => {
              setGettingStartedModalVisible(false);
              await api(HttpMethod.Patch, `${ApiResourceV1.Users}/${auth.userId}`, {
                body: { showGettingStartedModal: false },
              });
            }}
          />
        )}
      </NavbarBootstrap>
      {showChatbot && <ChatbotModal onClose={(): void => setShowChatbot(false)} />}
    </>
  );
};

export default NavBar;
