// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { Col, Placeholder, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

/**
 * @description Placeholder component for stats.
 */
const CardStatsPlaceholder = (): JSX.Element => {
  return (
    <Placeholder animation="glow" className={'w-100'}>
      <Row>
        {[...Array(10).keys()].map((num) => (
          <Col key={`card-stats-${num}`} md={4}>
            <Placeholder size={'xs'} style={{ width: '33%' }} />
            <br />
            <Placeholder size={'lg'} style={{ width: '66%' }} />
          </Col>
        ))}
      </Row>
    </Placeholder>
  );
};

export default withTranslation()(CardStatsPlaceholder);
