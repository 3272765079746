// Copyright © 2023 CATTLEytics Inc.

/**
 * WARNING!
 *
 * DO NOT EDIT THIS FILE
 *
 * CHANGES WILL BE OVERWRITTEN!
 *
 * Make changes in packages/shared and run npm run copy-shared-to-packages
 */

export enum QueryKey {
  ActiveTreatments = 'ActiveTreatments',
  AnimalColors = 'AnimalColors',
  AnimalEventTypes = 'AnimalEventTypes',
  AnimalEvents = 'AnimalEvents',
  AnimalGroups = 'AnimalGroups',
  AnimalHealthCheckResults = 'AnimalHealthCheckResults',
  AnimalHealthChecks = 'AnimalHealthChecks',
  AnimalMeasure = 'AnimalMeasure',
  AnimalReferenceTypes = 'AnimalReferenceTypes',
  AnimalReferences = 'AnimalReferences',
  Animals = 'Animals',
  ApiVersion = 'ApiVersion',
  Auth = 'Auth',
  BirthStatuses = 'BirthStatuses',
  Breeds = 'Breeds',
  CqlQueries = 'CqlQueries',
  CqlQueriesMeta = 'CqlQueriesMeta',
  DairyLogs = 'DairyLogs',
  DaysDriedOff = 'DaysDriedOff',
  Diagnoses = 'Diagnoses',
  DiagnosisRegimeDetails = 'DiagnosisRegimeDetails',
  DiagnosisRegimes = 'DiagnosisRegimes',
  FourWeekMilk = 'FourWeekMilk',
  Genders = 'Genders',
  Jobs = 'Jobs',
  LayoutItems = 'LayoutItems',
  Layouts = 'Layouts',
  LocalTimezone = 'LocalTz',
  Mastitis = 'Mastitis',
  Notes = 'Notes',
  Notifications = 'Notifications',
  Organizations = 'Organizations',
  PenInsights = 'PenInsights',
  Pens = 'Pens',
  ProductAdministrations = 'ProductAdministrations',
  ProductClasses = 'ProductClasses',
  ProductTypes = 'ProductTypes',
  Products = 'Products',
  ReportBredOutcome = 'ReportBredOutcome',
  ReportChronicIssues = 'ReportChronicIssues',
  ReportDimUpToEvent = 'ReportDimUpToEvent',
  ReportEcmQuantity = 'ReportEcmQuantity',
  ReportFertility = 'ReportFertility',
  ReportGenetics = 'ReportGenetics',
  ReportInventory = 'ReportInventory',
  ReportModelEcmQuantity = 'ReportModelEcmQuantity',
  ReportRepro = 'ReportRepro',
  ReportSisters = 'ReportSisters',
  Reports = 'Reports',
  ReproStatuses = 'ReproStatuses',
  Roles = 'Roles',
  SccQuantity = 'SccQuantity',
  Settings = 'Settings',
  Shifts = 'Shifts',
  SignUp = 'SignUp',
  Sires = 'Sires',
  Sites = 'Sites',
  Stripe = 'Stripe',
  StripeProducts = 'StripeProducts',
  TaskCategories = 'TaskCategories',
  Tasks = 'Tasks',
  TestData = 'TestData',
  UserSchedules = 'UserSchedules',
  UserSchedulesPendingRequests = 'UserSchedulesPendingRequests',
  Users = 'Users',
  WebReleaseNotes = 'WebReleaseNotes',
  WebVersion = 'WebVersion',
}
