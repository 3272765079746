// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { Spinner } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IconType } from 'react-icons';

import Button from '../../common/components/Button';

/**
 * Defines the input properties for this component.
 */
interface Props extends WithTranslation {
  // Whether the button is in a busy state
  busy?: boolean;

  // Whether the button should be disabled.
  disabled?: boolean;

  // Icon to display in button
  icon?: IconType;

  // Button text for accessibility.
  label?: string;

  // On click callback.
  onClick?: () => void;

  // HTML button type like "submit"
  type?: 'button' | 'submit' | 'reset';

  // Button style variant
  variant?: string;
}

/**
 * A button component for use in card style areas like a notes list on an animal card.
 */
const ButtonCardAction = (props: Props): JSX.Element => {
  const Icon = props.icon as IconType;
  return (
    <Button
      aria-label={props.label}
      className={`text-nowrap`}
      disabled={props.disabled}
      onClick={props.onClick}
      style={{ padding: 0, margin: 0, lineHeight: '1rem' }}
      type={props.type ?? 'button'}
      variant={'link'}
    >
      {props.busy ? (
        <>
          <Spinner animation="border" aria-hidden="true" as="span" role="status" size="sm" />
          <span className="visually-hidden">Loading...</span>
        </>
      ) : (
        <>{props.icon ? <Icon style={{ width: '24px', height: '24px' }} /> : null}</>
      )}
    </Button>
  );
};

export default withTranslation()(ButtonCardAction);
