// Copyright © 2024 CATTLEytics Inc.

import { flatten } from 'lodash';
import { useCallback, useMemo } from 'react';
import { Dropdown, Form, FormCheckProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { ButtonVariant } from './Button';

export type ChildOption = { id: number; label: string };
export type GroupedOption = { id: number; items: ChildOption[]; label: string };
type Value = GroupedOption['id'];

/**
 * Verify that all child items are selected.
 */
function hasAllChildrenSelected(
  groupId: number,
  values: Value[],
  allValues: GroupedOption[],
): boolean {
  const possibleGroup = allValues.find((g) => g.id === groupId);
  return possibleGroup?.items.every((item) => values.includes(item.id)) ?? false;
}

export type GroupedCheckboxDropdownProps = {
  label: string;
  onChange: (values: Value[]) => void;
  options: GroupedOption[];
  values: Value[];
};

/**
 * Checkbox drop down with groups.
 * Group checkboxes select/unselect all items within the group.
 */
const GroupedCheckboxDropdown = ({
  label,
  options,
  values,
  onChange,
}: GroupedCheckboxDropdownProps): JSX.Element => {
  const { t } = useTranslation();
  const allChildOptions = useMemo(() => flatten(options.map((group) => group.items)), [options]);
  const selectAll = useMemo(
    () => allChildOptions.length === values.length,
    [allChildOptions, values],
  );

  const handleChecked = useCallback<Exclude<FormCheckProps['onChange'], undefined>>(
    (event): void => {
      const newValues = event.target.checked
        ? [...values, Number(event.target.id)]
        : values.filter((id) => id !== Number(event.target.id));

      onChange(newValues);
    },
    [values, onChange],
  );

  const handleGroupChecked = useCallback<Exclude<FormCheckProps['onChange'], undefined>>(
    (event): void => {
      // select all items under the group id
      const groupItem = options.find((g) => g.id === Number(event.target.id));
      if (groupItem) {
        const groupItemIds = flatten(groupItem.items.map((i) => i.id));

        const newValues = event.target.checked
          ? [...values, ...groupItemIds]
          : values.filter((id) => !groupItemIds.includes(Number(id)));

        onChange(newValues);
      }
    },
    [options, values, onChange],
  );

  const handleSelectAll = useCallback<Exclude<FormCheckProps['onChange'], undefined>>(
    (event): void => {
      const allChildrenIds = allChildOptions.map((i) => i.id);
      onChange(event.target.checked ? allChildrenIds : []);
    },
    [allChildOptions, onChange],
  );

  return (
    <Dropdown>
      <Dropdown.Toggle id="dropdown-basic" variant={ButtonVariant.OutlinePrimary}>
        {label}
      </Dropdown.Toggle>

      <Dropdown.Menu className="px-2 py-1 w-100 overflow-y-auto" style={{ height: 330 }}>
        <Form.Check
          checked={selectAll}
          className="py-1"
          label={t('Select All')}
          onChange={handleSelectAll}
          type="checkbox"
        />
        {options.map((group) => {
          return (
            <div key={`gcd_${group.id}`}>
              <Form.Check
                checked={hasAllChildrenSelected(group.id, values, options)}
                className="py-1"
                id={String(group.id)}
                key={group.id}
                label={t(group.label)}
                onChange={handleGroupChecked}
                style={{ fontWeight: 'bold' }}
                type="checkbox"
              />
              {group.items.map((option) => (
                <Form.Check
                  checked={values.includes(option.id)}
                  className="py-1 mx-3"
                  id={String(option.id)}
                  key={`${group.id}_${option.id}`}
                  label={t(option.label)}
                  onChange={handleChecked}
                  type="checkbox"
                />
              ))}
            </div>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default GroupedCheckboxDropdown;
