// Copyright © 2023 CATTLEytics Inc.
import { FC, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BsCheck2Circle, BsSticky } from 'react-icons/bs';

import CardButton from '../../common/components/CardButton';
import Modal from '../../common/components/Modal';
import NoteModal from '../../notes/components/NoteModal';
import { TaskModal } from '../../tasks/components/TaskModal';

interface Props {
  /**
   * Callback when modal is closed.
   */
  onClose: () => void;
}

const WhiteboardAddNewModal: FC<Props> = (props) => {
  const { t } = useTranslation();

  const [noteModalVisible, setNoteModalVisible] = useState(false);
  const [taskModalVisible, setTaskModalVisible] = useState(false);

  return (
    <Modal onClose={props.onClose} size={'lg'} title={'New'} visible={true}>
      <div className={'new-modal show-grid'}>
        <Row md={2}>
          <Col>
            <CardButton
              icon={<BsCheck2Circle style={{ height: '100%', width: 'auto' }} />}
              label={t('Task')}
              onClick={(): void => setTaskModalVisible(true)}
            />
          </Col>
          <Col>
            <CardButton
              icon={<BsSticky style={{ height: '100%', width: 'auto' }} />}
              label={t('Note')}
              onClick={(): void => setNoteModalVisible(true)}
            />
          </Col>
        </Row>
      </div>

      {noteModalVisible && (
        <NoteModal
          onClose={(): void => {
            props.onClose();
            setNoteModalVisible(false);
          }}
          tags={['whiteboard']}
        />
      )}
      {taskModalVisible && (
        <TaskModal
          onClose={(): void => {
            props.onClose();
            setTaskModalVisible(false);
          }}
        />
      )}
    </Modal>
  );
};

export default WhiteboardAddNewModal;
