// Copyright © 2023 CATTLEytics Inc.

import { inject, injectable } from 'inversify';

import { TYPES } from '../../../types';
import type Logger from '../../logger/logger';
import { Task } from '../../shared';
import Api2Service from './api2Service';
import DataService from './dataService';

const path = '/v1/tasks';

/**
 * Service for managing tasks.
 */
@injectable()
export default class TaskService implements DataService<Task> {
  private logger: Logger;

  private apiService: Api2Service;

  /**
   * Creates an instance of TaskService.
   *
   * @param logger Logger instance.
   * @param apiService API Service instance
   */
  constructor(
    @inject(TYPES.logger) logger: Logger,
    @inject(TYPES.api2Service) apiService: Api2Service,
  ) {
    this.logger = logger;
    this.apiService = apiService;
  }

  /**
   * Retrieve a list of Animal Tasks
   * @return Task objects
   */
  async list(params?: Record<string, string>): Promise<Task[]> {
    const result = await this.apiService.get<Task[]>(path, params);
    if (!result) {
      return [];
    }

    const data = result as Task[];
    data.forEach((item) => this.cast(item));
    return data;
  }

  async get(id: number, params?: Partial<Task>): Promise<Task | undefined> {
    const result = await this.apiService.get<Task>(`${path}/${id}`, params);
    if (!result) {
      return undefined;
    }

    const data = result as Task;
    this.cast(data);
    return data;
  }

  async post(attributes: Partial<Task>): Promise<Task | undefined> {
    const result = await this.apiService.post<Task>(`${path}`, attributes);
    if (!result) {
      return undefined;
    }
    this.cast(result as Task);
    return result;
  }

  async patch(id: number, attributes: Partial<Task>): Promise<Task | undefined> {
    // make sure record identifier is included
    attributes.id = id;

    const result = await this.apiService.patch(`${path}/${id}`, attributes);
    if (!result) {
      return undefined;
    }

    const data = result as Task;
    this.cast(data);
    return data;
  }

  async delete(id: number): Promise<null> {
    return await this.apiService.delete(`${path}/${id}`);
  }

  /**
   * Casts fields into javascript types.
   * @param item
   * @return cast object
   */
  cast(item: Task): void {
    if (!item) {
      return;
    }
  }
}
