// Copyright © 2024 CATTLEytics Inc.

import { secondsInYear } from 'date-fns';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { QueryKey } from '../../shared';
import { getEnv } from '../utilities/env';

export function useReleaseNotes(): {
  cookieVersion: string | undefined;
  isNewVersion: boolean;
  updateCookieVersion: () => void;
  webVersion: string | undefined;
} {
  const [cookies, setCookie] = useCookies();
  const { t } = useTranslation();

  const getWebVersion = async (): Promise<string> => {
    return getEnv('VERSION') ?? t('unknown');
  };

  const queryWebVersion = useQuery([QueryKey.WebVersion], () => getWebVersion(), {
    cacheTime: 60_000,
    refetchOnWindowFocus: false,
  });

  const webVersion = queryWebVersion?.data;
  const cookieVersion = cookies.version;

  const isNewVersion =
    cookies.version === undefined ||
    cookies.version === 'undefined' ||
    // if cookie version is set and web version is set but they don't match then show release notes modal
    (cookies.version &&
      cookies.version !== 'undefined' &&
      webVersion &&
      cookies.version !== webVersion);

  const updateCookieVersion = (): void => {
    setCookie('version', webVersion, { path: '/', maxAge: secondsInYear });
  };

  return { webVersion, cookieVersion, isNewVersion, updateCookieVersion };
}
