// Copyright © 2023 CATTLEytics Inc.

import React, { useContext, useState } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Button from '../common/components/Button';
import Modal from '../common/components/Modal';
import { useIsAuthUserSuperAdmin } from '../common/hooks';
import AuthContext from '../common/store/auth-context';
import { api } from '../common/utilities';
import { camelToTitleCase } from '../common/utilities/camelCase';
import { ApiResourceV1, HttpMethod, JobKey } from '../shared';

/**
 * Component input properties.
 */
interface Props {
  /**
   * Callback when modal is closed.
   */
  onClose: () => void;
}

interface JobDescription {
  description: string[];
  disabled: boolean;
  scopeGlobal: boolean;
  scopeSite: boolean;
  when: string;
}

const descriptions: Record<string, JobDescription> = {
  AnimalUpdate: {
    scopeSite: true,
    scopeGlobal: true,
    disabled: false,
    when: 'daily and after animal event import',
    description: [
      'Calculates animal status',
      'Calculates animal data: calving date, times bred, lactation number',
      'Calculates latest event',
      'Calculates animal age class',
      'Calculates animal days-in-milk',
      'Calculates animal milking status',
      'Calculates animal reproduction status',
      'Clears animal heat status if expired',
    ],
  },
  PenUpdate: {
    scopeSite: true,
    scopeGlobal: true,
    disabled: false,
    when: 'daily and after animal or animal event modification',
    description: ['Calculates summary data for each pen.'],
  },
  Startup: {
    scopeSite: true,
    scopeGlobal: true,
    disabled: false,
    when: 'This job runs on application startup.',
    description: [
      'Ensures all configuration keys are present.',
      'Re-indexes all data if no indexes are present.',
      'Ensures all users have a role in all of their sites.',
      'Ensures all search indexes have been created.',
    ],
  },
  RebuildAllIndexes: {
    scopeSite: true,
    scopeGlobal: true,
    disabled: false,
    when: 'This job runs on startup if no indexes are detected and daily.',
    description: ['Deletes all search indexes, creates new indexes and re-indexes all data.'],
  },
  IndexAnimal: {
    scopeSite: true,
    scopeGlobal: true,
    disabled: true,
    when: 'an animal is updated or a reindex is started.',
    description: ['Indexes an animal'],
  },
  IndexAnimals: {
    scopeSite: true,
    scopeGlobal: true,
    disabled: false,
    when: 'Daily after animal update job.',
    description: ['Indexes all animals for a specific site.'],
  },
  IndexAnimalEvent: {
    scopeSite: true,
    scopeGlobal: true,
    disabled: true,
    when: 'an animal Event is updated or a reindex is started.',
    description: ['Indexes an animal event'],
  },
  IndexAnimalEvents: {
    scopeSite: true,
    scopeGlobal: true,
    disabled: false,
    when: 'After animal event import',
    description: ['Indexes all animal events for a specific site.'],
  },
  ImportData: {
    scopeSite: true,
    scopeGlobal: true,
    disabled: true,
    when: 'This job will run automatically.',
    description: ['Imports a CSV file of animal, event or test data'],
  },
  ImportDataCleanupAnimalEvents: {
    scopeSite: true,
    scopeGlobal: true,
    disabled: false,
    when: 'This job runs after a successful import of animal events.',
    description: ['Cleans up data after an import.', 'Cleans up event date time zones.'],
  },
  ImportDataCleanupAnimals: {
    scopeSite: true,
    scopeGlobal: true,
    disabled: false,
    when: 'This job runs after a successful import.',
    description: [
      'Cleans up data after an import.',
      'Creates animal references.',
      'cleans up birth date time zones.',
    ],
  },
  TaskUpdate: {
    scopeSite: true,
    scopeGlobal: true,
    disabled: false,
    when: 'To clean up task data.',
    description: ['Re-parses mentions'],
  },
};

export const JobModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const auth = useContext(AuthContext);
  const isSuperAdmin = useIsAuthUserSuperAdmin();

  const startJob = async (name: JobKey, siteId?: number): Promise<void> => {
    setLoading(true);
    await api(HttpMethod.Post, ApiResourceV1.Jobs, {
      body: {
        siteId: siteId,
        name: name as unknown as string,
      },
    });
    setLoading(false);
  };
  return (
    <Modal onClose={props.onClose} size={'lg'} title={t(`Job List`)} visible={true}>
      <ListGroup>
        {Object.values(JobKey)
          .filter((key) => descriptions[key])
          .map((key) => (
            <ListGroupItem key={key}>
              <details>
                <summary style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <h3 style={{ paddingTop: '8px' }}>{camelToTitleCase(key)}</h3>
                  </div>
                  <div>
                    {descriptions[key] && (
                      <>
                        {descriptions[key].scopeSite && (
                          <Button
                            busy={loading}
                            disabled={descriptions[key].disabled || loading}
                            onClick={(): Promise<void> => startJob(key, auth.siteId)}
                            variant={'primary'}
                          >
                            {t('jobModal|runSiteButton')}
                          </Button>
                        )}{' '}
                        {isSuperAdmin && descriptions[key].scopeGlobal && (
                          <Button
                            busy={loading}
                            disabled={descriptions[key].disabled || loading}
                            onClick={(): Promise<void> => startJob(key)}
                            variant={'primary'}
                          >
                            {t('jobModal|runGlobalButton')}
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                </summary>
                <div className="ms-2 me-auto">
                  <h4 style={{ fontSize: '13px' }}>Description</h4>
                  {descriptions[key] && (
                    <>
                      <ul>
                        {descriptions[key].description.map((desc, index) => (
                          <li key={index}>{desc}</li>
                        ))}
                      </ul>
                      <h4 style={{ fontSize: '13px' }}>{t('Schedule')}</h4>
                      <p>{descriptions[key].when}</p>
                    </>
                  )}
                </div>
              </details>
            </ListGroupItem>
          ))}
      </ListGroup>
    </Modal>
  );
};
