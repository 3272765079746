// Copyright © 2023 CATTLEytics Inc.

import { Spinner } from 'react-bootstrap';
import { useIsFetching } from 'react-query';

import { QueryKey } from '../../../../shared';
import { useAnimalCardEvents } from '../provider';
import AnimalEventsDateRangePicker from './AnimalEventsDateRangePicker';
import EventTypesSelect from './EventTypesSelect';

function Toolbar(): JSX.Element {
  const { animalId } = useAnimalCardEvents();
  const fetchingAnimalEventsCount = useIsFetching({ queryKey: [QueryKey.AnimalEvents] });

  return (
    <div className="p-2 d-flex flex-column flex-md-row align-items-center justify-content-md-between flex-no-wrap">
      <AnimalEventsDateRangePicker />

      <div className="d-flex flex-row">
        {fetchingAnimalEventsCount > 0 && <Spinner className="mx-4" />}
        <div className="mt-3 mt-md-0">
          <EventTypesSelect animalId={animalId} />
        </div>
      </div>
    </div>
  );
}

export default Toolbar;
