// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import Konva from 'konva';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Form, Placeholder } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Image, Layer, Rect, Stage } from 'react-konva';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { TYPES } from '../../../types';
import Button, { ButtonVariant } from '../../common/components/Button';
import { SignedUrlType } from '../../common/enums';
import FileService from '../../common/services/fileService';
import PenService from '../../common/services/penService';
import SiteService from '../../common/services/siteService';
import AuthContext from '../../common/store/auth-context';
import { Coordinate, Region } from '../../common/types';
import { IconDelete, IconEdit, IconUpload } from '../../common/utilities';
import { QueryKey } from '../../shared/enums';
import AssignPenModal from './AssignPenModal';
import DebugShowActiveTool from './pen-map/DebugShowActiveTool';
import DebugShowMousePosition from './pen-map/DebugShowMousePosition';
import ImageUploadModal from './pen-map/ImageUploadModal';
import normalizeRegion from './pen-map/normalizePenRegion';
import PenGroup from './pen-map/PenGroup';
import { Tool } from './pen-map/PenMapTools';

const penLimit = 1000;

/**
 * Component input properties.
 */
interface Props {
  /**
   * Whether the component should display it's busy/loading indicator
   */
  busy?: boolean;

  // Additional class names to pass to the component.
  className?: string;

  // Show debug information.
  debug?: boolean;

  // ID of the site mapUrl
  mapId?: string;

  // Object storage key for map image
  mapUrl?: string;

  // Callback when map image is replaced (user uploads a new image)
  onMapReplaced?: (mapUrl: string) => void;

  // Canvas virtual height. All drawing is relative to this height dimension and
  // all is affected by scale.
  stageHeight?: number;

  // Canvas virtual width. All drawing is relative to this height dimension and
  // all is affected by scale.
  stageWidth?: number;
}

/**
 * Map component for creating pen regions.
 */
const PenMap = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const history = useHistory();

  const fileService = useInjection<FileService>(TYPES.fileService);
  const siteService = useInjection<SiteService>(TYPES.siteService);
  const penService = useInjection<PenService>(TYPES.penService);

  const queryClient = useQueryClient();

  const auth = useContext(AuthContext);
  const siteId = Number(auth.siteId);

  const mapRef = useRef<HTMLDivElement>(null);
  const stageRef = useRef<Konva.Stage>(null);
  const activeRegionLayerRef = useRef<Konva.Layer>(null);

  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const [imageUploadModalVisible, setImageUploadModalVisible] = useState<boolean>(false);
  const [regionModalVisible, setRegionModalVisible] = useState<boolean>(false);
  const [activeRegion, setActiveRegion] = useState<Region>({
    start: { x: 0, y: 0 },
    end: { x: 0, y: 0 },
  });
  const [bgImage, setBgImage] = useState<HTMLImageElement>();
  const [scale, setScale] = useState<number>(1);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [mousePosition, setMousePosition] = useState<Coordinate>({ x: 0, y: 0 });
  const [activeTool, setActiveTool] = useState<Tool>(Tool.CreateRectRegion);
  const [stageWidth] = useState<number>(1000);
  const [stageHeight, setStageHeight] = useState<number>(0);
  const [busy, setBusy] = useState<boolean>(false);
  // const [mapId, setMapId] = useState<string | undefined>(props.mapId);

  const query = useQuery(
    [QueryKey.Pens, penLimit],
    () => penService.list({ limit: String(penLimit) }),
    { keepPreviousData: true },
  );
  const pens = query.data;

  const debug = props.debug ?? false;

  // Set the scale of the stage based on the dimensions of the parent element
  const checkSize = useCallback((): void => {
    if (mapRef.current) {
      setScale(mapRef.current.offsetWidth / stageWidth); //  container width / scene width
    }
  }, [mapRef, stageWidth]);

  // Get a reference to the stage.
  const getStage = useCallback((): Konva.Stage => {
    const stage = stageRef.current;
    if (!stage) {
      throw Error('No reference set to stage yet.');
    }
    return stage;
  }, [stageRef]);

  // change settings when tool is changed
  const changeTool = useCallback(
    (tool?: Tool): void => {
      if (props.busy) {
        return;
      }
      switch (tool) {
        case Tool.CreateRectRegion:
          getStage().container().style.cursor = 'crosshair';
          setActiveTool(Tool.CreateRectRegion);
          break;
        case Tool.MoveRegion:
          getStage().container().style.cursor = 'move';
          setActiveTool(Tool.MoveRegion);
          break;
        case Tool.DeleteRegion:
          getStage().container().style.cursor = 'pointer';
          setActiveTool(Tool.DeleteRegion);
          break;
        case Tool.NavigateToPen:
          getStage().container().style.cursor = 'pointer';
          setActiveTool(Tool.NavigateToPen);
          break;
        case Tool.Default:
        default:
          setActiveTool(Tool.Default);
          getStage().container().style.cursor = 'default';
          break;
      }
    },
    [getStage, props.busy],
  );

  // Setup handler for resize window events.
  useEffect(() => {
    window.addEventListener('resize', checkSize);
    return (): void => window.removeEventListener('resize', checkSize);
  }, [checkSize]);

  // once the data has been loaded recalculate canvas size
  useEffect(() => {
    checkSize();
  }, [checkSize]);

  // change mouse pointer on edit mode change
  useEffect(() => {
    if (!props.mapUrl) {
      return;
    }
    if (editMode) {
      //default tool in edit mode
      changeTool(Tool.CreateRectRegion);
    } else {
      changeTool(Tool.Default);
    }
  }, [props.mapUrl, editMode, changeTool]);

  useEffect(() => {
    if (bgImage) {
      const ratio = bgImage.width / bgImage.height;
      setStageHeight(stageWidth / ratio);
    }
  }, [bgImage, stageWidth]);

  // set the initial map image from the mapUrl prop
  useEffect(() => {
    if (props.mapUrl) {
      const image = new window.Image();
      image.src = props.mapUrl;
      image.onload = (): void => {
        setBgImage(image);
        checkSize();
      };
    }
  }, [props.mapUrl, checkSize]);

  const removeCurrentMap = useCallback(async () => {
    if (props.mapId) {
      await fileService.delete(props.mapId);
    }
  }, [props.mapId, fileService]);

  const sitePatchMutation = useMutation(
    async () => {
      await siteService.patchSite(siteId, {
        mapUrl: null,
      });
      await removeCurrentMap();
    },
    {
      onSuccess: async () => {
        // Invalidate and re-fetch
        await queryClient.invalidateQueries(QueryKey.Sites);
        props.onMapReplaced?.('');
      },
    },
  );

  if (props.busy) {
    return (
      <Placeholder animation={'glow'}>
        <Placeholder style={{ height: '450px', width: '100%' }} />
      </Placeholder>
    );
  }

  const imageUploadModal = (
    <ImageUploadModal
      busy={busy}
      onClose={(): void => {
        setImageUploadModalVisible(false);
      }}
      onUpload={async (file): Promise<void> => {
        setBusy(true);
        await removeCurrentMap();
        const fileResult = await fileService.uploadFile(file, SignedUrlType.Medium);
        await siteService.patchSite(siteId, {
          mapUrl: fileResult.key,
        });
        setBusy(false);
        setImageUploadModalVisible(false);
        props.onMapReplaced?.(fileResult.key);
        await queryClient.invalidateQueries(QueryKey.Sites);
      }}
    />
  );

  if (!props.mapUrl) {
    return (
      <>
        <div className={'p-3'}>
          <p className={'text-center'}>
            <strong>{t('penMap|noImageFoundMessage')}</strong>
            <br />
            {t('penMap|uploadMapImageMessage')}
          </p>
          <p className={'text-center mb-0'}>
            <Button
              onClick={(): void => setImageUploadModalVisible(true)}
              size={'sm'}
              variant={ButtonVariant.OutlineSecondary}
            >
              {t('Upload Image')}
            </Button>
          </p>
        </div>
        {imageUploadModalVisible && imageUploadModal}
      </>
    );
  }

  return (
    <div className={''} ref={mapRef} style={{ width: '100%', backgroundColor: 'transparent' }}>
      <Stage
        className={'pen-stage'}
        height={stageHeight * scale}
        onMouseDown={(e: Konva.KonvaEventObject<MouseEvent>): void => {
          if (activeTool !== Tool.CreateRectRegion) {
            return;
          }

          const stage = e.target.getStage();
          if (!stage) {
            return;
          }

          const pos = stage.getRelativePointerPosition();
          setActiveRegion({ start: { x: pos.x, y: pos.y }, end: { x: pos.x, y: pos.y } });
          setIsDrawing(true);
        }}
        onMousemove={(e: Konva.KonvaEventObject<MouseEvent>): void => {
          const stage = e.target.getStage();
          if (!stage) {
            return;
          }
          const point = stage.getRelativePointerPosition();
          if (isDrawing && activeTool === Tool.CreateRectRegion) {
            const newActiveRegion = { ...activeRegion };
            setActiveRegion({ start: newActiveRegion.start, end: { x: point.x, y: point.y } });
          }
          if (debug) {
            setMousePosition({ x: point.x, y: point.y });
          }
        }}
        onMouseup={(): void => {
          if (!isDrawing || activeTool !== Tool.CreateRectRegion) {
            return;
          }
          setActiveRegion((prev) => normalizeRegion(prev));
          setIsDrawing(false);
          setRegionModalVisible(true);
        }}
        //preventDefault={false}
        ref={stageRef}
        scale={{ x: scale, y: scale }}
        style={{ border: 'none', borderRadius: '1rem', overflow: 'hidden' }}
        width={stageWidth * scale}
      >
        <Layer preventDefault={false}>
          {bgImage && (
            <Image
              height={stageHeight}
              image={bgImage}
              preventDefault={false}
              width={stageWidth}
              x={0}
              y={0}
            />
          )}
          {/*<EditModeLabel x={stageWidth - 200} y={stageHeight - 50} visible={editMode} />*/}
        </Layer>
        <Layer id={'debug'}>
          <DebugShowMousePosition mousePosition={mousePosition} visible={debug} />
          <DebugShowActiveTool activeTool={activeTool} visible={debug} />
        </Layer>
        <Layer id={'pen-regions'} preventDefault={false}>
          {pens?.map((pen) => (
            <PenGroup
              editMode={editMode}
              isDragging={isDragging}
              isDrawing={isDrawing}
              key={pen.id}
              onChangeTool={changeTool}
              onClick={(pen): void => {
                !editMode && history.push(`/pens/${pen.id}`);
              }}
              onDelete={async (pen): Promise<void> => {
                await penService.patch(pen.id, { mapRegion: null });
                await query.refetch();
              }}
              onDragEnd={async (e): Promise<void> => {
                const newPen = { ...pen };
                const r = newPen.mapRegion as Region;
                const width = r.end.x - r.start.x;
                const height = r.end.y - r.start.y;

                // get the new group position and set it to the
                r.start.x = e.target.getPosition().x;
                r.start.y = e.target.getPosition().y;
                r.end.x = e.target.getPosition().x + width;
                r.end.y = e.target.getPosition().y + height;

                await penService.patch(pen.id, { mapRegion: r });
                setIsDragging(false);
              }}
              onDragStart={(): void => setIsDragging(true)}
              pen={pen}
              scale={scale}
            />
          ))}
        </Layer>
        <Layer id={'active-region'} preventDefault={false} ref={activeRegionLayerRef}>
          <Rect
            fillLinearGradientColorStops={[0, 'rgba(255,255,255,0.7)', 0.3, 'rgba(59,132,145,0.7)']}
            fillLinearGradientEndPoint={{
              x: activeRegion.end.x - activeRegion.start.x,
              y: activeRegion.end.y,
            }}
            fillLinearGradientStartPoint={{ x: activeRegion.end.x - activeRegion.start.x, y: 0 }}
            height={activeRegion.end.y - activeRegion.start.y}
            preventDefault={false}
            stroke={'rgba(59,132,145,1)'}
            strokeWidth={10}
            width={activeRegion.end.x - activeRegion.start.x}
            x={activeRegion.start.x}
            y={activeRegion.start.y}
          />
        </Layer>
      </Stage>
      {props.mapUrl && (
        <div className={'m-4 d-flex justify-content-between '}>
          <Button
            className={'text-nowrap'}
            onClick={(): void => setImageUploadModalVisible(true)}
            size={'sm'}
            variant={ButtonVariant.OutlinePrimary}
          >
            {props.mapUrl ? (
              <>
                <IconEdit className={'me-1'} />
                {t('Change Image')}
              </>
            ) : (
              <>
                <IconUpload className={'me-1'} />
                {t('Upload Image')}
              </>
            )}
          </Button>
          {editMode && (
            <Button
              busy={sitePatchMutation.isLoading}
              className={'text-nowrap'}
              onClick={async (): Promise<void> => await sitePatchMutation.mutateAsync()}
              size={'sm'}
              variant={ButtonVariant.OutlinePrimary}
            >
              {props.mapUrl && (
                <>
                  <IconDelete className={'me-1'} />
                  {t('Remove Image')}
                </>
              )}
            </Button>
          )}
          {imageUploadModalVisible && imageUploadModal}
          <Form.Check
            checked={editMode}
            id="edit-mode-switch"
            label="Edit Mode"
            onChange={(e): void => setEditMode(e.target.checked)}
            type="switch"
            value={'yes'}
          />
        </div>
      )}
      {regionModalVisible && (
        <AssignPenModal
          //visible={regionModalVisible}
          mapRegion={activeRegion}
          onClose={async (): Promise<void> => {
            setRegionModalVisible(false);
            if (activeRegionLayerRef.current) {
              setActiveRegion({ start: { x: 0, y: 0 }, end: { x: 0, y: 0 } });
            }
            await query.refetch();
          }}
        />
      )}
    </div>
  );
};

export default PenMap;
