// Copyright © 2023 CATTLEytics Inc.

export const TYPES = {
  activeTreatmentService: Symbol.for('ACTIVE_TREATMENT_SERVICE'),
  apiService: Symbol.for('API_SERVICE'),
  api2Service: Symbol.for('API2_SERVICE'),
  animalColorService: Symbol.for('ANIMAL_COLOR_SERVICE'),
  animalEventService: Symbol.for('ANIMAL_EVENT_SERVICE'),
  animalEventTypeService: Symbol.for('ANIMAL_EVENT_TYPE_SERVICE'),
  animalHealthCheckService: Symbol.for('ANIMAL_HEALTH_CHECK_SERVICE'),
  animalHealthCheckResultService: Symbol.for('ANIMAL_HEALTH_CHECK_RESULT_SERVICE'),
  animalReferenceService: Symbol.for('ANIMAL_REFERENCE_SERVICE'),
  animalReferenceTypeService: Symbol.for('ANIMAL_REFERENCE_TYPE_SERVICE'),
  animalReproductionService: Symbol.for('ANIMAL_REPRODUCTION_SERVICE'),
  animalService: Symbol.for('ANIMAL_SERVICE'),
  animalTestDayService: Symbol.for('ANIMAL_TEST_DAY_SERVICE'),
  authService: Symbol.for('AUTH_SERVICE'),
  birthStatusService: Symbol.for('BIRTH_STATUS_SERVICE'),
  breedService: Symbol.for('BREED_SERVICE'),
  breedingPlanService: Symbol.for('BREEDING_PLAN_SERVICE'),
  breedingProgramService: Symbol.for('BREEDING_PROGRAM_SERVICE'),
  breedingProgramTypeService: Symbol.for('BREEDING_PROGRAM_TYPE_SERVICE'),
  cqlQueryService: Symbol.for('CQL_QUERY_SERVICE'),
  dailyLogService: Symbol.for('DAILY_LOG_SERVICE'),
  dairyLogService: Symbol.for('DAIRY_LOG_SERVICE'),
  diagnosisService: Symbol.for('DIAGNOSIS_SERVICE'),
  diagnosisRegimeService: Symbol.for('DIAGNOSIS_REGIME_SERVICE'),
  diagnosisRegimeDetailService: Symbol.for('DIAGNOSIS_REGIME_DETAIL_SERVICE'),
  fileService: Symbol.for('FILE_SERVICE'),
  genderService: Symbol.for('GENDER_SERVICE'),
  importService: Symbol.for('IMPORT_SERVICE'),
  jobService: Symbol.for('JOB_SERVICE'),
  layoutService: Symbol.for('LAYOUT_SERVICE'),
  layoutItemService: Symbol.for('LAYOUT_ITEM_SERVICE'),
  logger: Symbol.for('LOGGER'),
  nameService: Symbol.for('NAME_SERVICE'),
  noteService: Symbol.for('NOTE_SERVICE'),
  noteAttachmentService: Symbol.for('NOTE_ATTACHMENT_SERVICE'),
  notificationService: Symbol.for('NOTIFICATION_SERVICE'),
  penService: Symbol.for('PEN_SERVICE'),
  penInsightService: Symbol.for('PEN_INSIGHT_SERVICE'),
  productService: Symbol.for('PRODUCT_SERVICE'),
  productAdministrationService: Symbol.for('PRODUCT_ADMINISTRATION_SERVICE'),
  productClassService: Symbol.for('PRODUCT_CLASS_SERVICE'),
  productTypeService: Symbol.for('PRODUCT_TYPE_SERVICE'),
  organizationService: Symbol.for('ORGANIZATION_SERVICE'),
  reportService: Symbol.for('REPORT_SERVICE'),
  roleService: Symbol.for('ROLE_SERVICE'),
  reproStatusService: Symbol.for('REPRO_STATUS_SERVICE'),
  settingService: Symbol.for('SETTING_SERVICE'),
  sireService: Symbol.for('SIRE_SERVICE'),
  siteService: Symbol.for('SITE_SERVICE'),
  taskService: Symbol.for('TASK_SERVICE'),
  userService: Symbol.for('USER_SERVICE'),
};
