// Copyright © 2023 CATTLEytics Inc.

import React from 'react';

/**
 * A busy indicator line meant for using with a table.
 */
const TableBusyIndicator = (): JSX.Element => {
  return (
    <div
      className={'bg-primary'}
      style={{
        height: '4px',
        animation: 'placeholder-glow 2s ease-in-out infinite',
      }}
    />
  );
};

export default TableBusyIndicator;
