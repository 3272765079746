// Copyright © 2023 CATTLEytics Inc.
import { useCallback, useMemo } from 'react';

import { useAnimalCardEvents } from '../provider';
import { EventFilters } from '../types';

type Return<Name extends keyof EventFilters> = readonly [
  EventFilters[Name],
  (filterValue: EventFilters[Name]) => void,
];

export const useAnimalCardEventFilter = <Name extends keyof EventFilters>(
  filterName: Name,
): Return<Name> => {
  const { filters, setFilters } = useAnimalCardEvents();

  const filter = useMemo(() => filters[filterName], [filters, filterName]);
  const setFilter = useCallback(
    (filterValue: EventFilters[Name]): void =>
      setFilters((prev) => ({ ...prev, [filterName]: filterValue })),
    [filterName, setFilters],
  );

  return [filter, setFilter] as const;
};
