// Copyright © 2023 CATTLEytics Inc.
import { memo } from 'react';
import { Placeholder } from 'react-bootstrap';

import TablePlaceholder from '../../../../common/components/TablePlaceholder';

export const AnimalCardEventsPlaceholder = memo(
  (): JSX.Element => (
    <Placeholder animation={'glow'}>
      <Placeholder className="w-100" style={{ height: 300, borderRadius: 8 }} />
      <TablePlaceholder />
    </Placeholder>
  ),
);
