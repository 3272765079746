// Copyright © 2024 CATTLEytics Inc.

import { json } from '@codemirror/lang-json';
import CodeMirror from '@uiw/react-codemirror';
import { forwardRef } from 'react';
import { Alert, Form } from 'react-bootstrap';

import Required from '../../common/components/Required';
import { IconError } from '../../common/utilities';
import { darkThemeJson, lightThemeJson } from '../../reports/components/ReportsCodeMirror';

interface JsonEditorProps {
  /**
   * Syntax error in the json
   */
  error: string | null;

  /**
   * A short description of the json
   */
  label: string;

  /**
   * A function to run when a modification is made to the json
   */
  onChange: (value: string) => void;

  /**
   * The initial value of the json editor
   */
  value: string;
}

/**
 * A simple json editor that supports syntax highlighting and errors
 */
const JsonEditor = forwardRef(
  ({ label, onChange, value, error }: JsonEditorProps, ref): JSX.Element => {
    const myTheme =
      document.documentElement.dataset.bsTheme === 'light' ? lightThemeJson : darkThemeJson;

    return (
      <Form.Group className="form-group mb-3" controlId="animalImportMap">
        <Form.Label>
          <span className="p-1">{label}</span>
          <Required />
        </Form.Label>
        <CodeMirror
          basicSetup={{
            lineNumbers: true,
            indentOnInput: false,
            defaultKeymap: true,
          }}
          className={'code-mirror'}
          extensions={[json()]}
          onChange={(value: string): void => {
            onChange(value);
          }}
          ref={ref}
          theme={myTheme}
          value={value}
        />
        {error && (
          <Alert className="mt-4 mb-4" variant="danger">
            <IconError className={'me-2'} />
            {error}
          </Alert>
        )}
      </Form.Group>
    );
  },
);
export default JsonEditor;
