// Copyright © 2023 CATTLEytics Inc.

import { reverseString } from './string';

export const incrementLastNumberInString = (str: string): string =>
  reverseString(
    reverseString(str).replace(/(\d+)+/, (match: string, number: string): string =>
      reverseString(String(parseInt(reverseString(number)) + 1)),
    ),
  );
