// Copyright © 2023 CATTLEytics Inc.

import React from 'react';

/**
 * @description Defines the input properties for the Arrow component.
 * @interface Props
 */
interface Props {
  /**
   * @description The background colour of this component.
   * @type {string}
   * @memberof Props
   */
  backgroundColor?: string;

  /**
   * @description Additional CSS classes to add to this component.
   * @type {string}
   * @memberof Props
   */
  className?: string;

  /**
   * @description The foreground colour of this component.
   * @type {string}
   * @memberof Props
   */
  color?: string;

  /**
   * @description The arrow's height in CSS height format.
   * @type {string}
   * @memberof Props
   */
  height?: string;

  /**
   * @description The arrow's height in CSS height format.
   * @type {string}
   * @memberof Props
   */
  width?: string;
}

/**
 * @description A simple coloured arrow with a number inside.
 * @class Arrow
 * @extends {React.Component<Props>}
 */
class Arrow extends React.Component<Props> {
  /**
   * @description Renders the component.
   * @returns {JSX.Element}
   * @memberof Arrow
   */
  render(): JSX.Element {
    const color = this.props.color ? this.props.color : 'black';
    const className = this.props.className ? this.props.className : '';
    const bgColor = this.props.backgroundColor ? this.props.backgroundColor : 'transparent';
    const height = this.props.height ? this.props.height : '32px';
    const width = this.props.width ? this.props.width : '100%';

    return (
      <div
        className={`arrow d-flex align-items-center justify-content-center ${className}`}
        style={{
          height: height,
          width: width,
          backgroundColor: bgColor,
          paddingRight: '10%',
          color: color,
          clipPath: 'polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%)',
        }}
      >
        <div style={{ fontSize: '2rem' }}>{this.props.children}</div>
      </div>
    );
  }
}

export default Arrow;
