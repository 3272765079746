// Copyright © 2023 CATTLEytics Inc.
import { FC, ReactNode } from 'react';
import { Card } from 'react-bootstrap';

import Button from '../../common/components/Button';
import { ButtonVariant } from './Button';

interface Props {
  /**
   * Display icon
   */
  icon: ReactNode;

  /**
   * Display label on button
   */
  label: string;

  /**
   * Event fired when card is clicked
   * @returns void
   */
  onClick?: () => void;
}

const CardButton: FC<Props> = (props) => {
  return (
    <Card body className={'text-center'} style={{ height: '160px' }}>
      <div
        className={'mb-2 d-flex justify-content-center align-items-center'}
        style={{ height: '75px' }}
      >
        {props.icon}
      </div>
      <Button
        className={'w-100 stretched-link'}
        onClick={props.onClick}
        variant={ButtonVariant.OutlinePrimary}
      >
        {props.label}
      </Button>
    </Card>
  );
};

export default CardButton;
