// Copyright © 2023 CATTLEytics Inc.

import React, { ReactNode } from 'react';
import { OverlayTrigger, Tooltip as TooltipBootstrap } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props extends WithTranslation {
  label?: string;
  tooltip?: string;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
}

/**
 * @description Displays a tooltip on hover.
 */
const Tooltip = (props: React.PropsWithChildren<Props>): JSX.Element => {
  return (
    <OverlayTrigger
      overlay={<TooltipBootstrap>{props.tooltip}</TooltipBootstrap>}
      placement={props.tooltipPlacement}
    >
      {({ ref, ...triggerHandler }): ReactNode => (
        <span ref={ref} {...triggerHandler}>
          {props.label ?? props.children}
        </span>
      )}
    </OverlayTrigger>
  );
};

export default withTranslation()(Tooltip);
