// Copyright © 2023 CATTLEytics Inc.

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { api } from './app/common/utilities/api';
import { ApiResourceV1, HttpMethod } from './app/shared';

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    keySeparator: '|',
    nsSeparator: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    saveMissing: false, //process.env.NODE_ENV === 'development',
    missingKeyHandler: async (lang, ns, key) => {
      try {
        await api(HttpMethod.Post, ApiResourceV1.Translations, {
          body: {
            language: lang,
            nameSpace: ns,
            package: 'web',
            key,
          },
        });
      } catch (err) {
        console.error('missingKeyHandler', err);
      }
    },
    backend: {
      loadPath: '/locales/{{lng}}.json',
      addPath: '/locales/{{lng}}.missing.json',
    },
  });

export default i18n;
