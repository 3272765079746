// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { TYPES } from '../../../types';
import AnimalModal from '../../animals/components/AnimalModal';
import Button from '../../common/components/Button';
import NewButtonModal from '../../common/components/NewButtonModal';
import AnimalEventType from '../../common/entities/animalEventType';
import { AnimalEventType as AnimalEventTypeEnum } from '../../common/enums';
import AnimalEventTypeService from '../../common/services/animalEventTypeService';
import { IconCreate } from '../../common/utilities';
import EventModal from '../../events/components/EventModal';
import { QueryKey } from '../../shared';

interface Props {
  className?: string;
}

/**
 * Quick actions component.
 */
const QuickActions = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const animalEventTypeService = useInjection<AnimalEventTypeService>(TYPES.animalEventTypeService);

  const [eventModalVisible, setEventModalVisible] = useState<boolean>(false);
  const [calfModalVisible, setCalfModalVisible] = useState<boolean>(false);
  const [animalModalVisible, setAnimalModalVisible] = useState<boolean>(false);

  const [eventTypes, setEventTypes] = useState<AnimalEventType[]>([]);

  const [newButtonModalVisible, setNewButtonModalVisible] = useState<boolean>(false);

  useQuery<AnimalEventType[]>([QueryKey.AnimalEventTypes], () => animalEventTypeService.list(), {
    onSuccess: (data) => {
      setEventTypes(data);
    },
  });

  const getEventTypeById = (eventTypeId: AnimalEventTypeEnum): AnimalEventType | undefined => {
    const type = eventTypes.filter((type) => type.id === eventTypeId);
    if (type.length > 0) {
      return type[0];
    }
    return undefined;
  };

  return (
    <section className={`d-grid gap-2 ${props.className ?? ''}`}>
      <Button
        icon={IconCreate}
        onClick={(): void => setAnimalModalVisible(true)}
        variant={'primary'}
      >
        {t('quickActions|addAnimalButtonLabel')}
      </Button>
      <Button icon={IconCreate} onClick={(): void => setCalfModalVisible(true)} variant={'primary'}>
        {t('quickActions|addCalfButtonLabel')}
      </Button>
      <Button
        icon={IconCreate}
        onClick={(): void => setNewButtonModalVisible(true)}
        variant={'secondary'}
      >
        {t('quickActions|addAnimalEventButtonLabel')}
      </Button>
      {animalModalVisible && <AnimalModal onClose={(): void => setAnimalModalVisible(false)} />}
      {calfModalVisible && (
        <EventModal
          defaultEventType={getEventTypeById(AnimalEventTypeEnum.Calving)}
          onClose={(): void => setCalfModalVisible(false)}
        />
      )}
      {eventModalVisible && <EventModal onClose={(): void => setEventModalVisible(false)} />}
      {newButtonModalVisible && (
        <NewButtonModal onClose={(): void => setNewButtonModalVisible(false)} />
      )}
    </section>
  );
};

export default QuickActions;
