// Copyright © 2023 CATTLEytics Inc.

import { inject, injectable } from 'inversify';

import { TYPES } from '../../../types';
import Api2Service from '../../common/services/api2Service';
import type Logger from '../../logger/logger';
import ExternalSystemType from '../entities/externalSystemType';
import ImportServiceInterface from '../interfaces/importService';

/**
 * @description Service for importing data via REST API.
 * @export
 * @class ImportService
 * @implements {ImportServiceInterface}
 */
@injectable()
export default class ImportService implements ImportServiceInterface {
  /**
   * @description Logger.
   * @private
   * @type {Logger}
   * @memberof Import
   */
  private logger: Logger;

  /**
   * Service for accessing the HTTP API
   */
  private api2Service: Api2Service;

  /**
   * Creates an instance of ImportService.
   * @param {Logger} logger Logger instance.
   * @param api2Service API B service
   * @memberof ImportService
   */
  constructor(
    @inject(TYPES.logger) logger: Logger,
    @inject(TYPES.api2Service) api2Service: Api2Service,
  ) {
    this.logger = logger;
    this.api2Service = api2Service;
  }

  /**
   * @inheritdoc
   */
  async getExternalSystemTypes(): Promise<ExternalSystemType[]> {
    const systems = await this.api2Service.get<ExternalSystemType[]>('/v1/external-systems');
    if (!systems) {
      return [];
    }

    return systems;
  }

  /**
   * @inheritdoc
   */
  async importData(
    externalSystemId: number,
    importDataTypeId: number,
    importFile: File,
  ): Promise<void> {
    this.logger.debug(
      `Importing data - system '${externalSystemId}', data type '${importDataTypeId}', file '${importFile.name}'.`,
    );

    const form = new FormData();
    form.append('externalSystemId', externalSystemId.toString());
    form.append('importDataTypeId', importDataTypeId.toString());
    form.append('importFile', importFile);

    await this.api2Service.post('/v1/import', form);
  }

  async startImportCleanupJob(): Promise<void> {
    await this.api2Service.post('/v1/jobs', { name: 'ImportDataCleanup' });
  }
  async startAnimalUpdateJob(): Promise<void> {
    await this.api2Service.post('/v1/jobs', { name: 'AnimalUpdate' });
  }
  async startPenUpdateJob(): Promise<void> {
    await this.api2Service.post('/v1/jobs', { name: 'PenUpdate' });
  }
  async startReindexJob(): Promise<void> {
    await this.api2Service.post('/v1/jobs', { name: 'Reindex' });
  }
}
