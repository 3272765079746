// Copyright © 2023 CATTLEytics Inc.

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MdAdd } from 'react-icons/md';
import { useQuery } from 'react-query';

import Button from '../common/components/Button';
import Modal, { Props as ModalProps } from '../common/components/Modal';
import { api } from '../common/utilities';
import { ApiResourceV1, HttpMethod, QueryKey, Shift } from '../shared';
import { defaultNewShift, EditableShift, ManageShiftForm } from './ManageShiftForm';

const UNSET_SELECT = 'unset';

type Props = Pick<ModalProps, 'onClose'> & {
  onSuccess?: () => void;
};

export function ManageShiftsModal({ onClose, onSuccess }: Props): JSX.Element {
  const { t } = useTranslation();
  const [selectedShift, setSelectedShift] = useState<EditableShift | undefined>(undefined);
  const { data, isLoading } = useQuery<Shift[]>(
    [QueryKey.Shifts],
    () => api(HttpMethod.Get, ApiResourceV1.Shifts),
    {},
  );

  const onSelectShift = useCallback(
    (shiftId: string) => {
      if (data?.length && shiftId) {
        setSelectedShift(data.find(({ id }) => id === Number(shiftId)));
      } else {
        setSelectedShift(undefined);
      }
    },
    [data, setSelectedShift],
  );

  const onFormSuccess = useCallback(
    (shift?: EditableShift) => {
      // refetch();
      if (shift) {
        setSelectedShift(shift);
      }
      onSuccess?.();
      onClose?.();
    },
    [onClose, setSelectedShift, onSuccess],
  );

  useEffect(() => {
    if (!isLoading && !data?.length) {
      setSelectedShift(defaultNewShift);
    }
  }, [isLoading, data]);

  const hasShifts = useMemo(() => !!data?.length, [data]);

  return (
    <Modal onClose={onClose} size={'lg'} title={t(`Manage Shifts`)} visible={true}>
      {!isLoading && (
        <>
          {hasShifts && (
            <>
              <InputGroup>
                <Form.Select
                  onChange={(e): void => onSelectShift(e.target.value)}
                  value={selectedShift?.id ?? UNSET_SELECT}
                >
                  <option value={UNSET_SELECT}>Select or Create Shift</option>
                  {data?.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </Form.Select>
                <Button onClick={(): void => setSelectedShift(defaultNewShift)} title={t('Create')}>
                  <MdAdd style={{ height: '100%' }} />
                </Button>
              </InputGroup>
            </>
          )}
          {selectedShift && (
            <>
              {!!data?.length && <hr />}
              <ManageShiftForm
                key={selectedShift.id}
                onCancel={(): void => setSelectedShift(undefined)}
                onSuccess={onFormSuccess}
                selectedShift={selectedShift}
              />
            </>
          )}
        </>
      )}
    </Modal>
  );
}
