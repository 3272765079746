// Copyright © 2023 CATTLEytics Inc.
import { useState } from 'react';

interface Return {
  closeModal: () => void;
  open: boolean;
  openModal: () => void;
}

export const useOpenModal = (initialOpen = false): Return => {
  const [open, setOpen] = useState(initialOpen);
  const openModal = (): void => setOpen(true);
  const closeModal = (): void => setOpen(false);

  return { open, openModal, closeModal } as const;
};
