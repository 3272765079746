// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useEffect, useState } from 'react';
import { Form, FormGroup, Placeholder, PlaceholderButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { TYPES } from '../../../types';
import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import { ButtonVariant } from '../../common/components/Button';
import ButtonModalCancel from '../../common/components/ButtonModalCancel';
import ButtonModalDelete from '../../common/components/ButtonModalDelete';
import ButtonModalSave from '../../common/components/ButtonModalSave';
import ConfirmModal from '../../common/components/ConfirmModal';
import Modal from '../../common/components/Modal';
import Required from '../../common/components/Required';
import HealthCheck from '../../common/entities/animalHealthCheck';
import HealthCheckService from '../../common/services/animalHealthCheckService';
import Logger from '../../logger/logger';
import { QueryKey } from '../../shared/enums';

/**
 * Component's input properties.
 */
interface Props {
  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Identifier of the item we want to edit
   */
  editId?: number;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;
}

/**
 * Modal to add/edit a health check.
 */
const HealthCheckModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const logger = useInjection<Logger>(TYPES.logger);

  const healthCheckService = useInjection<HealthCheckService>(TYPES.animalHealthCheckService);

  const [errorMessage, setErrorMessage] = useState<string>();
  const [validated, setValidated] = useState<boolean>(false);

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [type, setType] = useState<string>('calf');

  const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteConfirmModalErrorMessage, setDeleteConfirmModalErrorMessage] = useState<string>();

  const query = useQuery<HealthCheck | undefined>(
    [QueryKey.AnimalHealthChecks, props.editId],
    () => healthCheckService.get(props.editId ?? -1),
    {
      keepPreviousData: true,
      enabled: !!props.editId,
      onSuccess: (data) => {
        if (!data) {
          return;
        }
        if (data.description) {
          setDescription(data.description);
        }
        setName(data.name);
        setType(data.type);
      },
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  const mutation = useMutation(
    () => {
      const payload = {
        name: name,
        description: description,
        type: type,
      };

      if (props.editId) {
        return healthCheckService.patch(props.editId, payload);
      } else {
        return healthCheckService.post(payload);
      }
    },
    {
      onSuccess: async () => {
        // Invalidate to trigger re-fetch
        await queryClient.invalidateQueries(QueryKey.AnimalHealthChecks);
      },
    },
  );

  useEffect(() => {
    const invalidElements = document.querySelectorAll('input.form-control:invalid');
    if (invalidElements.length > 0) {
      invalidElements[0].closest('.form-group')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [validated]);

  const queryClient = useQueryClient();

  const deleteItem = async (): Promise<void> => {
    if (!props.editId) {
      return;
    }
    setIsDeleting(true);
    try {
      await healthCheckService.delete(props.editId);
      await queryClient.invalidateQueries(QueryKey.AnimalHealthChecks);
      setDeleteConfirmModalVisible(false);
      setIsDeleting(false);
      props.onClose();
    } catch (err) {
      setIsDeleting(false);
      logger.error('This item failed to be deleted', err);
      setDeleteConfirmModalErrorMessage(t('common|itemCannotDeleteError'));
    }
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      await mutation.mutateAsync();

      props.onClose();
    } catch (err) {
      logger.error(`Health check could not be saved.`, err);
      setErrorMessage(
        t(`{{typeLabel}} could not be saved.`, {
          typeLabel: t('Health check'),
        }),
      );
    }
  };

  const placeholder = (
    <Placeholder animation={'glow'}>
      <Placeholder xs={6} />
      <Placeholder className={'mb-5'} size={'lg'} xs={12} />
      <Placeholder xs={6} />
      <Placeholder className={'mb-5'} size={'lg'} xs={12} />
    </Placeholder>
  );

  const form = (
    <Form noValidate onSubmit={handleFormSubmit} validated={validated}>
      <FormGroup className="form-group mb-3" controlId="formName">
        <Form.Label>
          {t('Name')} <Required />
        </Form.Label>
        <Form.Control
          name={'name'}
          onChange={(e): void => setName(e.target.value)}
          placeholder={t('Name of this health check.')}
          required
          type={'text'}
          value={name}
        />
        <Form.Text className={'text-muted'}>
          {t('A short name to describe this health check.')}
        </Form.Text>
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </FormGroup>
      <FormGroup className="form-group mb-3" controlId="formDescription">
        <Form.Label>{t('Description')}</Form.Label>
        <Form.Control
          as={'textarea'}
          name={'description'}
          onChange={(e): void => setDescription(e.target.value)}
          placeholder={t('A longer description')}
          rows={4}
          value={description}
        />
        <Form.Text className={'text-muted'}>
          {t('You can optionally provide more detailed information for this health check.')}
        </Form.Text>
      </FormGroup>
      <Form.Group className="form-group mb-3" controlId="formType">
        <Form.Label>
          {t('Type')} <Required />
        </Form.Label>
        <Form.Select
          onChange={(e): void => {
            setType(e.target.value);
          }}
          value={type}
        >
          <option key={'calf'} value={'calf'}>
            {t('Calf')}
          </option>
        </Form.Select>
      </Form.Group>
      <AlertErrorForModal message={errorMessage} />
      <div className="modal-footer modal-footer-in-form">
        <ButtonModalCancel
          disabled={mutation.isLoading || query.isLoading}
          onClick={props.onClose}
        />
        {props.editId && (
          <ButtonModalDelete
            disabled={mutation.isLoading || query.isLoading}
            onClick={(): void => setDeleteConfirmModalVisible(true)}
          />
        )}
        <ButtonModalSave
          busy={mutation.isLoading}
          disabled={mutation.isLoading || query.isLoading}
        />
      </div>
    </Form>
  );

  return (
    <Modal
      fullscreen={'md-down'}
      onClose={props.onClose}
      size={'lg'}
      title={`${props.editId ? t('Edit') : t('common|add')} Health Check`}
      visible={true}
    >
      {query.isError && (
        <AlertErrorForModal
          message={t('Something unexpected happened while retrieving health check.')}
        />
      )}
      {query.isFetching ? placeholder : form}
      {query.isFetching && (
        <div className="modal-footer modal-footer-in-form">
          <PlaceholderButton variant={'secondary'} xs={2} />
          {props.editId && <PlaceholderButton variant={'danger'} xs={2} />}
          <PlaceholderButton xs={2} />
        </div>
      )}
      <ConfirmModal
        busy={isDeleting}
        cancelLabel={t('Cancel')}
        cancelOnClick={(): void => setDeleteConfirmModalVisible(false)}
        errorMessage={deleteConfirmModalErrorMessage}
        okLabel={t(`Yes, delete this {{value}}`, { value: t('regime') })}
        okOnClick={deleteItem}
        okVariant={ButtonVariant.Danger}
        title={t(`Delete this {{value}}`, { value: t('regime') })}
        visible={deleteConfirmModalVisible}
      >
        {t('Are you sure you want to delete this {{value}}?', { value: t('regime') })}
      </ConfirmModal>
    </Modal>
  );
};

export default HealthCheckModal;
