// Copyright © 2023 CATTLEytics Inc.
import { MouseEventHandler, useCallback } from 'react';

import { useTimeline } from './TimelineProvider';
import { DateEntity, EntityBase } from './types';

type Return = {
  onClick: MouseEventHandler<HTMLDivElement>;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

export const useTimelineNodeEvents = <Entity extends EntityBase>(
  item: DateEntity<Entity>,
): Return => {
  const { entityEventType, setSelectedEntity } = useTimeline<Entity>();

  const setItem = useCallback(() => {
    setSelectedEntity(item);
  }, [setSelectedEntity, item]);
  const removeItem = useCallback(() => {
    setSelectedEntity(null);
  }, [setSelectedEntity]);

  const onMouseEnter = useCallback((): void => {
    entityEventType === 'hover' && setItem();
  }, [setItem, entityEventType]);
  const onMouseLeave = useCallback((): void => {
    entityEventType === 'hover' && removeItem();
  }, [removeItem, entityEventType]);

  const onClickOutside = useCallback(() => {
    document.removeEventListener('click', onClickOutside);
    removeItem();
  }, [removeItem]);
  const onClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (evt): void => {
      evt.stopPropagation();
      entityEventType === 'click' && setItem();
      document.addEventListener('click', onClickOutside);
    },
    [setItem, onClickOutside, entityEventType],
  );

  return { onClick, onMouseEnter, onMouseLeave };
};
