// Copyright © 2023 CATTLEytics Inc.
// export const removeNullUndefined = (obj: any) =>
//   Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));

// export const removeUndefined = <T>(obj: T): T =>
//   Object.fromEntries(Object.entries(obj).filter(([key, value]) => value !== undefined));

export const removeUndefined = (obj: any): any => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach((key) => newObj[key] === undefined && delete newObj[key]);
  return newObj;
};

export const decimateArray = (arr: any[], fidelity = 2): any[] => {
  return arr.filter((_, index) => index % fidelity === 0);
};
