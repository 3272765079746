// Copyright © 2023 CATTLEytics Inc.

import { parseISO } from 'date-fns';
import { inject, injectable } from 'inversify';
import { Serializer } from 'ts-japi';

import { TYPES } from '../../../types';
import JsonApiDataResponse from '../../common/interfaces/jsonApiDataResponse';
import JsonApiQueryParams from '../../common/interfaces/jsonApiQueryParams';
import JsonApiResource from '../../common/interfaces/jsonApiResource';
import Api2Service from '../../common/services/api2Service';
import type Logger from '../../logger/logger';
import AnimalReproduction from '../entities/animalReproduction';
import { JsonApiTypes } from '../enums/jsonApiTypes';

/**
 * @description Service for managing animal event data via REST API.
 * @export
 * @class AnimalReproductionService
 */
@injectable()
export default class AnimalReproductionService {
  /**
   * @description Logger.
   * @private
   * @type {Logger}
   * @memberof AnimalReproductionService
   */
  private logger: Logger;

  /**
   * Service for accessing the HTTP API.
   */
  private apiService: Api2Service;

  /**
   * @description JSON:API serializer.
   * @private
   * @type {Serializer}
   * @memberof AnimalReproductionService
   */
  private serializer: Serializer;

  /**
   * @description API resource name like "animals" in "/animals".
   * @private
   * @type {string}
   * @memberof AnimalReproductionService
   */
  private resource = 'animalReproductions';

  /**
   * Creates an instance of AnimalReproductionService.
   * @param {Logger} logger Logger instance.
   * @param {Api2Service} apiService API Service instance
   * @memberof AnimalReproductionService
   */
  constructor(
    @inject(TYPES.logger) logger: Logger,
    @inject(TYPES.apiService) apiService: Api2Service,
  ) {
    this.logger = logger;
    this.apiService = apiService;
    this.serializer = new Serializer(JsonApiTypes.Animals);
  }

  /**
   * @inheritdoc
   */
  async getAnimalReproduction(
    animalReproductionId: number,
    params?: JsonApiQueryParams,
  ): Promise<AnimalReproduction> {
    // set default include query parameters
    if (!params) {
      params = {};
    }
    if (params && !params.include) {
      params.include = ['breedingTechnician'];
    }
    const result = await this.apiService.get<JsonApiDataResponse<AnimalReproduction>>(
      `/${this.resource}/${animalReproductionId}`,
      params,
    );
    if (!result) {
      throw new Error('No result');
    }
    //const data = result.data as JsonApiResource<AnimalReproduction>;
    // convert date strings to date objects
    // if (data && data.attributes.birthDate) {
    //   data.attributes.birthDate = parseISO(data.attributes.birthDate as unknown as string);
    // }
    // if (data && data.attributes.dateIn) {
    //   data.attributes.dateIn = parseISO(data.attributes.dateIn as unknown as string);
    // }
    // if (data && data.attributes.dateOut) {
    //   data.attributes.dateOut = parseISO(data.attributes.dateOut as unknown as string);
    // }
    return this.apiService.deserialize<AnimalReproduction>(result);
  }

  /**
   * @inheritdoc
   */
  async getAnimalReproductions(params?: JsonApiQueryParams): Promise<AnimalReproduction[]> {
    // set default include query parameters
    if (!params) {
      params = {};
    }

    if (params && !params.include) {
      params.include = ['breedingTechnician'];
    }

    const result = await this.apiService.get<JsonApiDataResponse<AnimalReproduction>>(
      `/${this.resource}`,
      params,
    );
    if (!result) {
      return [];
    }

    const data = result.data as JsonApiResource<AnimalReproduction>[];
    data.forEach((item) => this.cast(item));

    return this.apiService.deserializeArray<AnimalReproduction>(result);
  }

  /**
   * @description Casts fields into javascript types.
   * @param {JsonApiResource<AnimalReproduction>} item
   * @return {void}
   */
  cast(item: JsonApiResource<AnimalReproduction>): void {
    const data = item as JsonApiResource<AnimalReproduction>;
    if (!data) {
      return;
    }
    // convert date strings to date objects
    if (data.attributes.breedingDate) {
      data.attributes.breedingDate = parseISO(data.attributes.breedingDate as unknown as string);
    }
    if (data.attributes.freshDate) {
      data.attributes.freshDate = parseISO(data.attributes.freshDate as unknown as string);
    }
    if (data.attributes.dryOffDate) {
      data.attributes.dryOffDate = parseISO(data.attributes.dryOffDate as unknown as string);
    }
  }
}
