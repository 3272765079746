// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonVariant } from './Button';

/**
 * Defines the input properties for the AlertError component.
 */
interface Props {
  /**
   * Error message to display.
   */
  message: string;

  /**
   * Error message title to display.
   */
  title?: string;
}

/**
 * A wrapper for the Alert component for errors with our preferences defined.
 */
const AlertError = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  const history = useHistory();
  return (
    <Alert className={'text-center'} variant="danger">
      <h1>{props.title ?? t('Whoops!')}</h1>
      <p>{props.message ?? t('alertError|unexpectedError')}</p>

      <Button onClick={history.goBack} variant={ButtonVariant.OutlineDanger}>
        {t('Go back')}
      </Button>
    </Alert>
  );
};

export default AlertError;
