// Copyright © 2023 CATTLEytics Inc.

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import ButtonCreate from '../../common/components/ButtonCreate';
import Page from '../../common/components/Page';
import DiagnosisRegimeModal from './DiagnosisRegimeModal';
import DiagnosisRegimeTable from './DiagnosisRegimeTable';

/**
 * Diagnosis regimes page.
 */
const DiagnosisRegimesPage = (): JSX.Element => {
  const { t } = useTranslation();

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const filters: Record<string, string | undefined> = {
    pen: searchParams.get('pen') ? (searchParams.get('pen') as string) : undefined,
  };
  const [diagnosisRegimeModalVisible, setDiagnosisRegimeModalVisible] = useState<boolean>(false);

  return (
    <Page
      buttons={
        <ButtonCreate
          label={t('Create')}
          onClick={(): void => setDiagnosisRegimeModalVisible(true)}
        />
      }
      title={t('Diagnosis Regimes')}
    >
      <DiagnosisRegimeTable filters={filters} />

      {diagnosisRegimeModalVisible && (
        <DiagnosisRegimeModal
          onClose={(): void => setDiagnosisRegimeModalVisible(false)}
          onSave={(dr): void => history.push(`/diagnosis-regimes/${dr.id}`)}
        />
      )}
    </Page>
  );
};

export default DiagnosisRegimesPage;
