// Copyright © 2023 CATTLEytics Inc.

import { parseISO } from 'date-fns';
import { useInjection } from 'inversify-react';
import React, { useEffect, useState } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { TYPES } from '../../../types';
import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import AnimalReferenceTypeSelect from '../../common/components/AnimalReferenceTypeSelect';
import Button, { ButtonVariant } from '../../common/components/Button';
import ButtonModalCancel from '../../common/components/ButtonModalCancel';
import ButtonModalDelete from '../../common/components/ButtonModalDelete';
import ButtonModalSave from '../../common/components/ButtonModalSave';
import ConfirmModal from '../../common/components/ConfirmModal';
import FormPlaceholder from '../../common/components/FormPlaceholder';
import Modal from '../../common/components/Modal';
import Required from '../../common/components/Required';
import AnimalReference from '../../common/entities/animalReference';
import { PayloadPost } from '../../common/services/animalReferenceService';
import AnimalReferenceService from '../../common/services/animalReferenceService';
import Logger from '../../logger/logger';
import { QueryKey } from '../../shared';

/**
 * Component's input properties.
 */
interface Props {
  /**
   * The animal to add the reference to.
   */
  animalId: number;

  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Identifier of the item we want to edit
   */
  editId?: number;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;
}

/**
 * Modal to add/edit a product.
 */
const AnimalReferenceModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const logger = useInjection<Logger>(TYPES.logger);

  const animalReferenceService = useInjection<AnimalReferenceService>(TYPES.animalReferenceService);

  const [showAddReferenceTypeModal, setShowAddReferenceTypeModal] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>();
  const [validated, setValidated] = useState<boolean>(false);

  const [deleteConfirmModalErrorMessage, setDeleteConfirmModalErrorMessage] = useState<string>('');
  const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const [reference, setReference] = useState<string>('');
  const [effectiveDateStart, setEffectiveDateStart] = useState<string>('');
  const [effectiveDateEnd, setEffectiveDateEnd] = useState<string>('');
  const [active, setActive] = useState<boolean>(true);
  const [referenceTypeId, setReferenceTypeId] = useState<string>('');

  const queryClient = useQueryClient();

  const query = useQuery<AnimalReference | undefined>(
    [QueryKey.AnimalReferences, props.editId],
    () => animalReferenceService.get(props.editId ?? -1),
    {
      keepPreviousData: true,
      enabled: !!props.editId,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  useEffect(() => {
    const data = query.data;
    if (data) {
      setActive(data.active);
      if (data.effectiveDateEnd) {
        setEffectiveDateEnd(data.effectiveDateEnd.toISOString());
      }
      if (data.effectiveDateStart) {
        setEffectiveDateStart(data.effectiveDateStart.toISOString());
      }
      if (data.animalReferenceType) {
        setReferenceTypeId(String(data.animalReferenceTypeId));
      }
      setReference(data.reference);
    }
  }, [query.data]);

  const mutation = useMutation<AnimalReference | undefined>(
    () => {
      const payload: PayloadPost = {
        active: active,
        effectiveDateEnd: effectiveDateEnd ? parseISO(effectiveDateEnd).toISOString() : undefined,
        effectiveDateStart: effectiveDateEnd
          ? parseISO(effectiveDateStart).toISOString()
          : undefined,
        animalReferenceTypeId: Number(referenceTypeId),
        reference: reference,
        animalId: props.animalId,
      };

      if (props.editId) {
        return animalReferenceService.patch(props.editId, payload);
      } else {
        return animalReferenceService.post(payload);
      }
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKey.AnimalReferences);
        await queryClient.invalidateQueries(QueryKey.Animals);
      },
    },
  );

  const deleteItem = async (): Promise<void> => {
    if (!props.editId) {
      return;
    }
    setIsDeleting(true);
    try {
      await animalReferenceService.delete(props.editId);
      await queryClient.invalidateQueries(QueryKey.AnimalReferences);
      setDeleteConfirmModalVisible(false);
      setIsDeleting(false);
      props.onClose();
    } catch (err) {
      logger.error('This item failed to be deleted', err);
      setDeleteConfirmModalErrorMessage('This item could not be deleted.');
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    const invalidElements = document.querySelectorAll('input.form-control:invalid');
    if (invalidElements.length > 0) {
      invalidElements[0].closest('.form-group')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [validated]);

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      await mutation.mutateAsync();
      props.onClose();
    } catch (err) {
      logger.error(`Animal reference could not be saved.`, err);
      setErrorMessage(
        t(`{{typeLabel}} could not be saved.`, {
          typeLabel: t('Animal Reference'),
        }),
      );
    }
  };

  const placeholder = <FormPlaceholder showDeleteButton={!!props.editId} />;

  const form = (
    <Form noValidate onSubmit={handleFormSubmit} validated={validated}>
      <FormGroup className="form-group mb-3" controlId="formReference">
        <Form.Label>
          {t('Reference')} <Required />
        </Form.Label>
        <Form.Control
          name={'reference'}
          onChange={(e): void => setReference(e.target.value)}
          placeholder={t('Example: {{value}}', {
            value: t('animalReferenceModal|referenceExample'),
          })}
          required
          type={'text'}
          value={reference}
        />
        <Form.Text className={'text-muted'}>{t('animalReferenceModal|referenceText')}</Form.Text>
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </FormGroup>

      <Form.Group className="form-group mb-3" controlId="formAnimalReference">
        <Form.Label>
          {t('Type')} <Required />
        </Form.Label>
        <AnimalReferenceTypeSelect
          name={'animalReferenceTypeId'}
          onChange={(e): void => setReferenceTypeId(e.target.value)}
          onCloseAddModal={(): void => setShowAddReferenceTypeModal(false)}
          required
          showAddModal={showAddReferenceTypeModal}
          value={referenceTypeId}
        />
        <Form.Text className="text-muted">
          <span>{t('Choose a reference type from the dropdown or')}</span>
          <Button
            onClick={(): void => setShowAddReferenceTypeModal(true)}
            size={'sm'}
            type={'button'}
            variant={'link'}
          >
            {t('add reference')}
          </Button>
        </Form.Text>
      </Form.Group>

      <FormGroup className="form-group mb-3" controlId="formActive">
        <Form.Label>
          {t('animalReferenceModal|activeLabel')} <Required />
        </Form.Label>
        <Form.Switch
          checked={active}
          className={'me-3'}
          id={`form-active`}
          label={active ? 'Active' : 'Inactive'}
          onChange={(e): void => setActive(e.target.checked)}
        />

        <Form.Text className={'text-muted'}>{t('Is this identifier currently active?')}</Form.Text>
      </FormGroup>
      <FormGroup className="form-group mb-3" controlId="formEffectiveDateStart">
        <Form.Label>{t('Effective Date: Start')}</Form.Label>
        <Form.Control
          aria-label={t('Effective Date: Start')}
          onChange={(event): void => setEffectiveDateStart(event.target.value)}
          required={false}
          type={'date'}
          value={effectiveDateStart}
        />
        <Form.Text className={'text-muted'}>
          {t('animalReferenceModal|effectiveDateStartHelpText')}
        </Form.Text>
      </FormGroup>
      <FormGroup className="form-group mb-3" controlId="formEffectiveDateEnd">
        <Form.Label>{t('Effective Date: End')}</Form.Label>
        <Form.Control
          aria-label={t('Effective Date: End')}
          onChange={(event): void => setEffectiveDateEnd(event.target.value)}
          required={false}
          type={'date'}
          value={effectiveDateEnd}
        />
        <Form.Text className={'text-muted'}>
          {t('animalReferenceModal|effectiveCeaceDateText')}
        </Form.Text>
      </FormGroup>

      <AlertErrorForModal message={errorMessage} />
      <div className="modal-footer modal-footer-in-form">
        <ButtonModalCancel
          disabled={mutation.isLoading || query.isLoading}
          onClick={props.onClose}
        />
        {props.editId && (
          <ButtonModalDelete
            busy={mutation.isLoading || isDeleting}
            disabled={isDeleting || mutation.isLoading || query.isLoading}
            onClick={(): void => setDeleteConfirmModalVisible(true)}
          />
        )}
        <ButtonModalSave
          busy={mutation.isLoading}
          disabled={mutation.isLoading || query.isLoading}
        />
      </div>
    </Form>
  );

  return (
    <Modal
      fullscreen={'md-down'}
      onClose={props.onClose}
      size={'lg'}
      title={`${props.editId ? t('Modify') : t('common|add')} ${t('Animal Reference')}`}
      visible={true}
    >
      {query.isError && (
        <AlertErrorForModal
          message={t(`common|unexpectedRetrievalError`, {
            value: t('entity|animalReference'),
          })}
        />
      )}
      {query.isFetching ? placeholder : form}
      <ConfirmModal
        busy={isDeleting}
        cancelLabel={t('Cancel')}
        cancelOnClick={(): void => setDeleteConfirmModalVisible(false)}
        errorMessage={deleteConfirmModalErrorMessage}
        okLabel={t(`Yes, delete this {{value}}`, { value: t('reference') })}
        okOnClick={deleteItem}
        okVariant={ButtonVariant.Danger}
        title={t(`Delete this {{value}}`, { value: t('reference') })}
        visible={deleteConfirmModalVisible}
      >
        {t('Are you sure you want to delete this {{value}}?', {
          value: t('reference'),
        })}
      </ConfirmModal>
    </Modal>
  );
};

export default AnimalReferenceModal;
