// Copyright © 2023 CATTLEytics Inc.

declare global {
  interface Window {
    __APP_CONFIG__?: Record<string, string>;
  }
}

export const getEnv = (key: string): string | undefined => {
  if (window.__APP_CONFIG__) {
    const value = window.__APP_CONFIG__[`REACT_APP_${key}`];
    if (value) {
      return value;
    }
  }
  return process.env[`REACT_APP_${key}`];
};
