// Copyright © 2023 CATTLEytics Inc.
import { createContext, Provider, useContext } from 'react';

type Return<ContextValue extends any> = readonly [
  () => ContextValue,
  Provider<ContextValue | undefined>,
];

export const createGenericContext = <ContextValue extends any>(
  name?: string,
): Return<ContextValue> => {
  const context = createContext<ContextValue | undefined>(undefined);

  const useGenericContext = (): ContextValue => {
    const contextValue = useContext(context);

    if (!contextValue) {
      throw new Error(`${name || 'unnamed'} hook must be used within a Provider`);
    }

    return contextValue as ContextValue;
  };

  return [useGenericContext, context.Provider] as const;
};
