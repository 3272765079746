// Copyright © 2024 CATTLEytics Inc.
import { useMemo } from 'react';

import { dateFormat } from '../../../shared';
import { formatDate } from '../../utilities';
import { useTimeline } from './TimelineProvider';

type Return = {
  endDate: string;
  startDate: string;
};

export const useFormattedTimelineDates = (): Return => {
  const { dateStart, dateEnd } = useTimeline();
  const startDate = useMemo(() => formatDate(dateStart, dateFormat), [dateStart]);
  const endDate = useMemo(() => formatDate(dateEnd, dateFormat), [dateEnd]);

  return { startDate, endDate };
};
