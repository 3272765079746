// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { useTranslation } from 'react-i18next';

import Page from '../../common/components/Page';
import ListsTable from './ListsTable';

/**
 * Lists page component to show a list of all lists like breeds, genders, colours etc..
 */
const ListsPage = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Page title={t('listsPage|title')}>
      <ListsTable />
    </Page>
  );
};

export default ListsPage;
