// Copyright © 2023 CATTLEytics Inc.
import { FC, useState } from 'react';
import { MdAdd } from 'react-icons/md';

import Button from '../../common/components/Button';
import WhiteboardAddNewModal from './WhiteboardAddNewModal';

const AddNewFloatButtonModal: FC = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Button
        className="btn-floating me-2"
        onClick={(): void => setShowModal(true)}
        variant="primary"
      >
        <MdAdd style={{ height: '100%' }} />
      </Button>
      {showModal && <WhiteboardAddNewModal onClose={(): void => setShowModal(false)} />}
    </>
  );
};

export default AddNewFloatButtonModal;
