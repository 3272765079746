// Copyright © 2024 CATTLEytics Inc.

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { QueryKey } from '../../shared';
import Modal from './Modal';

interface ReleaseModalProps {
  onClose: () => void;
  visible?: boolean;
}

export function ReleaseModal({ visible = false, onClose }: ReleaseModalProps): JSX.Element {
  const { t } = useTranslation();

  const getWebReleaseNotes = async (): Promise<string> => {
    if (process.env.NODE_ENV === 'development') {
      return t('dev');
    }
    try {
      const response = await fetch(`/RELEASE`);
      return await response.text();
    } catch (err) {
      return t('unknown');
    }
  };

  const queryWebReleaseNotes = useQuery([QueryKey.WebReleaseNotes], () => getWebReleaseNotes(), {
    cacheTime: 60_000,
    refetchOnWindowFocus: false,
  });

  const releaseNotes = queryWebReleaseNotes?.data;

  return (
    <Modal onClose={onClose} size={'lg'} title={t('Release Notes')} visible={visible}>
      {releaseNotes
        ? releaseNotes.split('\n').map((line, i) => (
            <span key={i}>
              {line}
              <br />
            </span>
          ))
        : null}
    </Modal>
  );
}
