// Copyright © 2023 CATTLEytics Inc.

import { faTrash, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { resolve } from 'inversify-react';
import React from 'react';
import { ButtonGroup, Dropdown, Table } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { TYPES } from '../../../types';
import Button, { ButtonVariant } from '../../common/components/Button';
import ConfirmModal from '../../common/components/ConfirmModal';
import Spinner from '../../common/components/Spinner';
import Logger from '../../logger/logger';
import { BreedingPlan } from '../../shared';

/**
 * Defines the input properties for the BreedingPlanList component.
 */
export interface Props extends WithTranslation {
  /**
   * Breeding plans.
   */
  breedingPlans: BreedingPlan[];

  /**
   * loading status.
   */
  loading?: boolean;

  /**
   * method to delete a breeding plan by id
   */
  onDeleteBreedingPlan: (planId: number) => void;
}

/**
 * Describes the state of the @see BreedingPlanList component.
 */
interface State {
  /**
   * The error message to display in the delete confirmation dialog.
   */
  deleteConfirmErrorMessage?: string;

  /**
   * The busy/not-busy status of the confirm modal for deletion.
   */
  deleteConfirmModalBusy: boolean;

  /**
   * The hide/show status of the confirm modal for deletion.
   */
  deleteConfirmModalVisible: boolean;

  /**
   * The breeding plan ID to delete.
   */
  planIdToDelete?: number;
}

/**
 * Breeding plans root component.
 */
class BreedingPlanList extends React.Component<
  Props & WithTranslation & RouteComponentProps,
  State
> {
  /**
   * Creates an instance of BreedingPlanList.
   * @param {Props} props The component's input properties and WithTranslation props
   * @memberof BreedingPlanList
   */
  constructor(props: Props & WithTranslation & RouteComponentProps) {
    super(props);
    this.state = {
      deleteConfirmModalVisible: false,
      deleteConfirmModalBusy: false,
    };
  }

  @resolve(TYPES.logger)
  private logger!: Logger;

  render(): JSX.Element {
    if (this.props.loading) {
      return <Spinner />;
    }

    const rows =
      this.props.breedingPlans.length > 0 ? (
        this.props.breedingPlans.map((plan) => (
          <tr key={plan.id}>
            <td>{plan.id}</td>
            <td>{plan.name}</td>
            <td>{plan.description}</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
              <Dropdown as={ButtonGroup}>
                <Button
                  label={this.props.t('Manage')}
                  onClick={(): void => {
                    this.props.history.push(`/operations/breeding-plans/${plan.id}`);
                  }}
                  size="sm"
                  variant="outline-primary"
                />
                <Dropdown.Toggle size="sm" split variant="outline-primary" />
                <Dropdown.Menu align="end">
                  <Dropdown.Item href="#">
                    <FontAwesomeIcon icon={faWrench} />
                    {this.props.t('Manage Animals')}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    data-testid="delete-plan"
                    onClick={(): void =>
                      this.setState({ deleteConfirmModalVisible: true, planIdToDelete: plan.id })
                    }
                  >
                    <FontAwesomeIcon icon={faTrash} />
                    {this.props.t('Delete Plan')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td className="text-center" colSpan={7}>
            <em>{this.props.t('breedingPlanList|noPlansCreatedMessage')}</em>
          </td>
        </tr>
      );

    return (
      <React.Fragment>
        <Table striped>
          <thead>
            <tr>
              <th>{this.props.t('ID')}</th>
              <th>{this.props.t('Name')}</th>
              <th>{this.props.t('Description')}</th>
              <th title={this.props.t('Number of animals enrolled')}>{this.props.t('Animals')}</th>
              <th title={this.props.t('Number of animal groups')}>
                {this.props.t('Animal Groups')}
              </th>
              <th>{this.props.t('Performance')}</th>
              <th>{this.props.t('breedingPlanList|actionsTableHeader')}</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
        <ConfirmModal
          busy={this.state.deleteConfirmModalBusy}
          cancelLabel={this.props.t('Cancel')}
          cancelOnClick={(): void =>
            this.setState({ deleteConfirmModalVisible: false, deleteConfirmModalBusy: false })
          }
          errorMessage={this.state.deleteConfirmErrorMessage}
          okLabel={this.props.t('Yes, delete this plan')}
          okOnClick={async (): Promise<void> => {
            this.setState({ deleteConfirmModalBusy: true });
            try {
              this.props.onDeleteBreedingPlan(this.state.planIdToDelete as number);
              this.setState({ deleteConfirmModalVisible: false, deleteConfirmModalBusy: false });
            } catch (err) {
              this.logger.error('Breeding plan failed to be deleted', err);
              this.setState({
                deleteConfirmErrorMessage: this.props.t('breedingPlanList|planDeleteError'),
                deleteConfirmModalBusy: false,
              });
            }
          }}
          okVariant={ButtonVariant.Danger}
          title={this.props.t('Delete Breeding Plan')}
          visible={this.state.deleteConfirmModalVisible}
        >
          {this.props.t('Are you sure you want to delete this plan?')}
        </ConfirmModal>
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(BreedingPlanList));
