// Copyright © 2023 CATTLEytics Inc.

import { format, startOfToday } from 'date-fns';
import { inject, injectable } from 'inversify';

import { TYPES } from '../../../types';
import JsonApiQueryParams from '../../common/interfaces/jsonApiQueryParams';
import Api2Service from '../../common/services/api2Service';
import type Logger from '../../logger/logger';
import {
  BreedingProgram,
  BreedingProgramStats,
  BreedingProgramStatsRequestParams,
} from '../../shared';

const path = '/v1/breeding-programs';

/**
 * Service for managing breeding program data via REST API.
 */
@injectable()
export default class BreedingProgramService {
  private logger: Logger;

  /**
   * Service for accessing the HTTP API.
   */
  private apiService: Api2Service;

  /**
   * Creates an instance of BreedingProgramService.
   * @param {Logger} logger Logger instance.
   * @param {Api2Service} apiService API Service instance
   * @memberof BreedingProgramService
   */
  constructor(
    @inject(TYPES.logger) logger: Logger,
    @inject(TYPES.api2Service) apiService: Api2Service,
  ) {
    this.logger = logger;
    this.apiService = apiService;
  }

  async list(params?: Record<string, string>): Promise<BreedingProgram[]> {
    const result = await this.apiService.get<BreedingProgram[]>(path, params);
    if (!result) {
      return [];
    }

    const data = result as BreedingProgram[];
    data.forEach((item) => this.cast(item));
    return data;
  }

  async get(id: number, params?: Record<string, string>): Promise<BreedingProgram | undefined> {
    const result = await this.apiService.get<BreedingProgram>(`${path}/${id}`, params);
    if (!result) {
      return undefined;
    }

    const data = result as BreedingProgram;
    this.cast(data);
    return data;
  }

  async post(attributes: Record<string, string>): Promise<BreedingProgram | undefined> {
    const result = await this.apiService.post<BreedingProgram>(`${path}`, attributes);
    if (!result) {
      return undefined;
    }
    this.cast(result as BreedingProgram);
    return result;
  }

  async patch(
    id: number,
    attributes: Record<string, string | number | null>,
  ): Promise<BreedingProgram | undefined> {
    // make sure record identifier is included
    attributes.id = id;

    const result = await this.apiService.patch(`${path}/${id}`, attributes);
    if (!result) {
      return undefined;
    }

    const data = result as BreedingProgram;
    this.cast(data);
    return data;
  }

  async delete(id: number): Promise<null> {
    return await this.apiService.delete(`${path}/${id}`);
  }

  async getBreedingProgramStatistics(
    programId: number,
    stats: Record<string, BreedingProgramStatsRequestParams>,
  ): Promise<Record<string, BreedingProgramStats> | undefined> {
    const statistics = Object.entries(stats).map(([key, value]) => {
      const start = format(value.startDate, 'yyyy-MM-dd');
      const end = format(value.endDate ?? startOfToday(), 'yyyy-MM-dd');
      return `${key},${start},${end}`;
    });
    const params: JsonApiQueryParams = {
      fields: {
        breedingPrograms: ['statistics'],
      },
      statistics,
    };
    const result = await this.apiService.get<BreedingProgram>(
      `/breedingPrograms/${programId}`,
      params,
    );
    if (!result?.data) {
      return undefined;
    }
    const program = result.data as BreedingProgram;
    return program.attributes?.statistics;
  }

  /**
   * Casts fields into javascript types.
   * @param item
   * @return cast object
   */
  cast(item: BreedingProgram): void {
    if (!item) {
      return;
    }
  }
}
