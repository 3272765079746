// Copyright © 2023 CATTLEytics Inc.
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Animal } from '../../shared';
import { AnimalStatus } from '../enums/animalStatus';

const StatusToLabelMap: Record<string, string> = {
  [AnimalStatus.Active]: 'Active',
  [AnimalStatus.Dead]: 'Dead',
  [AnimalStatus.DeadAndSold]: 'Dead and Sold',
  [AnimalStatus.Sold]: 'Sold',
  [AnimalStatus.Unknown]: 'Unknown',
};

export const useAnimalStatusLabel = (animal: Animal | undefined): string => {
  const { t } = useTranslation();
  return useMemo(
    () => t(StatusToLabelMap[animal?.status ?? AnimalStatus.Unknown]) as string,
    [animal, t],
  );
};
