// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useState } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import Button, { ButtonVariant } from '../../common/components/Button';
import ConfirmModal from '../../common/components/ConfirmModal';
import DataTable, { DataTableHeader, DataTableRow } from '../../common/components/DataTable';
import { Sort } from '../../common/enums';
import DiagnosisRegimeDetailService from '../../common/services/diagnosisRegimeDetailService';
import {
  IconCancel,
  IconCheck,
  IconConditional,
  IconDelete,
  IconEdit,
  IconTreatment,
  IconWait,
} from '../../common/utilities';
import { QueryKey } from '../../shared';
import { DiagnosisRegimeDetailType } from '../../shared';
import DiagnosisRegimeDetailModal from './DiagnosisRegimeDetailModal';

interface Props {
  /**
   * Only show detail for the specified diagnosis regime.
   */
  diagnosisRegimeId?: number;

  /**
   * Number of events to show.
   */
  initialLimit?: number;

  /**
   * Number of rows to skip.
   */
  initialOffset?: number;

  /**
   * Table sort direction.
   */
  initialSortDirection?: Sort;

  /**
   * Table sort field.
   */
  initialSortField?: string;
}

/**
 * Table of diagnosis regime details.
 */
const DiagnosisRegimeDetailTable = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();
  const diagnosisRegimeDetailService = useInjection<DiagnosisRegimeDetailService>(
    TYPES.diagnosisRegimeDetailService,
  );

  const [deleteConfirmModalErrorMessage, setDeleteConfirmModalErrorMessage] = useState<string>('');
  const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState<boolean>(false);
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [editId, setEditId] = useState<number>(0);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();

  const [limit, setLimit] = useState<number>(props.initialLimit ?? 25);
  const [offset, setOffset] = useState<number>(props.initialOffset ?? 0);
  const [sortField, setSortField] = useState<string>(props.initialSortField ?? 'step');
  const [sortDirection, setSortDirection] = useState<Sort>(
    props.initialSortDirection ?? Sort.Ascending,
  );

  const query = useQuery(
    [
      QueryKey.DiagnosisRegimeDetails,
      limit,
      offset,
      sortField,
      sortDirection,
      props.diagnosisRegimeId,
    ],
    () =>
      diagnosisRegimeDetailService.list({
        limit: String(limit),
        offset: String(offset),
        sortField: sortField,
        diagnosisRegimeId: String(props.diagnosisRegimeId),
        sortDirection: String(sortDirection),
      }),
    { keepPreviousData: true },
  );

  const queryClient = useQueryClient();

  const deleteItem = async (): Promise<void> => {
    if (!deleteId) {
      return;
    }
    setIsDeleting(true);
    try {
      await diagnosisRegimeDetailService.delete(deleteId);
      await queryClient.invalidateQueries(QueryKey.DiagnosisRegimeDetails);
      setDeleteConfirmModalVisible(false);
    } catch (err) {
      console.error('This item failed to be deleted', err);
      setDeleteConfirmModalErrorMessage('This item could not be deleted.');
    }
    setIsDeleting(false);
  };

  const showEditModal = (id: number): void => {
    setEditModalVisible(true);
    setEditId(id);
  };

  const showDeleteConfirmModal = (id: number): void => {
    setDeleteConfirmModalVisible(true);
    setDeleteId(id);
  };

  if (query.isError) {
    return <AlertError message={t('common|animalRetrievalError')} />;
  }

  const headers: DataTableHeader[] = [
    {
      name: 'step',
      label: t('Step'),
      classNameMobile: 'col-2',
    },
    {
      name: 'description',
      label: t('Description'),
      infoTooltip: t('Description of the action to take'),
      classNameMobile: 'col-8',
    },
    {
      name: 'type',
      label: t('Type'),
      infoTooltip: t('Is this step a treatment or a condition'),
      classNameMobile: 'col-12',
    },
    {
      name: 'day',
      label: t('Day'),
      infoTooltip: t('diagnosisRegimeDetailTable|stepDayTooltip'),
      classNameMobile: 'col-2',
    },
    {
      name: 'ifYesStep',
      label: t('If Yes Step'),
      infoTooltip: t('diagnosisRegimeDetailModal|stepIfConditionIsTrue'),
      hideMobile: true,
    },
    {
      name: 'ifNoStep',
      label: t('If No Step'),
      infoTooltip: t('diagnosisRegimeDetailModal|stepIfConditionIsTrue'),
      hideMobile: true,
    },
    {
      name: 'lastStep',
      label: t('diagnosisRegimeDetailTable|lastStepLabel'),
      infoTooltip: t('diagnosisRegimeDetailTable|lastStepTooltip'),
      hideMobile: true,
    },
    { name: 'actions', hideMobile: true, sortable: false, label: 'Actions' },
  ];

  const data: DataTableRow[] = !query.data
    ? []
    : query.data.map((detail) => ({
        id: String(detail.id),
        step: String(detail.step),
        description: (
          <div className={'text-truncate text-truncate-clamp-2'}>{detail.description}</div>
        ),
        type: ((): JSX.Element => {
          switch (detail.type) {
            case DiagnosisRegimeDetailType.Treatment:
              return <IconTreatment />;
            case DiagnosisRegimeDetailType.Conditional:
              return <IconConditional />;
            case DiagnosisRegimeDetailType.WaitAndSee:
              return <IconWait />;
          }
        })(),
        day: String(detail.day),
        ifYesStep: detail.ifYesStep
          ? String(detail.ifYesStep)
          : detail.ifYesStep === 0
          ? 'stop'
          : '-',
        ifNoStep: detail.ifNoStep ? String(detail.ifNoStep) : '-',
        lastStep: detail.lastStep ? <IconCheck /> : '-',
        actions: (
          <div onClick={(e): void => e.stopPropagation()}>
            <Dropdown as={ButtonGroup} className={'text-nowrap'}>
              <Button
                disabled={detail.systemDefined}
                onClick={(): void => showEditModal(detail.id)}
                size="sm"
                variant="outline-primary"
              >
                <IconEdit className={'me-1'} /> {t('Edit')}
              </Button>
              <Dropdown.Toggle size="sm" split variant="outline-primary" />
              <Dropdown.Menu align="end">
                <Dropdown.Item
                  disabled={detail.systemDefined}
                  onClick={(): void => showEditModal(detail.id)}
                >
                  <IconEdit className={'me-1'} /> {t('Edit')}
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={detail.systemDefined}
                  onClick={(): void => showDeleteConfirmModal(detail.id)}
                >
                  <IconDelete className={'me-1'} />
                  {t('Delete')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),
      }));

  return (
    <>
      <DataTable
        className={'mt-3'}
        data={data}
        headers={headers}
        isLoading={query.isLoading}
        isPreviousData={query.isPreviousData}
        limit={limit}
        messageNoData={t('diagnosisRegimeDetailModal|noRegimeDetailMessage')}
        offset={offset}
        onLimitChange={(newLimit): void => setLimit(newLimit)}
        onOffsetChange={(newOffset): void => setOffset(newOffset)}
        onRowClick={(row): void => showEditModal(Number(row.id))}
        onSortDirectionChange={(newSortDirection): void => setSortDirection(newSortDirection)}
        onSortFieldChange={(newSortField): void => setSortField(newSortField)}
        sortDirection={sortDirection}
        sortField={sortField}
      />
      {editModalVisible && (
        <DiagnosisRegimeDetailModal
          editId={editId}
          onClose={(): void => setEditModalVisible(false)}
        />
      )}
      <ConfirmModal
        busy={isDeleting}
        cancelIcon={IconCancel}
        cancelLabel={'Cancel'}
        cancelOnClick={(): void => setDeleteConfirmModalVisible(false)}
        errorMessage={deleteConfirmModalErrorMessage}
        okIcon={IconDelete}
        okLabel={t(`Yes, delete this {{value}}`, { value: 'step' })}
        okOnClick={deleteItem}
        okVariant={ButtonVariant.Danger}
        title={t(`Delete this {{value}}`, { value: 'step' })}
        visible={deleteConfirmModalVisible}
      >
        {t('Are you sure you want to delete this {{value}}?', { value: 'step' })}
      </ConfirmModal>
    </>
  );
};

export default DiagnosisRegimeDetailTable;
