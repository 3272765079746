// Copyright © 2023 CATTLEytics Inc.

import { resolve } from 'inversify-react';
import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';

import { TYPES } from '../../../types';
import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import Button, { ButtonVariant } from '../../common/components/Button';
import Modal from '../../common/components/Modal';
import type Logger from '../../logger/logger';
import { BreedingPlan } from '../../shared';

/**
 * @description Defines the input properties for the BreedingPlanCreateModal component.
 * @interface Props
 */
export interface Props extends WithTranslation {
  /**
   * @description Callback when modal is closed.
   * @memberOf Props
   */
  onClose: () => void;

  /**
   * @description callback to create a breeding plan.
   * @memberOf Props
   */
  onCreateBreedingPlan: (breedingPlan: BreedingPlan) => void;
}

/**
 * @description Describes the state of the @see BreedingPlanCreateModal component.
 * @interface State */
export interface State {
  /**
   * @description The breeding plan we will be creating.
   * @type {BreedingPlan}
   * @memberOf State   */
  breedingPlan: BreedingPlan;

  /**
   * @description Whether or not we are in the process of making an API call.
   * @type {boolean}
   * @memberOf State   */
  busy: boolean;

  /**
   * @description An error message.
   * @type {string}
   * @memberOf State   */
  errorMessage?: string;

  /**
   * @description Whether or not the form in this modal is valid.
   * @type {boolean}
   * @memberOf State   */
  validated: boolean;
}

/**
 * @description Breeding plans create modal component.
 * @class BreedingPlanCreateModal
 * @extends {React.Component<Props>}
 */
class BreedingPlanCreateModal extends React.Component<Props, State> {
  /**
   * Creates an instance of BreedingPlanCreateModal.
   * @param {Props} props The component's input properties and WithTranslation props
   * @memberOf BreedingPlanCreateModal
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      breedingPlan: {
        id: 0,
        name: '',
        description: '',
        createdDate: new Date(),
        modifiedDate: new Date(),
      }, //breedingPlanDefault(),
      busy: false,
      validated: false,
    };
  }

  /**
   * @description Logger.
   * @private
   * @type {Logger}
   * @memberOf BreedingPlanCreateModal
   */
  @resolve(TYPES.logger)
  private logger!: Logger;

  /**
   * @description Renders the component.
   * @returns {JSX.Element}
   * @memberOf BreedingPlanCreateModal
   */
  render(): JSX.Element {
    return (
      <React.Fragment>
        <Modal
          onClose={(): void => this.props.onClose()}
          title={this.props.t('Create Breeding Plan')}
          visible={true}
        >
          <Form noValidate={true} onSubmit={this.onFormSubmit} validated={this.state.validated}>
            <Form.Group className="mb-3" controlId="formBreedingPlanName">
              <Form.Label>{this.props.t('Breeding plan name')}</Form.Label>
              <Form.Control
                name="name"
                onChange={this.onInputChange}
                placeholder={this.props.t('Enter a name')}
                required
                type="text"
                value={this.state.breedingPlan.name}
              />
              <Form.Control.Feedback type="invalid">
                {this.props.t('common|fieldRequiredFeedback')}
              </Form.Control.Feedback>
              <Form.Text className="text-muted">
                {this.props.t('breedingPlanCreateModal|planNameInstructions')}
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBreedingPlanDescription">
              <Form.Label>{this.props.t('Breeding plan description')}</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                placeholder={this.props.t('Enter a description')}
                rows={3}
              />
              <Form.Text className="text-muted">
                {this.props.t('breedingPlanCreateModal|descriptionInstructions')}
              </Form.Text>
            </Form.Group>
            <AlertErrorForModal message={this.state.errorMessage} />
            <div className="modal-footer modal-footer-in-form">
              <Button
                disabled={this.state.busy}
                onClick={(): void => this.props.onClose()}
                variant={ButtonVariant.Secondary}
              >
                Cancel
              </Button>
              <Button busy={this.state.busy} type="submit">
                Create
              </Button>
            </div>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }

  /**
   * @description Handles input changes on form elements.
   * @param {ChangeEvent<HTMLInputElement>} event The input element that has changed.
   * @returns {void}
   * @memberOf BreedingPlanCreateModal
   */
  private onInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const breedingPlan: BreedingPlan = { ...this.state.breedingPlan };
    breedingPlan[event.target.name] = event.target.value;
    this.setState({ breedingPlan: breedingPlan });
  };

  /**
   * @description Renders the component.
   * @param {React.FormEvent<HTMLFormElement>} event The form that was submitted.
   * @returns {Promise<void>}
   * @memberOf BreedingPlanCreateModal
   */
  private onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    this.setState({ validated: true });

    if (!valid) {
      return;
    }

    this.setState({ busy: true, errorMessage: '' });
    try {
      await this.props.onCreateBreedingPlan(this.state.breedingPlan);
      this.setState({ busy: false });
      this.props.onClose();
    } catch (err) {
      this.logger.error('Breeding plan failed to be created.', err, this.state.breedingPlan);
      this.setState({
        errorMessage: this.props.t('breedingPlanCreateModal|creationError'),
        busy: false,
      });
    }
  };
}

export default withTranslation()(BreedingPlanCreateModal);
