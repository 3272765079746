// Copyright © 2023 CATTLEytics Inc.

import convert, { Unit } from 'convert-units';
import { useInjection } from 'inversify-react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Alert, Form, FormGroup, InputGroup, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Clamp from 'react-multiline-clamp';
import { useQuery } from 'react-query';

import { TYPES } from '../../../types';
import Button from '../../common/components/Button';
import ButtonLess from '../../common/components/ButtonLess';
import ButtonMore from '../../common/components/ButtonMore';
import ImageThumbnail from '../../common/components/ImageThumbnail';
import ImageUploaded from '../../common/components/ImageUploaded';
import Required from '../../common/components/Required';
import Product from '../../common/entities/product';
import ProductAdministration from '../../common/entities/productAdministration';
import DiagnosisRegimeDetailService from '../../common/services/diagnosisRegimeDetailService';
import ProductAdministrationService from '../../common/services/productAdministrationService';
import SettingsContext, { SettingsAnimalsContext } from '../../common/store/settings-context';
import { IconMedicine } from '../../common/utilities';
import { DiagnosisRegimeDetail } from '../../shared';
import { QueryKey } from '../../shared/enums';

export interface ProductChange {
  /**
   * The product name or custom product name.
   */
  productCustomName: string;

  /**
   * The dosage amount in mL.
   * Example: The "5" in "5 mL per 100-pound weight"
   */
  productDosage: number;

  /**
   * The dosage factor amount in lbs.
   * Example: The "100" in "5 mL per 100-pound weight"
   */
  productDosageFactor: number;

  /**
   * The ID of the product, undefined indicates a custom product.
   */
  productId?: number;

  /**
   * Where the product was administered.
   */
  productLocation?: string;

  /**
   * Product administration route.
   */
  productRoute: string;

  /**
   * Whether the product details are valid. (Basically to ensure dosage is set)
   */
  valid: boolean;
}

interface Props {
  /**
   * Weight of animal in kg. If omitted typical weight from settings will be used.
   */
  animalWeight?: number;

  diagnosisId: number;

  /**
   * The identifier of the diagnosis regime detail
   * that contains the product information we need.
   */
  diagnosisRegimeDetailId?: number;

  /**
   * Used to indicate if selected animal is a calf for weight calculation
   */
  isCalf?: boolean;

  /**
   * Callback when a product is selected or changed.
   * @param productId undefined indicates a custom product
   */
  onProductChange?: (productChange: ProductChange) => void;
}

/**
 * A decision tree to determine which diagnosis regime detail records to create tasks for.
 */
const DiagnosisTreatWithProduct = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const diagnosisRegimeDetailService = useInjection<DiagnosisRegimeDetailService>(
    TYPES.diagnosisRegimeDetailService,
  );
  const productAdminService = useInjection<ProductAdministrationService>(
    TYPES.productAdministrationService,
  );

  const [customProduct, setCustomProduct] = useState<boolean>(false);
  const [customProductName, setCustomProductName] = useState<string>('');
  const [dosage, setDosage] = useState<string>('');
  const [dosageFactor] = useState<string>('100');
  const [route, setRoute] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [product, setProduct] = useState<Product | undefined>();
  const [productAdmins, setProductAdmins] = useState<ProductAdministration[]>([]);
  // select product administration (which has a product relation)
  const [productAdmin, setProductAdmin] = useState<ProductAdministration | undefined>();
  const [calculatedDosage, setCalculatedDosage] = useState<number>();

  const queryDrd = useQuery<DiagnosisRegimeDetail | undefined>(
    [QueryKey.DiagnosisRegimeDetails, 'get', props.diagnosisRegimeDetailId],
    () => diagnosisRegimeDetailService.get(props.diagnosisRegimeDetailId ?? 0),
    {
      enabled: !!props.diagnosisRegimeDetailId,
      retry: true,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnReconnect: false,
    },
  );

  const queryProductAdmins = useQuery<ProductAdministration[]>(
    [QueryKey.ProductAdministrations, 'list', props.diagnosisId],
    () =>
      productAdminService.list({
        sortField: 'id',
        sortDirection: 'DESC',
        diagnosisId: String(props.diagnosisId),
      }),
    {
      enabled: !!props.diagnosisId,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnReconnect: false,
    },
  );

  /**
   * for use with handling a product selection from the product
   * list (inc. custom product)
   */
  const productSelect = useCallback((product?: Product): void => {
    if (!product) {
      setProduct(undefined);
      setCustomProductName('');
      setCustomProduct(true);
    } else {
      setProduct(product);
      setCustomProductName(product.name);
      setCustomProduct(false);
    }
  }, []);

  const productAdminSelect = useCallback((productAdmin?: ProductAdministration): void => {
    if (!productAdmin) {
      setProductAdmin(undefined);
    } else {
      setProductAdmin(productAdmin);
    }
  }, []);

  useEffect(() => {
    if (queryProductAdmins.data) {
      setProductAdmins(queryProductAdmins.data);
      if (queryProductAdmins.data.length > 0) {
        productSelect(queryProductAdmins.data[0].product);
        productAdminSelect(queryProductAdmins.data[0]);
      }
    }
  }, [queryProductAdmins.data, productSelect, productAdminSelect]);

  const settingsAnimals = useContext(SettingsAnimalsContext);
  const settingsLocalization = useContext(SettingsContext);

  // if animal is a calf, use calf typical weight for weight calculation
  const animalWeight =
    props.animalWeight ??
    Number(props.isCalf ? settingsAnimals?.typicalCalfWeight : settingsAnimals?.typicalWeight);

  // when the dosage value changes, recalculate the calculated dosage
  useEffect(() => {
    if (productAdmin?.dosage) {
      const weightInLbs = convert(animalWeight).from('kg').to('lb');
      const multiplier = weightInLbs / Number(dosageFactor);

      setCalculatedDosage(Number(productAdmin.dosage) * multiplier);
    }
  }, [productAdmin, animalWeight, dosageFactor]);

  const imageUrl =
    product && product.imageUrlsSigned && product.imageUrlsSigned.length > 0
      ? product.imageUrlsSigned[0].signedUrl
      : undefined;

  const hasImages = product && product.imageUrlsSigned && product.imageUrlsSigned.length > 0;

  const images = hasImages && product.imageUrlsSigned ? product.imageUrlsSigned.slice(1) : [];

  return (
    <div className={'w-100'}>
      <p>{t('Choose a product from the list below:')}</p>
      <ListGroup className={'mb-4'}>
        {productAdmins.map((pa) => {
          const imageUrl =
            pa.product && pa.product.imageUrlsSigned && pa.product.imageUrlsSigned.length > 0
              ? pa.product.imageUrlsSigned[0].signedUrl
              : undefined;
          return (
            <ListGroup.Item
              action
              active={product?.id === pa.productId}
              className="d-flex justify-content-between align-items-start"
              key={pa.id}
              onClick={(): void => {
                productSelect(pa.product);
                productAdminSelect(pa);
              }}
              type={'button'}
            >
              <div>
                <ImageThumbnail
                  alt={pa?.product?.name}
                  height={'75px'}
                  src={imageUrl}
                  width={'75px'}
                />
              </div>
              <div className="ms-3 me-auto">
                <div>
                  <div className="fw-bold">{pa?.product?.name}</div>
                  <p className={'text-truncate text-truncate-clamp-2'}>
                    {pa?.product?.description}
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p>
                    <strong>{t('diagnosisTreatWithProduct|milkWithdrawalDay')}:</strong>{' '}
                    {pa?.meatWithdrawalDays ?? '-'}
                  </p>
                  <p>
                    <strong>{t('diagnosisTreatWithProduct|meatWithdrawalDay')}:</strong>{' '}
                    {pa?.milkWithdrawalDays ?? '-'}
                  </p>
                  <p></p>
                </div>
              </div>
            </ListGroup.Item>
          );
        })}
        <ListGroup.Item
          action
          active={!product}
          className="d-flex justify-content-between align-items-start"
          onClick={(): void => {
            productSelect();
            productAdminSelect();
          }}
          type={'button'}
        >
          <div>
            <ImageThumbnail fallbackIcon={IconMedicine} height={'75px'} width={'75px'} />
          </div>
          <div className="ms-3 me-auto">
            <div className="fw-bold">{t('Custom Product')}</div>
            <p className={'text-truncate text-truncate-clamp-2'}>
              {t('Use a different product then the ones in the list above')}
            </p>
          </div>
        </ListGroup.Item>
      </ListGroup>
      {product && (
        <div className={'d-flex'}>
          <div className={'me-3'} style={{ width: '150px' }}>
            {imageUrl && <ImageUploaded alt={product.name} src={imageUrl} />}
          </div>
          <div>
            <h1>{product.name}</h1>
            {product.description && (
              <section>
                <Clamp
                  lines={2}
                  showLessElement={({ toggle }): JSX.Element => (
                    <div className={'text-center'}>
                      <ButtonLess onClick={toggle} />
                    </div>
                  )}
                  showMoreElement={({ toggle }): JSX.Element => (
                    <div className={'text-center'}>
                      <ButtonMore onClick={toggle} />
                    </div>
                  )}
                  withToggle
                >
                  {product.description}
                </Clamp>
              </section>
            )}
            {product.instructions && (
              <section>
                <h2>{t('Product Instructions')}</h2>
                <Clamp
                  lines={2}
                  showLessElement={({ toggle }): JSX.Element => <ButtonLess onClick={toggle} />}
                  showMoreElement={({ toggle }): JSX.Element => <ButtonMore onClick={toggle} />}
                  withToggle
                >
                  {product.instructions}
                </Clamp>
              </section>
            )}

            <section>
              <h2>{t('Dosage and Administration')}</h2>
              <p>
                <strong>{t('Instructions')}:</strong> {productAdmin?.dosageInstructions ?? '-'}
              </p>
              <p>
                <strong>{t('Dosage')}:</strong> {productAdmin?.dosage ?? ''} mL per-
                {productAdmin?.dosageFactor}-pound-weight
              </p>
              <p>
                <strong>{t('Route')}:</strong> {productAdmin?.route ?? '-'}
              </p>
              <p>
                <strong>{t('Frequency')}:</strong> {productAdmin?.frequency ?? '-'}
              </p>
              <p>
                <strong>{t('diagnosisTreatWithProduct|milkWithdrawalDay')}:</strong>{' '}
                {productAdmin?.meatWithdrawalDays ?? '-'}
              </p>
              <p>
                <strong>{t('diagnosisTreatWithProduct|meatWithdrawalDay')}:</strong>{' '}
                {productAdmin?.milkWithdrawalDays ?? '-'}
              </p>
            </section>

            {images.length > 1 && (
              <section>
                <h2>{t('Images')}</h2>
                <div className={'d-flex flex-wrap justify-content-start mb-3 gap-2'}>
                  {hasImages &&
                    images.map((imageUrl, index) => (
                      <ImageThumbnail
                        alt={t('Product image {{value}}', { value: index })}
                        height={100}
                        key={index}
                        src={imageUrl.signedUrl ?? '/img/failure-image-cow.png'}
                        width={100}
                        withLightBox={true}
                      />
                    ))}
                  {!hasImages && <em>No images</em>}
                </div>
              </section>
            )}
            {product.notes && (
              <section>
                <h2>{t('Product notes')}</h2>
                <Clamp
                  lines={2}
                  showLessElement={({ toggle }): JSX.Element => <ButtonLess onClick={toggle} />}
                  showMoreElement={({ toggle }): JSX.Element => <ButtonMore onClick={toggle} />}
                  withToggle
                >
                  {product.notes}
                </Clamp>
              </section>
            )}
            {queryDrd.data && queryDrd.data.instructions && (
              <>
                <h2>{t('Regime specific product instructions')}</h2>
                <Clamp
                  lines={2}
                  showLessElement={({ toggle }): JSX.Element => <ButtonLess onClick={toggle} />}
                  showMoreElement={({ toggle }): JSX.Element => <ButtonMore onClick={toggle} />}
                  withToggle
                >
                  {queryDrd.data.instructions}
                </Clamp>
              </>
            )}
          </div>
        </div>
      )}
      {customProduct && (
        <div className={'d-flex'}>
          <div className={'me-3'}>
            <ImageThumbnail fallbackIcon={IconMedicine} height={'150px'} width={'150px'} />
          </div>
          <div>
            <h1>{t('Custom Product')}</h1>

            <FormGroup className="form-group mb-3" controlId="formDescription">
              <Form.Label>{t('Product Name')}</Form.Label>
              <Form.Control
                name={'customProductName'}
                onChange={(e): void => {
                  setCustomProductName(e.target.value);
                  props.onProductChange &&
                    props.onProductChange({
                      productId: product ? product.id : undefined,
                      productCustomName: e.target.value,
                      productDosage: Number(dosage),
                      productDosageFactor: Number(dosageFactor),
                      productRoute: route,
                      productLocation: location,
                      valid: !!dosage,
                    });
                }}
                placeholder={t('Product X')}
                value={customProductName}
              />
              <Form.Text className={'text-muted'}>
                {t('diagnosisTreatWithProduct|productDifferentNameInstructions')}
              </Form.Text>
            </FormGroup>
          </div>
        </div>
      )}
      <div className={'d-flex'}>
        <div className={'me-3'} style={{ width: '150px' }}></div>
        <div className={'flex-grow-1'}>
          <Alert variant={'info'}>
            <h3>Recommended dosage calculation</h3>
            <p>
              Animal Weight:{' '}
              <strong>
                <span>
                  {convert(animalWeight)
                    .from('kg')
                    .to(settingsLocalization?.weightUnit as Unit)
                    .toFixed(2)}{' '}
                  {settingsLocalization?.weightUnit}
                  {!props.animalWeight && '*'}
                </span>{' '}
                ={' '}
                <span>
                  {settingsLocalization?.weightUnit !== 'lb' && (
                    <>{convert(animalWeight).from('kg').to('lb').toFixed(2)} lbs</>
                  )}
                </span>
              </strong>
            </p>
            <p>
              {t('Calculated dosage')}:{' '}
              <strong style={{ fontSize: '1.2em' }}>{calculatedDosage?.toFixed(0)} mL</strong>
              <Button
                className={'ms-2'}
                onClick={(): void | undefined | 0 =>
                  calculatedDosage && setDosage(calculatedDosage.toFixed(0))
                }
                type={'button'}
                variant={'outline-primary'}
              >
                {t('Use')}
              </Button>
            </p>
            <p>
              <small>
                {!props.animalWeight &&
                  t('* No weight found for this animal, using typical weight')}
              </small>
            </p>
          </Alert>
          <FormGroup className="form-group mt-3 mb-3" controlId="formDosageAdministered">
            <Form.Label>
              {t('Dosage Administered')} <Required />
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                aria-describedby="dosage"
                aria-label={t('Dosage')}
                min={0}
                name={'dosage'}
                onChange={(e): void => {
                  setDosage(e.target.value);
                  props.onProductChange &&
                    props.onProductChange({
                      productId: product ? product.id : undefined,
                      productCustomName: product ? product.name : customProductName,
                      productDosage: Number(e.target.value),
                      productDosageFactor: 100,
                      productRoute: route,
                      productLocation: location,
                      valid: !!e.target.value,
                    });
                }}
                placeholder={'5'}
                type={'number'}
                // @TODO required
                value={dosage}
              />
              <InputGroup.Text id="dosage-factor-per-pound">{t('mL')}</InputGroup.Text>
            </InputGroup>

            <Form.Text className={'text-muted'}>
              {t('diagnosisTreatWithProduct|productDosageAmountInstructions')}
            </Form.Text>
          </FormGroup>
          <FormGroup className="form-group mt-3 mb-3" controlId="formDosageRoute">
            <Form.Label>
              {t('Dosage Route')} <Required />
            </Form.Label>

            <Form.Control
              aria-describedby="dosage-route"
              aria-label={t('Dosage Route')}
              name={'dosageRoute'}
              onChange={(e): void => {
                setRoute(e.target.value);
                props.onProductChange &&
                  props.onProductChange({
                    productId: product ? product.id : undefined,
                    productCustomName: product ? product.name : customProductName,
                    productDosage: Number(dosage),
                    productDosageFactor: 100,
                    productRoute: e.target.value,
                    productLocation: location,
                    valid: !!e.target.value,
                  });
              }}
              placeholder={'IM/SQ'}
              // @TODO required
              value={route}
            />

            <Form.Text className={'text-muted'}>
              {t('diagnosisTreatWithProduct|productDosageRouteInstructions')}
            </Form.Text>
          </FormGroup>
          <FormGroup className="form-group mt-3 mb-3" controlId="formDosageLocation">
            <Form.Label>{t('Location')}</Form.Label>

            <Form.Control
              aria-describedby="dosage-location"
              aria-label={t('Location')}
              name={'dosageLocation'}
              onChange={(e): void => {
                setLocation(e.target.value);
                props.onProductChange &&
                  props.onProductChange({
                    productId: product ? product.id : undefined,
                    productCustomName: product ? product.name : customProductName,
                    productDosage: Number(dosage),
                    productDosageFactor: 100,
                    productRoute: route,
                    productLocation: e.target.value,
                    valid: !!e.target.value,
                  });
              }}
              placeholder={''}
              value={location}
            />

            <Form.Text className={'text-muted'}>
              {t('diagnosisTreatWithProduct|productDosageLocationInstructions')}
            </Form.Text>
          </FormGroup>
        </div>
        {!product && !customProduct && <em>{t('No Product')}</em>}
      </div>
    </div>
  );
};

export default DiagnosisTreatWithProduct;
