// Copyright © 2023 CATTLEytics Inc.
import DateTime from '../../common/components/DateTime';
import Modal from '../../common/components/Modal';
import { Notification } from '../../shared';

/**
 * Component input properties.
 */
export interface Props {
  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Notification entity
   */
  notification: Notification;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;
}

const NotificationModal = ({ className, onClose, notification }: Props): JSX.Element => {
  return (
    <Modal
      className={className}
      fullscreen={'md-down'}
      onClose={onClose}
      size={'xl'}
      title={notification.heading}
      visible={true}
    >
      <DateTime date={notification.createdDate} />

      <div className="mt-1">{notification.body}</div>
    </Modal>
  );
};

export default NotificationModal;
