// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { ChangeEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { TYPES } from '../../../types';
import ManageListEditModal from '../../lists/components/ManageListEditModal';
import { ApiResourceV1, QueryKey } from '../../shared';
import DiagnosisService from '../services/diagnosisService';

/**
 * Component input properties.
 */
interface Props {
  /**
   * Accessibility label.
   */
  ariaLabel?: string;

  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Form element name.
   */
  name?: string;

  /**
   * Input change handler.
   * @param event
   */
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;

  // when add modal is closed
  onCloseAddModal?: () => void;

  /**
   * Whether this field is required
   */
  required?: boolean;

  // whether to display the add modal
  showAddModal?: boolean;

  // input value
  value: string;
}

/**
 * Form select component for diagnoses.
 */
const DiagnosisSelect = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const diagnosisService = useInjection<DiagnosisService>(TYPES.diagnosisService);

  const [showAddModal, setShowAddModal] = useState<boolean>(!!props.showAddModal);
  const [previousShowAddModal, setPreviousShowAddModal] = useState<boolean>();

  const query = useQuery([QueryKey.Diagnoses], () => diagnosisService.list(), {});
  const items = query.data;
  const value = props.value ?? undefined;

  if (!!props.showAddModal !== previousShowAddModal) {
    setShowAddModal(!!props.showAddModal);
    setPreviousShowAddModal(!!props.showAddModal);
  }

  return (
    <>
      <Form.Select
        aria-label={props.ariaLabel ?? 'Diagnosis'}
        className={props.className}
        name={props.name ?? 'diagnosisId'}
        onChange={props.onChange}
        required={props.required}
        value={value}
      >
        <option value={''}>
          {query.isFetching ? t('common|loading') : t('Choose a diagnosis')}
        </option>
        {items &&
          items.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
      </Form.Select>
      {showAddModal && (
        <ManageListEditModal
          apiResource={ApiResourceV1.Diagnoses}
          onClose={props.onCloseAddModal ?? ((): void => undefined)}
          queryKey={QueryKey.Diagnoses}
          typeName={'diagnosis'}
        />
      )}
    </>
  );
};

export default DiagnosisSelect;
