// Copyright © 2023 CATTLEytics Inc.
import { flatten, range } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Col,
  Form,
  Row,
  TabContainer,
  TabContent,
  TabPane,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useGetEventTypes } from '../../animals/components/animal-card-events/hooks/useGetEventTypes';
import { groupEventTypes } from '../../animals/components/animal-card-events/toolbar/utilities';
import { CheckboxDropdownProps } from '../../common/components/CheckboxDropdown';
import GroupedCheckboxDropdown, {
  GroupedOption,
} from '../../common/components/GroupedCheckboxDropdown';
import { IconEvent, IconTable } from '../../common/utilities';
import EventsCalendar from './EventsCalendar';
import EventsTable from './EventsTable';

interface Props {
  /**
   * tabKey param passed from summary page
   * string representing the option selected from summary page to navigate to events table
   */
  tabKey?: string;
  /**
   * year param passed from the summary page
   */
  yearParam?: string;
}

enum EventKeys {
  CALENDAR = 'calendar',
  TABLE = 'table',
}

const EventsCalendarTableToggle = ({ yearParam, tabKey }: Props): JSX.Element => {
  const { eventTypes } = useGetEventTypes();

  const [eventKey, setEventKey] = useState(EventKeys.TABLE);
  const { t } = useTranslation();
  const [selectedEventTypeIds, setSelectedEventTypeIds] = useState<number[]>();
  const toggleChange = (val: EventKeys): void => setEventKey(val);
  const [year, setYear] = useState<string | undefined>(yearParam);
  const years = range(2000, new Date().getFullYear() + 1).reverse();

  const options = useMemo<GroupedOption[]>(
    () => (eventTypes ? groupEventTypes(eventTypes) : []),
    [eventTypes],
  );

  useEffect(() => {
    if (options) {
      // set all events if tabkey is all
      if (tabKey === 'all') {
        const ids = flatten(options.map((group) => group.items.map((item) => item.id)));
        setSelectedEventTypeIds(ids);
      } else {
        // set initial filters based on tabkey Id
        setSelectedEventTypeIds(tabKey ? [parseInt(tabKey)] : []);
      }
    }
  }, [options, tabKey]);

  const onChange = useCallback<CheckboxDropdownProps['onChange']>(
    (values) => setSelectedEventTypeIds(values),
    [setSelectedEventTypeIds],
  );

  return (
    <TabContainer activeKey={eventKey}>
      <Row>
        <Col className="d-flex justify-content-end mb-3" sm={12}>
          <ToggleButtonGroup name="toggle" onChange={toggleChange} type="radio" value={eventKey}>
            <ToggleButton
              id={`btn-${EventKeys.CALENDAR}`}
              value={EventKeys.CALENDAR}
              variant="outline-dark"
            >
              <IconEvent />
            </ToggleButton>
            <ToggleButton
              id={`btn-${EventKeys.TABLE}`}
              value={EventKeys.TABLE}
              variant="outline-dark"
            >
              <IconTable />
            </ToggleButton>
          </ToggleButtonGroup>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-between" sm={12}>
          <GroupedCheckboxDropdown
            label={t('{{count}} Event Types Selected', {
              count: selectedEventTypeIds?.length ?? 0,
            })}
            onChange={onChange}
            options={options}
            values={selectedEventTypeIds ?? []}
          />
          {eventKey === EventKeys.TABLE && (
            <div style={{ width: '30%' }}>
              <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Select
                    aria-label={t('eventTable|selectYear')}
                    onChange={(e): void => setYear(e.target.value)}
                    value={year}
                  >
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Form>
            </div>
          )}
        </Col>
      </Row>

      <TabContent>
        <TabPane eventKey={EventKeys.CALENDAR} mountOnEnter unmountOnExit>
          <EventsCalendar eventTypeIds={selectedEventTypeIds} />
        </TabPane>
        <TabPane eventKey={EventKeys.TABLE} mountOnEnter unmountOnExit>
          <EventsTable
            eventTypeIds={selectedEventTypeIds}
            filters={
              year
                ? {
                    dateStart: `${year}-01-01T00:00:00.000Z`,
                    dateEnd: `${year}-12-31T23:59:59.000Z`,
                  }
                : {}
            }
          />
        </TabPane>
      </TabContent>
    </TabContainer>
  );
};

export default EventsCalendarTableToggle;
