// Copyright © 2023 CATTLEytics Inc.

import { inject, injectable } from 'inversify';

import { TYPES } from '../../../types';
import Api2Service from '../../common/services/api2Service';
import type Logger from '../../logger/logger';
import { BreedingPlan, BreedingProgram } from '../../shared';

const path = '/v1/breeding-plans';

/**
 * Service for managing breeding plan data via REST API.
 */
@injectable()
export default class BreedingPlanService {
  private logger: Logger;
  private apiService: Api2Service;

  /**
   * Creates an instance of BreedingPlanService.
   *
   * @param {Logger} logger Logger instance.
   * @param {Api2Service} apiService API Service instance
   */
  constructor(
    @inject(TYPES.logger) logger: Logger,
    @inject(TYPES.api2Service) apiService: Api2Service,
  ) {
    this.logger = logger;
    this.apiService = apiService;
  }

  async list(params?: Record<string, string>): Promise<BreedingPlan[]> {
    const result = await this.apiService.get<BreedingPlan[]>(path, params);
    if (!result) {
      return [];
    }

    const data = result as BreedingPlan[];
    data.forEach((item) => this.cast(item));
    return data;
  }

  async get(id: number, params?: Record<string, string>): Promise<BreedingPlan | undefined> {
    const result = await this.apiService.get<BreedingPlan>(`${path}/${id}`, params);
    if (!result) {
      return undefined;
    }

    const data = result as BreedingPlan;
    this.cast(data);
    return data;
  }

  async post(attributes: Record<string, string>): Promise<BreedingPlan | undefined> {
    const result = await this.apiService.post<BreedingPlan>(`${path}`, attributes);
    if (!result) {
      return undefined;
    }
    this.cast(result as BreedingPlan);
    return result;
  }

  async patch(
    id: number,
    attributes: Record<string, string | number | null>,
  ): Promise<BreedingPlan | undefined> {
    // make sure record identifier is included
    attributes.id = id;

    const result = await this.apiService.patch(`${path}/${id}`, attributes);
    if (!result) {
      return undefined;
    }

    const data = result as BreedingPlan;
    this.cast(data);
    return data;
  }

  async delete(id: number): Promise<null> {
    return await this.apiService.delete(`${path}/${id}`);
  }

  async reorderBreedingPrograms(
    breedingPlanId: number,
    breedingProgramIds: number[],
  ): Promise<void> {
    await this.apiService.post<BreedingProgram>(
      `actions/breedingPlans/${breedingPlanId}/reorderPrograms`,
      {
        breedingProgramIds: breedingProgramIds,
      },
    );
  }

  /**
   * Casts fields into javascript types.
   * @param item
   * @return cast object
   */
  cast(item: BreedingPlan): void {
    if (!item) {
      return;
    }
  }
}
