// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { useTranslation } from 'react-i18next';
import { GiCow } from 'react-icons/gi';
import { useQuery } from 'react-query';

import { TYPES } from '../../../types';
import AnimalService from '../../common/services/animalService';
import { Animal } from '../../shared';
import { nameTag, QueryKey } from '../../shared';

/**
 * Component properties
 */
interface Props {
  /**
   * Loading indicator.
   */
  busy?: boolean;

  /**
   * Filter by gender
   */
  genderId?: number;

  /**
   * HTML ID attribute
   */
  id: string;

  /**
   * Whether to allow multiple pen selection.
   *
   * Note: this also changes the parameters sent in the onSelect callback from
   * single item to array of items.
   */
  multiple?: boolean;

  /**
   * HTML name attribute
   */
  name?: string;

  /**
   * Callback after animal has been selected
   * @param animal
   */
  onSelect: (animal?: Animal | Animal[]) => void;

  /**
   * Pen ID to filter animals by. Show only animals currently in the specified pen.
   */
  penId?: string;

  /**
   * Set the typeahead placeholder text.
   */
  placeholder?: string;

  /**
   * If this field is required.
   */
  required?: boolean;

  /**
   * Initial selected option or options.
   */
  selected?: Option | Option[];

  /**
   * Whether the form this component is in has been validated.
   * This will show invalid messages if applicable.
   * Note this does not indicate a form is valid.
   */
  validated?: boolean;
}

/**
 * Provides ability to select an animal by name or ID.
 */
const AnimalAutocomplete = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const animalService = useInjection<AnimalService>(TYPES.animalService);

  const [searchQuery, setSearchQuery] = useState<string>(
    props.selected ? String(props.selected) : '',
  );

  const limit = 10;

  const { data: animals, isLoading: busy } = useQuery<Animal[]>(
    [QueryKey.Animals, searchQuery, limit, props.penId, props.genderId],
    () =>
      animalService.getAnimals({
        search: searchQuery,
        limit: String(limit),
        pen: props.penId,
        genderId: props.genderId ? String(props.genderId) : undefined,
        status: 'active',
      }),
    {
      //enabled: !!searchQuery,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  // if selected property is not set then empty array,
  // else if selected property is an array use it,
  // else make selected property an array
  const selected = !props.selected
    ? []
    : Array.isArray(props.selected)
    ? props.selected
    : [props.selected];

  const required = props.required ?? false;

  // if the validated property is set and true (form validation was run),
  // and the field is required and the selection is empty
  const isInvalid = !!props.validated && required && selected.length === 0;

  // if the validated property is set and true (form validation was run),
  // and the field is not isInvalid
  const isValid = !!props.validated && !isInvalid;

  // The following input element is a way to trigger a javascript invalid
  // state so HTML5 form validation can be used since the input element
  // inside the typeahead component does not support this
  // if one or more items have been selected this input element will be
  // set to 1 thus satisfying the required validator
  const inputHelper = (
    <input
      aria-hidden={true}
      readOnly={true}
      required={required}
      style={{ display: 'none' }}
      tabIndex={-1}
      type={'text'}
      value={selected.length === 0 ? '' : '1'}
    />
  );

  return (
    <div className={`animal-autocomplete ${isInvalid ? 'is-invalid' : 'is-valid'}`}>
      {inputHelper}
      <AsyncTypeahead
        clearButton={true}
        id={props.id} // for styling only
        inputProps={{ required: isInvalid }}
        isInvalid={isInvalid}
        isLoading={busy || !!props.busy}
        isValid={isValid}
        labelKey={(option): string => {
          const animal = option as Animal;
          return nameTag(animal.primaryTag, animal.name);
        }}
        minLength={0}
        multiple={props.multiple}
        onChange={(selected): void => {
          if (props.multiple) {
            props.onSelect(selected && selected.length > 0 ? (selected as Animal[]) : undefined);
          } else {
            props.onSelect(selected && selected.length > 0 ? (selected[0] as Animal) : undefined);
          }
        }}
        onSearch={(searchQuery: string): void => {
          setSearchQuery(searchQuery);
        }}
        options={animals ?? []}
        placeholder={props.placeholder ?? t('Enter an animal identifier')}
        renderMenuItemChildren={(option, { text }): JSX.Element => {
          const animal = option as Animal;
          const label = nameTag(animal.primaryTag, animal.name);
          return (
            <div
              className={'d-flex justify-space-between align-items-center'}
              style={{ borderBottom: '1px #ccc solid', paddingBottom: '8px' }}
            >
              {animal.imageUrlSigned ? (
                <img
                  alt={label}
                  className={'rounded'}
                  src={animal.imageUrlSigned}
                  style={{ width: '60px', height: '60px', marginRight: '2rem' }}
                />
              ) : (
                <div
                  className={'rounded d-flex justify-content-center align-items-center'}
                  style={{
                    border: '1px #ccc solid',
                    objectFit: 'cover',
                    height: '60px',
                    width: '60px',
                    lineHeight: '60p',
                    backgroundColor: '#ccc',
                    paddingBottom: '0rem',
                    marginRight: '2rem',
                  }}
                >
                  <GiCow style={{ fontSize: '2.5rem', color: '#999' }} />
                </div>
              )}
              <div className={'d-block'}>
                <Highlighter search={text}>{label}</Highlighter>
                <p className={'text-muted'}>
                  <small>Pen: {animal.currentPen?.name ?? '-'}</small>
                </p>
              </div>
            </div>
          );
        }}
        selected={selected}
      />
      <Form.Control.Feedback type={'invalid'}>
        {t('Please select a valid dam')}
      </Form.Control.Feedback>
    </div>
  );
};

export default AnimalAutocomplete;
