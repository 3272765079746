// Copyright © 2023 CATTLEytics Inc.

import React, { CSSProperties } from 'react';
import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { IconStatusSold } from '../utilities';

/**
 * Defines the input properties for this component.
 */
interface Props {
  /**
   * CSS classes to pass to component.
   */
  className?: string;

  /**
   * CSS styles
   */
  style?: CSSProperties;
}

/**
 * A badge to indicate an animal has been sold.
 */
const AnimalSoldBadge = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  const defaultStyle = { backgroundColor: 'limegreen' };
  const style = props.style ? { ...defaultStyle, ...props.style } : defaultStyle;
  return (
    <Badge bg={''} className={props.className} pill={true} style={style}>
      <IconStatusSold /> {t('Sold')}
    </Badge>
  );
};

export default AnimalSoldBadge;
