// Copyright © 2023 CATTLEytics Inc.

/**
 * WARNING!
 *
 * DO NOT EDIT THIS FILE
 *
 * CHANGES WILL BE OVERWRITTEN!
 *
 * Make changes in packages/shared and run npm run copy-shared-to-packages
 */

export enum ApiResourceV1 {
  ActiveTreatments = '/v1/active-treatments',
  AnimalColors = '/v1/animal-colors',
  AnimalEventTypes = '/v1/animal-event-types',
  AnimalEvents = '/v1/animal-events',
  AnimalGroups = '/v1/animal-groups',
  AnimalHealthCheckResults = '/v1/animal-health-check-results',
  AnimalHealthChecks = '/v1/animal-health-checks',
  AnimalMeasure = '/v1/animal-measure',
  AnimalReferenceTypes = '/v1/animal-reference-types',
  AnimalReferences = '/v1/animal-references',
  Animals = '/v1/animals',
  Auth = '/v1/auth',
  BirthStatuses = '/v1/birth-statuses',
  BreedingPlans = '/v1/breeding-plans',
  BreedingProgramTypes = '/v1/breeding-program-types',
  BreedingPrograms = '/v1/breeding-programs',
  Breeds = '/v1/breeds',
  Configuration = '/v1/configuration',
  CqlQueries = '/v1/cql-queries',
  CqlQueriesMeta = '/v1/cql-queries-meta',
  DairyLogs = '/v1/dairy-logs',
  Diagnoses = '/v1/diagnoses',
  DiagnosisRegimeDetails = '/v1/diagnosis-regime-details',
  DiagnosisRegimes = '/v1/diagnosis-regimes',
  ExternalAnimalMeasure = '/v1/external-animal-measure',
  ExternalSystems = '/v1/external-systems',
  Files = '/v1/files',
  Genders = '/v1/genders',
  Import = '/v1/import',
  Index = '/v1/index',
  Jobs = '/v1/jobs',
  LayoutItems = '/v1/layout-items',
  Layouts = '/v1/layouts',
  Names = '/v1/names',
  Notes = '/v1/notes',
  Notifications = '/v1/notifications',
  Organizations = '/v1/organizations',
  Pens = '/v1/pens',
  ProductAdministrations = '/v1/product-administrations',
  ProductClasses = '/v1/product-classes',
  ProductTypes = '/v1/product-types',
  Products = '/v1/products',
  PushTokens = '/v1/push-tokens',
  ReportBredOutcome = '/v1/report-bred-outcome',
  ReportDimUpToEvent = '/v1/report-dim-up-to-event',
  ReportEcmQuantity = '/v1/report-ecm-quantity',
  ReportInventory = '/v1/report-inventory',
  ReportModelEcmQuantity = '/v1/report-model-ecm-quantity',
  Reports = '/v1/reports',
  ReproStatuses = '/v1/repro-statuses',
  Roles = '/v1/roles',
  Settings = '/v1/settings',
  Shifts = '/v1/shifts',
  SignUp = '/v1/sign-up',
  Sires = '/v1/sires',
  Sites = '/v1/sites',
  Stripe = '/v1/stripe',
  StripeProducts = '/v1/stripe/products',
  StripeSubscriptions = '/v1/stripe/subscriptions',
  TaskCategories = '/v1/task-categories',
  TaskCategoriesActionsReorder = '/v1/task-categories/actions/reorder',
  Tasks = '/v1/tasks',
  Translations = '/v1/translations',
  UserSchedules = '/v1/user-schedules',
  UserSchedulesBulk = '/v1/user-schedules/bulk',
  Users = '/v1/users',
}
