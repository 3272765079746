// Copyright © 2023 CATTLEytics Inc.

import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { Text } from 'react-konva';

import { Tool } from './PenMapTools';

/**
 * @description Component input properties.
 */
interface Props {
  /**
   * @description The active tool.
   */
  activeTool: Tool;

  /**
   * @description CSS color value. Default: white
   */
  color?: string;

  /**
   * @description Whether or not to show component.
   */
  visible?: boolean;

  /**
   * @description Horizontal (top left) coordinate to draw this component. Default: 10
   * @default 10
   */
  x?: number;

  /**
   * @description Vertical (top left) coordinate to draw this component. Default: 10
   * @default 10
   */
  y?: number;
}

/**
 * @description For showing active tool.
 * @extends {React.Component}
 */
const DebugShowActiveTool = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const x = props.x ?? 10;
  const y = props.y ?? 25;
  const color = props.color ?? 'white';
  const visible = props.visible ?? true;
  return (
    <Fragment>
      {visible ? (
        <Text fill={color} fontSize={20} text={`Active Tool: ${props.activeTool}`} x={x} y={y} />
      ) : null}
    </Fragment>
  );
};

export default withTranslation()(DebugShowActiveTool);
