// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { withTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { TYPES } from '../../../types';
import { QueryKey } from '../../shared';
import AnimalEventType from '../entities/animalEventType';
import AnimalEventTypeService from '../services/animalEventTypeService';

/**
 * Component properties
 */
interface Props {
  /**
   * HTML element ID.
   */
  id: string;

  /**
   * Whether to allow multiple pen selection.
   */
  multiple?: boolean;

  /**
   * HTML element name.
   */
  name?: string;

  /**
   * Callback after event has been selected
   * @param animal
   */
  onSelect: (animalEventType?: AnimalEventType) => void;

  /**
   * If this field is required.
   */
  required?: boolean;

  /**
   * Initial selected option.
   */
  selected?: Option | Option[];
}

/**
 * Provides ability to select an animal by name or ID.
 */
const AnimalEventTypeAutocomplete = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const dataService = useInjection<AnimalEventTypeService>(TYPES.animalEventTypeService);

  const [searchQuery] = useState<string>(props.selected ? String(props.selected) : '');

  const { data, isLoading: busy } = useQuery<AnimalEventType[]>(
    [QueryKey.AnimalEventTypes, searchQuery],
    () =>
      dataService.list({
        search: searchQuery,
        limit: '1000',
      }),
  );

  const selected = !props.selected
    ? []
    : Array.isArray(props.selected)
    ? props.selected
    : [props.selected];

  const required = props.required ?? false;

  return (
    <Typeahead
      clearButton={true}
      disabled={!!props.selected && busy}
      id={props.id}
      inputProps={{ required: required }}
      // minLength={1}
      isLoading={busy}
      labelKey="name"
      multiple={props.multiple}
      onChange={(selected): void => {
        props.onSelect(
          selected && selected.length > 0 ? (selected[0] as AnimalEventType) : undefined,
        );
      }}
      options={data ?? []}
      placeholder={'Select an event type'}
      renderMenuItemChildren={(option): JSX.Element => (
        <div>
          {(option as unknown as Record<string, string>).name}
          <div>
            <small>{(option as unknown as Record<string, string>).description}</small>
          </div>
        </div>
      )}
      selected={selected}
    />
  );
};

export default withTranslation()(AnimalEventTypeAutocomplete);
