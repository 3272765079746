// Copyright © 2024 CATTLEytics Inc.

import { sql } from '@codemirror/lang-sql';
import CodeMirror, { ReactCodeMirrorRef } from '@uiw/react-codemirror';
import React from 'react';
import { useQuery } from 'react-query';

import Spinner from '../../common/components/Spinner';
import { api } from '../../common/utilities';
import { ApiResourceV1, HttpMethod, QueryKey } from '../../shared';
import { CqlQueryMeta } from '../../shared';
import { CQL, darkTheme, lightTheme } from './ReportsCodeMirror';

interface Props {
  /**
   * Callback when query changes
   */
  onChange: (query: string) => void;

  /**
   * The CQL query to show in the editor.
   */
  query: string;
}

export const CqlEditor = (props: Props): JSX.Element => {
  const myTheme = document.documentElement.dataset.bsTheme === 'light' ? lightTheme : darkTheme;
  const refs = React.useRef<ReactCodeMirrorRef>({});

  const queryMeta = useQuery<CqlQueryMeta>([QueryKey.CqlQueriesMeta], () =>
    api<CqlQueryMeta>(HttpMethod.Get, ApiResourceV1.CqlQueriesMeta),
  );

  if (queryMeta.isLoading) {
    return <Spinner />;
  }

  return (
    <CodeMirror
      basicSetup={{
        lineNumbers: true,
        indentOnInput: false,
        defaultKeymap: true,
      }}
      className={'code-mirror'}
      extensions={[
        sql({
          dialect: CQL,
          upperCaseKeywords: true,
          // TODO: change schema keys based on what the use is typing (index indicated)
          schema: queryMeta.data ? queryMeta.data.animals.autocomplete : {},
        }),
      ]}
      onChange={(value: string): void => props.onChange(value)}
      ref={refs}
      theme={myTheme}
      value={props.query}
    />
  );
};
