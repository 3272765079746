// Copyright © 2023 CATTLEytics Inc.

import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { useQuery } from 'react-query';

import Spinner from '../../common/components/Spinner';
import { api } from '../../common/utilities';
import { QueryKey } from '../../shared';
import { DaysDriedOff } from '../../shared';

const lineColors = [
  'rgb(255, 160, 160)', // PastalPink
  'rgb(152, 251, 152)', // PaleGreen
  'rgb(160, 160, 255)', //PastalPurple
  'rgb(255, 215, 0)', // Gold
  'rgb(255, 160, 122)', // LightSalmon
  'rgb(255, 105, 180)', // HotPink
  'rgb(135, 206, 235)', // SkyBlue
  'rgb(127, 255, 212)', // Aquamarine
  'rgb(160, 255, 255)', // PastalBlue
];

const DaysDriedOffChart = (): JSX.Element => {
  const [data, setData] = useState<ChartData<'line'>>();

  const query = useQuery<Required<DaysDriedOff>[]>([QueryKey.DaysDriedOff], () =>
    api<Required<DaysDriedOff>[]>('GET', '/v1/report-days-dried-off'),
  );

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineController,
    LineElement,
    Title,
    Tooltip,
    Legend,
  );

  const options = {
    plugins: {
      legend: {
        labels: {
          filter: (legendItem: any): boolean => {
            return legendItem && legendItem.text !== 'undefined';
          },
        },
      },
      title: {
        display: true,
        text: 'Lactations',
        font: {
          size: 18,
          weight: 'bold',
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Days Dried Off',
          font: {
            size: 16,
          },
        },
        min: 0,
        max: 305,

        grid: {
          display: true,
        },
        ticks: {
          maxTicksLimit: 35,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Number Of Records',
          font: {
            size: 16,
          },
        },
        min: 0,
      },
    },
  };

  useEffect(() => {
    if (!query.data) {
      return;
    }
    const allLabels: number[] = []; // this will contain all the dried off days
    const lactGroup: { [group: string]: { data: number[]; labels: number[] } } = {};

    query.data.forEach((item) => {
      const { daysDriedOff, numRecords, lactationGroup } = item;

      if (!lactGroup[lactationGroup]) {
        lactGroup[lactationGroup] = { labels: [], data: [] };
      }

      lactGroup[lactationGroup].labels.push(daysDriedOff);
      allLabels.push(daysDriedOff); // Store all the dried off days
      lactGroup[lactationGroup].data.push(numRecords);
    });

    const orderedLabels = Array.from(new Set(allLabels)).sort((a, b) => a - b);

    const datasets = Object.keys(lactGroup).map((group, index) => ({
      label: group,
      data: orderedLabels.map((label) => {
        if (group !== 'undefined') {
          return lactGroup[group].labels.includes(label)
            ? lactGroup[group].data[lactGroup[group].labels.indexOf(label)]
            : 0;
        } else {
          return null;
        }
      }),
      borderColor: lineColors[index % lineColors.length],
      backgroundColor: 'transparent',
      borderWidth: 3.5,
      pointRadius: 1,
    }));

    const chartData = {
      labels: orderedLabels,
      datasets: datasets,
    };

    setData(chartData);
  }, [query.data]);

  return (
    <>
      {!data && <Spinner />}
      {data && <Chart data={data} options={options} type="line" />}
    </>
  );
};

export default DaysDriedOffChart;
