// Copyright © 2023 CATTLEytics Inc.

import { inject, injectable } from 'inversify';

import { TYPES } from '../../../types';
import type Logger from '../../logger/logger';
import Name from '../entities/name';
import Api2Service from './api2Service';

const path = '/v1/names';

/**
 * Service for managing animal names
 */
@injectable()
export default class NameService {
  private logger: Logger;

  private apiService: Api2Service;

  /**
   * Creates an instance of PenService.
   * @param logger Logger instance.
   * @param apiService API Service instance
   */
  constructor(
    @inject(TYPES.logger) logger: Logger,
    @inject(TYPES.api2Service) apiService: Api2Service,
  ) {
    this.logger = logger;
    this.apiService = apiService;
  }

  async list(gender: 'm' | 'f', random?: boolean): Promise<Name[]> {
    const result = await this.apiService.get<Name[]>(path, {
      random: random ? '1' : '0',
      gender: gender,
    });
    if (!result) {
      return [];
    }

    const data = result as Name[];
    data.forEach((item) => this.cast(item));
    return data;
    //return this.apiService.deserializeArray<Pen>(result);
  }

  /**
   * @description Casts fields into javascript types.
   * @param item
   * @return {void}
   */
  cast(item: Name): void {
    const data = item; // as JsonApiResource<Pen>;
    if (!data) {
      return;
    }
  }
}
