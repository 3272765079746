// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { Fragment, useState } from 'react';
import { Accordion, Badge, Placeholder } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import Button, { ButtonVariant } from '../../common/components/Button';
import ConfirmModal from '../../common/components/ConfirmModal';
import DateTime from '../../common/components/DateTime';
import PaginationControls from '../../common/components/PaginationControls';
import Note from '../../common/entities/note';
import { Sort } from '../../common/enums';
import { usePaginationControls, usePaginationRequstValues } from '../../common/hooks';
import NoteService from '../../common/services/noteService';
import { formatDate, IconDelete, IconEdit } from '../../common/utilities';
import { QueryKey } from '../../shared/enums';
import NoteModal from './NoteModal';

interface Props {
  /**
   * Filter list by animal ID.
   */
  animalId?: number;

  /**
   * Additional CSS classes to add to component.
   */
  className?: string;

  /**
   * Number of notes to show.
   */
  limit?: number;

  /**
   * Callback when list data is successfully retrieved
   * @param data
   */
  onQuerySuccess?: (data: Note[]) => void;

  /**
   * Filter list by pen ID.
   */
  penId?: number;

  /**
   * animal primary tag
   */
  primaryTag?: string;

  /**
   * Direction to sort the list
   */
  sortDirection?: Sort;

  /**
   * Column to sort the list by
   */
  sortField?: string;

  /**
   * Tags to filter the list by
   */
  tags?: string[];
}

/**
 * List notes.
 */
const NotesList = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const noteService = useInjection<NoteService>(TYPES.noteService);
  const paginationRequestValues = usePaginationRequstValues({ initialLimit: props.limit ?? 25 });

  const queryParams: Record<string, string> = {
    limit: String(paginationRequestValues.limit),
    offset: String(paginationRequestValues.offset),
    sortField: props.sortField ? String(props.sortField) : 'createdDate',
    sortDirection: props.sortDirection ? String(props.sortDirection) : Sort.Descending,
  };

  if (props.animalId) {
    queryParams.animalId = String(props.animalId);
  }

  if (props.penId) {
    queryParams.penId = String(props.penId);
  }

  if (props.tags) {
    queryParams.tags = props.tags.join(',');
  }

  const query = useQuery<Note[]>(
    [QueryKey.Notes, queryParams],
    () => noteService.list(queryParams),
    { onSuccess: props.onQuerySuccess },
  );
  const [paginationValues, paginationHandlers] = usePaginationControls({
    ...paginationRequestValues,
    listLength: query.data?.length,
  });

  const queryClient = useQueryClient();

  const mutation = useMutation(
    () => {
      if (noteIdToDelete) {
        return noteService.delete(noteIdToDelete);
      } else {
        return new Promise<null>((resolve) => resolve(null));
      }
    },
    {
      onSuccess: () => {
        // Invalidate and re-fetch
        queryClient.invalidateQueries(QueryKey.Notes);
      },
    },
  );

  const [noteModalVisible, setNoteModalVisible] = useState<boolean>(false);
  const [noteModalNoteId, setNoteModalNoteId] = useState<number>();

  const [noteDeleteModalVisible, setNoteDeleteModalVisible] = useState<boolean>(false);
  const [noteIdToDelete, setNoteIdToDelete] = useState<number>();

  const placeholder = (
    <Accordion.Item eventKey={'loading'}>
      <Accordion.Header>
        <Placeholder animation={'glow'} className={'w-100 d-flex justify-content-between'}>
          <div className={'w-100 d-flex'}>
            <Placeholder className={'me-3'} md={2} />
            <Placeholder md={4} />
          </div>
          <Placeholder className={'me-1'} md={1} />
        </Placeholder>
      </Accordion.Header>
    </Accordion.Item>
  );

  if (query.isLoading) {
    return (
      <Fragment>
        {[...Array(5).keys()].map((key) => (
          <Fragment key={key}>{placeholder}</Fragment>
        ))}
      </Fragment>
    );
  }

  if (query.isError || !query.data) {
    return <AlertError message={'Error'} />;
  }

  const showModal = (noteId: number): void => {
    setNoteModalVisible(true);
    setNoteModalNoteId(noteId);
  };

  const showDeleteModal = (note: Note): void => {
    setNoteDeleteModalVisible(true);
    setNoteIdToDelete(note.id);
  };

  const notes = query.data;

  return (
    <section>
      <Accordion defaultActiveKey="0" flush>
        {notes.length === 0 && query.isFetched && (
          <p className={'text-center'}>
            <em>{t('No {{value}} found', { value: t('notes') })}</em>
          </p>
        )}
        {notes.map((note) => {
          const related = [];
          if (note.animalId || note.penId) {
            if (note.animalId) {
              related.push({
                link: `/animals/${note.animalId}`,
                label: `Related Animal #${note.animal?.primaryTag}`,
              });
            }
            if (note.penId) {
              related.push({
                link: `/pens/${note.penId}`,
                label: `Related Pen #${note.penId}`,
              });
            }
          }
          return (
            <Accordion.Item eventKey={String(note.id)} key={note.id}>
              <Accordion.Header>
                <div className={'w-100 d-flex justify-content-between'} style={{ minWidth: 0 }}>
                  <div className={'text-truncate'} style={{ minWidth: 0 }}>
                    <strong className={'me-3 text-muted'}>
                      <DateTime date={note.modifiedDate} />
                    </strong>
                    <span>{note.body.split('\n')[0]}</span>
                  </div>
                  {/*{!note.animalId && !note.penId ? <div /> : null}*/}
                  <div className={'text-nowrap'}>
                    {note.tags &&
                      note.tags.slice(0, 1).map((tag) => (
                        <Badge bg={'primary'} className={'me-3'} key={tag} pill>
                          {tag}
                        </Badge>
                      ))}
                    {note.animalId && (
                      <Badge bg={'success'} className={'me-3'} pill>
                        {t('Animal')}
                      </Badge>
                    )}
                    {note.penId && (
                      <Badge bg={'secondary'} className={'me-3'} pill>
                        {t('Pen')}
                      </Badge>
                    )}
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                {related && (
                  <>
                    <p>
                      <small className={''}>
                        {related.map((item, index) => (
                          <Link className={'alert-link me-3'} key={index} to={item.link}>
                            {item.label}
                          </Link>
                        ))}
                      </small>
                    </p>
                    <hr />
                  </>
                )}
                {note.body.split('\n').map((elem) => (
                  <>
                    {elem}
                    <br />
                  </>
                ))}
                <div className={'text-nowrap mt-3'}>
                  {note.tags &&
                    note.tags.slice(0, 2).map((tag) => (
                      <Badge bg={'primary'} className={'me-3'} key={tag} pill>
                        {tag}
                      </Badge>
                    ))}
                  {note.animalId && (
                    <Badge bg={'success'} className={'me-3'} pill>
                      {t('Animal')}
                    </Badge>
                  )}
                  {note.penId && (
                    <Badge bg={'secondary'} className={'me-3'} pill>
                      {t('Pen')}
                    </Badge>
                  )}
                </div>
                <hr />
                <div className={'d-flex justify-content-between'}>
                  <div>
                    <small>
                      <strong>{t('Created')}:</strong> {formatDate(note.createdDate)}
                    </small>
                  </div>
                  <div>
                    <small>
                      <strong>{t('Modified')}:</strong> {formatDate(note.modifiedDate)}
                    </small>
                  </div>

                  <Button
                    icon={IconDelete}
                    label={t('Delete')}
                    onClick={(): void => showDeleteModal(note)}
                    size={'sm'}
                    variant={ButtonVariant.OutlineDanger}
                  />
                  <Button
                    icon={IconEdit}
                    label={t('Edit')}
                    onClick={(): void => showModal(note.id)}
                    size={'sm'}
                    variant={ButtonVariant.OutlinePrimary}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
        {/*{query.isFetching ? placeholder : null}*/}
        {noteModalVisible && (
          <NoteModal noteId={noteModalNoteId} onClose={(): void => setNoteModalVisible(false)} />
        )}
        {noteDeleteModalVisible && (
          <ConfirmModal
            busy={mutation.isLoading}
            cancelOnClick={(): void => setNoteDeleteModalVisible(false)}
            okOnClick={async (): Promise<void> => {
              await mutation.mutateAsync();
              setNoteDeleteModalVisible(false);
            }}
            title={t('Delete {{value}}', { value: t('note') })}
            visible={true}
          >
            {t('Are you sure you want to delete this {{value}}?', { value: t('note') })}
          </ConfirmModal>
        )}
      </Accordion>
      <PaginationControls
        className={'mt-3'}
        disabled={query.isLoading}
        {...paginationValues}
        {...paginationHandlers}
      />
    </section>
  );
};

export default NotesList;
