// Copyright © 2023 CATTLEytics Inc.

import { inject, injectable } from 'inversify';

import { TYPES } from '../../../types';
import type Logger from '../../logger/logger';
import ProductAdministration from '../entities/productAdministration';
import Api2Service from './api2Service';
import DataService from './dataService';

const path = '/v1/product-administrations';

/**
 * Service for managing animal product administration records.
 */
@injectable()
export default class ProductAdministrationService implements DataService<ProductAdministration> {
  private logger: Logger;

  private apiService: Api2Service;

  /**
   * Creates an instance of the product type service.
   *
   * @param logger Logger instance.
   * @param apiService API Service instance
   */
  constructor(
    @inject(TYPES.logger) logger: Logger,
    @inject(TYPES.api2Service) apiService: Api2Service,
  ) {
    this.logger = logger;
    this.apiService = apiService;
  }

  /**
   * Retrieve a list of product types
   * @return product type objects
   */
  async list(params?: Record<string, string | undefined>): Promise<ProductAdministration[]> {
    const result = await this.apiService.get<ProductAdministration[]>(path, params);
    if (!result) {
      return [];
    }

    const data = result as ProductAdministration[];
    data.forEach((item) => this.cast(item));
    return data;
  }

  async get(
    id: number,
    params?: Record<string, string>,
  ): Promise<ProductAdministration | undefined> {
    const result = await this.apiService.get<ProductAdministration>(`${path}/${id}`, params);
    if (!result) {
      return undefined;
    }

    const data = result as ProductAdministration;
    this.cast(data);
    return data;
  }

  async post(
    attributes: Partial<ProductAdministration>,
  ): Promise<ProductAdministration | undefined> {
    const result = await this.apiService.post<ProductAdministration>(`${path}`, attributes);
    if (!result) {
      return undefined;
    }
    this.cast(result as ProductAdministration);
    return result;
  }

  async patch(
    id: number,
    attributes: Partial<ProductAdministration>,
  ): Promise<ProductAdministration | undefined> {
    // make sure record identifier is included
    attributes.id = id;

    const result = await this.apiService.patch(`${path}/${id}`, attributes);
    if (!result) {
      return undefined;
    }

    const data = result as ProductAdministration;
    this.cast(data);
    return data;
  }

  async delete(id: number): Promise<null> {
    return await this.apiService.delete(`${path}/${id}`);
  }

  /**
   * Casts fields into javascript types.
   * @param item
   * @return cast object
   */
  cast(item: ProductAdministration): void {
    if (!item) {
      return;
    }
  }
}
