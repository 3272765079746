// Copyright © 2023 CATTLEytics Inc.

import convert, { Unit } from 'convert-units';
import React, { useContext } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import SettingsContext from '../store/settings-context';

/**
 * Defines the input properties for this component.
 */
interface Props extends WithTranslation {
  /**
   * Additional CSS class names.
   */
  className?: string;

  /**
   * The default temperature unit to use.
   */
  defaultUnit?: string;

  /**
   * Precision.
   */
  toFixed?: number;

  /**
   * The value to display (and convert if needed).
   */
  value: number;
}

/**
 * A component to display a temperature unit.
 */
const TemperatureUnit = (props: Props): JSX.Element => {
  const settings = useContext(SettingsContext);
  const toUnit = props.defaultUnit ?? settings.temperatureUnit;
  const fromUnit = 'C';
  const toFixed = props.toFixed ?? 2;

  const value =
    toUnit === fromUnit
      ? Number(props.value).toFixed(toFixed)
      : convert(props.value)
          .from(fromUnit as Unit)
          .to(toUnit as Unit)
          .toFixed(toFixed);

  return (
    <span className={props.className}>
      <span className={'value'}>{value}</span>{' '}
      <span className={'unit'}>
        {toUnit === 'C' ? <>&#8451;</> : null}
        {toUnit === 'F' ? <>&#8457;</> : null}
      </span>
    </span>
  );
};

export default withTranslation()(TemperatureUnit);
