// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import Button, { ButtonVariant } from '../../common/components/Button';
import DataTable, { DataTableHeader, DataTableRow } from '../../common/components/DataTable';
import TablePlaceholder from '../../common/components/TablePlaceholder';
import { Sort } from '../../common/enums';
import SireService from '../../common/services/sireService';
import { IconView } from '../../common/utilities';
import { QueryKey } from '../../shared';

/**
 * A filterable list of sires.
 */
const SireTable = (): JSX.Element => {
  const { t } = useTranslation();

  const sireService = useInjection<SireService>(TYPES.sireService);

  const history = useHistory();

  const [limit, setLimit] = useState<number>(25);
  const [offset, setOffset] = useState<number>(0);
  const [sortField, setSortField] = useState<string>('name');
  const [sortDirection, setSortDirection] = useState<Sort>(Sort.Ascending);

  const query = useQuery(
    [QueryKey.Sires, limit, offset, sortField, sortDirection],
    () =>
      sireService.list({
        limit: String(limit),
        offset: String(offset),
        sortField: sortField,
        sortDirection: String(sortDirection),
      }),
    { keepPreviousData: true },
  );

  if (query.isLoading) {
    return <TablePlaceholder />;
  }

  if (query.isError) {
    return <AlertError message={t('Something unexpected happened while retrieving sires.')} />;
  }

  const headers: DataTableHeader[] = [
    {
      name: 'name',
      label: t('Name'),
      infoTooltip: t('sireTable|sireNameInfoTooltip'),
      classNameMobile: 'col-6',
    },
    {
      name: 'nationalId',
      label: t('National ID'),
      classNameMobile: 'col-6',
    },
    {
      name: 'aiCompany',
      label: t('sireTable|aiCompanyLabel'),
    },
    {
      name: 'owningEntity',
      label: t('Owning Entitiy'),
    },
    {
      name: 'semenCode',
      label: t('Semen Code'),
    },
    {
      name: 'totalNumberOreBreedings',
      label: t('Total Breedings'),
    },
    {
      name: 'breedingsResultingInCalf',
      label: t('Breedings Resulting In Calf'),
    },
    {
      name: 'totalCalvesInHerd',
      label: t('Total Calves In Herd'),
    },
    { name: 'actions', sortable: false, label: 'Actions', hideMobile: true },
  ];

  const data: DataTableRow[] = !query.data
    ? []
    : query.data.map((sire) => {
        return {
          id: String(sire.id),
          name: sire.name,
          nationalId: sire.nationalId,
          aiCompany: sire.aiCompany,
          owningEntity: sire.owningEntity,
          semenCode: sire.semenCode,
          // totalNumberOreBreedings: sire.totalNumberOreBreedings,
          // breedingsResultingInCalf: sire.breedingsResultingInCalf,
          // totalCalvesInHerd: sire.totalCalvesInHerd,
          actions: (
            <Link to={`/sires/${sire.id}`}>
              <Button size={'sm'} variant={ButtonVariant.OutlinePrimary}>
                <IconView className={'me-1'} />
                {t('View')}
              </Button>
            </Link>
          ),
        };
      });

  return (
    <>
      <DataTable
        className={'mt-3'}
        data={data}
        headers={headers}
        isLoading={query.isLoading}
        isPreviousData={query.isPreviousData}
        limit={limit}
        messageNoData={t('sireTable|noSiresFoundMessage')}
        offset={offset}
        onLimitChange={(newLimit): void => setLimit(newLimit)}
        onOffsetChange={(newOffset): void => setOffset(newOffset)}
        onRowClick={(row): void => history.push(`/sires/${row.id}`)}
        onSortDirectionChange={(newSortDirection): void => setSortDirection(newSortDirection)}
        onSortFieldChange={(newSortField): void => setSortField(newSortField)}
        sortDirection={sortDirection}
        sortField={sortField}
      />
    </>
  );
};

export default SireTable;
