// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Defines the input properties for this component.
 */
interface Props {
  /**
   * CSS height attribute for IMG
   */
  height?: string;

  /**
   * Text to display inside image
   */
  label?: string;

  /**
   * CSS width attribute for IMG
   */
  width?: string;
}

/**
 * A component to use when no image has been uploaded
 * @see ImageUploaded
 */
const NoImageUploaded = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div
      className={
        'text-muted text-center border rounded d-flex justify-content-center align-items-center'
      }
      style={{ width: props.width ?? '150px', height: props.height ?? '150px' }}
    >
      {props.label ?? t('No image')}
    </div>
  );
};

export default NoImageUploaded;
