// Copyright © 2023 CATTLEytics Inc.

import { format as dateFnsFormat, formatDistance, isToday } from 'date-fns';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import { useContext } from 'react';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import SettingsContext from '../store/settings-context';

/**
 * Defines the input properties for the Date component.
 */
interface Props {
  /**
   * Date to display
   */
  date?: Date | string;

  /**
   * Date format.
   */
  format?: string;

  /**
   * Whether to include time. Note: if hideTime is true "Today" will be
   * returned as the distance formatted date/time.
   */
  hideTime?: boolean;

  /**
   * If true displays the date distance from now when hovering over the date.
   * If false displays the formatted date when hovering over the distance from now date.
   */
  showDistanceOnHover?: boolean;
}

/**
 * A component for displaying date and time.
 */
const DateTime = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  const settings = useContext(SettingsContext);
  const tzIsDiff = Intl.DateTimeFormat().resolvedOptions().timeZone !== settings.timeZone;

  // if the show distance on hover property is set use it
  // otherwise check the preferredDateTimeStyle setting
  // if the preferredDateTimeStyle setting is set to absolute then
  // we will show the absolute date and the relative (distance) on hover
  const showDistanceOnHover =
    props.showDistanceOnHover !== undefined
      ? props.showDistanceOnHover
      : settings.preferredDateTimeStyle === 'absolute';

  const [dateFormatted, dateDistanceFormatted, tzShortCode] = ((): string[] => {
    if (!props.date) {
      return [t('Invalid date'), t('Invalid date')];
    }
    // if date is a string, parse to Date object
    const date =
      typeof props.date === 'string'
        ? utcToZonedTime(props.date, settings.timeZone)
        : utcToZonedTime(props.date, settings.timeZone);

    const tzShort = formatInTimeZone(date, settings.timeZone, 'zzz'); // Converts America/Toronto to EST or EDT.

    // set the date distance but if we are not supposed to show time and the date is today,
    // set the distance to simply Today as we don't want "x minutes ago"
    const distance =
      props.hideTime && isToday(date)
        ? 'Today'
        : formatDistance(date, utcToZonedTime(new Date(), settings.timeZone), { addSuffix: true });

    try {
      // a date/time format was specified, use it
      if (props.format) {
        return [dateFnsFormat(date, props.format), distance, tzShort];
      }

      // use the date time formats from settings but only use date format if we are hiding time
      const format = props.hideTime
        ? settings.dateFormat
        : `${settings.dateFormat} ${settings.timeFormat}`;

      return [dateFnsFormat(date, format), distance, tzShort];
    } catch (e) {
      return [t('Invalid date'), t('Invalid date'), t('Invalid date')];
    }
  })();

  const hover = showDistanceOnHover ? dateDistanceFormatted : dateFormatted;
  const value = showDistanceOnHover ? dateFormatted : dateDistanceFormatted;

  return (
    <>
      {!props.date && <>-</>}
      {props.date && (
        <OverlayTrigger
          defaultShow={undefined}
          delay={undefined}
          flip={undefined}
          onHide={undefined}
          onToggle={undefined}
          overlay={<Tooltip>{hover}</Tooltip>}
          placement="bottom"
          popperConfig={undefined}
          show={undefined}
          target={undefined}
          trigger={undefined}
        >
          <span className={'text-nowrap'}>
            {value}
            {tzIsDiff && props.hideTime !== true && (
              <Badge bg="secondary" className="ms-1">
                {tzShortCode}
              </Badge>
            )}
          </span>
        </OverlayTrigger>
      )}
    </>
  );
};

export default DateTime;
