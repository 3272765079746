// Copyright © 2023 CATTLEytics Inc.

import React, { CSSProperties, ReactElement } from 'react';

import { Sort } from '../enums';
import InfoTooltip from './InfoTooltip';
import SortIndicator from './SortIndicator';

interface Props {
  className?: string;

  /**
   * A value to display under a heading column in a <code> block
   */
  code?: string;

  /**
   * If set, an info icon with tooltip will be displayed with this text.
   */
  infoTooltip?: string;

  /**
   * Column name
   */
  label: string;

  /**
   * Whether this column is not sortable.
   */
  notSortable?: boolean;

  /**
   * When heading is clicked.
   */
  onClick: () => void;

  /**
   * Display this component.
   */
  showSortIndicator: boolean;

  /**
   * Direction of sort.
   */
  sortDirection: Sort;

  style?: CSSProperties;
}

/**
 * A TH component with sort indicator.
 */
const TableHeadingColumn = (props: Props): JSX.Element => {
  const style = { ...props.style };
  if (!props.notSortable) {
    style.cursor = 'pointer';
  }

  return (
    <th
      className={props.className}
      onClick={props.notSortable ? undefined : props.onClick}
      style={style}
    >
      <div className={'d-flex justify-content-between'}>
        {((): ReactElement => {
          const pieces = props.label.split(' ');
          return (
            <span>
              <span>{pieces.slice(0, pieces.length - 1).join(' ')}</span>{' '}
              <span style={{ whiteSpace: 'nowrap' }}>
                {pieces.slice(-1).join(' ')}
                {props.infoTooltip && <InfoTooltip tooltip={props.infoTooltip} />}
              </span>
            </span>
          );
        })()}

        <SortIndicator sortDirection={props.sortDirection} visible={props.showSortIndicator} />
      </div>
      {props.code && <code>{props.code}</code>}
    </th>
  );
};

export default TableHeadingColumn;
