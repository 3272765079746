// Copyright © 2023 CATTLEytics Inc.
import { DateEntity } from '../../../../common/components/timeline';
import { EventDateItem, EventEntity } from '../types';
import { getColorByLabel } from './getColorByLabel';

export const createEntityItem = ({ date, item }: EventDateItem): DateEntity<EventEntity> => ({
  date,
  entity: item,
  color: getColorByLabel(item.animalEventType?.name),
  label: item.animalEventType?.name,
});
