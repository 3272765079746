// Copyright © 2023 CATTLEytics Inc.
import { RefCallback, useMemo } from 'react';
import useResizeObserver from 'use-resize-observer';

import { convertToTimelineItems } from './convertToTimelineItems';
import { useTimeline } from './TimelineProvider';

type Return = {
  containerRef: RefCallback<HTMLDivElement>;
  data: ReturnType<typeof convertToTimelineItems>;
};
const useGetTimelineItems = (): Return => {
  const { dateFormat, direction, entityDates, maxDate, minDate, nodeDiameter } = useTimeline();
  const { ref: containerRef, ...props } = useResizeObserver<HTMLDivElement>({
    box: 'border-box',
  });

  const data = useMemo(() => {
    const areaSize = props[direction === 'vertical' ? 'height' : 'width'];

    return entityDates.length && areaSize && maxDate && minDate
      ? convertToTimelineItems({
          dates: entityDates,
          areaSize,
          maxDate,
          minDate,
          nodeDiameter,
          dateFormat,
        })
      : [];
  }, [entityDates, minDate, maxDate, props, nodeDiameter, dateFormat, direction]);

  return { containerRef, data } as const;
};

export default useGetTimelineItems;
