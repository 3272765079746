// Copyright © 2023 CATTLEytics Inc.

import { useTranslation } from 'react-i18next';

import Page from '../../common/components/Page';
import FourWeekMilkChart from './FourWeekMilkChart';

const MilkQuantityPage = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Page
      breadcrumbTitle={t('Milk Quantity')}
      breadcrumbs={[{ label: t('Milk'), to: '/test-data' }]}
      title={t('Milk Quantity')}
    >
      <FourWeekMilkChart />
    </Page>
  );
};

export default MilkQuantityPage;
