// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { getFirstLetters } from '../utilities';

/**
 * Properties for the component.
 */
export interface Props {
  /**
   * The background color for the component.
   */
  backgroundColor?: string;

  /**
   * Additional classes.
   */
  className?: string;

  /**
   * The size (height and width) for this component.
   * Example: 32px
   */
  size?: string;

  /**
   * Text to use in the creation of the avatar.
   * Example: John Smith would be shown as JS
   */
  text: string;
}

/**
 * The application's icon
 */
const Avatar = (props: Props & WithTranslation): JSX.Element => {
  return (
    <div
      className={'d-flex rounded justify-content-center align-items-center'}
      style={{
        width: props.size ?? '32px',
        height: props.size ?? '32px',
        color: 'white',
        backgroundColor: props.backgroundColor ?? '#666',
        fontSize: '20px',
        fontWeight: 'bold',
      }}
    >
      <span>{getFirstLetters(props.text, 2)}</span>
    </div>
  );
};

export default withTranslation()(Avatar);
