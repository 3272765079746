// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useEffect, useState } from 'react';
import { Col, Form, FormGroup, PlaceholderButton, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { TYPES } from '../../../types';
import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import Button, { ButtonVariant } from '../../common/components/Button';
import ButtonModalCancel from '../../common/components/ButtonModalCancel';
import ButtonModalDelete from '../../common/components/ButtonModalDelete';
import ButtonModalSave from '../../common/components/ButtonModalSave';
import ConfirmModal from '../../common/components/ConfirmModal';
import DiagnosisSelect from '../../common/components/DiagnosisSelect';
import FormPlaceholder from '../../common/components/FormPlaceholder';
import Modal from '../../common/components/Modal';
import Required from '../../common/components/Required';
import Diagnosis from '../../common/entities/diagnosis';
import ProductAdministration from '../../common/entities/productAdministration';
import ProductAdministrationService from '../../common/services/productAdministrationService';
import { QueryKey } from '../../shared';

/**
 * Component's input properties.
 */
interface Props {
  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Identifier of the item we want to edit
   */
  editId?: number;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;

  /**
   * Callback when save button is clicked and data is valid.
   * @param productAdministration
   */
  onSave?: (productAdministration: ProductAdministration) => void;

  /**
   * If we already have the product ID to assign to this administration
   */
  productId?: number;
}

/**
 * Modal to add/edit a product administration.
 */
const ProductAdminModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const productAdminService = useInjection<ProductAdministrationService>(
    TYPES.productAdministrationService,
  );

  const [showAddDiagnosisModal, setShowAddDiagnosisModal] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>();
  const [validated, setValidated] = useState<boolean>(false);

  const [_, setDiagnosis] = useState<Diagnosis>();
  const [diagnosisId, setDiagnosisId] = useState<string>('');
  const [dosage, setDosage] = useState<string>('');
  const [dosageFactor, setDosageFactor] = useState<string>('100');
  const [dosageInstructions, setDosageInstructions] = useState<string>('');
  const [frequency, setFrequency] = useState<string>('');
  const [meatWithdrawalDays, setMeatWithdrawalDays] = useState<number>();
  const [milkWithdrawalDays, setMilkWithdrawalDays] = useState<number>();
  const [mode, setMode] = useState<string>('');
  const [notes, setNotes] = useState<string>('');
  const [route, setRoute] = useState<string>('');

  const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteConfirmModalErrorMessage, setDeleteConfirmModalErrorMessage] = useState<string>();

  const queryClient = useQueryClient();

  const query = useQuery<ProductAdministration | undefined>(
    [QueryKey.ProductAdministrations, props.editId],
    () => productAdminService.get(props.editId ?? -1),
    {
      keepPreviousData: true,
      enabled: !!props.editId,
      onSuccess: (data) => {
        if (!data) {
          return;
        }
        if (data.diagnosis) {
          setDiagnosis(data.diagnosis);
        }
        setDiagnosisId(String(data.diagnosisId));
        setDosage(String(data.dosage));
        setDosageFactor(String(data.dosageFactor));
        if (data.dosageInstructions) {
          setDosageInstructions(data.dosageInstructions);
        }
        if (data.frequency) {
          setFrequency(data.frequency);
        }
        if (data.route) {
          setRoute(data.route);
        }
        if (data.meatWithdrawalDays !== undefined) {
          setMeatWithdrawalDays(data.meatWithdrawalDays);
        }
        if (data.milkWithdrawalDays !== undefined) {
          setMilkWithdrawalDays(data.milkWithdrawalDays);
        }
        if (data.mode) {
          setMode(data.mode);
        }
        if (data.notes) {
          setNotes(data.notes);
        }
      },
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  const mutation = useMutation<ProductAdministration | undefined>(
    () => {
      const payload: Partial<ProductAdministration> = {
        diagnosisId: Number(diagnosisId),
        dosage: Number(dosage),
        dosageFactor: Number(dosage),
        dosageInstructions: dosageInstructions,
        route: route,
        frequency: frequency,
        meatWithdrawalDays: meatWithdrawalDays ?? undefined,
        milkWithdrawalDays: milkWithdrawalDays ?? undefined,
        mode: mode,
        notes: notes,
        productId: props.productId,
      };

      if (props.editId) {
        return productAdminService.patch(props.editId, payload);
      } else {
        return productAdminService.post(payload);
      }
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKey.ProductAdministrations]);
      },
    },
  );

  useEffect(() => {
    const invalidElements = document.querySelectorAll('input.form-control:invalid');
    if (invalidElements.length > 0) {
      invalidElements[0].closest('.form-group')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [validated]);

  const deleteItem = async (): Promise<void> => {
    if (!props.editId) {
      return;
    }
    setIsDeleting(true);
    try {
      await productAdminService.delete(props.editId);
      await queryClient.invalidateQueries(QueryKey.ProductAdministrations);
      setDeleteConfirmModalVisible(false);
      setIsDeleting(false);
      props.onClose();
    } catch (err) {
      setIsDeleting(false);
      console.error('This item failed to be deleted', err);
      setDeleteConfirmModalErrorMessage(t('common|itemCannotDeleteError'));
    }
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      const pa = await mutation.mutateAsync();
      if (pa) {
        props.onSave && props.onSave(pa);
      }

      props.onClose();
    } catch (err) {
      console.error(`Product administration could not be saved.`, err);
      setErrorMessage(
        t(`{{typeLabel}} could not be saved.`, {
          typeLabel: t('Product administration'),
        }),
      );
    }
  };

  const placeholder = <FormPlaceholder showDeleteButton={!!props.editId} />;

  const form = (
    <Form noValidate onSubmit={handleFormSubmit} validated={validated}>
      <FormGroup className="form-group mb-3" controlId="formDiagnosis">
        <Form.Label>
          {t('Diagnosis')} <Required />
        </Form.Label>
        <DiagnosisSelect
          onChange={(e): void => {
            const diagnosisId = e.target.value;
            setDiagnosisId(diagnosisId);
          }}
          onCloseAddModal={(): void => setShowAddDiagnosisModal(false)}
          required
          showAddModal={showAddDiagnosisModal}
          value={diagnosisId}
        />
        <Form.Text className="text-muted">
          <span>{t('Choose a diagnosis from the dropdown or')}</span>
          <Button onClick={(): void => setShowAddDiagnosisModal(true)} size={'sm'} variant={'link'}>
            {t('add diagnosis')}
          </Button>
        </Form.Text>
      </FormGroup>
      <FormGroup className="form-group mb-3" controlId="formDosageInstructions">
        <Form.Label>
          {t('Dosage Instructions')} <Required />
        </Form.Label>
        <Form.Control
          as={'textarea'}
          name={'dosageInstructions'}
          onChange={(e): void => setDosageInstructions(e.target.value)}
          placeholder={t('productAdminModal|boeInjectionInstructions')}
          required
          rows={2}
          value={dosageInstructions}
        />
        <Form.Text className={'text-muted'}>
          {t('productAdminModal|productAdministrationInstructions')}
        </Form.Text>
      </FormGroup>
      <FormGroup className="form-group mb-3" controlId="formDosage">
        <Form.Label>
          {t('Dosage (mL)')} <Required />
        </Form.Label>
        <Form.Control
          name={'dosage'}
          onChange={(e): void => setDosage(e.target.value)}
          placeholder={t('productAdminModal|dosagePlaceholder')}
          required
          type={'number'}
          value={dosage}
        />
        <Form.Text className={'text-muted'}>
          {t('productAdminModal|dosageAmountInstructions')}
        </Form.Text>
        <br />
        <Form.Text className={'text-muted mt-1'}>
          {t('Example: {{dosage}} mL per {{dosageFactor}}-pound body weight', {
            dosage: dosage,
            dosageFactor: dosageFactor,
          })}
        </Form.Text>
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </FormGroup>
      <FormGroup className="form-group mb-3" controlId="formDosageFactor">
        <Form.Label>
          {t('Dosage Factor (lbs)')} <Required />
        </Form.Label>
        <Form.Control
          name={'dosageFactor'}
          onChange={(e): void => setDosageFactor(e.target.value)}
          placeholder={t('productAdminModal|dosageFactorPlaceholder')}
          required
          type={'number'}
          value={dosageFactor}
        />
        <Form.Text className={'text-muted'}>{t('productAdminModal|dosageFactorExample')}</Form.Text>
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </FormGroup>
      <FormGroup className="form-group mb-3" controlId="formRoute">
        <Form.Label>{t('Route')}</Form.Label>
        <Form.Control
          name={'route'}
          onChange={(e): void => setRoute(e.target.value)}
          placeholder={t('IM/SQ')}
          type={'text'}
          value={route}
        />
      </FormGroup>
      <FormGroup className="form-group mb-3" controlId="formFrequency">
        <Form.Label>{t('Frequency')}</Form.Label>
        <Form.Control
          name={'frequency'}
          onChange={(e): void => setFrequency(e.target.value)}
          placeholder={t('Repeat 72 hours')}
          type={'text'}
          value={frequency}
        />
        <Form.Text className={'text-muted'}>{t('How often to administer the product')}</Form.Text>
      </FormGroup>
      <FormGroup className="form-group mb-3" controlId="formMode">
        <Form.Label>{t('Mode')}</Form.Label>
        <Form.Control
          name={'mode'}
          onChange={(e): void => setMode(e.target.value)}
          placeholder={''}
          type={'text'}
          value={mode}
        />
      </FormGroup>
      <Row>
        <Col sm={6}>
          <FormGroup className="mb-3" controlId="formMilkWithdrawalDays">
            <Form.Label>{t('Milk withdrawal days')}</Form.Label>
            <Form.Control
              name={'milkWithdrawalDays'}
              onChange={(e): void => setMilkWithdrawalDays(Number(e.target.value))}
              value={milkWithdrawalDays}
            />
            <Form.Text className={'text-muted'}>
              {t('The milk withdrawal period in days.')}
            </Form.Text>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup className="mb-3" controlId="forMeatWithdrawalDays">
            <Form.Label>{t('Meat withdrawal days')}</Form.Label>
            <Form.Control
              name={'meatWithdrawalDays'}
              onChange={(e): void => setMeatWithdrawalDays(Number(e.target.value))}
              value={meatWithdrawalDays}
            />
            <Form.Text className={'text-muted'}>
              {t('The meat withdrawal period in days.')}
            </Form.Text>
          </FormGroup>
        </Col>
      </Row>
      <FormGroup className="form-group mb-3" controlId="formNotes">
        <Form.Label>{t('Notes')}</Form.Label>
        <Form.Control
          as={'textarea'}
          name={'notes'}
          onChange={(e): void => setNotes(e.target.value)}
          placeholder={t('Additional information for the administration of this product')}
          rows={4}
          value={notes}
        />
        <Form.Text className={'text-muted'}>
          {t('productAdminModal|productNotesInstructions')}
        </Form.Text>
      </FormGroup>

      <AlertErrorForModal message={errorMessage} />
      <div className="modal-footer modal-footer-in-form">
        <ButtonModalCancel
          disabled={mutation.isLoading || query.isLoading}
          onClick={props.onClose}
        />
        {props.editId && (
          <ButtonModalDelete
            disabled={mutation.isLoading || query.isLoading}
            onClick={(): void => setDeleteConfirmModalVisible(true)}
          />
        )}
        <ButtonModalSave
          busy={mutation.isLoading}
          disabled={mutation.isLoading || query.isLoading}
        />
      </div>
    </Form>
  );

  return (
    <Modal
      fullscreen={'md-down'}
      onClose={props.onClose}
      size={'lg'}
      title={`${props.editId ? t('Modify') : t('common|add')} Product Administration`}
      visible={true}
    >
      {query.isError && (
        <AlertErrorForModal
          message={t('common|unexpectedRetrievalError', {
            value: t('product administration'),
          })}
        />
      )}
      {query.isFetching ? placeholder : form}
      {query.isFetching && (
        <div className="modal-footer modal-footer-in-form">
          <PlaceholderButton variant={'secondary'} xs={2} />
          {props.editId && <PlaceholderButton variant={'danger'} xs={2} />}
          <PlaceholderButton xs={2} />
        </div>
      )}
      <ConfirmModal
        busy={isDeleting}
        cancelLabel={t('Cancel')}
        cancelOnClick={(): void => setDeleteConfirmModalVisible(false)}
        errorMessage={deleteConfirmModalErrorMessage}
        okLabel={t(`Yes, delete this {{value}}`, {
          value: t('product administration'),
        })}
        okOnClick={deleteItem}
        okVariant={ButtonVariant.Danger}
        title={t(`Delete this {{value}}`, { value: t('product administration') })}
        visible={deleteConfirmModalVisible}
      >
        {t('Are you sure you want to delete this {{value}}?', {
          value: t('product administration'),
        })}
      </ConfirmModal>
    </Modal>
  );
};

export default ProductAdminModal;
