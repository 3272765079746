// Copyright © 2023 CATTLEytics Inc.

/**
 * WARNING!
 *
 * DO NOT EDIT THIS FILE
 *
 * CHANGES WILL BE OVERWRITTEN!
 *
 * Make changes in packages/shared and run npm run copy-shared-to-packages
 */

/**
 * Validates a password based on our password validation rules.
 *
 * @param password Password to validate.
 */
export function passwordValidation(password: string): boolean {
  // regex to check if password contains 1 upper, 1 lower, and 1 number
  return password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$/) !== null && password.length >= 8;
}
