// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Col, PlaceholderButton, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import timeZones from 'timezones-list';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import ButtonSave from '../../common/components/ButtonSave';
import PlaceholderForm from '../../common/components/PlaceholderForm';
import Toast from '../../common/components/Toast';
import SettingService from '../../common/services/settingService';
import Logger from '../../logger/logger';
import { QueryKey } from '../../shared/enums';

interface Payload {
  [key: string]: string;
  dateFormat: string;
  defaultUnits: string;
  heightUnit: string;
  language: string;
  temperatureUnit: string;
  timeFormat: string;
  timeZone: string;
  weightUnit: string;
}

const LocalizationTab = (): JSX.Element => {
  const { t } = useTranslation();

  const settingService = useInjection<SettingService>(TYPES.settingService);
  const logger = useInjection<Logger>(TYPES.logger);
  const [data, setData] = useState<Payload>({
    dateFormat: '',
    temperatureUnit: '',
    weightUnit: '',
    heightUnit: '',
    defaultUnits: '',
    language: '',
    timeZone: 'America/Toronto',
    timeFormat: '',
    preferredDateTimeStyle: '',
  });

  const [toastVisible, setToastVisible] = useState<boolean>(false);

  const [validated, setValidated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const onInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const onSelectChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    setData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const queryClient = useQueryClient();

  const query = useQuery(
    [QueryKey.Settings, 'localization'],
    () => settingService.get('localization'),
    {
      onSuccess: (data) => {
        if (data) {
          setData(data as Payload);
        }
      },
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: true,
    },
  );

  const mutation = useMutation(() => settingService.patch('localization', data), {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(QueryKey.Settings);
    },
  });

  useEffect(() => {
    const invalidElements = document.querySelectorAll('input.form-control:invalid');
    if (invalidElements.length > 0) {
      invalidElements[0].closest('.form-group')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [validated]);

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      await mutation.mutateAsync();
      setToastVisible(true);
    } catch (err) {
      logger.error('Settings could not be saved.', err, data);
      setErrorMessage(t('localizationTab|settingsNotSavedError'));
    }
  };

  const placeholder = <PlaceholderForm fields={5} />;

  const fields = (
    <>
      <h2>{t('Date and time')}</h2>
      <Row>
        <Col md={6}>
          <Form.Group
            aria-describedby="dateHelpBlock"
            className="form-group mt-3 mb-1"
            controlId="formGroupDate"
          >
            <Form.Label>{t('Date Format')}</Form.Label>
            <Form.Select
              aria-label="Date Format"
              name={'dateFormat'}
              onChange={onSelectChange}
              value={data.dateFormat}
            >
              <option value="yyyy-MM-dd">yyyy-MM-dd - 2022-02-23</option>
              <option value="dd-MM-yyyy">dd-MM-yyyy - 23-02-2022</option>
              <option value="MM-dd-yyyy">MM-dd-yyyy - 02-23-2022</option>
              <option value="dd-MMM-yyyy">dd-MMM-yyyy - 23-Feb-2022</option>
              <option value="MMM d, yyyy">MMM d, yyyy - Feb 2, 2022</option>
            </Form.Select>
            <Form.Control.Feedback type={'invalid'}>
              {t('common|fieldRequiredFeedback')}
            </Form.Control.Feedback>
            <Form.Text id="dateHelpBlock" muted>
              {t('localizationTab|selectDateDisplayFormatInstructions')}
            </Form.Text>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group
            aria-describedby="timeFormatHelpBlock"
            className="form-group mt-3 mb-1"
            controlId="formGroupTimeFormat"
          >
            <Form.Label>{t('Time Format')}</Form.Label>
            <Form.Select
              aria-label="Time Format"
              name={'timeFormat'}
              onChange={onSelectChange}
              value={data.timeFormat}
            >
              <option value="h:mm aaa">h:mm aaa - 3:00pm</option>
              <option value="H:mm">H:mm - 15:00</option>
              <option value="HH:mm:ss">HH:mm:ss - 22:00:00</option>
            </Form.Select>
            <Form.Control.Feedback type={'invalid'}>
              {t('common|fieldRequiredFeedback')}
            </Form.Control.Feedback>
            <Form.Text id="dateHelpBlock" muted>
              {t('localizationTab|selectTimeDisplayFormatInstructions')}
            </Form.Text>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group
            aria-describedby="preferredDateTimeStyleHelpBlock"
            className="form-group mt-3 mb-1"
            controlId="formGroupPreferredDateTimeStyle"
          >
            <Form.Label>{t('Show dates and times in a relative format')}</Form.Label>
            <div>
              <Form.Check
                checked={data.preferredDateTimeStyle === 'relative'}
                id={'preferredDateTimeStyle-relative'}
                inline
                label={t('Relative (10 minutes ago)')}
                name={'preferredDateTimeStyle'}
                onChange={onInputChange}
                type={'radio'}
                value={'relative'}
              />
              <Form.Check
                checked={data.preferredDateTimeStyle === 'absolute'}
                id={'preferredDateTimeStyle-absolute'}
                inline
                label={t('localizationTab|absoluteTimeStyleLabel')}
                name={'preferredDateTimeStyle'}
                onChange={onInputChange}
                type={'radio'}
                value={'absolute'}
              />
            </div>
            <Form.Text id="preferredDateTimeStyleHelpBlock" muted>
              {t('Note: the opposite style will be displayed when hovering over a date/time')}
            </Form.Text>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group
            aria-describedby="timeZoneHelpBlock"
            className="form-group mt-3 mb-1"
            controlId="formGroupTimeZone"
          >
            <Form.Label> {t('Time Zone')}</Form.Label>
            <Form.Select
              aria-label="Time Zone"
              name={'timeZone'}
              onChange={onSelectChange}
              value={data.timeZone}
            >
              {timeZones.map((tz) => (
                <option key={tz.tzCode} value={tz.tzCode}>
                  {tz.label}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type={'invalid'}>
              {t('common|fieldRequiredFeedback')}
            </Form.Control.Feedback>
            <Form.Text id="timeZoneHelpBlock" muted>
              {t('localizationTab|selectTimeZoneInstructions')}
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <h2>{t('Measurement units')}</h2>
      <Row>
        <Col md={6}>
          <Form.Group
            aria-describedby="unitsHelpBlock"
            className="form-group mt-3 mb-1"
            controlId="formGroupUnits"
          >
            <Form.Label> {t('Default Units')}</Form.Label>
            <div>
              <Form.Check
                checked={data.defaultUnits === 'metric'}
                id={'default-units-metric'}
                inline
                label={t('Metric')}
                name={'defaultUnits'}
                onChange={onInputChange}
                type={'radio'}
                value={'metric'}
              />
              <Form.Check
                checked={data.defaultUnits === 'imperial'}
                id={'default-units-imperial'}
                inline
                label={t('Imperial')}
                name={'defaultUnits'}
                onChange={onInputChange}
                type={'radio'}
                value={'imperial'}
              />
            </div>
            <Form.Text id="unitsHelpBlock" muted>
              {t('localizationTab|displayUnitSelectionInstructions')}
            </Form.Text>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group
            aria-describedby="temperatureHelpBlock"
            className="form-group mt-3 mb-1"
            controlId="formGroupTemperature"
          >
            <Form.Label>{t('Temperature')}</Form.Label>
            <div>
              <Form.Check
                checked={data.temperatureUnit === 'F'}
                id={'temperature-f'}
                inline
                label={t('Fahrenheit (F)')}
                name={'temperatureUnit'}
                onChange={onInputChange}
                type={'radio'}
                value={'F'}
              />
              <Form.Check
                checked={data.temperatureUnit === 'C'}
                id={'temperature-c'}
                inline
                label={t('Celsius (C)')}
                name={'temperatureUnit'}
                onChange={onInputChange}
                type={'radio'}
                value={'C'}
              />
            </div>
            <Form.Text id="temperatureHelpBlock" muted>
              {t('localizationTab|selectTemperatureDisplayInstructions')}
            </Form.Text>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group
            aria-describedby="weightHelpBlock"
            className="form-group mt-3 mb-1"
            controlId="formGroupWeight"
          >
            <Form.Label>{t('Weight')}</Form.Label>
            <div>
              <Form.Check
                checked={data.weightUnit === 'lb'}
                id={'weight-lb'}
                inline
                label={t('Pounds (lb)')}
                name={'weightUnit'}
                onChange={onInputChange}
                type={'radio'}
                value={'lb'}
              />
              <Form.Check
                checked={data.weightUnit === 'kg'}
                id={'weight-kg'}
                inline
                label={t('Kilograms (kg)')}
                name={'weightUnit'}
                onChange={onInputChange}
                type={'radio'}
                value={'kg'}
              />
            </div>
            <Form.Text id="weightHelpBlock" muted>
              {t('localizationTab|selectWeightDisplayInstructions')}
            </Form.Text>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group
            aria-describedby="heightHelpBlock"
            className="form-group mt-3 mb-1"
            controlId="formGroupHeight"
          >
            <Form.Label> {t('Height')}</Form.Label>
            <div>
              <Form.Check
                checked={data.heightUnit === 'in'}
                id={'height-in'}
                inline
                label={t('Inches (in)')}
                name={'heightUnit'}
                onChange={onInputChange}
                type={'radio'}
                value={'in'}
              />
              <Form.Check
                checked={data.heightUnit === 'ft'}
                id={'height-ft'}
                inline
                label={t('Feet (ft)')}
                name={'heightUnit'}
                onChange={onInputChange}
                type={'radio'}
                value={'ft'}
              />
              <Form.Check
                checked={data.heightUnit === 'cm'}
                id={'height-cm'}
                inline
                label={t('Centimeters (cm)')}
                name={'heightUnit'}
                onChange={onInputChange}
                type={'radio'}
                value={'cm'}
              />
              <Form.Check
                checked={data.heightUnit === 'm'}
                id={'height-m'}
                inline
                label={t('Meters (m)')}
                name={'heightUnit'}
                onChange={onInputChange}
                type={'radio'}
                value={'m'}
              />
            </div>
            <Form.Text id="heightHelpBlock" muted>
              {t('localizationTab|selectDisplayInstructions')}
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
      {/* <h2>{t('Language')}</h2>
      <Row>
        <Col md={6}>
          <Form.Group
            aria-describedby="translationsHelpBlock"
            className="form-group mt-3 mb-1"
            controlId="formGroupTranslations"
          >
            <Form.Label> {t('Language')}</Form.Label>
            <Form.Select
              aria-label="language selection"
              name={'language'}
              onChange={onSelectChange}
              value={data.language}
            >
              <option value="en-us">{t('English - US')}</option>
              <option value="en-canada">{t('English - Canada')}</option>
            </Form.Select>
            <Form.Text id="translationsHelpBlock" muted>
              {t('localizationTab|selectLanguageInstructions')}
            </Form.Text>
          </Form.Group>
        </Col>
      </Row> */}
    </>
  );

  return (
    <Form noValidate onSubmit={onFormSubmit} validated={validated}>
      {query.isFetching ? placeholder : fields}

      {errorMessage ? <AlertError message={errorMessage} /> : null}

      <p className={'d-flex justify-content-center'}>
        {query.isFetching ? (
          <PlaceholderButton xs={2} />
        ) : (
          <ButtonSave busy={mutation.isLoading} disabled={mutation.isLoading} label={t('Save')} />
        )}
      </p>
      <Toast onClose={(): void => setToastVisible(false)} show={toastVisible}>
        {t('Settings saved')}
      </Toast>
    </Form>
  );
};

export default LocalizationTab;
