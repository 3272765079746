// Copyright © 2023 CATTLEytics Inc.
export const camelToSnakeCase = (str: string): string =>
  str.replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`).substr(1);

export const camelToTitleCase = (str: string): string =>
  str
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, function (str) {
      return str.toUpperCase();
    })
    .trim();
