// Copyright © 2023 CATTLEytics Inc.
import { withTranslation } from 'react-i18next';
import { FaAsterisk } from 'react-icons/fa';

const Required = (): JSX.Element => {
  return (
    <span>
      <FaAsterisk style={{ color: 'red', fontSize: '.6em' }} />
    </span>
  );
};

export default withTranslation()(Required);
