// Copyright © 2023 CATTLEytics Inc.
import { endOfDay, sub } from 'date-fns';
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';

import { createGenericContext } from '../../../../common/utilities';
import { useGetEventTypesByAnimal } from '../hooks';
import { EventEntity, EventFilters } from '../types';

type AnimalCardEventsContextValues = {
  animalId: string;
  filters: EventFilters;
  hoverEvent: EventEntity | null;
  setFilters: Dispatch<SetStateAction<EventFilters>>;
  setHoverEvent: Dispatch<SetStateAction<EventEntity | null>>;
};

type Props = Pick<AnimalCardEventsContextValues, 'animalId'>;

const [useAnimalCardEvents, AnimalCardEventsContextProvider] =
  createGenericContext<AnimalCardEventsContextValues>('AnimalCardEvents');

const AnimalCardEventsProvider: FC<Props> = ({ animalId, children }) => {
  const [hoverEvent, setHoverEvent] = useState<EventEntity | null>(null);

  const [filters, setFilters] = useState<EventFilters>({
    dateEnd: endOfDay(new Date()),
    dateStart: sub(new Date(), { years: 1 }),
  });

  const { eventTypes } = useGetEventTypesByAnimal({ animalId });

  const value = useMemo(
    () => ({
      animalId,
      filters,
      hoverEvent,
      setHoverEvent,
      setFilters,
    }),
    [animalId, filters, hoverEvent, setHoverEvent, setFilters],
  );

  useEffect(() => {
    if (eventTypes) {
      setFilters((prev) => ({
        ...prev,
        animalEventTypeIds: eventTypes.map(({ id }) => id),
      }));
    }
  }, [eventTypes, setFilters]);

  return (
    <AnimalCardEventsContextProvider value={value}>{children}</AnimalCardEventsContextProvider>
  );
};

export { AnimalCardEventsProvider, useAnimalCardEvents };
