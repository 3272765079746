// Copyright © 2023 CATTLEytics Inc.

import { Formik } from 'formik';
import { useInjection } from 'inversify-react';
import React, { ReactElement, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import bg from '../../../assets/img/splash.jpg';
import { TYPES } from '../../../types';
import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import AlertSuccessForModal from '../../common/components/AlertSuccessForModal';
import Button from '../../common/components/Button';
import Page from '../../common/components/Page';
import AuthService from '../../common/services/authService';
import { IconSave } from '../../common/utilities';
import { IconSignIn } from '../../common/utilities';
import Logger from '../../logger/logger';

/**
 * Component properties.
 */

interface Payload {
  password: string;
  resetToken: string;
}

interface RouteParams {
  /**
   * token from reset password link
   */
  token: string;
}

/**
 * Routable component to authenticate a user.
 */
const PasswordResetPage = (): JSX.Element => {
  const { t } = useTranslation();

  const { token } = useParams<RouteParams>();
  const history = useHistory();
  const authService = useInjection<AuthService>(TYPES.authService);
  const logger = useInjection<Logger>(TYPES.logger);

  const [userRedirectButtonVisible, setUserRedirectButtonVisible] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [data, setData] = useState<Payload>({
    resetToken: '',
    password: '',
  });
  const schema = yup.object().shape({
    password: yup
      .string()
      .required(t('common|fieldRequiredFeedback'))
      .matches(/^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/)
      .min(
        8,
        t(
          'Password must be at least 8 characters long and contain at least 1 uppercase, 1 lowercase, and 1 number',
        ),
      ),
    confirmPass: yup
      .string()
      .required(t('common|fieldRequiredFeedback'))
      .oneOf([yup.ref('password')], t('passwordResetPage|passwordDontMatchMessage')),
  });

  const routeChange = (): void => {
    history.push('/auth');
  };

  // need to add request to send password email reset to user
  const onFormSubmit = async (values: any): Promise<void> => {
    setData({ resetToken: token, password: values.password });
    // data.password = values.password;
    try {
      await mutation.mutateAsync();
      values.password = '';
      values.confirmPass = '';
    } catch (err) {
      logger.error('Request Failed', err, data);
      setErrorMessage(t('Password Reset Token has expired!'));
    }
  };

  // change to newPassword api request
  const mutation = useMutation<null | undefined, Error>(
    () =>
      authService.newPassword({
        resetToken: data.resetToken,
        password: data.password,
      }),
    {
      onSuccess: async () => {
        setUserRedirectButtonVisible(true);
        setSuccessMessage(
          t(`Successfully changed password. Please sign in by clicking the button below`),
        );
      },
    },
  );

  return (
    <div
      className={'vh-100 d-flex justify-content-center align-items-center'}
      style={{
        background: `url(${bg}) center center no-repeat`,
        backgroundSize: 'cover',
      }}
    >
      <Page
        className={'sign-in-form col-md-5'}
        hideBreadcrumbs={true}
        style={{ height: 'auto' }}
        title={t('Reset Password')}
      >
        <Formik
          initialValues={{
            password: '',
            confirmPass: '',
          }}
          onSubmit={onFormSubmit}
          validationSchema={schema}
        >
          {({ handleSubmit, handleChange, values, errors }): ReactElement<any, any> => (
            <Form noValidate onSubmit={handleSubmit}>
              {!successMessage && (
                <>
                  <Form.Group className="mb-3" controlId="auth.ControlPassword">
                    <Form.Label>{t('Password')}</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        isInvalid={!!errors.password}
                        name="password"
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {/* {errors.password} */}
                        {t(
                          'Password must be at least 8 characters long and contain at least 1 uppercase, 1 lowercase, and 1 number',
                        )}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="auth.ControlConfirmPass">
                    <Form.Label>{t('Confirm Password')}</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        isInvalid={!!errors.confirmPass}
                        name="confirmPass"
                        onChange={handleChange}
                        type="password"
                        value={values.confirmPass}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmPass}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </>
              )}
              <AlertErrorForModal message={errorMessage} />
              <AlertSuccessForModal message={successMessage} />
              {!userRedirectButtonVisible && (
                <div className={'d-grid gap-2'}>
                  <Button
                    disabled={!!errors.confirmPass || !!errors.password}
                    icon={IconSave}
                    label={t('Save')}
                    size={'lg'}
                    type={'submit'}
                  />
                </div>
              )}
              {userRedirectButtonVisible && (
                <div className={'d-grid gap-2'}>
                  <Button
                    icon={IconSignIn}
                    label={t('Sign in')}
                    onClick={routeChange}
                    size={'lg'}
                    type={'button'}
                  />
                </div>
              )}
            </Form>
          )}
        </Formik>
      </Page>
    </div>
  );
};

export default PasswordResetPage;
