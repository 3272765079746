// Copyright © 2024 CATTLEytics Inc.

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Button from '../../common/components/Button';
import { IconCheck, IconNotAllowed, IconWarning } from '../../common/utilities/icons';
import { UserSchedule } from '../../shared';

interface Props {
  featureFlagsAllowSwapping: boolean;
  onSwapClicked: (schedule: UserSchedule) => void;
  schedule: UserSchedule;
}

export function SwapButton({
  featureFlagsAllowSwapping,
  schedule,
  onSwapClicked,
}: Props): JSX.Element {
  const { t } = useTranslation();

  // when the site's feature flag is set to false or doesn't have a value:
  if (!featureFlagsAllowSwapping) {
    return <></>;
  }

  // User has `allowSwapping = false` set on their account.
  if (schedule?.user?.permissions['scheduleAllowSwapping'] === false) {
    return (
      <OverlayTrigger
        overlay={<Tooltip>{t('manageSchedulesForm|stateRestrictedTooltip')}</Tooltip>}
        placement="top"
        trigger="hover"
      >
        <span className="d-inline-block">
          <Button
            disabled
            icon={IconNotAllowed}
            label={t('manageSchedulesForm|stateRestricted')}
            variant="outline-warning"
          />
        </span>
      </OverlayTrigger>
    );
  }

  // when the schedule is set to no swap
  return !schedule?.allowSwap ? (
    <Button
      icon={IconWarning}
      label={t('manageSchedulesForm|stateNotAllowedLabel')}
      onClick={(): void => onSwapClicked(schedule)}
      variant={'outline-primary'}
    />
  ) : (
    // when the schedule is set to allow swap
    <Button
      icon={IconCheck}
      label={t('manageSchedulesForm|stateAllowedLabel')}
      onClick={(): void => onSwapClicked(schedule)}
      title={'help['}
      variant={'outline-success'}
    />
  );
}
