// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { TYPES } from '../../../types';
import DataCell from '../../common/components/DataCell';
import PenService from '../../common/services/penService';
import { QueryKey } from '../../shared';
import CardStatsPlaceholder from './CardStatsPlaceholder';

interface Props {
  penId: number;
}

/**
 * Pen stats detail.
 */
const PenStats = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const penService = useInjection<PenService>(TYPES.penService);
  const penId = props.penId;

  const query = useQuery([QueryKey.Pens, 'get', penId], () => penService.get(penId));

  const pen = query.data;
  if (!pen) {
    return <CardStatsPlaceholder />;
  }
  return (
    <Row>
      <Col md={4} xs={6}>
        <DataCell
          label={t('Capacity')}
          tooltip={t('penStats|penCapacityTooltip')}
          value={pen?.capacity}
        />
      </Col>
      <Col md={4} xs={6}>
        <DataCell
          label={t('Animals')}
          tooltip={t('penStats|penOccupancyTooltip')}
          value={pen?.totalAnimals}
        />
      </Col>
      <Col md={4} xs={6}>
        <DataCell
          label={t('Pregnant')}
          tooltip={t('penStats|numberOfPregnantAnimalsTooltip')}
          value={pen.totalPregnant}
        />
      </Col>
      <Col md={4} xs={6}>
        <DataCell
          label={t('Open')}
          tooltip={t('penStats|totalOpenAnimalsInPen')}
          value={pen?.totalOpen}
        />
      </Col>
      <Col md={4} xs={6}>
        <DataCell
          label={t('Dried-Off')}
          tooltip={t('penStats|totalDryAnimalsInPen')}
          value={pen?.totalDriedOff}
        />
      </Col>
      <Col md={4} xs={6}>
        <DataCell
          label={t('Days In Milk')}
          tooltip={t('penStats|avgDaysInMilkDescription')}
          value={pen?.averageDaysInMilk}
        />
      </Col>
      <Col md={4} xs={6}>
        <DataCell
          label={t('Lactation Group 1')}
          tooltip={t('penStats|totalAnimalsInLactationGroup1')}
          value={pen?.totalLactationGroup1}
        />
      </Col>
      <Col md={4} xs={6}>
        <DataCell
          label={t('Lactation Group 2')}
          tooltip={t('penStats|totalAnimalsInLactationGroup2')}
          value={pen?.totalLactationGroup2}
        />
      </Col>
      <Col md={4} xs={6}>
        <DataCell
          label={t('Lactation Group 3')}
          tooltip={t('penStats|totalAnimalsInLactationGroup3')}
          value={pen?.totalLactationGroup2}
        />
      </Col>
    </Row>
  );
};

export default PenStats;
