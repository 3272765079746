// Copyright © 2023 CATTLEytics Inc.

import React, { PropsWithChildren, useMemo, useState } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Icons from 'react-icons/io5';
import { useQuery, useQueryClient } from 'react-query';

import AlertError from '../../common/components/AlertError';
import Button, { ButtonVariant } from '../../common/components/Button';
import ConfirmModal from '../../common/components/ConfirmModal';
import DataTable, { DataTableHeader, DataTableRow } from '../../common/components/DataTable';
import TablePlaceholder from '../../common/components/TablePlaceholder';
import { Sort } from '../../common/enums';
import { useIsAuthUserSuperAdmin } from '../../common/hooks';
import { api, IconDelete, IconEdit, scrollToTop } from '../../common/utilities';
import { ApiResourceV1, HttpMethod, QueryKey } from '../../shared';
import { TaskCategory } from '../../shared';
import { TaskCategoryModal } from './TaskCategoryModal';

interface Props {
  /**
   * Number of events to show.
   */
  initialLimit?: number;

  /**
   * Number of rows to skip.
   */
  initialOffset?: number;

  /**
   * Table sort direction.
   */
  initialSortDirection?: Sort;

  /**
   * Table sort field.
   */
  initialSortField?: string;
}

/**
 * Table of task categories.
 */
export const TaskCategoriesTable = (props: PropsWithChildren<Props>): JSX.Element => {
  const isSuperAdmin = useIsAuthUserSuperAdmin();

  const { t } = useTranslation();

  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [editId, setEditId] = useState<number>(0);

  const [deleteConfirmModalErrorMessage, setDeleteConfirmModalErrorMessage] = useState<string>('');
  const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();

  const [limit, setLimit] = useState<number>(props.initialLimit ?? 25);
  const [offset, setOffset] = useState<number>(props.initialOffset ?? 0);
  const [sortField, setSortField] = useState<string>(props.initialSortField ?? 'name');
  const [sortDirection, setSortDirection] = useState<Sort>(
    props.initialSortDirection ?? Sort.Ascending,
  );

  const query = useQuery<TaskCategory[]>(
    [QueryKey.TaskCategories, 'list', limit, offset, sortField, sortDirection],
    () =>
      api<TaskCategory[]>(HttpMethod.Get, ApiResourceV1.TaskCategories, {
        params: {
          limit: String(limit),
          offset: String(offset),
          sortField: sortField,
          sortDirection: String(sortDirection),
        },
      }),
    {
      keepPreviousData: true,
      onSuccess: scrollToTop,
    },
  );

  const queryClient = useQueryClient();

  const deleteItem = async (): Promise<void> => {
    if (!deleteId) {
      return;
    }
    setIsDeleting(true);
    try {
      await api(HttpMethod.Delete, `${ApiResourceV1.TaskCategories}/${deleteId}`);
      await queryClient.invalidateQueries(QueryKey.TaskCategories);
      setDeleteConfirmModalVisible(false);
    } catch (err) {
      console.error('Task category could not be deleted', err);
      setDeleteConfirmModalErrorMessage(
        t('error|deleteFailed', { value: t('entity|taskCategory') }),
      );
    }
    setIsDeleting(false);
  };

  const showEditModal = (id: number): void => {
    setEditModalVisible(true);
    setEditId(id);
  };

  const showDeleteConfirmModal = (id: number): void => {
    setDeleteConfirmModalVisible(true);
    setDeleteId(id);
  };

  const headers: DataTableHeader[] = useMemo(() => {
    const headers: DataTableHeader[] = [
      {
        name: 'iconName',
        label: t('taskCategoriesTable|header|iconNameLabel'),
      },
      {
        name: 'name',
        label: t('taskCategoriesTable|header|nameLabel'),
      },
      {
        name: 'description',
        label: t('taskCategoriesTable|header|descriptionLabel'),
      },
    ];
    if (isSuperAdmin) {
      headers.push({
        name: 'actions',
        sortable: false,
        label: t('taskCategoriesTable|header|actionLabel'),
        hideMobile: true,
      });
    }
    return headers;
  }, [t, isSuperAdmin]);

  if (query.isLoading) {
    return <TablePlaceholder />;
  }

  if (query.isError) {
    return (
      <AlertError
        message={t('error|listFailed', { values: t('entity|taskCategory', { count: 2 }) })}
      />
    );
  }

  // @TODO useMemo
  const data: DataTableRow[] = !query.data
    ? []
    : query.data.map((row) => {
        const Icon = row.iconName
          ? Icons[row.iconName as keyof typeof Icons]
          : (): JSX.Element => <div />;
        return {
          id: String(row.id),
          iconName: <Icon className={'text-body'} size={32} />,
          name: row.name,
          description: (
            <div className={'text-truncate text-truncate-clamp-2'}>{row.description}</div>
          ),
          actions: (
            <>
              <div onClick={(e): void => e.stopPropagation()}>
                <Dropdown as={ButtonGroup}>
                  <Button
                    className={'text-nowrap'}
                    onClick={(): void => showEditModal(row.id)}
                    size="sm"
                    variant="outline-primary"
                  >
                    <IconEdit className={'me-1'} /> {t('Edit')}
                  </Button>
                  <Dropdown.Toggle size="sm" split variant="outline-primary" />
                  <Dropdown.Menu align="end">
                    <Dropdown.Item
                      className={'text-nowrap'}
                      onClick={(): void => showEditModal(row.id)}
                    >
                      <IconEdit className={'me-1'} /> {t('Edit')}
                    </Dropdown.Item>

                    <Dropdown.Item
                      className={'text-nowrap'}
                      onClick={(): void => showDeleteConfirmModal(row.id)}
                    >
                      <IconDelete className={'me-1'} />
                      {t('Delete')}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </>
          ),
        };
      });
  return (
    <>
      <DataTable
        data={data}
        headers={headers}
        isLoading={query.isLoading}
        isPreviousData={query.isPreviousData}
        limit={limit}
        messageNoData={t('No task categories found.')}
        offset={offset}
        onLimitChange={(newLimit): void => setLimit(newLimit)}
        onOffsetChange={(newOffset): void => setOffset(newOffset)}
        onRowClick={(row): void => showEditModal(Number(row.id))}
        onSortDirectionChange={(newSortDirection): void => setSortDirection(newSortDirection)}
        onSortFieldChange={(newSortField): void => setSortField(newSortField)}
        sortDirection={sortDirection}
        sortField={sortField}
      />
      {editModalVisible && (
        <TaskCategoryModal editId={editId} onClose={(): void => setEditModalVisible(false)} />
      )}
      <ConfirmModal
        busy={isDeleting}
        cancelLabel={t('Cancel')}
        cancelOnClick={(): void => setDeleteConfirmModalVisible(false)}
        errorMessage={deleteConfirmModalErrorMessage}
        okLabel={t(`Yes, delete this {{value}}`, { value: t('entity|taskCategory') })}
        okOnClick={deleteItem}
        okVariant={ButtonVariant.Danger}
        title={t(`Delete this {{value}}`, { value: t('entity|taskCategory') })}
        visible={deleteConfirmModalVisible}
      >
        {t('Are you sure you want to delete this {{value}}?', { value: t('entity|taskCategory') })}
      </ConfirmModal>
    </>
  );
};
