// Copyright © 2023 CATTLEytics Inc.
import { FC, useMemo, useState } from 'react';

import { Animal } from '../../shared';
import DefaultCalfImage from './DefaultCalfImage';
import DefaultCowImage from './DefaultCowImage';

const cowImageWidth = 40;
const calfImageWith = 35;

interface Props {
  /**
   * Animal entity
   */
  animal: Animal;
}

const AnimalTableImage: FC<Props> = ({ animal }) => {
  const [hasLoadError, setHasLoadError] = useState(false);
  const src = useMemo(() => animal.imageUrlSigned, [animal]);
  const img = useMemo(() => {
    if (src) {
      return (
        <img
          alt={animal.name ?? animal.primaryTag ?? ''}
          className="w-100 h-100"
          onError={(): void => setHasLoadError(true)}
          src={src}
          style={{ objectFit: 'cover' }}
        />
      );
    } else if (animal.isCalf) {
      return <DefaultCalfImage style={{ width: calfImageWith }} />;
    }

    return <DefaultCowImage style={{ width: cowImageWidth }} />;
  }, [animal, src]);

  return (
    <div
      className="rounded-circle overflow-hidden img-thumbnail p-0 d-flex justify-content-center align-items-center"
      style={{ width: 50, height: 50 }}
    >
      {hasLoadError ? <DefaultCowImage style={{ width: cowImageWidth }} /> : img}
    </div>
  );
};

export default AnimalTableImage;
