// Copyright © 2023 CATTLEytics Inc.

import React, { Fragment } from 'react';
import { Placeholder } from 'react-bootstrap';

/**
 * Defines the input properties for this component.
 */
interface Props {
  // The number of fields to return placeholders for
  fields?: number;
}

/**
 * A placeholder form.
 */
const PlaceholderForm = (props: Props): JSX.Element => {
  const fields = [...Array(props.fields ?? 3).keys()];
  return (
    <Placeholder animation={'glow'}>
      {fields.map((key) => (
        <Fragment key={key}>
          <Placeholder xs={6} />
          <Placeholder className={'mb-5'} size={'lg'} xs={12} />
        </Fragment>
      ))}
    </Placeholder>
  );
};

export default PlaceholderForm;
