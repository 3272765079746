// Copyright © 2023 CATTLEytics Inc.

import React, { useEffect, useState } from 'react';
import { Form, InputGroup, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useMutation, useQuery } from 'react-query';

import AlertErrorForModal from '../common/components/AlertErrorForModal';
import AlertSuccessForModal from '../common/components/AlertSuccessForModal';
import Button from '../common/components/Button';
import Required from '../common/components/Required';
import { IconCaretRight } from '../common/utilities';
import { api } from '../common/utilities';
import { QueryKey } from '../shared';
import { ApiResourceV1, HttpMethod } from '../shared';

interface StripeProduct {
  currency: string;
  defaultPrice: number;
  description: string;
  id: string;
  name: string;
  priceId: string;
  url: string;
}
interface StripeSetupIntent {
  clientSecret: string;
  subscriptionId: string;
}

interface Props {
  onComplete: (subscriptionId: string, clientSecret: string, promoCode: string) => void;
}
/**
 * Plan selection component
 */
export const ChoosePlan = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  const [promoCode, setPromoCode] = useState<string>('');
  const [promoCodeTouched, setPromoCodeTouched] = useState<boolean>(false);
  const [products, setProducts] = useState<StripeProduct[]>([]);
  // we are storing a stripe price ID here
  const [plan, setPlan] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [successMessageCoupon, setSuccessMessageCoupon] = useState<string>();
  const [validated, setValidated] = useState<boolean>(false);
  const [hasValidCoupon, setHasValidCoupon] = useState<boolean>(false);

  const queryProducts = useQuery<StripeProduct[]>(
    QueryKey.StripeProducts,
    () => api<StripeProduct[]>(HttpMethod.Get, ApiResourceV1.StripeProducts),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
    },
  );

  useEffect(() => {
    if (queryProducts.data) {
      setProducts(queryProducts.data);
      if (queryProducts.data.length > 0) {
        setPlan(queryProducts.data[0].priceId);
      }
    }
  }, [queryProducts.data]);

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      await mutation.mutateAsync();
    } catch (err) {
      console.error('Sign up failed', err);
      setErrorMessage((err as Error).message);
    }
  };

  const mutation = useMutation<StripeSetupIntent>(
    () =>
      api(HttpMethod.Post, ApiResourceV1.StripeSubscriptions, {
        body: {
          priceId: plan,
          promoCode: promoCode,
        },
      }),
    {
      onSuccess: (data) =>
        props.onComplete(data.subscriptionId, data.clientSecret, hasValidCoupon ? promoCode : ''),
    },
  );

  const mutationCoupon = useMutation<StripeSetupIntent>(
    () =>
      api(HttpMethod.Patch, `${ApiResourceV1.SignUp}/1`, {
        body: {
          promoCode: promoCode,
        },
      }),
    {
      onSuccess: () => {
        setHasValidCoupon(true);
      },
      onError: () => {
        setHasValidCoupon(false);
      },
    },
  );

  return (
    <Form noValidate onSubmit={onFormSubmit} validated={validated}>
      <Form.Group className={'my-3 form-group'}>
        <Form.Label>
          {t('choosePlan|choosePlanLabel')} <Required />
        </Form.Label>
        {products.map((product) => (
          <>
            <Form.Check
              checked={product.priceId === plan}
              className={'mb-3'}
              id={`plan-${product.priceId}`}
              key={product.priceId}
              label={
                <>
                  <Stack direction="horizontal">
                    <strong>{product.name}</strong>
                    {product.url !== undefined && product.url !== null && (
                      <a className="ms-2" href={product.url} rel="noreferrer" target="_blank">
                        {t('choosePlan|seeMoreLabel')}
                        <FaExternalLinkAlt className="ms-2" />
                      </a>
                    )}
                  </Stack>
                  ${product.defaultPrice} {product.currency}{' '}
                  {hasValidCoupon && <>{t('choosePlan|subscriptionPriceDescription')}</>}
                </>
              }
              onChange={(e): void => setPlan(e.target.value)}
              type={'radio'}
              value={product.priceId}
            />
          </>
        ))}
      </Form.Group>
      <Form.Group className="mb-3" controlId="promoCode">
        <Form.Label>{t('choosePlan|couponLabel')}</Form.Label>
        <InputGroup>
          <Form.Control
            name={'promoCode'}
            onChange={(e): void => {
              setPromoCode(e.target.value);
              setPromoCodeTouched(true);
            }}
            placeholder={''}
            readOnly={false}
            type={'text'}
            value={promoCode}
          />
          <Form.Control.Feedback type={'invalid'}>
            {t('choosePlan|requiredField')}
          </Form.Control.Feedback>
          <Button
            onClick={async (e): Promise<void> => {
              e.preventDefault();

              setErrorMessage('');
              setSuccessMessageCoupon('');
              if (!promoCode) return;
              try {
                await mutationCoupon.mutateAsync();
                setSuccessMessageCoupon(t('choosePlan|couponMessageFreeTrial'));
              } catch (err) {
                console.error('Could not add coupon', err);
                setErrorMessage((err as Error).message);
              }
            }}
            type={'button'}
            variant={'outline-primary'}
          >
            {t('choosePlan|applyButton')}
          </Button>
        </InputGroup>
      </Form.Group>
      <AlertSuccessForModal message={successMessageCoupon} />
      <AlertErrorForModal message={errorMessage} />
      <div className={'d-grid gap-2'}>
        <Button
          busy={mutation.isLoading}
          // disable button if a mutation is loading OR
          // a promo code is set and it is not valid
          disabled={mutation.isLoading || (promoCode && promoCodeTouched && !hasValidCoupon)}
          icon={IconCaretRight}
          label={t('choosePlan|continueButton')}
          size={'lg'}
          type={'submit'}
        />
      </div>
    </Form>
  );
};
