// Copyright © 2023 CATTLEytics Inc.

import { inject, injectable } from 'inversify';

import { TYPES } from '../../../types';
import Logger from '../../logger/logger';
import { ProductClass } from '../entities/productClass';
import Api2Service from './api2Service';
import DataService from './dataService';

const path = '/v1/product-classes';

/**
 * Service for managing product classes.
 */
@injectable()
export default class ProductClassService implements DataService<ProductClass> {
  private logger: Logger;

  private apiService: Api2Service;

  /**
   * Creates an instance of the product class service.
   *
   * @param logger Logger instance.
   * @param apiService API Service instance
   */
  constructor(
    @inject(TYPES.logger) logger: Logger,
    @inject(TYPES.api2Service) apiService: Api2Service,
  ) {
    this.logger = logger;
    this.apiService = apiService;
  }

  /**
   * Retrieve a list of product classes
   * @return product class objects
   */
  async list(params?: Record<string, string>): Promise<ProductClass[]> {
    const result = await this.apiService.get<ProductClass[]>(path, params);
    if (!result) {
      return [];
    }

    const data = result as ProductClass[];
    data.forEach((item) => this.cast(item));
    return data;
  }

  async get(id: number, params?: Record<string, string>): Promise<ProductClass | undefined> {
    const result = await this.apiService.get<ProductClass>(`${path}/${id}`, params);
    if (!result) {
      return undefined;
    }

    const data = result as ProductClass;
    this.cast(data);
    return data;
  }

  async post(attributes: Record<string, string>): Promise<ProductClass | undefined> {
    const result = await this.apiService.post<ProductClass>(`${path}`, attributes);
    if (!result) {
      return undefined;
    }
    this.cast(result as ProductClass);
    return result;
  }

  async patch(
    id: number,
    attributes: Record<string, string | number | null>,
  ): Promise<ProductClass | undefined> {
    // make sure record identifier is included
    attributes.id = id;

    const result = await this.apiService.patch(`${path}/${id}`, attributes);
    if (!result) {
      return undefined;
    }

    const data = result as ProductClass;
    this.cast(data);
    return data;
  }

  async delete(id: number): Promise<null> {
    return await this.apiService.delete(`${path}/${id}`);
  }

  /**
   * Casts fields into javascript classes.
   * @param item
   * @return cast object
   */
  cast(item: ProductClass): void {
    if (!item) {
      return;
    }
  }
}
