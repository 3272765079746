// Copyright © 2023 CATTLEytics Inc.

import 'react-big-calendar/lib/sass/styles.scss';

import { format, getDay, parse, startOfWeek } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { useInjection } from 'inversify-react';
import { useEffect, useMemo, useState } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { TYPES } from '../../../types';
import { AnimalEventType, Sort } from '../../common/enums';
import AnimalEventService from '../../common/services/animalEventService';
import { nameTag } from '../../shared';
import { QueryKey } from '../../shared/enums';
import EventDetailModal from './EventDetailModal';

interface Props extends WithTranslation {
  eventTypeIds?: AnimalEventType[];
}

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

/**
 * Routable component for calendar.
 */
const EventsCalendar = (props: Props): JSX.Element => {
  //const initialDate = new Date();
  const animalEventService = useInjection<AnimalEventService>(TYPES.animalEventService);
  //const [dateStart, setDateStart] = useState<Date>(subDays(initialDate, 30));
  //const [dateEnd, setDateEnd] = useState<Date>(addDays(initialDate, 30));
  const [detailId, setDetailId] = useState<number>();
  const [date, setDate] = useState<Date>();
  const query = useQuery(
    [QueryKey.AnimalEvents, props.eventTypeIds], //, dateStart, dateEnd],
    () =>
      animalEventService.list({
        //dateStart: dateStart.toISOString(),
        //dateEnd: dateEnd.toISOString(),
        sortField: 'eventDateTime',
        sortDirection: Sort.Descending,
        animalEventTypeIds: props.eventTypeIds?.join(','),
      }),
    { keepPreviousData: true },
  );

  const events = useMemo(
    () =>
      query.data?.map((event) => ({
        id: event.id,
        allDay: true,
        title: `${event.animalEventType?.name} - ${nameTag(
          event.animal?.primaryTag,
          event.animal?.name,
        )}`,
        start: event.eventDateTime,
        end: event.eventDateTime,
      })) ?? [],
    [query.data],
  );

  useEffect(() => {
    if (events.length) {
      setDate(events[0].start);
    }
  }, [events]);

  if (query.isLoading) {
    return <span>Loading...</span>;
  }

  return (
    <section className="mt-3">
      <Calendar
        date={date}
        endAccessor="end"
        events={events}
        localizer={localizer}
        onNavigate={(date): void => setDate(date)}
        onSelectEvent={(evt): void => setDetailId(evt.id)}
        startAccessor="start"
        style={{ minHeight: 500 }}
      />
      {detailId && (
        <EventDetailModal
          animalEventId={detailId as number}
          onClose={(): void => setDetailId(undefined)}
        />
      )}
    </section>
  );
};

export default withTranslation()(EventsCalendar);
