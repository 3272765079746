// Copyright © 2023 CATTLEytics Inc.
import convert, { Unit } from 'convert-units';
import { TFunction } from 'i18next';

export const placeholderExample = (
  t: TFunction,
  value: string | number,
  fromUnit: string,
  toUnit: string,
): string => {
  return t('Example: {{value}}', {
    value: convert(Number(value))
      .from(fromUnit as Unit)
      .to(toUnit as Unit)
      .toFixed(2),
  });
};
