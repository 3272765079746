// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { useTranslation } from 'react-i18next';

import Page from '../../common/components/Page';
import MethaneChart from './MethaneChart';

const MethanePage = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Page title={t('Methane')}>
      <MethaneChart />
    </Page>
  );
};

export default MethanePage;
