// Copyright © 2023 CATTLEytics Inc.

import {
  add,
  endOfQuarter,
  endOfYear,
  isEqual,
  startOfDay,
  startOfQuarter,
  startOfYear,
} from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { ButtonGroup, Dropdown, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import DateRangePicker from '../../../../common/components/DateRangePicker';
import { dateFormat } from '../../../../shared';
import { useAnimalCardEventFilter } from '../hooks';

enum DateRangePreset {
  LastFiveYear = 'animalEventsDateRange|lastFiveYears',
  LastQuarter = 'animalEventsDateRange|lastQuarter',
  LastYear = 'animalEventsDateRange|lastYear',
  ThisQuarter = 'animalEventsDateRange|thisQuarter',
  ThisYear = 'animalEventsDateRange|thisYear',
  Unknown = 'animalEventsDateRange|ranges',
}

/**
 * Detects the date preset by the filled in start and end dates
 */
function computePreset(startDate: Date | null, endDate: Date | null): DateRangePreset {
  const s = startDate ?? new Date();
  const e = endDate ?? new Date();
  const today = startOfDay(new Date());
  const lastYear = add(today, { years: -1 });
  const dateInPreviousQuarter = add(startOfQuarter(today), { days: -1 });

  if (isEqual(s, startOfYear(today)) && isEqual(e, endOfYear(today))) {
    return DateRangePreset.ThisYear;
  } else if (isEqual(s, startOfYear(lastYear)) && isEqual(e, endOfYear(lastYear))) {
    return DateRangePreset.LastYear;
  } else if (isEqual(s, startOfQuarter(today)) && isEqual(e, endOfQuarter(today))) {
    return DateRangePreset.ThisQuarter;
  } else if (
    isEqual(s, startOfQuarter(dateInPreviousQuarter)) &&
    isEqual(e, endOfQuarter(dateInPreviousQuarter))
  ) {
    return DateRangePreset.LastQuarter;
  } else if (isEqual(s, add(today, { years: -5 })) && isEqual(e, today)) {
    return DateRangePreset.LastFiveYear;
  }
  return DateRangePreset.Unknown;
}

const AnimalEventsDateRangePicker = (): JSX.Element => {
  const { t } = useTranslation();
  const [dateStart, setDateStart] = useAnimalCardEventFilter('dateStart');
  const [dateEnd, setDateEnd] = useAnimalCardEventFilter('dateEnd');
  const [startDate, setStartDate] = useState<Date | null>(dateStart);
  const [endDate, setEndDate] = useState<Date | null>(dateEnd);

  const buttonLabel = useMemo(() => t(computePreset(startDate, endDate)), [t, startDate, endDate]);

  useEffect(() => {
    if (startDate && endDate) {
      setDateEnd(endDate);
      setDateStart(startDate);
    }
  }, [startDate, endDate, setDateEnd, setDateStart]);

  const createPresetEventHandler = (presetValue: DateRangePreset): (() => void) => {
    // return handler for the onClick event
    return (): void => {
      const today = startOfDay(new Date());
      if (presetValue === DateRangePreset.ThisYear) {
        setStartDate(startOfYear(today));
        setEndDate(endOfYear(today));
      } else if (presetValue === DateRangePreset.LastYear) {
        const lastYear = add(today, { years: -1 });
        setStartDate(startOfYear(lastYear));
        setEndDate(endOfYear(lastYear));
      } else if (presetValue === DateRangePreset.ThisQuarter) {
        setStartDate(startOfQuarter(today));
        setEndDate(endOfQuarter(today));
      } else if (presetValue === DateRangePreset.LastQuarter) {
        const dateInPreviousQuarter = add(startOfQuarter(today), { days: -1 });
        setStartDate(startOfQuarter(dateInPreviousQuarter));
        setEndDate(endOfQuarter(dateInPreviousQuarter));
      } else if (presetValue === DateRangePreset.LastFiveYear) {
        // today minus five years?
        setStartDate(add(today, { years: -5 }));
        setEndDate(today);
      }
    };
  };

  return (
    <div className="d-flex flex-column flex-md-row align-items-center">
      <Form.Label className="me-2 ">Range</Form.Label>
      <DateRangePicker
        autoComplete="off"
        endDate={endDate}
        onEndDateChange={setEndDate}
        onStartDateChange={setStartDate}
        placeholderText={dateFormat.toUpperCase()}
        required
        startDate={startDate}
      />
      <Dropdown as={ButtonGroup} className="ms-2">
        <Dropdown.Toggle size="sm" variant="outline-primary">
          {buttonLabel}
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Item onClick={createPresetEventHandler(DateRangePreset.ThisYear)}>
            {t('animalEventsDateRange|thisYear')}
          </Dropdown.Item>
          <Dropdown.Item onClick={createPresetEventHandler(DateRangePreset.LastYear)}>
            {t('animalEventsDateRange|lastYear')}
          </Dropdown.Item>
          <Dropdown.Item onClick={createPresetEventHandler(DateRangePreset.ThisQuarter)}>
            {t('animalEventsDateRange|thisQuarter')}
          </Dropdown.Item>
          <Dropdown.Item onClick={createPresetEventHandler(DateRangePreset.LastQuarter)}>
            {t('animalEventsDateRange|lastQuarter')}
          </Dropdown.Item>
          <Dropdown.Item onClick={createPresetEventHandler(DateRangePreset.LastFiveYear)}>
            {t('animalEventsDateRange|lastFiveYears')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default AnimalEventsDateRangePicker;
