// Copyright © 2023 CATTLEytics Inc.

import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { resolve } from 'inversify-react';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import EditInPlace from '../../common/components/EditInPlace';
import Page from '../../common/components/Page';
import Spinner from '../../common/components/Spinner';
import Toast, { ToastConfig } from '../../common/components/Toast';
import { api } from '../../common/utilities/api';
import type Logger from '../../logger/logger';
import {
  ApiResourceV1,
  BreedingPlan,
  BreedingProgram,
  BreedingProgramType,
  HttpMethod,
} from '../../shared';
import BreedingPlanService from '../services/breedingPlanService';
import BreedingProgramService from '../services/breedingProgramService';
import BreedingProgramTypeService from '../services/breedingProgramTypeService';
import BreedingProgramCreateModal from './BreedingProgramCreateModal';
import BreedingTimeline from './BreedingTimeline';

/**
 * Define the route parameters to be used in this component.
 */
interface RouteParams {
  id: string;
}

/**
 * Defines the component's props as a combination of route parameters and
 *   translation props.
 */
type Props = RouteComponentProps<RouteParams> & WithTranslation;

/**
 * Describes the state of the @see BreedingPlanManage component.
 */
interface State {
  /**
   * The breeding plan we are managing.
   */
  breedingPlan: BreedingPlan;

  /**
   * The visible status of the breeding program create modal.
   */
  breedingProgramCreateModalVisible: boolean;

  /**
   * All breeding programs types.
   */
  breedingProgramTypes: BreedingProgramType[];

  /**
   * The breeding programs for this plan.
   */
  breedingPrograms: BreedingProgram[];

  /**
   * Whether or not this component is in a busy state.
   */
  busy: boolean;

  /**
   * An error message to display.
   */
  error?: string;

  /**
   * The display configuration for the Toast component.
   */
  toast: ToastConfig;
}

/**
 * Breeding plan manage root component.
 */
class BreedingPlanManage extends React.Component<Props, State> {
  /**
   * Creates an instance of BreedingPlanManage.
   */
  constructor(props: Props) {
    super(props);

    this.state = {
      breedingPlan: {
        id: 0,
        name: '',
        description: '',
        createdDate: new Date(),
        modifiedDate: new Date(),
      },
      breedingPrograms: [],
      breedingProgramTypes: [],
      breedingProgramCreateModalVisible: false,
      busy: false,
      error: undefined,
      toast: { visible: false },
    };
  }

  @resolve(TYPES.logger)
  private logger!: Logger;

  /**
   * Service used for accessing breeding plan data.
   */
  @resolve(TYPES.breedingPlanService)
  private breedingPlanService!: BreedingPlanService;

  /**
   * Service used for accessing breeding program data.
   */
  @resolve(TYPES.breedingProgramService)
  private breedingProgramService!: BreedingProgramService;

  /**
   * Service used for accessing breeding program type data.
   */
  @resolve(TYPES.breedingProgramTypeService)
  private breedingProgramTypeService!: BreedingProgramTypeService;

  /**
   * Fetches the breeding plan we are going to manage from the API when the
   *  component has mounted.
   * @returns {Promise<void>}
   */
  async componentDidMount(): Promise<void> {
    const planId = parseInt(this.props.match.params.id);

    this.setState({ busy: true });
    await Promise.all([
      this.getBreedingPlan(planId),
      this.getBreedingPrograms(planId),
      this.getBreedingProgramTypes(),
    ]);
    this.setState({ busy: false });
  }

  /**
   * @description Makes a call to breeding plan service to get a breeding plan
   *   and handles any errors that may occur.
   * @param {number} planId
   * @return {Promise<void>}
   * @memberOf BreedingPlanManage
   */
  getBreedingPlan = async (planId: number): Promise<void> => {
    this.logger.debug('Fetching breeding plan: ', planId);
    try {
      const breedingPlan = await api<BreedingPlan>(
        HttpMethod.Get,
        `${ApiResourceV1.BreedingPlans}/${planId}`,
      );

      if (!breedingPlan) {
        throw Error('Breeding plan could not be found');
      }
      this.setState({
        breedingPlan: breedingPlan,
      });
    } catch (error) {
      this.logger.error(`Error loading breeding plan: ${error}`);
      this.setState({
        error: this.props.t('breedingPlanManage|planLoadError'),
      });
    }
  };

  /**
   * @description Makes a call to breeding plan type service to get all
   *   breeding plan types and handles any errors that may occur.
   * @return {Promise<void>}
   * @memberOf BreedingPlanManage
   */
  getBreedingProgramTypes = async (): Promise<void> => {
    this.logger.debug('Fetching breeding program types.');
    try {
      const breedingProgramTypes = await api<BreedingProgramType[]>(
        HttpMethod.Get,
        ApiResourceV1.BreedingProgramTypes,
      );

      this.setState({
        breedingProgramTypes: breedingProgramTypes,
      });
    } catch (error) {
      this.logger.error(`Error loading breeding program types: ${error}`);
      this.setState({
        error: this.props.t('breedingPlanManage|programTypesLoadError'),
      });
    }
  };

  /**
   * Makes a call to breeding program service to get all breeding
   *   programs for the specified plan and handles any errors that may occur.
   * @param {number} planId
   * @returns {Promise<void>}
   */
  getBreedingPrograms = async (planId: number): Promise<void> => {
    this.logger.debug('Fetching breeding programs for plan: ', planId);
    try {
      const breedingPrograms = await api<BreedingProgram[]>(
        HttpMethod.Get,
        ApiResourceV1.BreedingPrograms,
        {
          params: { breedingPlanId: planId.toString() },
        },
      );

      this.setState({
        breedingPrograms: breedingPrograms.sort((a: BreedingProgram, b: BreedingProgram) => {
          if (a.dim > b.dim) return 1;
          if (a.dim < b.dim) return -1;
          return 0;
        }),
      });
    } catch (error) {
      this.logger.error(`Error loading breeding programs: ${error}`);
      this.setState({
        error: this.props.t('breedingPlanManage|programLoadError'),
      });
    }
  };

  /**
   * Renders the component.
   * @returns {JSX.Element}
   */
  render(): JSX.Element {
    const plan = this.state.breedingPlan;

    if (this.state.busy) {
      return <Spinner />;
    }

    if (this.state.error) {
      return <AlertError message={this.state.error} />;
    }

    const timeline = (
      <section>
        <h3>{this.props.t('Timeline')}</h3>
        <BreedingTimeline
          breedingProgramTypes={this.state.breedingProgramTypes}
          breedingPrograms={this.state.breedingPrograms}
          onAddProgram={(): void => this.setState({ breedingProgramCreateModalVisible: true })}
          onDeleteProgram={this.handleDeleteProgram}
          onDropProgram={this.handleDropProgram}
          onDuplicateProgram={this.handleDuplicateProgram}
          onSaveProgram={this.handleSaveProgram}
        />
      </section>
    );

    return (
      <Page title={this.props.t('Manage Breeding Plan')}>
        <p>
          <strong>{this.props.t('Plan Name')}:</strong>{' '}
          <EditInPlace
            name={'name'}
            onSave={(key, value): Promise<void> => this.patchBreedingPlan(plan.id, key, value)}
            type="text"
            value={plan.name}
          >
            {plan.name}
          </EditInPlace>
        </p>
        <p>
          <strong>{this.props.t('Plan Description')}:</strong> <span>{plan.description}</span>
        </p>

        {timeline}
        <Toast
          onClose={(): void => this.setState({ toast: { visible: false } })}
          show={this.state.toast.visible}
        >
          {this.state.toast.message}
        </Toast>
        {this.state.breedingProgramCreateModalVisible ? (
          <BreedingProgramCreateModal
            breedingProgramTypes={this.state.breedingProgramTypes}
            nextDim={
              this.state.breedingPrograms.length > 0
                ? this.state.breedingPrograms[this.state.breedingPrograms.length - 1].dim +
                  this.state.breedingPrograms[this.state.breedingPrograms.length - 1].duration +
                  1
                : 1
            }
            onClose={(): void => this.setState({ breedingProgramCreateModalVisible: false })}
            onSaveProgram={this.handleSaveProgram}
          />
        ) : null}
      </Page>
    );
  }

  /**
   * @description Handles the patching of a breeding plan.
   * @param {number} planId
   * @param {string} key
   * @param {string | number} value
   * @returns {Promise<void>}
   * @memberOf BreedingPlanManage
   */
  patchBreedingPlan = async (planId: number, key: string, value: string): Promise<void> => {
    try {
      await this.breedingPlanService.patch(planId, {
        [key]: value,
      });
      const patchedPlan = { ...this.state.breedingPlan };
      patchedPlan[key] = value;
      this.setState({
        breedingPlan: patchedPlan,
      });
    } catch (error) {
      this.logger.error(`Error patching breeding plan: ${error}`);
      this.setState({
        error: this.props.t('breedingPlanManage|planSaveError'),
      });
    }
  };

  /**
   * Handles making the API call to save a breeding program.
   * @param {BreedingProgram} breedingProgram
   * @returns {Promise<void>}
   */
  handleSaveProgram = async (breedingProgram: BreedingProgram): Promise<void> => {
    const planId = parseInt(this.props.match.params.id);
    const program = { ...breedingProgram };

    let breedingPrograms: BreedingProgram[];
    if (breedingProgram.id > 0) {
      this.logger.debug(`Editing a breeding program.`, breedingProgram);
      await this.breedingProgramService.patch(breedingProgram.id, program);

      const programIds = this.state.breedingPrograms.map((program) => program.id);
      const planId = parseInt(this.props.match.params.id);

      await this.breedingPlanService.reorderBreedingPrograms(planId, programIds);

      await this.getBreedingPrograms(planId);
      this.setState({
        toast: { visible: true, message: this.props.t('Breeding program edited successfully.') },
      });
    } else {
      this.logger.debug(`Creating a breeding program.`);
      program.breedingPlanId = planId;
      program.breedingPlan = this.state.breedingPlan;

      const newBreedingProgram = await this.breedingProgramService.post(program);
      if (!newBreedingProgram) {
        throw Error('Could not create breeding program');
      }
      newBreedingProgram.breedingProgramType = program.breedingProgramType;

      breedingPrograms = [...this.state.breedingPrograms, newBreedingProgram];
      this.setState({
        toast: { visible: true, message: this.props.t('breedingPlanManage|planSaveSuccessful') },
        breedingPrograms: breedingPrograms,
      });
    }
  };

  /**
   * @description Handles duplicating a breeding program.
   * @param {BreedingProgram} breedingProgram
   * @returns {void}
   * @memberOf BreedingPlanManage
   */
  handleDuplicateProgram = async (breedingProgram: BreedingProgram): Promise<void> => {
    this.logger.debug(`Duplicating breeding program with ID ${breedingProgram.id}`);

    const program = { ...breedingProgram };
    program.id = 0;

    const newBreedingProgram = await this.breedingProgramService.post(program);
    if (!newBreedingProgram) {
      throw Error('Could not create new breeding program');
    }
    const planId = parseInt(this.props.match.params.id);

    const breedingPrograms = [...this.state.breedingPrograms, newBreedingProgram];
    const programIds = breedingPrograms.map((program) => program.id);
    await this.breedingPlanService.reorderBreedingPrograms(planId, programIds);

    await this.getBreedingPrograms(planId);

    this.setState({
      toast: { visible: true, message: this.props.t('Breeding program duplicated successfully.') },
    });
  };

  /**
   * @description Handles the deletion of a breeding program.
   * @param {number} programId ID of the breeding plan to delete.
   * @returns {Promise<void>}
   * @memberOf BreedingPlanManage
   */
  handleDeleteProgram = async (programId: number): Promise<void> => {
    this.logger.debug(`Delete the breeding program with ID: ${programId}`);
    await this.breedingProgramService.delete(programId);

    this.setState({
      toast: { visible: true, message: this.props.t('breedingPlanManage|programDeleteSuccessful') },
      breedingPrograms: this.state.breedingPrograms.filter((program) => program.id !== programId),
    });
  };

  /**
   * @description Updated the program order based on the drop event.
   * @param {DragEndEvent} event
   * @return {void}
   * @memberOf BreedingPlanManage
   */
  handleDropProgram = async (event: DragEndEvent): Promise<void> => {
    const { active, over } = event;
    if (!over) {
      return;
    }
    if (active.id !== over.id) {
      const oldIndex = this.state.breedingPrograms.findIndex(
        (program) => String(program.id) === active.id,
      );
      const newIndex = this.state.breedingPrograms.findIndex(
        (program) => String(program.id) === over.id,
      );
      const items = arrayMove(this.state.breedingPrograms, oldIndex, newIndex);
      this.setState({ breedingPrograms: items });

      const programIds = this.state.breedingPrograms.map((program) => program.id);

      const planId = parseInt(this.props.match.params.id);

      await this.breedingPlanService.reorderBreedingPrograms(planId, programIds);

      await this.getBreedingPrograms(planId);
    }
  };

  /**
   * @description Recalculate the start date (DIM) for each program based on a
   *   program's duration.
   * @deprecated Most likely don't need this unless making the backend call
   *   takes too long.
   * @return {void}
   * @memberOf BreedingPlanManage
   */
  recalculateProgramDims = (): void => {
    let nextDim = 0;
    const items: BreedingProgram[] = [];

    // iterate over each program, update the DIM and calculate the next DIM
    this.state.breedingPrograms.forEach((program) => {
      const newProgram = { ...program };
      newProgram.dim = nextDim;
      items.push(newProgram);
      nextDim += newProgram.duration;
    });

    this.setState({ breedingPrograms: items });
  };
}

export default withTranslation()(BreedingPlanManage);
