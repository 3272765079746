// Copyright © 2023 CATTLEytics Inc.

/**
 * For sort direction.
 */
export enum Sort {
  /**
   * Sort ascending.
   */
  Ascending = 'ASC',

  /**
   * Sort descending.
   */
  Descending = 'DESC',
}
