// Copyright © 2023 CATTLEytics Inc.
import { createElement, ReactElement, useEffect, useMemo, useState } from 'react';
import { Overlay, OverlayProps, Popover, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { IconChevronLeft, IconChevronRight } from '../../utilities';
import Button from '../Button';
import { defaultColor } from './constants';
import { useTimeline } from './TimelineProvider';
import { DateEntity, EntityBase } from './types';

export type TimelineNodeTooltipProps<Entity extends EntityBase> = Omit<
  OverlayProps,
  'children' | 'show'
> & {
  entityItems: DateEntity<Entity>[];
  selectedEntityItem: DateEntity<Entity> | null | undefined;
};

export function TimelineNodeTooltip<Entity extends EntityBase>({
  entityItems,
  selectedEntityItem,
  ...props
}: TimelineNodeTooltipProps<Entity>): JSX.Element {
  const { t } = useTranslation();
  const { slots } = useTimeline();
  const [displayedEntity, setDisplayedEntity] = useState<typeof selectedEntityItem>();
  const entity = useMemo(() => displayedEntity?.entity, [displayedEntity]);

  const color = useMemo(
    () => (displayedEntity && displayedEntity?.color) ?? defaultColor,
    [displayedEntity],
  );

  const show = useMemo(
    () => !!(selectedEntityItem && entityItems.includes(selectedEntityItem)),
    [selectedEntityItem, entityItems],
  );

  const selectedIndex = useMemo(
    () => entityItems.findIndex(({ entity: { id } }) => id === displayedEntity?.entity.id) + 1,
    [displayedEntity, entityItems],
  );

  useEffect(() => {
    if (selectedEntityItem) {
      setDisplayedEntity(selectedEntityItem);
    }
  }, [selectedEntityItem]);

  return (
    <Overlay placement="right-start" {...props} show={show} transition={false}>
      {(props): ReactElement => (
        <Popover
          {...props}
          className="timeline-node-tooltip"
          style={{ ...props.style, borderRadius: 4, minWidth: 200 }}
        >
          <Popover.Header
            style={{
              backgroundColor: 'var(--bs-body-bg)',
              borderTopLeftRadius: 3,
              borderTopRightRadius: 3,
              fontSize: '0.75rem',
            }}
          >
            <Stack className="d-flex flex-column align-items-center" direction="vertical">
              {entityItems.length > 1 && (
                <Stack className="justify-content-center" direction="horizontal">
                  <Button
                    className="p-0 me-1"
                    disabled={selectedIndex === 1}
                    onClick={(): void => setDisplayedEntity(entityItems[selectedIndex - 2])}
                    style={{ backgroundColor: 'transparent', border: 'none' }}
                  >
                    <IconChevronLeft style={{ width: 24, height: 24 }} />
                  </Button>

                  {t(`{{current}} of {{total}}`, {
                    total: entityItems.length,
                    current: selectedIndex,
                  })}
                  <Button
                    className="p-0 ms-1"
                    disabled={selectedIndex === entityItems.length}
                    onClick={(): void => setDisplayedEntity(entityItems[selectedIndex])}
                    style={{ backgroundColor: 'transparent', border: 'none' }}
                  >
                    <IconChevronRight style={{ width: 24, height: 24 }} />
                  </Button>
                </Stack>
              )}
              <h6 className="m-auto " style={{ textTransform: 'none', color }}>
                {displayedEntity?.label}
              </h6>
            </Stack>
          </Popover.Header>
          <Popover.Body>
            {slots?.tooltipContent && entity && createElement(slots.tooltipContent, { entity })}
          </Popover.Body>
        </Popover>
      )}
    </Overlay>
  );
}
