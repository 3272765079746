// Copyright © 2023 CATTLEytics Inc.

import 'reflect-metadata';

import { Container } from 'inversify';

import BreedingPlanService from './app/breeding-plans/services/breedingPlanService';
import BreedingProgramService from './app/breeding-plans/services/breedingProgramService';
import BreedingProgramTypeService from './app/breeding-plans/services/breedingProgramTypeService';
import ActiveTreatmentService from './app/common/services/activeTreatmentService';
import AnimalColorService from './app/common/services/animalColorService';
import AnimalEventService from './app/common/services/animalEventService';
import AnimalEventTypeService from './app/common/services/animalEventTypeService';
import AnimalHealthCheckResultService from './app/common/services/animalHealthCheckResultService';
import AnimalHealthCheckService from './app/common/services/animalHealthCheckService';
import AnimalReferenceService from './app/common/services/animalReferenceService';
import AnimalReferenceTypeService from './app/common/services/animalReferenceTypeService';
import AnimalReproductionService from './app/common/services/animalReproductionService';
import AnimalService from './app/common/services/animalService';
import AnimalTestDayService from './app/common/services/animalTestDayService';
import Api2Service from './app/common/services/api2Service';
import AuthService from './app/common/services/authService';
import BirthStatusService from './app/common/services/birthStatusService';
import BreedService from './app/common/services/breedService';
import CqlQueryService from './app/common/services/cqlService';
import DairyLogService from './app/common/services/dairyLogService';
import DiagnosisRegimeDetailService from './app/common/services/diagnosisRegimeDetailService';
import DiagnosisRegimeService from './app/common/services/diagnosisRegimeService';
import DiagnosisService from './app/common/services/diagnosisService';
import FileService from './app/common/services/fileService';
import GenderService from './app/common/services/genderService';
import JobService from './app/common/services/jobService';
import LayoutItemService from './app/common/services/layoutItemService';
import LayoutService from './app/common/services/layoutService';
import NameService from './app/common/services/nameService';
import NoteAttachmentService from './app/common/services/noteAttachmentService';
import NoteService from './app/common/services/noteService';
import NotificationService from './app/common/services/notificationService';
import OrganizationService from './app/common/services/organizationService';
import PenService from './app/common/services/penService';
import ProductAdministrationService from './app/common/services/productAdministrationService';
import ProductClassService from './app/common/services/productClassService';
import ProductService from './app/common/services/productService';
import ProductTypeService from './app/common/services/productTypeService';
import ReproStatusService from './app/common/services/reproStatusService';
import RoleService from './app/common/services/roleService';
import SettingService from './app/common/services/settingService';
import SireService from './app/common/services/sireService';
import SiteService from './app/common/services/siteService';
import TaskService from './app/common/services/taskService';
import UserService from './app/common/services/userService';
import ImportServiceInterface from './app/import/interfaces/importService';
import ImportService from './app/import/services/importService';
import Logger from './app/logger/logger';
import { TYPES } from './types';

const container = new Container();

container
  .bind<ActiveTreatmentService>(TYPES.activeTreatmentService)
  .to(ActiveTreatmentService)
  .inSingletonScope();
container.bind<AnimalService>(TYPES.animalService).to(AnimalService).inSingletonScope();
container
  .bind<AnimalColorService>(TYPES.animalColorService)
  .to(AnimalColorService)
  .inSingletonScope();
container
  .bind<AnimalEventService>(TYPES.animalEventService)
  .to(AnimalEventService)
  .inSingletonScope();
container
  .bind<AnimalEventTypeService>(TYPES.animalEventTypeService)
  .to(AnimalEventTypeService)
  .inSingletonScope();
container
  .bind<AnimalHealthCheckService>(TYPES.animalHealthCheckService)
  .to(AnimalHealthCheckService)
  .inSingletonScope();
container
  .bind<AnimalHealthCheckResultService>(TYPES.animalHealthCheckResultService)
  .to(AnimalHealthCheckResultService)
  .inSingletonScope();
container
  .bind<AnimalReferenceTypeService>(TYPES.animalReferenceTypeService)
  .to(AnimalReferenceTypeService)
  .inSingletonScope();
container
  .bind<AnimalReproductionService>(TYPES.animalReproductionService)
  .to(AnimalReproductionService)
  .inSingletonScope();
container
  .bind<AnimalTestDayService>(TYPES.animalTestDayService)
  .to(AnimalTestDayService)
  .inSingletonScope();
container.bind<Api2Service>(TYPES.api2Service).to(Api2Service).inSingletonScope();
container.bind<AuthService>(TYPES.authService).to(AuthService).inSingletonScope();
container
  .bind<BirthStatusService>(TYPES.birthStatusService)
  .to(BirthStatusService)
  .inSingletonScope();
container
  .bind<BreedingPlanService>(TYPES.breedingPlanService)
  .to(BreedingPlanService)
  .inSingletonScope();
container
  .bind<BreedingProgramService>(TYPES.breedingProgramService)
  .to(BreedingProgramService)
  .inSingletonScope();
container
  .bind<BreedingProgramTypeService>(TYPES.breedingProgramTypeService)
  .to(BreedingProgramTypeService)
  .inSingletonScope();
container.bind<BreedService>(TYPES.breedService).to(BreedService).inSingletonScope();
container.bind<CqlQueryService>(TYPES.cqlQueryService).to(CqlQueryService).inSingletonScope();
container
  .bind<AnimalReferenceService>(TYPES.animalReferenceService)
  .to(AnimalReferenceService)
  .inSingletonScope();
container.bind<DairyLogService>(TYPES.dairyLogService).to(DairyLogService).inSingletonScope();
container.bind<DiagnosisService>(TYPES.diagnosisService).to(DiagnosisService).inSingletonScope();
container
  .bind<DiagnosisRegimeService>(TYPES.diagnosisRegimeService)
  .to(DiagnosisRegimeService)
  .inSingletonScope();
container
  .bind<DiagnosisRegimeDetailService>(TYPES.diagnosisRegimeDetailService)
  .to(DiagnosisRegimeDetailService)
  .inSingletonScope();
container.bind<FileService>(TYPES.fileService).to(FileService).inSingletonScope();
container.bind<GenderService>(TYPES.genderService).to(GenderService).inSingletonScope();
container.bind<ImportServiceInterface>(TYPES.importService).to(ImportService).inSingletonScope();
container.bind<JobService>(TYPES.jobService).to(JobService).inSingletonScope();
container.bind<LayoutService>(TYPES.layoutService).to(LayoutService).inSingletonScope();
container.bind<LayoutItemService>(TYPES.layoutItemService).to(LayoutItemService).inSingletonScope();
container.bind<Logger>(TYPES.logger).toConstantValue(console);
container.bind<NameService>(TYPES.nameService).to(NameService).inSingletonScope();
container
  .bind<NoteAttachmentService>(TYPES.noteAttachmentService)
  .to(NoteAttachmentService)
  .inSingletonScope();
container.bind<NoteService>(TYPES.noteService).to(NoteService).inSingletonScope();
container
  .bind<NotificationService>(TYPES.notificationService)
  .to(NotificationService)
  .inSingletonScope();
container
  .bind<OrganizationService>(TYPES.organizationService)
  .to(OrganizationService)
  .inSingletonScope();
container.bind<PenService>(TYPES.penService).to(PenService).inSingletonScope();
container.bind<ProductService>(TYPES.productService).to(ProductService).inSingletonScope();
container
  .bind<ProductAdministrationService>(TYPES.productAdministrationService)
  .to(ProductAdministrationService)
  .inSingletonScope();
container
  .bind<ProductClassService>(TYPES.productClassService)
  .to(ProductClassService)
  .inSingletonScope();
container
  .bind<ProductTypeService>(TYPES.productTypeService)
  .to(ProductTypeService)
  .inSingletonScope();
container
  .bind<ReproStatusService>(TYPES.reproStatusService)
  .to(ReproStatusService)
  .inSingletonScope();
container.bind<RoleService>(TYPES.roleService).to(RoleService).inSingletonScope();
container.bind<SireService>(TYPES.sireService).to(SireService).inSingletonScope();
container.bind<SiteService>(TYPES.siteService).to(SiteService).inSingletonScope();
container.bind<SettingService>(TYPES.settingService).to(SettingService).inSingletonScope();
container.bind<TaskService>(TYPES.taskService).to(TaskService).inSingletonScope();
container.bind<UserService>(TYPES.userService).to(UserService).inSingletonScope();

export default container;
