// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useState } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import Button, { ButtonVariant } from '../../common/components/Button';
import ConfirmModal from '../../common/components/ConfirmModal';
import DataTable, { DataTableHeader, DataTableRow } from '../../common/components/DataTable';
import DateTime from '../../common/components/DateTime';
import { Sort } from '../../common/enums';
import AnimalReferenceService from '../../common/services/animalReferenceService';
import { IconCreate, IconDelete, IconEdit } from '../../common/utilities';
import { QueryKey } from '../../shared';
import AnimalReferenceModal from './AnimalReferenceModal';

interface Props {
  animalId?: number;

  filters?: Record<string, string | undefined>;

  // Number of events to show.
  initialLimit?: number;

  /**
   * Number of events to skip.
   */
  initialOffset?: number;

  /**
   * Table sort direction.
   */
  initialSortDirection?: Sort;

  /**
   * Table sort field.
   */
  initialSortField?: string;

  /**
   * Pen ID to filter animalIdentifiers by
   */
  penId?: string;
}

/**
 * A filterable list of animal identifiers.
 */
const AnimalIdentifierTable = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const animalReferenceService = useInjection<AnimalReferenceService>(TYPES.animalReferenceService);

  const [limit, setLimit] = useState<number>(props.initialLimit ?? 25);
  const [offset, setOffset] = useState<number>(props.initialOffset ?? 0);
  const [sortField, setSortField] = useState<string>(props.initialSortField ?? 'id');
  const [sortDirection, setSortDirection] = useState<Sort>(
    props.initialSortDirection ?? Sort.Ascending,
  );

  const [editId, setEditId] = useState<number>();
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState<boolean>(false);
  const [deleteConfirmModalErrorMessage, setDeleteConfirmModalErrorMessage] = useState<string>();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();

  const cleanFilters: Record<string, string> = {};

  if (props.filters !== undefined) {
    Object.keys(props.filters).forEach((key) => {
      const propsFilters = props.filters as Record<string, string>;
      cleanFilters[key] = propsFilters[key] ?? '';
    });
  }

  if (props.penId) {
    cleanFilters['pen'] = props.penId;
  }
  if (props.animalId) {
    cleanFilters['animalId'] = String(props.animalId);
  }

  const query = useQuery(
    [QueryKey.AnimalReferences, limit, offset, cleanFilters, sortField, sortDirection],
    () =>
      animalReferenceService.list({
        ...cleanFilters,
        ...{
          limit: String(limit),
          offset: String(offset),
          sortField: sortField,
          sortDirection: String(sortDirection),
        },
      }),
    { keepPreviousData: true },
  );

  // for edit functionality
  const showEditModal = (id?: number): void => {
    setEditModalVisible(true);
    setEditId(id);
  };

  const showDeleteConfirmModal = (id: number): void => {
    setDeleteConfirmModalVisible(true);
    setDeleteId(id);
  };

  const queryClient = useQueryClient();

  const deleteItem = async (): Promise<void> => {
    if (!deleteId) {
      return;
    }
    setIsDeleting(true);
    try {
      await animalReferenceService.delete(deleteId);
      await queryClient.invalidateQueries(QueryKey.AnimalReferences);
      setIsDeleting(false);
      setDeleteConfirmModalVisible(false);
    } catch (err) {
      setDeleteConfirmModalErrorMessage(t('This item could not be deleted.'));
    }
    setIsDeleting(false);
  };

  if (query.isError) {
    return <AlertError message={t('animalReferencesTable|unexpectedRetrievalError')} />;
  }

  const headers: DataTableHeader[] = [
    {
      name: 'reference',
      label: t('ID'),
      infoTooltip: t('animalReferencesTable|animalIdentifierDescription'),
      classNameMobile: 'col-6',
    },
    {
      name: 'animalReferenceType',
      label: t('Type'),
      infoTooltip: t('animalReferencesTable|referenceTypeInfoTooltip'),
      classNameMobile: 'col-6',
    },
    {
      name: 'effectiveDateStart',
      label: t('Effective Start Date'),
      infoTooltip: t('Date when this identifier was or will be activated'),
      hideMobile: false,
    },
    {
      name: 'effectiveDateEnd',
      label: t('Effective End Date'),
      infoTooltip: t('Date when this identifier was or will be deactivated'),
      hideMobile: false,
    },
    {
      name: 'active',
      label: t('animalReferencesTable|activeLabel'),
      infoTooltip: t('Whether this identifier is active'),
      hideMobile: false,
    },
    { name: 'actions', sortable: false, label: 'Actions', hideMobile: true },
  ];

  const data: DataTableRow[] = !query.data
    ? []
    : query.data.map((ar) => ({
        id: String(ar.id),
        reference: ar.reference,
        animalReferenceType: ar.animalReferenceType?.name,
        effectiveDateStart: <DateTime date={ar.effectiveDateStart} hideTime={true} />,
        effectiveDateEnd: <DateTime date={ar.effectiveDateEnd} hideTime={true} />,
        active: ar.active ? 'Yes' : 'No',
        actions: (
          <Dropdown as={ButtonGroup} onClick={(e): void => e.stopPropagation()}>
            <Button
              onClick={(): void => showEditModal(ar.id)}
              size={'sm'}
              variant={'outline-primary'}
            >
              <IconEdit className={'me-1'} /> {t('Edit')}
            </Button>
            <Dropdown.Toggle size="sm" split variant={'outline-primary'} />
            <Dropdown.Menu align="end">
              <Dropdown.Item onClick={(): void => showEditModal(ar.id)}>
                <IconEdit className={'me-1'} /> {t('Edit')}
              </Dropdown.Item>
              <Dropdown.Item
                disabled={ar.animalReferenceType && ar.animalReferenceType.id === 1}
                onClick={(): void => showDeleteConfirmModal(ar.id)}
              >
                <IconDelete className={'me-1'} />
                {t('Delete')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      }));

  return (
    <>
      <DataTable
        className={'mt-3'}
        data={data}
        headers={headers}
        hidePaginationControls={true}
        isLoading={query.isLoading}
        isPreviousData={query.isPreviousData}
        limit={limit}
        messageNoData={t('animalReferencesTable|noReferencesFoundMessage')}
        offset={offset}
        onLimitChange={(newLimit): void => setLimit(newLimit)}
        onOffsetChange={(newOffset): void => setOffset(newOffset)}
        onRowClick={(row): void => {
          showEditModal(Number(row.id));
        }}
        onSortDirectionChange={(newSortDirection): void => setSortDirection(newSortDirection)}
        onSortFieldChange={(newSortField): void => setSortField(newSortField)}
        sortDirection={sortDirection}
        sortField={sortField}
      />
      <div className={'text-center'}>
        <Button
          icon={IconCreate}
          label={'Add Reference'}
          onClick={(): void => showEditModal(undefined)}
          type={'button'}
          variant={ButtonVariant.OutlinePrimary}
        />
      </div>
      {props.animalId && editModalVisible && (
        <AnimalReferenceModal
          animalId={props.animalId}
          editId={editId}
          onClose={(): void => setEditModalVisible(false)}
        />
      )}
      <ConfirmModal
        busy={isDeleting}
        cancelLabel={t('Cancel')}
        cancelOnClick={(): void => setDeleteConfirmModalVisible(false)}
        errorMessage={deleteConfirmModalErrorMessage}
        okLabel={t('Yes, delete this identifier')}
        okOnClick={deleteItem}
        okVariant={ButtonVariant.Danger}
        onClose={(): void => setDeleteConfirmModalVisible(false)}
        title={t('Delete identifier')}
        visible={deleteConfirmModalVisible}
      >
        {t('Are you sure you want to delete this identifier?')}
      </ConfirmModal>
    </>
  );
};

export default AnimalIdentifierTable;
