// Copyright © 2023 CATTLEytics Inc.

/**
 * Layout Widget Type Enumerations
 */
export enum LayoutWidgetType {
  Notes = 'notes',
  Tasks = 'tasks',
  Treatments = 'treatments',
}
