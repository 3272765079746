// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import { useState } from 'react';
import { Col, Placeholder, PlaceholderButton, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MdAddCircle } from 'react-icons/md';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';

import { TYPES } from '../../../types';
import Button from '../../common/components/Button';
import ButtonCardAction from '../../common/components/ButtonCardAction';
import ButtonEdit from '../../common/components/ButtonEdit';
import Page from '../../common/components/Page';
import TitlePlaceholder from '../../common/components/placeholders/TitlePlaceholder';
import { Sort } from '../../common/enums';
import PenService from '../../common/services/penService';
import NoteModal from '../../notes/components/NoteModal';
import NotesList from '../../notes/components/NotesList';
import PenModal from '../../pens/components/PenModal';
import { QueryKey } from '../../shared';
import AnimalTable from './AnimalTable';
import PenStats from './PenStats';

interface RouteParams {
  penId: string;
}

/**
 * Pen detail and animals component.
 */
const PenCard = (): JSX.Element => {
  const { t } = useTranslation();

  const { penId } = useParams<RouteParams>();

  const penService = useInjection<PenService>(TYPES.penService);
  const [noteModalVisible, setNoteModalVisible] = useState<boolean>(false);
  const [penModalVisible, setPenModalVisible] = useState<boolean>(false);

  const query = useQuery([QueryKey.Pens, penId], () => penService.get(parseInt(penId)));

  const pen = query.data;

  return (
    <Page
      breadcrumbs={[{ label: 'Pens', to: '/pens' }]}
      buttons={
        query.isLoading ? (
          <PlaceholderButton style={{ width: '8rem' }} xs={2} />
        ) : (
          <ButtonEdit
            onClick={(): void => {
              setPenModalVisible(true);
            }}
          />
        )
      }
      documentTitle={pen ? `${pen.name}${pen.description ? ` - ${pen.description}` : ''}` : ''}
      title={pen ? `${pen.name}${pen.description ? ` - ${pen.description}` : ''}` : ''}
      titleLoading={query.isLoading}
      titlePlaceholder={<TitlePlaceholder />}
    >
      <Row className={'mt-3'}>
        <Col lg={6} xs={12}>
          <h2>{t('Summary')}</h2>
          <PenStats penId={parseInt(penId)} />
        </Col>
        <Col lg={6} xs={12}>
          <h2 className={'d-flex justify-content-between'}>
            {t('Notes')}
            <ButtonCardAction
              icon={MdAddCircle}
              label={t('Create Note')}
              onClick={(): void => {
                setNoteModalVisible(true);
              }}
              variant={'outline-primary'}
            />
          </h2>
          <NotesList limit={3} penId={parseInt(penId)} />
          {noteModalVisible && (
            <NoteModal onClose={(): void => setNoteModalVisible(false)} pen={pen} />
          )}

          {pen ? (
            <Link className={'d-flex justify-content-end'} to={`/notes?pen=${pen.id}`}>
              <Button className={'text-center'} variant={'link'}>
                {t('More')}
              </Button>
            </Link>
          ) : (
            <Placeholder animation={'glow'} className={'d-flex justify-content-end'}>
              <Placeholder md={2} />
            </Placeholder>
          )}
        </Col>
      </Row>
      <h2 className={'mt-3'}>{t('Animals')}</h2>
      <AnimalTable initialSortDirection={Sort.Ascending} penId={penId} />
      {penModalVisible && (
        <PenModal editId={Number(penId)} onClose={(): void => setPenModalVisible(false)} />
      )}
    </Page>
  );
};

export default PenCard;
