// Copyright © 2023 CATTLEytics Inc.

import convert, { Unit } from 'convert-units';
import { format, parseISO } from 'date-fns';
import { useInjection } from 'inversify-react';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { TYPES } from '../../../../types';
import AnimalAutocomplete from '../../../animals/components/AnimalAutocomplete';
import AlertErrorForModal from '../../../common/components/AlertErrorForModal';
import Button from '../../../common/components/Button';
import { ButtonVariant } from '../../../common/components/Button';
import ButtonModal from '../../../common/components/ButtonModal';
import ReproStatusSelect from '../../../common/components/ReproStatusSelect';
import Required from '../../../common/components/Required';
import AnimalEvent from '../../../common/entities/animalEvent';
import { AnimalEventType as AnimalEventTypeEnum } from '../../../common/enums';
import AnimalEventService from '../../../common/services/animalEventService';
import SettingsContext from '../../../common/store/settings-context';
import { IconSave, placeholderExample } from '../../../common/utilities';
import { Animal } from '../../../shared';
import { QueryKey } from '../../../shared/enums';

/**
 * Component input properties.
 */
interface Props {
  /**
   * Default animal to select.
   */
  animal?: Animal;

  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * ID of event to edit in this modal.
   */
  eventId?: number;

  /**
   * Callback when form is cancelled.
   */
  onCancel?: () => void;

  /**
   * Callback when form has been submitted successfully..
   */
  onSubmitSuccess?: () => void;
}

interface Payload {
  [key: string]: any | undefined;
  animalEventTypeId: number;
  animalIds: number[];
  eventDateTime: string;
  eventDetail?: PayloadDetail[];
}

interface PayloadDetail {
  key: string;
  value: string;
}

/**
 * Form for moving animals between pens. For use in event modal component.
 */
const ArrivalForm = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const eventService = useInjection<AnimalEventService>(TYPES.animalEventService);

  const [validated, setValidated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  // fields
  const [eventDateTime, setEventDateTime] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [animal, setAnimal] = useState<Animal | undefined>(props.animal);
  const [weight, setWeight] = useState<string>('');
  const [temperature, setTemperature] = useState<string>('');
  const [hipHeight, setHipHeight] = useState<string>('');
  const [reproStatus, setReproStatus] = useState<string>('');

  const [showAddReproStatusModal, setShowAddReproStatusModal] = useState<boolean>(false);

  const settings = useContext(SettingsContext);

  const [units, setUnits] = useState<Record<string, string>>({
    temperatureUnit: settings?.temperatureUnit,
    weightUnit: settings?.weightUnit,
    hipHeightUnit: settings?.heightUnit,
  });

  const mutation = useMutation<AnimalEvent[] | undefined, unknown, Payload>(
    (payload) => {
      // if (props.eventId) {
      //   return eventService.patch(payload.id, payload);
      // } else {
      return eventService.post(payload);
      //}
    },
    {
      onSuccess: () => {
        // Invalidate and re-fetch
        queryClient.invalidateQueries(QueryKey.AnimalEvents);
      },
    },
  );

  useEffect(() => {
    const invalidElements = document.querySelectorAll('input.form-control:invalid');
    if (invalidElements.length > 0) {
      invalidElements[0].closest('.form-group')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [validated]);

  /**
   * Handles a unit select change event
   * @param event
   */
  const onUnitChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const newUnit = { ...units };
    const fromUnit = units[event.target.name];
    const toUnit = event.target.value;
    newUnit[event.target.name] = toUnit;
    setUnits(newUnit);

    const dataKey = event.target.name.replace('Unit', '');

    switch (dataKey) {
      case 'temperature':
        setTemperature(
          convert(parseFloat(temperature))
            .from(fromUnit as Unit)
            .to(toUnit as Unit)
            .toFixed(2),
        );
        break;
      case 'hipHeight':
        setHipHeight(
          convert(parseFloat(hipHeight))
            .from(fromUnit as Unit)
            .to(toUnit as Unit)
            .toFixed(2),
        );
        break;
      case 'weight':
        setWeight(
          convert(parseFloat(weight))
            .from(fromUnit as Unit)
            .to(toUnit as Unit)
            .toFixed(2),
        );
        break;
      default:
        throw Error('Invalid unit');
    }
  };

  const queryClient = useQueryClient();

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    let convertedWeight;
    if (weight) {
      convertedWeight =
        units['weightUnit'] !== 'kg'
          ? convert(parseFloat(weight))
              .from(units['weightUnit'] as Unit)
              .to('kg')
              .toFixed(2)
          : weight;
    }

    let convertedTemperature;
    if (temperature) {
      convertedTemperature =
        units['temperatureUnit'] !== 'C'
          ? convert(parseFloat(temperature))
              .from(units['temperatureUnit'] as Unit)
              .to('C')
              .toFixed(2)
          : temperature;
    }

    let convertedHipHeight = undefined;
    if (hipHeight) {
      convertedHipHeight =
        units['hipHeightUnit'] !== 'cm'
          ? convert(parseFloat(hipHeight))
              .from(units['hipHeightUnit'] as Unit)
              .to('cm')
              .toFixed(2)
          : hipHeight;
    }

    const payload: Payload = {
      animalIds: [(animal as Animal).id],
      animalEventTypeId: AnimalEventTypeEnum.Arrival,
      eventDateTime: parseISO(eventDateTime).toISOString(),
      temperature: convertedTemperature,
      hipHeight: convertedHipHeight,
      weight: convertedWeight,
      reproStatus: reproStatus,
    };

    setErrorMessage('');
    try {
      await mutation.mutateAsync(payload);
      if (props.onSubmitSuccess) {
        props.onSubmitSuccess();
      }
    } catch (err) {
      setErrorMessage(t('common|eventSaveError'));
    }
  };

  const fields = (
    <>
      <Form.Group className="form-group mb-3" controlId="formEventDate">
        <Form.Label>
          {t('Event Date')} <Required />
        </Form.Label>
        <Form.Control
          aria-label={t('Event Date')}
          onChange={(event): void => setEventDateTime(event.target.value)}
          required={true}
          type={'date'}
          value={eventDateTime}
        />
        <Form.Control.Feedback type={'invalid'}>This field is required.</Form.Control.Feedback>
      </Form.Group>

      {/* Selecting the first animal will set the pen from dropdown */}
      <Form.Group className="form-group mb-3" controlId="formAnimals">
        <Form.Label>
          Animal <Required />
        </Form.Label>
        <AnimalAutocomplete
          id={'animal'}
          multiple={false}
          onSelect={(selected): void => {
            if (!selected) {
              return setAnimal(undefined);
            } else if (Array.isArray(selected)) {
              return;
            }
            setAnimal(selected);
          }}
          required={true}
          selected={animal}
          validated={validated}
        />
      </Form.Group>
      <Form.Group className="form-group mb-3" controlId="formPenAutocomplete">
        <Form.Label>Weight</Form.Label>
        <InputGroup className={''}>
          <Form.Control
            aria-label="Weight"
            name={'weight'}
            onChange={(e): void => setWeight(e.target.value)}
            placeholder={placeholderExample(t, 568, 'lb', units.weightUnit)}
            style={{ width: '60%' }}
            type={'number'}
            value={weight}
          />
          <Form.Select
            aria-label="Weight Unit"
            className={'no-validate'}
            name={'weightUnit'}
            onChange={onUnitChange}
            value={units.weightUnit}
          >
            <option value="lb">lbs</option>
            <option value="kg">kg</option>
          </Form.Select>
        </InputGroup>
        <Form.Text className="text-muted">{t('arrivalForm|weightInstructions')}</Form.Text>
      </Form.Group>
      <Form.Group className={'form-group mb-3'}>
        <Form.Label>{t('Temperature')}</Form.Label>
        <InputGroup>
          <Form.Control
            aria-label="Temperature"
            name={'temperature'}
            onChange={(e): void => setTemperature(e.target.value)}
            placeholder={placeholderExample(t, 36.6, 'C', units.temperatureUnit)}
            style={{ width: '60%' }}
            type={'number'}
            value={temperature}
          />
          <Form.Select
            aria-label="Temperature unit"
            className={'no-validate'}
            name={'temperatureUnit'}
            onChange={onUnitChange}
            value={units.temperatureUnit}
          >
            <option value="C">&#8451;</option>
            <option value="F">&#8457;</option>
          </Form.Select>
        </InputGroup>
        <Form.Text className="text-muted">{t('arrivalForm|temperatureInstructions')}</Form.Text>
      </Form.Group>
      <Form.Group className={'form-group mb-3'}>
        <Form.Label>{t('Hip height')}</Form.Label>
        <InputGroup className="">
          <FormControl
            aria-label="Hip height"
            name={'hipHeight'}
            onChange={(e): void => setHipHeight(e.target.value)}
            placeholder={placeholderExample(t, 45, 'in', units.hipHeightUnit)}
            style={{ width: '60%' }}
            type={'number'}
            value={hipHeight}
          />
          <Form.Select
            aria-label="Hip height"
            className={'no-validate'}
            name={'hipHeightUnit'}
            onChange={onUnitChange}
            value={units.hipHeightUnit}
          >
            <option value="in">{t('in')}</option>
            <option value="ft">{t('ft')}</option>
            <option value="cm">{t('cm')}</option>
            <option value="m">{t('m')}</option>
          </Form.Select>
        </InputGroup>
        <Form.Text className="text-muted">{t('arrivalForm|hipHeightInstructions')}</Form.Text>
      </Form.Group>
      <Form.Group className="form-group mb-3" controlId="formReproductionStatus">
        <Form.Label>{t('Reproduction status')}</Form.Label>
        <ReproStatusSelect
          onChange={(e): void => setReproStatus(e.target.value)}
          onCloseAddModal={(): void => setShowAddReproStatusModal(false)}
          showAddModal={showAddReproStatusModal}
          value={reproStatus}
        />
        <Form.Text className="text-muted">
          <span>{t('Choose a reproduction status from the dropdown or ')}</span>
          <Button
            onClick={(): void => setShowAddReproStatusModal(true)}
            size={'sm'}
            variant={'link'}
          >
            {t('add status')}
          </Button>
        </Form.Text>
      </Form.Group>
    </>
  );

  return (
    <Form noValidate={true} onSubmit={onFormSubmit} validated={validated}>
      {fields}
      <AlertErrorForModal message={errorMessage} />
      <div className="modal-footer modal-footer-in-form">
        <ButtonModal
          disabled={mutation.isLoading}
          label={t('Cancel')}
          onClick={props.onCancel}
          type={'button'}
          variant={ButtonVariant.Secondary}
        />
        <ButtonModal
          busy={mutation.isLoading}
          disabled={mutation.isLoading}
          icon={IconSave}
          label={'Save'}
          type="submit"
        />
      </div>
    </Form>
  );
};

export default ArrivalForm;
