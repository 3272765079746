// Copyright © 2023 CATTLEytics Inc.

import { inject, injectable } from 'inversify';

import { TYPES } from '../../../types';
import type Logger from '../../logger/logger';
import FileEntity from '../entities/file';
import { SignedUrlType } from '../enums';
import { getEnv } from '../utilities';
import Api2Service from './api2Service';

/**
 * Service for uploading files.
 */
@injectable()
export default class FileService {
  private logger: Logger;
  private apiService: Api2Service;

  /**
   * Creates an instance of PenService.
   * @param logger Logger instance.
   * @param apiService API Service instance.
   */
  constructor(
    @inject(TYPES.logger) logger: Logger,
    @inject(TYPES.api2Service) apiService: Api2Service,
  ) {
    this.logger = logger;
    this.apiService = apiService;
  }

  public async uploadFile(
    file: File,
    signedUrlType?: SignedUrlType,
    siteId?: number,
  ): Promise<FileEntity> {
    const endpoint = getEnv('API_ENDPOINT2');

    const data = new FormData();
    data.append('file', file);

    const response = await fetch(
      `${endpoint}/v1/files?signedUrlType=${signedUrlType ?? SignedUrlType.Original}${
        siteId !== undefined ? `&siteId=${siteId}` : ''
      }`,
      {
        method: 'POST',
        headers: { 'X-Milkshake-Token': 'Milkshake' },
        body: data,
        credentials: 'include',
      },
    );

    return (await response.json()) as FileEntity;
  }

  public async get(key: string, signedUrlType?: SignedUrlType): Promise<FileEntity> {
    const result = await this.apiService.get<FileEntity>(`/v1/files/${key}`, {
      signedUrlType: signedUrlType ?? 'original',
    });
    if (!result) {
      throw new Error('No result');
    }
    return result;
  }

  public async delete(id: string): Promise<null> {
    return await this.apiService.delete(`/v1/files/${id}`);
  }
}
