// Copyright © 2024 CATTLEytics Inc.

import { groupBy } from 'lodash';
import { useCallback, useContext, useMemo, useState } from 'react';
import { Card, Form, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaCircle } from 'react-icons/fa';
import { useMutation, useQueryClient } from 'react-query';

import Button from '../common/components/Button';
import DataTable, { DataTableHeader, DataTableRow } from '../common/components/DataTable';
import DeleteConfirmModal from '../common/components/DeleteConfirmModal';
import FormFieldWrapper from '../common/components/FormFieldWrapper';
import { useDeleteConfirmModal } from '../common/hooks';
import AuthContext from '../common/store/auth-context';
import { useSettingsContext } from '../common/store/useSettingsContext';
import { api, IconCancel, IconDuplicate, isSiteAdminOrAbove } from '../common/utilities';
import { apiGetUser } from '../common/utilities/apis/user';
import { useSettingsScheduleContext } from '../settings/context/ScheduleContext';
import {
  ApiResourceV1,
  getShiftDateString,
  HttpMethod,
  QueryKey,
  User,
  UserSchedule,
} from '../shared';
import UserAutocomplete from '../users/components/UserAutocomplete';
import { HeadingLabel } from './components/HeadingLabel';
import { SwapButton } from './components/SwapButton';

interface Props {
  onNoSchedules?: () => void;
  schedules?: UserSchedule[];
}

export function ManageSchedulesForm({ onNoSchedules, schedules }: Props): JSX.Element {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const auth = useContext(AuthContext);
  const settings = useSettingsContext();

  const isAdmin = isSiteAdminOrAbove(auth);
  const isAdminEditable = useMemo(() => isAdmin, [isAdmin]);
  const [userSchedules, setUserSchedules] = useState<UserSchedule[]>(
    schedules?.length ? schedules : [],
  );
  const [showDeleteUserSchedule, setShowDeleteUserSchedule] = useState<UserSchedule>();
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);

  const shift = useMemo(() => userSchedules?.[0]?.shift, [userSchedules]);
  const {
    deleteConfirmModalOpen,
    deleteConfirmModalErrorMessage,
    openDeleteConfirmModal,
    closeDeleteConfirmModal,
  } = useDeleteConfirmModal();
  const { featureFlags } = useSettingsScheduleContext();

  const onDeleteClicked = useCallback(
    (userSchedule: UserSchedule) => {
      setShowDeleteUserSchedule(userSchedule);
      openDeleteConfirmModal();
    },
    [openDeleteConfirmModal, setShowDeleteUserSchedule],
  );

  const onDeleteSuccess = useCallback(() => {
    closeDeleteConfirmModal();
    setShowDeleteUserSchedule(undefined);
    setUserSchedules((prev) => prev.filter(({ id }) => id !== showDeleteUserSchedule?.id));
    if (userSchedules.length <= 1) {
      onNoSchedules?.();
    }
  }, [
    closeDeleteConfirmModal,
    setShowDeleteUserSchedule,
    setUserSchedules,
    onNoSchedules,
    userSchedules,
    showDeleteUserSchedule,
  ]);

  const { mutateAsync: saveAsync } = useMutation<
    UserSchedule[] | undefined,
    unknown,
    { users: User[] }
  >(
    ({ users }) => {
      const method = HttpMethod.Post;
      const path: string = ApiResourceV1.UserSchedulesBulk;

      return api(method, path, {
        body: {
          userIds: users.map((u) => u.id),
          shiftId: shift?.id,
          dates: [userSchedules[0].date],
        },
      });
    },
    {
      onSuccess: async (data) => {
        if (data?.length) {
          const schedule = data[0];
          const user = await apiGetUser(schedule.userId);
          setUserSchedules((prev) => [...prev, { ...schedule, user }]);
          queryClient.invalidateQueries(QueryKey.UserSchedules);
        }
      },
    },
  );

  const { isLoading: deleteIsLoading, mutateAsync: deleteAsync } = useMutation<
    UserSchedule | undefined
  >(
    () => {
      if (showDeleteUserSchedule?.id) {
        return api(
          HttpMethod.Delete,
          `${ApiResourceV1.UserSchedules}/${showDeleteUserSchedule.id}`,
        );
      }
      return Promise.reject();
    },
    {
      onSuccess: onDeleteSuccess,
    },
  );

  const onDelete = useCallback(() => deleteAsync(), [deleteAsync]);

  const { mutateAsync: swapSync } = useMutation<
    Partial<UserSchedule> | undefined,
    unknown,
    { allowSwap: boolean; id: number }
  >(
    ({ id, allowSwap }) => {
      if (id) {
        return api(HttpMethod.Patch, `${ApiResourceV1.UserSchedules}/${id}`, {
          body: { allowSwap },
        });
      }
      return Promise.reject();
    },
    {
      onSuccess: async (data, variables) => {
        if (data) {
          setUserSchedules((prev) =>
            prev.map((schedule) =>
              schedule.id === variables.id ? { ...schedule, allowSwap: data.allowSwap } : schedule,
            ),
          );
        }
        queryClient.invalidateQueries(QueryKey.UserSchedules);
      },
    },
  );

  const onSwapClicked = useCallback(
    (userSchedule: UserSchedule) => {
      const currentState = userSchedule.allowSwap ?? false;
      swapSync({
        allowSwap: !currentState,
        id: userSchedule.id,
      });
    },
    [swapSync],
  );

  const allSwapState = useMemo(() => {
    // if all are true, then allSwapState is true.
    return userSchedules.every((schedule) => schedule.allowSwap);
  }, [userSchedules]);

  const onSwapAllClicked = useCallback(
    (nextState: boolean) => {
      // turn on or off the swaps.
      if (nextState !== undefined) {
        for (const schedule of userSchedules) {
          if (nextState && schedule.user?.permissions.scheduleAllowSwapping === false) {
            // dont change swap status.
          } else {
            swapSync({
              allowSwap: nextState,
              id: schedule.id,
            });
          }
        }
      }
    },
    [swapSync, userSchedules],
  );

  const dateStr = useMemo(
    () => shift && getShiftDateString(shift, userSchedules, settings.timezone),
    [settings.timezone, shift, userSchedules],
  );

  const tableItems: DataTableRow[] = useMemo(() => {
    const userMap = groupBy(userSchedules, ({ userId }) => userId);

    return Object.values(userMap).reduce<DataTableRow[]>((prev, schedules) => {
      const isDup = schedules.length > 1;

      const items = schedules.reduce<DataTableRow[]>((schedulePrev, schedule) => {
        if (schedule.user) {
          const { firstName, lastName } = schedule.user;

          schedulePrev.push({
            name: (
              <>
                {`${firstName} ${lastName}`}
                {isDup ? <IconDuplicate className="me-4" /> : <></>}
              </>
            ),
            colClassName: isDup ? 'bg-warning bg-opacity-50' : '',
            buttons: (
              <Stack className="me-4" direction="horizontal" gap={4}>
                {isAdminEditable ? (
                  <SwapButton
                    featureFlagsAllowSwapping={featureFlags.allowSwapping}
                    onSwapClicked={onSwapClicked}
                    schedule={schedule}
                  />
                ) : (
                  <></>
                )}
                {isAdminEditable ? (
                  <Button
                    className="ms-auto"
                    icon={IconCancel}
                    label={t('Delete')}
                    onClick={(): void => onDeleteClicked(schedule)}
                    title={t('Delete')}
                    variant="danger"
                  />
                ) : (
                  <></>
                )}
              </Stack>
            ),
          });
        }

        return schedulePrev;
      }, []);

      return [...prev, ...items];
    }, []);
  }, [
    userSchedules,
    isAdminEditable,
    featureFlags.allowSwapping,
    t,
    onSwapClicked,
    onDeleteClicked,
  ]);

  const headers: DataTableHeader[] = [
    {
      name: 'name',
      label: t('manageSchedulesForm|grid|nameLabel'),
      className: 'text-nowrap',
      colClassName: 'text-nowrap flex-grow-1',
    },
    {
      name: 'buttons',
      label: t('manageSchedulesForm|grid|buttonsLabel'),
      className: 'text-end w-50',
      colClassName: 'text-end',
    },
  ];

  const data: DataTableRow[] = tableItems.slice(offset, offset + limit);

  if (!isAdmin) {
    // Users should not see this form - only site-admin and super-admin
    return <></>;
  }

  return (
    <>
      <Stack gap={2}>
        <div className="mb-2">
          <Stack>
            <div>{t('manageSchedulesForm|scheduleWorkLabel', {})}</div>
            <Stack className="justify-content-between" direction="horizontal">
              <HeadingLabel
                title={t('manageSchedulesForm|shiftNameLabel')}
                value={
                  <>
                    <FaCircle className={'me-1'} color={shift?.color} />
                    {shift?.name}
                  </>
                }
              />
              <HeadingLabel title={t('manageSchedulesForm|dateLabel')} value={dateStr} />
            </Stack>
          </Stack>
        </div>
        {isAdminEditable && (
          <FormFieldWrapper
            controlId="formUser"
            invalidFeedback={t('common|fieldRequiredFeedback')}
            label={t('manageSchedulesForm|addUserLabel')}
          >
            <UserAutocomplete
              id="userSelect"
              multiple={true}
              name={'userId'}
              onSelect={(selected): void => {
                if (selected && !Array.isArray(selected)) {
                  saveAsync({ users: [selected] });
                }
                if (selected && Array.isArray(selected)) {
                  saveAsync({ users: selected });
                }
              }}
            />
          </FormFieldWrapper>
        )}
        {isAdminEditable && featureFlags.allowSwapping && (
          <FormFieldWrapper
            controlId="formSwap"
            invalidFeedback={t('common|fieldRequiredFeedback')}
            label={t('manageSchedulesForm|swappableLabel')}
          >
            <Form.Check
              checked={allSwapState}
              label={t('manageSchedulesForm|enableLabel')}
              onChange={(e): void => onSwapAllClicked(e.currentTarget.checked)}
            />
          </FormFieldWrapper>
        )}
        <Card>
          <Card.Body>
            <DataTable
              data={data}
              headers={headers}
              limit={limit}
              offset={offset}
              onLimitChange={setLimit}
              onOffsetChange={setOffset}
            />
          </Card.Body>
        </Card>
      </Stack>
      <DeleteConfirmModal
        busy={deleteIsLoading}
        cancelOnClick={(): void => closeDeleteConfirmModal()}
        errorMessage={deleteConfirmModalErrorMessage}
        okLabel={t('Yes, remove this schedule')}
        okOnClick={onDelete}
        title={t('Remove this schedule')}
        value={t('schedule')}
        visible={deleteConfirmModalOpen}
      />
    </>
  );
}
