// Copyright © 2024 CATTLEytics Inc.

import 'chartjs-adapter-date-fns';

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  ScatterController,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useMemo } from 'react';
import { Chart } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { api } from '../../../common/utilities/api';
import { ModelEcmQuantity } from '../../../shared';
import { ReportLactationDetails } from '../../../shared';
import { ApiResourceV1 } from '../../../shared/enums/api';
import { HttpMethod } from '../../../shared/enums/http';
import { QueryKey } from '../../../shared/enums/queryKeys';

ChartJS.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  ScatterController,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
);

interface AnimalEcmData {
  breedId: number;
  lactationGroup: string;
  lactationNumber: number;
  testDateYear: string;
}

const IndividualEcmChart = ({
  lactOrder,
  animalId,
}: {
  animalId: number;
  lactOrder: number;
}): JSX.Element => {
  const { t } = useTranslation();

  // Query to get the prediction model
  const modelQuery = useQuery<Required<ModelEcmQuantity>[]>([QueryKey.ReportModelEcmQuantity], () =>
    api<Required<ModelEcmQuantity>[]>(HttpMethod.Get, ApiResourceV1.ReportModelEcmQuantity),
  );

  // Query to get the herd's individual ECM quantity data
  const individualEcmQuantityQuery = useQuery<ReportLactationDetails>(
    [QueryKey.ReportEcmQuantity, animalId],
    () =>
      api<ReportLactationDetails>(HttpMethod.Get, `${ApiResourceV1.ReportEcmQuantity}/${animalId}`),
  );

  const validatedData = useMemo<{
    chartData: ChartData<'line' | 'scatter'>;
    lactationNumber: number;
  } | null>(() => {
    if (!individualEcmQuantityQuery.data) {
      return null;
    }

    const lactationNumbers = individualEcmQuantityQuery.data.map(
      (item: ReportLactationDetails) => item.lactationNumber,
    );

    // New array to contain unique lactation numbers
    const uniqueLactationNumbers: number[] = Array.from(new Set(lactationNumbers));

    const sortedLactationNumbers = uniqueLactationNumbers.sort((a: number, b: number) => b - a);

    if (sortedLactationNumbers.length < lactOrder + 1) {
      return null;
    }

    const animalData: AnimalEcmData = individualEcmQuantityQuery.data.find(
      (entry: ReportLactationDetails) =>
        entry.lactationNumber === sortedLactationNumbers[lactOrder],
    );

    // Filter the data to only include records where lactation === the right lactation number
    const individualEcmQuantityFiltered =
      individualEcmQuantityQuery.data &&
      individualEcmQuantityQuery.data?.filter(
        (item: { lactationNumber: number }) => item.lactationNumber === animalData.lactationNumber,
      );

    const individualEcmQuantity: { data: { x: number; y: number }[] } = {
      data: individualEcmQuantityFiltered.map((row: ReportLactationDetails) => {
        return {
          x: row.dim,
          y: row.ecmQuantity,
        };
      }),
    };

    let testDateYear: number;

    if (animalData.testDateYear === new Date().getFullYear().toString()) {
      testDateYear = parseInt(animalData.testDateYear) - 1;
    } else {
      testDateYear = parseInt(animalData.testDateYear);
    }

    const modelQueryFiltered =
      modelQuery.data &&
      modelQuery.data?.find(
        (item) =>
          item.lactationGroup === animalData.lactationGroup &&
          item.breedId === animalData.breedId &&
          item.testDateYear === testDateYear,
      );

    const polynomialString = modelQueryFiltered ? modelQueryFiltered.equation : '';

    // Regular expression to match the coefficients without scientific notation
    const regex = /(-?\d*\.\d+)/g;

    // Extracting the coefficients from the string using the regular expression
    const matches = polynomialString.match(regex);

    const coefficients = matches ? matches.map((match) => Number(match)) : [];

    const calculateFunctionValues = (x: number): number => {
      return coefficients.reduce(
        (acc, coef, index) => acc + coef * Math.pow(x, coefficients.length - 1 - index),
        0,
      );
    };

    const chartData: ChartData<'line' | 'scatter', { x: number; y: number }[], number> = {
      // Generates an array of numbers from 1 to 305, representing DIM, to be used as labels for the x-axis.
      labels: Array.from({ length: 305 }, (_, i) => i + 1),
      datasets: [
        {
          label:
            t('individualEcmChart|lactationGroupLabel') +
            animalData.lactationGroup +
            t('individualEcmChart|yearLabel') +
            testDateYear,
          data: Array.from({ length: 305 }, (_, i) => ({
            x: i + 1,
            y: calculateFunctionValues(i),
          })),
          borderColor: 'rgb(255, 105, 180)',
          backgroundColor: 'transparent',
          fill: true,
          pointRadius: 0,
          pointHoverRadius: 5,
          type: 'line',
        },
        {
          label: t('individualEcmChart|individualECMQuantityLabel'),
          data: individualEcmQuantity.data,
          borderColor: 'rgb(255, 215, 0)',
          backgroundColor: 'rgb(255, 215, 0)',
          fill: true,
          pointRadius: 4,
          pointHoverRadius: 5,
          type: 'scatter',
        },
      ],
    };
    return { chartData, lactationNumber: animalData.lactationNumber };
  }, [individualEcmQuantityQuery.data, lactOrder, modelQuery.data, t]);

  const options: ChartOptions<'line' | 'scatter'> = {
    scales: {
      x: {
        type: 'linear',
        title: {
          display: true,
          text: t('individualEcmChart|xAxisLabel'),
        },
        stacked: false,
      },
      y: {
        type: 'linear',
        title: {
          display: true,
          text: t('individualEcmChart|yAxisLabel'),
        },
        stacked: false,
      },
    },
  };

  return (
    <>
      {validatedData?.chartData && (
        <h2>ECM Chart for Lactation Number : {validatedData.lactationNumber}</h2>
      )}
      {validatedData?.chartData && (
        <Chart data={validatedData?.chartData} options={options} type="scatter" />
      )}
    </>
  );
};
export default IndividualEcmChart;
