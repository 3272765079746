// Copyright © 2023 CATTLEytics Inc.

import { inject, injectable } from 'inversify';

import { TYPES } from '../../../types';
import type Logger from '../../logger/logger';
import { Pen } from '../../shared';
import PenInsight from '../entities/penInsight';
import { Region } from '../types';
import Api2Service from './api2Service';

const path = '/v1/pens';

/**
 * Service for managing animal pen data via REST API.
 */
@injectable()
export default class PenService {
  // Logger service
  private logger: Logger;

  // Service for accessing the HTTP API.
  private apiService: Api2Service;

  /**
   * Creates an instance of PenService.
   * @param logger Logger instance.
   * @param apiService API Service instance
   */
  constructor(
    @inject(TYPES.logger) logger: Logger,
    @inject(TYPES.api2Service) apiService: Api2Service,
  ) {
    this.logger = logger;
    this.apiService = apiService;
  }

  /**
   * Retrieve a list of Animal Pens
   * @param params Parameters to pass along to the API
   * @return Pen objects
   */
  async list(params?: Record<string, string>): Promise<Pen[]> {
    const result = await this.apiService.get<Pen[]>(path, params);
    if (!result) {
      return [];
    }

    const data = result as Pen[];
    data.forEach((item) => this.cast(item));
    return data;
  }

  async get(id: number, params?: Record<string, string>): Promise<Pen | undefined> {
    const result = await this.apiService.get<Pen>(`${path}/${id}`, params);
    if (!result) {
      return undefined;
    }

    const data = result as Pen;
    this.cast(data);
    return data;
  }

  async insights(id: number, params?: Record<string, string>): Promise<PenInsight | undefined> {
    const result = await this.apiService.get<PenInsight>(`${path}/${id}/insights`, params);
    if (!result) {
      return undefined;
    }

    return result;
  }

  async post(attributes: Record<string, string>): Promise<Pen | undefined> {
    const result = await this.apiService.post<Pen>(`${path}`, attributes);
    if (!result) {
      return undefined;
    }
    this.cast(result as Pen);
    return result;
  }

  async patch(
    id: number,
    attributes: Record<string, string | number | null | Region>,
  ): Promise<Pen | undefined> {
    // make sure record identifier is included
    attributes.id = id;

    if (attributes.mapRegion) {
      const region = attributes.mapRegion as Region;
      attributes.mapRegion = `${region.start.x},${region.start.y},${region.end.x},${region.end.y}`;
    }

    const result = await this.apiService.patch(`${path}/${id}`, attributes);
    if (!result) {
      return undefined;
    }

    const data = result as Pen;
    this.cast(data);
    return data;
  }

  async delete(id: number): Promise<null> {
    return await this.apiService.delete(`${path}/${id}`);
  }

  /**
   * Casts fields into javascript types.
   * @param item
   * @return {void}
   */
  cast(item: Pen): void {
    const data = item;
    if (!data) {
      return;
    }

    // convert region string to object
    if (data.mapRegion) {
      const points = (data.mapRegion as unknown as string).split(',');
      data.mapRegion = {
        start: {
          x: parseInt(points[0]),
          y: parseInt(points[1]),
        },
        end: {
          x: parseInt(points[2]),
          y: parseInt(points[3]),
        },
      };
    }
  }
}
