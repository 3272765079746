// Copyright © 2023 CATTLEytics Inc.

import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import ButtonCreate from '../../common/components/ButtonCreate';
import Page from '../../common/components/Page';
import { capitalizeWords } from '../../common/utilities';
import AnimalModal from './AnimalModal';
import AnimalTable from './AnimalTable';
import CalfTable from './CalfTable';

interface RouteParams {
  // Tab key to make active on render.
  tabKey: string;
}

/**
 * Animals page to show lists of animals.
 */
const AnimalsPage = (): JSX.Element => {
  const { t } = useTranslation();

  const { tabKey } = useParams<RouteParams>();
  const history = useHistory();

  const [animalModalVisible, setAnimalModalVisible] = useState<boolean>(false);

  return (
    <Page
      breadcrumbTitle={capitalizeWords(tabKey)}
      breadcrumbs={[{ label: 'Animals', to: '/animals/active' }]}
      buttons={
        <ButtonCreate label={t('New Animal')} onClick={(): void => setAnimalModalVisible(true)} />
      }
      title={t('Animals')}
    >
      <Tabs
        activeKey={tabKey}
        className="mb-3"
        id="animals-tabs"
        mountOnEnter={true}
        onSelect={(k): void => {
          if (k) {
            history.push(`/animals/${k}`);
          }
        }}
      >
        <Tab eventKey="cows" title={t('Cows')}>
          <AnimalTable filters={{ cowsOnly: '1' }} />
        </Tab>
        <Tab eventKey="heifers" title={t('Heifers')}>
          <AnimalTable filters={{ heifersOnly: '1' }} />
        </Tab>
        <Tab eventKey="calves" title={t('Calves')}>
          <CalfTable />
        </Tab>
        <Tab eventKey="active" title={t('animalsPage|activeTabTitle')}>
          <AnimalTable />
        </Tab>
        <Tab eventKey="dead" title={t('Dead')}>
          <AnimalTable isDead={true} />
        </Tab>
        <Tab eventKey="sold" title={t('Sold')}>
          <AnimalTable isSold={true} />
        </Tab>
      </Tabs>

      {animalModalVisible && <AnimalModal onClose={(): void => setAnimalModalVisible(false)} />}
    </Page>
  );
};

export default AnimalsPage;
