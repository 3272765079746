// Copyright © 2023 CATTLEytics Inc.
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'usehooks-ts';

import { defaultColor } from './constants';
import { TimelineNodeTooltip, TimelineNodeTooltipProps } from './TimelineNodeTooltip';
import { useTimeline } from './TimelineProvider';
import { EntityBase, TimelineItem } from './types';
import { useTimelineNodeEvents } from './use-timeline-node-events';

type Props<Entity extends EntityBase> = {
  item: TimelineItem<Entity>;
};

function TimelineNode<Entity extends EntityBase>({ item }: Props<Entity>): JSX.Element {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const { nodeDiameter, selectedEntity } = useTimeline();
  const anchor = useRef(null);
  const [tooltipPlacement, setTooltipPlacement] =
    useState<TimelineNodeTooltipProps<Entity>['placement']>();
  const numberOfItems = useMemo(() => item.dateEntities.length, [item]);
  const multiple = useMemo(() => numberOfItems > 1, [numberOfItems]);
  const color = useMemo(
    () => (multiple ? defaultColor : item.dateEntities[0].color ?? defaultColor),
    [item, multiple],
  );
  const { width: windowWidth } = useWindowSize();

  useLayoutEffect(() => {
    if (ref.current) {
      const { x } = ref.current.getBoundingClientRect();
      setTooltipPlacement(x > windowWidth / 2 ? 'left-start' : 'right-start');
    }
  }, [windowWidth, ref, setTooltipPlacement]);

  const events = useTimelineNodeEvents(item.dateEntities[0]);
  const label = useMemo(() => {
    const name = item.dateEntities[0].label;
    return multiple
      ? t('{{name}} (+{{count}})', { name, count: item.dateEntities.length - 1 })
      : name;
  }, [multiple, item, t]);

  return (
    <div
      className="position-absolute d-flex flex-column align-items-center"
      ref={ref}
      {...events}
      style={{
        left: item.point,
        height: '100%',
        width: nodeDiameter,
        cursor: 'pointer',
      }}
    >
      {item.daysBetweenPoint && (
        <div
          className="position-absolute pe-none"
          style={{
            fontSize: '0.8rem',
            left: item.daysBetweenPoint,
            top: '20%',
            transform: 'translate(-12px, 0px) rotate(90deg)',
            whiteSpace: 'nowrap',
          }}
        >
          {t('{{days}} Days', { days: item.daysBetweenLastEvent })}
        </div>
      )}
      <div
        className="d-flex justify-content-center align-items-center"
        ref={anchor}
        style={{
          height: nodeDiameter,
          width: nodeDiameter,
          borderRadius: '50%',
          backgroundColor: 'var(--bs-body-bg)',
          border: `2px solid ${color}`,
          fontSize: '0.75rem',
        }}
      >
        {multiple ? numberOfItems : ''}
      </div>
      <div style={{ border: `1px solid ${color}`, width: 2, height: '20%' }} />
      <div className="flex-grow-1 d-flex flex-column algin-align-items-center w-100">
        <div style={{ transform: 'translate(0px, 4px) rotate(90deg)', whiteSpace: 'nowrap' }}>
          {label}
        </div>
      </div>
      <TimelineNodeTooltip
        entityItems={item.dateEntities}
        placement={tooltipPlacement}
        selectedEntityItem={selectedEntity}
        target={anchor.current}
      />
    </div>
  );
}

export default TimelineNode;
