// Copyright © 2023 CATTLEytics Inc.

import { useTranslation } from 'react-i18next';

import Page from '../../common/components/Page';
import SccQuantityChart from './SccQuantityChart';

const MilkQualityPage = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Page
      breadcrumbTitle={t('Milk Quality')}
      breadcrumbs={[{ label: t('Milk'), to: '/test-data' }]}
      title={t('Somatic Cell Count by Month')}
    >
      <SccQuantityChart />
    </Page>
  );
};

export default MilkQualityPage;
