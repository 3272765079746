// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { Spinner } from 'react-bootstrap';
import { IconType } from 'react-icons';

import Button from '../../common/components/Button';

/**
 * Defines the input properties for this component.
 */
interface Props {
  /**
   * Whether the button is in a busy state
   */
  busy?: boolean;

  /**
   * CSS classes.
   */
  className?: string;

  /**
   * Whether the button should be disabled.
   */
  disabled?: boolean;

  /**
   * Icon to display in button
   */
  icon?: IconType;

  /**
   * Button text.
   */
  label?: string;

  /**
   * On click callback.
   */
  onClick?: () => void;

  /**
   * HTML button type like "submit"
   */
  type?: 'button' | 'submit' | 'reset';

  /**
   * Button style variant
   */
  variant?: string;
}

/**
 * A button component for use in modals.
 */
const ButtonModal = (props: Props): JSX.Element => {
  const Icon = props.icon as IconType;
  return (
    <Button
      className={`text-nowrap ${props.className}`}
      disabled={props.disabled}
      onClick={props.onClick}
      style={{ minWidth: '9rem' }}
      type={props.type ?? 'button'}
      variant={props.variant ?? 'primary'}
    >
      {props.busy ? (
        <>
          <Spinner animation="border" aria-hidden="true" as="span" role="status" size="sm" />
          <span className="visually-hidden">Loading...</span>
        </>
      ) : (
        <>
          {props.icon && <Icon className={'me-1'} style={{ height: '100%' }} />}
          {props.label ?? ''}
        </>
      )}
    </Button>
  );
};

export default ButtonModal;
