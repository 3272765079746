// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { Modal } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';

import Button, { ButtonVariant } from '../../../common/components/Button';
import Spinner from '../../../common/components/Spinner';
import { IconCancel } from '../../../common/utilities';
import FileUpload from './FileUpload';

/**
 * Component input properties.
 */
interface Props extends WithTranslation {
  // Whether the component is busy
  busy?: boolean;

  // Callback when modal is closed.
  onClose: () => void;

  // Callback when a file has been uploaded.
  onUpload: (file: File) => void;
}

/**
 * Map component for creating pen regions.
 */
const ImageUploadModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const busy = props.busy;

  return (
    <Modal centered onHide={props.onClose} show={true}>
      <Modal.Header closeButton>
        <Modal.Title>Upload an image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FileUpload disabled={busy} onUpload={props.onUpload} />
      </Modal.Body>

      <Modal.Footer>
        <Button
          disabled={busy}
          icon={IconCancel}
          onClick={props.onClose}
          type={'button'}
          variant={ButtonVariant.Secondary}
        >
          Cancel
        </Button>
        {busy ? <Spinner /> : null}
      </Modal.Footer>
    </Modal>
  );
};

export default withTranslation()(ImageUploadModal);
