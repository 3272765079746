// Copyright © 2023 CATTLEytics Inc.

import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import ButtonCreate from '../../common/components/ButtonCreate';
import Page from '../../common/components/Page';
import { useIsAuthUserSiteAdminOrAbove } from '../../common/hooks';
import { TaskCategoriesManagePinned } from './TaskCategoriesManagePinned';
import { TaskCategoriesTable } from './TaskCategoriesTable';
import { TaskCategoryModal } from './TaskCategoryModal';

interface RouteParams {
  /**
   * Tab key to make active on render.
   */
  tabKey: string;
}

export const TaskCategoriesPage = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const { tabKey } = useParams<RouteParams>();

  const isSiteAdmin = useIsAuthUserSiteAdminOrAbove();

  const [taskCategoryModalVisible, setTaskCategoryModalVisible] = useState<boolean>(false);

  return (
    <Page
      breadcrumbs={[{ label: t('listsPage|title'), to: '/lists' }]}
      buttons={
        isSiteAdmin && (
          <ButtonCreate
            label={t('taskCategoriesPage|createButton')}
            onClick={(): void => {
              setTaskCategoryModalVisible(true);
            }}
          />
        )
      }
      title={t('taskCategoriesPage|title')}
    >
      <Tabs
        activeKey={tabKey}
        className="mb-3"
        id="task-categories-tabs"
        mountOnEnter={true}
        onSelect={(k): void => {
          if (k) {
            history.push(`/lists/task-categories/${k}`);
          }
        }}
      >
        <Tab eventKey="all" title={t('taskCategoriesPage|tabTitleAll')}>
          <TaskCategoriesTable />
        </Tab>
        <Tab eventKey="pinned" title={t('taskCategoriesPage|tabTitlePinned')}>
          <p>{t('taskCategoriesPage|introPinned')}</p>
          <TaskCategoriesManagePinned />
        </Tab>
      </Tabs>

      {taskCategoryModalVisible && (
        <TaskCategoryModal onClose={(): void => setTaskCategoryModalVisible(false)} />
      )}
    </Page>
  );
};
