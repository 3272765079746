// Copyright © 2023 CATTLEytics Inc.

import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import React, { FormEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import AlertErrorForModal from '../common/components/AlertErrorForModal';
import Button from '../common/components/Button';
import Required from '../common/components/Required';
import { getEnv, IconCaretRight } from '../common/utilities';

export const CheckoutForm = (props: { promoCode?: string }): JSX.Element => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent: paymentIntent }) => {
      if (!paymentIntent) {
        return;
      }
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    if (!props.promoCode) {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${getEnv('FRONTEND_URI')}/sign-up/complete`,
        },
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error.message);
      } else {
        setMessage('An unexpected error occurred.');
      }
    } else {
      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${getEnv('FRONTEND_URI')}/sign-up/complete`,
        },
      });
      setMessage('An unexpected error occurred.');
      console.error(error.message);
    }

    setIsLoading(false);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: 'tabs',
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <Form.Group className={'mb-3'}>
        <Form.Label>
          Payment information <Required />
        </Form.Label>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
      </Form.Group>
      <AlertErrorForModal message={message} />
      <div className={'d-grid gap-2'}>
        <Button
          busy={isLoading}
          disabled={isLoading || !stripe || !elements}
          icon={IconCaretRight}
          label={t('Continue')}
          size={'lg'}
          type={'submit'}
        />
      </div>
    </form>
  );
};
