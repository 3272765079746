// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { Placeholder } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import { ButtonVariant } from './Button';

interface Props {
  /**
   * The number of fields to return placeholders for
   */
  fields?: number;

  /**
   * Whether to show a delete button placeholder
   */
  showDeleteButton?: boolean;
}

/**
 * Placeholder for loading form data.
 */
const FormPlaceholder = (props: Props): JSX.Element => {
  return (
    <Placeholder animation="glow">
      {[...Array(props.fields ?? 4).keys()].map((num) => (
        <div className={'mb-3'} key={num}>
          <Placeholder size={'sm'} xs={Math.floor(Math.random() * (11 - 2) + 2)} />
          <Placeholder className={'w-100'} size={'lg'} style={{ height: '40px' }} xs={12} />
          <Placeholder size={'xs'} xs={Math.floor(Math.random() * (11 - 2) + 2)} />
        </div>
      ))}
      <div className={'d-flex justify-content-between mt-5'}>
        <Placeholder.Button size={'lg'} variant={ButtonVariant.Secondary} xs={3} />
        {props.showDeleteButton && (
          <Placeholder.Button size={'lg'} variant={ButtonVariant.Danger} xs={3} />
        )}
        <Placeholder.Button size={'lg'} variant={ButtonVariant.Primary} xs={3} />
      </div>
    </Placeholder>
  );
};

export default withTranslation()(FormPlaceholder);
