// Copyright © 2023 CATTLEytics Inc.
import { useInjection } from 'inversify-react';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { TYPES } from '../../../../../types';
import AnimalEventService from '../../../../common/services/animalEventService';
import NoteService from '../../../../common/services/noteService';
import { Note, QueryKey } from '../../../../shared';
import { EventDateItem, EventFilters } from '../types';

type Options = {
  animalId: string;
  filters: EventFilters;
};

type Return = {
  dates: EventDateItem[];
  isLoading: boolean;
};

export const useGetEventDates = ({ animalId, filters }: Options): Return => {
  const noteService = useInjection<NoteService>(TYPES.noteService);
  const animalEventService = useInjection<AnimalEventService>(TYPES.animalEventService);
  const queryEvents = useQuery(
    [QueryKey.AnimalEvents, String(animalId), ...Object.values(filters)],
    () =>
      filters.animalEventTypeIds
        ? animalEventService.list({
            animalId: String(animalId),
            animalEventTypeIds: filters.animalEventTypeIds?.join(','),
            sortField: 'eventDateTime',
            sortDirection: 'ASC',
            limit: '500',
            dateStart: filters.dateStart?.toISOString(),
            dateEnd: filters.dateEnd?.toISOString(),
          })
        : undefined,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: true,
      keepPreviousData: true,
    },
  );

  const queryNotes = useQuery<Note[]>(
    [QueryKey.Notes, String(animalId), ...Object.values(filters)],
    () =>
      noteService.list({
        animalId: String(animalId),
        sortField: 'createdDate',
        sortDirection: 'ASC',
        dateStart: filters.dateStart?.toISOString(),
        dateEnd: filters.dateEnd?.toISOString(),
      }),
    {
      enabled: !!filters.showNotes,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: true,
    },
  );

  const dates = useMemo(() => {
    const list = [
      ...(queryEvents.data?.length
        ? queryEvents.data.map((item) => ({ date: item.eventDateTime, item }))
        : []),
      ...(queryNotes.data?.length
        ? queryNotes.data.map((item) => ({ date: item.createdDate, item }))
        : []),
    ].sort((a, b) => a.date.valueOf() - b.date.valueOf());
    return list;
  }, [queryEvents.data, queryNotes.data]);

  const isLoading = useMemo(
    () => queryEvents.isLoading || queryNotes.isLoading,
    [queryEvents, queryNotes],
  );

  return { dates, isLoading };
};
