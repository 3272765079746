// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { Link } from 'react-router-dom';

import { nameTag } from '../../shared';
import { IconTag } from '../utilities';

interface Props {
  /**
   * The animal's identifier.
   * Note: this property is required when link property is true
   */
  animalId?: number;

  /**
   * Whether the animal's info should be wrapped in a link.
   * Note: animalId property is required when this property is true
   */
  link?: boolean;

  /**
   * Animal's name
   */
  name?: string;

  /**
   * Animal's primary tag
   */
  primaryTag?: string;
}

/**
 * Displays an animal's tag and name with an optional link.
 */
function AnimalTag(props: React.PropsWithChildren<Props>): JSX.Element {
  if (!props.link) {
    return (
      <>
        <IconTag className={'me-1'} />
        {nameTag(props.primaryTag, props.name)}
      </>
    );
  }

  // don't link to an animal without an ID.
  if (props.link && !props.animalId) {
    return <>-</>;
  }

  return (
    <>
      <Link to={`/animals/${props.animalId}`}>
        <IconTag className={'me-1'} />
        {nameTag(props.primaryTag, props.name)}
      </Link>
    </>
  );
}

export default AnimalTag;
