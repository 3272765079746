// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Col, Form, FormGroup, Placeholder, PlaceholderButton, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { TYPES } from '../../../types';
import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import Button from '../../common/components/Button';
import { ButtonVariant } from '../../common/components/Button';
import ButtonModalCancel from '../../common/components/ButtonModalCancel';
import ButtonModalDelete from '../../common/components/ButtonModalDelete';
import ButtonModalSave from '../../common/components/ButtonModalSave';
import ConfirmModal from '../../common/components/ConfirmModal';
import Modal from '../../common/components/Modal';
import Required from '../../common/components/Required';
import DiagnosisRegime from '../../common/entities/diagnosisRegime';
import DiagnosisRegimeDetailService from '../../common/services/diagnosisRegimeDetailService';
import DiagnosisRegimeService from '../../common/services/diagnosisRegimeService';
import { IconDelete } from '../../common/utilities';
import ProductAdminTable from '../../products/components/ProductAdminTable';
import { DiagnosisRegimeDetail, DiagnosisRegimeDetailType } from '../../shared';
import { QueryKey } from '../../shared/enums';

/**
 * Component's input properties.
 */
interface Props {
  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * The diagnosis regime id this step is for.
   * At the moment there doesn't seem to be a good reason to allow the
   * selection of the regime from the modal itself, so it should be passed in
   * as a prop for now for new steps.
   */
  diagnosisRegimeId?: number;

  /**
   * Identifier of the item we want to edit
   */
  editId?: number;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;
}

/**
 * Modal to add/edit a diagnosis regime.
 */
const DiagnosisRegimeDetailModal = (props: PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const diagnosisRegimeService = useInjection<DiagnosisRegimeService>(TYPES.diagnosisRegimeService);
  const diagnosisRegimeDetailService = useInjection<DiagnosisRegimeDetailService>(
    TYPES.diagnosisRegimeDetailService,
  );

  const history = useHistory();

  const [deleteConfirmModalErrorMessage, setDeleteConfirmModalErrorMessage] = useState<string>('');
  const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [validated, setValidated] = useState<boolean>(false);

  const [day, setDay] = useState<string>('1');
  const [description, setDescription] = useState<string>('');
  const [diagnosisRegime, setDiagnosisRegime] = useState<DiagnosisRegime>();
  const [diagnosisRegimeId, setDiagnosisRegimeId] = useState<string | undefined>(
    props.diagnosisRegimeId ? String(props.diagnosisRegimeId) : undefined,
  );
  const [ifYesStep, setIfYesStep] = useState<string>('');
  const [ifNoStep, setIfNoStep] = useState<string>('');
  const [lastStep, setLastStep] = useState<boolean>(false);
  const [step, setStep] = useState<string>('1');
  const [type, setType] = useState<DiagnosisRegimeDetailType>(DiagnosisRegimeDetailType.Treatment);

  /**
   * Get all the steps to determine the next step.
   */
  const queryDrdList = useQuery<DiagnosisRegimeDetail[]>(
    [QueryKey.DiagnosisRegimeDetails, props.diagnosisRegimeId],
    () =>
      diagnosisRegimeDetailService.list({
        diagnosisRegimeId: String(props.diagnosisRegimeId),
      }),
    {
      keepPreviousData: true,
      enabled: !props.editId,
      onSuccess: (data) => {
        if (props.editId || !data || data.length === 0) {
          return;
        }
        const nextStep = Math.max(...data.map((step) => Number(step.step))) + 1;
        if (nextStep) {
          setStep(String(nextStep));
        }
      },
    },
  );

  const queryDiagnosisRegime = useQuery<DiagnosisRegime | undefined>(
    [QueryKey.DiagnosisRegimes, diagnosisRegimeId],
    () => diagnosisRegimeService.get(Number(diagnosisRegimeId)),
    {
      enabled: !!diagnosisRegimeId,
    },
  );

  // save the diagnosis regime data to state once we receive it
  useEffect(() => {
    if (queryDiagnosisRegime.data) {
      setDiagnosisRegime(queryDiagnosisRegime.data);
    }
  }, [props.diagnosisRegimeId, queryDiagnosisRegime.data]);

  const showDeleteConfirmModal = (id: number): void => {
    setDeleteConfirmModalVisible(true);
    setDeleteId(id);
  };

  const deleteItem = async (): Promise<void> => {
    if (!deleteId) {
      return;
    }
    setIsDeleting(true);
    try {
      await diagnosisRegimeDetailService.delete(deleteId);
      await queryClient.invalidateQueries(QueryKey.DiagnosisRegimeDetails);
      setDeleteConfirmModalVisible(false);
      props.onClose();
    } catch (err) {
      console.error('This item failed to be deleted', err);
      setDeleteConfirmModalErrorMessage('This item could not be deleted.');
    }
    setIsDeleting(false);
  };

  const query = useQuery<DiagnosisRegimeDetail | undefined>(
    [QueryKey.DiagnosisRegimeDetails, props.editId],
    () => diagnosisRegimeDetailService.get(props.editId ?? -1),
    {
      keepPreviousData: true,
      enabled: !!props.editId,
      onSuccess: (data) => {
        if (!data) {
          return;
        }
        setDay(String(data.day));
        setDescription(data.description);
        setDiagnosisRegimeId(String(data.diagnosisRegimeId));
        if (data.ifYesStep || data.ifYesStep === 0) {
          setIfYesStep(String(data.ifYesStep));
        }
        if (data.ifNoStep || data.ifNoStep === 0) {
          setIfNoStep(String(data.ifNoStep));
        }
        setLastStep(data.lastStep);
        setStep(String(data.step));
        setType(data.type);
      },
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  const mutation = useMutation(
    () => {
      // clear some value based on step type
      const newIfNoStep = type === DiagnosisRegimeDetailType.Treatment ? null : ifNoStep;
      const newIfYesStep = type === DiagnosisRegimeDetailType.Treatment ? null : ifYesStep;
      const newLastStep = type === DiagnosisRegimeDetailType.Conditional ? false : lastStep;

      const payload = {
        day: day ? Number(day) : null,
        diagnosisRegimeId: Number(diagnosisRegimeId),
        description: description,
        ifNoStep: newIfNoStep ? Number(newIfNoStep) : null,
        ifYesStep: newIfYesStep ? Number(newIfYesStep) : null,
        lastStep: newLastStep,
        step: Number(step),
        type: type,
      };

      if (props.editId) {
        return diagnosisRegimeDetailService.patch(props.editId, payload);
      } else {
        return diagnosisRegimeDetailService.post(payload);
      }
    },
    {
      onSuccess: async () => {
        // Invalidate to trigger re-fetch
        await queryClient.invalidateQueries(QueryKey.DiagnosisRegimeDetails);
      },
    },
  );

  const queryClient = useQueryClient();

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);
    setErrorMessage('');

    if (
      type === DiagnosisRegimeDetailType.Conditional &&
      Number(ifNoStep) === 0 &&
      Number(ifYesStep) === 0
    ) {
      setErrorMessage('A conditional step cannot have both conditions set to 0');
      return;
    }

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      await mutation.mutateAsync();

      props.onClose();
    } catch (err) {
      setErrorMessage(
        t(`{{typeLabel}} could not be saved.`, {
          typeLabel: t('Diagnosis regime detail'),
        }),
      );
    }
  };

  const placeholder = (
    <Placeholder animation={'glow'}>
      <Placeholder xs={6} />
      <Placeholder className={'mb-5'} size={'lg'} xs={12} />
      <Placeholder xs={6} />
      <Placeholder className={'mb-5'} size={'lg'} xs={12} />
    </Placeholder>
  );

  const form = (
    <Form noValidate onSubmit={handleFormSubmit} validated={validated}>
      <Row>
        <Col md={6}>
          <FormGroup className="mb-3" controlId="formStep">
            <Form.Label>
              {t('Step')} <Required />
            </Form.Label>
            <Form.Control
              disabled={queryDrdList.isLoading}
              min={1}
              name={'step'}
              onChange={(e): void => setStep(e.target.value)}
              placeholder={t('Step number')}
              required
              type={'number'}
              value={step}
            />
            <Form.Text className={'text-muted'}>
              {t('diagnosisRegimeDetailModal|regimeStepNumberText')}
            </Form.Text>
            <Form.Control.Feedback type={'invalid'}>
              {t('common|fieldRequiredFeedback')}
            </Form.Control.Feedback>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup className="mb-3" controlId="formIfNo">
            <Form.Label>
              {t('Day')} <Required />
            </Form.Label>

            <Form.Control
              min={1}
              name={'day'}
              onChange={(e): void => setDay(e.target.value)}
              placeholder={t('Example: {{value}}', { value: 1 })}
              required
              type={'number'}
              value={day}
            />

            <Form.Text className={'text-muted'}>
              {t('diagnosisRegimeDetailModal|stepExecutionDateText')}
            </Form.Text>
            <Form.Control.Feedback type={'invalid'}>
              {t('diagnosisRegimeDetailModal|requiredNaturalNumberInvalidFeedback')}
            </Form.Control.Feedback>
          </FormGroup>
        </Col>
      </Row>
      <FormGroup className="mb-3" controlId="formDescription">
        <Form.Label>
          {t('Description')} <Required />
        </Form.Label>
        <Form.Control
          as={'textarea'}
          name={'description'}
          onChange={(e): void => setDescription(e.target.value)}
          placeholder={t('diagnosisRegimeDetailModal|detailDescriptionExample')}
          required
          rows={4}
          value={description}
        />
        <Form.Text className={'text-muted'}>
          {t('diagnosisRegimeDetailModal|actionsDescriptionInstructions')}
        </Form.Text>
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </FormGroup>
      <FormGroup className="mb-3" controlId="formIsConditional">
        <Form.Label>
          {t('Is this a treatment step or a conditional step?')} <Required />
        </Form.Label>
        <Form.Check
          checked={type === DiagnosisRegimeDetailType.Conditional}
          id={'type-conditional'}
          label={t('Conditional')}
          name={'type'}
          onChange={(e): void => setType(e.target.value as DiagnosisRegimeDetailType)}
          type={'radio'}
          value={DiagnosisRegimeDetailType.Conditional}
        />
        <Form.Check
          checked={type === DiagnosisRegimeDetailType.Treatment}
          id={'type-treatment'}
          label={t('Treatment')}
          name={'type'}
          onChange={(e): void => setType(e.target.value as DiagnosisRegimeDetailType)}
          type={'radio'}
          value={DiagnosisRegimeDetailType.Treatment}
        />
        <Form.Check
          checked={type === DiagnosisRegimeDetailType.WaitAndSee}
          id={'type-wait-and-see'}
          label={t('Wait and See')}
          name={'type'}
          onChange={(e): void => setType(e.target.value as DiagnosisRegimeDetailType)}
          type={'radio'}
          value={DiagnosisRegimeDetailType.WaitAndSee}
        />
      </FormGroup>
      {type === DiagnosisRegimeDetailType.Conditional && (
        <Row>
          <Col md={6}>
            <FormGroup className="mb-3" controlId="formIfYesStep">
              <Form.Label>
                {t('Next step if condition is true')} <Required />
              </Form.Label>
              <Form.Control
                min={0}
                name={'ifYesStep'}
                onChange={(e): void => setIfYesStep(e.target.value)}
                placeholder={t('Example: {{value}}', { value: 3 })}
                required
                type={'number'}
                value={ifYesStep}
              />
              <Form.Control.Feedback type={'invalid'}>
                {t('common|fieldRequiredFeedback')}
              </Form.Control.Feedback>
              <Form.Text className={'text-muted'}>
                {t('diagnosisRegimeDetailModal|conditionTrueAction')}
              </Form.Text>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className="mb-3" controlId="formIfNoCondition">
              <Form.Label>
                {t('Next step if condition is false')} <Required />
              </Form.Label>
              <Form.Control
                min={0}
                name={'ifNoStep'}
                onChange={(e): void => setIfNoStep(e.target.value)}
                placeholder={t('Example: {{value}}', { value: 5 })}
                required
                type={'number'}
                value={ifNoStep}
              />
              <Form.Control.Feedback type={'invalid'}>
                {t('common|fieldRequiredFeedback')}
              </Form.Control.Feedback>
              <Form.Text className={'text-muted'}>
                {t('diagnosisRegimeDetailModal|conditionFalseAction')}
              </Form.Text>
            </FormGroup>
          </Col>
        </Row>
      )}

      {type === DiagnosisRegimeDetailType.Treatment && (
        <Row>
          <Col md={12}>
            {t('Treatment options for the diagnosis:')}{' '}
            <strong>{diagnosisRegime?.diagnosis?.name}</strong>
            <ProductAdminTable
              diagnosisId={diagnosisRegime?.diagnosisId}
              hideDiagnosisColumn={true}
              readOnly={true}
            />
            <p className={'text-center mb-3'}>
              <Button
                onClick={(): void => history.push('/lists/products')}
                variant={ButtonVariant.Link}
              >
                {t('Manage products')}
              </Button>
            </p>
          </Col>
        </Row>
      )}
      {type !== DiagnosisRegimeDetailType.Conditional && (
        <Row>
          <Col md={12}>
            <FormGroup className="mb-3" controlId="formLastStep">
              <Form.Label>{t('Last step')}</Form.Label>
              <Form.Check
                checked={lastStep}
                label={t('Yes, this is the last step')}
                name={'lastStep'}
                onChange={(e): void => setLastStep(e.target.checked)}
                placeholder={t('Example: {{value}}', { value: 3 })}
                type={'checkbox'}
              />
              <Form.Text className={'text-muted'}>
                {t('diagnosisRegimeDetailModal|lastStepDescription')}
              </Form.Text>
            </FormGroup>
          </Col>
        </Row>
      )}
      <AlertErrorForModal message={errorMessage} />
      <div className="modal-footer modal-footer-in-form">
        <ButtonModalCancel
          disabled={mutation.isLoading || query.isLoading}
          onClick={props.onClose}
        />
        {props.editId && (
          <ButtonModalDelete
            onClick={(): void => {
              if (props.editId) {
                showDeleteConfirmModal(props.editId);
              }
            }}
          />
        )}
        <ButtonModalSave
          busy={mutation.isLoading}
          disabled={mutation.isLoading || query.isLoading}
        />
      </div>
    </Form>
  );

  return (
    <Modal
      fullscreen={'md-down'}
      onClose={props.onClose}
      size={'lg'}
      title={props.editId ? t('Edit diagnosis regime step') : t('Add step to diagnosis regime')}
      visible={true}
    >
      {query.isError ? (
        <AlertErrorForModal message={t(`diagnosisRegimeDetailModal|diagnosisRetrievalError`)} />
      ) : null}
      {query.isFetching ? placeholder : form}
      {query.isFetching && (
        <div className="modal-footer modal-footer-in-form">
          <PlaceholderButton variant={'secondary'} xs={2} />
          <PlaceholderButton xs={2} />
        </div>
      )}
      <ConfirmModal
        busy={isDeleting}
        cancelOnClick={(): void => setDeleteConfirmModalVisible(false)}
        errorMessage={deleteConfirmModalErrorMessage}
        okIcon={IconDelete}
        okLabel={t(`Yes, delete this {{value}}`, { value: 'step' })}
        okOnClick={deleteItem}
        okVariant={ButtonVariant.Danger}
        title={t(`Delete this {{value}}`, { value: 'step' })}
        visible={deleteConfirmModalVisible}
      >
        {t('Are you sure you want to delete this {{value}}?', { value: 'step' })}
      </ConfirmModal>
    </Modal>
  );
};

export default DiagnosisRegimeDetailModal;
