// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import Button from '../../common/components/Button';
import { IconManage } from '../../common/utilities';

/**
 * Routable component for working with lists.
 */
const ListsTable = (): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Table responsive striped>
      <thead>
        <tr>
          <th>{t('listsTable|headerName')}</th>
          <th>{t('listsTable|headerAction')}</th>
        </tr>
      </thead>
      <tbody>
        <tr className={'clickable'} onClick={(): void => history.push('/lists/animal-colors')}>
          <td>{t('entity|animalColor', { count: 2 })}</td>
          <td>
            <Link to={'/lists/animal-colors'}>
              <Button className={'text-nowrap'} size={'sm'} variant={'outline-primary'}>
                <IconManage className={'me-1'} />
                {t('listsTable|manageButton')}
              </Button>
            </Link>
          </td>
        </tr>
        <tr className={'clickable'} onClick={(): void => history.push('/lists/birth-statuses')}>
          <td>{t('entity|birthStatus', { count: 2 })}</td>
          <td>
            <Link to={'/lists/birth-statuses'}>
              <Button className={'text-nowrap'} size={'sm'} variant={'outline-primary'}>
                <IconManage className={'me-1'} />
                {t('listsTable|manageButton')}
              </Button>
            </Link>
          </td>
        </tr>
        <tr className={'clickable'} onClick={(): void => history.push('/lists/diagnoses')}>
          <td>{t('entity|diagnosis', { count: 2 })}</td>
          <td>
            <Link to={'/lists/diagnoses'}>
              <Button className={'text-nowrap'} size={'sm'} variant={'outline-primary'}>
                <IconManage className={'me-1'} />
                {t('listsTable|manageButton')}
              </Button>
            </Link>
          </td>
        </tr>
        <tr className={'clickable'} onClick={(): void => history.push('/diagnosis-regimes')}>
          <td>{t('entity|diagnosisRegime', { count: 2 })}</td>
          <td>
            <Link to={'/diagnosis-regimes'}>
              <Button className={'text-nowrap'} size={'sm'} variant={'outline-primary'}>
                <IconManage className={'me-1'} />
                {t('listsTable|manageButton')}
              </Button>
            </Link>
          </td>
        </tr>
        <tr className={'clickable'} onClick={(): void => history.push('/lists/animal-event-types')}>
          <td>{t('entity|animalEventType', { count: 2 })}</td>
          <td>
            <Link to={'/lists/animal-event-types'}>
              <Button className={'text-nowrap'} size={'sm'} variant={'outline-primary'}>
                <IconManage className={'me-1'} />
                {t('listsTable|manageButton')}
              </Button>
            </Link>
          </td>
        </tr>
        <tr className={'clickable'} onClick={(): void => history.push('/lists/breeds')}>
          <td>{t('entity|breed', { count: 2 })}</td>
          <td>
            <Link to={'/lists/breeds'}>
              <Button className={'text-nowrap'} size={'sm'} variant={'outline-primary'}>
                <IconManage className={'me-1'} />
                {t('listsTable|manageButton')}
              </Button>
            </Link>
          </td>
        </tr>
        <tr className={'clickable'} onClick={(): void => history.push('/lists/genders')}>
          <td>{t('entity|gender', { count: 2 })}</td>
          <td>
            <Link to={'/lists/genders'}>
              <Button className={'text-nowrap'} size={'sm'} variant={'outline-primary'}>
                <IconManage className={'me-1'} />
                {t('listsTable|manageButton')}
              </Button>
            </Link>
          </td>
        </tr>
        <tr className={'clickable'} onClick={(): void => history.push('/animal-health-checks')}>
          <td>{t('entity|healthCheck', { count: 2 })}</td>
          <td>
            <Link to={'/animal-health-checks'}>
              <Button className={'text-nowrap'} size={'sm'} variant={'outline-primary'}>
                <IconManage className={'me-1'} />
                {t('listsTable|manageButton')}
              </Button>
            </Link>
          </td>
        </tr>

        <tr className={'clickable'} onClick={(): void => history.push('/lists/products/active')}>
          <td>{t('entity|product', { count: 2 })}</td>
          <td>
            <Link to={'/lists/products/active'}>
              <Button className={'text-nowrap'} size={'sm'} variant={'outline-primary'}>
                <IconManage className={'me-1'} />
                {t('listsTable|manageButton')}
              </Button>
            </Link>
          </td>
        </tr>
        <tr>
          <td>{t('entity|productClass', { count: 2 })}</td>
          <td>
            <Link to={'/lists/product-classes'}>
              <Button
                className={'text-nowrap'}
                disabled={false}
                size={'sm'}
                variant={'outline-primary'}
              >
                <IconManage className={'me-1'} />
                {t('listsTable|manageButton')}
              </Button>
            </Link>
          </td>
        </tr>
        <tr>
          <td>{t('entity|productType', { count: 2 })}</td>
          <td>
            <Link to={'/lists/product-types'}>
              <Button
                className={'text-nowrap'}
                disabled={false}
                size={'sm'}
                variant={'outline-primary'}
              >
                <IconManage className={'me-1'} />
                {t('listsTable|manageButton')}
              </Button>
            </Link>
          </td>
        </tr>

        <tr
          className={'clickable'}
          onClick={(): void => history.push('/lists/animal-reference-types')}
        >
          <td>{t('entity|animalReferenceType', { count: 2 })}</td>
          <td>
            <Link to={'/lists/animal-reference-types'}>
              <Button className={'text-nowrap'} size={'sm'} variant={'outline-primary'}>
                <IconManage className={'me-1'} />
                {t('listsTable|manageButton')}
              </Button>
            </Link>
          </td>
        </tr>
        <tr className={'clickable'} onClick={(): void => history.push('/lists/repro-statuses')}>
          <td>{t('entity|reproductionStatus', { count: 2 })}</td>
          <td>
            <Link to={'/lists/repro-statuses'}>
              <Button className={'text-nowrap'} size={'sm'} variant={'outline-primary'}>
                <IconManage className={'me-1'} />
                {t('listsTable|manageButton')}
              </Button>
            </Link>
          </td>
        </tr>
        <tr
          className={'clickable'}
          onClick={(): void => history.push('/lists/task-categories/all')}
        >
          <td>{t('entity|taskCategory', { count: 2 })}</td>
          <td>
            <Link to={'/lists/task-categories/all'}>
              <Button className={'text-nowrap'} size={'sm'} variant={'outline-primary'}>
                <IconManage className={'me-1'} />
                {t('listsTable|manageButton')}
              </Button>
            </Link>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default ListsTable;
