// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import CardStatsPlaceholder from '../../animals/components/CardStatsPlaceholder';
import AlertError from '../../common/components/AlertError';
import DataCell from '../../common/components/DataCell';
import ReportHerdInfo from '../../common/entities/reportHerdInfo';
import { api } from '../../common/utilities';
import { ApiResourceV1, HttpMethod, QueryKey } from '../../shared/enums';

interface Props {
  className?: string;
}

/**
 * Displays herd information.
 */
const HerdInfo = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const query = useQuery<ReportHerdInfo | undefined>(
    [QueryKey.Reports, 'herd-info-counts'],
    () => api<ReportHerdInfo>(HttpMethod.Get, `${ApiResourceV1.Reports}/herd-info-counts`),
    {
      refetchInterval: 300000,
      refetchOnWindowFocus: false,
    },
  );

  if (query.isLoading) {
    return <CardStatsPlaceholder />;
  }

  if (!query.data) {
    return <AlertError message={'Could not load herd info'} />;
  }

  return (
    <Card className={props.className} style={{ borderRadius: '1rem' }}>
      <Card.Body>
        <Row>
          <Col md={3} xs={6}>
            <DataCell
              label={t('Total animals')}
              tooltip={t('Total animals')}
              value={query?.data?.totalAnimals}
            />
          </Col>
          <Col md={3} xs={6}>
            <DataCell
              label={t('Total pregnant')}
              tooltip={t('Total pregnant')}
              value={query?.data?.totalPregnant}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default HerdInfo;
