// Copyright © 2024 CATTLEytics Inc.

export function applyPermissionsFilter(
  sitePermissions: Record<string, boolean>,
  userPermissions: Record<string, boolean>,
): Record<string, boolean> {
  // filter userPermissions to only show keys that exist in sitePermissions
  const siteKeys = Object.keys(sitePermissions);
  const setOfKeys = new Set([...Object.keys(sitePermissions), ...Object.keys(userPermissions)]);
  const validUserPermissions = [...setOfKeys.values()].reduce((prev, userKey) => {
    if (siteKeys.includes(userKey) && sitePermissions[userKey] !== false) {
      return {
        ...prev,
        [userKey]: userPermissions[userKey] ?? sitePermissions[userKey],
      };
    }
    return prev;
  }, {} as Record<string, boolean>);

  return { ...validUserPermissions };
}
