// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { TYPES } from '../../../types';
import ManageListEditModal from '../../lists/components/ManageListEditModal';
import { ApiResourceV1, QueryKey } from '../../shared';
import BirthStatusService from '../services/birthStatusService';

/**
 * Component input properties.
 */
interface Props {
  /**
   * Accessibility label
   */
  ariaLabel?: string;

  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Whether the first item of the select should be selected as the default.
   */
  firstItemDefault?: boolean;

  /**
   * Form element name
   */
  name?: string;

  /**
   * Input change handler
   * @param event
   */
  onChange: (value: string) => void;

  /**
   * When add modal is closed
   */
  onCloseAddModal?: () => void;

  /**
   * Whether this field is required
   */
  required?: boolean;

  /**
   * Whether to display the add modal
   */
  showAddModal?: boolean;

  /**
   * Input value
   */
  value: number;
}

/**
 * Form select component for birthStatuses.
 */
const BirthStatusSelect = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const birthStatusService = useInjection<BirthStatusService>(TYPES.birthStatusService);

  const [showAddModal, setShowAddModal] = useState<boolean>(!!props.showAddModal);
  const [previousShowAddModal, setPreviousShowAddModal] = useState<boolean>();

  const query = useQuery([QueryKey.BirthStatuses], () => birthStatusService.list(), {
    onSuccess: (data) => {
      if (!data || data.length === 0) {
        return;
      }
      if (props.firstItemDefault && data.length > 0) {
        props.onChange(String(data[0].id));
      }
    },
  });
  const items = query.data;
  const value = props.value ?? undefined;

  if (!!props.showAddModal !== previousShowAddModal) {
    setShowAddModal(!!props.showAddModal);
    setPreviousShowAddModal(!!props.showAddModal);
  }

  return (
    <>
      <Form.Select
        aria-label={props.ariaLabel ?? t('Birth Status')}
        className={props.className}
        name={props.name ?? 'birthStatusId'}
        onChange={(e): void => props.onChange(e.target.value)}
        required={props.required}
        value={value}
      >
        <option>{query.isFetching ? t('common|loading') : t('Choose a birth status')}</option>
        {items &&
          items.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
      </Form.Select>
      {showAddModal && (
        <ManageListEditModal
          apiResource={ApiResourceV1.BirthStatuses}
          onClose={props.onCloseAddModal ?? ((): void => undefined)}
          queryKey={QueryKey.BirthStatuses}
          typeName={'birthStatus'}
        />
      )}
    </>
  );
};

export default BirthStatusSelect;
