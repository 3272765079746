// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Col, Placeholder, Row } from 'react-bootstrap';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { MdFullscreen, MdFullscreenExit, MdHome } from 'react-icons/md';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { TYPES } from '../../../types';
import Button from '../../common/components/Button';
import Spinner from '../../common/components/Spinner';
import Layout from '../../common/entities/layout';
import LayoutItem from '../../common/entities/layoutItem';
import Site from '../../common/entities/site';
import { LayoutWidgetType } from '../../common/enums';
import LayoutItemService from '../../common/services/layoutItemService';
import LayoutService from '../../common/services/layoutService';
import SiteService from '../../common/services/siteService';
import AuthContext from '../../common/store/auth-context';
import ActiveTreatmentsWhiteboardList from '../../events/components/ActiveTreatmentsWhiteboardList';
import NotesWhiteboardList from '../../notes/components/NotesWhiteboardList';
import { QueryKey } from '../../shared';
import TasksListWhiteboard from '../../tasks/components/TasksListWhiteboard';
import NoteViewerModal from './NoteViewerModal';
import AddNewFloatButtonModal from './WhiteboardAddNewFloatButtonModal';
import WhiteboardFooter from './WhiteboardFooter';
import WhiteboardHeader from './WhiteboardHeader';

/**
 * Whiteboard component.
 */
const WhiteboardPage = (): JSX.Element => {
  const layoutService = useInjection<LayoutService>(TYPES.layoutService);
  const layoutItemService = useInjection<LayoutItemService>(TYPES.layoutItemService);
  const siteService = useInjection<SiteService>(TYPES.siteService);

  const [showNoteViewer, setShowNoteViewer] = useState(false);
  const [noteViewerId, setNoteViewerId] = useState(0);

  const auth = useContext(AuthContext);

  const [site, setSite] = useState<Site>();
  const [layout, setLayout] = useState<Layout>();
  const handle = useFullScreenHandle();

  const query = useQuery<Layout[]>([QueryKey.Layouts], () => layoutService.list());

  const querySite = useQuery(
    [QueryKey.Sites, auth.siteId],
    () => siteService.getSite(Number(auth.siteId)),
    {
      refetchOnWindowFocus: 'always',
      refetchOnMount: true,
      refetchOnReconnect: 'always',
      retry: true,
    },
  );

  const toggleFullscreen = useCallback(() => {
    handle.active ? handle.exit() : handle.enter();
  }, [handle]);

  useEffect(() => {
    if (querySite.data) {
      setSite(querySite.data);
    }
  }, [querySite.data]);

  const queryLayoutItems = useQuery<LayoutItem[]>(
    [QueryKey.LayoutItems, 1],
    () => layoutItemService.list({ layoutId: String(layout ? layout.id : '') }),
    {
      enabled: !!(layout && layout.id),
      refetchOnWindowFocus: 'always',
      refetchOnMount: true,
      refetchOnReconnect: 'always',
      retry: true,
    },
  );

  useEffect(() => {
    if (query.data && query.data[0]) {
      setLayout(query.data[0]);
    }
  }, [query.data]);

  const widgets = queryLayoutItems.data;

  return (
    <FullScreen handle={handle}>
      <div className="whiteboard-page fixed-top vh-100 d-flex flex-column">
        <div className="modal-header">
          {querySite.isLoading ? (
            <Placeholder animation={'glow'} as={WhiteboardHeader} />
          ) : (
            <WhiteboardHeader site={site} />
          )}
        </div>
        <div className="modal-body overflow-auto overflow-md-hidden overflow-x-hidden">
          {queryLayoutItems.isLoading && <Spinner />}
          {!queryLayoutItems.isLoading && (
            <Row className="h-100">
              {widgets &&
                widgets.map((widget, index) => {
                  const component = ((): JSX.Element => {
                    switch (widget.widgetType) {
                      case LayoutWidgetType.Tasks:
                        return <TasksListWhiteboard />;
                      case LayoutWidgetType.Notes:
                        const properties = widget.properties;
                        const tags = properties && properties.tags ? properties.tags : undefined;
                        return (
                          <NotesWhiteboardList
                            onView={(noteId): void => {
                              setNoteViewerId(noteId);
                              setShowNoteViewer(true);
                            }}
                            tags={tags}
                          />
                        );
                      case LayoutWidgetType.Treatments:
                        return <ActiveTreatmentsWhiteboardList />;
                      default:
                        return <span>Unknown</span>;
                    }
                  })();
                  const cols = widget.properties?.cols;
                  return (
                    <Col
                      className="d-flex flex-column col-12 col-md-6 col-xl-4"
                      key={index}
                      md={cols ?? undefined}
                    >
                      <h2>{widget.title}</h2>
                      {component}
                    </Col>
                  );
                })}
            </Row>
          )}
        </div>
        <div className="modal-footer">
          <WhiteboardFooter />
        </div>
        <div className="fixed-bottom d-flex flex-row p-2" style={{ left: 'unset' }}>
          {!handle.active && <AddNewFloatButtonModal />}
          <Button className="btn-floating me-2" onClick={toggleFullscreen} variant="primary">
            {handle.active ? (
              <MdFullscreenExit style={{ height: '100%' }} />
            ) : (
              <MdFullscreen style={{ height: '100%' }} />
            )}
          </Button>
          <Link to="/dashboard">
            <Button className="btn-floating" variant="primary">
              <MdHome style={{ height: '100%' }} />
            </Button>
          </Link>
        </div>
        {showNoteViewer && (
          <NoteViewerModal
            noteId={noteViewerId}
            onClose={(): void => {
              setShowNoteViewer(false);
            }}
          />
        )}
      </div>
    </FullScreen>
  );
};

export default WhiteboardPage;
