// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconComplete, IconNotComplete } from '../common/utilities';

interface Props {
  className?: string;
  stepNumber: number;
}

const iconCurrentStepStyled = (
  <IconNotComplete className={'me-2'} style={{ fontSize: '24px', color: 'var(--bs-dark)' }} />
);
const iconNotCompleteStyled = (
  <IconNotComplete className={'me-2'} style={{ fontSize: '24px', color: 'var(--bs-secondary)' }} />
);
const iconCompleteStyled = (
  <IconComplete className={'me-2'} style={{ fontSize: '24px', color: 'var(--bs-success)' }} />
);

export const OnboardingProgress = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div
      className={`${props.className ?? ''} d-flex justify-content-around`}
      style={{ fontStyle: '15px' }}
    >
      <div>
        {props.stepNumber === 1 && iconCurrentStepStyled}
        {props.stepNumber > 1 && iconCompleteStyled}
        {props.stepNumber === 1 && <strong>{t('onboardingProgress|step1Label')}</strong>}
        {props.stepNumber !== 1 && <>{t('onboardingProgress|step1Label')}</>}
      </div>
      <div>
        {props.stepNumber < 2 && iconNotCompleteStyled}
        {props.stepNumber === 2 && iconCurrentStepStyled}
        {props.stepNumber > 2 && iconCompleteStyled}
        {props.stepNumber === 2 && <strong>{t('onboardingProgress|step2Label')}</strong>}
        {props.stepNumber !== 2 && <>{t('onboardingProgress|step2Label')}</>}
      </div>
      <div>
        {props.stepNumber < 3 && iconNotCompleteStyled}
        {props.stepNumber === 3 && iconCurrentStepStyled}
        {props.stepNumber > 3 && iconCompleteStyled}
        {props.stepNumber === 3 && <strong>{t('onboardingProgress|step3Label')}</strong>}
        {props.stepNumber !== 3 && <>{t('onboardingProgress|step3Label')}</>}
      </div>
      <div>
        {props.stepNumber < 4 && iconNotCompleteStyled}
        {props.stepNumber === 4 && iconCurrentStepStyled}
        {props.stepNumber > 4 && iconCompleteStyled}
        {props.stepNumber === 4 && <strong>{t('onboardingProgress|step4Label')}</strong>}
        {props.stepNumber !== 4 && <>{t('onboardingProgress|step4Label')}</>}
      </div>
    </div>
  );
};
