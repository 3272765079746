// Copyright © 2023 CATTLEytics Inc.

import { inject, injectable } from 'inversify';

import { TYPES } from '../../../types';
import type Logger from '../../logger/logger';
import AnimalReference from '../entities/animalReference';
import NextId from '../entities/nextId';
import Api2Service from './api2Service';
import DataService from './dataService';

const path = '/v1/animal-references';

/** @see AnimalReference */
export interface PayloadPost {
  active?: boolean;
  animalId: number;
  animalReferenceTypeId: number;
  effectiveDateEnd?: string;
  effectiveDateStart?: string;
  reference: string;
}

export interface PayloadPatch extends PayloadPost {
  id: number;
}

/**
 * Service for managing animal references.
 */
@injectable()
export default class AnimalReferenceService implements DataService<AnimalReference> {
  private logger: Logger;

  private apiService: Api2Service;

  /**
   * Creates an instance of AnimalReferenceService.
   *
   * @param logger Logger instance.
   * @param apiService API Service instance
   */
  constructor(
    @inject(TYPES.logger) logger: Logger,
    @inject(TYPES.api2Service) apiService: Api2Service,
  ) {
    this.logger = logger;
    this.apiService = apiService;
  }

  /**
   * Retrieve a list of animal references
   * @return AnimalReference objects
   */
  async list(params?: Record<string, string>): Promise<AnimalReference[]> {
    const result = await this.apiService.get<AnimalReference[]>(path, params);
    if (!result) {
      return [];
    }

    const data = result as AnimalReference[];
    data.forEach((item) => this.cast(item));
    return data;
  }

  async get(id: number, params?: Record<string, string>): Promise<AnimalReference | undefined> {
    const result = await this.apiService.get<AnimalReference>(`${path}/${id}`, params);
    if (!result) {
      return undefined;
    }

    const data = result as AnimalReference;
    this.cast(data);
    return data;
  }

  async nextId(params?: Record<string, string>): Promise<NextId | undefined> {
    const result = await this.apiService.get<NextId>(`${path}/next-id`, params);
    if (!result) {
      return undefined;
    }

    return result;
  }

  async post(attributes: PayloadPost): Promise<AnimalReference | undefined> {
    const result = await this.apiService.post<AnimalReference>(`${path}`, attributes);
    if (!result) {
      return undefined;
    }
    this.cast(result as AnimalReference);
    return result;
  }

  async patch(id: number, attributes: Partial<PayloadPatch>): Promise<AnimalReference | undefined> {
    // make sure record reference is included
    attributes.id = id;

    const result = await this.apiService.patch(`${path}/${id}`, attributes);
    if (!result) {
      return undefined;
    }

    const data = result as AnimalReference;
    this.cast(data);
    return data;
  }

  async delete(id: number): Promise<null> {
    return await this.apiService.delete(`${path}/${id}`);
  }

  /**
   * Casts fields into javascript types.
   * @param item
   * @return cast object
   */
  cast(item: AnimalReference): void {
    if (!item) {
      return;
    }
  }
}
