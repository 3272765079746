// Copyright © 2023 CATTLEytics Inc.
import { Animal } from '../../shared';

/**
 * Get an animal's primary tag.
 * Iterates through all of an animal's references and returns the reference of type Primary Tag.
 * @param animal
 */
export const extractAnimalReferencePrimaryTag = (animal?: Animal): string | undefined => {
  if (!animal) {
    return undefined;
  }
  const refs = animal.animalReferences.filter((reference) => reference.animalReferenceTypeId === 1);
  if (refs.length > 0) {
    return refs[0].reference;
  }
  return undefined;
};

/**
 * Get an animal's name.
 * Iterates through all of an animal's references and returns the reference of type Name.
 * @param animal
 */
export const extractAnimalReferenceName = (animal?: Animal): string | undefined => {
  if (!animal) {
    return undefined;
  }
  const refs = animal.animalReferences.filter((reference) => reference.animalReferenceTypeId === 2);
  if (refs.length > 0) {
    return refs[0].reference;
  }
  return undefined;
};
