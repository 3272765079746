// Copyright © 2023 CATTLEytics Inc.

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import Spinner from '../common/components/Spinner';
import { IconComplete } from '../common/utilities';
import { api } from '../common/utilities';
import { QueryKey } from '../shared';
import { ApiResourceV1, HttpMethod } from '../shared';

interface SignUpStatus {
  ready: boolean;
}
/**
 * Waiting for payment/site creation to complete component
 */
export const CompleteWaiting = (): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();

  const query = useQuery<SignUpStatus>(
    QueryKey.SignUp,
    () => api<SignUpStatus>(HttpMethod.Get, `${ApiResourceV1.SignUp}/1`),
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: 5000,
      refetchIntervalInBackground: false,
    },
  );

  const queryClient = useQueryClient();

  useEffect(() => {
    if (query.data) {
      if (query.data.ready) {
        const timer = setTimeout(() => {
          queryClient.invalidateQueries(QueryKey.Auth);
          history.push('/dashboard');
        }, 5000);
        return (): void => clearTimeout(timer);
      }
    }
  }, [query.data, queryClient, history]);

  return (
    <>
      <div className={'d-flex justify-content-center'}>
        <IconComplete
          className={'me-2'}
          style={{ fontSize: '150px', color: 'var(--bs-success)' }}
        />
      </div>
      <div className={'mb-2 d-flex justify-content-center'}>
        <p className={'text-center'}>
          <strong>{t('completeWaiting|redirectingSoonText')}</strong>
        </p>
      </div>
      <div className={'mb-5 d-flex justify-content-center'}>
        <Spinner />
      </div>
    </>
  );
};
