// Copyright © 2023 CATTLEytics Inc.

import { useTranslation } from 'react-i18next';

import Page from '../../common/components/Page';
import BredOutcomeTable from './BredOutcomeTable';
import FirstBredEventChart from './FirstBredEventChart';

const ReproductionManagementPage = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Page title={t('reproductionManagement|reproductionManagementPage')}>
      <div className="mb-5">
        <h2>{t('reproductionManagement|bredOutcomeTableHeader')}</h2>
        <BredOutcomeTable />
      </div>
      <h2>{t('reproductionManagement|firstBredEventChartHeader')}</h2>
      <FirstBredEventChart />
    </Page>
  );
};

export default ReproductionManagementPage;
