// Copyright © 2024 CATTLEytics Inc.

import { ReactNode, useMemo } from 'react';
import { Event } from 'react-big-calendar';
import { OverlayTrigger, OverlayTriggerProps, Popover } from 'react-bootstrap';

import { useSettingsContext } from '../../common/store/useSettingsContext';
import { getShiftDateString, Shift, UserSchedule } from '../../shared';

type EventTooltipProps = {
  buttons?: ReactNode;
  event: Event;
  label?: string;
  tooltip?: string;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
};
export const EventTooltip = ({
  tooltipPlacement,
  event,
  children,
}: React.PropsWithChildren<
  EventTooltipProps & Pick<OverlayTriggerProps, 'children'>
>): JSX.Element => {
  const shift = useMemo(() => event.resource.shift as Shift, [event]);
  const schedules = useMemo(() => event.resource.schedules as UserSchedule[], [event]);
  const settings = useSettingsContext();
  const dateStr = useMemo(
    () => getShiftDateString(shift, schedules, settings.timeZone),
    [shift, schedules, settings.timeZone],
  );
  return (
    <OverlayTrigger
      overlay={
        <Popover className="shadow" id="popover-basic" style={{ minWidth: 200 }}>
          <Popover.Header
            as="h3"
            className="text-white text-center"
            style={{ backgroundColor: shift.color }}
          >
            <div>{shift.name}</div>
            <div className="mt-1" style={{ fontSize: '0.75rem' }}>
              {dateStr}
            </div>
          </Popover.Header>
          <Popover.Body>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              {schedules.reduce<ReactNode[]>((prev, { user }, i) => {
                if (user) {
                  const { firstName, lastName, id } = user;
                  prev.push(
                    <li key={`${id}-${i}`} style={{ margin: 0 }}>{`${firstName} ${lastName}`}</li>,
                  );
                }
                return prev;
              }, [])}
            </ul>
          </Popover.Body>
        </Popover>
      }
      placement={tooltipPlacement}
    >
      {children}
    </OverlayTrigger>
  );
};
