// Copyright © 2023 CATTLEytics Inc.

/**
 * Gender Enumerations
 *
 * Note: these types mapped 1:1 to the system defined rows in gender table.
 */
export enum Gender {
  Unknown = 1,
  Female = 2,
  Male = 3,
}
