// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useContext, useState } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import Button, { ButtonVariant } from '../../common/components/Button';
import ConfirmModal from '../../common/components/ConfirmModal';
import DataTable, { DataTableHeader, DataTableRow } from '../../common/components/DataTable';
import ImageTableColumn from '../../common/components/ImageTableColumn';
import NoImageUploaded from '../../common/components/NoImageUploaded';
import TablePlaceholder from '../../common/components/TablePlaceholder';
import { Sort } from '../../common/enums';
import ProductService from '../../common/services/productService';
import AuthContext from '../../common/store/auth-context';
import {
  IconDelete,
  IconEdit,
  IconHide,
  IconView,
  isSuperAdmin,
  scrollToTop,
} from '../../common/utilities';
import Logger from '../../logger/logger';
import { Product } from '../../shared';
import { QueryKey } from '../../shared/enums';
import DiagnosisRegimeModal from './ProductModal';

interface Props {
  /**
   * Number of events to show.
   */
  initialLimit?: number;

  /**
   * Number of rows to skip.
   */
  initialOffset?: number;

  /**
   * Table sort direction.
   */
  initialSortDirection?: Sort;

  /**
   * Table sort field.
   */
  initialSortField?: string;

  showOnlyHidden?: boolean;
}

/**
 * Table of products.
 */
const ProductsTable = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const logger = useInjection<Logger>(TYPES.logger);
  const productService = useInjection<ProductService>(TYPES.productService);

  const auth = useContext(AuthContext);
  const history = useHistory();

  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [editId, setEditId] = useState<number>(0);

  const [deleteConfirmModalErrorMessage, setDeleteConfirmModalErrorMessage] = useState<string>('');
  const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();

  const [limit, setLimit] = useState<number>(props.initialLimit ?? 25);
  const [offset, setOffset] = useState<number>(props.initialOffset ?? 0);
  const [search, setSearch] = useState<string>('');
  const [sortField, setSortField] = useState<string>(props.initialSortField ?? 'name');
  const [sortDirection, setSortDirection] = useState<Sort>(
    props.initialSortDirection ?? Sort.Ascending,
  );

  const query = useQuery(
    [
      QueryKey.Products,
      'list',
      limit,
      offset,
      sortField,
      sortDirection,
      search,
      props.showOnlyHidden ? '1' : '0',
    ],
    () =>
      productService.list({
        search: String(search),
        limit: String(limit),
        offset: String(offset),
        sortField: sortField,
        sortDirection: String(sortDirection),
        showOnlyHidden: props.showOnlyHidden ? '1' : '0',
      }),
    {
      keepPreviousData: true,
      onSuccess: scrollToTop,
    },
  );

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (productId: number) => {
      // if only showing hidden
      return productService.patch(productId, { hidden: !props.showOnlyHidden });
    },
    {
      onSuccess: () => queryClient.invalidateQueries(QueryKey.Products),
    },
  );

  const deleteItem = async (): Promise<void> => {
    if (!deleteId) {
      return;
    }
    setIsDeleting(true);
    try {
      await productService.delete(deleteId);
      await queryClient.invalidateQueries(QueryKey.Products);
      setDeleteConfirmModalVisible(false);
    } catch (err) {
      logger.error('This item failed to be deleted', err);
      setDeleteConfirmModalErrorMessage(t('common|itemCannotDeleteError'));
    }
    setIsDeleting(false);
  };

  const showEditModal = (id: number): void => {
    setEditModalVisible(true);
    setEditId(id);
  };

  const showDeleteConfirmModal = (id: number): void => {
    setDeleteConfirmModalVisible(true);
    setDeleteId(id);
  };

  if (query.isLoading) {
    return <TablePlaceholder />;
  }

  if (query.isError) {
    return <AlertError message={t('productsTable|productRetrievalError')} />;
  }

  const headers: DataTableHeader[] = [
    {
      name: 'image',
      label: t('Image'),
    },
    {
      name: 'name',
      label: t('Name'),
      infoTooltip: t('The name of this animal'),
    },
    {
      name: 'description',
      label: t('Description'),
    },
    {
      name: 'productClass',
      label: t('Class'),
      infoTooltip: t('The product class'),
      hideMobile: true,
    },
    {
      name: 'productType',
      label: t('Type'),
      infoTooltip: t('The product type'),
      hideMobile: true,
    },
  ];
  if (isSuperAdmin(auth)) {
    headers.push({ name: 'actions', sortable: false, label: 'Actions', hideMobile: true });
  }

  const data: DataTableRow[] = !query.data
    ? []
    : query.data.map((row) => {
        const firstImage =
          row.imageUrlsSigned && row.imageUrlsSigned.length > 0 && row.imageUrlsSigned[0].signedUrl;
        return {
          id: String(row.id),
          image: firstImage ? (
            <ImageTableColumn alt={row.name} src={firstImage} />
          ) : (
            <NoImageUploaded height={'60px'} width={'60px'} />
          ),
          name: row.name,
          description: (
            <div className={'text-truncate text-truncate-clamp-2'}>{row.description}</div>
          ),
          productClass: row.productClass.name,
          productType: row.productType.name,
          actions: (
            <>
              <div onClick={(e): void => e.stopPropagation()}>
                <Dropdown as={ButtonGroup}>
                  <Button
                    className={'text-nowrap'}
                    onClick={(): void => history.push(`/products/${row.id}`)}
                    size="sm"
                    variant="outline-primary"
                  >
                    <IconView className={'me-1'} /> {t('View')}
                  </Button>
                  <Dropdown.Toggle size="sm" split variant="outline-primary" />
                  <Dropdown.Menu align="end">
                    <Dropdown.Item
                      className={'text-nowrap'}
                      onClick={(): void => history.push(`/products/${row.id}`)}
                    >
                      <IconView className={'me-1'} /> {t('View')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={'text-nowrap'}
                      onClick={(): void => showEditModal(row.id)}
                    >
                      <IconEdit className={'me-1'} /> {t('Edit')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={'text-nowrap'}
                      onClick={async (): Promise<Product | undefined> =>
                        await mutation.mutateAsync(row.id)
                      }
                    >
                      <IconHide className={'me-1'} />{' '}
                      {props.showOnlyHidden ? t('Make active') : t('Hide')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={'text-nowrap'}
                      onClick={(): void => showDeleteConfirmModal(row.id)}
                    >
                      <IconDelete className={'me-1'} />
                      {t('Delete')}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </>
          ),
        };
      });
  return (
    <>
      <DataTable
        data={data}
        headers={headers}
        isLoading={query.isLoading}
        isPreviousData={query.isPreviousData}
        limit={limit}
        messageNoData={t('productsTable|noProductsFoundMessage')}
        offset={offset}
        onLimitChange={(newLimit): void => setLimit(newLimit)}
        onOffsetChange={(newOffset): void => setOffset(newOffset)}
        onRowClick={(row): void => history.push(`/products/${row.id}`)}
        onSearchChange={(search): void => setSearch(search)}
        onSortDirectionChange={(newSortDirection): void => setSortDirection(newSortDirection)}
        onSortFieldChange={(newSortField): void => setSortField(newSortField)}
        search={search}
        showSearch={true}
        sortDirection={sortDirection}
        sortField={sortField}
      />
      {editModalVisible && (
        <DiagnosisRegimeModal editId={editId} onClose={(): void => setEditModalVisible(false)} />
      )}
      <ConfirmModal
        busy={isDeleting}
        cancelLabel={t('Cancel')}
        cancelOnClick={(): void => setDeleteConfirmModalVisible(false)}
        errorMessage={deleteConfirmModalErrorMessage}
        okLabel={t(`Yes, delete this {{value}}`, { value: t('product') })}
        okOnClick={deleteItem}
        okVariant={ButtonVariant.Danger}
        title={t(`Delete this {{value}}`, { value: t('product') })}
        visible={deleteConfirmModalVisible}
      >
        {t('Are you sure you want to delete this {{value}}?', { value: t('product') })}
      </ConfirmModal>
    </>
  );
};

export default ProductsTable;
