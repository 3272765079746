// Copyright © 2023 CATTLEytics Inc.

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Modal, { Props as ModalProps } from '../common/components/Modal';
import { UserSchedule } from '../shared';
import ScheduleUserForm from './ScheduleUserForm';

type Props = Pick<ModalProps, 'onClose'> & {
  onSuccess?: (data?: UserSchedule[]) => void;
  selectedDate?: Date;
};

export function ScheduleUserModal({ selectedDate, onClose, onSuccess }: Props): JSX.Element {
  const { t } = useTranslation();
  const title = useMemo(() => t('Create User Schedule'), [t]);
  return (
    <Modal onClose={onClose} size={'lg'} title={title} visible={true}>
      <ScheduleUserForm onCancel={onClose} onSuccess={onSuccess} selectedDate={selectedDate} />
    </Modal>
  );
}
