// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';

import { TYPES } from '../../../types';
import AgeClassBadge from '../../common/components/AgeClassBadge';
import AlertError from '../../common/components/AlertError';
import AnimalMilkingStatusBadge from '../../common/components/AnimalMilkingStatusBadge';
import AnimalReproductionStatusBadge from '../../common/components/AnimalReproductionStatusBadge';
import Button, { ButtonVariant } from '../../common/components/Button';
import DataTable, { DataTableHeader, DataTableRow } from '../../common/components/DataTable';
import DateTime from '../../common/components/DateTime';
import LactationNumberBadge from '../../common/components/LactationNumberBadge';
import TablePlaceholder from '../../common/components/TablePlaceholder';
import Tooltip from '../../common/components/Tooltip';
import { AnimalReproductionStatus, Sort } from '../../common/enums';
import { AnimalStatus } from '../../common/enums/animalStatus';
import AnimalService from '../../common/services/animalService';
import { IconEvent, IconTag, IconView } from '../../common/utilities';
import EventDetailModal from '../../events/components/EventDetailModal';
import { QueryKey } from '../../shared';
import { AnimalMilkingStatus, buildAgeClassCode } from '../../shared';
import AnimalTableImage from './AnimalTableImage';

interface Props {
  filters?: Record<string, string | undefined>;

  /**
   * Number of events to show.
   */
  initialLimit?: number;

  /**
   * Number of events to skip.
   */
  initialOffset?: number;

  // Table sort direction.
  initialSortDirection?: Sort;

  // Table sort field.
  initialSortField?: string;

  /**
   * Whether to show dead animals
   */
  isDead?: boolean;

  /**
   * Whether to show animals that have been sold
   */
  isSold?: boolean;

  /**
   * Pen ID to filter animals by
   */
  penId?: string;
}

/**
 * A filterable list of animals.
 */
const AnimalTable = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const animalService = useInjection<AnimalService>(TYPES.animalService);

  const [detailId, setDetailId] = useState<number>();
  const [detailModalVisible, setDetailModalVisible] = useState<boolean>(false);

  const history = useHistory();

  const defaultStatus = [];

  if (props.isDead && props.isSold) {
    defaultStatus.push(AnimalStatus.DeadAndSold);
  } else if (props.isDead) {
    defaultStatus.push(AnimalStatus.Dead);
  } else if (props.isSold) {
    defaultStatus.push(AnimalStatus.Sold);
  } else {
    defaultStatus.push(AnimalStatus.Active);
  }

  const defaultFilters: Record<string, string | undefined> = {
    reproductionStatus: [
      AnimalReproductionStatus.Bred,
      AnimalReproductionStatus.Open,
      AnimalReproductionStatus.Pregnant,
      AnimalReproductionStatus.Unknown,
    ].join(','),
    milkingStatus: ['lactating', AnimalMilkingStatus.Dry, AnimalMilkingStatus.Unknown].join(','),
    status: defaultStatus.join(','),
    pen: props.penId,
    includeReferenceAnimals: String(false),
  };

  const [limit, setLimit] = useState<number>(props.initialLimit ?? 25);
  const [offset, setOffset] = useState<number>(props.initialOffset ?? 0);
  const [sortField, setSortField] = useState<string>(props.initialSortField ?? 'primaryTag');
  const [sortDirection, setSortDirection] = useState<Sort>(
    props.initialSortDirection ?? Sort.Ascending,
  );
  const [filters, setFilters] = useState<Record<string, string | undefined>>(
    props.filters ? { ...defaultFilters, ...props.filters } : defaultFilters,
  );

  const query = useQuery(
    [QueryKey.Animals, limit, offset, filters, sortField, sortDirection],
    () =>
      animalService.getAnimals({
        ...filters,
        ...{
          limit: String(limit),
          offset: String(offset),
          sortField: sortField,
          sortDirection: String(sortDirection),
        },
      }),
    { keepPreviousData: true },
  );

  const updateFilterStatus = (key: string, status: string, enabled: boolean): void => {
    const newFilters = { ...filters };
    const existingFilter = newFilters[key];
    let statuses = existingFilter ? existingFilter.split(',') : [];

    if (enabled) {
      statuses.push(status);
      statuses = [...new Set(statuses)];
    } else {
      statuses = statuses.filter((s) => status !== s);
    }
    newFilters[key] = statuses.join(',');
    setFilters(newFilters);
    setOffset(0);
  };

  const showDetailModal = (animalEventId: number): void => {
    setDetailId(animalEventId);
    setDetailModalVisible(true);
  };

  if (query.isLoading) {
    return <TablePlaceholder />;
  }

  if (query.isError) {
    return <AlertError message={t('common|animalRetrievalError')} />;
  }

  const headers: DataTableHeader[] = [
    {
      name: 'image',
      hideLabel: true,
      label: '',
    },
    {
      name: 'primaryTag',
      label: t('animalTable|primaryTagLabel'),
      infoTooltip: t('animalTable|primaryTagInfoTooltip'),
      classNameMobile: 'col-6',
    },
    {
      name: 'name',
      label: t('animalTable|nameLabel'),
      infoTooltip: t('animalTable|nameInfoTooltip'),
      classNameMobile: 'col-6',
    },
    {
      name: 'lactationNumber',
      label: t('animalTable|lactationGroupLabel'),
      hideMobile: true,
      sortable: true,
    },
    {
      name: 'reproductionStatus',
      infoTooltip: t('animalTable|reproductionStatusInfoTooltip'),
      label: t('animalTable|reproductionStatusLabel'),
      hideMobile: false,
      sortable: true,
    },
    {
      name: 'milkingStatus',
      infoTooltip: t('animalTable|milkingStatusInfoTooltip'),
      label: t('animalTable|milkingStatusLabel'),
      hideMobile: false,
      sortable: true,
    },
    {
      name: 'status',
      infoTooltip: t('animalTable|statusInfoTooltip'),
      label: t('animalTable|statusLabel'),
      hideMobile: false,
      sortable: true,
    },
    {
      name: 'daysInMilk',
      infoTooltip: t('animalTable|dimInfoTooltip'),
      label: t('animalTable|dimLabel'),
      hideMobile: false,
      sortable: true,
    },
    {
      name: 'event',
      label: t('animalTable|eventLabel'),
      infoTooltip: t('animalTable|animalRecentEventTooltip'),
      hideMobile: true,
      sortable: false,
    },
    {
      name: 'testData',
      label: t('animalTable|testDataLabel'),
      infoTooltip: t('animalTable|animalRecentTestDataTooltip'),
      hideMobile: true,
      sortable: false,
    },
    {
      name: 'pen',
      label: t('animalTable|penLabel'),
      infoTooltip: t('animalTable|animalCurrentPenTooltip'),
      hideMobile: true,
      sortable: false,
    },
    {
      name: 'milk',
      label: t('animalTable|milkLabel'),
      infoTooltip: t('animalTable|amtOfMilkDescription'),
      hideMobile: true,
      sortable: false,
    },
    {
      name: 'fat',
      label: t('animalTable|fatLabel'),
      infoTooltip: t('animalTable|amtOfFatDescription'),
      hideMobile: true,
      sortable: false,
    },
    {
      name: 'actions',
      sortable: false,
      label: t('animalTable|actionsLabel'),
      hideMobile: true,
    },
  ];

  const data: DataTableRow[] = !query.data
    ? []
    : query.data.map((animal) => {
        const mp = animal.animalTestDayLatestMilkProperties;
        return {
          id: String(animal.id),
          image: <AnimalTableImage animal={animal} />,
          primaryTag: animal.primaryTag ? (
            <>
              <IconTag className={'me-1'} style={{ fontSize: '12px' }} />
              {animal.primaryTag}
            </>
          ) : (
            '-'
          ),
          name: animal.name,
          ageClass: <AgeClassBadge ageClass={buildAgeClassCode(animal)} />,
          lactationNumber: animal.lactationNumber ? (
            <LactationNumberBadge lactationNumber={animal.lactationNumber} />
          ) : (
            '-'
          ),
          reproductionStatus: (
            <AnimalReproductionStatusBadge
              status={animal.reproductionStatus as AnimalReproductionStatus}
              style={{ fontSize: '1rem' }}
            />
          ),
          milkingStatus: (
            <AnimalMilkingStatusBadge status={animal.milkingStatus} style={{ fontSize: '1rem' }} />
          ),
          status: animal.status,
          daysInMilk: String(animal.daysInMilk ?? '-'),
          event: animal.animalEventLatest && (
            <Button
              className={'p-0 text-start'}
              onClick={(e): void => {
                e.preventDefault();
                e.stopPropagation();
                if (animal.animalEventLatest?.id) {
                  showDetailModal(animal.animalEventLatest.id);
                }
              }}
              variant={'link'}
            >
              <span className={'text-start text-nowrap'}>
                <IconEvent className={'me-1'} />
                <DateTime date={animal.animalEventLatest?.eventDateTime} hideTime={true} />{' '}
              </span>
              {animal.animalEventLatest?.animalEventType?.name}
              {animal.animalEventLatest?.notes ? <br /> : null}
              {animal.animalEventLatest?.notes}
            </Button>
          ),
          testData: <DateTime date={animal.animalTestDayLatest?.testDate} hideTime={true} />,
          pen: (
            <Tooltip tooltip={`Home Pen: ${animal.homePen?.name}`}>
              {animal.currentPen?.name}
            </Tooltip>
          ),
          milk: mp?.quantity ? `${mp.quantity} lbs` : '-',
          fat: mp?.fatPct ? `${mp.fatPct}%` : '-',
          actions: (
            <Link to={`/animals/${animal.id}`}>
              <Button size={'sm'} variant={ButtonVariant.OutlinePrimary}>
                <IconView className={'me-1'} />
                {t('animalTable|viewText')}
              </Button>
            </Link>
          ),
        };
      });

  const reproStatuses = Object.values(AnimalReproductionStatus);
  //@TODO fix this
  //const milkingStatuses = Object.values(AnimalMilkingStatus);
  const milkingStatuses = ['lactating', 'dry', 'unknown'];
  const statuses = Object.values(AnimalStatus);

  return (
    <>
      <div className={'d-flex flex-wrap flex-start gap-2 '}>
        <Card body className={'mb-3'}>
          <strong>{t('animalTable|reproductionLabel')}:</strong>
          <div className={'d-flex flex-wrap justify-content-md-between'}>
            {reproStatuses.map((status) => (
              <Form.Switch
                checked={filters.reproductionStatus?.split(',').includes(status)}
                className={'me-3'}
                id={`filter-reproduction-${status}`}
                key={`filter-reproduction-${status}`}
                label={t(`animalTable|reproductionLabels|${status}`)}
                onChange={(e): void =>
                  updateFilterStatus('reproductionStatus', status, e.target.checked)
                }
              />
            ))}
          </div>
        </Card>
        <Card body className={'mb-3'}>
          <strong>{t('animalTable|milkingLabel')}:</strong>
          <div className={'d-flex flex-wrap justify-content-md-between'}>
            {milkingStatuses.map((status) => (
              <Form.Switch
                checked={filters.milkingStatus?.split(',').includes(status)}
                className={'me-3'}
                id={`filter-milking-${status}`}
                key={`filter-milking-${status}`}
                label={t(`animalTable|milkingLabels|${status}`)}
                onChange={(e): void =>
                  updateFilterStatus('milkingStatus', status, e.target.checked)
                }
              />
            ))}
          </div>
        </Card>
        <Card body className={'mb-3'}>
          <strong>{t('animalTable|otherLabel')}:</strong>
          <div className={'d-flex flex-wrap justify-content-md-between'}>
            {statuses.map((status) => (
              <Form.Switch
                checked={filters.status?.split(',').includes(status)}
                className={'me-3'}
                id={`filter-status-${status}`}
                key={`filter-status-${status}`}
                label={t(`animalTable|statusLabels|${status}`)}
                onChange={(e): void => updateFilterStatus('status', status, e.target.checked)}
              />
            ))}
            <Form.Switch
              checked={filters.includeReferenceAnimals === 'true'}
              className={'me-3'}
              label={t(`animalTable|statusLabels|reference`)}
              onChange={(e): void => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  includeReferenceAnimals: String(e.target.checked),
                }));
                setOffset(0);
              }}
            />
          </div>
        </Card>
      </div>
      <DataTable
        className={'mt-3'}
        data={data}
        headers={headers}
        isLoading={query.isLoading}
        isPreviousData={query.isPreviousData}
        limit={limit}
        messageNoData={t('animalTable|noDataMessage')}
        offset={offset}
        onLimitChange={(newLimit): void => setLimit(newLimit)}
        onOffsetChange={(newOffset): void => setOffset(newOffset)}
        onRowClick={(row): void => history.push(`/animals/${row.id}`)}
        onSortDirectionChange={(newSortDirection): void => setSortDirection(newSortDirection)}
        onSortFieldChange={(newSortField): void => setSortField(newSortField)}
        sortDirection={sortDirection}
        sortField={sortField}
      />
      {detailModalVisible && (
        <EventDetailModal
          animalEventId={detailId as number}
          onClose={(): void => setDetailModalVisible(false)}
        />
      )}
    </>
  );
};

export default AnimalTable;
