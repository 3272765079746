// Copyright © 2023 CATTLEytics Inc.

import { startOfToday, subDays } from 'date-fns';
import { resolve } from 'inversify-react';
import React from 'react';
import { Accordion, Table } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';

import { TYPES } from '../../../types';
import Spinner from '../../common/components/Spinner';
import {
  BreedingProgram as BreedingProgramEntity,
  BreedingProgramStats as BreedingProgramStatsEntity,
  BreedingProgramStatsRequestParams,
} from '../../shared';
import BreedingProgramService from '../services/breedingProgramService';

/**
 * @description Defines the input properties for the BreedingProgramStats component.
 * @interface Props
 */
interface Props extends WithTranslation {
  /**
   * @description Breeding program.
   * @type {BreedingProgram}
   * @memberOf Props
   */
  breedingProgram: BreedingProgramEntity;

  /**
   * @description The definition of the stats to display.
   * @type {Record<string, BreedingProgramStatsRequestParams>}
   * @memberof Props
   */
  statsDefinition?: Record<string, BreedingProgramStatsRequestParams>;
}

/**
 * @description State definitions for BreedingProgramStats component.
 * @interface State
 */
interface State {
  /**
   * @description Indicates the component is loading.
   * @type {boolean}
   * @memberof State
   */
  loading: boolean;

  /**
   * @description The stats.
   * @type {Record<string, BreedingProgramStatsEntity>}
   * @memberof State
   */
  stats?: Record<string, BreedingProgramStatsEntity>;
}

/**
 * @description Breeding program statistics component.
 * @const BreedingProgramStats
 * @return {JSX.Element}
 */
class BreedingProgramStats extends React.Component<Props, State> {
  /**
   * Creates an instance of BreedingProgramStats.
   * @param {Props} props The input properties.
   * @memberof BreedingProgramStats
   */
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  /**
   * @description Service used for accessing breeding plan data.
   * @private
   * @type {BreedingPlanService}
   * @memberof BreedingPlanManage
   */
  @resolve(TYPES.breedingProgramService)
  private breedingProgramService!: BreedingProgramService;

  /**
   * @description Fetches the stats for the program when the component mounts.
   * @return {Promise<void>}
   * @memberof BreedingProgramStats
   */
  async componentDidMount(): Promise<void> {
    const today = startOfToday();

    const statDef = this.props.statsDefinition ?? {
      last30: {
        startDate: subDays(today, 30),
      },
      last90: {
        startDate: subDays(today, 90),
      },
      allTime: {
        startDate: new Date(1970, 1, 1),
      },
    };

    const stats = await this.breedingProgramService.getBreedingProgramStatistics(
      this.props.breedingProgram.id,
      statDef,
    );

    this.setState({
      loading: false,
      stats,
    });
  }

  /**
   * @description Renders the component.
   * @return {JSX.Element} The rendered element.
   * @memberof BreedingProgramStats
   */
  render(): JSX.Element | null {
    if (this.state.loading) {
      return <Spinner />;
    } else if (!this.state.stats) {
      return null;
    }

    const renderStatValue = (stat: string | number | null | undefined): string => {
      if (stat === null || stat === undefined) {
        return this.props.t('N/A');
      }

      return stat.toString();
    };

    return (
      <Accordion>
        {Object.entries(this.state.stats).map(([statName, statValue], statIndex) => {
          return (
            <Accordion.Item eventKey={`${statIndex}`} key={statIndex}>
              <Accordion.Header>{this.props.t(statName)}</Accordion.Header>
              <Accordion.Body>
                <Table bordered responsive size="sm" striped>
                  <thead>
                    <tr>
                      <th>{this.props.t('Metric')}</th>
                      <th>{this.props.t('Value')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {statValue.stats.map((statRecord, statRecordIndex) => {
                      return (
                        <tr key={statRecordIndex}>
                          <td>{statRecord.description}</td>
                          <td>{renderStatValue(statRecord.value)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    );
  }
}

export default withTranslation()(BreedingProgramStats);
