// Copyright © 2024 CATTLEytics Inc.

/**
 * WARNING!
 *
 * DO NOT EDIT THIS FILE
 *
 * CHANGES WILL BE OVERWRITTEN!
 *
 * Make changes in packages/shared and run npm run copy-shared-to-packages
 */

/**
 * Converts a lactation number to a lactation group (>= 3 will display as '3+')
 */
export const buildLactationGroup = (lactationNumber: number | undefined): string => {
  if (!lactationNumber) {
    return '';
  }
  return lactationNumber >= 3 ? '3+' : `${lactationNumber}`;
};
