// Copyright © 2023 CATTLEytics Inc.

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import AlertErrorForModal from '../common/components/AlertErrorForModal';
import AlertSuccessForModal from '../common/components/AlertSuccessForModal';
import { ButtonVariant } from '../common/components/Button';
import ButtonModalCancel from '../common/components/ButtonModalCancel';
import ButtonModalDelete from '../common/components/ButtonModalDelete';
import ButtonModalSave from '../common/components/ButtonModalSave';
import ConfirmModal from '../common/components/ConfirmModal';
import FormFieldWrapper from '../common/components/FormFieldWrapper';
import { getTimeString, getTimeValue } from '../common/components/TimeSelect';
import { IconDelete } from '../common/utilities';
import { api } from '../common/utilities/api';
import { useSettingsScheduleContext } from '../settings/context/ScheduleContext';
import { ApiResourceV1, HttpMethod, QueryKey, Shift, UserSchedule } from '../shared';
import { ColorPicker, Colors } from './components/ColorPicker';
import TimeSelectRange from './TimeSelectRange';

export type EditableShift = Pick<
  Shift,
  | 'endTimeHour'
  | 'endTimeMinutes'
  | 'name'
  | 'startTimeHour'
  | 'startTimeMinutes'
  | 'color'
  | 'allowSwap'
> & {
  id?: Shift['id'];
};

/**
 * Returns set of upcoming UserSchedules for the selected shift.
 */
async function getUpcomingSchedulesByShift(
  selectedShift: EditableShift | Shift,
): Promise<UserSchedule[]> {
  return api<UserSchedule[]>(HttpMethod.Get, ApiResourceV1.UserSchedules, {
    params: {
      limit: String(100_000),
      shiftIds: String(selectedShift.id),
      dateStart: new Date().toISOString(),
    },
  });
}

/**
 * Removes the schedule
 */
async function deleteSchedule(schedule: UserSchedule): Promise<void> {
  return api(HttpMethod.Delete, `${ApiResourceV1.UserSchedules}/${schedule.id}`, {});
}

/**
 * Removes the shift
 */
async function deleteShift(selectedShift: EditableShift | Shift): Promise<void> {
  return api(HttpMethod.Delete, `${ApiResourceV1.Shifts}/${selectedShift.id}`, {});
}

/**
 * Updates the shift or inserts it.
 */
async function editShift(
  selectedShift: EditableShift | Shift,
  body: Partial<Shift>,
): Promise<Shift> {
  let method = HttpMethod.Post;
  let path: string = ApiResourceV1.Shifts;
  if (selectedShift.id) {
    method = HttpMethod.Patch;
    path = `${path}/${selectedShift.id}`;
  }
  return api(method, path, {
    body: body,
  });
}

export const defaultNewShift: EditableShift = {
  startTimeHour: 6,
  startTimeMinutes: 0,
  name: '',
  endTimeHour: 12,
  endTimeMinutes: 0,
  color: Colors[0],
  allowSwap: false,
};

let successTimer: ReturnType<typeof setInterval>;

type Props = {
  onCancel?: () => void;
  onSuccess?: (data?: EditableShift) => void;
  selectedShift: EditableShift;
};

export function ManageShiftForm({ onSuccess, onCancel, selectedShift }: Props): JSX.Element {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [name, setName] = useState(selectedShift.name);
  const [startTimeHour, setStartTimeHour] = useState(selectedShift.startTimeHour);
  const [startTimeMinutes, setStartTimeMinutes] = useState(selectedShift.startTimeMinutes);
  const [endTimeHour, setEndTimeHour] = useState(selectedShift.endTimeHour);
  const [endTimeMinutes, setEndTimeMinutes] = useState(selectedShift.endTimeMinutes);
  const [color, setColor] = useState(selectedShift.color ?? Colors[0]);

  const [deleteModalOptions, setDeleteModalOptions] = useState<{
    errorMessage?: string;
    isDeleting: boolean;
    visible: boolean;
  }>({
    visible: false,
    isDeleting: false,
  });
  const [allowSwap, setAllowSwap] = useState<boolean>(selectedShift.allowSwap);
  const { featureFlags } = useSettingsScheduleContext();

  const startTime = useMemo(
    () => getTimeString(startTimeHour, startTimeMinutes, t('timeSelect|endOfDay')),
    [startTimeHour, startTimeMinutes, t],
  );
  const endTime = useMemo(
    () => getTimeString(endTimeHour, endTimeMinutes, t('timeSelect|endOfDay')),
    [endTimeHour, endTimeMinutes, t],
  );

  const onEndTimeChange = useCallback(
    (time: Option) => {
      const { hour, minutes } = getTimeValue(time, t('timeSelect|endOfDay'));
      setEndTimeHour(hour);
      setEndTimeMinutes(minutes);
    },
    [t],
  );

  const onStartTimeChange = useCallback(
    (time: Option) => {
      const { hour, minutes } = getTimeValue(time, t('timeSelect|endOfDay'));
      setStartTimeHour(hour);
      setStartTimeMinutes(minutes);
    },
    [t],
  );

  const mutation = useMutation<Shift | undefined>(
    () => {
      return editShift(selectedShift, {
        name,
        startTimeHour,
        startTimeMinutes,
        endTimeHour,
        endTimeMinutes,
        color,
        allowSwap,
      });
    },
    {
      onSuccess: async (data) => {
        onSuccess?.(data);
        await queryClient.invalidateQueries([QueryKey.Shifts]);
      },
    },
  );

  const { mutateAsync: deleteMutation } = useMutation(
    async () => {
      // deletes the shift.
      // check and prompt about deleting all upcoming userSchedules

      const upcomingSchedules = await getUpcomingSchedulesByShift(selectedShift);

      if (upcomingSchedules.length > 0) {
        // mark all UserSchedules as deleted.
        for (const schedule of upcomingSchedules) {
          await deleteSchedule(schedule);
        }
      }

      return deleteShift(selectedShift);
    },
    {
      onSuccess: async () => {
        onSuccess?.();
        await queryClient.invalidateQueries([QueryKey.Shifts]);
      },
    },
  );

  async function onDelete(): Promise<void> {
    // show a confirmation modal, and warn if there are upcoming schedules
    const upcomingSchedules = await getUpcomingSchedulesByShift(selectedShift);
    setDeleteModalOptions((prev) => ({
      ...prev,
      visible: true,
      errorMessage:
        upcomingSchedules.length > 0
          ? t('manageShiftForm|deleteShiftWithSchedules', {
              count: upcomingSchedules.length,
            })
          : undefined,
    }));
  }

  const onFormSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
      event.preventDefault();
      event.stopPropagation();
      clearInterval(successTimer);
      setSuccessMessage('');

      const form = event.currentTarget;
      const valid = form.checkValidity();

      // mark the form as having its validity checked
      setValidated(true);

      if (!valid) {
        return;
      }

      setErrorMessage('');
      try {
        await mutation.mutateAsync();
        form.classList.remove('was-validated');
        form.reset();
        successTimer = setInterval(() => setSuccessMessage(''), 5000);
      } catch (err) {
        setErrorMessage(t('manageShiftForm|shiftNotSavedError'));
      }
    },
    [t, mutation],
  );

  useEffect(() => {
    const { endTimeHour, endTimeMinutes, name, startTimeHour, startTimeMinutes } = selectedShift;
    clearInterval(successTimer);
    setSuccessMessage('');
    setName(name);
    setStartTimeHour(startTimeHour);
    setStartTimeMinutes(startTimeMinutes);
    setEndTimeHour(endTimeHour);
    setEndTimeMinutes(endTimeMinutes);

    return (): void => clearInterval(successTimer);
  }, [selectedShift]);

  return (
    <Form noValidate={true} onSubmit={onFormSubmit} validated={validated}>
      <div className="h5">{t(selectedShift.id ? 'Edit' : 'Create')}</div>
      <FormFieldWrapper
        controlId="formName"
        invalidFeedback={t('common|fieldRequiredFeedback')}
        label={t('Name')}
      >
        <Form.Control
          minLength={1}
          name={'name'}
          onChange={(e): void => setName(e.target.value)}
          placeholder={t('Example: Morning Shift')}
          required
          value={name}
        />
      </FormFieldWrapper>

      <TimeSelectRange
        endTime={endTime}
        onEndTimeChange={onEndTimeChange}
        onStartTimeChange={onStartTimeChange}
        required={true}
        startTime={startTime}
        validateEarly={true}
        validated={validated}
      />

      <ColorPicker
        label={t('manageShiftForm|colorPickerLabel')}
        name="color"
        onChange={setColor}
        value={color}
      />

      {featureFlags.allowSwapping && (
        <FormFieldWrapper controlId="formSwap" label={t('manageShiftForm|swappableSwitchLabel')}>
          <Form.Switch checked={allowSwap} onChange={(): void => setAllowSwap(!allowSwap)} />
        </FormFieldWrapper>
      )}

      <AlertErrorForModal message={errorMessage} />
      <AlertSuccessForModal message={successMessage} />

      <ConfirmModal
        busy={deleteModalOptions.isDeleting}
        cancelLabel={t('manageShiftForm|deleteConfirmation|cancelLabel')}
        cancelOnClick={(): void => setDeleteModalOptions((prev) => ({ ...prev, visible: false }))}
        errorMessage={deleteModalOptions.errorMessage}
        okIcon={IconDelete}
        okLabel={t(`manageShiftForm|deleteConfirmation|okLabel`, {
          value: t('entity|shift').toLowerCase(),
        })}
        okOnClick={deleteMutation}
        okVariant={ButtonVariant.Danger}
        title={t(`manageShiftForm|deleteConfirmation|title`, {
          value: t('entity|shift').toLowerCase(),
        })}
        visible={deleteModalOptions.visible}
      >
        {t('manageShiftForm|deleteConfirmation|message', {
          value: t('entity|shift').toLowerCase(),
        })}
      </ConfirmModal>

      <div className="modal-footer modal-footer-in-form">
        <ButtonModalCancel disabled={mutation.isLoading} onClick={onCancel} />
        <ButtonModalDelete
          busy={mutation.isLoading}
          disabled={mutation.isLoading}
          onClick={onDelete}
        />
        <ButtonModalSave busy={mutation.isLoading} disabled={mutation.isLoading} />
      </div>
    </Form>
  );
}
