// Copyright © 2023 CATTLEytics Inc.

import { inject, injectable } from 'inversify';

import { TYPES } from '../../../types';
import type Logger from '../../logger/logger';
import { TaskStatus } from '../../shared';
import ActiveTreatment from '../entities/activeTreatment';
import { AnimalEventType as AnimalEventTypeEnum, DecisionTreeAnswers } from '../enums';
import Api2Service from './api2Service';
import DataService from './dataService';

const path = '/v1/active-treatments';

export interface PostPayload {
  answers: DecisionTreeAnswers;
  completed: boolean;
  currentStep: number;
  events: PostPayloadEvent[];
  status: TaskStatus;
}

export interface PatchPayload extends PostPayload {
  id: number;
}

export interface PostPayloadEvent {
  animalEventTypeId: AnimalEventTypeEnum;
  animalIds: number[];
  diagnosisId: number;
  diagnosisRegimeDetailId: number;
  diagnosisRegimeId: number;
  eventDateTime: string;
  notes: string;
  productDosage: number;
  productDosageFactor: number;
  productId?: number;
  productLocation?: string;
  productRoute: string;
  userId: number;
}

/**
 * Service for managing animal activeTreatments.
 */
@injectable()
export default class ActiveTreatmentService implements DataService<ActiveTreatment> {
  private logger: Logger;

  private apiService: Api2Service;

  /**
   * Creates an instance of ActiveTreatmentService.
   *
   * @param logger Logger instance.
   * @param apiService API Service instance
   */
  constructor(
    @inject(TYPES.logger) logger: Logger,
    @inject(TYPES.api2Service) apiService: Api2Service,
  ) {
    this.logger = logger;
    this.apiService = apiService;
  }

  /**
   * Retrieve a list of Animal ActiveTreatments
   * @return ActiveTreatment objects
   */
  async list(params?: Record<string, string>): Promise<ActiveTreatment[]> {
    const result = await this.apiService.get<ActiveTreatment[]>(path, params);
    if (!result) {
      return [];
    }

    const data = result as ActiveTreatment[];
    data.forEach((item) => this.cast(item));
    return data;
  }

  async get(id: number, params?: Record<string, string>): Promise<ActiveTreatment | undefined> {
    const result = await this.apiService.get<ActiveTreatment>(`${path}/${id}`, params);
    if (!result) {
      return undefined;
    }

    const data = result as ActiveTreatment;
    this.cast(data);
    return data;
  }

  async post(attributes: PostPayload): Promise<ActiveTreatment | undefined> {
    const result = await this.apiService.post<ActiveTreatment>(`${path}`, attributes);
    if (!result) {
      return undefined;
    }
    this.cast(result as ActiveTreatment);
    return result;
  }

  async patch(id: number, attributes: Partial<PatchPayload>): Promise<ActiveTreatment | undefined> {
    // make sure record identifier is included
    attributes.id = id;

    const result = await this.apiService.patch(`${path}/${id}`, attributes);
    if (!result) {
      return undefined;
    }

    const data = result as ActiveTreatment;
    this.cast(data);
    return data;
  }

  async delete(id: number): Promise<null> {
    return await this.apiService.delete(`${path}/${id}`);
  }

  /**
   * Casts fields into javascript types.
   * @param item
   * @return cast object
   */
  cast(item: ActiveTreatment): void {
    if (!item) {
      return;
    }
  }
}
