// Copyright © 2023 CATTLEytics Inc.

import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconCancel, IconEdit, IconSave } from '../utilities';
import Button, { ButtonVariant } from './Button';

/**
 * @description Properties for the @see EditInPlace component.
 * @export
 * @interface Props
 */
export interface Props {
  /**
   * @description Children of this component.
   * @type {React.ReactNode}
   * @memberof Props
   */
  children?: React.ReactNode;

  /**
   * @description Additional classes for the component.
   * @type {string}
   * @memberOf Props
   */
  className?: string;

  /**
   * @description Key name of attribute to update.
   * @type {string}
   * @memberOf Props
   */
  name: string;

  /**
   * @description Save callback.
   * @param {string} key
   * @param {string} value
   * @memberOf Props
   */
  onSave: (key: string, value: string) => void;

  /**
   * @description Input type.
   * @type {'text' | 'email' | 'textarea'}
   * @memberOf Props
   */
  type?: 'text' | 'email' | 'textarea';

  /**
   * @description Inital value.
   * @type {string}
   * @memberOf Props
   */
  value: string;
}

/**
 * @description A component to allow modifying an input element in place.
 * @const EditInPlace
 * @param {Props} props
 * @return {JSX.Element}
 */
const EditInPlace = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  /**
   * @description If the component is in edit mode or not.
   * @const
   */
  const [editMode, setEditMode] = useState<boolean>(false);

  /**
   * @description The busy status of the save button.
   * @const
   */
  const [saveButtonBusy, setSaveButtonBusy] = useState<boolean>(false);

  /**
   * @description The temporary input value to be saved.
   * @const
   */
  const [value, setValue] = useState<string>(props.value);

  /**
   * @description The original value so we can restore it if user cancels.
   * @const
   */
  const [initialValue] = useState<string>(props.value);

  // if we are in edit mode render an input element with save and cancel buttons.
  if (editMode) {
    return (
      <React.Fragment>
        <input
          onChange={(event: ChangeEvent<HTMLInputElement>): void => setValue(event.target.value)}
          type={props.type}
          value={value}
        />
        <Button
          ariaLabel={t(`Save`)}
          busy={saveButtonBusy}
          className="mx-2 rounded-circle"
          icon={IconSave}
          onClick={async (): Promise<void> => {
            setSaveButtonBusy(true);
            await props.onSave(props.name, value);
            setSaveButtonBusy(false);
            setEditMode(false);
          }}
          size="sm"
          variant={ButtonVariant.OutlinePrimary}
        />
        <Button
          ariaLabel={t(`Cancel`)}
          className="rounded-circle"
          icon={IconCancel}
          onClick={(): void => {
            setValue(initialValue);
            setEditMode(false);
          }}
          size="sm"
          variant={ButtonVariant.OutlinePrimary}
        />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {props.children}
      <Button
        ariaLabel={t(`Edit`)}
        className="mx-2 rounded-circle"
        icon={IconEdit}
        onClick={(): void => setEditMode(true)}
        size="sm"
        variant={ButtonVariant.OutlinePrimary}
      />
    </React.Fragment>
  );
};

export default EditInPlace;
