// Copyright © 2023 CATTLEytics Inc.

import { inject, injectable } from 'inversify';

import { TYPES } from '../../../types';
import type Logger from '../../logger/logger';
import Entity from '../entities/entity';
import JsonApiDataResponse from '../interfaces/jsonApiDataResponse';
import JsonApiQueryParams, { isJsonApiQueryParams } from '../interfaces/jsonApiQueryParams';
import { getEnv, sleep } from '../utilities';
import { removeUndefined } from '../utilities/filter';

/**
 * Generic service for accessing the backend API.
 * @deprecated Use src/app/common/utilities/api.ts
 */
@injectable()
export default class Api2Service {
  private logger: Logger;

  // The HTTP endpoint address for the API.
  private readonly endpoint: string;

  // Temporary api token for authentication
  private readonly token: string;

  /**
   * Creates an instance of ApiService.
   * @param {Logger} logger Logger.
   */
  constructor(@inject(TYPES.logger) logger: Logger) {
    this.logger = logger;

    this.endpoint = getEnv('API_ENDPOINT2') ?? '';
    this.token = getEnv('API_TEMP_TOKEN') ?? '';

    this.logger.debug(`The API endpoint is '${this.endpoint}'.`);
  }

  /**
   * @inheritDoc
   */
  public deserializeArray<T extends Entity>(result: JsonApiDataResponse<T> | null): T[] {
    if (!result || !Array.isArray(result.data)) {
      return [];
    }

    // iterate over each resource object in the data array
    return result.data.map((resource) => {
      const entity: T = { ...resource.attributes };
      entity.id = parseInt(resource.id);

      // if there are relationships defined and included data then populate the data into the data model
      if (resource.relationships && result.included) {
        // iterate over all the relationships
        const keys: (keyof T)[] = Object.keys(resource.relationships);
        keys.forEach((key) => {
          if (!resource.relationships) {
            return;
          }
          const relationship = resource.relationships[key as string];

          // make sure relationship is actually populated (null foreign key can cause this)
          if (relationship.data && result.included) {
            // find the related resource object in the included data
            const relatedResource = result.included.find(
              (includedResource) =>
                includedResource.type === relationship.data.type &&
                includedResource.id === relationship.data.id,
            );

            // add the related resource object and set the id parameter
            if (relatedResource) {
              entity[key] = relatedResource.attributes as T[keyof T];
              entity[key].id = parseInt(relatedResource.id);
            }
          }
        });
      }
      return entity;
    });
  }

  /**
   * @inheritDoc
   */
  public deserialize<T extends Entity>(result: JsonApiDataResponse<T> | null): T {
    if (!result || !result.data || Array.isArray(result.data)) {
      throw Error('Could not deserialize JSON:API response.');
    }
    const entity = { ...result.data.attributes };
    entity.id = parseInt(result.data.id);

    const resource = result.data;

    // if there are relationships defined and included data then populate the data into the data model
    if (resource.relationships && result.included) {
      // iterate over all the relationships
      const keys: (keyof T)[] = Object.keys(resource.relationships);
      keys.forEach((key) => {
        if (!resource.relationships) {
          return;
        }
        const relationship = resource.relationships[key as string];

        // make sure relationship is actually populated (null foreign key can cause this)
        if (relationship.data && result.included) {
          // find the related resource object in the included data
          const relatedResource = result.included.find(
            (includedResource) =>
              includedResource.type === relationship.data.type &&
              includedResource.id === relationship.data.id,
          );

          // add the related resource object and set the id parameter
          if (relatedResource) {
            entity[key] = relatedResource.attributes as T[keyof T];
            entity[key].id = parseInt(relatedResource.id);
          }
        }
      });
    }
    return entity;
  }

  /**
   * @inheritdoc
   */
  get<T>(
    route: string,
    params?: Record<string, string> | JsonApiQueryParams,
    headers?: Record<string, string>,
  ): Promise<T | null> {
    return this.fetch(route, 'GET', params, headers);
  }

  /**
   * @inheritdoc
   */
  post<T>(
    route: string,
    body: any,
    params?: Record<string, string>,
    headers?: Record<string, string>,
  ): Promise<T | null> {
    return this.fetch<T>(route, 'POST', params, headers, body);
  }

  /**
   * @inheritdoc
   */
  put<T>(
    route: string,
    body: any,
    params?: Record<string, string>,
    headers?: Record<string, string>,
  ): Promise<T | null> {
    return this.fetch<T>(route, 'PUT', params, headers, body);
  }

  /**
   * @inheritdoc
   */
  patch<T>(
    route: string,
    body: any,
    params?: Record<string, string>,
    headers?: Record<string, string>,
  ): Promise<T | null> {
    return this.fetch<T>(route, 'PATCH', params, headers, body);
  }

  /**
   * @inheritdoc
   */
  delete(
    route: string,
    params?: Record<string, string>,
    headers?: Record<string, string>,
    body?: any,
  ): Promise<null> {
    return this.fetch<null>(route, 'DELETE', params, headers, body);
  }

  /**
   * @description Executes an HTTP request.
   * @private
   * @template T
   * @param {string} route The request route.
   * @param {string} method The request method.
   * @param {*} [body] The request body.
   * @param {Record<string, string>} [params] URL parameters.
   * @param {Record<string, string>} [headers] The request headers.
   * @return {Promise<T | null>} The deserialized response payload or null if the response was empty.
   * @memberof Api2Service
   */
  private async fetch<T>(
    route: string,
    method: string,
    params?: Record<string, string> | JsonApiQueryParams,
    headers?: Record<string, string>,
    body?: any,
  ): Promise<T | null> {
    const url = new URL(route, this.endpoint);

    await sleep(parseInt(process.env.REACT_APP_API_DELAY as string));

    if (params && isJsonApiQueryParams(params)) {
      // @deprecated
      const searchParams = new URLSearchParams();
      Object.entries(params).forEach(([key, value]) => {
        if (key === 'filter') {
          const filter = value as Record<string, string>;
          Object.entries(filter).forEach(([filterKey, filterValue]) => {
            //searchParams.append('filter', `equals(${filterKey},'${filterValue}')`),
            if (!filterValue) {
              return;
            }

            // is the filter already a function? if not then assume it is an equals function
            if (
              filterValue.match(
                /(equals|lessThan|lessOrEqual|greaterThan|greaterOrEqual|contains|startsWith|endsWith|any|has|not|or|and)\(.*\)/g,
              )
            ) {
              searchParams.append('filter', filterValue);
            } else {
              //searchParams.append('filter', `equals(${key},'${params.filter[key]}')`);
              searchParams.append('filter', `equals(${filterKey},'${filterValue}')`);
            }
          });
        } else {
          if (typeof value === 'string') {
            searchParams.append(key, value);
          } else if (Array.isArray(value)) {
            value.forEach((customValue) => searchParams.append(key, customValue));
          } else if (value) {
            Object.entries(value).forEach(([customKey, customValue]) => {
              if (typeof customValue === 'string') {
                searchParams.append(`${key}[${customKey}]`, customValue);
              } else if (Array.isArray(customValue)) {
                searchParams.append(`${key}[${customKey}]`, customValue.join(','));
              }
            });
          }
        }
      });
      url.search = searchParams.toString();
    } else {
      url.search = new URLSearchParams(removeUndefined(params)).toString();
    }
    // the URLSearchParams class URI encodes the keys and value
    // pairs which causes problems for filter[key]=value syntax.
    url.search = decodeURIComponent(url.search);

    const urlString = url.toString();
    this.logger.debug(`Fetching API URL '${urlString}' with method '${method}'.`);

    if (!headers) {
      headers = {};
    }

    let requestBody = body;
    if (body) {
      if (body instanceof FormData) {
        requestBody = body;
      } else {
        requestBody = JSON.stringify(body);

        const contentType = headers['Content-Type'];
        if (!contentType) {
          headers['Content-Type'] = 'application/json';
        }
      }
    }

    if (headers) {
      headers['X-Milkshake-Token'] = this.token;
    }

    const response = await fetch(urlString, {
      method,
      body: requestBody,
      headers,
      // this is need for session cookies
      credentials: 'include',
    });

    if (response.status === 204) {
      return null;
    }

    const data = await response.json();
    const errorDetail = data && data.length > 0 && data[0].detail ? data[0].detail : undefined;

    if (!response.ok) {
      throw new Error(
        errorDetail ??
          `fetch request failed with status '${response.status}' and statusText '${response.statusText}'.`,
      );
    }

    //const data = await response.json();
    return data as T;
  }

  /**
   * @inheritDoc
   */
  public buildFilters(params: Record<string, string>): Record<string, string> {
    const filters: Record<string, string> = {};

    if (params && Object.keys(params).length > 0) {
      Object.keys(params).map((key) =>
        params && params[key] ? (filters[`filter[${key}]`] = params[key]) : '',
      );
    }

    return filters;
  }
}
