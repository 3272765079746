// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { TYPES } from '../../../types';
import ManageListEditModal from '../../lists/components/ManageListEditModal';
import { ApiResourceV1, QueryKey } from '../../shared';
import { ProductClass } from '../entities/productClass';
import { Sort } from '../enums';
import ProductClassService from '../services/productClassService';

/**
 * Component input properties.
 */
interface Props {
  /**
   * Accessibility label.
   */
  ariaLabel?: string;

  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Whether the component should be disabled.
   */
  disabled?: boolean;

  /**
   * Whether the first item of the select should be selected as the default.
   */
  firstItemDefault?: boolean;

  /**
   * Form element name.
   */
  name?: string;

  /**
   * Input change handler.
   */
  onChange: (value: string) => void;

  /**
   * When add modal is closed
   */
  onCloseAddModal?: () => void;

  /**
   * Whether this field is required
   */
  required?: boolean;

  /**
   * Whether to display the add modal
   */
  showAddModal?: boolean;

  /**
   * Input value
   */
  value: string;
}

/**
 * Form select component for diagnoses.
 */
const ProductClassSelect = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const productClassService = useInjection<ProductClassService>(TYPES.productClassService);

  const [showAddModal, setShowAddModal] = useState<boolean>(!!props.showAddModal);
  const [previousShowAddModal, setPreviousShowAddModal] = useState<boolean>();

  const sortField = 'name';
  const sortDirection = Sort.Ascending;
  const query = useQuery<ProductClass[]>(
    [QueryKey.ProductClasses, 'list', sortField, sortDirection],
    () =>
      productClassService.list({
        sortField: sortField,
        sortDirection: sortDirection,
        limit: '1000',
      }),
    {
      enabled: !props.disabled,
      onSuccess: (data) => {
        if (!props.value && props.firstItemDefault && data.length > 0) {
          props.onChange(String(data[0].id));
        }
      },
    },
  );
  const items = query.data;
  const value = props.value ?? undefined;

  if (!!props.showAddModal !== previousShowAddModal) {
    setShowAddModal(!!props.showAddModal);
    setPreviousShowAddModal(!!props.showAddModal);
  }

  return (
    <>
      <Form.Select
        aria-label={props.ariaLabel ?? 'Product Class'}
        className={props.className}
        disabled={props.disabled}
        name={props.name ?? 'productClassId'}
        onChange={(e): void => props.onChange(e.target.value)}
        required={props.required}
        value={value}
      >
        {props.disabled ? <option value={''}>{t('Choose a product class')}</option> : null}
        {props.firstItemDefault ? (
          <>{query.isFetching ? <option value={''}>{t('common|loading')}</option> : null}</>
        ) : (
          <option value={''}>
            {query.isFetching ? t('common|loading') : t('Choose a product class')}
          </option>
        )}
        {items &&
          items.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
      </Form.Select>
      {showAddModal && (
        <ManageListEditModal
          apiResource={ApiResourceV1.ProductClasses}
          onClose={props.onCloseAddModal ?? ((): void => undefined)}
          queryKey={QueryKey.ProductClasses}
          typeName={'product class'}
        />
      )}
    </>
  );
};

export default ProductClassSelect;
