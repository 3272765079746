// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

/**
 * Defines the input properties for this component.
 */
interface Props extends WithTranslation {
  /**
   * Text to display inside image
   */
  alt: string;

  /**
   * Additional CSS classes to apply to this component
   */
  className?: string;

  /**
   * CSS height attribute for IMG
   */
  height?: string;

  /**
   * image source
   */
  src: string;

  /**
   * CSS width attribute for IMG
   */
  width?: string;
}

/**
 * A component to use when displaying an uploaded image in a form.
 * @see NoImageUploaded
 */
const ImageUploaded = (props: Props): JSX.Element => {
  const width = props.width ?? '150px';
  const height = props.height ?? '150px';
  return (
    <img
      alt={props.alt}
      className={`border rounded${props.className ? ` ${props.className}` : ''}`}
      src={props.src}
      style={{
        display: 'block',
        objectFit: 'cover',
        objectPosition: '50% 50%',
        width: width,
        height: height,
      }}
    />
  );
};

export default withTranslation()(ImageUploaded);
