// Copyright © 2023 CATTLEytics Inc.

import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { AsyncTypeahead, Highlighter, Token } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { useTranslation } from 'react-i18next';
import { BsFillSquareFill } from 'react-icons/bs';
import { useQuery } from 'react-query';

import { api } from '../../common/utilities';
import { Shift } from '../../shared';
import { ApiResourceV1, QueryKey } from '../../shared/enums';

/**
 * Component properties
 */
interface Props {
  /**
   * Loading indicator.
   */
  busy?: boolean;

  disabled?: boolean;

  /**
   * HTML ID attribute
   */
  id: string;

  /**
   * Whether to allow multiple shift selection.
   *
   * Note: this also changes the parameters sent in the onSelect callback from
   * single item to array of items.
   */
  multiple?: boolean;

  /**
   * HTML name attribute
   */
  name?: string;

  /**
   * When add modal is closed
   */
  onCloseAddModal?: () => void;

  /**
   * Callback after shift has been selected
   * @param shift
   */
  onSelect: (shift?: Shift | Shift[]) => void;

  /**
   * If this field is required.
   */
  required?: boolean;

  /**
   * Initial selected option or options.
   */
  selected?: Option | Option[];

  /**
   * Whether to display the add modal
   */
  showAddModal?: boolean;

  /**
   * Whether the form this component is in has been validated.
   * This will show invalid messages if applicable.
   * Note this does not indicate a form is valid.
   */
  validated?: boolean;
}

/**
 * Provides ability to select a shift by name or ID.
 */
export function ShiftAutocomplete(props: React.PropsWithChildren<Props>): JSX.Element {
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState<string>(
    props.selected ? String(props.selected) : '',
  );

  const limit = 10;

  const { data: shifts, isLoading: busy } = useQuery<Shift[]>(
    [QueryKey.Shifts, searchQuery, limit],
    () =>
      api('GET', ApiResourceV1.Shifts, {
        params: {
          search: searchQuery,
          limit: String(limit),
        },
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  // if selected property is not set then empty array,
  // else if selected property is an array use it,
  // else make selected property an array
  const selected = !props.selected
    ? []
    : Array.isArray(props.selected)
    ? props.selected
    : [props.selected];

  const required = props.required ?? false;

  // if the validated property is set and true (form validation was run),
  // and the field is required and the selection is empty
  const isInvalid = !!props.validated && required && selected.length === 0;

  // if the validated property is set and true (form validation was run),
  // and the field is not isInvalid
  const isValid = !!props.validated && !isInvalid;

  // The following input element is a way to trigger a javascript invalid
  // state so HTML5 form validation can be used since the input element
  // inside the typeahead component does not support this
  // if one or more items have been selected this input element will be
  // set to 1 thus satisfying the required validator
  const inputHelper = (
    <input
      aria-hidden={true}
      readOnly={true}
      required={required}
      style={{ display: 'none' }}
      tabIndex={-1}
      type={'text'}
      value={selected.length === 0 ? '' : '1'}
    />
  );

  return (
    <div className={`shift-autocomplete ${isInvalid ? 'is-invalid' : 'is-valid'}`}>
      {inputHelper}
      <AsyncTypeahead
        clearButton={true}
        disabled={props.disabled}
        id={props.id} // for styling only
        inputProps={{ required: isInvalid }}
        isInvalid={isInvalid}
        isLoading={busy || !!props.busy}
        isValid={isValid}
        labelKey={(option): string => {
          const shift = option as Shift;
          return `${shift.name}`;
        }}
        minLength={0}
        multiple={props.multiple}
        onChange={(selected): void => {
          if (props.multiple) {
            props.onSelect(selected && selected.length > 0 ? (selected as Shift[]) : undefined);
          } else {
            props.onSelect(selected && selected.length > 0 ? (selected[0] as Shift) : undefined);
          }
        }}
        onSearch={(searchQuery: string): void => {
          setSearchQuery(searchQuery);
        }}
        options={shifts ?? []}
        placeholder={'Enter a shift name'}
        renderMenuItemChildren={(option, { text }): JSX.Element => {
          const shift = option as Shift;
          const label = `${shift.name}`;
          return (
            <div
              className="d-flex justify-space-between align-items-center"
              style={{ borderBottom: '1px #ccc solid', marginBottom: '8px' }}
            >
              <div className="d-block">
                <BsFillSquareFill className="me-2" style={{ color: shift.color }} />
                <Highlighter search={text}>{label}</Highlighter>
              </div>
            </div>
          );
        }}
        renderToken={(option, { onRemove }, index): JSX.Element => {
          const shift = option as Shift;
          return (
            <Token
              key={index}
              onRemove={onRemove}
              option={option}
              style={{ backgroundColor: shift.color }}
            >
              {shift.name}
            </Token>
          );
        }}
        selected={selected}
      />
      <Form.Control.Feedback type={'invalid'}>
        {t('common|fieldRequiredFeedback')}
      </Form.Control.Feedback>
    </div>
  );
}
