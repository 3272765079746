// Copyright © 2023 CATTLEytics Inc.

import { resolve } from 'inversify-react';
import React from 'react';
import { Alert } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';

import { TYPES } from '../../../types';
import ButtonCreate from '../../common/components/ButtonCreate';
import Page from '../../common/components/Page';
import Toast, { ToastConfig } from '../../common/components/Toast';
import type Logger from '../../logger/logger';
import { BreedingPlan } from '../../shared';
import BreedingPlanService from '../services/breedingPlanService';
import BreedingPlanCreateModal from './BreedingPlanCreateModal';
import BreedingPlanList from './BreedingPlanList';

/**
 * Describes the state of the @see BreedingPlans component.
 */
export interface State {
  /**
   * @description Whether or not to show the create breeding plan modal.
   * @type {boolean}
   * @memberof State
   */
  breedingPlanCreateModalVisible: boolean;

  /**
   * @description The breeding plans
   * @type {BreedingPlan[]}
   * @memberof State
   */
  breedingPlans: BreedingPlan[];

  /**
   * @description Error indicator for breeding plans
   * @type {boolean}
   * @memberof State
   */
  breedingPlansError: boolean;

  /**
   * @description The loaded status of breeding plan data
   * @type {boolean}
   * @memberof State
   */
  breedingPlansLoading: boolean;

  /**
   * @description The display configuration for the Toast component.
   * @type {ToastConfig}
   * @memberof State
   */
  toast: ToastConfig;
}

/**
 * @description Breeding plans root component.
 * @class BreedingPlans
 * @extends {React.Component}
 */
class BreedingPlans extends React.Component<WithTranslation, State> {
  /**
   * Creates an instance of BreedingPlans.
   * @param {WithTranslation} props The component's input properties.
   * @memberof BreedingPlans
   */
  constructor(props: WithTranslation) {
    super(props);

    this.state = {
      breedingPlans: [],
      breedingPlanCreateModalVisible: false,
      breedingPlansError: false,
      breedingPlansLoading: false,
      toast: { visible: false },
    };
  }

  /**
   * @description Logger.
   * @private
   * @type {Logger}
   * @memberof Import
   */
  @resolve(TYPES.logger)
  private logger!: Logger;

  /**
   * @description Service used for accessing breeding plan.
   * @private
   * @type {BreedingPlanService}
   * @memberof BreedingPlan
   */
  @resolve(TYPES.breedingPlanService)
  private breedingPlanService!: BreedingPlanService;

  /**
   * @description Fetches the list of breeding plans when the
   *  component has mounted.
   * @returns {Promise<void>}
   * @memberof BreedingPlanList
   */
  async componentDidMount(): Promise<void> {
    this.logger.debug('Fetching available breeding plans...');

    try {
      this.setState({ breedingPlansLoading: true });

      const breedingPlans = await this.breedingPlanService.list();

      this.setState({
        breedingPlansLoading: false,
        breedingPlansError: false,
        breedingPlans: breedingPlans,
      });
    } catch (error) {
      this.logger.error(`Error loading breeding plans: ${error}`);
      this.setState({
        breedingPlansError: true,
        breedingPlansLoading: false,
        breedingPlans: [],
      });
    }
  }

  /**
   * @description Renders the component.
   * @returns {JSX.Element}
   * @memberof BreedingPlans
   */
  render(): JSX.Element {
    const plans = this.state.breedingPlans;

    const error = this.state?.breedingPlansError ? (
      <Alert variant="danger">{this.props.t('breedingPlans|planLoadError')}</Alert>
    ) : null;

    return (
      <Page
        buttons={
          <ButtonCreate
            label={this.props.t('Create')}
            onClick={(): void => this.setState({ breedingPlanCreateModalVisible: true })}
          />
        }
        title={this.props.t('Breeding Plans')}
      >
        {error}
        {!error ? (
          <BreedingPlanList
            breedingPlans={plans}
            loading={this.state.breedingPlansLoading}
            onDeleteBreedingPlan={this.handleDeleteBreedingPlan}
          />
        ) : null}
        <Toast
          onClose={(): void => this.setState({ toast: { visible: false } })}
          show={this.state.toast.visible}
        >
          {this.state.toast.message}
        </Toast>
        {this.state.breedingPlanCreateModalVisible ? (
          <BreedingPlanCreateModal
            onClose={(): void => this.setState({ breedingPlanCreateModalVisible: false })}
            onCreateBreedingPlan={this.handleCreateBreedingPlan}
          />
        ) : null}
      </Page>
    );
  }

  /**
   * @description Handles the deletion of a breeding plan.
   * @param {number} planId ID of the breeding plan to delete.
   * @returns {void}
   * @memberof BreedingPlans
   */
  handleDeleteBreedingPlan = async (planId: number): Promise<void> => {
    this.logger.debug(`Delete the breeding plan with ID: ${planId}`);
    await this.breedingPlanService.delete(planId);

    this.setState({
      toast: { visible: true, message: this.props.t('breedingPlans|deleteSuccessful') },
      breedingPlans: this.state.breedingPlans.filter((plan) => plan.id !== planId),
    });
  };

  /**
   * @description Handles making the API call to create a breeding plan.
   * @param {BreedingPlan} breedingPlan
   * @returns {void}
   * @memberof BreedingPlans
   */
  handleCreateBreedingPlan = async (breedingPlan: BreedingPlan): Promise<void> => {
    this.logger.debug(`Creating a breeding plan.`);
    const newBreedingPlan = await this.breedingPlanService.post(breedingPlan);
    if (!newBreedingPlan) {
      throw Error('Could not create breeding plan');
    }
    const breedingPlans = [...this.state.breedingPlans, newBreedingPlan];

    this.setState({
      toast: { visible: true, message: this.props.t('breedingPlans|createSuccessful') },
      breedingPlans: breedingPlans,
    });
  };
}

export default withTranslation()(BreedingPlans);
