// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { withTranslation } from 'react-i18next';

import Page from '../../common/components/Page';

/**
 * Routable component for terms.
 */
const TermsPage = (): JSX.Element => {
  return <Page title="Terms" />;
};

export default withTranslation()(TermsPage);
