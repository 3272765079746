// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { withTranslation } from 'react-i18next';

import Page from '../../common/components/Page';

/**
 * Routable component for privacy.
 */
const PrivacyPage = (): JSX.Element => {
  return <Page title="Privacy" />;
};

export default withTranslation()(PrivacyPage);
