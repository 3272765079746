// Copyright © 2023 CATTLEytics Inc.

/**
 * @description JSON:API resource object types.
 * @export
 * @enum
 */
export enum JsonApiTypes {
  AnimalEvents = 'animalEvents',
  AnimalTestDays = 'animalTestDays',
  Animals = 'animals',
  BreedingPlans = 'breedingPlans',
  BreedingProgramTypes = 'breedingProgramTypes',
  BreedingPrograms = 'breedingPrograms',
  DairyLogs = 'dairyLogs',
  NoteAttachments = 'noteAttachments',
  Notes = 'notes',
  Organizations = 'organizations',
  Pens = 'pens',
  Sites = 'sites',
}
