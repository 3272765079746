// Copyright © 2024 CATTLEytics Inc.

import { isFuture } from 'date-fns';
import { useCallback, useContext, useState } from 'react';
import { EventProps } from 'react-big-calendar';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import FormFieldWrapper from '../../common/components/FormFieldWrapper';
import AuthContext from '../../common/store/auth-context';
import { useSettingsContext } from '../../common/store/useSettingsContext';
import { IconSwitcher } from '../../common/utilities';
import { User, UserSchedule, utcStartDateOfSchedule } from '../../shared';
import { RequestSwapDirection } from '../../shared/enums/requestSwapDirection';
import UserAutocomplete from '../../users/components/UserAutocomplete';
import { SchedulesCalendar } from '../SchedulesCalendar';
import { EventTooltip } from './EventTooltip';

export function EventItem({ event }: EventProps): JSX.Element {
  const auth = useContext(AuthContext);

  if (
    event.resource.schedules[0].user.id === auth.userId ||
    event.resource.schedules[0].allowSwap === false
  ) {
    return (
      <EventTooltip event={event}>
        <div className="h-100 text-decoration-line-through" style={{ cursor: 'not-allowed' }}>
          {event.title}
        </div>
      </EventTooltip>
    );
  }
  return (
    <EventTooltip event={event}>
      <div className="h-100" style={{ cursor: 'pointer' }}>
        <IconSwitcher className="me-1" />
        {event.title}
      </div>
    </EventTooltip>
  );
}

interface UserSchedulePickerProps {
  activeSchedule?: UserSchedule;
  onOneWaySelected: (userId: number) => void;
  onUserScheduleSelected: (userSchedule: UserSchedule) => void;
  swapDirection?: RequestSwapDirection;
}

export function UserSchedulePicker({
  activeSchedule,
  onOneWaySelected,
  swapDirection,
  onUserScheduleSelected,
}: UserSchedulePickerProps): JSX.Element {
  const auth = useContext(AuthContext);
  const settings = useSettingsContext();
  const { t } = useTranslation();

  const [selectedUsers, setSelectedUsers] = useState<User[] | undefined>();
  const [userFeedback, setUserFeedback] = useState<string | undefined>();

  const onUserSelect = useCallback(
    (user: User | User[] | undefined) => {
      if (user !== undefined) {
        const validUsers = Array.isArray(user) ? user : [user];
        setSelectedUsers(validUsers);

        if (swapDirection === 'take') {
          // only valid when user isnt on active schedule already
          if (validUsers[0].id === auth.userId) {
            setUserFeedback(t('userSchedulePicker|errorSelf'));
            setSelectedUsers(undefined);
            return;
          }
          if (activeSchedule && activeSchedule?.coworkers?.find((u) => u.id === validUsers[0].id)) {
            setUserFeedback(t('userSchedulePicker|errorDuplicate'));
            setSelectedUsers(undefined);
            return;
          }
          onOneWaySelected(validUsers[0].id);
          setUserFeedback(undefined);
        }
      } else {
        setSelectedUsers(undefined);
        setUserFeedback(undefined);
      }
    },
    [activeSchedule, auth.userId, onOneWaySelected, swapDirection, t],
  );

  return (
    <Form>
      <FormFieldWrapper invalidFeedback={userFeedback} label={t('User')}>
        <UserAutocomplete
          id="swapUser"
          multiple={true}
          onSelect={onUserSelect}
          selected={selectedUsers}
        />
        {userFeedback && <Form.Text className="text-danger">{userFeedback}</Form.Text>}
      </FormFieldWrapper>
      {swapDirection !== 'take' && (
        <SchedulesCalendar
          components={{
            event: EventItem,
          }}
          customEventFilter={(schedule: UserSchedule): boolean => {
            // don't allow the user to swap with themselves
            if (schedule.user?.id === auth.userId) {
              return false;
            }
            // don't allow the user to swap schedule is not allowed to swap.
            if (schedule.allowSwap === false) {
              return false;
            }
            // don't allow the user to swap if the schedule is in the past
            const startDate = schedule.shift
              ? utcStartDateOfSchedule(schedule.shift, schedule, settings.timeZone)
              : undefined;
            if (startDate === undefined || !isFuture(startDate)) {
              return false;
            }

            // don't allow the user to double schedule someone else (they already have a scheduled time for this shift type today)
            if (
              activeSchedule &&
              activeSchedule?.coworkers?.find((u) => u.id === schedule.user?.id)
            ) {
              return false;
            }

            return true;
          }}
          onSelectedSchedules={(s): void => {
            if (s && s.length > 0) {
              if (s[0].user?.id === auth.userId) {
                return;
              }
              if (s[0].allowSwap === false) {
                return;
              }

              onUserScheduleSelected(s[0]);
            }
          }}
          showGrouped={false}
          // showHistorical={false}
          style={{ minHeight: '500px' }}
          userIds={selectedUsers ? selectedUsers.map((u) => u.id) : undefined}
        />
      )}
    </Form>
  );
}
