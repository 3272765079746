// Copyright © 2023 CATTLEytics Inc.
import { FC, ImgHTMLAttributes } from 'react';

import cowImage from '../../../assets/img/cow.svg';

type Props = Omit<ImgHTMLAttributes<HTMLImageElement>, 'alt' | 'src'>;

const DefaultCowImage: FC<Props> = (props) => {
  return (
    <img
      {...props}
      alt={'Cow'}
      src={cowImage}
      style={{
        filter:
          'invert(52%) sepia(93%) saturate(2%) hue-rotate(335deg) brightness(97%) contrast(99%)',
        ...props.style,
      }}
    />
  );
};

export default DefaultCowImage;
