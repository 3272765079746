// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { TYPES } from '../../../types';
import Pen from '../../common/entities/pen';
import PenService from '../../common/services/penService';
import { QueryKey } from '../../shared';

/**
 * Component properties
 */
interface Props {
  // Loading indicator.
  busy?: boolean;

  // Additional classes to add to this component.
  className?: string;

  /**
   * An array of pen IDs that should not be displayed in the dropdown.
   */
  filterIds?: number[];

  // HTML element ID.
  id: string;

  // Whether to allow multiple pen selection.
  multiple?: boolean;

  name?: string;

  // Callback after pen has been selected
  onSelect: (pen?: Pen) => void;

  placeholder?: string;

  // If this field is required.
  required?: boolean;

  // Initial selected option.
  selected?: Option | Option[];
}

/**
 * Provides ability to select pen by name or ID.
 */
const PenAutocomplete = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const penService = useInjection<PenService>(TYPES.penService);

  const page = '100';

  const { data, isLoading: busy } = useQuery<Pen[]>([QueryKey.Pens, page], () =>
    penService.list({
      page: page,
    }),
  );

  const selected = !props.selected
    ? []
    : Array.isArray(props.selected)
    ? props.selected
    : [props.selected];

  const required = props.required ?? false;

  const pens =
    props.filterIds && data
      ? data.filter((pen) => !(props.filterIds as number[]).includes(pen.id))
      : data;

  return (
    <Typeahead
      clearButton={true}
      disabled={!!props.selected && (busy || props.busy)}
      id={props.id}
      inputProps={{ required: required }}
      isLoading={busy || props.busy}
      labelKey={(option): string => {
        const pen = option as Pen;
        return pen.name;
      }}
      multiple={props.multiple}
      onChange={(selected): void => {
        props.onSelect(selected && selected.length > 0 ? (selected[0] as Pen) : undefined);
      }}
      options={pens ?? []}
      placeholder={props.placeholder ?? t('Select a pen')}
      selected={selected}
    />
  );
};

export default PenAutocomplete;
