// Copyright © 2023 CATTLEytics Inc.
import i18next from 'i18next';

import { AnimalReproductionStatus } from '../enums';
import { AnimalStatus } from '../interfaces';
import { IconStatusBred, IconStatusOpen, IconStatusPregnant, IconStatusUnknown } from './icons';

export const getAnimalReproductionStatus = (status?: AnimalReproductionStatus): AnimalStatus => {
  switch (status?.toLowerCase()) {
    case AnimalReproductionStatus.Bred:
      return {
        icon: IconStatusBred,
        textColor: '#fff',
        label: i18next.t('Bred'),
        backgroundColor: '#FF7F50',
      };
    case AnimalReproductionStatus.Open:
      return {
        icon: IconStatusOpen,
        textColor: '#fff',
        backgroundColor: '#9932CC',
        label: 'Open',
      };
    case AnimalReproductionStatus.Pregnant:
      return {
        icon: IconStatusPregnant,
        textColor: '#fff',
        backgroundColor: '#006400',
        label: 'Pregnant',
      };
    default:
      return {
        icon: IconStatusUnknown,
        backgroundColor: '#ccc',
        textColor: '#666',
        label: 'Unknown',
      };
  }
};
