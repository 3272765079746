// Copyright © 2024 CATTLEytics Inc.

import { useQuery, UseQueryResult } from 'react-query';

import { api } from '../../common/utilities';
import { ApiResourceV1, HttpMethod, QueryKey, SettingsSchedule } from '../../shared';

function useSettings<T>({ key }: { key: string }): UseQueryResult<T> {
  const query = useQuery<T>(
    [QueryKey.Settings, key],
    () => api(HttpMethod.Get, `${ApiResourceV1.Settings}/${key}`),
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: true,
    },
  );

  return query;
}

interface UseSettingsScheduleResult {
  scheduleAllowSwapping: boolean;
  scheduleRequestApproval: boolean;
}

/**
 * Parsed settings for the schedule from the Settings API.
 */
export function useSettingsSchedule(): UseSettingsScheduleResult {
  const query = useSettings<SettingsSchedule>({ key: 'schedule' });
  return {
    // ...(query.data ? query.data : {}),
    scheduleAllowSwapping: (query.data?.allowSwapping ?? 'false') === 'true',
    scheduleRequestApproval: (query.data?.requestApproval ?? 'false') === 'true',
  };
}
