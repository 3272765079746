// Copyright © 2024 CATTLEytics Inc.

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import ButtonCreate from '../common/components/ButtonCreate';
import Page from '../common/components/Page';
import { AnimalGroupsModal } from './AnimalGroupsModal';
import { AnimalGroupsTable } from './AnimalGroupsTable';

export const AnimalGroupsPage = (): JSX.Element => {
  const { t } = useTranslation();

  const history = useHistory();

  const [animalGroupsModalVisible, setAnimalGroupsModalVisible] = useState<boolean>(false);

  return (
    <Page
      breadcrumbTitle={t('animalGroupsPage|breadcrumbTitle')}
      breadcrumbs={[{ label: t('entity|animal', { count: 2 }), to: '/animals/active' }]}
      buttons={
        <ButtonCreate
          label={t('animalGroupsPage|newGroupButtonLabel')}
          onClick={(): void => setAnimalGroupsModalVisible(true)}
        />
      }
      title={t('animalGroupsPage|title')}
    >
      <AnimalGroupsTable />
      {animalGroupsModalVisible && (
        <AnimalGroupsModal
          onClose={(): void => setAnimalGroupsModalVisible(false)}
          onSave={(animalGroupId: number): void => {
            setAnimalGroupsModalVisible(false);
            history.push(`/animals/groups/${animalGroupId}/manage`);
          }}
        />
      )}
    </Page>
  );
};
