// Copyright © 2024 CATTLEytics Inc.

import { useTranslation } from 'react-i18next';

import Button from '../../common/components/Button';
import { WeekdaySelector } from '../../common/components/WeekdaySelector';
import { IconCreate, IconDelete } from '../../common/utilities';

export interface MultiWeekSelectorProps {
  /**
   * Add the week.
   */
  onAddWeek: () => void;

  /**
   * Called when the selected days change.
   */
  onDaysChangeHandler: (options: SelectedDaysInWeek[]) => void;

  /**
   * Remove the week at the given index.
   */
  onRemoveWeek: (index: number) => void;

  /**
   * Array of weeks with selected days.
   */
  selectedDaysInWeeks: SelectedDaysInWeek[];
}

export interface SelectedDaysInWeek {
  /**
   * Selected days of the week. 0-based.
   */
  days: number[];

  /**
   * Unique identifier for the week.
   */
  id: number;
}

/**
 * Show a list of `<WeekDaySelector />` components, one for each week.
 */
export function MultiWeekSelector(props: MultiWeekSelectorProps): JSX.Element {
  const { t } = useTranslation();

  const hasMultipleWeeks = props.selectedDaysInWeeks.length > 1;

  return (
    <>
      {(props.selectedDaysInWeeks ?? []).map((currentWeek, weekIndex) => {
        return (
          <>
            <div className="d-flex flex-row justify-content-between align-self-center align-items-center">
              <div className="d-flex flex-row  justify-content-start align-self-center align-items-center">
                {hasMultipleWeeks && (
                  <div className="mb-3 me-3">
                    {t('scheduleUserForm|weekLabel')} {weekIndex + 1}:
                  </div>
                )}
                <WeekdaySelector
                  days={currentWeek.days}
                  id={`week-${currentWeek.id}`}
                  key={currentWeek.id}
                  onChange={(selectedDays: number[]): void => {
                    const nextItems = props.selectedDaysInWeeks.map((oldWeek) => {
                      if (oldWeek.id === currentWeek.id) {
                        return { ...oldWeek, days: selectedDays };
                      }

                      return oldWeek;
                    });
                    props.onDaysChangeHandler(nextItems);
                  }}
                />
              </div>
              <div>
                {weekIndex === props.selectedDaysInWeeks.length - 1 && (
                  <Button className="mb-3 ms-3" onClick={(): void => props.onAddWeek()} size="sm">
                    <IconCreate className={'me-1'} style={{ height: '100%' }} />
                    {t('scheduleUserForm|addWeek')}
                  </Button>
                )}
                {weekIndex > 0 && (
                  <Button
                    className="mb-3 ms-3"
                    onClick={(): void => props.onRemoveWeek(weekIndex)}
                    size="sm"
                    variant="outline-danger"
                  >
                    <IconDelete className={'me-1'} style={{ height: '100%' }} />
                  </Button>
                )}
              </div>
            </div>
          </>
        );
      })}
    </>
  );
}
