// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { ChangeEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Route, Switch, useHistory } from 'react-router-dom';

import bg from '../../../assets/img/splash.jpg';
import { TYPES } from '../../../types';
import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import Button from '../../common/components/Button';
import Page from '../../common/components/Page';
import Required from '../../common/components/Required';
import Session, { authDefault } from '../../common/entities/session';
import AuthService from '../../common/services/authService';
import { IconSignIn } from '../../common/utilities';
import Logger from '../../logger/logger';
import { Auth } from '../../shared';
import UserPasswordResetModal from '../../users/components/UserPasswordResetModal';

/**
 * Component properties.
 */
interface Props {
  /**
   * Callback to set session based on auth request.
   */
  setSession: React.Dispatch<React.SetStateAction<Session>>;
}

interface Payload {
  email: string;
  password: string;
}

/**
 * Routable component to authenticate a user.
 */
const AuthPage = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const history = useHistory();

  const authService = useInjection<AuthService>(TYPES.authService);
  const logger = useInjection<Logger>(TYPES.logger);

  const [errorMessage, setErrorMessage] = useState<string>();
  const [validated, setValidated] = useState<boolean>(false);
  const [data, setData] = useState<Payload>({
    email: '',
    password: '',
  });

  const onInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      await mutation.mutateAsync();
    } catch (err) {
      logger.error('Authentication failed', err, data);
      setErrorMessage((err as Error).message);
    }
  };

  const queryClient = useQueryClient();

  const mutation = useMutation<Auth | undefined, Error>(
    () =>
      authService.post({
        email: data.email,
        password: data.password,
      }),
    {
      onSuccess: async (data) => {
        console.info('Authentication Successful');
        console.info('Name:', `${data?.firstName} ${data?.lastName}`);
        console.info('Role:', data?.role);
        console.info('Super Admin:', data?.isSuperAdmin ? 'Yes' : 'No');
        console.info('Site ID:', data?.siteId);
        console.info('User ID:', data?.userId);
        await queryClient.invalidateQueries();
        props.setSession({ ...authDefault(), ...{ authenticated: true } });
      },
    },
  );

  return (
    <div
      className={'vh-100 d-flex justify-content-center align-items-center'}
      style={{
        background: `url(${bg}) center center no-repeat`,
        backgroundSize: 'cover',
      }}
    >
      <Page
        className={'sign-in-form col-md-4'}
        hideBreadcrumbs={true}
        style={{ height: 'auto' }}
        title={t('Sign in')}
      >
        <Form noValidate onSubmit={onFormSubmit} validated={validated}>
          <Form.Group className="mb-3" controlId="auth.ControlUsername">
            <Form.Label>
              {t('Email')} <Required />
            </Form.Label>
            <Form.Control
              autoFocus
              name={'email'}
              onChange={onInputChange}
              placeholder="jsmith@example.com"
              required
              type="text"
              value={data.email}
            />
            <Form.Control.Feedback type={'invalid'}>
              {t('common|fieldRequiredFeedback')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="auth.ControlPassword">
            <Form.Label>
              {t('Password')} <Required />
            </Form.Label>
            <Form.Control
              name={'password'}
              onChange={onInputChange}
              required
              type={'password'}
              value={data.password}
            />
          </Form.Group>
          <AlertErrorForModal message={errorMessage} />
          <div className={'d-grid gap-2'}>
            <Button
              busy={mutation.isLoading}
              disabled={mutation.isLoading}
              icon={IconSignIn}
              label={t('Sign in')}
              size={'lg'}
              type={'submit'}
            />
          </div>
          <div className={'d-grid gap-2'}>
            <Button
              busy={mutation.isLoading}
              disabled={mutation.isLoading}
              label={t('Forgot Password?')}
              onClick={(): void => {
                history.push('/forgotPassword');
              }}
              size={'sm'}
              type={'button'}
              variant={'link'}
            />
          </div>
          <Switch>
            <Route path="/forgotPassword">
              <UserPasswordResetModal
                onClose={(): void => {
                  // return to the root url
                  history.push('/');
                }}
              />
            </Route>
          </Switch>
        </Form>
      </Page>
    </div>
  );
};

export default AuthPage;
