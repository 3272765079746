// Copyright © 2024 CATTLEytics Inc.

import { useContext } from 'react';

import { SettingsLocalization } from '../../shared';
import SettingsContext from './settings-context';

export function useSettingsContext(): SettingsLocalization {
  return useContext(SettingsContext);
}
