// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { MdEdit } from 'react-icons/md';

import Button from '../../common/components/Button';

/**
 * Defines the input properties for this component.
 */
interface Props {
  /**
   * Whether the button should be disabled.
   */
  disabled?: boolean;

  /**
   * Button text.
   */
  label?: string;

  /**
   * On click callback.
   */
  onClick?: () => void;
}

/**
 * A large edit button.
 */
const ButtonEdit = (props: Props): JSX.Element => {
  return (
    <Button
      className={'text-nowrap'}
      disabled={props.disabled}
      onClick={props.onClick}
      size={'lg'}
      style={{ display: 'flex', alignItems: 'center' }}
      variant={'primary'}
    >
      <MdEdit className={'me-1'} style={{ height: '100%' }} />
      {props.label ?? 'Edit'}
    </Button>
  );
};

export default ButtonEdit;
