// Copyright © 2023 CATTLEytics Inc.
import { AnimalMilkingStatus } from '../../shared';
import { AnimalStatus } from '../interfaces';
import { IconStatusDry, IconStatusFresh, IconStatusUnknown } from './icons';

export const getAnimalMilkingStatus = (status?: AnimalMilkingStatus): AnimalStatus => {
  switch (status?.toLowerCase()) {
    case AnimalMilkingStatus.Dry:
      return {
        icon: IconStatusDry,
        textColor: '#fff',
        backgroundColor: '#DC143C',
        label: 'Dry',
      };
    case AnimalMilkingStatus.Lactating:
    case 'lactating':
      return {
        icon: IconStatusFresh,
        textColor: '#fff',
        backgroundColor: 'CornflowerBlue',
        label: 'Lactating',
      };
    default:
      return {
        icon: IconStatusUnknown,
        backgroundColor: '#ccc',
        textColor: '#666',
        label: 'Unknown',
      };
  }
};
