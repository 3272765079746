// Copyright © 2023 CATTLEytics Inc.

/**
 * @description async/await timeout
 * @param {number} ms number of milliseconds to wait
 * @return {Promise<void>}
 */
export function sleep(ms?: number): Promise<void> {
  ms = ms === undefined ? 2000 : ms;
  return new Promise((resolve) => setTimeout(resolve, ms));
}
