// Copyright © 2023 CATTLEytics Inc.

import { parseISO } from 'date-fns';
import { inject, injectable } from 'inversify';

import { TYPES } from '../../../types';
import type Logger from '../../logger/logger';
import { AnimalTestDay } from '../entities/animalTestDay';
import Api2Service from './api2Service';

const resource = '/v1/animal-test-day';

/**
 * Service for managing animal test day data via REST API.
 */
@injectable()
export default class AnimalTestDayService {
  private logger: Logger;

  // Service for accessing the HTTP API.
  private apiService: Api2Service;

  /**
   * Creates an instance of AnimalTestDayService.
   * @param logger Logger instance.
   * @param apiService API Service instance
   */
  constructor(
    @inject(TYPES.logger) logger: Logger,
    @inject(TYPES.api2Service) apiService: Api2Service,
  ) {
    this.logger = logger;
    this.apiService = apiService;
  }

  async getAnimalTestDays(params?: Record<string, string>): Promise<AnimalTestDay[]> {
    const result = await this.apiService.get<AnimalTestDay[]>(resource, params);
    if (!result) {
      return [];
    }

    result.forEach((item) => this.cast(item));

    return result;
  }

  /**
   * Casts fields into javascript types.
   * @param item
   */
  cast(item: AnimalTestDay): void {
    const data = item;
    if (!data) {
      return;
    }
    if (data.modifiedDate) {
      data.modifiedDate = parseISO(data.modifiedDate as unknown as string);
    }
    if (data.createdDate) {
      data.createdDate = parseISO(data.createdDate as unknown as string);
    }
    if (data.testDate) {
      data.testDate = parseISO(data.testDate as unknown as string);
    }

    if (data.animalEventLatest?.eventDateTime) {
      data.animalEventLatest.eventDateTime = parseISO(
        data.animalEventLatest.eventDateTime as unknown as string,
      );
    }
    if (data.animalTestDayLatest?.testDate) {
      data.animalTestDayLatest.testDate = parseISO(
        data.animalTestDayLatest.testDate as unknown as string,
      );
    }
    if (data.animalTestDayLatest?.milkProperties.fatPct) {
      data.animalTestDayLatest.milkProperties.fatPct = Number(
        (data.animalTestDayLatest.milkProperties.fatPct * 100).toFixed(2),
      );
    }
    if (data.milkProperties.fatPct) {
      data.milkProperties.fatPct = Number((data.milkProperties.fatPct * 100).toFixed(2));
    }
    if (data.milkProperties.proteinPct) {
      data.milkProperties.proteinPct = Number((data.milkProperties.proteinPct * 100).toFixed(2));
    }
  }
}
