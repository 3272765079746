// Copyright © 2024 CATTLEytics Inc.

import { Stack } from 'react-bootstrap';

export function HeadingLabel({
  title,
  value,
}: {
  title: string;
  value: React.ReactNode;
}): JSX.Element {
  return (
    <Stack className="mt-2" gap={1}>
      <div className="fw-bold">{title}</div>
      <h3>{value}</h3>
    </Stack>
  );
}
