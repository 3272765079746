// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { TYPES } from '../../../types';
import { QueryKey } from '../../shared';
import SiteService from '../services/siteService';

/**
 * Component input properties.
 */
interface Props {
  /**
   * Accessibility label
   */
  ariaLabel?: string;

  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * form element name
   */
  name?: string;

  /**
   * Input change handler
   * @param event
   */
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;

  /**
   * When add modal is closed
   */
  onCloseAddModal?: () => void;

  /**
   * Whether this field is required.
   */
  required?: boolean;

  /**
   * Input value
   */
  value: string;
}

/**
 * Form select component for breeds.
 */
const SiteSelect = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const siteService = useInjection<SiteService>(TYPES.siteService);

  const query = useQuery(
    [QueryKey.Sites],
    () => siteService.list({ sortField: 'id', sortDirection: 'ASC' }),
    {},
  );
  const items = query.data;
  const value = props.value ?? undefined;

  return (
    <>
      <Form.Select
        aria-label={props.ariaLabel ?? 'Site'}
        className={props.className}
        name={props.name ?? 'siteId'}
        onChange={props.onChange}
        required={props.required}
        value={value}
      >
        <option>{query.isFetching ? 'Loading...' : t('Choose a site')}</option>
        {items &&
          items.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
      </Form.Select>
    </>
  );
};

export default SiteSelect;
