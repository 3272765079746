// Copyright © 2023 CATTLEytics Inc.

import { parseISO } from 'date-fns';
import { inject, injectable } from 'inversify';

import { TYPES } from '../../../types';
import AnimalEvent from '../../common/entities/animalEvent';
import Api2Service from '../../common/services/api2Service';
import type Logger from '../../logger/logger';

export interface SummaryByMonth {
  //[index: string]: string | number;
  month: number;
  name: string;
  total: number;
  year: number;
}

const path = '/v1/animal-events';

/**
 * Service for managing animal event data via REST API.
 */
@injectable()
export default class AnimalEventService {
  private logger: Logger;

  private apiService: Api2Service;

  /**
   * Creates an instance of AnimalEventService.
   *
   * @param logger Logger instance.
   * @param apiService API Service instance
   */
  constructor(
    @inject(TYPES.logger) logger: Logger,
    @inject(TYPES.api2Service) apiService: Api2Service,
  ) {
    this.logger = logger;
    this.apiService = apiService;
  }

  /**
   * Retrieve a list of animal events
   * @return AnimalEvent objects
   */
  async list(params?: Record<string, string | undefined>): Promise<AnimalEvent[]> {
    const result = await this.apiService.get<AnimalEvent[]>(path, params);
    if (!result) {
      return [];
    }

    const data = result as AnimalEvent[];
    data.forEach((item) => this.cast(item));
    return data;
  }

  async summaryByMonth(params?: Record<string, string | undefined>): Promise<SummaryByMonth[]> {
    const result = await this.apiService.get<SummaryByMonth[]>(`${path}/summary-by-month`, params);
    if (!result) {
      return [];
    }

    const data = result as SummaryByMonth[];
    data.forEach((item) => item);
    return data;
  }

  async get(id: number, params?: Record<string, string>): Promise<AnimalEvent | undefined> {
    const result = await this.apiService.get<AnimalEvent>(`${path}/${id}`, params);
    if (!result) {
      return undefined;
    }

    const data = result as AnimalEvent;
    this.cast(data);
    return data;
  }

  async post(
    attributes: Record<string, string | undefined | number | number[]>,
  ): Promise<AnimalEvent[] | undefined> {
    const result = await this.apiService.post<AnimalEvent[]>(`${path}`, attributes);
    if (!result) {
      return undefined;
    }
    const data = result as AnimalEvent[];
    data.forEach((item) => this.cast(item));
    return result;
  }

  async patch(
    id: number,
    attributes: Record<string, string | number | null | undefined | number[]>,
  ): Promise<AnimalEvent | undefined> {
    // make sure record identifier is included
    attributes.id = id;

    const result = await this.apiService.patch(`${path}/${id}`, attributes);
    if (!result) {
      return undefined;
    }

    const data = result as AnimalEvent;
    this.cast(data);
    return data;
  }

  async delete(id: number): Promise<null> {
    return await this.apiService.delete(`${path}/${id}`);
  }

  /**
   * @description Casts fields into javascript types.
   * @param {JsonApiResource<AnimalEvent>} item
   * @return {void}
   */
  cast(item: AnimalEvent): void {
    const data = item;
    if (!data) {
      return;
    }
    //convert date strings to date objects
    if (data.eventDateTime) {
      data.eventDateTime = parseISO(data.eventDateTime as unknown as string);
    }
  }
}
