// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import Button from '../../common/components/Button';
import DataTable from '../../common/components/DataTable';
import DateTime from '../../common/components/DateTime';
import ActiveTreatment from '../../common/entities/activeTreatment';
import AnimalEvent from '../../common/entities/animalEvent';
import { Sort } from '../../common/enums';
import { IconView } from '../../common/utilities';
import { QueryKey } from '../../shared/enums';

interface Props {
  /**
   * Additional CSS classes to add to component.
   */
  className?: string;

  /**
   * Keys to filter data.
   */
  filters?: Record<string, string>;

  /**
   * Number of events to show.
   */
  initialLimit?: number;

  /**
   * Number of events to skip.
   */
  initialOffset?: number;

  /**
   * Table sort direction.
   */
  initialSortDirection?: Sort;

  /**
   * Table sort field.
   */
  initialSortField?: string;
}

/**
 * A table component containing animal event data.
 */
const ActiveTreatmentsTable = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const history = useHistory();

  const activeTreatmentService = useInjection<ActiveTreatment>(TYPES.activeTreatmentService);

  const [limit, setLimit] = useState<number>(props.initialLimit ?? 25);
  const [offset, setOffset] = useState<number>(props.initialOffset ?? 0);
  const [sortField, setSortField] = useState<string>(props.initialSortField ?? 'createdDate');
  const [sortDirection, setSortDirection] = useState<Sort>(
    props.initialSortDirection ?? Sort.Descending,
  );
  const filter: undefined | Record<string, string> = props.filters;

  const query = useQuery<AnimalEvent[]>(
    [QueryKey.ActiveTreatments, filter, limit, offset, sortField, sortDirection],
    () =>
      activeTreatmentService.list({
        ...filter,
        ...{
          limit: String(limit),
          offset: String(offset),
          sortField: sortField,
          sortDirection: String(sortDirection),
        },
      }),
    { keepPreviousData: true },
  );

  if (query.isError) {
    return <AlertError message={'Something unexpected happened while retrieving treatments.'} />;
  }

  const headers = [
    {
      label: 'Date',
      sortable: true,
      name: 'createdDate',
      classNameMobile: 'col-4',
    },
    {
      label: 'Animal',
      sortable: true,
      visible: !filter?.animalId,
      name: 'animalId',
      classNameMobile: 'col-4',
    },
    {
      label: 'Regime',
      sortable: true,
      name: 'regime',
      classNameMobile: 'col-4',
    },
    {
      label: 'Status',
      sortable: true,
      name: 'status',
      hideMobile: true,
    },
    {
      label: 'Next Action Date',
      sortable: true,
      name: 'nextActionDate',
      hideMobile: true,
    },
    {
      label: 'Actions',
      sortable: false,
      name: 'actions',
      hideMobile: true,
    },
  ];

  const data = query.data
    ? query.data.map((row) => {
        const name = row.animal?.name;
        return {
          id: String(row.id),
          createdDate: <DateTime date={row.createdDate} hideTime={true} />,
          animalId: (
            <div onClick={(e): void => e.stopPropagation()}>
              <Link to={`/animals/${row.animalId}`}>
                {row.animal?.primaryTag}
                {name && ` - ${name}`}
              </Link>
            </div>
          ),
          regime: row.diagnosisRegime?.name,
          status: row.status,
          nextActionDate: <DateTime date={row.nextActionDate} hideTime={true} />,
          actions: (
            <Button
              icon={IconView}
              onClick={(): void => history.push(`/active-treatments/${row.id}`)}
              size={'sm'}
              variant={'outline-primary'}
            >
              {t('View')}
            </Button>
          ),
        };
      })
    : [];

  return (
    <>
      <DataTable
        className={'mt-3'}
        data={data}
        headers={headers}
        isLoading={query.isLoading}
        isPreviousData={query.isPreviousData}
        limit={limit}
        offset={offset}
        onLimitChange={(newLimit): void => setLimit(newLimit)}
        onOffsetChange={(newOffset): void => setOffset(newOffset)}
        onRowClick={(row): void => history.push(`/active-treatments/${row.id}`)}
        onSortDirectionChange={(newSortDirection): void => setSortDirection(newSortDirection)}
        onSortFieldChange={(newSortField): void => setSortField(newSortField)}
        sortDirection={sortDirection}
        sortField={sortField}
      />
    </>
  );
};

export default ActiveTreatmentsTable;
