// Copyright © 2023 CATTLEytics Inc.

import './CommandSearchInput.scss';

import { defaultKeymap } from '@codemirror/commands';
import { sql, SQLDialect } from '@codemirror/lang-sql';
import { keymap } from '@codemirror/view';
import { tags as t } from '@lezer/highlight';
import { createTheme } from '@uiw/codemirror-themes';
import CodeMirror, { ReactCodeMirrorRef } from '@uiw/react-codemirror';
import React, { useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import AnimalAutocomplete from '../../animals/components/AnimalAutocomplete';
import Button from '../../common/components/Button';
import { IconCommandLine, IconSearch } from '../../common/utilities';
import { Animal } from '../../shared';

interface Props extends WithTranslation {
  /**
   * Additional CSS class names
   */
  className?: string;

  /**
   * Whether to display the search box (true) or CLI (false)
   */
  searchMode?: boolean;
}

const CQL = SQLDialect.define({
  keywords: 'list show events count sum pct plot econ bredsum guide for by downby',
});

/**
 * The command/search autocomplete in the top navbar.
 */
const CommandSearchInput = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const history = useHistory();
  const [searchMode, setSearchMode] = useState<boolean>(!!props.searchMode);
  const [sqlQuery, setSqlQuery] = useState<string>(
    'LIST primaryTag lactationNumber status pen milkingStatus FOR primaryTag>0 status=active pen=1 DOWNBY lactationNumber',
  );

  const refs = React.useRef<ReactCodeMirrorRef>({});

  const myTheme = createTheme({
    theme: 'dark',
    settings: {
      background: '#384854',
      foreground: '#fff',
      caret: '#fff',
      selection: '#999',
      selectionMatch: '#fff',
      lineHighlight: '#384854',
      gutterBackground: '#384854',
      gutterForeground: '#fff',
    },
    styles: [
      { tag: t.comment, color: '#787b8099' },
      { tag: t.variableName, color: '#0080ff' },
      { tag: [t.string, t.special(t.brace)], color: '#fff' },
      { tag: t.number, color: '#fff' },
      { tag: t.bool, color: '#fff' },
      { tag: t.null, color: '#fff' },
      { tag: t.keyword, color: '#aaa' },
      { tag: t.operator, color: '#fff' },
      { tag: t.className, color: '#fff' },
      { tag: t.definition(t.typeName), color: '#fff' },
      { tag: t.typeName, color: '#fff' },
      { tag: t.angleBracket, color: '#fff' },
      { tag: t.tagName, color: '#fff' },
      { tag: t.attributeName, color: '#fff' },
    ],
  });

  return (
    <InputGroup className="search nav-command-search-input">
      <Button
        className={`bg-primary-darker first ${searchMode ? 'active' : ''}`}
        onClick={(): void => setSearchMode(true)}
        style={{ fontSize: '12px' }}
        variant={'outline-light'}
      >
        <IconSearch />
      </Button>
      <Button
        className={`bg-primary-darker ${!searchMode ? 'active' : ''}`}
        onClick={(): void => setSearchMode(false)}
        style={{ fontSize: '12px' }}
        variant={'outline-light'}
      >
        <IconCommandLine />
      </Button>
      <div style={{ flex: '1' }}>
        {searchMode ? (
          <AnimalAutocomplete
            id="nav-animal-lookup"
            onSelect={(animal): void => {
              if (animal) {
                history.push(`/animals/${(animal as Animal).id}`);
              }
            }}
            placeholder={
              searchMode ? props.t('Enter an animal identifier') : props.t('Enter a CQL query')
            }
          />
        ) : (
          <div>
            <CodeMirror
              basicSetup={{
                lineNumbers: false,
                indentOnInput: false,
                defaultKeymap: false,
              }}
              className={'code-mirror'}
              extensions={[
                keymap.of([
                  // remap enter key to run CQL query
                  {
                    key: 'Enter',
                    run: (): true => {
                      history.push(`/reports/custom/${encodeURIComponent(sqlQuery)}`);
                      return true;
                    },
                    preventDefault: true,
                  },
                  ...defaultKeymap,
                ]),
                sql({
                  dialect: CQL,
                  upperCaseKeywords: true,
                }),
              ]}
              onChange={(value: string): void => {
                setSqlQuery(value);
              }}
              ref={refs}
              theme={myTheme}
              value={sqlQuery}
            />
          </div>
        )}
      </div>
    </InputGroup>
  );
};

export default withTranslation()(CommandSearchInput);
