// Copyright © 2023 CATTLEytics Inc.

import React, { CSSProperties } from 'react';
import { Badge } from 'react-bootstrap';
import { IconType } from 'react-icons';

import { AnimalMilkingStatus } from '../../shared';
import { getAnimalMilkingStatus } from '../utilities';

/**
 * Defines the input properties for this component.
 */
interface Props {
  /**
   * CSS classes to pass to component.
   */
  className?: string;

  /**
   * Whether to show an unknown status. If disabled "-" will be returned.
   */
  showUnknown?: boolean;

  /**
   * The animal's status.
   */
  status?: AnimalMilkingStatus;

  /**
   * CSS styles
   */
  style?: CSSProperties;
}

/**
 * A badge to indicate an animal's milking status.
 */
const AnimalMilkingStatusBadge = (props: Props): JSX.Element => {
  const status = getAnimalMilkingStatus(props.status);
  const style: CSSProperties = { backgroundColor: status.backgroundColor, color: status.textColor };
  const Icon = status.icon as IconType;

  if (props.status === AnimalMilkingStatus.Unknown && !props.showUnknown) {
    return <>-</>;
  }

  return (
    <Badge
      bg={''}
      className={props.className}
      pill={true}
      style={props.style ? { ...props.style, ...style } : style}
    >
      <Icon /> {status.label}
    </Badge>
  );
};

export default AnimalMilkingStatusBadge;
