// Copyright © 2023 CATTLEytics Inc.

import React, { PropsWithChildren } from 'react';
import { Toast as BootstrapToast, ToastContainer } from 'react-bootstrap';

/**
 * Helps simplify the configuration of a Toast.
 */
export interface ToastConfig {
  /**
   * Message text that can be used inside a Toast body.
   */
  message?: string;

  /**
   * Whether the component is visible.
   */
  visible: boolean;
}

/**
 * Properties for the @see Toast component.
 */
export interface Props {
  /**
   * Additional classes.
   */
  className?: string;

  /**
   * Whether the toast should be hidden or closed when clicked.
   */
  closeOnClick?: boolean;

  /**
   * Delay before calling the onClose callback.
   */
  delay?: number;

  /**
   * Callback when the autohide delay has elapsed.
   */
  onClose: () => void;

  /**
   * Whether Toast is visible
   */
  show: boolean;
}

/**
 * A react-bootstrap Toast wrapper with our customization and defaults.
 */
const Toast = (props: PropsWithChildren<Props>): JSX.Element => {
  return (
    <ToastContainer containerPosition={'fixed'} position="bottom-center">
      <BootstrapToast
        autohide={true}
        bg={'secondary'}
        className={'mb-5'}
        delay={props.delay ? props.delay : 3000}
        onClose={props.onClose}
        show={props.show}
      >
        <BootstrapToast.Body
          className={'text-light text-center'}
          onClick={props.closeOnClick ? props.onClose : undefined}
        >
          {props.children}
        </BootstrapToast.Body>
      </BootstrapToast>
    </ToastContainer>
  );
};

export default Toast;
