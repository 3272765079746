// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import Button from '../../common/components/Button';
import DataTable, { DataTableHeader, DataTableRow } from '../../common/components/DataTable';
import DateTime from '../../common/components/DateTime';
import TablePlaceholder from '../../common/components/TablePlaceholder';
import { AnimalEventType, Sort } from '../../common/enums';
import AnimalEventService from '../../common/services/animalEventService';
import { IconTag, IconView } from '../../common/utilities';
import { nameTag } from '../../shared';
import { QueryKey } from '../../shared/enums';
import EventDetailModal from './EventDetailModal';

interface Props {
  /**
   * Additional CSS classes to add to component.
   */
  className?: string;

  eventTypeIds?: AnimalEventType[];

  /**
   * Keys to filter data.
   */
  filters?: Record<string, string>;

  /**
   * Number of events to show.
   */
  initialLimit?: number;

  /**
   * Number of events to skip.
   */
  initialOffset?: number;

  /**
   * Table sort direction.
   */
  initialSortDirection?: Sort;

  /**
   * Table sort field.
   */
  initialSortField?: string;
}

/**
 * A table component containing animal event data.
 */
const EventsTable = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const animalEventService = useInjection<AnimalEventService>(TYPES.animalEventService);

  const [detailId, setDetailId] = useState<number>();
  const [detailModalVisible, setDetailModalVisible] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(props.initialLimit ?? 25);
  const [offset, setOffset] = useState<number>(props.initialOffset ?? 0);
  const [sortField, setSortField] = useState<string>(props.initialSortField ?? 'eventDateTime');
  const [sortDirection, setSortDirection] = useState<Sort>(
    props.initialSortDirection ?? Sort.Descending,
  );
  const filter: undefined | Record<string, string> = props.filters;

  const query = useQuery(
    [QueryKey.AnimalEvents, props.eventTypeIds, filter, limit, offset, sortField, sortDirection],
    () =>
      animalEventService.list({
        ...filter,
        ...{
          animalEventTypeIds: props.eventTypeIds ? props.eventTypeIds.join(',') : undefined,
          limit: String(limit),
          offset: String(offset),
          sortField: sortField,
          sortDirection: String(sortDirection),
        },
      }),
    { keepPreviousData: true },
  );

  const showDetailModal = (animalEventId: number): void => {
    setDetailId(animalEventId);
    setDetailModalVisible(true);
  };

  if (query.isLoading) {
    return <TablePlaceholder />;
  }

  if (query.isError) {
    return <AlertError message={'Something unexpected happened while retrieving events.'} />;
  }

  const events = query.data ?? [];

  const data: DataTableRow[] = events.map((event) => {
    let notes = <>{event.notes}</>;
    // if this is a move event
    const matches = event.notes?.match(/F([0-9]{3})T([0-9]{3})/);
    if (matches) {
      notes = (
        <>
          From: <strong>Pen {parseInt(matches[1])}</strong> To:{' '}
          <strong>Pen {parseInt(matches[2])}</strong>
        </>
      );
    }
    const name = event.animal?.name;
    return {
      id: String(event.id),
      eventDateTime: <DateTime date={event.eventDateTime} hideTime={true} />,
      animalId: (
        <Link to={`/animals/${event.animalId}`}>
          <IconTag className={'me-1'} />
          {nameTag(event.animal?.primaryTag, name)}
        </Link>
      ),
      animalEventType: event.animalEventType?.name,
      notes: notes,
      actions: (
        <Button
          className={'text-nowrap'}
          onClick={(): void => showDetailModal(event.id)}
          size={'sm'}
          variant={'outline-primary'}
        >
          <IconView className={'me-1'} />
          {t('Detail')}
        </Button>
      ),
    };
  });

  const headers: DataTableHeader[] = [
    { label: 'Date', name: 'eventDateTime' },
    { label: 'Animal', name: 'animalId', visible: !filter?.animalId },
    { label: 'Type', name: 'animalEventType', sortable: false },
    {
      label: 'Notes',
      name: 'notes',
      style: { width: '50%' },
      className: 'td-text-nowrap',
      hideMobile: true,
    },
    { label: 'Actions', name: 'actions', hideMobile: true },
  ];

  return (
    <div>
      {detailModalVisible && (
        <EventDetailModal
          animalEventId={detailId as number}
          onClose={(): void => setDetailModalVisible(false)}
        />
      )}
      <DataTable
        data={data}
        headers={headers}
        limit={limit}
        offset={offset}
        onLimitChange={(newLimit): void => setLimit(newLimit)}
        onOffsetChange={(newOffset): void => setOffset(newOffset)}
        onRowClick={(row): void => showDetailModal(Number(row.id))}
        onSortDirectionChange={(newSortDirection): void => setSortDirection(newSortDirection)}
        onSortFieldChange={(newSortField): void => setSortField(newSortField)}
        sortDirection={sortDirection}
        sortField={sortField}
      />
    </div>
  );
};

export default EventsTable;
