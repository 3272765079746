// Copyright © 2023 CATTLEytics Inc.

import React, { ReactNode } from 'react';
import { withTranslation } from 'react-i18next';

interface Props {
  className?: string;
  icon?: ReactNode;
}

/**
 * Simple heading wrapper for consistent nav group heading style..
 */
const NavGroupHeading = (props: React.PropsWithChildren<Props>): JSX.Element => {
  return (
    <h4
      className="nav-group-heading mt-3 d-flex align-items-center"
      style={{ color: 'var(--bs-light' }}
    >
      <div>{props.icon && <div className={'rounded-circle'}>{props.icon}</div>}</div>
      <span>{props.children}</span>
    </h4>
  );
};

export default withTranslation()(NavGroupHeading);
