// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { withTranslation } from 'react-i18next';

import { IconDelete } from '../utilities';
import { ButtonVariant } from './Button';
import ButtonModal from './ButtonModal';

/**
 * Defines the input properties for this component.
 */
interface Props {
  /**
   * Whether the button is in a busy state
   */
  busy?: boolean;

  /**
   * Whether the button should be disabled.
   */
  disabled?: boolean;

  /**
   * Callback for click event.
   */
  onClick?: () => void;
}

/**
 * A button component for use in modals.
 */
const ButtonModalDelete = (props: Props): JSX.Element => {
  return (
    <ButtonModal
      busy={props.busy}
      className={'w-100 w-auto-sm'}
      disabled={props.disabled}
      icon={IconDelete}
      label={'Delete'}
      onClick={props.onClick}
      type={'button'}
      variant={ButtonVariant.Danger}
    />
  );
};

export default withTranslation()(ButtonModalDelete);
