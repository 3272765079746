// Copyright © 2023 CATTLEytics Inc.
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';

interface PaginationValues {
  disabled?: boolean;
  end: boolean;
  limit: number;
  start: boolean;
}

interface PaginationHandlers {
  onLimitChange: (limit: number) => void;
  onNext: () => void;
  onPrev: () => void;
}

interface Options {
  limit: number;
  listLength?: number;
  offset: number;
  setLimit: Dispatch<SetStateAction<number>>;
  setOffset: Dispatch<SetStateAction<number>>;
}

type Return = [PaginationValues, PaginationHandlers];

export const usePaginationControls = ({
  limit,
  listLength = 0,
  offset,
  setLimit,
  setOffset,
}: Options): Return => {
  const start = useMemo(() => offset === 0, [offset]);
  const end = useMemo(() => listLength < limit, [listLength, limit]);
  const onNext = useCallback((): void => setOffset((prev) => prev + limit), [limit, setOffset]);
  const onPrev = useCallback(
    (): void => setOffset((prev) => Math.max(prev - limit, 0)),
    [limit, setOffset],
  );

  return [
    { end, limit, start },
    { onLimitChange: setLimit, onNext, onPrev },
  ] as Return;
};
