// Copyright © 2023 CATTLEytics Inc.

import {
  format as dateFnsFormat,
  formatDistanceStrict,
  formatDistanceToNow,
  isToday,
  isYesterday,
  parseISO,
} from 'date-fns';

/**
 * Formats a date.
 *
 * @param date (optional) date to format, if omitted empty string will be returned.
 * @param format (optional) date format, default: if today: 'h:mm a', yesterday: Yesterday, later: 'yyyy-MM-dd'
 * @param showTime (optional) Whether to show a time component (format param takes precedence)
 * @return formatted date
 */
export function formatDate(date?: Date, format?: string, showTime?: boolean): string {
  if (!date) {
    return '';
  }
  try {
    if (format) {
      return dateFnsFormat(date, format);
    }

    if (isToday(date)) {
      if (showTime) {
        return dateFnsFormat(date, 'h:mm a');
      } else {
        return 'Today';
      }
    }

    if (isYesterday(date)) {
      if (showTime) {
        return `Yesterday at ${dateFnsFormat(date, 'h:mm a')}`;
      } else {
        return 'Yesterday';
      }
    }

    return dateFnsFormat(date, 'yyyy-MM-dd');
  } catch {
    return 'Invalid date';
  }
}

export function formatDateTime(date?: Date): string {
  if (!date) {
    return '';
  }

  return formatDate(date, 'yyyy-MM-dd HH:mm:ss');
}

/**
 * Calculates the number of months between now a date.
 *
 * @param {Date} date
 * @return number of months between now and date param
 */
export function ageInMonths(date: Date): number {
  return parseInt(formatDistanceStrict(new Date(), date, { unit: 'month' }).split(' ')[0]);
}

/**
 * Calculates the number of years between now a date.
 *
 * @param {Date} date
 * @return number of months between now and date param
 */
export function ageInYears(date: Date): number {
  return parseInt(formatDistanceStrict(new Date(), date, { unit: 'year' }).split(' ')[0]);
}

/**
 * Strips out the time component of a date object.
 *
 * @param {Date} date date to remove time from
 * @return date with time set to 00:00:00.000000
 */
export function dropTimeFromDate(date: Date): Date {
  return parseISO(dateFnsFormat(date, 'yyyy-MM-dd'));
}

/**
 * @deprecated
 * @param date
 */
export function timeAgo(date: Date | string): string {
  return formatDate(typeof date === 'string' ? parseISO(date) : date, undefined, true);
}

export function formatDateAbsolute(date: Date | string, format: string): string {
  if (!date) {
    return '';
  }

  try {
    return dateFnsFormat(typeof date === 'string' ? parseISO(date) : date, format);
  } catch {
    return 'Invalid date';
  }
}

export function formatDateRelative(date?: Date, hideTime?: boolean): string {
  if (!date) {
    return '';
  }
  try {
    return hideTime && isToday(date) ? 'Today' : formatDistanceToNow(date, { addSuffix: true });
  } catch {
    return 'Invalid date';
  }
}
