// Copyright © 2023 CATTLEytics Inc.

import { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IconType } from 'react-icons';

import LightBoxModal from './LightBoxModal';

/**
 * Defines the input properties for this component.
 */
interface Props extends WithTranslation {
  /**
   * Text to display inside image
   */
  alt?: string;

  /**
   * Additional CSS classes to apply to this component
   */
  className?: string;

  /**
   * Optional react-icon to use as a fallback if no image src specified.
   */
  fallbackIcon?: IconType;

  /**
   * CSS height attribute for IMG
   */
  height?: string | number;

  /**
   * image source
   */
  src?: string;

  /**
   * CSS width attribute for IMG
   */
  width?: string | number;

  /**
   * Whether to show image in light box when clicked.
   */
  withLightBox?: boolean;
}

/**
 * A component to use to display a small version of an image.
 */
const ImageThumbnail = (props: Props): JSX.Element => {
  const width = props.width ?? '150px';
  const height = props.height ?? '150px';

  const FallbackIcon = props.fallbackIcon as IconType;

  const [lightBoxModalVisible, setLightBoxModalVisible] = useState<boolean>(false);

  return (
    <>
      {!props.src && (
        <div
          className={'border align-items-center justify-content-center d-flex'}
          style={{ width: width, height: height, borderRadius: '1rem' }}
        >
          {props.fallbackIcon && (
            <FallbackIcon className={'text-muted'} style={{ fontSize: '32px' }} />
          )}
          {!props.fallbackIcon && <em className="text-center">No Image</em>}
        </div>
      )}
      {props.src && (
        <img
          alt={props.alt}
          className={`border ${props.withLightBox && 'cursor-pointer'}${
            props.className ? ` ${props.className}` : ''
          }`}
          onClick={(): void | boolean => props.withLightBox && setLightBoxModalVisible(true)}
          src={props.src}
          style={{
            display: 'block',
            objectFit: 'cover',
            objectPosition: '50% 50%',
            width: width,
            height: height,
            borderRadius: '1rem',
          }}
        />
      )}
      {lightBoxModalVisible && (
        <LightBoxModal imageUrl={props.src} onClose={(): void => setLightBoxModalVisible(false)} />
      )}
    </>
  );
};

export default withTranslation()(ImageThumbnail);
