// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';

import { TYPES } from '../../../types';
import AgeClassBadge from '../../common/components/AgeClassBadge';
import AlertError from '../../common/components/AlertError';
import Button, { ButtonVariant } from '../../common/components/Button';
import DataTable, { DataTableHeader, DataTableRow } from '../../common/components/DataTable';
import DateTime from '../../common/components/DateTime';
import TablePlaceholder from '../../common/components/TablePlaceholder';
import Tooltip from '../../common/components/Tooltip';
import { AnimalReproductionStatus, Sort } from '../../common/enums';
import { AnimalStatus } from '../../common/enums/animalStatus';
import AnimalService from '../../common/services/animalService';
import { IconCheck, IconEvent, IconTag, IconView } from '../../common/utilities';
import EventDetailModal from '../../events/components/EventDetailModal';
import { QueryKey } from '../../shared';
import { Animal, AnimalMilkingStatus, buildAgeClassCode } from '../../shared';
import AnimalTableImage from './AnimalTableImage';

interface Props {
  /**
   * Dam ID to filter animals by
   */
  damId?: number;

  filters?: Record<string, string | undefined>;

  /**
   * Whether to hide filters.
   * Note: If this is set to true, the status filter is automatically cleared
   * to show all calves of any status
   */
  hideFilters?: boolean;

  /**
   * Number of events to show.
   */
  initialLimit?: number;

  /**
   * Number of events to skip.
   */
  initialOffset?: number;

  // Table sort direction.
  initialSortDirection?: Sort;

  // Table sort field.
  initialSortField?: string;

  /**
   * Whether to show dead animals
   */
  isDead?: boolean;

  /**
   * Whether to show animals that have been sold
   */
  isSold?: boolean;

  /**
   * Pen ID to filter animals by
   */
  penId?: string;

  /**
   * Whether to show a filter to only show calves.
   * Useful for showing all of an animal's prodigy.
   */
  showCalvesOnlyFilter?: boolean;
}

/**
 * A filterable list of animals.
 */
const CalfTable = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const animalService = useInjection<AnimalService>(TYPES.animalService);

  const [detailId, setDetailId] = useState<number>();
  const [detailModalVisible, setDetailModalVisible] = useState<boolean>(false);

  const history = useHistory();

  const defaultStatus = [];

  if (props.isDead && props.isSold) {
    defaultStatus.push(AnimalStatus.DeadAndSold);
  } else if (props.isDead) {
    defaultStatus.push(AnimalStatus.Dead);
  } else if (props.isSold) {
    defaultStatus.push(AnimalStatus.Sold);
  } else {
    defaultStatus.push(AnimalStatus.Active);
  }

  const defaultFilters: Record<string, string | undefined> = {
    reproductionStatus: [
      AnimalReproductionStatus.Bred,
      AnimalReproductionStatus.Open,
      AnimalReproductionStatus.Pregnant,
      AnimalReproductionStatus.Unknown,
    ].join(','),
    milkingStatus: [
      AnimalMilkingStatus.Lactating,
      AnimalMilkingStatus.Dry,
      AnimalMilkingStatus.Unknown,
    ].join(','),
    status: defaultStatus.join(','),
    pen: props.penId,
    calvesOnly: '1',
  };

  const [limit, setLimit] = useState<number>(props.initialLimit ?? 25);
  const [offset, setOffset] = useState<number>(props.initialOffset ?? 0);
  const [sortField, setSortField] = useState<string>(props.initialSortField ?? 'primaryTag');
  const [sortDirection, setSortDirection] = useState<Sort>(
    props.initialSortDirection ?? Sort.Ascending,
  );
  const [filters, setFilters] = useState<Record<string, string | undefined>>(
    props.filters ? { ...defaultFilters, ...props.filters } : defaultFilters,
  );

  if (props.damId) {
    filters.damId = String(props.damId);
  }

  if (props.hideFilters) {
    filters.status = undefined;
  }

  const query = useQuery<Animal[]>(
    [QueryKey.Animals, limit, offset, filters, sortField, sortDirection],
    () =>
      animalService.getAnimals({
        ...filters,
        ...{
          limit: String(limit),
          offset: String(offset),
          sortField: sortField,
          sortDirection: String(sortDirection),
        },
      }),
    { keepPreviousData: true },
  );

  const updateFilterStatus = (key: string, status: string, enabled: boolean): void => {
    const newFilters = { ...filters };
    const existingFilter = newFilters[key];
    let statuses = existingFilter ? existingFilter.split(',') : [];

    if (enabled) {
      statuses.push(status);
      statuses = [...new Set(statuses)];
    } else {
      statuses = statuses.filter((s) => status !== s);
    }
    newFilters[key] = statuses.join(',');
    setFilters(newFilters);
    setOffset(0);
  };

  const showDetailModal = (animalEventId: number): void => {
    setDetailId(animalEventId);
    setDetailModalVisible(true);
  };

  if (query.isLoading) {
    return <TablePlaceholder />;
  }

  if (query.isError) {
    return <AlertError message={t('common|animalRetrievalError')} />;
  }

  const headers: DataTableHeader[] = [
    {
      name: 'image',
      hideLabel: true,
      label: '',
    },
    {
      name: 'primaryTag',
      label: t('Primary Tag'),
      infoTooltip: t('calfTable|primaryTagInfoTooltip'),
      classNameMobile: 'col-6',
    },
    {
      name: 'name',
      label: t('Name'),
      infoTooltip: t('calfTable|animalNameInfoTooltip'),
      classNameMobile: 'col-6',
    },
    {
      name: 'ageClass',
      label: t('Age Class'),
      infoTooltip: t(
        'The age classification of this animal: BC = Bull Calf, HC = Heifer Class, C = Cow, H = Heifer',
      ),
      classNameMobile: 'col-6',
    },
    {
      name: 'birthStatus',
      infoTooltip: t(`What was the status at the animal's birth`),
      label: t('Birth Status'),
      hideMobile: false,
      sortable: true,
    },
    {
      name: 'status',
      infoTooltip: t('Is this animal alive, sold or dead?'),
      label: t('Current Status'),
      hideMobile: false,
      sortable: true,
    },
    {
      name: 'birthDate',
      infoTooltip: t(`Calf's birth date`),
      label: t('Birth Date'),
      hideMobile: false,
      sortable: true,
    },
    {
      name: 'healthChecks',
      infoTooltip: t(`Animal health checks`),
      label: t('Health Checks'),
      hideMobile: false,
      sortable: false,
    },
    // {
    //   name: 'event',
    //   label: 'Event',
    //   infoTooltip: t('Most recent event involving this animal.'),
    //   hideMobile: true,
    //   sortable: false,
    // },
    {
      name: 'pen',
      label: 'Pen',
      infoTooltip: t('calfTable|animalCurrentPenTooltip'),
      hideMobile: true,
      sortable: false,
    },
    { name: 'actions', sortable: false, label: 'Actions', hideMobile: true },
  ];

  const data: DataTableRow[] = !query.data
    ? []
    : query.data.map((animal) => {
        return {
          id: String(animal.id),
          image: <AnimalTableImage animal={animal} />,
          primaryTag: (
            <>
              <IconTag className={'me-1'} style={{ fontSize: '12px' }} />
              {animal.primaryTag}
            </>
          ),
          name: animal.name,
          ageClass: <AgeClassBadge ageClass={buildAgeClassCode(animal)} />,
          birthDate: animal.birthDate,
          birthStatus: animal.birthStatus?.name,
          status: animal.status,
          healthChecks: (
            <>
              {animal.animalHealthCheckResults &&
                animal.animalHealthCheckResults.map((hcr) => (
                  <>
                    {hcr.result && (
                      <span className={'text-nowrap'}>
                        <IconCheck className={'text-primary'} /> {hcr.animalHealthCheck?.name}{' '}
                      </span>
                    )}
                  </>
                ))}
            </>
          ),
          event: animal.animalEventLatest && (
            <Button
              className={'p-0'}
              onClick={(e): void => {
                e.preventDefault();
                e.stopPropagation();
                if (animal.animalEventLatest?.id) {
                  showDetailModal(animal.animalEventLatest.id);
                }
              }}
              variant={'link'}
            >
              <span className={'text-nowrap'}>
                <IconEvent className={'me-1'} />
                <DateTime date={animal.animalEventLatest?.eventDateTime} hideTime={true} />{' '}
              </span>
              {animal.animalEventLatest?.animalEventType?.name}
              {animal.animalEventLatest?.notes ? <br /> : null}
              {animal.animalEventLatest?.notes}
            </Button>
          ),
          pen: (
            <Tooltip tooltip={`Home Pen: ${animal.homePen?.name}`}>
              {animal.currentPen?.name}
            </Tooltip>
          ),
          actions: (
            <Link to={`/animals/${animal.id}`}>
              <Button size={'sm'} variant={ButtonVariant.OutlinePrimary}>
                <IconView className={'me-1'} />
                {t('View')}
              </Button>
            </Link>
          ),
        };
      });

  const statuses = Object.values(AnimalStatus);

  return (
    <>
      {!props.hideFilters && (
        <div className={'d-flex flex-wrap justify-content-between '}>
          <Card body className={'mb-3'}>
            <strong>Filters</strong>
            <div className={'d-flex flex-wrap justify-content-md-between'}>
              {statuses.map((status) => (
                <Form.Switch
                  checked={filters.status?.split(',').includes(status)}
                  className={'me-3'}
                  id={`filter-status-${status}`}
                  key={`filter-status-${status}`}
                  label={t(`animalTable|statusLabels|${status}`)}
                  onChange={(e): void => updateFilterStatus('status', status, e.target.checked)}
                />
              ))}
              {props.showCalvesOnlyFilter && (
                <Form.Switch
                  checked={filters.calvesOnly === '1'}
                  className={'me-3'}
                  id={`filter-only-calves`}
                  key={`filter-only-calves`}
                  label={'Only calves'}
                  onChange={(e): void => {
                    const newFilters = { ...filters };
                    newFilters.calvesOnly = e.target.checked ? '1' : '0';
                    setFilters(newFilters);
                  }}
                />
              )}
            </div>
          </Card>
        </div>
      )}

      <DataTable
        className={'mt-3'}
        data={data}
        headers={headers}
        isLoading={query.isLoading}
        isPreviousData={query.isPreviousData}
        limit={limit}
        messageNoData={t('calfTable|noDataMessage')}
        offset={offset}
        onLimitChange={(newLimit): void => setLimit(newLimit)}
        onOffsetChange={(newOffset): void => setOffset(newOffset)}
        onRowClick={(row): void => history.push(`/animals/${row.id}`)}
        onSortDirectionChange={(newSortDirection): void => setSortDirection(newSortDirection)}
        onSortFieldChange={(newSortField): void => setSortField(newSortField)}
        sortDirection={sortDirection}
        sortField={sortField}
      />
      {detailModalVisible && (
        <EventDetailModal
          animalEventId={detailId as number}
          onClose={(): void => setDetailModalVisible(false)}
        />
      )}
    </>
  );
};

export default CalfTable;
