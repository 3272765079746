// Copyright © 2023 CATTLEytics Inc.

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonCreate from '../../common/components/ButtonCreate';
import Page from '../../common/components/Page';
import SiteModal from './SiteModal';
import SitesTable from './SitesTable';

/**
 * Routable component to list and manage sites.
 */
const SitesPage = (): JSX.Element => {
  const { t } = useTranslation();

  const [sitesModalVisible, setSitesModalVisible] = useState<boolean>(false);

  return (
    <Page
      buttons={<ButtonCreate label={'New Site'} onClick={(): void => setSitesModalVisible(true)} />}
      title={t('Sites')}
    >
      <SitesTable />
      {sitesModalVisible && <SiteModal onClose={(): void => setSitesModalVisible(false)} />}
    </Page>
  );
};

export default SitesPage;
