// Copyright © 2023 CATTLEytics Inc.

import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import GroupedCheckboxDropdown, {
  GroupedCheckboxDropdownProps,
  GroupedOption,
} from '../../../../common/components/GroupedCheckboxDropdown';
import { useAnimalCardEventFilter, useGetEventTypesByAnimal } from '../hooks';
import { groupEventTypes } from './utilities';

type EventTypesSelectProps = {
  animalId: string;
};

const EventTypesSelect = ({ animalId }: EventTypesSelectProps): JSX.Element => {
  const { t } = useTranslation();
  const [selectedEventTypeIds, setSelectedEventTypeIds] =
    useAnimalCardEventFilter('animalEventTypeIds');
  const { eventTypes } = useGetEventTypesByAnimal({ animalId });

  const options = useMemo<GroupedOption[]>(
    () => (eventTypes ? groupEventTypes(eventTypes) : []),
    [eventTypes],
  );

  const onChange = useCallback<GroupedCheckboxDropdownProps['onChange']>(
    (values) => setSelectedEventTypeIds(values),
    [setSelectedEventTypeIds],
  );

  return (
    <GroupedCheckboxDropdown
      label={t('{{count}} Event Types Selected', { count: selectedEventTypeIds?.length ?? 0 })}
      onChange={onChange}
      options={options}
      values={selectedEventTypeIds ?? []}
    />
  );
};

export default EventTypesSelect;
