// Copyright © 2023 CATTLEytics Inc.

import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import ButtonCreate from '../../common/components/ButtonCreate';
import Page from '../../common/components/Page';
import AnimalEventType from '../../common/entities/animalEventType';
import { AnimalEventType as AnimalEventTypeEnum } from '../../common/enums';
import { capitalizeWords } from '../../common/utilities';
import { api } from '../../common/utilities';
import { ApiResourceV1, HttpMethod, QueryKey } from '../../shared';
import ActiveTreatmentsTable from './ActiveTreatmentsTable';
import EventModal from './EventModal';

interface RouteParams {
  /**
   * Tab key to make active on render.
   */
  tabKey: string;
}

/**
 * Routable component to list active treatments.
 */
const ActiveTreatmentsPage = (): JSX.Element => {
  const { t } = useTranslation();

  const { tabKey } = useParams<RouteParams>();
  const history = useHistory();

  const [eventModalVisible, setEventModalVisible] = useState<boolean>(false);
  const [eventType, setEventType] = useState<AnimalEventType>();
  const [eventTypes, setEventTypes] = useState<AnimalEventType[]>([]);

  const filters: Record<string, string> = {};
  filters[tabKey] = '1';
  filters.order = '-eventDateTime';

  const getEventTypeById = (eventTypeId: AnimalEventTypeEnum): AnimalEventType | undefined => {
    const type = eventTypes.filter((type) => type.id === eventTypeId);
    if (type.length > 0) {
      return type[0];
    }
    return undefined;
  };

  const query = useQuery<AnimalEventType[]>([QueryKey.AnimalEventTypes], () =>
    api<AnimalEventType[]>(HttpMethod.Get, ApiResourceV1.AnimalEventTypes, {
      params: { limit: '1000', sortDirection: 'asc', sortField: 'id' },
    }),
  );

  useEffect(() => {
    if (!query.data) return;
    setEventTypes(query.data);
  }, [query.data]);

  const showEventModal = (eventType?: AnimalEventType): void => {
    if (eventType) {
      setEventType(eventType);
    }
    setEventModalVisible(true);
  };

  return (
    <Page
      breadcrumbTitle={capitalizeWords(tabKey)}
      breadcrumbs={[
        { label: 'Animal Health', to: '/' },
        { label: 'Ongoing Treatments', to: '/active-treatments/all' },
      ]}
      buttons={
        <ButtonCreate
          label={t('Create')}
          onClick={(): void => showEventModal(getEventTypeById(AnimalEventTypeEnum.Diagnosis))}
        />
      }
      title={t('activeTreatmentsPage|activeTreatmentsTitle')}
    >
      <Tabs
        activeKey={tabKey}
        className="mb-3"
        id="active-treatments-tabs"
        mountOnEnter={true}
        onSelect={(k): void => {
          if (k) {
            history.push(`/active-treatments/${k}`);
          }
        }}
      >
        <Tab eventKey="all" title="All">
          <ActiveTreatmentsTable filters={{ order: '-createdDate' }} />
        </Tab>
        <Tab eventKey="pending" title="Pending">
          <ActiveTreatmentsTable filters={filters} />
        </Tab>
        <Tab eventKey="completed" title="Completed">
          <ActiveTreatmentsTable filters={filters} />
        </Tab>
      </Tabs>
      {eventModalVisible && (
        <EventModal
          defaultEventType={eventType}
          onClose={(): void => {
            setEventModalVisible(false);
          }}
          onSave={(ae): void => {
            if (ae && ae.activeTreatmentId) {
              history.push(`/active-treatments/${ae.activeTreatmentId}`);
            }
            setEventModalVisible(false);
          }}
        />
      )}
    </Page>
  );
};

export default ActiveTreatmentsPage;
