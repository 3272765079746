// Copyright © 2023 CATTLEytics Inc.

import { parseISO } from 'date-fns';
import { inject, injectable } from 'inversify';

import { TYPES } from '../../../types';
import type Logger from '../../logger/logger';
import { Notification } from '../../shared';
import ApiService from './api2Service';
import DataService from './dataService';

const path = '/v1/notifications';

/**
 * Service for notifications.
 */
@injectable()
export default class NotificationService implements DataService<Notification> {
  private logger: Logger;

  private apiService: ApiService;

  /**
   * Creates an instance of NotificationService.
   *
   * @param logger Logger instance.
   * @param apiService API Service instance
   */
  constructor(
    @inject(TYPES.logger) logger: Logger,
    @inject(TYPES.api2Service) apiService: ApiService,
  ) {
    this.logger = logger;
    this.apiService = apiService;
  }

  /**
   * Retrieve a list of Animal Notifications
   * @return Notification objects
   */
  async list(params?: Record<string, string>): Promise<Notification[]> {
    const result = await this.apiService.get<Notification[]>(path, params);
    if (!result) {
      return [];
    }

    const data = result as Notification[];
    data.forEach((item) => this.cast(item));
    return data;
  }

  async listTags(params?: Record<string, string>): Promise<string[]> {
    const result = await this.apiService.get<string[]>(`${path}/tags`, params);
    if (!result) {
      return [];
    }

    return result as string[];
  }

  async get(id: number, params?: Record<string, string>): Promise<Notification | undefined> {
    const result = await this.apiService.get<Notification>(`${path}/${id}`, params);
    if (!result) {
      return undefined;
    }

    const data = result as Notification;
    this.cast(data);
    return data;
  }

  async post(attributes: Record<string, string>): Promise<Notification | undefined> {
    const result = await this.apiService.post<Notification>(`${path}`, attributes);
    if (!result) {
      return undefined;
    }
    this.cast(result as Notification);
    return result;
  }

  async patch(
    id: number,
    attributes: Record<string, string | number | boolean | null>,
  ): Promise<Notification | undefined> {
    // make sure record identifier is included
    attributes.id = id;

    const result = await this.apiService.patch(`${path}/${id}`, attributes);
    if (!result) {
      return undefined;
    }

    const data = result as Notification;
    this.cast(data);
    return data;
  }

  async patchAll(
    attributes: Record<string, string | number | boolean | null>,
  ): Promise<Notification | undefined> {
    const result = await this.apiService.patch(path, attributes);
    if (!result) {
      return undefined;
    }

    const data = result as Notification;
    this.cast(data);
    return data;
  }

  async delete(id: number): Promise<null> {
    return await this.apiService.delete(`${path}/${id}`);
  }

  /**
   * Casts fields into javascript types.
   * @param item
   * @return cast object
   */
  cast(item: Notification): void {
    if (!item) {
      return;
    }

    if (item.modifiedDate) {
      item.modifiedDate = parseISO(item.modifiedDate as unknown as string);
    }
    if (item.createdDate) {
      item.createdDate = parseISO(item.createdDate as unknown as string);
    }
  }
}
