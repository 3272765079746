// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { ChangeEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { TYPES } from '../../../types';
import ManageListEditModal from '../../lists/components/ManageListEditModal';
import { ApiResourceV1, QueryKey } from '../../shared';
import GenderService from '../services/genderService';

/**
 * Component input properties.
 */
interface Props extends WithTranslation {
  /**
   * accessibility label
   */
  ariaLabel?: string;

  /**
   * additional class names to pass to the component.
   */
  className?: string;

  /**
   * Conditions to make the field invalid.
   *
   * @see Set when using yup schema
   */
  isInvalid?: boolean;

  /**
   * form element name
   */
  name?: string;

  /**
   * input change handler
   */
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;

  /**
   * when add modal is closed
   */
  onCloseAddModal?: () => void;

  /**
   * whether this field is required
   */
  required?: boolean;

  /**
   * whether to display the add modal
   */
  showAddModal?: boolean;

  /**
   * input value
   */
  value: string | number | undefined;
}

/**
 * Form select component for genders.
 */
const GenderSelect = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const genderService = useInjection<GenderService>(TYPES.genderService);

  const [showAddModal, setShowAddModal] = useState<boolean>(!!props.showAddModal);
  const [previousShowAddModal, setPreviousShowAddModal] = useState<boolean>();

  const query = useQuery([QueryKey.Genders], () => genderService.list(), {});
  const items = query.data;
  const value = props.value ?? undefined;

  if (!!props.showAddModal !== previousShowAddModal) {
    setShowAddModal(!!props.showAddModal);
    setPreviousShowAddModal(!!props.showAddModal);
  }

  return (
    <>
      <Form.Select
        aria-label={props.ariaLabel ?? 'Gender'}
        className={props.className}
        isInvalid={props.isInvalid}
        name={props.name ?? 'genderId'}
        onChange={props.onChange}
        required={props.required}
        value={value}
      >
        <option value={''}>{query.isFetching ? 'Loading...' : 'Choose a gender'}</option>
        {items &&
          items.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
      </Form.Select>
      {props.required && (
        <Form.Control.Feedback type={'invalid'}>This field is required</Form.Control.Feedback>
      )}
      {showAddModal && (
        <ManageListEditModal
          apiResource={ApiResourceV1.Genders}
          onClose={props.onCloseAddModal ?? ((): void => undefined)}
          queryKey={QueryKey.Genders}
          typeName={'gender'}
        />
      )}
    </>
  );
};

export default withTranslation()(GenderSelect);
