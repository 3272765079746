// Copyright © 2023 CATTLEytics Inc.

import './IconPickerModal.scss';

import React, { PropsWithChildren, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Icons from 'react-icons/io5';

import Button from '../../common/components/Button';
import { camelToTitleCase } from '../utilities/camelCase';
import Modal from './Modal';

/**
 * Component properties
 */
interface Props {
  /**
   * Callback after icon has been selected
   * @param iconName
   */
  onChange: (iconName?: string) => void;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;

  /**
   * If this field is required.
   */
  required?: boolean;

  /**
   * Set the search placeholder text.
   */
  searchPlaceholder?: string;

  /**
   * The selected icon name
   */
  value?: string;
}

/**
 * Provides ability to select an animal by name or ID.
 */
export const IconPickerModal = (props: PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string>('');

  return (
    <Modal
      fullscreen={'md-down'}
      onClose={props.onClose}
      scrollable={true}
      size={'xl'}
      title={t('iconPickerModal|title')}
      visible={true}
    >
      <Form.Group
        className={'py-3'}
        style={{ position: 'sticky', top: '-2rem', backgroundColor: 'var(--bs-body-bg)' }}
      >
        <Form.Control
          onChange={(e): void => setSearchQuery(e.target.value)}
          placeholder={props.searchPlaceholder ?? t('iconPickerModal|searchPlaceholder')}
          type={'text'}
          value={searchQuery}
        />
      </Form.Group>
      <div
        className={'d-grid gap-3 gap-md-0 iconModal'}
        style={{
          gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
          gridAutoRows: '1fr',
        }}
      >
        {Object.keys(Icons)
          .filter((value) =>
            camelToTitleCase(value.substring(2)).toLowerCase().includes(searchQuery.toLowerCase()),
          )
          .map((value) => {
            const Icon = Icons[value as keyof typeof Icons];
            const iconName = camelToTitleCase(value.substring(2)).replace(
              new RegExp(`(${searchQuery})`, 'i'),
              '$1',
            );

            return (
              <Button
                className={'d-flex flex-column align-items-center'}
                key={value}
                onClick={(): void => {
                  props.onChange(value);
                  props.onClose();
                }}
                style={{
                  minWidth: 0,
                  margin: '2px',
                  padding: '0.25rem',
                  textDecoration: 'none',
                  color: value === props.value ? '#FFFFFF' : 'var(--bs-primary)',
                  backgroundColor: value === props.value ? 'var(--bs-primary)' : 'transparent',
                }}
                variant={'link'}
              >
                <Icon color={value === props.value ? '#FFFFFF' : 'var(--bs-primary)'} size={32} />
                <div className={'text-center text-wrap'}>
                  <strong>{iconName}</strong>
                </div>
              </Button>
            );
          })}
      </div>
      <Form.Control.Feedback type={'invalid'}>
        {t('error|fieldRequiredFeedback')}
      </Form.Control.Feedback>
    </Modal>
  );
};
