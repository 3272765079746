// Copyright © 2024 CATTLEytics Inc.

import { sql } from '@codemirror/lang-sql';
import CodeMirror from '@uiw/react-codemirror';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button from '../../common/components/Button';
import { IconCopyToClipboard, IconRun, sleep } from '../../common/utilities';
import { CQL, darkTheme, lightTheme } from './ReportsCodeMirror';

interface Props {
  className?: string;

  /**
   * CQL query string.
   */
  code: string;

  /**
   * Description of query.
   */
  description?: string;
}

export const CqlExample = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const myTheme = document.documentElement.dataset.bsTheme === 'light' ? lightTheme : darkTheme;
  return (
    <section className={props.className}>
      {props.description && <p>{props.description}</p>}
      <div className="code-container">
        <CodeMirror
          basicSetup={{
            lineNumbers: true,
            indentOnInput: false,
            defaultKeymap: true,
          }}
          className={'code-mirror'}
          extensions={[
            sql({
              dialect: CQL,
              upperCaseKeywords: true,
            }),
          ]}
          style={{ width: '100%', overflow: 'scroll' }}
          theme={myTheme}
          value={props.code}
        />
        <div>
          <div className={'mb-3 mx-3'}>
            <Button
              icon={IconRun}
              onClick={async (): Promise<void> => {
                // instantly scroll to the top of the page
                const root = document.querySelector(':root') as Element;
                root.setAttribute('style', 'scroll-behavior: auto;');

                // Timeout ensures styles are applied before scrolling
                setTimeout(function () {
                  window.scrollTo(0, 0);
                  root.removeAttribute('style');
                }, 0);

                // we need this delay to prevent scrolling issues
                await sleep(10);
                // we go to the new page and then a dummy page and then go back which essentially
                // triggers a page refresh
                history.push(
                  `/reports/custom/${encodeURIComponent(props.code.replace(/\n/g, ' '))}`,
                );
                history.push('/empty');
                history.goBack();
              }}
            >
              {t('cqlExample|runButton')}
            </Button>
          </div>
          <div className={'mb-3 mx-3'}>
            <CopyToClipboard text={props.code}>
              <Button
                icon={IconCopyToClipboard}
                label={t('cqlExample|copyButton')}
                type={'button'}
                variant="outline-primary"
              />
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </section>
  );
};
