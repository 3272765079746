// Copyright © 2023 CATTLEytics Inc.

import { useTranslation } from 'react-i18next';

import Page from '../../common/components/Page';
import DaysDriedOffChart from './DaysDriedOffChart';

const LactationManagementPage = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Page breadcrumbs={[{ label: t('Milk'), to: '/test-data' }]} title={t('Lactation Management')}>
      <DaysDriedOffChart />
    </Page>
  );
};

export default LactationManagementPage;
