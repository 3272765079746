// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import Modal from '../../common/components/Modal';

/**
 * Component's input properties.
 */
interface Props extends WithTranslation {
  /**
   * Json displayed in modal
   */
  json: Record<string, string>;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;
}

/**
 * Modal for displaying json.
 */
const JsonModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  return (
    <Modal
      allowHide={true}
      fullscreen={'md-down'}
      onClose={props.onClose}
      size={'xl'}
      title={''}
      visible={true}
    >
      <div className="code-block">
        <pre className="m-0">{JSON.stringify(props.json, null, 2)}</pre>
      </div>
    </Modal>
  );
};

export default withTranslation()(JsonModal);
