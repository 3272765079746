// Copyright © 2023 CATTLEytics Inc.
import { useContext, useMemo } from 'react';

import AuthContext from '../store/auth-context';
import { isSuperAdmin } from '../utilities';

export const useIsAuthUserSuperAdmin = (): boolean => {
  const auth = useContext(AuthContext);

  return useMemo(() => isSuperAdmin(auth), [auth]);
};
