// Copyright © 2023 CATTLEytics Inc.

import { addDays, endOfDay, endOfWeek, isPast, isToday, parseISO, startOfDay } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useInjection } from 'inversify-react';
import React, { Fragment } from 'react';
import { Badge, ListGroup, ListGroupItem, Nav, Placeholder } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import DateTime from '../../common/components/DateTime';
import { TaskStatusIcon } from '../../common/components/TaskStatusIcon';
import { Sort, TaskPeriod } from '../../common/enums';
import TaskService from '../../common/services/taskService';
import { useSettingsContext } from '../../common/store/useSettingsContext';
import { extractAnimalReferencePrimaryTag, IconPriorityHighest } from '../../common/utilities';
import { replaceMentionValues, Task, TaskPriority } from '../../shared';
import { QueryKey } from '../../shared/enums';

interface Props {
  /**
   * Filter list by animal ID.
   */
  animalId?: number;

  /**
   * Additional CSS classes to add to component.
   */
  className?: string;

  completedDateEnd?: Date;
  completedDateStart?: Date;
  dueDateEnd?: Date;
  dueDatePeriod?: TaskPeriod;
  dueDateStart?: Date;
  /**
   * Filter task list by category.
   */
  filterByCategoryId?: number;

  /**
   * Number of tasks to show.
   */
  limit?: number;

  /**
   * Callback when list data is successfully retrieved
   * @param data
   */
  onQuerySuccess?: (data: Task[]) => void;

  onlyCompleted?: boolean;

  /**
   * Filter list by pen ID.
   */
  penId?: number;

  /**
   * Show completed
   */
  showCompleted?: boolean;

  /**
   * Direction to sort the list
   */
  sortDirection?: Sort;

  /**
   * Column to sort the list by
   */
  sortField?: string;
}

/**
 * List tasks.
 */
const TasksListWhiteboard = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();
  const settings = useSettingsContext();

  const now = new Date();
  const todayStart = startOfDay(now);
  const todayEnd = endOfDay(now);
  const weekStart = addDays(startOfDay(now), 1);
  const weekEnd = addDays(endOfDay(weekStart), 7);

  const taskService = useInjection<TaskService>(TYPES.taskService);

  const queryParams: Record<string, string> = {
    sortField: props.sortField ? String(props.sortField) : 'dueDate',
    sortDirection: props.sortDirection ? String(props.sortDirection) : Sort.Ascending,
    limit: props.limit ? String(props.limit) : '10',
  };

  if (props.animalId) {
    queryParams.animalId = String(props.animalId);
  }

  if (props.filterByCategoryId) {
    queryParams.categoryId = String(props.filterByCategoryId);
  }

  if (props.dueDateStart) {
    queryParams.dueDateStart = props.dueDateStart.toISOString();
  }

  if (props.dueDateEnd) {
    queryParams.dueDateEnd = props.dueDateEnd.toISOString();
  }

  if (props.completedDateStart) {
    queryParams.completedDateStart = props.completedDateStart.toISOString();
  }

  if (props.completedDateEnd) {
    queryParams.completedDateEnd = props.completedDateEnd.toISOString();
  }

  if (props.onlyCompleted) {
    queryParams.onlyCompleted = '1';
  }

  if (props.dueDatePeriod) {
    switch (props.dueDatePeriod) {
      case TaskPeriod.Daily:
        queryParams.dueDateStart = todayStart.toISOString();
        queryParams.dueDateEnd = todayEnd.toISOString();
        break;
      case TaskPeriod.Weekly:
        queryParams.dueDateStart = weekStart.toISOString();
        queryParams.dueDateEnd = weekEnd.toISOString();
        break;
      case TaskPeriod.Whenever:
        queryParams.noDueDate = '1';
        break;
    }
  }

  queryParams.showCompleted = props.showCompleted ? '1' : '0';

  if (props.penId) {
    queryParams.penId = String(props.penId);
  }

  const query = useQuery<Task[]>(
    [QueryKey.Tasks, queryParams],
    () => taskService.list(queryParams),
    {
      onSuccess: props.onQuerySuccess,
      refetchInterval: 30_000,
      refetchOnWindowFocus: 'always',
      refetchOnMount: true,
      refetchOnReconnect: 'always',
      retry: true,
    },
  );

  const placeholder = (
    <Placeholder animation={'glow'} className={'w-100 d-flex justify-content-between'}>
      <div className={'w-100 d-flex'}>
        <Placeholder className={'me-3'} md={2} />
        <Placeholder md={4} />
      </div>
      <Placeholder className={'me-1'} md={1} />
    </Placeholder>
  );

  if (query.isLoading) {
    return (
      <Fragment>
        {[...Array(5).keys()].map((key) => (
          <Fragment key={key}>{placeholder}</Fragment>
        ))}
      </Fragment>
    );
  }

  if (query.isError || !query.data) {
    return (
      <AlertError
        message={`Error: Failed to get data at ${formatInTimeZone(
          query.errorUpdatedAt,
          settings.timeZone,
          `${settings.dateFormat} ${settings.timeFormat}`,
        )}`}
      />
    );
  }

  const tasks = query.data;

  return (
    <ListGroup className="overflow-md-auto flex-grow-md-1">
      {tasks.length === 0 && query.isFetched && (
        <p className={'text-center'}>
          <em>{t('No tasks found')}</em>
        </p>
      )}
      {tasks.map((task) => {
        const related = [];

        if (task.animalId || task.penId) {
          if (task.animalId) {
            related.push({
              link: `/animals/${task.animalId}`,
              label: `Animal`,
              value: `#${extractAnimalReferencePrimaryTag(task.animal)}`,
            });
          }

          if (task.penId) {
            related.push({
              link: `/pens/${task.penId}`,
              label: `Pen`,
              value: `#${task.penId}`,
            });
          }
        }

        const animalPrimaryTag = extractAnimalReferencePrimaryTag(task.animal);
        const isOverdue =
          task.completed === false &&
          !!task.dueDate &&
          !isToday(parseISO(task.dueDate)) &&
          isPast(parseISO(task.dueDate));
        return (
          <ListGroupItem action={false} key={task.id}>
            <Nav.Link as={Link} disabled={false} eventKey={'tasks'} to={'/tasks/all'}>
              <div className={'w-100 d-flex justify-content-between'} style={{ minWidth: 0 }}>
                <div
                  className={`text-truncate ${isOverdue ? 'text-danger' : 'text-muted'}`}
                  style={{ minWidth: 0 }}
                >
                  <span className={'me-3'}>
                    <TaskStatusIcon overdue={isOverdue} status={task.status} />
                  </span>
                  {animalPrimaryTag && <small className={'me-3'}>#{animalPrimaryTag}</small>}

                  {task.dueDate && isPast(endOfWeek(parseISO(task.dueDate))) && (
                    <strong className={`me-3 `}>
                      <DateTime date={task.dueDate} hideTime={true} />
                    </strong>
                  )}
                  <span>
                    {replaceMentionValues(task.description.split('\n')[0], (md) => `@${md.name}`)}
                  </span>
                </div>

                {!task.animalId && !task.penId && <div />}

                <div className={'me-2 d-flex align-items-center'}>
                  {task.taskCategory && (
                    <Badge className={'me-3'} pill>
                      {task.taskCategory.name}
                    </Badge>
                  )}
                  {task.animalId && (
                    <Badge bg={'primary'} className={'me-3'} pill>
                      Animal
                    </Badge>
                  )}
                  {task.assignedUser && (
                    <Badge bg={'secondary'} className={'me-3'} pill>
                      {`${task.assignedUser?.firstName} ${task.assignedUser?.lastName}`}
                    </Badge>
                  )}
                </div>
                {task.priority === TaskPriority.Highest && (
                  <IconPriorityHighest
                    style={{
                      color: 'var(--bs-danger)',
                      position: 'absolute',
                      top: 'calc(50% - 8px)',
                      right: 8,
                    }}
                  />
                )}
              </div>
            </Nav.Link>
          </ListGroupItem>
        );
      })}
    </ListGroup>
  );
};

export default TasksListWhiteboard;
