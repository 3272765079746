// Copyright © 2023 CATTLEytics Inc.
import { createElement } from 'react';
import { Stack } from 'react-bootstrap';

import TimelineNode from './HorizontalTimelineNode';
import TimelineContainer from './TimelineContainer';
import { useTimeline } from './TimelineProvider';
import { useFormattedTimelineDates } from './use-formatted-timeline-dates';
import useGetTimelineItems from './use-get-timeline-items';

const HorizontalTimeline = (): JSX.Element => {
  const { dateEnd, dateStart, slots } = useTimeline();
  const { endDate, startDate } = useFormattedTimelineDates();
  const { containerRef, data } = useGetTimelineItems();

  return (
    <TimelineContainer>
      <div className="position-absolute top-0 start-0 ps-2">{startDate}</div>
      <div className="position-absolute top-0 end-0 pe-2">{endDate}</div>
      <Stack direction="vertical" gap={1} style={{ paddingTop: 32 }}>
        <div className="w-100" style={{ minHeight: 5 }}>
          {slots?.horizontalTopLayover &&
            createElement(slots.horizontalTopLayover, { dateEnd, dateStart })}
        </div>
        <div className="position-relative flex-grow-1 w-100" style={{ minHeight: 200 }}>
          <div
            className="w-100 position-absolute "
            style={{
              border: '4px solid #CCC',
              left: 0,
              right: 0,
              top: '10%',
            }}
          />
          <div
            className="mx-4 position-absolute"
            ref={containerRef}
            style={{
              top: `calc(10% - 8px)`,
              bottom: 0,
              width: 'calc(100% - 3rem)',
            }}
          >
            {data.map((item, index) => (
              <TimelineNode item={item} key={index} />
            ))}
          </div>
        </div>
      </Stack>
    </TimelineContainer>
  );
};

export default HorizontalTimeline;
