// Copyright © 2023 CATTLEytics Inc.
import { Fragment, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { MentionData, mentionRegEx } from '../../shared';

type NodeMentionData = MentionData & { key: string };

type Replacer = (mention: NodeMentionData) => ReactNode;

export const replaceMentionValuesWithNode = (value: string, replacer: Replacer): ReactNode[] => {
  const textArray = value.split(mentionRegEx);

  let i = 0;
  const nodes: ReactNode[] = [];
  while (i < textArray.length) {
    const str = textArray[i];
    if (mentionRegEx.test(str)) {
      const node = replacer({
        original: textArray[i],
        trigger: textArray[i + 1],
        name: textArray[i + 2],
        id: textArray[i + 3],
        key: `${textArray[i + 3]}-${i}`,
      });

      nodes.push(node);
      i = i + 4;
    } else {
      nodes.push(<Fragment key={i}>{str}</Fragment>);
      i++;
    }
  }

  return nodes;
};

export const animalLinkReplacer: Replacer = ({ id, name, key }) => (
  <Link key={key} to={`/animals/${id}`}>
    @{name}
  </Link>
);
