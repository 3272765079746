// Copyright © 2024 CATTLEytics Inc.

import { EventProps } from 'react-big-calendar';

import { EventTooltip } from './EventTooltip';

export function EventItem({ event }: EventProps): JSX.Element {
  return (
    <EventTooltip event={event}>
      <div className="h-100">{event.title}</div>
    </EventTooltip>
  );
}
