// Copyright © 2024 CATTLEytics Inc.

import { useState } from 'react';
import { BsFullscreen } from 'react-icons/bs';
import { useQuery } from 'react-query';

import Button from '../../common/components/Button';
import { Props as ModalDialogProps1 } from '../../common/components/Modal';
import { api } from '../../common/utilities';
import { ApiResourceV1, QueryKey } from '../../shared';
import { Configuration } from '../../shared/entities/configuration';

/**
 * Component input properties.
 */
interface Props extends Omit<ModalDialogProps1, 'visible'> {
  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;
}

export function ChatbotModal(props: Props): JSX.Element {
  const [fullscreen, setFullscreen] = useState(false);
  const chatbotQuery = useQuery(
    [QueryKey.Settings, 'AI'],
    () => api<Configuration[]>('GET', ApiResourceV1.Configuration),
    { keepPreviousData: true },
  );

  if (chatbotQuery.isLoading) {
    return <></>;
  }

  const chatbotUrl = (chatbotQuery.data ?? []).find(
    (config) => config.path === 'AI/ChatbotURL',
  )?.value;

  const onFullScreenToggle = (): void => {
    setFullscreen((prev) => !prev);
  };

  return (
    <div
      className={`chatbot ${
        fullscreen ? 'chatbot-fullscreen' : ''
      } bg-white shadow-sm rounded p-4 d-flex flex-6 flex-column`}
      style={{ zIndex: 1019 }}
    >
      <iframe className="flex-5 flex-grow-1 " src={chatbotUrl} title="CowGPT" />
      <div className="flex-1 d-flex justify-content-between gap-2">
        <Button className="flex-5 flex-grow-1" onClick={props.onClose}>
          Close
        </Button>
        <Button onClick={onFullScreenToggle}>
          <BsFullscreen />
        </Button>
      </div>
    </div>
  );
}
