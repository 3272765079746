// Copyright © 2023 CATTLEytics Inc.

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonCreate from '../../common/components/ButtonCreate';
import Page from '../../common/components/Page';
import SireModal from './SireModal';
import SireTable from './SireTable';

/**
 * Sires page to show sire table.
 */
const SiresPage = (): JSX.Element => {
  const { t } = useTranslation();

  const [sireModalVisible, setSireModalVisible] = useState<boolean>(false);

  return (
    <Page
      buttons={<ButtonCreate label={t('Create')} onClick={(): void => setSireModalVisible(true)} />}
      title={t('Sires')}
    >
      <SireTable />
      {sireModalVisible && <SireModal onClose={(): void => setSireModalVisible(false)} />}
    </Page>
  );
};

export default SiresPage;
