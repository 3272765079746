// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { FaSortDown, FaSortUp } from 'react-icons/fa';

import { Sort } from '../enums';

interface Props {
  /**
   * Direction of sort.
   */
  sortDirection: Sort;

  /**
   * Display this component.
   */
  visible: boolean;
}

/**
 * A component to indicate a sort direction.
 */
const SortIndicator = (props: Props): JSX.Element => {
  return (
    <span>
      {props.visible ? (
        props.sortDirection === Sort.Ascending ? (
          <FaSortUp />
        ) : (
          <FaSortDown />
        )
      ) : null}
    </span>
  );
};

export default SortIndicator;
