// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { withTranslation } from 'react-i18next';

interface Props {
  className?: string;
}

/**
 * @description Routable component to show a single note.
 */
const NotePage = (props: React.PropsWithChildren<Props>): JSX.Element => {
  return <section className={props.className}>single note</section>;
};

export default withTranslation()(NotePage);
