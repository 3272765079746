// Copyright © 2023 CATTLEytics Inc.

import { Animal } from '../../shared';
import Entity from './entity';
import Pen from './pen';

/**
 * @description Represents a note.
 * @export
 * @interface Note
 */
export default interface Note extends Entity {
  /**
   * Related Animal.
   */
  animal?: Animal;

  /**
   * Related animal identifier.
   */
  animalId?: number;

  /**
   * Body of the note.
   */
  body: string;

  /**
   * Name of Note.
   */
  pen?: Pen;

  /**
   * Identifier of the related pen.
   */
  penId?: number;

  /**
   * Tags to organize notes.
   */
  tags?: string[];
}

/**
 * Returns an Note with all required fields set.
 */
export function noteDefault(): Note {
  return {
    id: 0,
    body: '',
    createdDate: new Date(),
    modifiedDate: new Date(),
  };
}
