// Copyright © 2023 CATTLEytics Inc.

/**
 * Animal Reference Type Enumerations
 *
 * Note: these types mapped 1:1 to the system defined rows in animal_reference_type table.
 */
export enum AnimalReferenceType {
  PrimaryTag = 1,
  Name = 2,
}
