// Copyright © 2023 CATTLEytics Inc.

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop(): JSX.Element | null {
  const { pathname } = useLocation();

  useEffect(() => {
    // note the "as auto" is because the type is correctly saying "instant" in not valid
    // https://kilianvalkhof.com/2022/css-html/preventing-smooth-scrolling-with-javascript/
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' as 'auto' });
  }, [pathname]);

  return null;
}
