// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MdSave } from 'react-icons/md';

import Button from '../../common/components/Button';

/**
 * Defines the input properties for this component.
 */
interface Props {
  /**
   * Whether the button is in a busy state
   */
  busy?: boolean;

  /**
   * Whether the button should be disabled.
   */
  disabled?: boolean;

  /**
   * Button text.
   */
  label?: string;

  /**
   * On click callback.
   */
  onClick?: () => void;
}

/**
 * A large save button.
 */
const ButtonSave = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Button
      className={'text-nowrap mt-3'}
      disabled={props.disabled}
      onClick={props.onClick}
      size={'lg'}
      style={{ display: 'flex', alignItems: 'center', minWidth: '10rem', justifyContent: 'center' }}
      type={'submit'}
      variant={'primary'}
    >
      {props.busy ? (
        <span style={{ height: '30px' }}>
          <Spinner animation="border" aria-hidden="true" as="span" role="status" size="sm" />
          <span className="visually-hidden">Loading...</span>
        </span>
      ) : (
        <>
          <MdSave className={'me-1'} style={{ height: '100%' }} />
          {props.label || t('Save')}
        </>
      )}
    </Button>
  );
};

export default ButtonSave;
