// Copyright © 2023 CATTLEytics Inc.

import { inject, injectable } from 'inversify';
import { Serializer } from 'ts-japi';

import { TYPES } from '../../../types';
import Site from '../../common/entities/site';
import JsonApiQueryParams from '../../common/interfaces/jsonApiQueryParams';
import type Logger from '../../logger/logger';
import { JsonApiTypes } from '../enums/jsonApiTypes';
import Api2Service from './api2Service';

const path = '/v1/sites';
/**
 * Service for managing site data via REST API.
 */
@injectable()
export default class SiteService {
  /**
   * Logger.
   */
  private logger: Logger;

  /**
   * Service for accessing the HTTP API.
   */
  private apiService: Api2Service;

  /**
   * JSON:API serializer.
   */
  private serializer: Serializer;

  /**
   * Creates an instance of SiteService.
   * @param {Logger} logger Logger instance.
   * @param {ApiService} apiService API Service instance
   */
  constructor(
    @inject(TYPES.logger) logger: Logger,
    @inject(TYPES.api2Service) apiService: Api2Service,
  ) {
    this.logger = logger;
    this.apiService = apiService;
    this.serializer = new Serializer(JsonApiTypes.Sites);
  }

  /**
   * @inheritdoc
   */
  async getSite(siteId: number, params?: JsonApiQueryParams): Promise<Site> {
    // set default include query parameters
    if (!params) {
      params = {};
    }
    // if (params && !params.include) {
    //   params.include = ['organization'];
    // }
    const result = await this.apiService.get<Site>(`/v1/sites/${siteId}`, params);
    if (!result) {
      throw new Error('No result');
    }

    this.cast(result);

    return result;
  }

  /**
   * Retrieve a list of sites.
   * @return Site objects
   */
  async list(params?: Record<string, string>): Promise<Site[]> {
    const result = await this.apiService.get<Site[]>(path, params);
    if (!result) {
      return [];
    }

    const data = result as Site[];
    data.forEach((item) => this.cast(item));
    return data;
  }

  async patch(
    id: number,
    attributes: Record<string, string | number | null>,
  ): Promise<Site | undefined> {
    // make sure record identifier is included
    attributes.id = id;

    const result = await this.apiService.patch(`${path}/${id}`, attributes);
    if (!result) {
      return undefined;
    }

    const data = result as Site;
    this.cast(data);
    return data;
  }

  async post(attributes: Record<string, string>): Promise<Site | undefined> {
    const result = await this.apiService.post<Site>(`${path}`, attributes);
    if (!result) {
      return undefined;
    }
    this.cast(result as Site);
    return result;
  }

  /**
   * @inheritdoc
   */
  async patchSite(id: number, attributes: Record<string, string | number | null>): Promise<null> {
    // make sure record identifier is included
    attributes.id = id;

    return await this.apiService.patch(`/v1/sites/${id}`, attributes, undefined, {
      'Content-Type': 'application/json',
    });
  }

  async delete(id: number): Promise<null> {
    return await this.apiService.delete(`${path}/${id}`);
  }

  /**
   * Casts fields into javascript types.
   * @param {JsonApiResource<Site>} item
   */
  cast(item: Site): void {
    const data = item as Site;
    if (!data) {
      return;
    }

    // convert region string to object
  }
}
