// Copyright © 2023 CATTLEytics Inc.

import { parseISO } from 'date-fns';
import { inject, injectable } from 'inversify';

import { TYPES } from '../../../types';
import type Logger from '../../logger/logger';
import { Animal } from '../../shared';
import Api2Service from './api2Service';

const path = '/v1/animals';

/**
 * Service for managing animal data via REST API.
 */
@injectable()
export default class AnimalService {
  private logger: Logger;

  // Service for accessing the HTTP API.
  private apiService: Api2Service;

  /**
   * Creates an instance of AnimalTestDayService.
   * @param logger Logger instance.
   * @param apiService API Service instance
   */
  constructor(
    @inject(TYPES.logger) logger: Logger,
    @inject(TYPES.api2Service) apiService: Api2Service,
  ) {
    this.logger = logger;
    this.apiService = apiService;
  }

  /**
   * @inheritdoc
   */
  async getAnimals(params?: Record<string, string | undefined>): Promise<Animal[]> {
    const result = await this.apiService.get<Animal[]>(path, params);
    if (!result) {
      return [];
    }

    result.forEach((item) => this.cast(item));

    return result;
  }

  async patch(
    id: number | string,
    attributes: Record<string, string | Record<string, string>[]>,
  ): Promise<null> {
    // make sure record identifier is included
    attributes.id = String(id);

    return await this.apiService.patch(`${path}/${id}`, attributes);
  }

  async create(
    attributes: Record<string, string | Record<string, string>[]>,
  ): Promise<Animal | null> {
    return await this.apiService.post(`${path}`, attributes);
  }

  async get(id: number | string, params?: Record<string, string>): Promise<Animal | undefined> {
    const result = await this.apiService.get<Animal>(`${path}/${id}`, params);
    if (!result) {
      return undefined;
    }

    const data = result as Animal;
    this.cast(data);
    return data;
  }

  /**
   * Casts fields into javascript types.
   * @param item
   * @return {void}
   */
  cast(item: Animal): void {
    const data = item;
    if (!data) {
      return;
    }
    if (data.birthDate) {
      data.birthDate = data.birthDate.slice(0, 10);
    }
    if (data.modifiedDate) {
      data.modifiedDate = parseISO(data.modifiedDate as unknown as string);
    }
    if (data.createdDate) {
      data.createdDate = parseISO(data.createdDate as unknown as string);
    }

    if (data.animalEventLatest?.eventDateTime) {
      data.animalEventLatest.eventDateTime = parseISO(
        data.animalEventLatest.eventDateTime as unknown as string,
      );
    }
    if (data.animalTestDayLatest?.testDate) {
      data.animalTestDayLatest.testDate = parseISO(
        data.animalTestDayLatest.testDate as unknown as string,
      );
    }
    if (data.animalTestDayLatestMilkProperties?.fatPct) {
      data.animalTestDayLatestMilkProperties.fatPct = Number(
        (data.animalTestDayLatestMilkProperties.fatPct * 100).toFixed(2),
      );
    }
  }
}
