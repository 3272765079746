// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useContext, useEffect, useState } from 'react';
import { Form, FormGroup, Placeholder, PlaceholderButton, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import ButtonModalCancel from '../../common/components/ButtonModalCancel';
import ButtonModalSave from '../../common/components/ButtonModalSave';
import Modal from '../../common/components/Modal';
import Required from '../../common/components/Required';
import RoleSelect from '../../common/components/RoleSelect';
import SiteSelect from '../../common/components/SiteSelect';
import RoleService from '../../common/services/roleService';
import UserService from '../../common/services/userService';
import AuthContext from '../../common/store/auth-context';
import Logger from '../../logger/logger';
import { Role, User } from '../../shared';
import { RoleEnum } from '../../shared';
import { QueryKey } from '../../shared/enums';

/**
 * Component's input properties.
 */
interface Props {
  // Additional class names to pass to the component.
  className?: string;

  // Callback when modal is closed.
  onClose: () => void;

  /**
   * The user identifier of the user we will assign to a site
   */
  userId: number;
}

/**
 * Modal to add/edit an animal user.
 */
const UserManageRolesModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const logger = useInjection<Logger>(TYPES.logger);
  const userService = useInjection<UserService>(TYPES.userService);
  const roleService = useInjection<RoleService>(TYPES.roleService);

  const auth = useContext(AuthContext);

  const [errorMessage, setErrorMessage] = useState<string>();
  const [validated, setValidated] = useState<boolean>(false);
  const [siteId, setSiteId] = useState<number>();
  const [role, setRole] = useState<RoleEnum>(RoleEnum.User);
  const [user, setUser] = useState<User>();
  const [rolesBySiteId, setRolesBySiteId] = useState<Record<number, RoleEnum>>();

  const query = useQuery<User | undefined>(
    [QueryKey.Users, props.userId],
    () => userService.get(props.userId ?? -1),
    {
      keepPreviousData: true,
      enabled: !!props.userId,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  const queryRoles = useQuery<Role[]>([QueryKey.Roles, props.userId], () =>
    roleService.list({ userId: String(props.userId) }),
  );

  useEffect(() => {
    if (query.data) {
      setUser(query.data);
    }
  }, [query.data]);

  useEffect(() => {
    if (queryRoles.data) {
      const r: Record<number, RoleEnum> = {};
      for (const role of queryRoles.data) {
        r[role.siteId] = role.role;
      }
      setRolesBySiteId(r);
    }
  }, [queryRoles.data]);

  const mutation = useMutation<Role | undefined>(
    () => {
      return roleService.post({
        siteId: Number(auth.isSuperAdmin ? siteId : auth.siteId),
        role: role,
        userId: props.userId,
      });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKey.Users);
        await queryClient.invalidateQueries(QueryKey.Roles);
      },
    },
  );

  useEffect(() => {
    const invalidElements = document.querySelectorAll('input.form-control:invalid');
    if (invalidElements.length > 0) {
      invalidElements[0].closest('.form-group')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [validated]);

  const queryClient = useQueryClient();

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      await mutation.mutateAsync();

      props.onClose();
    } catch (err) {
      logger.error(`Site could not be attached to user.`, err, user, siteId);
      setErrorMessage(t(`Site could not be attached to user.`));
    }
  };

  const placeholder = (
    <Placeholder animation={'glow'}>
      <Placeholder xs={6} />
      <Placeholder className={'mb-5'} size={'lg'} xs={12} />
      <Placeholder xs={6} />
      <Placeholder className={'mb-5'} size={'lg'} xs={12} />
    </Placeholder>
  );

  const form = (
    <Form noValidate onSubmit={handleFormSubmit} validated={validated}>
      {auth.isSuperAdmin && (
        <FormGroup className="form-group mb-3" controlId="formSite">
          <Form.Label>
            {t('Select a site')} <Required />
          </Form.Label>
          <SiteSelect
            onChange={(e): void => setSiteId(Number(e.target.value))}
            required={true}
            value={String(siteId)}
          />
          <Form.Text className={'text-muted'}>
            {t('userManageRolesModal|siteRoleSelectionText')}
          </Form.Text>
          <Form.Control.Feedback type={'invalid'}>
            {t('common|fieldRequiredFeedback')}
          </Form.Control.Feedback>
        </FormGroup>
      )}
      <FormGroup className="form-group mb-3" controlId="formRole">
        <Form.Label>
          {t('Choose a role')} <Required />
        </Form.Label>
        <RoleSelect onChange={(role): void => setRole(role)} value={String(role)} />
        <Form.Text className={'text-muted'}>The role to assign to this user.</Form.Text>
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </FormGroup>
      {user && (
        <div>
          <strong>
            {user.firstName} {user.lastName} has the following roles:
          </strong>
          <Table>
            <thead>
              <tr>
                <th>Site</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              {user.sites &&
                user.sites.map((site) => (
                  <tr>
                    <td>
                      {site.name} <small className={'text-muted'}>({site.id})</small>
                    </td>
                    <td>{(rolesBySiteId && rolesBySiteId[site.id]) ?? '-'}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      )}

      <AlertErrorForModal message={errorMessage} />

      <div className="modal-footer modal-footer-in-form">
        <ButtonModalCancel
          disabled={mutation.isLoading || query.isLoading}
          onClick={props.onClose}
        />
        <ButtonModalSave
          busy={mutation.isLoading}
          disabled={mutation.isLoading || query.isLoading}
        />
      </div>
    </Form>
  );

  return (
    <Modal
      fullscreen={'md-down'}
      onClose={props.onClose}
      size={'lg'}
      title={`Assign a role to ${user?.firstName ?? '...'}`}
      visible={true}
    >
      {query.isError && (
        <AlertError message={`Something unexpected happened while retrieving user.`} />
      )}
      {query.isFetching ? placeholder : form}
      {query.isFetching && (
        <div className="modal-footer modal-footer-in-form">
          <PlaceholderButton variant={'secondary'} xs={2} />
          <PlaceholderButton xs={2} />
        </div>
      )}
    </Modal>
  );
};

export default UserManageRolesModal;
