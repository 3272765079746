// Copyright © 2023 CATTLEytics Inc.

import { useTranslation } from 'react-i18next';

import Page from '../../common/components/Page';
import MastitisChart from './MastitisChart';

const MastitisPage = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Page breadcrumbs={[{ label: t('Milk'), to: '/test-data' }]} title={t('Mastitis')}>
      <MastitisChart />
    </Page>
  );
};

export default MastitisPage;
