// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useContext, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GiCow, GiMilkCarton } from 'react-icons/gi';
import { MdOutlineEditNote } from 'react-icons/md';
import { RiStethoscopeLine } from 'react-icons/ri';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import cattleyticsLogoDark from '../../../assets/img/cattleytics-logo-dark-with-tagline.png';
import { TYPES } from '../../../types';
import BreedImage from '../../animals/components/BreedImage';
import Button from '../../common/components/Button';
import LogoEnvironmentBadge from '../../common/components/LogoEnvironmentBadge';
import Session, { authDefault } from '../../common/entities/session';
import AuthService from '../../common/services/authService';
import SiteService from '../../common/services/siteService';
import AuthContext from '../../common/store/auth-context';
import {
  getFirstLetters,
  IconCreate,
  IconOperations,
  IconSetup,
  IconSuperAdmin,
  isSiteAdminOrAbove,
  isSuperAdmin,
} from '../../common/utilities';
import { QueryKey, Site } from '../../shared';
import AppLinkModal from '../../users/components/AppLinkModal';
import NavGroupHeading from './NavGroupHeading';
import NavSubItem from './NavSubItem';

/**
 * Component properties.
 */
interface Props {
  /**
   * Callback when App Link button is clicked.
   *
   * Note: This component gets removed from dom so new button modal needs to
   * be located elsewhere.
   */
  onAppLinkModalVisible: () => void;

  /**
   * Callback when Change password is clicked.
   *
   * Note: This component gets removed from dom so new button modal needs to
   * be located elsewhere.
   */
  onChangePassword: () => void;
  /**
   * Callback when Edit profile is clicked.
   *
   * Note: This component gets removed from dom so new button modal needs to
   * be located elsewhere.
   */
  onEditProfile: () => void;
  /**
   * Callback when new button is clicked.
   *
   * Note: This component gets removed from dom so new button modal needs to
   * be located elsewhere.
   */
  onNewButtonClick: (visible: boolean) => void;

  /**
   * Callback when Site switch is clicked.
   *
   * Note: This component gets removed from dom so new button modal needs to
   * be located elsewhere.
   */
  onSiteSwitch: () => void;

  /**
   * Callback to set session based on auth request.
   */
  setSession: React.Dispatch<React.SetStateAction<Session>>;
}

/**
 * Left side navigation component.
 */
const NavLeft = (props: Props): JSX.Element => {
  const [appLinkModalVisible, setAppLinkModalVisible] = useState<boolean>(false);
  const authService = useInjection<AuthService>(TYPES.authService);
  const siteService = useInjection<SiteService>(TYPES.siteService);
  const auth = useContext(AuthContext);

  const { t } = useTranslation();

  const [site, setSite] = useState<Site>();

  useQuery<Site | undefined>(
    [QueryKey.Sites, auth.siteId],
    () => siteService.getSite(Number(auth.siteId)),
    {
      onSuccess: (data) => setSite(data),
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: true,
    },
  );

  //const experimental = !!parseInt(getEnv('EXPERIMENTAL') ?? '0');

  const signOutMutation = useMutation(() => authService.delete(1), {
    onSuccess: () => props.setSession({ ...authDefault(), ...{ authenticated: false } }),
  });

  const signOut = async (): Promise<void> => {
    await signOutMutation.mutateAsync();
  };
  const orgLogoSize = '50px';
  return (
    <div
      className={'nav-left mb-3'}
      onClick={(): void => {
        // returns overflow back to original state
        document.body.style.overflow = 'auto';

        // scroll browser back up to the top
        window.scroll({ top: 0, left: 0, behavior: 'auto' });
      }}
    >
      {/* Only show sticky logo on xl */}
      <Link
        className={'d-xl-block bg-primary'}
        style={{
          width: '100%',
          height: '50px',
          padding: '1em 0',
          position: 'sticky',
          zIndex: 150000,
          top: '0',
        }}
        to={'/dashboard'}
      >
        <img alt={'CATTLEytics'} src={cattleyticsLogoDark} style={{ height: '26px' }} />
        <LogoEnvironmentBadge />
      </Link>
      <hr style={{ backgroundColor: 'white' }} />
      <div className={'d-flex justify-content-center align-items-center'}>
        {site?.iconUrlSigned && (
          <div style={{ width: orgLogoSize, height: orgLogoSize }}>
            <img
              alt={site.name}
              className={'w-100 h-100 d-flex'}
              src={site.iconUrlSigned}
              style={{ border: '2px solid white', borderRadius: '50%' }}
            />
          </div>
        )}
        {!site?.iconUrlSigned && (
          <div
            className={'d-flex justify-content-center align-items-center'}
            style={{
              color: 'white',
              fontSize: '13px',
              fontWeight: 'bold',
              width: orgLogoSize,
              height: orgLogoSize,
              border: '2px solid white',
              borderRadius: '50%',
            }}
          >
            {site?.name ? <span>{getFirstLetters(site?.name, 2)}</span> : null}
          </div>
        )}
      </div>
      <h4 className="mt-3 text-center" style={{ color: 'white', fontSize: '18px' }}>
        {site?.name}
      </h4>
      <Nav.Link
        className="p-0 text-center"
        onClick={props.onSiteSwitch}
        style={{ fontSize: '12px' }}
      >
        {t('Switch Site')}
      </Nav.Link>
      <hr style={{ backgroundColor: 'white' }} />
      <div style={{ width: '100%', paddingRight: '16px' }}>
        <Button
          className={'btn-new mx-auto'}
          icon={IconCreate}
          label={t('New')}
          onClick={(): void => props.onNewButtonClick(true)}
          style={{
            display: 'block',
            borderColor: '#c9dde0',
            margin: '0 auto',
            paddingLeft: '3rem',
            paddingRight: '3rem',
            transition: '.4s',
          }}
          type={'button'}
        />
      </div>

      <hr style={{ backgroundColor: 'white' }} />

      <Nav className="navbar-dark flex-column ps-5" defaultActiveKey="/dashboard" variant="pills">
        <Nav.Link as={Link} eventKey="animals-all" style={{ padding: 0 }} to={'/'}>
          <NavGroupHeading icon={<GiCow />}>{t('navLeft|herd')}</NavGroupHeading>
        </Nav.Link>
        <Nav.Link as={Link} className={''} eventKey={'dashboard'} to={'/dashboard'}>
          {t('navLeft|dashboard')}
        </Nav.Link>
        <Nav.Link as={Link} eventKey={'pens'} to={'/pens'}>
          {t('navLeft|pens')}
        </Nav.Link>
        <Nav.Link as={Link} eventKey={'animals'} to={'/animals/cows'}>
          {t('navLeft|animals')}
        </Nav.Link>
        <NavSubItem eventKey={'animals'} label={t('navLeft|cows')} link={'/animals/cows'} />
        <NavSubItem eventKey={'animals'} label={t('navLeft|calves')} link={'/animals/calves'} />
        <NavSubItem eventKey={'animals'} label={t('navLeft|heifers')} link={'/animals/heifers'} />
        <Nav.Link as={Link} eventKey={'sires'} to={'/sires'}>
          {t('navLeft|sires')}
        </Nav.Link>
        <Nav.Link as={Link} eventKey={'event-summary'} to={'/events-summary-by-month'}>
          {t('navLeft|eventsSummary')}
        </Nav.Link>
        <Nav.Link as={Link} eventKey={'animal-groups'} to={'/animals/groups'}>
          {t('navLeft|groups')}
        </Nav.Link>
        <Nav.Link as={Link} eventKey="animals-all" style={{ padding: 0 }} to={'/'}>
          <NavGroupHeading
            icon={<BreedImage fillColor={'#d8e6eb'} height={'20px'} width={'20px'} />}
          >
            {t('Reproduction')}
          </NavGroupHeading>
        </Nav.Link>
        <Nav.Link as={Link} disabled={false} eventKey={'breeding'} to={'/events/7'}>
          {t('Breeding')}
        </Nav.Link>
        <Nav.Link as={Link} disabled={true} eventKey={'synchronization'} to={'/'}>
          {t('Synchronization')}
        </Nav.Link>
        <Nav.Link as={Link} disabled={false} eventKey={'calving'} to={'/events/13'}>
          {t('Calving')}
        </Nav.Link>
        <Nav.Link as={Link} disabled={true} eventKey={'Pregnancy'} to={'/'}>
          {t('Pregnancy')}
        </Nav.Link>
        <Nav.Link
          as={Link}
          disabled={false}
          eventKey={'reproduction-management'}
          to={'/reproduction/reproduction-management'}
        >
          {t('navLeft|reproductionManagement')}
        </Nav.Link>
        <Nav.Link as={Link} eventKey="milk" style={{ padding: 0 }} to={'/test-data'}>
          <NavGroupHeading icon={<GiMilkCarton />}>{t('Milk')}</NavGroupHeading>
        </Nav.Link>
        <Nav.Link as={Link} disabled={false} eventKey={'milk-quality'} to={'/milk/milk-quality'}>
          {t('Milk Quality')}
        </Nav.Link>
        <Nav.Link as={Link} disabled={false} eventKey={'milk-quantity'} to={'/milk/milk-quantity'}>
          {t('Milk Quantity')}
        </Nav.Link>
        <Nav.Link
          as={Link}
          disabled={false}
          eventKey={'lactation-management'}
          to={'/milk/lactation-management'}
        >
          {t('Lactation Management')}
        </Nav.Link>
        <Nav.Link as={Link} disabled={false} eventKey={'mastitis'} to={'/milk/mastitis'}>
          {t('Mastitis')}
        </Nav.Link>
        <Nav.Link as={Link} eventKey="animal-health" style={{ padding: 0 }} to={'/'}>
          <NavGroupHeading icon={<RiStethoscopeLine />}>{t('Animal Health')}</NavGroupHeading>
        </Nav.Link>
        <Nav.Link as={Link} disabled={true} eventKey={'disease-incidence'} to={'/'}>
          {t('Disease Incidence')}
        </Nav.Link>
        <Nav.Link
          as={Link}
          disabled={false}
          eventKey={'treatments'}
          to={'/active-treatments/completed'}
        >
          {t('Treatments')}
        </Nav.Link>
        <Nav.Link
          as={Link}
          disabled={false}
          eventKey={'ongoing-treatments'}
          to={'/active-treatments/pending'}
        >
          {t('Ongoing Treatments')}
        </Nav.Link>
        <Nav.Link as={Link} disabled={true} eventKey={'preventive-medicine'} to={'/'}>
          {t('Preventive Medicine')}
        </Nav.Link>
        <Nav.Link as={Link} disabled={true} eventKey={'post-mortems'} to={'/'}>
          {t('Post Mortems')}
        </Nav.Link>
        <Nav.Link as={Link} eventKey="operations" style={{ padding: 0 }} to={'/'}>
          <NavGroupHeading icon={<IconOperations />}>{t('Operations')}</NavGroupHeading>
        </Nav.Link>
        <Nav.Link as={Link} disabled={true} eventKey={'inventory'} to={'/'}>
          {t('Inventory')}
        </Nav.Link>
        <Nav.Link as={Link} disabled={false} eventKey={'movements'} to={'/events/4'}>
          {t('Movements')}
        </Nav.Link>
        <Nav.Link as={Link} disabled={false} eventKey={'inventory-log'} to={'/events/all'}>
          {t('Inventory Log')}
        </Nav.Link>
        <Nav.Link as={Link} disabled={false} eventKey={'calving'} to={'/events/13'}>
          {t('Calving')}
        </Nav.Link>
        <Nav.Link as={Link} disabled={false} eventKey={'methane'} to={'/operations/methane'}>
          {t('Methane')}
        </Nav.Link>
        <Nav.Link
          as={Link}
          disabled={true}
          eventKey={'breeding-plans'}
          to={'/operations/breeding-plans'}
        >
          {t('navLeft|breedingPlans')}
        </Nav.Link>
        <Nav.Link as={Link} disabled={false} eventKey={'schedules'} to={'/operations/schedules'}>
          {t('navLeft|schedules')}
        </Nav.Link>
        <Nav.Link as={Link} eventKey="dairy-log" style={{ padding: 0 }} to={'/'}>
          <NavGroupHeading icon={<MdOutlineEditNote />}>{t('Dairy Log')}</NavGroupHeading>
        </Nav.Link>
        <Nav.Link as={Link} disabled={false} eventKey={'logs'} to={'/dairy-logs'}>
          {t('Logs')}
        </Nav.Link>
        <Nav.Link as={Link} disabled={false} eventKey={'notes'} to={'/notes'}>
          {t('Notes')}
        </Nav.Link>
        <Nav.Link as={Link} disabled={false} eventKey={'tasks'} to={'/tasks/all'}>
          {t('Tasks')}
        </Nav.Link>

        <Nav.Link as={Link} eventKey="setup-administration" style={{ padding: 0 }} to={'/'}>
          <NavGroupHeading icon={<IconSetup />}>{t('Setup and Administration')}</NavGroupHeading>
        </Nav.Link>
        {isSiteAdminOrAbove(auth) && (
          <Nav.Link as={Link} eventKey="animals-cows" to={'/users/all'}>
            {t('nav|navLeft|users')}
          </Nav.Link>
        )}
        <Nav.Link as={Link} eventKey="misc-lists" to={'/lists'}>
          {t('Lists')}
        </Nav.Link>
        <Nav.Link as={Link} eventKey="products" to={'/lists/products'}>
          {t('Products')}
        </Nav.Link>
        {isSiteAdminOrAbove(auth) && (
          <>
            <Nav.Link as={Link} eventKey="misc-settings" to={'/settings/general'}>
              {t('Settings')}
            </Nav.Link>
            <Nav.Link as={Link} eventKey="misc-data-import" to={'/import'}>
              {t('Data Import')}
            </Nav.Link>
            {isSuperAdmin(auth) && (
              <>
                <Nav.Link as={Link} eventKey="admin" to={'/system'}>
                  {t('System')} <IconSuperAdmin />
                </Nav.Link>
                <Nav.Link as={Link} eventKey="sites" to={'/sites'}>
                  {t('Sites')} <IconSuperAdmin />
                </Nav.Link>
              </>
            )}
          </>
        )}
        <hr style={{ backgroundColor: 'white' }} />
        <Nav.Link onClick={props.onAppLinkModalVisible}>{t('navLeft|mooToDo')}</Nav.Link>
        <Nav.Link onClick={props.onEditProfile}>{t('Edit profile')}</Nav.Link>
        <Nav.Link onClick={props.onChangePassword}>{t('Change password')}</Nav.Link>
        <Nav.Link eventKey="misc-sign-out" onClick={signOut}>
          {t('Sign out')}
        </Nav.Link>
      </Nav>
      {appLinkModalVisible && <AppLinkModal onClose={(): void => setAppLinkModalVisible(false)} />}
    </div>
  );
};

export default NavLeft;
