// Copyright © 2023 CATTLEytics Inc.
export enum DairyLogEventType {
  Emergency = 'Emergency', //1,
  Feeding = 'Feeding', //2,
  Health = 'Health', //0,
  Maintenance = 'Maintenance', //3,
  Other = 'Other', //4,
}

export const DairyLogEventTypeIdMap = {
  [DairyLogEventType.Health]: 0,
  [DairyLogEventType.Emergency]: 1,
  [DairyLogEventType.Feeding]: 2,
  [DairyLogEventType.Maintenance]: 3,
  [DairyLogEventType.Other]: 4,
};
