// Copyright © 2024 CATTLEytics Inc.

import { formatInTimeZone } from 'date-fns-tz';
import { useContext } from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Page from '../common/components/Page';
import SettingsContext from '../common/store/settings-context';
import { IconWarning } from '../common/utilities';
import { PendingRequests } from './components/PendingRequests';
import Schedules from './Schedules';

export function SchedulesPage(): JSX.Element {
  const { t } = useTranslation();
  const settings = useContext(SettingsContext);

  const userTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const showTzWarning = userTz !== settings.timeZone;
  const userTzShort = formatInTimeZone(new Date(), userTz, 'zzz');
  const siteTzShort = formatInTimeZone(new Date(), settings.timeZone, 'zzz');

  return (
    <Page
      buttons={[<PendingRequests />]}
      otherHeaderElement={
        showTzWarning ? (
          <Alert variant="danger">
            <IconWarning className="me-2" />
            <strong>{t('scheduleCalendar|warningLabel')}</strong>:{' '}
            {t('scheduleCalendar|warningTzLabel', {
              userTz: userTzShort,
              serverTz: siteTzShort,
            })}
          </Alert>
        ) : (
          <></>
        )
      }
      title={t('Schedules')}
    >
      <Schedules />
    </Page>
  );
}
