// Copyright © 2023 CATTLEytics Inc.

import React, { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import ButtonCreate from '../../common/components/ButtonCreate';
import Page from '../../common/components/Page';
import PenModal from '../../pens/components/PenModal';
import PenTable from './PenTable';

/**
 * Routable component to list of pens.
 */
const PensPage = (prop: WithTranslation): JSX.Element => {
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  return (
    <Page
      buttons={
        <ButtonCreate
          label={prop.t('Create Pen')}
          onClick={(): void => setCreateModalVisible(true)}
        />
      }
      title={prop.t('Pens')}
    >
      <PenTable />
      {createModalVisible && <PenModal onClose={(): void => setCreateModalVisible(false)} />}
    </Page>
  );
};

export default withTranslation()(PensPage);
