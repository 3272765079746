// Copyright © 2023 CATTLEytics Inc.

import './i18n';
import '@fontsource/montserrat';
import '@fontsource/abril-fatface';
import '@fontsource/lora';
import './index.scss';

import { Provider } from 'inversify-react';
import React, { Suspense } from 'react';
import { Spinner } from 'react-bootstrap';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ReactGA from 'react-ga4';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';

import App from './app/App';
import DevDisplaySize from './app/common/components/DevDisplaySize';
import ScrollToTop from './app/common/components/ScrollToTop';
import { getEnv } from './app/common/utilities';
import ErrorPage from './app/misc/components/ErrorPage';
import container from './inversify';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient();

// in production, initialize Google Analytics
if (getEnv('ENV') === 'production') {
  ReactGA.initialize('G-VHN98QQPY6');
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <Provider container={container}>
        <CookiesProvider>
          <Suspense
            fallback={
              <div
                className={'d-flex justify-content-center align-items-center'}
                style={{ minHeight: '100vh' }}
              >
                <Spinner animation={'border'} variant={'primary'} />
              </div>
            }
          >
            <BrowserRouter>
              <QueryClientProvider client={queryClient}>
                <ScrollToTop />
                <App />
                {process.env.NODE_ENV === 'development' && (
                  <>
                    <ReactQueryDevtools initialIsOpen={false} />
                    <DevDisplaySize />
                  </>
                )}
              </QueryClientProvider>
            </BrowserRouter>
          </Suspense>
        </CookiesProvider>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
