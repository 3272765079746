// Copyright © 2023 CATTLEytics Inc.

import { useTranslation, withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useIsAuthUserSuperAdmin } from '../../common/hooks';
import { ApiResourceV1, QueryKey } from '../../shared';
import ManageListPage from './ManageListPage';

interface RouteParams {
  action: string;
}

/**
 * Routable page to manage list of genders.
 */
const ListsGendersPage = (): JSX.Element => {
  const { action } = useParams<RouteParams>();
  const isSuperAdmin = useIsAuthUserSuperAdmin();

  const { t } = useTranslation();

  return (
    <ManageListPage
      apiResource={ApiResourceV1.Genders}
      pageTitle={t('entity|gender', { count: 2 })}
      queryKey={QueryKey.Genders}
      restricted={!isSuperAdmin}
      showAddModal={!!action}
      typeLabel={'gender'}
      typeName={'gender'}
    />
  );
};

export default withTranslation()(ListsGendersPage);
