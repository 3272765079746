// Copyright © 2023 CATTLEytics Inc.
import { useMemo, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import TypeaheadRef from 'react-bootstrap-typeahead/types/core/Typeahead';
import { Option, TypeaheadPropsAndState } from 'react-bootstrap-typeahead/types/types';
import { useTranslation } from 'react-i18next';

export const TimeSelectEODValue: Option = '11:59 PM';

enum TimePeriod {
  AM = 'AM',
  PM = 'PM',
}

export const getTimeString = (hour: number, minutes: number, endOfDay?: string): string => {
  if (!!endOfDay && hour === 23 && minutes === 59) {
    return endOfDay;
  }

  const minutesStr = minutes <= 9 ? `0${minutes}` : minutes;
  const period = hour >= 12 ? TimePeriod.PM : TimePeriod.AM;
  hour = period === TimePeriod.PM ? hour - 12 : hour;
  return `${hour === 0 ? 12 : hour}:${minutesStr} ${period}`;
};

export const getTimeValueInMinutes = (timeString: Option, endOfDay?: string): number => {
  const { hour, minutes } = getTimeValue(timeString, endOfDay);
  return hour * 60 + minutes;
};

export const getTimeValue = (
  timeString: Option,
  endOfDay?: string,
): { hour: number; minutes: number } => {
  if (!!endOfDay && timeString === endOfDay) {
    timeString = TimeSelectEODValue;
  }
  const [timeWithoutPeriod, period]: string = timeString.split(' ');
  // eslint-disable-next-line prefer-const
  let [hour, minutes] = timeWithoutPeriod.split(':').map((time) => Number(time));
  if (period === TimePeriod.AM && hour === 12) {
    hour = 0;
  } else if (period === TimePeriod.PM && hour !== 12) {
    hour = hour + 12;
  }
  return { hour, minutes };
};

type Props = {
  className: string;
  disableValueBefore?: string;
  formLabel?: string;
  id: string;
  onChange: (selected: Option[]) => void;
  required: boolean;
  validateEarly: boolean;
  validated: boolean;
  value: Option;
};

function TimeSelect({ disableValueBefore, ...props }: Props): JSX.Element {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<Option>(props.value);
  const [prevSelected, setPrevSelected] = useState<Option>('');
  const [isFocus, setFocus] = useState(false);
  const typeaheadRef = useRef<TypeaheadRef>(null);

  const timePattern = `^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$|^${t('timeSelect|endOfDay')}$`;
  const timeRegex = new RegExp(timePattern);

  // Generate 97-item array of 15 minute interval times from 12:00 AM to 11:59 PM
  const timeOptionsValues: Option[] = useMemo(() => {
    return [
      ...Object.values(TimePeriod).reduce<string[]>(
        (prev, suffix) => [
          ...prev,
          ...new Array(12).fill(undefined).reduce<string[]>((hourArr, _, i) => {
            const hour = i === 0 ? 12 : i;
            const intervals = ['00', '15', '30', '45'];
            const hourIntervals = intervals.map((minute) => `${hour}:${minute} ${suffix}`);
            return [...hourArr, ...hourIntervals];
          }, []),
        ],
        [],
      ),
      t('timeSelect|endOfDay'),
    ];
  }, [t]);

  const disableIndex = useMemo(() => {
    if (disableValueBefore) {
      const index = timeOptionsValues.findIndex((value) => value === disableValueBefore);
      return index === -1 ? undefined : index;
    }
    return undefined;
  }, [disableValueBefore, timeOptionsValues]);

  // Filter anything that should be disabled
  const timeOptionsFiltered = timeOptionsValues
    .map((item, i) => {
      if (disableIndex === undefined || i > disableIndex) {
        return item;
      }
      return false;
    })
    .filter((item): item is string => item !== false);

  // Match any strings only from the start
  function matchFromStart(option: Option, matchProps: TypeaheadPropsAndState): boolean {
    return option.indexOf(matchProps.text) === 0;
  }

  function handleBlur(): void {
    setFocus(false);
    if (!selected && prevSelected) {
      setSelected(prevSelected);
    }
    setPrevSelected('');
  }

  function handleChange(s: Option[]): void {
    if (s.length > 0) {
      setSelected(s[0]);
      props.onChange(s);
      setPrevSelected('');
      typeaheadRef.current?.blur();
    }
  }

  function handleFocus(): void {
    setFocus(true);
    if (selected === props.value) {
      setPrevSelected(selected);
      setSelected('');
    }
  }

  function handleInputChange(s: string): void {
    setSelected(s.replace(/^0/, ''));
    if (timeRegex.test(s)) {
      props.onChange([s]);
    }
  }

  const isInvalid =
    (props.validateEarly || props.validated) &&
    !isFocus &&
    props.required &&
    (selected !== props.value || !timeRegex.test(selected as string));

  const isValid =
    (props.validateEarly || props.validated) && !isFocus && props.required && !isInvalid;

  return (
    <div style={{ width: '100%', alignSelf: 'start' }}>
      {!!props.formLabel && <Form.Label>{t(`${props.formLabel}`)}</Form.Label>}
      <Typeahead
        className={`${props.className} ${isInvalid ? 'is-invalid' : 'is-valid'}`}
        defaultSelected={[props.value]}
        emptyLabel={''}
        filterBy={matchFromStart}
        id={props.id}
        inputProps={{ pattern: timePattern, required: props.required }}
        isInvalid={isInvalid}
        isValid={isValid}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        onInputChange={handleInputChange}
        options={timeOptionsFiltered}
        placeholder={t('timeSelect|chooseTime')}
        ref={typeaheadRef}
        selected={[selected]}
      />
      <Form.Control.Feedback type={'invalid'}>
        {t('timeSelect|selectValidTime')}
      </Form.Control.Feedback>
    </div>
  );
}

export default TimeSelect;
