// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdExpandMore } from 'react-icons/md';

import Button from '../../common/components/Button';
import { ButtonVariant } from './Button';

/**
 * Defines the input properties for this component.
 */
interface Props {
  /**
   * On click callback.
   */
  onClick?: () => void;
}

/**
 * A small more button, ideal for use in Clamp component
 */
const ButtonMore = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Button
      onClick={props.onClick}
      size={'sm'}
      type="button"
      variant={ButtonVariant.OutlinePrimary}
    >
      <MdExpandMore className={'me-1'} />
      {t('more')}
    </Button>
  );
};

export default ButtonMore;
