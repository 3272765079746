// Copyright © 2023 CATTLEytics Inc.
import { useTranslation } from 'react-i18next';

import androidButton from '../../../assets/img/android-button.png';
import iosButton from '../../../assets/img/ios-button.png';
import Modal from '../../common/components/Modal';

/**
 * Component input properties.
 */
interface Props {
  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;
}

/**
 * A modal for a user to change their profile information.
 */
const AppLinkModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Modal onClose={props.onClose} size={'lg'} title={t(`appLinkModal|download`)} visible={true}>
      <ul style={{ listStyle: 'none' }}>
        <li className={'mb-3'}>
          <div className={'d-flex'}>
            <div className={'me-3'}>
              <a
                href="https://apps.apple.com/ca/app/moo-to-do/id6448990980"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  alt={t('gettingStartedModal|downloadIosApp')}
                  src={iosButton}
                  style={{ height: '50px' }}
                />
              </a>
            </div>
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.cattleytics.milkshake"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  alt={t('gettingStartedModal|downloadAndroidApp')}
                  src={androidButton}
                  style={{ height: '50px' }}
                />
              </a>
            </div>
          </div>
        </li>
      </ul>
    </Modal>
  );
};

export default AppLinkModal;
