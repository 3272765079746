// Copyright © 2023 CATTLEytics Inc.

import { inject, injectable } from 'inversify';
import { Serializer } from 'ts-japi';

import { TYPES } from '../../../types';
import NoteAttachment from '../../common/entities/noteAttachment';
import JsonApiDataResponse from '../../common/interfaces/jsonApiDataResponse';
import JsonApiQueryParams from '../../common/interfaces/jsonApiQueryParams';
import JsonApiResource from '../../common/interfaces/jsonApiResource';
import type Logger from '../../logger/logger';
import { JsonApiTypes } from '../enums/jsonApiTypes';
import Api2Service from './api2Service';

/**
 * Service for managing noteAttachment data via REST API.
 */
@injectable()
export default class NoteAttachmentService {
  private logger: Logger;

  /**
   * Service for accessing the HTTP API.
   */
  private apiService: Api2Service;

  /**
   * JSON:API serializer.
   * @deprecated
   */
  private serializer: Serializer;

  /**
   * Creates an instance of NoteAttachmentService.
   * @param {Logger} logger Logger instance.
   * @param {ApiService} apiService API Service instance
   * @memberOf NoteAttachmentService
   */
  constructor(
    @inject(TYPES.logger) logger: Logger,
    @inject(TYPES.apiService) apiService: Api2Service,
  ) {
    this.logger = logger;
    this.apiService = apiService;
    this.serializer = new Serializer(JsonApiTypes.NoteAttachments);
  }

  /**
   * @inheritdoc
   */
  async getNoteAttachment(
    noteAttachmentId: number,
    params?: JsonApiQueryParams,
  ): Promise<NoteAttachment> {
    // set default include query parameters
    if (!params) {
      params = {};
    }
    if (params && !params.include) {
      params.include = [];
    }
    const result = await this.apiService.get<JsonApiDataResponse<NoteAttachment>>(
      `/noteAttachments/${noteAttachmentId}`,
      params,
    );
    if (!result) {
      throw new Error('No result');
    }

    this.cast(result.data as JsonApiResource<NoteAttachment>);

    return this.apiService.deserialize<NoteAttachment>(result);
  }

  /**
   * @inheritdoc
   */
  async getNoteAttachments(params?: JsonApiQueryParams): Promise<NoteAttachment[]> {
    const result = await this.apiService.get<JsonApiDataResponse<NoteAttachment>>(
      '/noteAttachments',
      params,
    );
    if (!result) {
      return [];
    }

    const data = result.data as JsonApiResource<NoteAttachment>[];
    data.forEach((item) => this.cast(item));

    return this.apiService.deserializeArray<NoteAttachment>(result);
  }

  /**
   * @inheritdoc
   */
  async createNoteAttachment(noteAttachment: NoteAttachment): Promise<NoteAttachment> {
    const result = await this.apiService.post<JsonApiDataResponse<NoteAttachment>>(
      `/noteAttachments`,
      await this.serializer.serialize(noteAttachment),
      undefined,
      { 'Content-Type': 'application/vnd.api+json' },
    );
    return this.apiService.deserialize<NoteAttachment>(result);
  }

  /**
   * @inheritdoc
   */
  async patchNoteAttachment(
    id: number,
    attributes: Record<string, string | number | null>,
  ): Promise<null> {
    // make sure record identifier is included
    attributes.id = id;

    return await this.apiService.patch(
      `/noteAttachments/${id}`,
      await this.serializer.serialize(attributes),
      undefined,
      { 'Content-Type': 'application/vnd.api+json' },
    );
  }

  /**
   * @description Casts fields into javascript types.
   * @param {JsonApiResource<NoteAttachment>} item
   * @return {void}
   */
  cast(item: JsonApiResource<NoteAttachment>): void {
    const data = item as JsonApiResource<NoteAttachment>;
    if (!data) {
      return;
    }
  }
}
