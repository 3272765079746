// Copyright © 2023 CATTLEytics Inc.
import { useInjection } from 'inversify-react';
import { FC, Fragment } from 'react';
import { Badge, ListGroup, ListGroupItem, Placeholder } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import DateTime from '../../common/components/DateTime';
import { Sort } from '../../common/enums';
import { ActiveTreatment, AnimalEvent, QueryKey } from '../../shared';

const ActiveTreatmentsWhiteboardList: FC<WithTranslation> = (props) => {
  const activeTreatmentService = useInjection<ActiveTreatment>(TYPES.activeTreatmentService);

  const queryParams: Record<string, string> = {
    sortField: 'createdDate',
    sortDirection: Sort.Descending,
    limit: '10',
  };

  const query = useQuery<AnimalEvent[]>(
    [QueryKey.ActiveTreatments, queryParams],
    () => activeTreatmentService.list(queryParams),
    { keepPreviousData: false, refetchInterval: 30000 },
  );

  const placeholder = (
    <Placeholder animation={'glow'} className={'w-100 d-flex justify-content-between'}>
      <div className={'w-100 d-flex'}>
        <Placeholder className={'me-3'} md={2} />
        <Placeholder md={4} />
      </div>
      <Placeholder className={'me-1'} md={1} />
    </Placeholder>
  );

  if (query.isLoading) {
    return (
      <Fragment>
        {[...Array(5).keys()].map((key) => (
          <Fragment key={key}>{placeholder}</Fragment>
        ))}
      </Fragment>
    );
  }

  if (query.isError || !query.data) {
    return <AlertError message={'Error'} />;
  }

  const treatments = query.data;

  return (
    <ListGroup className="overflow-auto flex-grow-1">
      {treatments.length === 0 && query.isFetched && (
        <p className={'text-center'}>
          <em>{props.t('No active treatments found')}</em>
        </p>
      )}
      {treatments.map(
        ({ createdDate, diagnosisRegime, id, animalId, animal, status, nextActionDate }) => {
          return (
            <ListGroupItem action={false} as={Link} key={id} to={`/active-treatments/${id}`}>
              <div className="w-100 d-flex flex-column" style={{ minWidth: 0 }}>
                <div className="d-flex flex-row justify-content-between align-items-start w-100">
                  <div className="d-flex flex-row">
                    {!!animalId && (
                      <div onClick={(e): void => e.stopPropagation()}>
                        <Link to={`/animals/${animalId}`}>
                          {animal?.primaryTag}
                          {animal?.name && ` - ${animal.name}`}
                        </Link>
                      </div>
                    )}
                  </div>

                  <Badge bg={'primary'} className="w-auto" pill>
                    {status}
                  </Badge>
                </div>
                <small className="text-muted" style={{ fontSize: '11px' }}>
                  <span>{props.t('Date')}: </span>
                  <DateTime date={createdDate} hideTime={true} />
                </small>
                <small className="text-muted" style={{ fontSize: '11px' }}>
                  <span>{props.t('Next Action Date')}: </span>
                  <DateTime date={nextActionDate} hideTime={true} />
                </small>
                {!!diagnosisRegime && <div>{diagnosisRegime.name}</div>}
              </div>
            </ListGroupItem>
          );
        },
      )}
    </ListGroup>
  );
};

export default withTranslation()(ActiveTreatmentsWhiteboardList);
