// Copyright © 2023 CATTLEytics Inc.

import { FC, MouseEventHandler, useMemo } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Text } from 'react-konva';
import { Html } from 'react-konva-utils';

import Pen from '../../../common/entities/pen';

const fontSizeTitle = 18;
const fontSizeValue = 16;
const fontSizeDescription = 14;
const fontSizeLabel = 12;

/**
 * Component input properties.
 */
interface Props extends WithTranslation {
  /**
   * Whether to enable debug information.
   */
  debug?: boolean;

  /**
   * Indicates if detail is in edit mode
   */
  editMode: boolean;

  /**
   * Handles on click event
   * @returns void
   */
  onClick?: () => void;

  /**
   *  Handles on mouse out event
   */
  onMouseOut?: MouseEventHandler<HTMLDivElement>;

  /**
   *  Handles on mouse over event
   */
  onMouseOver?: MouseEventHandler<HTMLDivElement>;

  /**
   * The pen whose detail to show.
   */
  pen: Pen;

  /**
   * The map pen region height.
   */
  regionHeight: number;

  /**
   * The map pen region width.
   */
  regionWidth: number;

  /**
   * The scale the parent is being rendered at.
   */
  scale: number;
}

/**
 * A component to assist with uploading files.
 */
const PenDetail: FC<Props> = (props) => {
  const data = useMemo(() => {
    const {
      t,
      pen: { capacity, totalAnimals, totalPregnant, totalFresh, totalOpen },
    } = props;

    let total: number | string = totalAnimals ?? 0;

    if (total && capacity) {
      total = `${total} (${Math.round((total / capacity) * 100)}%)`;
    }

    return [
      { label: t('penDetail|totalAnimalsAndTotalOccLabel'), value: total },
      { label: t('penDetail|totalPregnant'), value: totalPregnant ?? 0 },
      { label: t('penDetail|totalFresh'), value: totalFresh ?? 0 },
      { label: t('penDetail|totalOpen'), value: totalOpen ?? 0 },
    ];
  }, [props]);

  return props.editMode ? (
    <Text
      align={'center'}
      ellipsis
      fill={'#fff'}
      fontSize={fontSizeTitle / props.scale}
      fontStyle={'bold'}
      preventDefault={false}
      text={props.pen.name}
      verticalAlign="center"
      width={props.regionWidth - 16}
      wrap="none"
      x={8}
      y={(props.regionHeight - fontSizeTitle / props.scale) / 2}
    />
  ) : (
    <Html>
      <div
        className="text-white p-2 pe-none overflow-hidden"
        style={{ width: props.regionWidth, height: props.regionHeight }}
      >
        <div className="h-100 user-select-none">
          <div className="text-center fw-bold" style={{ fontSize: fontSizeTitle / props.scale }}>
            {props.pen.name}
          </div>
          {props.pen.description && (
            <div
              className="overflow-hidden text-truncate text-center"
              style={{ fontSize: fontSizeDescription / props.scale }}
            >
              {props.pen.description}
            </div>
          )}
          <div className="d-flex flex-row flex-wrap ">
            {data.map((item) => (
              <div
                className={`text-center p-1 ${props.regionWidth >= 160 ? 'flex-grow-1' : 'w-100'}`}
                key={item.label}
              >
                <div style={{ fontSize: fontSizeLabel / props.scale }}>{item.label}</div>
                <div style={{ fontSize: fontSizeValue / props.scale }}>{item.value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        onClick={props.onClick}
        onMouseOut={props.onMouseOut}
        onMouseOver={props.onMouseOver}
        style={{
          cursor: 'pointer',
          position: 'absolute',
          top: 0,
          left: 0,
          width: props.regionWidth,
          height: props.regionHeight,
        }}
      />
    </Html>
  );
};

export default withTranslation()(PenDetail);
