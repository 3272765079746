// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { TYPES } from '../../../types';
import Page from '../../common/components/Page';
import { AnimalEventType } from '../../common/enums';
import AnimalEventTypeService from '../../common/services/animalEventTypeService';
import { capitalizeWords } from '../../common/utilities';
import { QueryKey } from '../../shared';
import EventModal from './EventModal';
import EventsTabs, { RouteParams } from './EventsTabs';

/**
 * Routable component to list events.
 */
const EventsPage = (): JSX.Element => {
  const { t } = useTranslation();

  const { tabKey } = useParams<RouteParams>();
  const history = useHistory();

  const [eventsModalVisible, setEventsModalVisible] = useState<boolean>(false);
  const animalEventTypeService = useInjection<AnimalEventTypeService>(TYPES.animalEventTypeService);

  const eventQuery = useQuery(
    [QueryKey.AnimalEventTypes],
    () =>
      animalEventTypeService.list({
        sortField: 'name',
        sortDirection: 'ASC',
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: true,
    },
  );

  const tabName = eventQuery?.data?.find((item) => item.id === parseInt(tabKey));

  return (
    <Page
      breadcrumbTitle={capitalizeWords(tabName ? tabName?.name : '')}
      breadcrumbs={[{ label: 'Events', to: '/events/all' }]}
      // buttons={
      //   <ButtonCreate label={'New Event'} onClick={(): void => setEventsModalVisible(true)} />
      // }
      title={t('Events')}
    >
      <EventsTabs />
      {eventsModalVisible ? (
        <EventModal
          onClose={(): void => setEventsModalVisible(false)}
          onSave={(ae): void => {
            if (ae) {
              if (ae.length > 0 && ae[0].animalId) {
                history.push(`/animals/${ae[0].animalId}`);
              } else if (ae.animalEventType?.id === AnimalEventType.Bred && ae.sire) {
                history.push(`/sires/${ae.sire.id}`);
              }
            }
            setEventsModalVisible(false);
          }}
        />
      ) : null}
    </Page>
  );
};

export default EventsPage;
