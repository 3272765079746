// Copyright © 2023 CATTLEytics Inc.
import { differenceInDays } from 'date-fns';

import { dropTimeFromDate, formatDate } from '../../utilities';
import { DateEntity, EntityBase, TimelineItem } from './types';

type ConvertToTimelineItemsOptions<Entity extends EntityBase> = {
  areaSize: number;
  dateFormat: string;
  dates: DateEntity<Entity>[];
  maxDate: number;
  minDate: number;
  nodeDiameter: number;
};

export const convertToTimelineItems = <Entity extends EntityBase>({
  dates,
  areaSize,
  maxDate,
  minDate,
  nodeDiameter,
  dateFormat,
}: ConvertToTimelineItemsOptions<Entity>): TimelineItem<Entity>[] => {
  let lastItem: TimelineItem<Entity>;
  const totalRange = maxDate - minDate;
  const containerSize = areaSize - nodeDiameter;

  const newData = dates.reduce<Record<string, TimelineItem<Entity>>>((prev, dateEntity) => {
    const { date } = dateEntity;
    const dateFormatted = formatDate(date, dateFormat);
    const point = ((dropTimeFromDate(date).getTime() - minDate) / totalRange) * containerSize;
    const prevData = prev[dateFormatted];

    if (lastItem && lastItem.point + nodeDiameter > point) {
      lastItem.dateEntities.push(dateEntity);
      return prev;
    }

    lastItem = {
      date,
      dateEntities: [...(prevData?.dateEntities.length ? prevData.dateEntities : []), dateEntity],
      point,
      ...(lastItem && {
        daysBetweenLastEvent: differenceInDays(date, lastItem.date),
        daysBetweenPoint: -((point - lastItem.point) / 2),
      }),
    };
    return {
      ...prev,
      [dateFormatted]: lastItem,
    };
  }, {});
  // let lastY:number;
  return Object.values(newData);
};
