// Copyright © 2023 CATTLEytics Inc.

import Entity from '../../common/entities/entity';
import { Region } from '../types';
import Site, { siteDefault } from './site';

/**
 * Represents an pen.
 */
export default interface Pen extends Entity {
  averageDaysInMilk?: number;
  /**
   * Number of how many animals can be in this pen
   */
  capacity?: number;

  /**
   * @description Name of the pen
   * @type {string}
   * @memberof Pen
   */
  mapRegion?: Region;

  /**
   * Name of the pen
   */
  name: string;

  /**
   * @description Site of the pen.
   * @type {Site}
   * @memberof Pen
   */
  site: Site;

  /**
   * @description Site ID of the pen.
   * @type {number}
   * @memberof Pen
   */
  siteId: number;

  /**
   * Number of animals contained in this pen.
   */
  totalAnimals?: number;

  /**
   * Number of animals that have died in this pen.
   */
  totalDied?: number;

  /**
   * Number of animals contained in this pen that have dried off.
   */
  totalDriedOff?: number;

  /**
   * Number of animals contained in this pen that are fresh.
   */
  totalFresh?: number;

  /**
   * Number of animals contained in this pen that are in lactation group 1.
   */
  totalLactationGroup1?: number;

  /**
   * Number of animals contained in this pen that are in lactation group 2.
   */
  totalLactationGroup2?: number;

  /**
   * Number of animals contained in this pen that are in lactation group 3+.
   */
  totalLactationGroup3Plus?: number;

  /**
   * Number of animals contained in this pen that are open.
   */
  totalOpen?: number;

  /**
   * Number of animals contained in this pen that are pregnant.
   */
  totalPregnant?: number;

  /**
   * Total number of animals that have been sold from this pen.
   */
  totalSold?: number;
}

/**
 * Returns an Pen with all required fields set.
 */
export function penDefault(): Pen {
  return {
    id: 0,
    name: '',
    site: siteDefault(),
    siteId: 0,
    createdDate: new Date(),
    modifiedDate: new Date(),
  };
}
