// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import Button from '../../common/components/Button';
import DataTable, { DataTableHeader, DataTableRow } from '../../common/components/DataTable';
import TablePlaceholder from '../../common/components/TablePlaceholder';
import ProductAdministration from '../../common/entities/productAdministration';
import { Sort } from '../../common/enums';
import ProductAdministrationService from '../../common/services/productAdministrationService';
import { IconEdit } from '../../common/utilities';
import { QueryKey } from '../../shared/enums';
import ProductAdminModal from './ProductAdminModal';

interface Props {
  className?: string;
  diagnosisId?: number;

  hideDiagnosisColumn?: boolean;
  hideProductNameColumn?: boolean;

  /**
   * Filter by product ID
   */
  productId?: number;

  readOnly?: boolean;
}

/**
 * A filterable list of product administration.
 */
const ProductAdminTable = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const productAdminService = useInjection<ProductAdministrationService>(
    TYPES.productAdministrationService,
  );

  const [editId, setEditId] = useState<number>();
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);

  const [limit, setLimit] = useState<number>(25);
  const [offset, setOffset] = useState<number>(0);
  const [sortField, setSortField] = useState<string>('id');
  const [sortDirection, setSortDirection] = useState<Sort>(Sort.Ascending);

  const query = useQuery<ProductAdministration[]>(
    [
      QueryKey.ProductAdministrations,
      limit,
      offset,
      sortField,
      sortDirection,
      props.productId,
      props.diagnosisId,
    ],
    () =>
      productAdminService.list({
        limit: String(limit),
        offset: String(offset),
        sortField: sortField,
        sortDirection: String(sortDirection),
        diagnosisId: props.diagnosisId ? String(props.diagnosisId) : undefined,
        productId: props.productId ? String(props.productId) : undefined,
      }),

    { keepPreviousData: true },
  );

  const showEditModal = (id: number): void => {
    setEditModalVisible(true);
    setEditId(id);
  };

  if (query.isLoading) {
    return <TablePlaceholder />;
  }

  if (query.isError) {
    return <AlertError message={t('common|animalRetrievalError')} />;
  }

  const headers: DataTableHeader[] = ((): DataTableHeader[] => {
    const headers = [];
    if (!props.hideDiagnosisColumn) {
      headers.push({
        name: 'diagnosis',
        label: t('Diagnosis'),
        classNameMobile: 'col-6',
      });
    }
    if (!props.hideProductNameColumn) {
      headers.push({
        name: 'productName',
        label: t('Product'),
        classNameMobile: 'col-6',
      });
    }
    headers.push({
      name: 'dosage',
      label: t('Dosage (mL)'),
      classNameMobile: 'col-6',
    });
    headers.push({
      name: 'route',
      label: t('Route'),
    });
    headers.push({
      name: 'frequency',
      label: t('Frequency'),
    });
    headers.push({
      name: 'meatWithdrawalDays',
      label: t('Meat Withdrawal'),
    });
    headers.push({
      name: 'milkWithdrawalDays',
      label: t('Milk Withdrawal'),
    });
    headers.push({
      name: 'mode',
      label: t('Mode'),
    });
    if (!props.readOnly) {
      headers.push({ name: 'actions', sortable: false, label: 'Actions', hideMobile: true });
    }
    return headers;
  })();

  const data: DataTableRow[] = query.data
    ? query.data.map((row) => {
        return {
          id: String(row.id),
          diagnosis: row.diagnosis?.name,
          productName: row.product?.name,
          dosage: `${row.dosage} mL`,
          route: row.route,
          frequency: row.frequency,
          meatWithdrawalDays: row.meatWithdrawalDays,
          milkWithdrawalDays: row.milkWithdrawalDays,
          mode: row.mode,
          actions: (
            <div onClick={(e): void => e.stopPropagation()}>
              <Button
                onClick={(): void => showEditModal(row.id)}
                size="sm"
                type={'button'}
                variant="outline-primary"
              >
                <IconEdit className={'me-1'} /> {t('Modify')}
              </Button>
            </div>
          ),
        };
      })
    : [];

  return (
    <>
      <DataTable
        className={'mt-3'}
        data={data}
        headers={headers}
        hidePaginationControls={true}
        isLoading={query.isLoading}
        isPreviousData={query.isPreviousData}
        limit={limit}
        messageNoData={t('productAdminTable|noAdministrationsFound')}
        offset={offset}
        onLimitChange={(newLimit): void => setLimit(newLimit)}
        onOffsetChange={(newOffset): void => setOffset(newOffset)}
        onSortDirectionChange={(newSortDirection): void => setSortDirection(newSortDirection)}
        onSortFieldChange={(newSortField): void => setSortField(newSortField)}
        sortDirection={sortDirection}
        sortField={sortField}
      />
      {editModalVisible && (
        <ProductAdminModal editId={editId} onClose={(): void => setEditModalVisible(false)} />
      )}
    </>
  );
};

export default ProductAdminTable;
