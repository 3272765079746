// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';

import Arrow from '../../common/components/Arrow';

/**
 * @description Defines the input properties for the BreedingTimelineStep component.
 * @interface Props
 */
interface Props extends WithTranslation {
  /**
   * @description Children of this component.
   * @type {React.ReactNode}
   * @memberof Props
   */
  children?: React.ReactNode;

  /**
   * @description Is a program being dragged?
   * @type {boolean}
   * @memberof Props
   */
  isDragging?: boolean;

  /**
   * @description Is the program that is being dragged over this component?
   * @type {boolean}
   * @memberof Props
   */
  isOver?: boolean;

  /**
   * @description The step number.
   * @type {number}
   * @memberof Props
   */
  step: number;

  /**
   * @description Text that will be displayed below the step number arrow.
   * @type {string}
   * @memberof Props
   */
  stepLabel?: string;

  /**
   * @description CSS properties to pass to the top element of this component.
   * @type {React.CSSProperties}
   * @memberof Props
   */
  style?: React.CSSProperties;
}

/**
 * @description Breeding timeline step component. For displaying
 *   the various program steps that make up a breeding plan.
 * @const BreedingTimelineStep
 * @params {Props}
 * @return {JSX.Element}
 */
const BreedingTimelineStep = (props: Props): JSX.Element => {
  const avatar = (
    <Arrow backgroundColor={getColor(props.step - 1)} color="white" height="64px">
      {props.step}
    </Arrow>
  );

  return (
    <Row className="h-100">
      <Col className="d-flex align-items-center " lg={3} xs={3}>
        <div className="w-100">
          {avatar}
          {!props.isDragging && props.stepLabel ? <div>{props.stepLabel}</div> : <div>&nbsp;</div>}
        </div>
      </Col>
      <Col className="h-100" lg={9} xs={9}>
        <div
          className="h-100"
          style={{
            backgroundColor: props.isOver ? '#ccc' : 'transparent',
          }}
        >
          {props.children}
        </div>
      </Col>
    </Row>
  );
};

/**
 * @description Cycle through a list of colours based on an index value parameter.
 * @param index
 * @return string CSS color
 */
const getColor = (index: number): string => {
  const colors = ['dodgerblue', 'firebrick', 'darkviolet', 'coral', 'forestgreen'];
  if (index + 1 > colors.length) {
    return colors[index - colors.length * Math.floor(index / colors.length)];
  }
  return colors[index];
};

export default withTranslation()(BreedingTimelineStep);
