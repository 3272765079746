// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdExpandLess } from 'react-icons/md';

import Button from '../../common/components/Button';
import { ButtonVariant } from './Button';

/**
 * Defines the input properties for this component.
 */
interface Props {
  /**
   * On click callback.
   */
  onClick?: () => void;
}

/**
 * A small less button, ideal for use in Clamp component
 */
const ButtonLess = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Button
      className={'text-nowrap'}
      onClick={props.onClick}
      size={'sm'}
      type="button"
      variant={ButtonVariant.OutlinePrimary}
    >
      <MdExpandLess className={'me-1'} />
      {t('less')}
    </Button>
  );
};

export default ButtonLess;
