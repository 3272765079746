// Copyright © 2024 CATTLEytics Inc.

import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import AlertError from '../../../common/components/AlertError';
import DataTable, { DataTableHeader, DataTableRow } from '../../../common/components/DataTable';
import { api } from '../../../common/utilities/api';
import { QueryKey } from '../../../shared';
import { Animal } from '../../../shared';
import { ApiResourceV1 } from '../../../shared/enums/api';
import { HttpMethod } from '../../../shared/enums/http';

interface Props {
  /**
   * Animal id
   */
  animalId: number;
}

/**
 * A table component containing animal's repro information.
 */
const ReproInfoTable = (props: PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const query = useQuery<Animal>([QueryKey.Animals, props.animalId], () =>
    api<Animal>(HttpMethod.Get, `${ApiResourceV1.Animals}/${props.animalId}`),
  );

  if (query.isError) {
    return <AlertError message={t('reproInfoTable|fetchError')} />;
  }

  const headers: DataTableHeader[] = [
    {
      name: 'animalID',
      label: t('reproInfoTable|animalIdColumn'),
      classNameMobile: 'col-6',
    },
    {
      name: 'daysInMilk',
      label: t('reproInfoTable|daysInMilkColumn'),
      classNameMobile: 'col-6',
    },
    {
      name: 'lactationNumber',
      label: t('reproInfoTable|lactationNumberColumn'),
      classNameMobile: 'col-6',
    },
    {
      name: 'milkingStatus',
      label: t('reproInfoTable|milkingStatusColumn'),
      classNameMobile: 'col-6',
    },
    {
      name: 'reproductionStatus',
      label: t('reproInfoTable|reproductionStatusColumn'),
      classNameMobile: 'col-6',
    },
  ];

  const data: DataTableRow[] = !query.data
    ? []
    : [
        {
          animalID: String(query.data.id),
          daysInMilk: String(query.data.daysInMilk),
          lactationNumber: String(query.data.lactationNumber),
          milkingStatus: String(query.data.milkingStatus),
          reproductionStatus: String(query.data.reproductionStatus),
        },
      ];

  return (
    <div>
      <h2>{t('reproInfoTable|header')}</h2>
      <DataTable
        data={data}
        headers={headers}
        hidePaginationControls={true}
        isLoading={query.isLoading}
        messageNoData={t('reproInfoTable|messageNoData')}
      />
    </div>
  );
};

export default ReproInfoTable;
