// Copyright © 2023 CATTLEytics Inc.

import React from 'react';

import Auth from '../entities/auth';

const AuthContext = React.createContext<Auth>({
  authenticated: false,
  firstName: undefined,
  lastName: undefined,
  siteId: undefined,
  userId: undefined,
  email: undefined,
  role: undefined,
});

export default AuthContext;

// export default function useAuth() {
//   return useContext(AuthContext);
// }
