// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ApiResourceV1, QueryKey } from '../../shared';
import ManageListPage from './ManageListPage';

interface RouteParams {
  action: string;
}

/**
 * Routable page to manage list of reproduction statuses.
 */
const ListsReproStatusesPage = (): JSX.Element => {
  const { action } = useParams<RouteParams>();
  const { t } = useTranslation();

  return (
    <ManageListPage
      apiResource={ApiResourceV1.ReproStatuses}
      pageTitle={t('entity|reproductionStatus', { count: 2 })}
      queryKey={QueryKey.ReproStatuses}
      showAddModal={!!action}
      typeLabel={'reproduction'}
      typeName={'reproStatus'}
    />
  );
};

export default withTranslation()(ListsReproStatusesPage);
