// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useEffect, useState } from 'react';
import { Col, Form, PlaceholderButton, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { TYPES } from '../../../types';
import CardStatsPlaceholder from '../../animals/components/CardStatsPlaceholder';
import AlertError from '../../common/components/AlertError';
import AnimalTag from '../../common/components/AnimalTag';
import ButtonSave from '../../common/components/ButtonSave';
import DataCell from '../../common/components/DataCell';
import DateTime from '../../common/components/DateTime';
import Page from '../../common/components/Page';
import TablePlaceholder from '../../common/components/TablePlaceholder';
import Toast from '../../common/components/Toast';
import ActiveTreatment from '../../common/entities/activeTreatment';
import AnimalEvent from '../../common/entities/animalEvent';
import { AnimalEventType as AnimalEventTypeEnum, DecisionTreeAnswers } from '../../common/enums';
import ActiveTreatmentService, {
  PostPayloadEvent,
} from '../../common/services/activeTreatmentService';
import AnimalEventService from '../../common/services/animalEventService';
import DiagnosisDecisionTree, {
  StepChange,
} from '../../diagnoses/components/DiagnosisDecisionTree';
import NotFoundPage from '../../misc/components/NotFoundPage';
import { QueryKey } from '../../shared/enums';
import EventsTable from './EventsTable';

interface RouteParams {
  /**
   * The ID of the active treatment we want to manage.
   */
  activeTreatmentId: string;
}

/**
 * Routable component to show details of a specific active treatment.
 */
const ActiveTreatmentsDetailPage = (): JSX.Element => {
  const { t } = useTranslation();

  const { activeTreatmentId } = useParams<RouteParams>();

  const activeTreatmentService = useInjection<ActiveTreatmentService>(TYPES.activeTreatmentService);
  const eventService = useInjection<AnimalEventService>(TYPES.animalEventService);

  const [activeTreatment, setActiveTreatment] = useState<ActiveTreatment>();
  const [completedEvents, setCompletedEvents] = useState<AnimalEvent[]>([]);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [stepChange, setStepChange] = useState<StepChange>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [answers, setAnswers] = useState<DecisionTreeAnswers>({});

  // look up active treatment
  const filters: Record<string, string> = {};

  filters.order = '-eventDateTime';
  filters.activeTreatmentId = activeTreatmentId;

  // @TODO this may not be needed if we remove the property requirement diagnosisRegimeId in decision tree
  const queryActiveTreatment = useQuery<ActiveTreatment | undefined>(
    [QueryKey.ActiveTreatments, 'get', activeTreatmentId],
    () => activeTreatmentService.get(Number(activeTreatmentId)),
    {
      enabled: true,
      refetchInterval: 300000,
      refetchOnWindowFocus: false,
    },
  );

  const queryAnimalEvents = useQuery<AnimalEvent[]>(
    [QueryKey.AnimalEvents, 'get', activeTreatmentId],
    () => eventService.list({ activeTreatmentId: String(activeTreatmentId) }),
    {
      enabled: true,
      refetchInterval: 300000,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(
    () => queryAnimalEvents.data && setCompletedEvents(queryAnimalEvents.data),
    [queryAnimalEvents.data],
  );
  useEffect(() => setActiveTreatment(queryActiveTreatment.data), [queryActiveTreatment.data]);

  const queryClient = useQueryClient();

  const mutation = useMutation(
    async () => {
      if (!activeTreatment) {
        throw Error('No active treatment has been loaded yet.');
      }

      if (!stepChange) {
        throw Error(
          'No step change has occurred in the decision tree so there is nothing to save.',
        );
      }

      const events: PostPayloadEvent[] = [];

      const completedDrdIds = completedEvents.map((event) => event.diagnosisRegimeDetailId);

      if (stepChange.events.length > 0) {
        stepChange.events.forEach((drd) => {
          const productDosage = answers[drd.id] && answers[drd.id].productDosage;
          const productLocation = answers[drd.id] && answers[drd.id].productLocation;
          const productRoute = answers[drd.id] && answers[drd.id].productRoute;
          const productId = answers[drd.id] && answers[drd.id].productId;

          if (!completedDrdIds.includes(drd.id)) {
            events.push({
              animalIds: [activeTreatment.animalId],
              animalEventTypeId: AnimalEventTypeEnum.Diagnosis,
              diagnosisId: activeTreatment.diagnosisId,
              diagnosisRegimeId: activeTreatment.diagnosisRegimeId,
              diagnosisRegimeDetailId: Number(drd.id),
              productId: Number(productId),
              productDosage: Number(productDosage),
              productDosageFactor: 100,
              productRoute: productRoute ?? '',
              productLocation: productLocation,
              eventDateTime: new Date().toISOString(),
              notes: `Completed: ${drd.description}`,
              userId: activeTreatment.userId,
            });
          }
        });
      }

      const attributes = {
        answers: stepChange.answers,
        events: events,
        completed: stepChange.completed,
        status: stepChange.status,
        currentStep: stepChange.currentStepId,
      };

      return await activeTreatmentService.patch(Number(activeTreatmentId), attributes);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKey.AnimalEvents);
        await queryClient.invalidateQueries(QueryKey.ActiveTreatments);
      },
    },
  );

  if (!queryActiveTreatment.isFetching && !queryActiveTreatment.data) {
    return <NotFoundPage />;
  }

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    setErrorMessage('');
    try {
      await mutation.mutateAsync();
      setToastVisible(true);
    } catch (err) {
      setErrorMessage(t('common|eventSaveError'));
    }
  };

  const name = activeTreatment?.animal.name;
  const newEvents = stepChange
    ? stepChange.events.filter(
        (drd) => !completedEvents.map((event) => event.diagnosisRegimeDetailId).includes(drd.id),
      )
    : [];

  return (
    <Page
      breadcrumbs={[
        {
          to: '/active-treatments/pending',
          label: t('activeTreatmentsDetailPage|activeTreatmentsLabel'),
        },
      ]}
      title={t('Manage Active Treatment')}
    >
      <h2>{t('Detail')}</h2>
      {queryActiveTreatment.isLoading && <CardStatsPlaceholder />}
      {!queryActiveTreatment.isLoading && (
        <Row>
          <Col cols={6} sm={3}>
            <DataCell label={t('Status')} value={activeTreatment?.status} />
          </Col>
          <Col cols={6} sm={3}>
            <DataCell
              label={t('Completed')}
              value={String(activeTreatment?.completed ? 'Yes' : 'No')}
            />
          </Col>
          <Col cols={6} sm={3}>
            <DataCell
              label={t('Diagnosis Regime')}
              value={String(activeTreatment?.diagnosisRegime.name)}
            />
          </Col>
          <Col cols={6} sm={3}>
            <DataCell label={t('Next Action Date')}>
              <DateTime
                date={activeTreatment?.nextActionDate}
                hideTime={true}
                showDistanceOnHover={true}
              />
            </DataCell>
          </Col>
          <Col cols={6} sm={3}>
            <DataCell label={t('Animal')}>
              {activeTreatment && (
                <AnimalTag
                  animalId={activeTreatment?.animalId}
                  link={true}
                  name={name}
                  primaryTag={activeTreatment?.animal.primaryTag}
                />
              )}
            </DataCell>
          </Col>
          <Col cols={6} sm={3}>
            <DataCell label={t('Created')}>
              <DateTime
                date={activeTreatment?.createdDate}
                hideTime={false}
                showDistanceOnHover={true}
              />
            </DataCell>
          </Col>
          <Col cols={6} sm={3}>
            <DataCell label={t('Modified')}>
              <DateTime
                date={activeTreatment?.modifiedDate}
                hideTime={false}
                showDistanceOnHover={true}
              />
            </DataCell>
          </Col>
        </Row>
      )}
      <h2>{t('Related Treatment Events')}</h2>
      <EventsTable filters={filters} />
      <h2>{t('Diagnosis Decision Tree')}</h2>
      {queryActiveTreatment.isLoading && <TablePlaceholder />}
      {activeTreatment && (
        <DiagnosisDecisionTree
          activeTreatmentId={Number(activeTreatmentId)}
          diagnosisRegimeId={activeTreatment.diagnosisRegimeId}
          disabled={activeTreatment.completed}
          onStepChange={(stepChange): void => {
            setStepChange(stepChange);
            setAnswers(stepChange.answers);
          }}
        />
      )}
      {newEvents.length > 0 && (
        <p>
          <strong>{newEvents.length}</strong> event{newEvents.length !== 1 && 's'} will be created
          upon completing this form.
        </p>
      )}
      <Form noValidate={true} onSubmit={onFormSubmit}>
        {errorMessage && <AlertError message={errorMessage} />}
        <p className={'d-flex justify-content-center'}>
          {queryActiveTreatment.isFetching ? (
            <PlaceholderButton xs={2} />
          ) : (
            <ButtonSave
              busy={mutation.isLoading}
              disabled={
                activeTreatment?.completed ||
                (stepChange && stepChange.events.length === 0) ||
                mutation.isLoading
              }
            />
          )}
        </p>
        <Toast onClose={(): void => setToastVisible(false)} show={toastVisible}>
          {t('activeTreatmentsDetailPage|treatmentSavedToast')}
        </Toast>
      </Form>
    </Page>
  );
};

export default ActiveTreatmentsDetailPage;
