// Copyright © 2023 CATTLEytics Inc.

import Entity from './entity';

/**
 * @description Represents an breedingTech.
 * @export
 * @interface Organization
 */
export default interface Organization extends Entity {
  /**
   * @description URL to the organizations logo.
   * @type {string}
   * @memberof Organization
   */
  logoUrl?: string;

  /**
   * @description Name of Organization.
   * @type {string}
   * @memberof Organization
   */
  name: string;
}

/**
 * @description Returns an Organization with all required fields set.
 * @export
 * @function organizationDefault
 */
export function organizationDefault(): Organization {
  return {
    id: 0,
    name: '',
    createdDate: new Date(),
    modifiedDate: new Date(),
  };
}
