// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { Fragment, useMemo, useState } from 'react';
import { Accordion, Badge, Placeholder } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import Button, { ButtonVariant } from '../../common/components/Button';
import ConfirmModal from '../../common/components/ConfirmModal';
import PaginationControls from '../../common/components/PaginationControls';
import DairyLog from '../../common/entities/dairyLog';
import { usePaginationControls } from '../../common/hooks';
import { usePaginationRequstValues } from '../../common/hooks/usePaginationRequestValues';
import DairyLogService from '../../common/services/dairyLogService';
import { formatDate, IconDelete, IconEdit } from '../../common/utilities';
import { QueryKey } from '../../shared/enums';
import DairyLogModal from './DairyLogModal';

interface Props {
  // Filter list by animal ID.
  animalId?: number;

  // Additional CSS classes to add to component.
  className?: string;

  // Number of dairyLogs to show.
  limit?: number;

  // Filter list by pen ID.
  penId?: number;

  // Show pagination
  showPagination?: boolean;
}

/**
 * Component to display a list of dairy logs.
 */
const DairyLogsList = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const dairyLogService = useInjection<DairyLogService>(TYPES.dairyLogService);
  const showPagination = useMemo(() => props.showPagination ?? true, [props]);
  const paginationRequestValues = usePaginationRequstValues({ initialLimit: props.limit ?? 10 });
  const filters: Record<string, string> = {};

  if (props.animalId) {
    filters.animalId = String(props.animalId);
  }
  if (props.penId) {
    filters.penId = String(props.penId);
  }

  if (showPagination) {
    filters.limit = String(paginationRequestValues.limit);
    filters.offset = String(paginationRequestValues.offset);
  }

  const query = useQuery([QueryKey.DairyLogs, filters], () => dairyLogService.list(filters));
  const [paginationValues, paginationHandlers] = usePaginationControls({
    ...paginationRequestValues,
    listLength: query.data?.length,
  });
  const queryClient = useQueryClient();

  const mutation = useMutation(
    () => {
      if (dairyLogIdToDelete) {
        return dairyLogService.delete(dairyLogIdToDelete);
      } else {
        return new Promise<null>((resolve) => resolve(null));
      }
    },
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(QueryKey.DairyLogs);
      },
    },
  );

  const [dairyLogModalVisible, setDairyLogModalVisible] = useState<boolean>(false);
  const [dairyLogModalDairyLogId, setDairyLogModalDairyLogId] = useState<number>();

  const [dairyLogDeleteModalVisible, setDairyLogDeleteModalVisible] = useState<boolean>(false);
  const [dairyLogIdToDelete, setDairyLogIdToDelete] = useState<number>();

  const placeholder = (
    <Accordion.Item eventKey={'loading'}>
      <Accordion.Header>
        <Placeholder animation={'glow'} className={'w-100 d-flex justify-content-between'}>
          <div className={'w-100 d-flex'}>
            <Placeholder className={'me-3'} md={2} />
            <Placeholder md={4} />
          </div>
          <Placeholder className={'me-1'} md={1} />
        </Placeholder>
      </Accordion.Header>
    </Accordion.Item>
  );

  if (query.isLoading) {
    return (
      <>
        {[...Array(5).keys()].map((key) => (
          <Fragment key={key}>{placeholder}</Fragment>
        ))}
      </>
    );
  }

  if (query.isError || !query.data) {
    return <AlertError message={'Error'} />;
  }

  const showModal = (dairyLogId: number): void => {
    setDairyLogModalVisible(true);
    setDairyLogModalDairyLogId(dairyLogId);
  };

  const showDeleteModal = (dairyLog: DairyLog): void => {
    setDairyLogDeleteModalVisible(true);
    setDairyLogIdToDelete(dairyLog.id);
  };

  const dairyLogs = query.data;

  return (
    <Accordion defaultActiveKey="0" flush>
      {dairyLogs.length === 0 && query.isFetched ? (
        <p className={'text-center'}>
          <em>No logs found</em>
        </p>
      ) : null}
      {dairyLogs.map((dairyLog) => {
        const related = [];
        if (dairyLog.animalId || dairyLog.penId) {
          if (dairyLog.animalId) {
            related.push({
              link: `/animals/${dairyLog.animalId}`,
              label: `Related Animal #${dairyLog.animalId}`,
            });
          }
          if (dairyLog.penId) {
            related.push({
              link: `/pens/${dairyLog.penId}`,
              label: `Related Pen #${dairyLog.penId}`,
            });
          }
        }
        return (
          <Accordion.Item eventKey={String(dairyLog.id)} key={dairyLog.id}>
            <Accordion.Header>
              <div className={'w-100 d-flex justify-content-between'} style={{ minWidth: 0 }}>
                <div className={'text-truncate'} style={{ minWidth: 0 }}>
                  <strong className={'me-3 text-muted'}>{formatDate(dairyLog.date)}</strong>
                  <span>{dairyLog.description}</span>
                </div>
                {!dairyLog.animalId && !dairyLog.penId ? <div /> : null}
                <div>
                  <Badge bg={'success'} className={'me-3'} pill>
                    {dairyLog.eventType}
                  </Badge>

                  {dairyLog.animalId ? (
                    <Badge bg={'primary'} className={'me-3'} pill>
                      Animal
                    </Badge>
                  ) : null}
                  {dairyLog.penId ? (
                    <Badge bg={'secondary'} className={'me-3'} pill>
                      Pen
                    </Badge>
                  ) : null}
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              {related ? (
                <Fragment>
                  <p>
                    <small className={''}>
                      {related.map((item, index) => (
                        <Link className={'alert-link me-3'} key={index} to={item.link}>
                          {item.label}
                        </Link>
                      ))}
                    </small>
                  </p>
                  <hr />
                </Fragment>
              ) : null}
              <h3 className={'mt-3'}>Event Type</h3>
              {dairyLog.eventType}

              <h3 className={'mt-3'}>Description</h3>
              {dairyLog.description}

              <h3 className={'mt-3'}>Notes</h3>
              {dairyLog.notes ?? (
                <p>
                  <em>There are currently no notes for this log.</em>
                </p>
              )}

              <h3 className={'mt-3'}>Follow Up</h3>
              {dairyLog.followUp ?? (
                <p>
                  <em>There are currently no follow up information for this log.</em>
                </p>
              )}

              <hr />
              <div className={'d-flex justify-content-between'}>
                <div>
                  <small>
                    <strong>Created:</strong> {formatDate(dairyLog.createdDate)}
                  </small>
                </div>
                <div>
                  <small>
                    <strong>Modified:</strong> {formatDate(dairyLog.modifiedDate)}
                  </small>
                </div>

                <Button
                  icon={IconDelete}
                  label={t('Delete')}
                  onClick={(): void => showDeleteModal(dairyLog)}
                  size={'sm'}
                  variant={ButtonVariant.OutlineDanger}
                />
                <Button
                  icon={IconEdit}
                  label={t('Edit')}
                  onClick={(): void => showModal(dairyLog.id)}
                  size={'sm'}
                  variant={ButtonVariant.OutlinePrimary}
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>
        );
      })}
      {showPagination && (
        <PaginationControls
          className="mt-3"
          disabled={query.isLoading}
          {...paginationValues}
          {...paginationHandlers}
        />
      )}
      {/*{query.isFetching ? placeholder : null}*/}
      {dairyLogModalVisible ? (
        <DairyLogModal
          dairyLogId={dairyLogModalDairyLogId}
          onClose={(): void => setDairyLogModalVisible(false)}
        />
      ) : null}
      {dairyLogDeleteModalVisible ? (
        <ConfirmModal
          busy={mutation.isLoading}
          cancelOnClick={(): void => setDairyLogDeleteModalVisible(false)}
          okOnClick={async (): Promise<void> => {
            await mutation.mutateAsync();
            setDairyLogDeleteModalVisible(false);
          }}
          title={'Delete Log'}
          visible={true}
        >
          Are you sure you want to delete this item?
        </ConfirmModal>
      ) : null}
    </Accordion>
  );
};

export default DairyLogsList;
