// Copyright © 2024 CATTLEytics Inc.

import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import AlertError from '../../../common/components/AlertError';
import AnimalTag from '../../../common/components/AnimalTag';
import DataTable, { DataTableHeader, DataTableRow } from '../../../common/components/DataTable';
import { IconTag } from '../../../common/utilities';
import { api } from '../../../common/utilities/api';
import { QueryKey } from '../../../shared';
import { Animal } from '../../../shared';
import { ApiResourceV1 } from '../../../shared/enums/api';
import { HttpMethod } from '../../../shared/enums/http';

interface Props {
  /**
   * Animal id
   */
  animalId: number;
}

/**
 * A table component containing animal genetics.
 */
const GeneticsTable = (props: PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const query = useQuery<Animal>([QueryKey.ReportGenetics, props.animalId], () =>
    api<Animal>(HttpMethod.Get, `${ApiResourceV1.Animals}/${props.animalId}`),
  );

  if (query.isError) {
    return <AlertError message={t('geneticsTable|fetchError')} />;
  }

  const headers: DataTableHeader[] = [
    {
      name: 'damName',
      label: t('geneticsTable|damNameColumn'),
      classNameMobile: 'col-3',
    },
    {
      name: 'sireName',
      label: t('geneticsTable|sireNameColumn'),
      classNameMobile: 'col-3',
    },
  ];

  const data: DataTableRow[] = !query.data
    ? []
    : [
        {
          damId: String(query.data.damId),
          damName: (
            <AnimalTag
              animalId={query.data.damId}
              link={true}
              name={query.data.dam?.name}
              primaryTag={query.data.dam?.primaryTag}
            />
          ),
          sireId: String(query.data.sire?.id),
          sireName: (
            <Link to={`/sires/${query.data.sire?.id}`}>
              <IconTag className={'me-1'} />
              {query.data.sire?.name}
            </Link>
          ),
        },
      ];

  return (
    <div>
      <h2>{t('geneticsTable|header')}</h2>
      <DataTable
        data={data}
        headers={headers}
        hidePaginationControls={true}
        isLoading={query.isLoading}
        messageNoData={t('geneticsTable|messageNoData')}
      />
    </div>
  );
};

export default GeneticsTable;
