// Copyright © 2023 CATTLEytics Inc.

import { FC } from 'react';
import { Mention, MentionsInput, MentionsInputProps, SuggestionDataItem } from 'react-mentions';

import { Animal, ApiResourceV1, nameTag } from '../../../shared';
import { api } from '../../utilities';

type Props = Omit<MentionsInputProps, 'children'>;

/**
 * Gets the list of animals for the MentionsInput api.
 *
 * Based on example from https://github.com/signavio/react-mentions/blob/master/demo/src/examples/AsyncGithubUserMentions.js
 */
function fetchAnimals(query: string, callback: (data: SuggestionDataItem[]) => void): void {
  api<Animal[]>('GET', ApiResourceV1.Animals, {
    params: { search: query, includeReferenceAnimals: String(false), status: 'active' },
  })
    .then((res) =>
      res.map<SuggestionDataItem>(({ id, name, primaryTag }) => ({
        id,
        display: nameTag(primaryTag, name),
      })),
    )
    .then(callback);
}

export const AnimalMentionsTextArea: FC<Props> = (props) => {
  return (
    <MentionsInput {...props} className="mentions">
      <Mention
        data={fetchAnimals}
        displayTransform={(id, name): string => `@${name}`}
        trigger="@"
      />
    </MentionsInput>
  );
};
