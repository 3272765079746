// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useState } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import Button from '../../common/components/Button';
import { ButtonVariant } from '../../common/components/Button';
import ConfirmModal from '../../common/components/ConfirmModal';
import DataTable, { DataTableHeader, DataTableRow } from '../../common/components/DataTable';
import TablePlaceholder from '../../common/components/TablePlaceholder';
import { Sort } from '../../common/enums';
import SiteService from '../../common/services/siteService';
import { IconDelete, IconEdit } from '../../common/utilities';
import { QueryKey } from '../../shared/enums';
import SiteModal from './SiteModal';

interface Props {
  /**
   * Additional CSS classes to add to component.
   */
  className?: string;

  /**
   * Keys to filter data.
   */
  filters?: Record<string, string>;

  /**
   * Number of users to show.
   */
  initialLimit?: number;

  /**
   * Number of users to skip.
   */
  initialOffset?: number;

  /**
   * Table sort direction.
   */
  initialSortDirection?: Sort;

  /**
   * Table sort field.
   */
  initialSortField?: string;
}

/**
 * A table component containing animal event data.
 */
const SitesTable = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const siteService = useInjection<SiteService>(TYPES.siteService);

  const [editId, setEditId] = useState<number>();
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);

  const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState<boolean>(false);
  const [deleteConfirmModalErrorMessage, setDeleteConfirmModalErrorMessage] = useState<string>();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();

  const [limit, setLimit] = useState<number>(props.initialLimit ?? 25);
  const [offset, setOffset] = useState<number>(props.initialOffset ?? 0);
  const [sortField, setSortField] = useState<string>(props.initialSortField ?? 'id');
  const [sortDirection, setSortDirection] = useState<Sort>(
    props.initialSortDirection ?? Sort.Ascending,
  );
  const filter: undefined | Record<string, string> = props.filters;

  const query = useQuery(
    [QueryKey.Sites, filter, limit, offset, sortField, sortDirection],
    () =>
      siteService.list({
        ...filter,
        ...{
          limit: String(limit),
          offset: String(offset),
          sortField: sortField,
          sortDirection: String(sortDirection),
        },
      }),
    { keepPreviousData: true },
  );

  const showEditModal = (editId: number): void => {
    setEditId(editId);
    setEditModalVisible(true);
  };

  const showDeleteConfirmModal = (id: number): void => {
    setDeleteConfirmModalVisible(true);
    setDeleteId(id);
  };

  const queryClient = useQueryClient();

  const deleteItem = async (): Promise<void> => {
    if (!deleteId) {
      return;
    }
    setIsDeleting(true);
    try {
      await siteService.delete(deleteId);
      await queryClient.invalidateQueries(QueryKey.Sites);
      setDeleteConfirmModalVisible(false);
    } catch (err) {
      setDeleteConfirmModalErrorMessage('This item could not be deleted.');
    }
    setIsDeleting(false);
    await queryClient.invalidateQueries(QueryKey.Sites);
  };

  if (query.isLoading) {
    return <TablePlaceholder />;
  }

  if (query.isError) {
    return (
      <AlertError
        message={t('common|unexpectedRetrievalError', {
          value: t('sites'),
        })}
      />
    );
  }

  const headers: DataTableHeader[] = [
    {
      name: 'id',
      label: t('ID'),
      classNameMobile: 'col-6',
    },
    {
      name: 'name',
      label: t('Name'),
      classNameMobile: 'col-6',
    },
    { name: 'actions', sortable: false, label: 'Actions', hideMobile: true },
  ];

  const data: DataTableRow[] = !query.data
    ? []
    : query.data.map((row) => ({
        id: String(row.id),
        name: row.name,
        actions: (
          <div onClick={(e): void => e.stopPropagation()}>
            <Dropdown as={ButtonGroup}>
              <Button
                onClick={(): void => showEditModal(row.id)}
                size="sm"
                variant="outline-primary"
              >
                <IconEdit className={'me-1'} /> {t('Edit')}
              </Button>
              <Dropdown.Toggle size="sm" split variant="outline-primary" />
              <Dropdown.Menu align="end">
                <Dropdown.Item onClick={(): void => showEditModal(row.id)}>
                  <IconEdit className={'me-1'} /> {t('Edit')}
                </Dropdown.Item>
                <Dropdown.Item onClick={(): void => showDeleteConfirmModal(row.id)}>
                  <IconDelete className={'me-1'} />
                  {t('Delete')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),
      }));

  return (
    <div>
      <DataTable
        data={data}
        headers={headers}
        isLoading={query.isLoading}
        isPreviousData={query.isPreviousData}
        limit={limit}
        messageNoData={t('sitesTable|noDataFoundMessage', { value: t('sites') })}
        offset={offset}
        onLimitChange={(newLimit): void => setLimit(newLimit)}
        onOffsetChange={(newOffset): void => setOffset(newOffset)}
        onRowClick={(row): void => showEditModal(Number(row.id))}
        onSortDirectionChange={(newSortDirection): void => setSortDirection(newSortDirection)}
        onSortFieldChange={(newSortField): void => setSortField(newSortField)}
        sortDirection={sortDirection}
        sortField={sortField}
      />

      {editModalVisible && (
        <SiteModal editId={editId as number} onClose={(): void => setEditModalVisible(false)} />
      )}
      <ConfirmModal
        busy={isDeleting}
        cancelLabel={t('Cancel')}
        cancelOnClick={(): void => setDeleteConfirmModalVisible(false)}
        errorMessage={deleteConfirmModalErrorMessage}
        okLabel={t(`Yes, delete this {{value}}`, { value: 'site' })}
        okOnClick={deleteItem}
        okVariant={ButtonVariant.Danger}
        title={t(`Delete this {{value}}`, { value: 'site' })}
        visible={deleteConfirmModalVisible}
      >
        {t('sitesTable|deleteSiteWarning', { value: 'site' })}
      </ConfirmModal>
    </div>
  );
};

export default SitesTable;
