// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Form, Placeholder } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { TYPES } from '../../../types';
import AnimalAutocomplete from '../../animals/components/AnimalAutocomplete';
import NoteTagsAutocomplete from '../../animals/components/NoteTagsAutocomplete';
import PenAutocomplete from '../../animals/components/PenAutocomplete';
import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import ButtonModalCancel from '../../common/components/ButtonModalCancel';
import ButtonModalSave from '../../common/components/ButtonModalSave';
import Modal from '../../common/components/Modal';
import Required from '../../common/components/Required';
import Note, { noteDefault } from '../../common/entities/note';
import Pen from '../../common/entities/pen';
import AnimalService from '../../common/services/animalService';
import NoteService from '../../common/services/noteService';
import Logger from '../../logger/logger';
import { Animal } from '../../shared';
import { QueryKey } from '../../shared/enums';

/**
 * Component input properties.
 */
interface Props {
  /**
   * Animal ID to associate this note with
   */
  animalId?: number;

  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * ID of note to edit in this modal.
   */
  noteId?: number;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;

  /**
   * Callback with saved note.
   */
  onSave?: (note: Note) => void;

  /**
   * Default pen to select.
   */
  pen?: Pen;

  /**
   * Default pen to select.
   */
  tags?: string[] | undefined;
}

/**
 * Note modal component for creating/editing notes.
 */
const NoteModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const logger = useInjection<Logger>(TYPES.logger);
  const noteService = useInjection<NoteService>(TYPES.noteService);
  const animalService = useInjection<AnimalService>(TYPES.animalService);
  const note = noteDefault();
  const [data, setData] = useState<Note>(note);
  const [validated, setValidated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useQuery([QueryKey.Animals], () => animalService.get(String(props.animalId)), {
    onSuccess: (animal) => {
      setData((note) => ({
        ...note,
        ...{
          animal: animal,
          animalId: animal?.id,
        },
      }));
    },
  });

  if (props.pen) {
    note.pen = props.pen;
    note.penId = props.pen.id;
  }

  if (props.tags) {
    note.tags = [];
    note.tags.push(...props.tags);
  }

  const query = useQuery(
    [QueryKey.Notes, props.noteId],
    () => noteService.get(props.noteId as number),
    {
      refetchOnWindowFocus: false,
      enabled: !!props.noteId,
    },
  );

  const mutation = useMutation(
    () => {
      if (props.noteId) {
        return noteService.patch(data.id, data);
      } else {
        return noteService.post(data);
      }
    },
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(QueryKey.Notes);
      },
    },
  );

  const queryClient = useQueryClient();

  useEffect(() => {
    if (query.data) {
      setData(query.data);
    }
  }, [query.data]);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newData = { ...data };
    newData[event.target.name] = event.target.value;
    setData(newData);
  };

  const onTypeaheadSelect = (name: string, value?: Animal | Pen): void => {
    const newData = { ...data };
    if (!value) {
      newData[name.slice(0, name.length - 2)] = null;
      newData[name] = null;
    } else {
      newData[name.slice(0, name.length - 2)] = value;
      newData[name] = value.id;
    }
    setData(newData);
  };

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      await mutation.mutateAsync();
      if (props.onSave) {
        props.onSave(data);
      }

      props.onClose();
    } catch (err) {
      logger.error('Note could not be saved.', err, data);
      setErrorMessage(t('Note could not be saved.'));
    }
  };

  const fields = (
    <>
      <Form.Group className="form-group mb-3" controlId="formNote">
        <Form.Label>
          Note <Required />
        </Form.Label>
        <Form.Control
          aria-label="Note"
          as={'textarea'}
          name={'body'}
          onChange={onInputChange}
          required={true}
          rows={4}
          value={data.body}
        />
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="form-group mb-3" controlId="formAnimal">
        <Form.Label>{t('Animal')}</Form.Label>
        <AnimalAutocomplete
          id={'animal'}
          name={'animalId'}
          onSelect={(animal): void => onTypeaheadSelect('animalId', animal as Animal | undefined)}
          required={false}
          selected={data.animalId ? data.animal : undefined}
        />
      </Form.Group>
      <Form.Group className="form-group mb-3" controlId="formPenAutocomplete">
        <Form.Label>{t('Pen')}</Form.Label>
        <PenAutocomplete
          id={'formPenAutocomplete'}
          onSelect={(pen): void => onTypeaheadSelect('penId', pen)}
          required={false}
          selected={data.penId ? data.pen : undefined}
        />
      </Form.Group>
      <Form.Group className="form-group mb-3" controlId="formNoteTagAutocomplete">
        <Form.Label>{t('Tag')}</Form.Label>
        <NoteTagsAutocomplete
          id={'formNoteTagsAutocomplete'}
          multiple={true}
          onSelect={(tags): void => {
            let newTags: string[] = [];

            if (tags) {
              if (Array.isArray(tags)) {
                tags.forEach((e) => newTags.push(e));
              } else {
                newTags = [tags];
              }
            }

            setData({ ...data, tags: newTags });
            note.tags = newTags;
          }}
          required={false}
          selected={data.tags ? data.tags : undefined}
        />
      </Form.Group>
    </>
  );
  const placeholder = (
    <Placeholder animation={'glow'}>
      <Placeholder className={'mt-3'} size={'lg'} xs={6} />
      <Placeholder xs={12} />
      <Placeholder xs={12} />
      <Placeholder xs={12} />
      <Placeholder className={'mt-3'} size={'lg'} xs={6} />
      <Placeholder xs={12} />
      <Placeholder className={'mt-3'} size={'lg'} xs={6} />
      <Placeholder xs={12} />
    </Placeholder>
  );

  return (
    <Modal
      onClose={props.onClose}
      size={'lg'}
      title={`${props.noteId ? 'Edit' : 'Create'} Note`}
      visible={true}
    >
      <Form noValidate={true} onSubmit={onFormSubmit} validated={validated}>
        {query.isLoading ? placeholder : fields}
        {/*<Form.Group className="mb-3" controlId="formBasicEmail">*/}
        {/*  <Form.Label>Animal</Form.Label>*/}
        {/*  <Form.Text aria-label="Pen filter" value={data.id} name={'id'} onChange={onInputChange} />*/}
        {/*</Form.Group>*/}
        <AlertErrorForModal message={errorMessage} />

        <div className="modal-footer modal-footer-in-form">
          <ButtonModalCancel
            busy={mutation.isLoading}
            disabled={mutation.isLoading || query.isLoading}
            onClick={props.onClose}
          />
          <ButtonModalSave
            busy={mutation.isLoading}
            disabled={mutation.isLoading || query.isLoading}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default NoteModal;
