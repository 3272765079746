// Copyright © 2023 CATTLEytics Inc.

import Konva from 'konva';
import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { Group, Line, Rect } from 'react-konva';

/**
 * @description Component input properties.
 */
interface Props {
  /**
   * @description Callback when mouse clicks on component.
   */
  onClick?: (event: Konva.KonvaEventObject<MouseEvent>) => void;

  /**
   * @description Callback when mouse hovers over component.
   */
  onMouseEnter?: (event: Konva.KonvaEventObject<MouseEvent>) => void;

  /**
   * @description Callback when mouse hovers away from component.
   */
  onMouseLeave?: (event: Konva.KonvaEventObject<MouseEvent>) => void;

  /**
   * @description Size (same prop for width and height) of this button.
   * @type {number}
   */
  size: number;

  /**
   * @description Whether or not to show component.
   */
  visible?: boolean;

  /**
   * @description Horizontal (top left) coordinate to draw this component.
   */
  x: number;

  /**
   * @description Vertical (top left) coordinate to draw this component.
   */
  y: number;
}

/**
 * @description A Konva rectangle with an x drawn inside.
 * @extends {React.Component}
 */
const DeleteRegionButton = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const visible = props.visible ?? true;
  if (!visible) {
    return <Fragment />;
  }
  return (
    <Group
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      x={props.x}
      y={props.y}
    >
      <Rect
        fill={'rgba(59,132,145,0.8)'}
        height={props.size}
        width={props.size}
        //fill={'red'}
      />
      <Line
        lineCap={'round'}
        points={[
          props.size * 0.1,
          props.size * 0.1,
          props.size - props.size * 0.1,
          props.size - props.size * 0.1,
        ]}
        stroke={'white'}
        strokeWidth={props.size * 0.05}
      />
      <Line
        lineCap={'round'}
        points={[
          props.size - props.size * 0.1,
          props.size * 0.1,
          props.size * 0.1,

          props.size - props.size * 0.1,
        ]}
        stroke={'white'}
        strokeWidth={props.size * 0.05}
      />
    </Group>
  );
};

export default withTranslation()(DeleteRegionButton);
