// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { Placeholder } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

/**
 * Placeholder for page titles.
 */
const TitlePlaceholder = (): JSX.Element => {
  return (
    <Placeholder animation={'glow'}>
      <Placeholder size={'lg'} style={{ width: '12rem' }} />
    </Placeholder>
  );
};

export default withTranslation()(TitlePlaceholder);
