// Copyright © 2024 CATTLEytics Inc.

import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import AlertError from '../../../common/components/AlertError';
import DataTable, { DataTableHeader, DataTableRow } from '../../../common/components/DataTable';
import { api } from '../../../common/utilities/api';
import { QueryKey } from '../../../shared';
import { ReportChronicIssues } from '../../../shared';
import { ApiResourceV1 } from '../../../shared/enums/api';
import { HttpMethod } from '../../../shared/enums/http';

interface Props {
  /**
   * Animal id
   */
  animalId: number;
}

/**
 * A table component containing animal's repro information.
 */
const ReproChronicIssuesTable = (props: PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const query = useQuery<ReportChronicIssues>([QueryKey.ReportChronicIssues, props.animalId], () =>
    api<ReportChronicIssues>(
      HttpMethod.Get,
      `${ApiResourceV1.AnimalEvents}/${props.animalId}/chronic-issues`,
    ),
  );

  if (query.isError) {
    return <AlertError message={t('reproChronicIssuesTable|fetchError')} />;
  }

  const headers: DataTableHeader[] = [
    {
      name: 'lameness',
      label: t('reproChronicIssuesTable|lameness'),
      classNameMobile: 'col-6',
    },
    {
      name: 'mastitis',
      label: t('reproChronicIssuesTable|mastitis'),
      classNameMobile: 'col-6',
    },
    {
      name: 'metritis',
      label: t('reproChronicIssuesTable|metritis'),
      classNameMobile: 'col-6',
    },
  ];

  const data: DataTableRow[] = !query.data
    ? []
    : query.data.map((row: ReportChronicIssues) => ({
        lameness: row.lameness.toString(),
        mastitis: row.mastitis.toString(),
        metritis: row.metritis.toString(),
      }));

  return (
    <div>
      <h2>{t('reproChronicIssuesTable|header')}</h2>
      <DataTable
        data={data}
        headers={headers}
        hidePaginationControls={true}
        isLoading={query.isLoading}
        messageNoData={t('reproChronicIssuesTable|messageNoData')}
      />
    </div>
  );
};

export default ReproChronicIssuesTable;
