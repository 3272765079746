// Copyright © 2023 CATTLEytics Inc.

/**
 * WARNING!
 *
 * DO NOT EDIT THIS FILE
 *
 * CHANGES WILL BE OVERWRITTEN!
 *
 * Make changes in packages/shared and run npm run copy-shared-to-packages
 */

export enum TaskStatus {
  Completed = 'completed',
  InProgress = 'in-progress',
  Pending = 'pending',
  Scheduled = 'scheduled',
  Skipped = 'skipped',
}
