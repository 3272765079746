// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { Alert } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
// import { IconError } from '../utilities';

/**
 * Defines the input properties for this component.
 */
interface Props extends WithTranslation {
  /**
   * Error message to display. If empty or not defined alert will be hidden.
   */
  message?: string;
}

/**
 * A wrapper for the Alert component for errors that occur within a modal.
 */
const AlertSuccessForModal = (props: Props): JSX.Element => {
  return props.message ? (
    <Alert className="mt-4" variant="success">
      {/* <IconError className="me-2" /> */}
      {props.message}
    </Alert>
  ) : (
    <></>
  );
};

export default withTranslation()(AlertSuccessForModal);
