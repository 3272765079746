// Copyright © 2023 CATTLEytics Inc.

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Button, { ButtonVariant } from '../../common/components/Button';
import ConfirmModal from '../../common/components/ConfirmModal';
import { IconDelete, IconDrag, IconDuplicate, IconEdit } from '../../common/utilities';
import { BreedingProgram as BreedingProgramEntity, BreedingProgramType } from '../../shared';
import BreedingProgramCreateModal from './BreedingProgramCreateModal';
import BreedingProgramStats from './BreedingProgramStats';

/**
 * Defines the input properties for the BreedingProgram component.
 */
interface Props {
  /**
   * Breeding program.
   */
  breedingProgram: BreedingProgramEntity;

  /**
   * @description Breeding program types.
   * @type {BreedingProgramType[]}
   * @memberOf Props
   */
  breedingProgramTypes: BreedingProgramType[];

  /**
   * To help the drag and drop container keep track of element order.
   */
  index: number;

  /**
   * Is the program that is being dragged over this component?
   */
  isOver?: boolean;

  /**
   * @description Callback when delete button is fixed.
   * @param {number} programId
   * @memberOf Props
   */
  onDeleteProgram: (programId: number) => void;

  /**
   * @description Callback when duplicate button is clicked.
   * @param {BreedingProgramEntity} breedingProgram
   * @memberOf Props
   */
  onDuplicateProgram: (breedingProgram: BreedingProgramEntity) => void;

  /**
   * @description Callback when save button is clicked.
   * @param {BreedingProgramEntity} breedingProgram
   * @memberOf Props
   */
  onSaveProgram: (breedingProgram: BreedingProgramEntity) => void;
}

/**
 * @description Breeding program component.
 * @const BreedingProgram
 * @return {JSX.Element}
 */
const BreedingProgram = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState<boolean>(false);
  const [deleteConfirmModalBusy, setDeleteConfirmModalBusy] = useState<boolean>(false);
  const [deleteButtonBusy, setDeleteButtonBusy] = useState<boolean>(false);
  const [deleteConfirmErrorMessage, setDeleteConfirmErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [editProgramModalVisible, setEditProgramModalVisible] = useState<boolean>(false);
  const [editButtonBusy, setEditButtonBusy] = useState<boolean>(false);

  const program = props.breedingProgram;

  const { attributes, listeners, isDragging, setNodeRef, transform } = useSortable({
    id: String(program.id),
  });

  const style = {
    opacity: isDragging ? '0.9' : '1',
    zIndex: isDragging && !props.isOver ? 1000 : 0,
    transform: CSS.Transform.toString(transform),
  };

  return (
    <Card className={`h-100 ${isDragging ? '0.9' : '1'}`} ref={setNodeRef} style={style}>
      <Card.Header
        className="d-flex justify-content-between"
        style={{ cursor: 'grab' }}
        {...attributes}
        {...listeners}
      >
        <div>
          {program.description} - {program.duration} days -{' '}
          {program.breedingProgramType?.description}
        </div>
        <IconDrag />
      </Card.Header>
      <Card.Body>
        <BreedingProgramStats breedingProgram={program} />
      </Card.Body>
      <Card.Footer className="d-flex justify-content-between">
        <Button
          ariaLabel={t(`Delete program with ID`, { id: program.id })}
          busy={deleteButtonBusy}
          className="rounded-circle"
          icon={IconDelete}
          onClick={(): void => {
            setDeleteButtonBusy(true);
            setDeleteConfirmModalVisible(true);
          }}
          size="sm"
          variant={ButtonVariant.OutlinePrimary}
        />
        <Button
          ariaLabel={t(`Duplicate program with ID`, { id: program.id })}
          className="rounded-circle"
          icon={IconDuplicate}
          onClick={(): void => props.onDuplicateProgram(props.breedingProgram)}
          size="sm"
          variant={ButtonVariant.OutlinePrimary}
        />
        <Button
          ariaLabel={t(`Edit program with ID`, { id: program.id })}
          busy={editButtonBusy}
          className="rounded-circle"
          icon={IconEdit}
          onClick={(): void => {
            setEditButtonBusy(true);
            setEditProgramModalVisible(true);
          }}
          size="sm"
          variant={ButtonVariant.OutlinePrimary}
        />
      </Card.Footer>
      <ConfirmModal
        busy={deleteConfirmModalBusy}
        cancelLabel={t('Cancel')}
        cancelOnClick={(): void => {
          setDeleteConfirmModalVisible(false);
          setDeleteConfirmModalBusy(false);
          setDeleteButtonBusy(false);
        }}
        errorMessage={deleteConfirmErrorMessage}
        okLabel={t('Yes, delete this program')}
        okOnClick={async (): Promise<void> => {
          setDeleteConfirmModalBusy(true);
          try {
            props.onDeleteProgram(program.id);
            setDeleteConfirmModalVisible(false);
            setDeleteConfirmModalBusy(false);
            setDeleteButtonBusy(false);
          } catch (err) {
            setDeleteConfirmModalBusy(false);
            setDeleteConfirmErrorMessage(t('breedingProgram|planDeleteError'));
          }
        }}
        okVariant={ButtonVariant.Danger}
        title={t('Delete Breeding Program')}
        visible={deleteConfirmModalVisible}
      >
        {t('Are you sure you want to delete this program?')}
      </ConfirmModal>
      {editProgramModalVisible ? (
        <BreedingProgramCreateModal
          breedingProgram={props.breedingProgram}
          breedingProgramTypes={props.breedingProgramTypes}
          onClose={(): void => {
            setEditButtonBusy(false);
            setEditProgramModalVisible(false);
          }}
          onSaveProgram={props.onSaveProgram}
        />
      ) : null}
    </Card>
  );
};

export default BreedingProgram;
