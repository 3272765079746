// Copyright © 2024 CATTLEytics Inc.

import { SQLDialect } from '@codemirror/lang-sql';
import { tags } from '@lezer/highlight';
import { createTheme } from '@uiw/codemirror-themes';

export const CQL = SQLDialect.define({
  keywords: 'list show events count sum pct plot econ bredsum guide for by downby',
});

export const lightTheme = createTheme({
  theme: 'light',
  settings: {
    background: '#ffffff',
    foreground: '#5c6166',
    caret: '#5d00ff',
    selection: '#036dd626',
    selectionMatch: '#036dd626',
    lineHighlight: '#8a91991a',
    gutterBackground: '#fff',
    gutterForeground: '#8a919966',
  },
  styles: [
    { tag: tags.comment, color: '#787b8099' },
    { tag: tags.variableName, color: '#5c6166' },
    { tag: [tags.string, tags.special(tags.brace)], color: '#5c6166' },
    { tag: tags.number, color: '#5c6166' },
    { tag: tags.bool, color: '#5c6166' },
    { tag: tags.null, color: '#5c6166' },
    { tag: tags.keyword, color: '#0080ff' },
    { tag: tags.operator, color: '#5c6166' },
    { tag: tags.className, color: '#5c6166' },
    { tag: tags.definition(tags.typeName), color: '#5c6166' },
    { tag: tags.typeName, color: '#5c6166' },
    { tag: tags.angleBracket, color: '#5c6166' },
    { tag: tags.tagName, color: '#5c6166' },
    { tag: tags.attributeName, color: '#5c6166' },
  ],
});
export const darkTheme = createTheme({
  theme: 'dark',
  settings: {
    background: '#333333',
    foreground: '#cccccc',
    caret: '#fff',
    selection: '#036dd626',
    selectionMatch: '#036dd626',
    lineHighlight: '#8a91991a',
    gutterBackground: '#333',
    gutterForeground: '#8a919966',
  },
  styles: [
    { tag: tags.comment, color: '#787b8099' },
    { tag: tags.variableName, color: '#eee' },
    { tag: [tags.string, tags.special(tags.brace)], color: '#eee' },
    { tag: tags.number, color: '#eee' },
    { tag: tags.bool, color: '#eee' },
    { tag: tags.null, color: '#eee' },
    { tag: tags.keyword, color: '#0080ff' },
    { tag: tags.operator, color: '#eee' },
    { tag: tags.className, color: '#eee' },
    { tag: tags.definition(tags.typeName), color: '#eee' },
    { tag: tags.typeName, color: '#eee' },
    { tag: tags.angleBracket, color: '#eee' },
    { tag: tags.tagName, color: '#eee' },
    { tag: tags.attributeName, color: '#eee' },
  ],
});
export const lightThemeJson = createTheme({
  theme: 'light',
  settings: {
    background: '#ffffff',
    foreground: '#5c6166',
    caret: '#5d00ff',
    selection: '#036dd626',
    selectionMatch: '#036dd626',
    lineHighlight: '#8a91991a',
    gutterBackground: '#fff',
    gutterForeground: '#8a919966',
  },
  styles: [
    { tag: tags.comment, color: '#787b8099' },
    { tag: tags.variableName, color: '#5c6166' },
    { tag: tags.squareBracket, color: '#7b3814' },
    { tag: tags.string, color: '#0451a5' },
    { tag: tags.number, color: '#098658' },
    { tag: tags.bool, color: '#0000ff' },
    { tag: tags.null, color: '#0000ff' },
    { tag: tags.brace, color: '#319331' },
    { tag: tags.propertyName, color: '#a31515' },
    { tag: tags.keyword, color: '#0080ff' },
    { tag: tags.operator, color: '#5c6166' },
    { tag: tags.className, color: '#5c6166' },
    { tag: tags.definition(tags.typeName), color: '#5c6166' },
    { tag: tags.typeName, color: '#5c6166' },
    { tag: tags.angleBracket, color: '#5c6166' },
    { tag: tags.tagName, color: '#5c6166' },
    { tag: tags.attributeName, color: '#5c6166' },
  ],
});
export const darkThemeJson = createTheme({
  theme: 'dark',
  settings: {
    background: '#333333',
    foreground: '#cccccc',
    caret: '#fff',
    selection: '#036dd626',
    selectionMatch: '#036dd626',
    lineHighlight: '#8a91991a',
    gutterBackground: '#333',
    gutterForeground: '#8a919966',
  },
  styles: [
    { tag: tags.comment, color: '#787b8099' },
    { tag: tags.variableName, color: '#eee' },
    { tag: tags.squareBracket, color: '#179fff' },
    { tag: tags.string, color: '#ce9178' },
    { tag: tags.number, color: '#b5cea8' },
    { tag: tags.bool, color: '#ce9178' },
    { tag: tags.null, color: '#ce9178' },
    { tag: tags.brace, color: '#da70d6' },
    { tag: tags.propertyName, color: '#9cdcfe' },
    { tag: tags.keyword, color: '#0080ff' },
    { tag: tags.operator, color: '#eee' },
    { tag: tags.className, color: '#eee' },
    { tag: tags.definition(tags.typeName), color: '#eee' },
    { tag: tags.typeName, color: '#eee' },
    { tag: tags.angleBracket, color: '#eee' },
    { tag: tags.tagName, color: '#eee' },
    { tag: tags.attributeName, color: '#eee' },
  ],
});

export const formatCode = (code: string): string => {
  return `${code
    .replaceAll(/\n/g, ' ')
    .replaceAll(/\s+/g, ' ')
    .replaceAll(' FOR', '\nFOR')
    .replaceAll(' DOWNBY', '\nDOWNBY')
    .replaceAll(' BY', '\nBY')
    .trim()}\n`;
};
