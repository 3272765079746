// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useState } from 'react';
import { Form, Placeholder } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { TYPES } from '../../../types';
import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import { ButtonVariant } from '../../common/components/Button';
import ButtonModal from '../../common/components/ButtonModal';
import Modal from '../../common/components/Modal';
import TaskService from '../../common/services/taskService';
import { IconComplete } from '../../common/utilities';
import Logger from '../../logger/logger';
import { Task, TaskStatus } from '../../shared';
import { QueryKey } from '../../shared/enums';

/**
 * Component input properties.
 */
interface Props {
  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;

  /**
   * ID of task to mark complete and comment on in this modal.
   */
  taskId: number;
}

/**
 * Task modal component for creating/editing tasks.
 */
const TaskModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const logger = useInjection<Logger>(TYPES.logger);
  const taskService = useInjection<TaskService>(TYPES.taskService);

  const [comment, setComment] = useState<string>('');
  const [validated, setValidated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const query = useQuery<Task | undefined>(
    [QueryKey.Tasks, props.taskId],
    () => taskService.get(props.taskId as number),
    {
      enabled: !!props.taskId,
    },
  );

  const mutation = useMutation(
    () => {
      if (!query.data) {
        throw Error('Invalid task');
      }
      return taskService.patch(props.taskId, {
        status: TaskStatus.Completed,
        completed: true,
        notes: `${query.data.notes ?? ''}\n\nComment:\n${comment}`,
      });
    },
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(QueryKey.Tasks);
      },
    },
  );

  const queryClient = useQueryClient();

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      await mutation.mutateAsync();

      props.onClose();
    } catch (err) {
      logger.error('Task could not be saved.', err);
      setErrorMessage(t('taskCommentModal|taskNotSavedError'));
    }
  };

  const fields = (
    <>
      <Form.Group className="mb-3" controlId="formTask">
        <Form.Label>Comment</Form.Label>
        <Form.Control
          aria-label="Comment"
          as={'textarea'}
          name={comment}
          onChange={(e): void => setComment(e.target.value)}
          required={true}
          rows={4}
          value={comment}
        />
        <Form.Control.Feedback type={'invalid'}>This field is required.</Form.Control.Feedback>
      </Form.Group>
    </>
  );
  const placeholder = (
    <Placeholder animation={'glow'}>
      <Placeholder className={'mt-3'} size={'lg'} xs={6} />
      <Placeholder xs={12} />
      <Placeholder xs={12} />
      <Placeholder xs={12} />
      <Placeholder className={'mt-3'} size={'lg'} xs={6} />
      <Placeholder xs={12} />
      <Placeholder className={'mt-3'} size={'lg'} xs={6} />
      <Placeholder xs={12} />
    </Placeholder>
  );

  return (
    <Modal
      onClose={props.onClose}
      size={'lg'}
      title={`${props.taskId ? 'Edit' : 'Create'} Task`}
      visible={true}
    >
      <Form noValidate={true} onSubmit={onFormSubmit} validated={validated}>
        {query.isLoading ? placeholder : fields}
        {/*<Form.Group className="mb-3" controlId="formBasicEmail">*/}
        {/*  <Form.Label>Animal</Form.Label>*/}
        {/*  <Form.Text aria-label="Pen filter" value={data.id} name={'id'} onChange={onInputChange} />*/}
        {/*</Form.Group>*/}
        <AlertErrorForModal message={errorMessage} />
        <div className="modal-footer modal-footer-in-form">
          <ButtonModal
            disabled={mutation.isLoading || query.isLoading}
            label={t('Cancel')}
            onClick={props.onClose}
            type={'button'}
            variant={ButtonVariant.Secondary}
          />
          <ButtonModal
            busy={mutation.isLoading}
            disabled={mutation.isLoading || query.isLoading}
            icon={IconComplete}
            label={t('Complete with comment')}
            type="submit"
          />
        </div>
      </Form>
    </Modal>
  );
};

export default TaskModal;
