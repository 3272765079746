// Copyright © 2023 CATTLEytics Inc.

export const getFirstLetters = (str: string, limit?: number): string => {
  const firstLetters = str
    .split(' ')
    .map((word) => word[0])
    .join('');
  if (limit) {
    return firstLetters.slice(0, limit);
  }
  return firstLetters;
};
