// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useEffect, useState } from 'react';
import { Placeholder, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { TYPES } from '../../../types';
import DateTime from '../../common/components/DateTime';
import HeightUnit from '../../common/components/HeightUnit';
import Modal from '../../common/components/Modal';
import TemperatureUnit from '../../common/components/TemperatureUnit';
import WeightUnit from '../../common/components/WeightUnit';
import AnimalEvent from '../../common/entities/animalEvent';
import AnimalEventService from '../../common/services/animalEventService';
import ProductService from '../../common/services/productService';
import { IconPen, IconTag, IconUsers } from '../../common/utilities';
import { isNullOrWhitespace, nameTag, Product } from '../../shared';
import { QueryKey } from '../../shared/enums';

/**
 * Component input properties.
 */
interface Props {
  /**
   * Animal event identifier to filter the event details query with.
   */
  animalEventId: number;

  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;
}

/**
 * Event modal component for creating/editing events.
 */
const EventDetailModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const eventService = useInjection<AnimalEventService>(TYPES.animalEventService);
  const productService = useInjection<ProductService>(TYPES.productService);

  const [productSearchQuery, setProductSearchQuery] = useState<string>('');

  const queryEvent = useQuery<AnimalEvent | undefined>(
    [QueryKey.AnimalEvents, props.animalEventId],
    () => eventService.get(props.animalEventId),
  );

  useEffect(() => {
    if (queryEvent.data && queryEvent.data.notes) {
      const productName = queryEvent.data.notes;
      if (isNullOrWhitespace(productName)) {
        return;
      }
      // @TODO this is a hack, products should be linked properly
      if (productName.includes('J-5')) {
        setProductSearchQuery('J-5');
      } else {
        setProductSearchQuery(productName);
      }
    }
  }, [queryEvent.data]);

  const queryProduct = useQuery<Product[]>(
    [QueryKey.Products, productSearchQuery, 1],
    () => productService.list({ search: productSearchQuery, limit: '1' }),
    { enabled: !isNullOrWhitespace(productSearchQuery) },
  );

  const placeholderEvent = (
    <Placeholder animation={'glow'} aria-label={'Event information loading'}>
      <Placeholder className={'mt-3 me-4'} size={'lg'} style={{ width: '9rem' }} />
      <Placeholder className={'mt-3'} size={'lg'} style={{ width: '9rem' }} xs={2} />
      <br />
      <Placeholder className={'mt-3 me-4'} size={'lg'} style={{ width: '9rem' }} />
      <Placeholder className={'mt-3'} size={'lg'} style={{ width: '8rem' }} xs={2} />
      <br />
    </Placeholder>
  );

  const event = queryEvent && queryEvent.data && (
    <Table>
      <thead>
        <tr>
          <th>{t('Field')}</th>
          <th>{t('Value')}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{t('Event ID')}:</td>
          <td>{queryEvent.data.id}</td>
        </tr>
        <tr>
          <td>{t('Event Type')}:</td>
          <td>{queryEvent.data.animalEventType?.name}</td>
        </tr>
        <tr>
          <td>{t('Event Date')}:</td>
          <td>
            <DateTime date={queryEvent.data.eventDateTime} hideTime={true} />
          </td>
        </tr>

        {queryEvent.data.activeTreatmentId && (
          <tr>
            <td>{t('Active Treatment')}:</td>
            <td>
              <Link to={`/active-treatments/${queryEvent.data?.activeTreatmentId}`}>
                {t('Treatment')}
              </Link>
            </td>
          </tr>
        )}
        {queryEvent.data.animalId && queryEvent.data.animal && (
          <tr>
            <td>{t('Animal')}:</td>
            <td>
              <Link to={`/animals/${queryEvent.data?.animalId}`}>
                <IconTag className={'me-1'} />
                {nameTag(queryEvent.data.animal.primaryTag, queryEvent.data.animal.name)}
              </Link>
            </td>
          </tr>
        )}
        {queryEvent.data.calvingDifficulty && (
          <tr>
            <td>{t('Calving Difficulty')}:</td>
            <td>{queryEvent.data.calvingDifficulty}</td>
          </tr>
        )}
        {queryEvent.data.calvingAssistance && (
          <tr>
            <td>{t('Calving Assistance')}:</td>
            <td>{queryEvent.data.calvingAssistance}</td>
          </tr>
        )}
        {queryEvent.data.calvingDueDate && (
          <tr>
            <td>{t('Calving Due Date')}:</td>
            <td>
              <DateTime date={queryEvent.data.calvingDueDate} hideTime={true} />
            </td>
          </tr>
        )}
        {queryEvent.data.diagnosisRegimeId && queryEvent.data.diagnosisRegime && (
          <tr>
            <td>{t('Diagnosis Regime')}:</td>
            <td>
              <Link to={`/diagnosis-regimes/${queryEvent.data?.diagnosisRegimeId}`}>
                {queryEvent.data.diagnosisRegime.name}
              </Link>
            </td>
          </tr>
        )}
        {queryEvent.data.diagnosisRegimeDetailId && queryEvent.data.diagnosisRegimeDetail && (
          <tr>
            <td>{t('Diagnosis Regime Detail')}:</td>
            <td>
              <Link to={`/diagnosis-regimes/${queryEvent.data?.diagnosisRegimeId}`}>
                {queryEvent.data.diagnosisRegimeDetail.name}
              </Link>
            </td>
          </tr>
        )}
        {queryEvent.data.dim && (
          <tr>
            <td>{t('DIM')}:</td>
            <td>{queryEvent.data.dim}</td>
          </tr>
        )}
        {queryEvent.data.externalAnimalEvent?.externalId && (
          <tr>
            <td>{t('External System Data')}:</td>
            <td>{queryEvent.data.externalAnimalEvent?.externalId}</td>
          </tr>
        )}
        {queryEvent.data.hipHeight && (
          <tr>
            <td>{t('Hip Height')}:</td>
            <td>
              <HeightUnit toFixed={0} value={queryEvent.data.hipHeight} />
            </td>
          </tr>
        )}
        {queryEvent.data.lactationNumber && (
          <tr>
            <td>{t('Lactation Number')}:</td>
            <td>{queryEvent.data.lactationNumber}</td>
          </tr>
        )}
        {queryEvent.data.notes && (
          <tr>
            <td>{t('Notes')}:</td>
            <td>{queryEvent.data.notes}</td>
          </tr>
        )}
        {!queryEvent.data.productId && queryProduct.data && queryProduct.data.length > 0 && (
          <tr>
            <td>{t('Product')}:</td>
            <td>
              <Link to={`/products/${queryProduct.data[0].id}`}>{queryProduct.data[0].name}</Link>
            </td>
          </tr>
        )}
        {queryEvent.data.penId && (
          <tr>
            <td>{t('Pen')}:</td>
            <td>
              <Link to={`/pens/${queryEvent.data?.penId}`}>
                <IconPen className={'me-1'} />
                {queryEvent.data.pen?.name}
              </Link>
            </td>
          </tr>
        )}
        {queryEvent.data.productId && queryEvent.data.product && (
          <tr>
            <td>{t('Product')}:</td>
            <td>
              <Link to={`/products/${queryEvent.data?.productId}`}>
                {queryEvent.data.product.name}
              </Link>
            </td>
          </tr>
        )}
        {queryEvent.data.productDosage && (
          <tr>
            <td>{t('Dosage (mL)')}:</td>
            <td>{queryEvent.data.productDosage}</td>
          </tr>
        )}
        {queryEvent.data.productRoute && (
          <tr>
            <td>{t('Dosage route')}:</td>
            <td>{queryEvent.data.productRoute}</td>
          </tr>
        )}
        {queryEvent.data.productLocation && (
          <tr>
            <td>{t('Dosage location')}:</td>
            <td>{queryEvent.data.productLocation}</td>
          </tr>
        )}
        {queryEvent.data.sire && (
          <tr>
            <td>{t('Sire')}:</td>
            <td>
              <Link to={`/sires/${queryEvent.data.sire?.id}`}>
                <IconTag className={'me-1'} />
                {queryEvent.data.sire?.name}
              </Link>
            </td>
          </tr>
        )}
        {queryEvent.data.temperature && (
          <tr>
            <td>{t('Temperature')}:</td>
            <td>
              <TemperatureUnit value={queryEvent.data.temperature} />
            </td>
          </tr>
        )}
        {queryEvent.data.userId && queryEvent.data.user && (
          <tr>
            <td>{t('User')}:</td>
            <td>
              <Link to={`/users/${queryEvent.data.user?.id}`}>
                <IconUsers className={'me-1'} />
                {`${queryEvent.data.user.firstName} ${queryEvent.data.user.lastName}`}
              </Link>
            </td>
          </tr>
        )}
        {queryEvent.data.weight && (
          <tr>
            <td>{t('Weight')}:</td>
            <td>
              <WeightUnit value={queryEvent.data.weight} />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );

  return (
    <Modal
      fullscreen={'md-down'}
      onClose={props.onClose}
      size={'xl'}
      title={'Event Detail'}
      visible={true}
    >
      {queryEvent.isLoading ? placeholderEvent : event}
    </Modal>
  );
};

export default EventDetailModal;
