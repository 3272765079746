// Copyright © 2024 CATTLEytics Inc.

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import AnimalTableImage from '../animals/components/AnimalTableImage';
import AlertError from '../common/components/AlertError';
import Button, { ButtonVariant } from '../common/components/Button';
import DataTable, { DataTableHeader, DataTableRow } from '../common/components/DataTable';
import TablePlaceholder from '../common/components/TablePlaceholder';
import { Sort } from '../common/enums';
import { api, IconAddCircle, IconDelete, IconTag } from '../common/utilities';
import { Animal, AnimalGroup, ApiResourceV1, HttpMethod, QueryKey } from '../shared';

interface Props {
  animalGroupId: number;
  disableSearch?: boolean;
  disableSort?: boolean;
  hideActions?: boolean;
  notInThisGroup?: boolean;
}

export const AnimalGroupsAnimalTable = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  const [limit, setLimit] = useState<number>(25);
  const [offset, setOffset] = useState<number>(0);
  const [sortField, setSortField] = useState<string>('primaryTag');
  const [sortDirection, setSortDirection] = useState<Sort>(Sort.Ascending);
  const [search, setSearch] = useState<string>('');

  const notInThisGroup = !!props.notInThisGroup;

  const query = useQuery<Animal[]>(
    [
      QueryKey.AnimalGroups,
      props.animalGroupId,
      'animals',
      notInThisGroup,
      limit,
      offset,
      sortField,
      sortDirection,
      search,
    ],
    () =>
      api<Animal[]>(
        HttpMethod.Get,
        `${ApiResourceV1.AnimalGroups}/${props.animalGroupId}/animals`,
        {
          params: {
            limit: String(limit),
            search: String(search),
            notInThisGroup: String(notInThisGroup),
            offset: String(offset),
            sortField: sortField,
            sortDirection: String(sortDirection),
          },
        },
      ),
    { keepPreviousData: false },
  );

  const mutationSuccess = async (): Promise<void> =>
    await queryClient.invalidateQueries([QueryKey.AnimalGroups, props.animalGroupId, 'animals']);

  const mutationAdd = useMutation<unknown, unknown, number[]>(
    (ids) => {
      return api<AnimalGroup>(
        HttpMethod.Post,
        `${ApiResourceV1.AnimalGroups}/${props.animalGroupId}/animals`,
        { body: { ids } },
      );
    },
    {
      onSuccess: mutationSuccess,
    },
  );

  const mutationRemove = useMutation<unknown, unknown, number>(
    (id) => {
      return api<null>(
        HttpMethod.Delete,
        `${ApiResourceV1.AnimalGroups}/${props.animalGroupId}/animals/${id}`,
      );
    },
    {
      onSuccess: mutationSuccess,
    },
  );

  const queryClient = useQueryClient();

  const removeAnimal = async (id: number): Promise<void> => {
    try {
      await mutationRemove.mutateAsync(id);
    } catch (err) {
      console.error(err);
    }
  };
  const addAnimal = async (id: number): Promise<void> => {
    try {
      await mutationAdd.mutateAsync([id]);
    } catch (err) {
      console.error(err);
    }
  };

  if (query.isLoading) {
    return <TablePlaceholder />;
  }

  if (query.isError) {
    return <AlertError message={t('common|animalRetrievalError')} />;
  }

  const headers: DataTableHeader[] = [
    {
      name: 'image',
      hideLabel: false,
      label: t('animalGroupsAnimalTable|imageLabel'),
      sortable: !props.disableSort,
    },
    {
      name: 'primaryTag',
      label: t('animalGroupsAnimalTable|primaryTagLabel'),
      infoTooltip: t('animalGroupsAnimalTable|primaryTagInfoTooltip'),
      classNameMobile: 'col-6',
      sortable: !props.disableSort,
    },
    {
      name: 'name',
      label: t('animalGroupsAnimalTable|nameLabel'),
      infoTooltip: t('animalGroupsAnimalTable|nameInfoTooltip'),
      classNameMobile: 'col-6',
      sortable: !props.disableSort,
    },

    {
      name: 'pen',
      label: t('animalGroupsAnimalTable|penLabel'),
      infoTooltip: t('animalGroupsAnimalTable|animalCurrentPenTooltip'),
      hideMobile: true,
      sortable: !props.disableSort,
    },
  ];

  if (!props.hideActions) {
    headers.push({
      name: 'actions',
      sortable: false,
      label: t('animalGroupsAnimalTable|actionsLabel'),
      hideMobile: true,
    });
  }

  const data: DataTableRow[] = !query.data
    ? []
    : query.data.map((animal) => {
        return {
          id: String(animal.id),
          image: <AnimalTableImage animal={animal} />,
          primaryTag: animal.primaryTag ? (
            <>
              <IconTag className={'me-1'} style={{ fontSize: '12px' }} />
              {animal.primaryTag}
            </>
          ) : (
            '-'
          ),
          name: animal.name,
          pen: animal.pen,
          actions: (
            <div onClick={(e): void => e.stopPropagation()}>
              {notInThisGroup && (
                <Button
                  onClick={(): Promise<void> => addAnimal(animal.id)}
                  size={'sm'}
                  variant={ButtonVariant.OutlinePrimary}
                >
                  <IconAddCircle className={'me-1'} />
                  {t('animalGroupsAnimalTable|addButtonLabel')}
                </Button>
              )}
              {!notInThisGroup && (
                <Button
                  onClick={(): Promise<void> => removeAnimal(animal.id)}
                  size={'sm'}
                  variant={ButtonVariant.OutlinePrimary}
                >
                  <IconDelete className={'me-1'} />
                  {t('animalGroupsAnimalTable|removeButtonLabel')}
                </Button>
              )}
            </div>
          ),
        };
      });

  return (
    <DataTable
      className={'mt-3'}
      data={data}
      headers={headers}
      isLoading={query.isLoading}
      isPreviousData={query.isFetching}
      limit={limit}
      messageNoData={t('animalGroupsAnimalTable|noDataMessage')}
      offset={offset}
      onLimitChange={(newLimit): void => setLimit(newLimit)}
      onOffsetChange={(newOffset): void => setOffset(newOffset)}
      onRowClick={(row): void => history.push(`/animals/${row.id}`)}
      onSearchChange={(query): void => setSearch(query)}
      onSortDirectionChange={(newSortDirection): void => setSortDirection(newSortDirection)}
      onSortFieldChange={(newSortField): void => setSortField(newSortField)}
      search={search}
      showSearch={!props.disableSearch}
      sortDirection={sortDirection}
      sortField={sortField}
    />
  );
};
