// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useContext, useState } from 'react';
import { PlaceholderButton, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { TYPES } from '../../../types';
import ButtonCreate from '../../common/components/ButtonCreate';
import Page from '../../common/components/Page';
import Product from '../../common/entities/product';
import ProductService from '../../common/services/productService';
import AuthContext from '../../common/store/auth-context';
import { isSuperAdmin } from '../../common/utilities';
import { QueryKey } from '../../shared';
import ProductModal from './ProductModal';
import ProductsTable from './ProductsTable';

interface RouteParams {
  /**
   * Tab key to make active on render.
   */
  tabKey: string;
}

const ProductsPage = (): JSX.Element => {
  const { t } = useTranslation();

  const productService = useInjection<ProductService>(TYPES.productService);

  const { tabKey } = useParams<RouteParams>();
  const history = useHistory();

  const auth = useContext(AuthContext);

  const [productModalVisible, setProductModalVisible] = useState<boolean>(false);

  // @TODO what is this for?
  const query = useQuery<Product[]>([QueryKey.Products, 'list'], () => productService.list(), {
    refetchInterval: 300000,
    refetchOnWindowFocus: true,
  });

  return (
    <Page
      breadcrumbs={[{ label: 'Lists', to: '/lists' }]}
      buttons={
        query.isLoading ? (
          <PlaceholderButton style={{ width: '8rem' }} xs={2} />
        ) : (
          isSuperAdmin(auth) && (
            <ButtonCreate
              label={t('productsPage|addProductButtonLabel')}
              onClick={(): void => {
                setProductModalVisible(true);
              }}
            />
          )
        )
      }
      title={'Products'}
    >
      <Tabs
        activeKey={tabKey}
        className="mb-3"
        id="products-tabs"
        mountOnEnter={true}
        onSelect={(k): void => {
          if (k) {
            history.push(`/lists/products/${k}`);
          }
        }}
      >
        <Tab eventKey="active" title="Active">
          <ProductsTable showOnlyHidden={false} />
        </Tab>
        <Tab eventKey="hidden" title="Hidden">
          <ProductsTable showOnlyHidden={true} />
        </Tab>
      </Tabs>
      {productModalVisible && <ProductModal onClose={(): void => setProductModalVisible(false)} />}
    </Page>
  );
};

export default ProductsPage;
