// Copyright © 2023 CATTLEytics Inc.

/**
 * WARNING!
 *
 * DO NOT EDIT THIS FILE
 *
 * CHANGES WILL BE OVERWRITTEN!
 *
 * Make changes in packages/shared and run npm run copy-shared-to-packages
 */

export interface MentionData {
  id: string;
  name: string;
  original: string;
  trigger: string;
}

/**
 * RegEx grouped results. Example - "@[Full Name](123abc)"
 * We have 4 groups here:
 * - The whole original string - "@[Full Name](123abc)"
 * - Mention trigger - "@"
 * - Name - "Full Name"
 * - Id - "123abc"
 */
export const mentionRegEx = /((.)\[([^[]*)]\(([^(^)]*)\))/gi;

export const getIdsFromTextWithMentions = (textWithMentions: string): number[] => {
  if (!textWithMentions) {
    return [];
  }
  const parts = [...textWithMentions.matchAll(mentionRegEx)];
  return parts.map((match) => Number(match[4]));
};

export const replaceMentionValues = (
  value: string,
  replacer: (mention: MentionData) => string,
): string =>
  value.replace(mentionRegEx, (fullMatch, original, trigger, name, id) =>
    replacer({
      original,
      trigger,
      name,
      id,
    }),
  );
