// Copyright © 2024 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React from 'react';
import { Badge } from 'react-bootstrap';
import { useTranslation, withTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { TYPES } from '../../../types';
import Modal from '../../common/components/Modal';
import NoteService from '../../common/services/noteService';
import { formatDate } from '../../common/utilities';
import { Note, QueryKey } from '../../shared';

interface Props {
  noteId: number;
  onClose: () => void;
}

const NoteViewerModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const noteService = useInjection<NoteService>(TYPES.noteService);

  const query = useQuery<Note | undefined>([QueryKey.Notes], () => noteService.get(props.noteId), {
    onSuccess: () => '',
    onError: () => '',
  });

  const note = query.data;

  const body =
    note &&
    note.body &&
    note.body.split('\n').map((e) => {
      return (
        <>
          {e}
          <br />
        </>
      );
    });

  const badges = (
    <div className="mt-4">
      {note &&
        note.tags &&
        note.tags.slice(0, 2).map((tag) => (
          <Badge bg={'primary'} className={'me-3'} key={tag} pill>
            {tag}
          </Badge>
        ))}
      {note && note.animalId && (
        <Badge bg={'success'} className={'me-3'} pill>
          {t('Animal')}
        </Badge>
      )}
      {note && note.penId && (
        <Badge bg={'secondary'} className={'me-3'} pill>
          {t('Pen')}
        </Badge>
      )}
    </div>
  );

  const createdDate = (
    <div className="col-6">
      <small>
        <strong>{t('noteViewerModal|createdLabel')}</strong>:{' '}
        {formatDate(note?.createdDate) || 'unknown'}
      </small>
    </div>
  );

  const modifiedDate = (
    <div className="col-6">
      <small>
        <strong>{t('noteViewerModal|modifiedLabel')}</strong>:{' '}
        {formatDate(note?.modifiedDate) || 'unknown'}
      </small>
    </div>
  );

  return (
    <Modal onClose={props.onClose} size="lg" title={t('noteViewerModal|modalTitle')} visible={true}>
      {body}
      {badges}
      <hr />
      <div className="row">
        {createdDate}
        {modifiedDate}
      </div>
    </Modal>
  );
};

export default withTranslation()(NoteViewerModal);
