// Copyright © 2023 CATTLEytics Inc.

import React, { ChangeEvent, PropsWithChildren, useEffect, useState } from 'react';
import { Form, FormGroup, Placeholder, PlaceholderButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import { ButtonVariant } from '../../common/components/Button';
import ButtonModalCancel from '../../common/components/ButtonModalCancel';
import ButtonModalDelete from '../../common/components/ButtonModalDelete';
import ButtonModalSave from '../../common/components/ButtonModalSave';
import ConfirmModal from '../../common/components/ConfirmModal';
import Modal from '../../common/components/Modal';
import Required from '../../common/components/Required';
import { api, capitalizeFirstLetter } from '../../common/utilities';
import { ApiResourceV1, HttpMethod } from '../../shared';

/**
 * Component's input properties.
 */
interface Props {
  /**
   * API resource to handle the CRUD actions for this list
   */
  apiResource: ApiResourceV1;

  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Identifier of the item we want to edit
   */
  editId?: number;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;

  /**
   * Base key to help determine when a query should be re-fetched
   */
  queryKey: string;

  /**
   * Short entity label like reproduction status (suitable for titles)
   */
  typeLabel?: string;

  /**
   * Short entity name like breed
   */
  typeName: string;
}

/**
 * General modal to add/edit a list type item.
 */
const ManageListEditModal = (props: PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState<boolean>(false);
  const [deleteConfirmModalErrorMessage, setDeleteConfirmModalErrorMessage] = useState<string>();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [validated, setValidated] = useState<boolean>(false);
  const [data, setData] = useState<Record<string, string>>({ name: '', description: '' });

  const query = useQuery(
    [props.queryKey, props.editId],
    () => api(HttpMethod.Get, `${props.apiResource}/${props.editId}`),
    {
      keepPreviousData: true,
      enabled: !!props.editId,
      onSuccess: (data) => setData(data as Record<string, string>),
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  const mutation = useMutation(
    () => {
      if (!data) {
        throw Error('no data to save');
      }
      if (props.editId) {
        return api(HttpMethod.Patch, `${props.apiResource}/${data.id}`, { body: data });
      } else {
        return api(HttpMethod.Post, props.apiResource, { body: data });
      }
    },
    {
      onSuccess: async () => {
        // Invalidate to trigger re-fetch
        await queryClient.invalidateQueries(props.queryKey);
      },
    },
  );

  const showDeleteConfirmModal = (id: number): void => {
    setDeleteConfirmModalVisible(true);
    setDeleteId(id);
  };

  const deleteItem = async (): Promise<void> => {
    if (!deleteId) {
      return;
    }
    setIsDeleting(true);
    try {
      await api(HttpMethod.Delete, `${props.apiResource}/${deleteId}`);
      await queryClient.invalidateQueries(props.queryKey);
      setDeleteConfirmModalVisible(false);
    } catch (err) {
      console.error('This item failed to be deleted', err);
      setDeleteConfirmModalErrorMessage('This item could not be deleted.');
    }
    setIsDeleting(false);
  };

  useEffect(() => {
    const invalidElements = document.querySelectorAll('input.form-control:invalid');
    if (invalidElements.length > 0) {
      invalidElements[0].closest('.form-group')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [validated]);

  const typeLabel = props.typeLabel ?? props.typeName;

  const queryClient = useQueryClient();

  const onHandleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      await mutation.mutateAsync();

      props.onClose();
    } catch (err) {
      console.error(`${typeLabel} could not be saved.`, err, data);
      setErrorMessage(
        t(`{{typeLabel}} could not be saved.`, {
          typeLabel: capitalizeFirstLetter(typeLabel),
        }),
      );
    }
  };

  const placeholder = (
    <Placeholder animation={'glow'}>
      <Placeholder xs={6} />
      <Placeholder className={'mb-5'} size={'lg'} xs={12} />
      <Placeholder xs={6} />
      <Placeholder className={'mb-5'} size={'lg'} xs={12} />
    </Placeholder>
  );

  const form = (
    <Form noValidate onSubmit={handleFormSubmit} validated={validated}>
      <FormGroup className="form-group mb-3" controlId="formName">
        <Form.Label>
          {t('manageListEditModal|nameFieldLabel')} <Required />
        </Form.Label>
        <Form.Control
          name={'name'}
          onChange={onHandleInputChange}
          required
          type={'text'}
          value={data.name}
        />
        <Form.Text className={'text-muted'}>
          {t('manageListEditModal|nameFieldHint', { typeLabel: typeLabel })}
        </Form.Text>
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </FormGroup>
      <FormGroup className="form-group mb-3" controlId="formDescription">
        <Form.Label>{t('manageListEditModal|descriptionFieldLabel')}</Form.Label>
        <Form.Control
          as={'textarea'}
          name={'description'}
          onChange={onHandleInputChange}
          rows={4}
          value={data.description ?? ''}
        />
        <Form.Text className={'text-muted'}>
          {t('manageListEditModal|descriptionFieldHint', {
            typeLabel: typeLabel,
          })}
        </Form.Text>
      </FormGroup>
      <AlertErrorForModal message={errorMessage} />
      <div className="modal-footer modal-footer-in-form">
        <ButtonModalCancel
          disabled={mutation.isLoading || query.isLoading}
          onClick={props.onClose}
        />
        {props.editId && (
          <ButtonModalDelete
            onClick={(): void => {
              if (props.editId) {
                showDeleteConfirmModal(props.editId);
              }
            }}
          />
        )}
        <ButtonModalSave
          busy={mutation.isLoading}
          disabled={mutation.isLoading || query.isLoading}
        />
      </div>
    </Form>
  );

  return (
    <Modal
      fullscreen={'md-down'}
      onClose={props.onClose}
      size={'lg'}
      title={`${props.editId ? t('Edit') : t('common|add')} ${typeLabel}`}
      visible={true}
    >
      {query.isError ? (
        <AlertErrorForModal
          message={t(`common|unexpectedRetrievalError`, {
            value: typeLabel,
          })}
        />
      ) : null}
      {query.isFetching ? placeholder : form}
      {query.isFetching && (
        <div className="modal-footer modal-footer-in-form">
          <PlaceholderButton variant={'secondary'} xs={2} />
          <PlaceholderButton xs={2} />
        </div>
      )}
      <ConfirmModal
        busy={isDeleting}
        cancelLabel={t('Cancel')}
        cancelOnClick={(): void => setDeleteConfirmModalVisible(false)}
        errorMessage={deleteConfirmModalErrorMessage}
        okLabel={t(`Yes, delete this {{value}}`, { value: t(typeLabel) })}
        okOnClick={deleteItem}
        okVariant={ButtonVariant.Danger}
        title={t(`Delete this {{value}}`, { value: t(typeLabel) })}
        visible={deleteConfirmModalVisible}
      >
        {t('Are you sure you want to delete this {{value}}?', { value: t(typeLabel) })}
      </ConfirmModal>
    </Modal>
  );
};

export default ManageListEditModal;
