// Copyright © 2024 CATTLEytics Inc.

import React, { useContext } from 'react';

export interface ScheduleContextProps {
  featureFlags: {
    allowSwapping: boolean;
    requestApproval: boolean;
  };
}

export const ScheduleContext = React.createContext<ScheduleContextProps>({
  featureFlags: {
    allowSwapping: false,
    requestApproval: false,
  },
});

export function useSettingsScheduleContext(): ScheduleContextProps {
  return useContext(ScheduleContext);
}
