// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from '../../common/components/Button';
import Page from '../../common/components/Page';

/**
 * Routable component for not found page
 */
const notFoundPage = (): JSX.Element => {
  return (
    <Page title="Page not found">
      <p>Oops, it looks like the page you are looking for is not available.</p>
      <p>
        <Link to={'/dashboard'}>
          <Button variant={'outline-primary'}>Dashboard</Button>
        </Link>
      </p>
    </Page>
  );
};

export default withTranslation()(notFoundPage);
