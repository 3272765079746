// Copyright © 2023 CATTLEytics Inc.

import { Animal } from '../../shared';
import { DairyLogEventType, DairyLogEventTypeIdMap } from '../enums/dairyLogEventType';
import Entity from './entity';
import Pen from './pen';

/**
 * Represents a DairyLog entity.
 */
export default interface DairyLog extends Entity {
  // Related Animal.
  animal?: Animal;

  // Related animal identifier.
  animalId?: number;

  // Date of the log.
  date: Date;

  /// Brief description of this log event.
  description: string;

  /// Follow up information.
  followUp?: string;

  // Notes related to this dairy log.
  notes?: string;

  // Name of DairyLog.
  pen?: Pen;

  // Identifier of the related pen.
  penId?: number;
}

/**
 * Returns an DairyLog with all required fields set.
 */
export function dairyLogDefault(): DairyLog {
  return {
    id: 0,
    date: new Date(),
    description: '',
    eventType: DairyLogEventTypeIdMap[DairyLogEventType.Other],
    createdDate: new Date(),
    modifiedDate: new Date(),
  };
}
