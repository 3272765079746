// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { TYPES } from '../../../types';
import NoteService from '../../common/services/noteService';
import { QueryKey } from '../../shared';

/**
 * Component properties
 */
interface Props {
  /**
   * Loading indicator.
   */
  busy?: boolean;

  /**
   * HTML ID attribute
   */
  id: string;

  /**
   * Whether to allow multiple pen selection.
   *
   * Note: this also changes the parameters sent in the onSelect callback from
   * single item to array of items.
   */
  multiple?: boolean;

  /**
   * HTML name attribute
   */
  name?: string;

  /**
   * Callback after tag has been selected
   * @param tag
   */
  onSelect: (tag?: string | string[]) => void;

  /**
   * Set the typeahead placeholder text.
   */
  placeholder?: string;

  /**
   * If this field is required.
   */
  required?: boolean;

  /**
   * Initial selected option or options.
   */
  selected?: Option | Option[];

  /**
   * Whether the form this component is in has been validated.
   * This will show invalid messages if applicable.
   * Note this does not indicate a form is valid.
   */
  validated?: boolean;
}

/**
 * Provides ability to select an animal by name or ID.
 */
const NoteTagsAutocomplete = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const noteService = useInjection<NoteService>(TYPES.noteService);

  const [searchQuery, setSearchQuery] = useState<string>(
    props.selected ? String(props.selected) : '',
  );

  const limit = 10;

  const { data: tags, isLoading: busy } = useQuery<string[]>(
    [QueryKey.Notes, 'tags', searchQuery, limit],
    () =>
      noteService.listTags({
        search: searchQuery,
        limit: String(limit),
      }),
    {
      //enabled: !!searchQuery,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  // if selected property is not set then empty array,
  // else if selected property is an array use it,
  // else make selected property an array
  const selected = !props.selected
    ? []
    : Array.isArray(props.selected)
    ? props.selected
    : [props.selected];

  const required = props.required ?? false;

  // if the validated property is set and true (form validation was run),
  // and the field is required and the selection is empty
  const isInvalid = !!props.validated && required && selected.length === 0;

  // if the validated property is set and true (form validation was run),
  // and the field is not isInvalid
  const isValid = !!props.validated && !isInvalid;

  // The following input element is a way to trigger a javascript invalid
  // state so HTML5 form validation can be used since the input element
  // inside the typeahead component does not support this
  // if one or more items have been selected this input element will be
  // set to 1 thus satisfying the required validator
  const inputHelper = (
    <input
      aria-hidden={true}
      readOnly={true}
      required={required}
      style={{ display: 'none' }}
      tabIndex={-1}
      type={'text'}
      value={selected.length === 0 ? '' : '1'}
    />
  );

  return (
    <div className={`note-tag-autocomplete ${isInvalid ? 'is-invalid' : 'is-valid'}`}>
      {inputHelper}
      <AsyncTypeahead
        allowNew={true}
        clearButton={true} // for styling only
        id={props.id}
        inputProps={{
          required: isInvalid,
          onInput: (event: React.FormEvent<HTMLInputElement>): void => {
            const target = event.target as HTMLInputElement;
            // Update the input's tag value to be lowercase
            if (target?.value) {
              target.value = target.value.toLowerCase();
            }
          },
        }}
        isInvalid={isInvalid}
        isLoading={busy || !!props.busy}
        isValid={isValid}
        // labelKey={(option): string => {
        //   // const animal = option as Animal;
        //   // return nameTag(animal.primaryTag, animal.name);
        //   return option;
        // }}
        minLength={0}
        multiple={props.multiple}
        onChange={(selected: Option[]): void => {
          if (props.multiple) {
            const tags =
              selected.length > 0
                ? selected.map((tag) => (typeof tag === 'string' ? tag : (tag.label as string)))
                : undefined;
            props.onSelect(tags);
          } else {
            props.onSelect(selected && selected.length > 0 ? (selected[0] as string) : undefined);
          }
        }}
        onSearch={(searchQuery: string): void => {
          setSearchQuery(searchQuery);
        }}
        options={tags ?? []}
        placeholder={props.placeholder ?? t('Select one or more tags')}
        selected={selected}
      />
      <Form.Control.Feedback type={'invalid'}>
        {t('common|fieldRequiredFeedback')}
      </Form.Control.Feedback>
    </div>
  );
};

export default NoteTagsAutocomplete;
