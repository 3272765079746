// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import DataCell from '../../common/components/DataCell';
import SireService from '../../common/services/sireService';
import { QueryKey } from '../../shared';
import CardStatsPlaceholder from './CardStatsPlaceholder';

interface Props {
  sireId: number;
}

/**
 * Sire stats detail.
 */
const SireStats = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const sireService = useInjection<SireService>(TYPES.sireService);
  const sireId = props.sireId;

  const query = useQuery([QueryKey.Sires, 'get', sireId], () => sireService.get(sireId));

  const sire = query.data;
  if (!sire && query.isLoading) {
    return <CardStatsPlaceholder />;
  }
  if (!sire) {
    return <AlertError message={t('sireStats|noDataError')} />;
  }
  return (
    <Row>
      <Col md={4} xs={6}>
        <DataCell label={t('Name')} value={sire?.name} />
      </Col>
      <Col md={4} xs={6}>
        <DataCell label={t('National ID')} value={sire?.nationalId} />
      </Col>
      <Col md={4} xs={6}>
        <DataCell label={t('Owning Entity')} value={sire?.owningEntity} />
      </Col>
      <Col md={4} xs={6}>
        <DataCell label={t('Semen Code')} value={sire?.semenCode} />
      </Col>
      <Col md={4} xs={6}>
        <DataCell label={t('sireStats|aiCompanyLabel')} value={sire?.aiCompany} />
      </Col>
    </Row>
  );
};

export default SireStats;
