// Copyright © 2023 CATTLEytics Inc.
import { FC } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import Avatar from '../../common/components/Avatar';
import { Site } from '../../shared';

type Props = WithTranslation & {
  site?: Site;
};

const WhiteboardHeader: FC<Props> = ({ site }) => {
  return (
    <div className={'d-flex'}>
      {site?.iconUrlSigned ? (
        <img
          alt={site?.name}
          className={'rounded-circle'}
          src={site?.iconUrlSigned}
          style={{ height: '60px' }}
        />
      ) : (
        <Avatar backgroundColor={'var(--bs-primary)'} size={'48px'} text={site?.name ?? ''} />
      )}

      {site?.logoUrlSigned ? (
        <img
          alt={site?.name}
          className={'ms-2'}
          src={site?.logoUrlSigned}
          style={{ height: '60px' }}
        />
      ) : (
        <h1
          className="ms-2"
          style={{
            fontFamily: 'Lora, sans-serif',
            textTransform: 'none',
            fontWeight: 'normal',
          }}
        >
          <span>{site?.name}</span>
        </h1>
      )}
    </div>
  );
};

export default withTranslation()(WhiteboardHeader);
