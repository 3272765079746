// Copyright © 2023 CATTLEytics Inc.

/**
 * WARNING!
 *
 * DO NOT EDIT THIS FILE
 *
 * CHANGES WILL BE OVERWRITTEN!
 *
 * Make changes in packages/shared and run npm run copy-shared-to-packages
 */

import { Animal } from '../entities';

export interface AgeClass {
  code: string;
  description?: string;
}

export const buildAgeClassCode = (animal: Animal): AgeClass => {
  if (animal.isBullCalf) {
    return { code: 'BC', description: 'Bull Calf' };
  } else if (animal.isHeiferCalf) {
    return { code: 'HC', description: 'Heifer Calf' };
  } else if (animal.isHeifer) {
    return { code: 'H', description: 'Heifer' };
  } else if (animal.isCow) {
    return { code: 'C', description: 'Cow' };
  } else {
    return { code: '-', description: 'Unknown' };
  }
};
