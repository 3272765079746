// Copyright © 2023 CATTLEytics Inc.
import './Timeline.scss';

import React from 'react';

import HorizontalTimeline from './HorizontalTimeline';
import { TimelineProvider, TimelineProviderProps } from './TimelineProvider';
import { EntityBase } from './types';
import VerticalTimeline from './VerticalTimeline';

type Props<Entity extends EntityBase> = TimelineProviderProps<Entity>;

function Timeline<Entity extends EntityBase>({
  direction = 'vertical',
  ...props
}: Props<Entity>): JSX.Element {
  return (
    <TimelineProvider direction={direction} {...props}>
      {direction === 'vertical' ? <VerticalTimeline /> : <HorizontalTimeline />}
    </TimelineProvider>
  );
}

export default Timeline;
