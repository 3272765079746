// Copyright © 2023 CATTLEytics Inc.

import { inject, injectable } from 'inversify';

import { TYPES } from '../../../types';
import Product from '../../common/entities/product';
import type Logger from '../../logger/logger';
import Api2Service from './api2Service';
import DataService from './dataService';

const path = '/v1/products';

/**
 * @see Product
 */
export interface PayloadPost {
  description: string;
  imageUrls?: string[];
  instructions?: string;
  meatWithdrawalDays?: number;
  milkWithdrawalDays?: number;
  name: string;
  productClassId: number;
  productTypeId: number;
  websiteUrl: string;
}
export interface PayloadPatch extends PayloadPost {
  hidden?: boolean;
  id: number;
}

/**
 * Service for managing animal products.
 */
@injectable()
export default class ProductService implements DataService<Product> {
  private logger: Logger;

  private apiService: Api2Service;

  /**
   * Creates an instance of the product service.
   *
   * @param logger Logger instance.
   * @param apiService API Service instance
   */
  constructor(
    @inject(TYPES.logger) logger: Logger,
    @inject(TYPES.api2Service) apiService: Api2Service,
  ) {
    this.logger = logger;
    this.apiService = apiService;
  }

  /**
   * Retrieve a list of products
   * @return product objects
   */
  async list(params?: Record<string, string>): Promise<Product[]> {
    const result = await this.apiService.get<Product[]>(path, params);
    if (!result) {
      return [];
    }

    const data = result as Product[];
    data.forEach((item) => this.cast(item));
    return data;
  }

  async get(id: number, params?: Record<string, string>): Promise<Product | undefined> {
    const result = await this.apiService.get<Product>(`${path}/${id}`, params);
    if (!result) {
      return undefined;
    }

    const data = result as Product;
    this.cast(data);
    return data;
  }

  async post(attributes: PayloadPost): Promise<Product | undefined> {
    const result = await this.apiService.post<Product>(`${path}`, attributes);
    if (!result) {
      return undefined;
    }
    this.cast(result as Product);
    return result;
  }

  async patch(id: number, attributes: Partial<PayloadPatch>): Promise<Product | undefined> {
    // make sure record identifier is included
    attributes.id = id;

    const result = await this.apiService.patch(`${path}/${id}`, attributes);
    if (!result) {
      return undefined;
    }

    const data = result as Product;
    this.cast(data);
    return data;
  }

  async delete(id: number): Promise<null> {
    return await this.apiService.delete(`${path}/${id}`);
  }

  /**
   * Casts fields into javascript types.
   * @param item
   * @return cast object
   */
  cast(item: Product): void {
    if (!item) {
      return;
    }
  }
}
