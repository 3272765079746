// Copyright © 2023 CATTLEytics Inc.
import { FC } from 'react';
import { Form, FormGroupProps, FormLabelProps } from 'react-bootstrap';
import { FeedbackProps } from 'react-bootstrap/esm/Feedback';

type Props = FormGroupProps & {
  InvalidFeedbackProps?: FeedbackProps;
  LabelProps?: FormLabelProps;
  invalidFeedback?: string;
  label?: string;
};

const FormFieldWrapper: FC<Props> = ({
  children,
  InvalidFeedbackProps,
  LabelProps,
  invalidFeedback,
  label,
  className,
  ...props
}) => {
  return (
    <Form.Group className={`form-group mb-3 ${className ?? ''}`} {...props}>
      {label && (
        <Form.Label className={`text-nowrap ${LabelProps?.className ?? ''}`} {...LabelProps}>
          {label}
        </Form.Label>
      )}
      {children}
      {invalidFeedback && (
        <Form.Control.Feedback {...InvalidFeedbackProps} type="invalid">
          {invalidFeedback}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default FormFieldWrapper;
