// Copyright © 2023 CATTLEytics Inc.

import React, { ChangeEvent, useState } from 'react';
import { Form, InputGroup, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Button from '../../common/components/Button';
import { ApiResourceV1, HttpMethod, Name } from '../../shared';
import { api } from '../utilities';
import InfoTooltip from './InfoTooltip';

/**
 * Component properties
 */
interface Props {
  // Loading indicator
  busy?: boolean;

  // Additional classes to add to this component.
  className?: string;

  gender: string;

  // HTML element ID
  id?: string;

  // HTML input name attribute
  name?: string;

  // Callback when name is changed
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;

  // Callback when name is generated
  onRandomName: (name: string) => void;

  // If this field is required.
  required?: boolean;

  value: string;
}

/**
 * Populates a text box with a random unique name.
 */
const RandomName = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [busy, setBusy] = useState<boolean>(false);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);

  const name = props.value;

  const getRandomName = async (): Promise<void> => {
    setBusy(true);
    setIsInvalid(false);
    try {
      const randomNames = await api<Name>(HttpMethod.Get, ApiResourceV1.Names, {
        params: { gender: 'f' },
      });
      props.onRandomName(randomNames[0].name);
    } catch (err) {
      setErrorMessage((err as Error).message);
      setIsInvalid(true);
    }
    setBusy(false);
  };

  return (
    <Form.Group className={props.className} controlId={props.name}>
      <Form.Label>
        {t('randomName|nameLabel')} <InfoTooltip tooltip={t('randomName|nameInfoTooltip')} />
      </Form.Label>

      <InputGroup>
        <Form.Control
          isInvalid={isInvalid}
          name={props.name}
          onChange={props.onChange}
          placeholder={t('randomName|placeholder')}
          required={props.required}
          type="text"
          value={name}
        />

        <Button disabled={busy} onClick={getRandomName} variant="outline-primary">
          {busy ? (
            <>
              <Spinner animation="border" aria-hidden="true" as="span" role="status" size="sm" />
              <span className="visually-hidden">{t('common|loading')}</span>
            </>
          ) : (
            <span>{t('Random Name')}</span>
          )}
        </Button>
      </InputGroup>

      {props.children}
      {isInvalid && <div className={'invalid-feedback d-block'}>{errorMessage}</div>}
      <Form.Text muted>{t('randomName|nameInstructions')}</Form.Text>
    </Form.Group>
  );
};

export default RandomName;
