// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { getEnv } from '../utilities';

/**
 * Defines the input properties for the component.
 */
interface Props extends WithTranslation {
  /**
   * CSS font size for the badge
   */
  fontSize?: string;
}

/**
 * A badge for indicating the current environment over the logo
 */
const LogoEnvironmentBadge = (props: Props): JSX.Element => {
  const env = getEnv('ENV') ?? '';

  if (env === 'production') {
    return <></>;
  }

  const badge = ((): { color: string; label: string } | undefined => {
    switch (env) {
      case 'development':
        if (window.location.port) {
          return { label: 'Local', color: 'var(--bs-secondary)' };
        } else {
          return { label: 'Dev', color: 'var(--bs-info)' };
        }
      case 'staging':
        return { label: 'Staging', color: 'var(--bs-danger)' };
    }
  })();

  if (!badge) {
    return <></>;
  }

  return (
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        right: '-0.5rem',
        textTransform: 'uppercase',
        backgroundColor: badge.color,
        padding: '0 5px',
        fontSize: props.fontSize ?? '10px',
        fontWeight: 'bold',
        color: 'white',
        borderRadius: '6px',
      }}
    >
      {badge.label}
    </div>
  );
};

export default withTranslation()(LogoEnvironmentBadge);
