// Copyright © 2023 CATTLEytics Inc.

import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import { ImageSize, resizeImage } from '../../../common/utilities/imageResize';

/**
 * Component input properties.
 */
interface Props {
  // Whether the component is disabled
  disabled?: boolean;

  // HTML element name
  name?: string;

  // Callback when upload has completed
  onUpload: (file: File) => void;
}

/**
 * A component to assist with uploading files.
 */
const FileUpload = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const changeHandler = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = ((event.target as HTMLInputElement).files as FileList)[0];
    props.onUpload(await resizeImage(file, ImageSize.Medium));
  };

  return (
    <Form.Group className="mb-3" controlId="formFile">
      <Form.Label>Upload an image of your barn layout to define pens.</Form.Label>
      <Form.Control
        disabled={props.disabled}
        name={props.name ?? 'file'}
        onChange={changeHandler}
        type="file"
      />
    </Form.Group>
  );
};

export default withTranslation()(FileUpload);
