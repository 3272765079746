// Copyright © 2023 CATTLEytics Inc.

import { inject, injectable } from 'inversify';

import { TYPES } from '../../../types';
import Breed from '../../common/entities/breed';
import Gender from '../../common/entities/gender';
import type Logger from '../../logger/logger';
import Api2Service from './api2Service';
import DataService from './dataService';

const path = '/v1/genders';

/**
 * Service for managing animal genders
 */
@injectable()
export default class GenderService implements DataService<Gender> {
  private logger: Logger;

  private apiService: Api2Service;

  /**
   * Creates an instance of GenderService.
   *
   * @param logger Logger instance.
   * @param apiService API Service instance
   */
  constructor(
    @inject(TYPES.logger) logger: Logger,
    @inject(TYPES.api2Service) apiService: Api2Service,
  ) {
    this.logger = logger;
    this.apiService = apiService;
  }

  /**
   * Retrieve a list of Animal Genders
   * @return Gender objects
   */
  async list(): Promise<Gender[]> {
    const result = await this.apiService.get<Gender[]>(path);
    if (!result) {
      return [];
    }

    const data = result as Gender[];
    data.forEach((item) => this.cast(item));
    return data;
  }

  async get(id: number, params?: Record<string, string>): Promise<Gender | undefined> {
    const result = await this.apiService.get<Gender>(`${path}/${id}`, params);
    if (!result) {
      return undefined;
    }

    const data = result as Breed;
    this.cast(data);
    return data;
  }

  async post(attributes: Record<string, string>): Promise<Breed | undefined> {
    const result = await this.apiService.post<Gender>(`${path}`, attributes);
    if (!result) {
      return undefined;
    }
    this.cast(result);
    return result;
  }

  async patch(
    id: number,
    attributes: Record<string, string | number | null>,
  ): Promise<Gender | undefined> {
    // make sure record identifier is included
    attributes.id = id;

    const result = await this.apiService.patch(`${path}/${id}`, attributes);
    if (!result) {
      return undefined;
    }

    const data = result as Gender;
    this.cast(data);
    return data;
  }

  async delete(id: number): Promise<null> {
    return await this.apiService.delete(`${path}/${id}`);
  }

  /**
   * Casts fields into javascript types.
   * @param item
   * @return cast object
   */
  cast(item: Gender): void {
    if (!item) {
      return;
    }
  }
}
