// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { FormSelect } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';

import Button, { ButtonVariant } from './Button';

/**
 * Defines the input properties for this component.
 */
interface Props {
  /**
   * Additional class names to add to this component.
   */
  className?: string;

  /**
   * Whether the component is disabled.
   */
  disabled?: boolean;

  /**
   * Whether we are at the last page of results (number of
   *   rows is less than limit).
   */
  end?: boolean;

  /**
   * Number of items to return. Value should be 10, 50, 100 or 200.
   */
  limit?: number;

  /**
   * Callback when number of results limit value is changed.
   */
  onLimitChange: (limit: number) => void;

  /**
   * Callback when next button is clicked.
   */
  onNext: () => void;

  /**
   * Callback when previous button is clicked.
   */
  onPrev: () => void;

  /**
   * Whether we are at the first page of results (offset 0).
   */
  start?: boolean;
}

/**
 * Set of components for navigation table pagination.
 */
const PaginationControls = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={`${props.className} d-grid gap-2 d-sm-flex justify-content-sm-center `}>
      <Button
        className={'pe-3 me-2 text-nowrap'}
        disabled={props.disabled || props.start}
        onClick={props.onPrev}
        size={'sm'}
        variant={ButtonVariant.OutlinePrimary}
      >
        <MdNavigateBefore className={'me-1'} style={{ height: '100%' }} />
        {t('Previous')}
      </Button>
      <div className={'col-sm-3'}>
        <FormSelect
          className={' me-2 text-center '}
          disabled={props.disabled}
          onChange={(e): void => props.onLimitChange(parseInt(e.target.value))}
          size={'sm'}
          value={props.limit}
        >
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
          <option value={200}>200</option>
        </FormSelect>
      </div>
      <Button
        className={'ps-3 text-nowrap'}
        disabled={props.disabled || props.end}
        onClick={props.onNext}
        size={'sm'}
        variant={ButtonVariant.OutlinePrimary}
      >
        {t('Next')}
        <MdNavigateNext className={'me-1'} style={{ height: '100%' }} />
      </Button>
    </div>
  );
};

export default PaginationControls;
