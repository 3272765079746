// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import AnimalTag from '../../common/components/AnimalTag';
import DataTable, { DataTableHeader, DataTableRow } from '../../common/components/DataTable';
import DateTime from '../../common/components/DateTime';
import { AnimalTestDay } from '../../common/entities/animalTestDay';
import { Sort } from '../../common/enums';
import AnimalTestDayService from '../../common/services/animalTestDayService';
import { QueryKey } from '../../shared';

interface Props {
  /**
   * Additional CSS classes to add to component.
   */
  className?: string;

  /**
   * Keys to filter data.
   */
  filters?: Record<string, string>;

  /**
   * Number of events to show.
   */
  initialLimit?: number;
  /**
   * Number of events to skip.
   */
  initialOffset?: number;

  initialSortDirection?: Sort;

  initialSortField?: Sort;
}

/**
 * A table component containing animal event data.
 */
const TestDataTable = (props: PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();
  const animalTestDayService = useInjection<AnimalTestDayService>(TYPES.animalTestDayService);

  const [limit, setLimit] = useState<number>(props.initialLimit ?? 25);
  const [offset, setOffset] = useState<number>(props.initialOffset ?? 0);
  const [sortField, setSortField] = useState<string>(props.initialSortField ?? 'testDate');
  const [sortDirection, setSortDirection] = useState<Sort>(
    props.initialSortDirection ?? Sort.Descending,
  );
  const filter: undefined | Record<string, string> = props.filters;

  const query = useQuery<AnimalTestDay[]>(
    [QueryKey.TestData, filter, limit, offset, sortField, sortDirection],
    () =>
      animalTestDayService.getAnimalTestDays({
        ...filter,
        ...{
          limit: String(limit),
          offset: String(offset),
          sortField: sortField,
          sortDirection: String(sortDirection),
        },
      }),
    { keepPreviousData: true },
  );

  if (query.isError) {
    return <AlertError message={t('testDataTable|fetchError')} />;
  }

  const headers: DataTableHeader[] = [
    {
      name: 'testDate',
      label: t('testDataTable|testDateLabel'),
      infoTooltip: t('testDataTable|testDateInfoTooltip'),
      classNameMobile: 'col-6',
    },
    {
      name: 'primaryTag',
      label: t('testDataTable|primaryTagLabel'),
      infoTooltip: t('testDataTable|primaryTagInfoTooltip'),
      visible: !filter?.animalId,
      classNameMobile: 'col-6',
    },
    {
      name: 'quantity',
      label: t('Quantity'),
      classNameMobile: 'col-6',
      infoTooltip: t('Measured in pounds (lbs)'),
      sortable: false,
    },
    {
      name: 'energyCorrectedQuantity',
      label: t('EC Quantity'),
      classNameMobile: 'col-6',
      infoTooltip: t('Measured in pounds (lbs)'),
      sortable: false,
    },
    {
      name: 'fat',
      label: t('Fat (%)'),
      classNameMobile: 'col-6',
      sortable: false,
    },
    {
      name: 'protein',
      label: t('Protein (%)'),
      classNameMobile: 'col-6',
      sortable: false,
    },
    {
      name: 'milkProduction305',
      label: t('Milk Production 305'),
      classNameMobile: 'col-6',
      sortable: false,
    },
    {
      name: 'urea',
      label: t('UREA'),
      classNameMobile: 'col-6',
      infoTooltip: t('The UREA/MUN content of the milk'),
      sortable: false,
    },
    {
      name: 'scc',
      label: t('SCC'),
      classNameMobile: 'col-6',
      infoTooltip: t('The somatic cell count of the milk X 1000.'),
      sortable: false,
    },
    // @TODO: for future use
    // {
    //   name: 'actions',
    //   label: t('testDataTable|actionsLabel'),
    //   visible: !filter?.animalId,
    //   classNameMobile: 'col-6',
    //   sortable: false,
    // },
  ];

  const data: DataTableRow[] = !query.data
    ? []
    : query.data.map((row) => ({
        testDate: <DateTime date={row.testDate} hideTime={true} />,
        primaryTag: <AnimalTag name={row.animal?.name} primaryTag={row.animal?.primaryTag} />,
        quantity: String(row.milkProperties.quantity),
        energyCorrectedQuantity: String(row.milkProperties.energyCorrectedQuantity),
        fat: String(row.milkProperties.fatPct),
        protein: String(row.milkProperties.proteinPct),
        milkProduction305: String(row.milkProperties.milkProduction305),
        scc: String(row.milkProperties.scc),
        urea: String(row.milkProperties.urea),
      }));

  return (
    <DataTable
      className={props.className}
      data={data}
      headers={headers}
      isLoading={query.isLoading}
      isPreviousData={query.isPreviousData}
      limit={limit}
      messageNoData={t('testDataTable|noDataFoundMessage', { value: t('test data') })}
      offset={offset}
      onLimitChange={(newLimit): void => setLimit(newLimit)}
      onOffsetChange={(newOffset): void => setOffset(newOffset)}
      onSortDirectionChange={(newSortDirection): void => setSortDirection(newSortDirection)}
      onSortFieldChange={(newSortField): void => setSortField(newSortField)}
      sortDirection={sortDirection}
      sortField={sortField}
    />
  );
};

export default TestDataTable;
