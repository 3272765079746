// Copyright © 2023 CATTLEytics Inc.

import Entity from '../../common/entities/entity';
import Organization, { organizationDefault } from '../../common/entities/organization';

/**
 * Represents an site.
 */
export default interface Site extends Entity {
  active: boolean;

  /**
   * URL to the site logo.
   */
  logoUrl?: string;

  /**
   * URL to a map image of the site.
   */
  mapUrl?: string;

  /**
   * Name of the site
   */
  name: string;

  /**
   * Organization of the site
   */
  organization: Organization;

  /**
   * Organization ID of the site
   */
  organizationId: number;
}

/**
 * Returns a Site with all required fields set.
 * @deprecated
 */
export function siteDefault(): Site {
  return {
    id: 0,
    active: true,
    name: '',
    organizationId: 0,
    organization: organizationDefault(),
    createdDate: new Date(),
    modifiedDate: new Date(),
  };
}
