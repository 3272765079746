// Copyright © 2023 CATTLEytics Inc.

import './Page.scss';

import React, { PropsWithChildren, ReactNode } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

/**
 * Defines the input properties for the AlertError component.
 */
interface Props extends PropsWithChildren<WithTranslation> {
  /**
   * If set, overrides the breadcrumb label for the current page.
   * If not set, title prop is used.
   */
  breadcrumbTitle?: string;

  /**
   * Breadcrumbs to include.
   * Example: For animal card - Home / Animals / 123, breadcrumbs should be set to
   *   [{label: 'Animals', to: '/animals'}]
   */
  breadcrumbs?: { label: string; to?: string }[];

  /**
   * Buttons or other components to the right of the title.
   */
  buttons?: ReactNode;
  /**
   * Additional classes to add to component
   */
  className?: string;

  /**
   * A title to set the document title with.
   * Default will be title prop.
   */
  documentTitle?: string;

  /**
   * Whether to hide breadcrumbs.
   */
  hideBreadcrumbs?: boolean;

  otherHeaderElement?: ReactNode;

  /**
   * Additional CSS styles.
   */
  style?: React.CSSProperties | undefined;

  /**
   * Page title.
   */
  title: string | ReactNode;

  /**
   * Whether the title is is a loading state
   */
  titleLoading?: boolean;
  /**
   * Element to display while title is loading
   */
  titlePlaceholder?: JSX.Element;
}

/**
 * Component to ensure all our pages have the same styles.
 */
const Page = (props: Props): JSX.Element => {
  const documentTitle = props.documentTitle ?? props.title;
  document.title = `${documentTitle} - CATTLEytics`;
  const breadcrumbs = props.breadcrumbs ?? [];
  return (
    <>
      {!props.hideBreadcrumbs && (
        // z-index 11 is to make breadcrumb be above nav corner
        <div className={'px-sm-2'} style={{ zIndex: 11, position: 'relative' }}>
          <Breadcrumb>
            <BreadcrumbItem className={'text-capitalize'} linkAs={Link} linkProps={{ to: '/' }}>
              {props.t('Home')}
            </BreadcrumbItem>
            {breadcrumbs.map((item, index) => (
              <BreadcrumbItem
                className={'text-capitalize'}
                key={index}
                linkAs={Link}
                linkProps={{ to: item.to }}
              >
                {item.label}
              </BreadcrumbItem>
            ))}
            <BreadcrumbItem active className={'text-capitalize'}>
              {props.breadcrumbTitle ?? props.title}
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
      )}
      <div className={`page ${props.className ?? ''} p-2 p-sm-4`} style={props.style}>
        <div className={'d-sm-flex gap-2 justify-content-between flex-wrap flex-sm-nowrap mb-0'}>
          <h1 className={'mb-3 flex-grow-0'}>
            {props.titleLoading ? props.titlePlaceholder : props.title}
          </h1>
          {props.otherHeaderElement}
          <div className={'mb-3 buttons d-grid gap-2'}>{props.buttons}</div>
        </div>
        {props.children}
      </div>
    </>
  );
};

export default withTranslation()(Page);
