// Copyright © 2023 CATTLEytics Inc.
import Konva from 'konva';
import { FC, useCallback, useMemo, useRef } from 'react';
import { Group, KonvaNodeEvents, Rect } from 'react-konva';

import { Pen } from '../../../shared';
import DeleteRegionButton from './DeleteRegionButton';
import normalizeRegion from './normalizePenRegion';
import PenDetail from './PenDetail';
import { Tool } from './PenMapTools';

interface Props {
  debug?: boolean;
  editMode: boolean;
  isDragging: boolean;
  isDrawing: boolean;
  onChangeTool: (tool: Tool) => void;
  onClick?: (pen: Pen) => void;
  onDelete: (pen: Pen) => void;
  onDragEnd: KonvaNodeEvents['onDragEnd'];
  onDragStart: KonvaNodeEvents['onDragStart'];
  pen: Pen;
  scale: number;
}

const PenGroup: FC<Props> = ({
  debug,
  editMode,
  isDragging,
  isDrawing,
  onClick,
  onChangeTool,
  onDelete,
  onDragEnd,
  onDragStart,
  pen,
  scale,
}) => {
  const rectRef = useRef<Konva.Rect>(null);

  const deleteButtonSize = useMemo(() => 15 / scale, [scale]);

  const region = useMemo(() => pen.mapRegion && normalizeRegion(pen.mapRegion), [pen]);
  const centerX = useMemo(() => (region ? region.end.x - region.start.x : 0), [region]);
  const regionWidth = useMemo(() => (region ? region.end.x - region.start.x : 0), [region]);
  const regionHeight = useMemo(() => (region ? region.end.y - region.start.y : 0), [region]);

  const onMouseOutHandler = useCallback(
    () => rectRef.current?.setAttrs({ strokeWidth: 3 / scale }),
    [rectRef, scale],
  );
  const onMouseOverHandler = useCallback(
    () => rectRef.current?.setAttrs({ strokeWidth: 10 }),
    [rectRef],
  );

  if (!region) {
    return null;
  }

  return (
    <Group
      draggable={editMode}
      height={region.end.y - region.start.y}
      listening={!isDrawing}
      onDragEnd={async (e): Promise<void> => {
        if (!editMode || !isDragging) {
          return;
        }
        onDragEnd?.(e);
      }}
      onDragStart={(e): void => {
        if (!editMode) {
          return;
        }
        onDragStart?.(e);
      }}
      onMouseEnter={(): void => onChangeTool(editMode ? Tool.MoveRegion : Tool.NavigateToPen)}
      onMouseLeave={(): void => onChangeTool(editMode ? Tool.CreateRectRegion : Tool.Default)}
      //   (e): void => {
      //     //e.target.setAttrs({ fontStyle: 'normal' });
      //     e.target.getParent().children[0].setAttrs({ strokeWidth: 3 / scale });
      //   }
      onMouseOut={onMouseOutHandler}
      //   (e): void => {
      //     //e.target.setAttrs({ fontStyle: 'bold' });
      //     e.target.getParent().children[0].setAttrs({ strokeWidth: 10 });
      //   }
      onMouseOver={onMouseOverHandler}
      onTap={(): void => onClick?.(pen)}
      preventDefault={false}
      stroke={'rgba(59,132,145,0.8)'}
      strokeWidth={0}
      width={region.end.x - region.start.x}
      x={region.start.x}
      y={region.start.y}
    >
      <Rect
        cornerRadius={20}
        fillLinearGradientColorStops={[
          0,
          'rgba(59,115,132,0.7)',
          0.3,
          'rgba(99, 153, 174,0.7)', // pms7696
        ]}
        fillLinearGradientEndPoint={{ x: centerX, y: region.end.y }}
        fillLinearGradientStartPoint={{ x: centerX, y: 0 }}
        height={region.end.y - region.start.y}
        preventDefault={false}
        ref={rectRef}
        stroke={'rgba(99, 153, 174, 1)'}
        strokeWidth={3 / scale}
        width={region.end.x - region.start.x}
        // onMouseOver={(e) => e.target.setAttrs({ strokeWidth: 10 })}
        // onMouseOut={(e) => e.target.setAttrs({ strokeWidth: 3 / scale })}
      />
      <PenDetail
        debug={debug}
        editMode={editMode}
        onClick={(): void => onClick?.(pen)}
        onMouseOut={onMouseOutHandler}
        onMouseOver={onMouseOverHandler}
        pen={pen}
        regionHeight={regionHeight}
        regionWidth={regionWidth}
        scale={scale}
      />

      <DeleteRegionButton
        onClick={async (): Promise<void> => {
          if (!pen) {
            return;
          }
          onDelete(pen);
          onChangeTool(Tool.CreateRectRegion);
        }}
        onMouseEnter={(): void => onChangeTool(Tool.DeleteRegion)}
        onMouseLeave={(): void => onChangeTool(Tool.MoveRegion)}
        // x={region.end.x - deleteButtonSize - 10}
        // y={region.start.y + 10}
        size={deleteButtonSize}
        visible={editMode}
        x={region.end.x - region.start.x - deleteButtonSize - 10}
        y={10}
      />
    </Group>
  );
};

export default PenGroup;
