// Copyright © 2023 CATTLEytics Inc.
import { getAnimalReproductionStatus } from '../../../../common/utilities';
import { AnimalReproductionStatus } from '../../../../shared';

export const neutralColor = '#666666';

export const getColorByLabel = (label?: string): string => {
  if (!label) return neutralColor;

  if (label === 'Fresh') {
    return '#32A3E5';
  } else {
    const animalStatus = getAnimalReproductionStatus(label as AnimalReproductionStatus);
    return animalStatus.label === 'Unknown' ? neutralColor : animalStatus.backgroundColor;
  }
};
