// Copyright © 2023 CATTLEytics Inc.
import { FC } from 'react';

const TimelineContainer: FC = ({ children }) => {
  return (
    <div
      className="h-100 w-100 overflow-hidden d-flex position-relative"
      style={{
        borderRadius: 8,
        border: '1px solid gray',
      }}
    >
      {children}
    </div>
  );
};

export default TimelineContainer;
