// Copyright © 2023 CATTLEytics Inc.
import { Dispatch, SetStateAction, useState } from 'react';

import { useOpenModal } from './useOpenModal';

// Copyright © 2023 CATTLEytics Inc.
interface Return {
  closeDeleteConfirmModal: () => void;
  deleteConfirmModalErrorMessage: string;
  deleteConfirmModalOpen: boolean;
  openDeleteConfirmModal: () => void;
  setDeleteConfirmModalErrorMessage: Dispatch<SetStateAction<string>>;
}

export const useDeleteConfirmModal = (): Return => {
  const {
    open: deleteConfirmModalOpen,
    openModal: openDeleteConfirmModal,
    closeModal: closeDeleteConfirmModal,
  } = useOpenModal();
  const [deleteConfirmModalErrorMessage, setDeleteConfirmModalErrorMessage] = useState<string>('');

  return {
    deleteConfirmModalOpen,
    openDeleteConfirmModal,
    closeDeleteConfirmModal,
    deleteConfirmModalErrorMessage,
    setDeleteConfirmModalErrorMessage,
  } as const;
};
