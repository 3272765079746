// Copyright © 2023 CATTLEytics Inc.

import React, { CSSProperties } from 'react';

import { buildLactationGroup } from '../../shared/utilities';

/**
 * Defines the input properties for this component.
 */
interface Props {
  /**
   * CSS classes to pass to component.
   */
  className?: string;
  /**
   * The animal's lactation number.
   */
  lactationNumber: number;

  /**
   * CSS styles
   */
  style?: CSSProperties;
}

/**
 * A badge to indicate an animal's milking status.
 */
const LactationNumberBadge = (props: Props): JSX.Element => {
  const backgroundClassName = ((): string => {
    switch (props.lactationNumber) {
      case 0:
        return '';
      case 1:
        return 'bg-success';
      case 2:
        return 'bg-warning';
      case 3:
      default:
        return 'bg-danger';
    }
  })();

  const defaultStyle = {
    fontSize: '13px',
    fontFamily: 'Arial',
    borderRadius: '2rem',
    width: '28px',
    height: '28px',
    color: 'white',
  };

  const style = {
    ...defaultStyle,
    ...(props.style ?? {}),
  };

  return (
    <div
      className={`d-flex justify-content-center align-items-center ${backgroundClassName} ${props.className}`}
      style={style}
      title={`${props.lactationNumber}`}
    >
      <span>{buildLactationGroup(props.lactationNumber)}</span>
    </div>
  );
};

export default LactationNumberBadge;
