// Copyright © 2024 CATTLEytics Inc.

import { format } from 'date-fns';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import AlertError from '../../../common/components/AlertError';
import AnimalTag from '../../../common/components/AnimalTag';
import DataTable, { DataTableHeader, DataTableRow } from '../../../common/components/DataTable';
import { api } from '../../../common/utilities/api';
import { Animal } from '../../../shared';
import { ApiResourceV1 } from '../../../shared/enums/api';
import { HttpMethod } from '../../../shared/enums/http';

interface Props {
  /**
   * Animal id
   */
  animalId: number;

  /**
   * Table type; calves or sisters
   */
  tableType: 'calves' | 'sisters';
}

/**
 * A table component containing animal's repro information.
 */
const ReproRelativesTable = (props: PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  const query = useQuery<Animal>([props.tableType, props.animalId], () =>
    api<Animal>(HttpMethod.Get, `${ApiResourceV1.Animals}/${props.animalId}/${props.tableType}`),
  );

  if (query.isError) {
    return <AlertError message={t('reproRelativesTable|fetchError')} />;
  }

  const headers: DataTableHeader[] = [
    {
      name: 'primaryTag',
      label: t('reproRelativesTable|primaryTagColumn'),
      classNameMobile: 'col-6',
    },
    {
      name: 'birthDate',
      label: t('reproRelativesTable|birthDateColumn'),
      classNameMobile: 'col-6',
    },
  ];

  const data: DataTableRow[] = !query.data
    ? []
    : query.data.map((row: Animal) => ({
        animalId: row.id.toString(),
        birthDate: row.birthDate ? format(new Date(row.birthDate), 'yyyy-MM-dd') : '',
        primaryTag: (
          <AnimalTag animalId={row.id} link={true} name={row.name} primaryTag={row.primaryTag} />
        ),
      }));

  return (
    <div>
      <h2>{t(`reproRelativesTable|${props.tableType}`)}</h2>
      <DataTable
        data={data}
        headers={headers}
        hidePaginationControls={true}
        isLoading={query.isLoading}
        messageNoData={t('reproRelativesTable|messageNoData')}
        onRowClick={(row): void => history.push(`/animals/${row.animalId}`)}
      />
    </div>
  );
};

export default ReproRelativesTable;
