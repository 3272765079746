// Copyright © 2023 CATTLEytics Inc.

import { parse } from 'date-fns';
import { useInjection } from 'inversify-react';
import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { Form, Placeholder } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { TYPES } from '../../../types';
import AnimalAutocomplete from '../../animals/components/AnimalAutocomplete';
import PenAutocomplete from '../../animals/components/PenAutocomplete';
import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import ButtonModalCancel from '../../common/components/ButtonModalCancel';
import ButtonModalSave from '../../common/components/ButtonModalSave';
import Modal from '../../common/components/Modal';
import Required from '../../common/components/Required';
import DairyLog, { dairyLogDefault } from '../../common/entities/dairyLog';
import Pen from '../../common/entities/pen';
import { DairyLogEventType, DairyLogEventTypeIdMap } from '../../common/enums/dairyLogEventType';
import DairyLogService from '../../common/services/dairyLogService';
import { formatDate } from '../../common/utilities';
import Logger from '../../logger/logger';
import { Animal } from '../../shared';
import { QueryKey } from '../../shared/enums';

/**
 * Component input properties.
 */
interface Props {
  /**
   * Default animal to select.
   */
  animal?: Animal;

  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * ID of dairyLog to edit in this modal.
   */
  dairyLogId?: number;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;

  /**
   * Callback with saved dairyLog.
   */
  onSave?: (dairyLog: DairyLog) => void;

  /**
   * Default pen to select.
   */
  pen?: Pen;
}

/**
 * DairyLog modal component for creating/editing dairyLogs.
 */
const DairyLogModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const logger = useInjection<Logger>(TYPES.logger);
  const dairyLogService = useInjection<DairyLogService>(TYPES.dairyLogService);

  const dairyLog = dairyLogDefault();

  if (props.animal) {
    dairyLog.animal = props.animal;
    dairyLog.animalId = props.animal.id;
  }
  if (props.pen) {
    dairyLog.pen = props.pen;
    dairyLog.penId = props.pen.id;
  }

  const [data, setData] = useState<DairyLog>(dairyLog);
  const [validated, setValidated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const query = useQuery(
    [QueryKey.DairyLogs, props.dairyLogId],
    () => dairyLogService.get(props.dairyLogId as number),
    {
      enabled: !!props.dairyLogId,
    },
  );

  const mutation = useMutation(
    () => {
      if (props.dairyLogId) {
        return dairyLogService.patch(data.id, data);
      } else {
        return dairyLogService.post(data);
      }
    },
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(QueryKey.DairyLogs);
      },
    },
  );

  useEffect(() => {
    const invalidElements = document.querySelectorAll('input.form-control:invalid');
    if (invalidElements.length > 0) {
      invalidElements[0].closest('.form-group')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [validated]);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (query.data) {
      setData(query.data);
    }
  }, [query.data]);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newData = { ...data };
    newData[event.target.name] = event.target.value;
    setData(newData);
  };

  const onDateChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newData = { ...data };
    newData[event.target.name] = parse(event.target.value, 'yyyy-MM-dd', new Date());
    setData(newData);
  };

  const onSelect = (event: ChangeEvent<HTMLSelectElement>): void => {
    const newData = { ...data };
    newData[event.target.name] = event.target.value;
    setData(newData);
  };

  const onTypeaheadSelect = (name: string, value?: Animal | Pen): void => {
    const newData = { ...data };
    if (!value) {
      newData[name.substr(0, name.length - 2)] = null;
      newData[name] = null;
    } else {
      newData[name.substr(0, name.length - 2)] = value;
      newData[name] = value.id;
    }
    setData(newData);
  };

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      await mutation.mutateAsync();
      //await dairyLogService.createDairyLog(data);
      if (props.onSave) {
        props.onSave(data);
      }

      props.onClose();
    } catch (err) {
      logger.error('DairyLog could not be saved.', err, data);
      setErrorMessage(t('dairyLogModal|logSaveError'));
    }
  };

  const fields = (
    <Fragment>
      <Form.Group className="form-group mb-3" controlId="formDate">
        <Form.Label>
          {t('Event Type')} <Required />
        </Form.Label>
        <Form.Select
          aria-label={t('Event Type')}
          name={'eventType'}
          onChange={onSelect}
          required={true}
          value={data.eventType ?? 0}
        >
          {Object.values(DairyLogEventType)
            .filter((v) => isNaN(Number(v)))
            .map((eventType) => (
              <option key={eventType} value={DairyLogEventTypeIdMap[eventType]}>
                {eventType}
              </option>
            ))}
        </Form.Select>
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="form-group mb-3" controlId="formDate">
        <Form.Label>
          {t('Date')} <Required />
        </Form.Label>
        <Form.Control
          aria-label="Date"
          name={'date'}
          onChange={onDateChange}
          required={true}
          type={'date'}
          value={formatDate(data.date, 'yyyy-MM-dd')}
        />
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="form-group mb-3" controlId="formDescription">
        <Form.Label>
          {t('Description')} <Required />
        </Form.Label>
        <Form.Control
          aria-label={t('Description')}
          as={'textarea'}
          name={'description'}
          onChange={onInputChange}
          required={true}
          rows={4}
          value={data.description}
        />
        <Form.Text>{t('dairyLogModal|eventDescriptionInstructions')}</Form.Text>
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="form-group mb-3" controlId="formBasicNotes">
        <Form.Label>
          {t('Notes')} <Required />
        </Form.Label>
        <Form.Control
          aria-label={t('Notes')}
          as={'textarea'}
          name={'notes'}
          onChange={onInputChange}
          required={false}
          rows={4}
          value={data.notes}
        />
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="form-group mb-3" controlId="formFollowUp">
        <Form.Label>{t('Follow Up')}</Form.Label>
        <Form.Control
          aria-label={t('Follow Up')}
          as={'textarea'}
          name={'followUp'}
          onChange={onInputChange}
          required={false}
          rows={4}
          value={data.followUp}
        />
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="form-group mb-3" controlId="formAnimal">
        <Form.Label>{t('Animal')}</Form.Label>
        <AnimalAutocomplete
          id={'animal'}
          name={'animalId'}
          onSelect={(animal): void => onTypeaheadSelect('animalId', animal as Animal | undefined)}
          required={false}
          selected={data.animalId ? data.animal : undefined}
        />
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="form-group mb-3" controlId="formPenAutocomplete">
        <Form.Label>{t('Pen')}</Form.Label>
        <PenAutocomplete
          id={'formPenAutocomplete'}
          onSelect={(pen): void => onTypeaheadSelect('penId', pen)}
          required={false}
          selected={data.penId ? data.pen : undefined}
        />
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </Form.Group>
    </Fragment>
  );
  const placeholder = (
    <Placeholder animation={'glow'}>
      <Placeholder className={'mt-3'} size={'lg'} xs={6} />
      <Placeholder xs={12} />
      <Placeholder xs={12} />
      <Placeholder xs={12} />
      <Placeholder className={'mt-3'} size={'lg'} xs={6} />
      <Placeholder xs={12} />
      <Placeholder className={'mt-3'} size={'lg'} xs={6} />
      <Placeholder xs={12} />
    </Placeholder>
  );

  return (
    <Modal
      onClose={props.onClose}
      size={'lg'}
      title={t(`{{action}} {{value}}`, {
        action: props.dairyLogId ? t('Edit') : t('Create'),
        value: t('Log'),
      })}
      visible={true}
    >
      <Form noValidate={true} onSubmit={onFormSubmit} validated={validated}>
        {query.isLoading ? placeholder : fields}

        <AlertErrorForModal message={errorMessage} />
        <div className="modal-footer modal-footer-in-form">
          <ButtonModalCancel
            disabled={mutation.isLoading || query.isLoading}
            onClick={props.onClose}
          />
          <ButtonModalSave
            busy={mutation.isLoading}
            disabled={mutation.isLoading || query.isLoading}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default DairyLogModal;
