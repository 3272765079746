// Copyright © 2023 CATTLEytics Inc.

import { parseISO } from 'date-fns';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import AnimalTag from '../../common/components/AnimalTag';
import DataCell from '../../common/components/DataCell';
import DateTime from '../../common/components/DateTime';
import LactationNumberBadge from '../../common/components/LactationNumberBadge';
import { Gender } from '../../common/enums';
import { useAnimalStatusLabel } from '../../common/hooks';
import { ageInMonths, ageInYears, IconTag } from '../../common/utilities';
import { Animal, nameTag } from '../../shared';

interface Props {
  /**
   * An animal entity. The source for all the data in this component.
   */
  animal: Animal;

  /**
   * Additional CSS classes to apply to this component.
   */
  className?: string;
}

/**
 * Animal stats detail.
 */
const AnimalStats = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const animal = props.animal;
  const status = useAnimalStatusLabel(animal);
  const [age, ageTooltip] = ((): (string | undefined)[] => {
    if (!props.animal.birthDate) {
      return [undefined, undefined];
    }
    const years = ageInYears(parseISO(props.animal.birthDate));
    if (years < 1) {
      const months = ageInMonths(parseISO(props.animal.birthDate));
      return [`${months} months`, 'age in months'];
    }
    return [`${years} years`, 'age in years'];
  })();

  return (
    <Row>
      {/*<Col md={3} xs={6}>*/}
      {/*  <DataCell label={'GROUP'} tooltip="Animal group identifier" value="3" />*/}
      {/*</Col>*/}
      <Col md={3} xs={6}>
        <DataCell
          label={t('Current Pen')}
          linkTo={`/pens/${animal.currentPen?.id}`}
          tooltip={t("Animal's current pen")}
          value={props.animal.currentPen?.name}
        />
      </Col>
      <Col md={3} xs={6}>
        <DataCell
          label={t('Status')}
          tooltip={t('animalStats|animalStatusTooltip')}
          value={status}
        />
      </Col>
      <Col md={3} xs={6}>
        <DataCell label={t('Age')} tooltip={ageTooltip} value={age ?? '-'} />
      </Col>
      <Col md={3} xs={6}>
        <DataCell
          label={t('Gender')}
          tooltip={t('The gender of this animal')}
          value={props.animal.gender?.name}
        />
      </Col>
      <Col md={3} xs={6}>
        <DataCell
          label={t('Breed')}
          tooltip={t('The breed of this animal')}
          value={props.animal.breed?.name}
        />
      </Col>
      <Col md={3} xs={6}>
        <DataCell
          label={t('Lot')}
          tooltip={t('The lot this animal belongs to')}
          value={props.animal.lot}
        />
      </Col>
      {/* <Col md={3} xs={6}>
        <DataCell
          label={t('Age Class')}
          tooltip={t(
            'The age classification of this animal: BC = Bull Calf, HC = Heifer Class, C = Cow, H = Heifer',
          )}
        >
          <AgeClassBadge ageClass={buildAgeClassCode(props.animal)} />
        </DataCell>
      </Col> */}
      <Col md={3} xs={6}>
        <DataCell
          label={t('Colour')}
          tooltip={t('The colour of this animal')}
          value={props.animal.animalColor?.name}
        />
      </Col>
      <Col md={3} xs={6}>
        <DataCell label={t('Birth Date')} tooltip={t('Birth date')}>
          <DateTime date={props.animal.birthDate} hideTime={true} showDistanceOnHover={true} />
        </DataCell>
      </Col>
      <Col md={3} xs={6}>
        <DataCell label={t('Dam')} tooltip={t('Dam identifier')}>
          {props.animal.damId && (
            <AnimalTag
              animalId={props.animal.damId}
              link={true}
              name={props.animal.dam?.name}
              primaryTag={props.animal.dam?.primaryTag}
            />
          )}
        </DataCell>
      </Col>
      <Col md={3} xs={6}>
        <DataCell
          label={t('Sire')}
          linkTo={props.animal.sireId ? `/animals/${String(props.animal.sireId)}` : undefined}
          tooltip={t('Sire identifier')}
          value={
            props.animal.sireId
              ? nameTag(props.animal.sire?.primaryTag, props.animal.sire?.name)
              : undefined
          }
        >
          {props.animal.sireId && (
            <Link to={`/sires/${props.animal.sireId}`}>
              <IconTag className={'me-1'} />
              {props.animal.sire?.name}
            </Link>
          )}
        </DataCell>
      </Col>
      {props.animal.genderId === Gender.Female && (
        <>
          <Col md={3} xs={6}>
            <DataCell label={t('Lactation Group')} tooltip={t('Lactation group')}>
              {props.animal.lactationNumber ? (
                <LactationNumberBadge lactationNumber={props.animal.lactationNumber} />
              ) : (
                '-'
              )}
            </DataCell>
          </Col>
          <Col md={3} xs={6}>
            <DataCell label={t('Lactation Number')} tooltip={t('Lactation number')}>
              {props.animal.lactationNumber ? props.animal.lactationNumber : '-'}
            </DataCell>
          </Col>
          <Col md={3} xs={6}>
            <DataCell label={t('Calving Date')} tooltip={t('Estimated calving date')}>
              <DateTime
                date={props.animal.calvingDate}
                hideTime={true}
                showDistanceOnHover={true}
              />
            </DataCell>
          </Col>

          <Col md={3} xs={6}>
            <DataCell
              label={t('Days In Milk')}
              tooltip={t('Days in milk')}
              value={props.animal.daysInMilk}
            />
          </Col>
          <Col md={3} xs={6}>
            <DataCell label={t('TOTM')} tooltip={t('Total milk this lactation')} value=".." />
          </Col>
          <Col md={3} xs={6}>
            <DataCell label={t('Milk withdrawal')} tooltip={t('Milk withdrawal date')} value=".." />
          </Col>
          {/*<Col md={3} xs={6}>*/}
          {/*  <DataCell*/}
          {/*    label={t('Latest Repro')}*/}
          {/*    tooltip={t('Latest reproductive event and date')}*/}
          {/*    value={*/}
          {/*      props.animalInsight.latestReproEvent*/}
          {/*        ? `${props.animalInsight.latestReproEvent?.eventType?.name}\n${formatDate(*/}
          {/*            props.animalInsight.latestReproEvent?.eventDateTime,*/}
          {/*          )}`*/}
          {/*        : undefined*/}
          {/*    }*/}
          {/*  />*/}
          {/*</Col>*/}
          <Col md={3} xs={6}>
            <DataCell
              label={t('Times Bred')}
              tooltip={t('Times bred')}
              value={props.animal.timesBred}
            />
          </Col>
          <Col md={3} xs={6}>
            <DataCell
              label={t('SCC > 150k')}
              tooltip={t(
                'Number of times the somatic cell count was greater than 150k in current lactation.',
              )}
              value={props.animal.scc150kCount}
            />
          </Col>
          <Col md={3} xs={6}>
            <DataCell
              label={t('animalStats|terminationCodeLabel')}
              tooltip={t('animalStats|terminationCodeTooltip')}
              value={props.animal.terminationCode?.name}
            />
          </Col>
        </>
      )}
    </Row>
  );
};

export default AnimalStats;
