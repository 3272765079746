// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Arrow from '../../common/components/Arrow';
import Button, { ButtonVariant } from '../../common/components/Button';
import { IconCreate } from '../../common/utilities';

/**
 * @description Defines the input properties for the BreedingTimelineStepAdd component.
 * @interface Props
 */
interface Props {
  /**
   * @description Children of this component.
   * @memberOf Props
   */
  children?: React.ReactNode;

  /**
   * @description Add program callback.
   * @memberOf Props
   */
  onAddProgram?: () => void;

  /**
   * @description The step number.
   * @type {number}
   * @memberOf Props
   */
  step: number;

  /**
   * @description Text that will be displayed below the step number arrow.
   * @type {string}
   * @memberof Props
   */
  stepLabel?: string;
}

/**
 * @description Breeding timeline step component. For displaying
 *   the various program steps that make up a breeding plan.
 * @const BreedingTimelineStepAdd
 * @param {Props} props
 */
const BreedingTimelineStepAdd = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  const avatar = (
    <Arrow backgroundColor={'#ccc'} color="white" height="64px">
      {props.step}
    </Arrow>
  );
  return (
    <Col className="mb-5" lg={6} md={12} xl={4}>
      <Row className="h-100">
        <Col className="d-flex align-items-center justify-content-center flex-column" lg={3} xs={3}>
          {avatar}
          {props.stepLabel ? <div className="w-100">{props.stepLabel}</div> : null}
        </Col>
        <Col className="h-100" lg={9} xs={9}>
          {props.children}
          <Card
            className="w-100 h-100 position-relative"
            style={{
              minHeight: '200px',
              border: '1px dashed gray',
              backgroundColor: 'transparent',
            }}
          >
            <Card.Body className="d-flex align-items-center justify-content-center">
              <Button
                className="stretched-link"
                icon={IconCreate}
                label={t('common|add')}
                onClick={(): void | null => (props.onAddProgram ? props.onAddProgram() : null)}
                variant={ButtonVariant.OutlinePrimary}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

export default BreedingTimelineStepAdd;
