// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { withTranslation } from 'react-i18next';

import Page from '../../common/components/Page';

/**
 * Routable component for help.
 */
const HelpPage = (): JSX.Element => {
  return <Page title="Help" />;
};

export default withTranslation()(HelpPage);
