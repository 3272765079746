// Copyright © 2023 CATTLEytics Inc.

import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { Text } from 'react-konva';

import { Coordinate } from '../../../common/types';

/**
 * @description Component input properties.
 */
interface Props {
  /**
   * @description CSS color value. Default: white
   */
  color?: string;

  /**
   * @description The location of the mouse. If stage is scaled this should be set via getRelativeMousePosition()
   */
  mousePosition: Coordinate;

  /**
   * @description Whether or not to show component.
   */
  visible?: boolean;

  /**
   * @description Horizontal (top left) coordinate to draw this component. Default: 10
   * @default 10
   */
  x?: number;

  /**
   * @description Vertical (top left) coordinate to draw this component. Default: 10
   * @default 10
   */
  y?: number;
}

/**
 * @description For showing x,y mouse position.
 * @extends {React.Component}
 */
const DebugShowMousePosition = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const x = props.x ?? 10;
  const y = props.y ?? 10;
  const color = props.color ?? 'white';
  const visible = props.visible ?? true;
  return (
    <Fragment>
      {visible ? (
        <Text
          fill={color}
          fontSize={20}
          text={`X: ${props.mousePosition.x.toFixed(2)}, Y: ${props.mousePosition.y.toFixed(2)}`}
          x={x}
          y={y}
        />
      ) : null}
    </Fragment>
  );
};

export default withTranslation()(DebugShowMousePosition);
