// Copyright © 2023 CATTLEytics Inc.

import React, { CSSProperties, ReactNode } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { AgeClass } from '../utilities';

/**
 * Defines the input properties for this component.
 */
interface Props {
  /**
   * The animal's age classification.
   */
  ageClass: AgeClass;

  /**
   * CSS classes to pass to component.
   */
  className?: string;

  /**
   * CSS styles
   */
  style?: CSSProperties;
}

/**
 * A badge to indicate an animal's add classification.
 */
const AgeClassBadge = (props: Props): JSX.Element => {
  const defaultStyle = {
    // fontSize: '13px',
    // fontFamily: 'Arial',
    // borderRadius: '2rem',
    // width: '28px',
    // height: '28px',
    // color: 'white',
  };

  const style = {
    ...defaultStyle,
    ...(props.style ?? {}),
  };

  return (
    <span style={style}>
      <OverlayTrigger
        overlay={<Tooltip>{props.ageClass.description}</Tooltip>}
        placement={'bottom'}
      >
        {({ ref, ...triggerHandler }): ReactNode => (
          <span ref={ref} {...triggerHandler}>
            <strong>{props.ageClass.code}</strong>
          </span>
        )}
      </OverlayTrigger>
    </span>
  );
};

export default AgeClassBadge;
