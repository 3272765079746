// Copyright © 2023 CATTLEytics Inc.
import './ReportsInstructions.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { CqlExample } from './CqlExample';

interface Props {
  className?: string;
}

export const ReportsInstructions = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  // @TODO more examples from Shari
  // 1. Answering the question -what was the J-VAC vaccine history for any cow that had mastitis?
  //   (Pull up the occurrence of that type of vaccine + the cows that had a mastitis event)
  // 2. How many subsequent breedings did it take for the cows to become pregnant who calved in June of last year?
  //   (ideally - how many did it take throughout each month of the year to compare)
  // 3. The animals who had a difficult calving
  // can we summarize the subsequent issues they had (RP, DA, (LDA/RDA), Metritis, Endometritis, Milk Fever etc)

  return (
    <section className={props.className}>
      <h2>{t('reportsInstructions|title')}</h2>
      <p>{t(`reportsInstructions|introduction`)}</p>
      <p>{t('reportsInstructions|formatExample')}</p>

      <h2>{t('reportsInstructions|examplesHeading')}</h2>

      <h3>{t('reportsInstructions|vaccinationExampleHeading')}:</h3>
      <p>{t('reportsInstructions|vaccinationExampleDescription')}</p>
      <CqlExample
        className={'mb-3'}
        code={
          'LIST primaryTag mastitisCount pen milkingStatus\n' +
          'FOR status=active mastitisCount>2\n' +
          'DOWNBY mastitisCount\n'
        }
        description={t('reportsInstructions|vaccinationExampleDescriptionA')}
      />
      <CqlExample
        className={'mb-3'}
        code={'EVENTS\nFOR primaryTag=3217 type=Vaccination notes~"1.5 ENV"\n'}
        description={t('reportsInstructions|vaccinationExampleDescriptionB')}
      />

      <h3>{t('reportsInstructions|listFullExampleHeading')}:</h3>
      <CqlExample
        className={'mb-3'}
        code={
          'LIST primaryTag lactationNumber status pen milkingStatus\nFOR status=active pen=1\nDOWNBY lactationNumber\n'
        }
        description={t('reportsInstructions|listFullExampleDescription')}
      />

      <h3>{t('reportsInstructions|mastitisCountExampleHeading')}:</h3>
      <CqlExample
        className={'mb-3'}
        code={
          'LIST primaryTag mastitisCount pen milkingStatus\n' +
          'FOR status=active mastitisCount>2\n' +
          'DOWNBY mastitisCount\n'
        }
        description={t('reportsInstructions|mastitisCountExampleDescription')}
      />

      <h3>{t('reportsInstructions|sccCountExampleHeading')}:</h3>
      <CqlExample
        code={
          'LIST primaryTag lactationNumber status pen milkingStatus\nFOR scc>150000 status=active\nBY pen\n'
        }
        description={t('reportsInstructions|sccCountExampleDescription')}
      />

      <h3>{t('reportsInstructions|orConditionExampleHeading')}:</h3>
      <CqlExample
        code={
          'LIST primaryTag lactationNumber status pen milkingStatus\nFOR primaryTag>0 status=active (pen=1 lactationNumber=2) (pen=2 lactationNumber=3)\nDOWNBY lactationNumber'
        }
        description={t('reportsInstructions|orConditionExampleDescription')}
      />
    </section>
  );
};
