// Copyright © 2023 CATTLEytics Inc.

import { addDays } from 'date-fns';
import { useInjection } from 'inversify-react';
import React, { useCallback, useEffect, useState } from 'react';
import { Alert, ProgressBar, ToggleButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleFill,
  RiCheckboxCircleLine,
} from 'react-icons/ri';
import { useQuery } from 'react-query';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import Button, { ButtonVariant } from '../../common/components/Button';
import DateTime from '../../common/components/DateTime';
import TablePlaceholder from '../../common/components/TablePlaceholder';
import ActiveTreatment from '../../common/entities/activeTreatment';
import DiagnosisRegime from '../../common/entities/diagnosisRegime';
import { DecisionTreeAnswer as Answer, DecisionTreeAnswers } from '../../common/enums';
import ActiveTreatmentService from '../../common/services/activeTreatmentService';
import DiagnosisRegimeDetailService from '../../common/services/diagnosisRegimeDetailService';
import DiagnosisRegimeService from '../../common/services/diagnosisRegimeService';
import {
  IconComplete,
  IconThumbsDown,
  IconThumbsUp,
  IconUndo,
  IconWarning,
} from '../../common/utilities';
import { QueryKey } from '../../shared';
import { DiagnosisRegimeDetail, DiagnosisRegimeDetailType } from '../../shared';
import { TaskStatus } from '../../shared';
import DiagnosisTreatWithProduct, { ProductChange } from './DiagnosisTreatWithProduct';

/**
 * Data for a step change event.
 */
export interface StepChange {
  /**
   * Object of decision tree answers
   */
  answers: DecisionTreeAnswers;

  /**
   * If this active treatment is now complete
   */
  completed: boolean;

  /**
   * The current step identifier which is actually a diagnosis regime detail ID.
   */
  currentStepId: number;

  /**
   * The current step number (not an ID).
   */
  currentStepNumber: number;

  /**
   * Completed events.
   */
  events: DiagnosisRegimeDetail[];

  status: TaskStatus;
}

interface Props {
  /**
   * An optional identifier to restore the tree to a pre-defined state.
   */
  activeTreatmentId?: number;

  /**
   * Animal's weight in kg. If omitted typical weight from settings will be used.
   */
  animalWeight?: number;

  /**
   * Only show detail for the specified diagnosis regime.
   */
  diagnosisRegimeId: number;

  /**
   * Whether this component should be disabled.
   */
  disabled?: boolean;

  /**
   * Used to indicate if selected animal is a calf for weight calculation
   */
  isCalf?: boolean;

  /**
   * Callback when a step is marked as completed.
   * Note: only steps that require an action to be taken like one with a linked product.
   * @param steps
   */
  onStepChange?: (stepChange: StepChange) => void;
}

const YesIcon = (props: IconButtonProps): JSX.Element => {
  const onClick = props.disabled ? undefined : props.onClick;
  return (
    <div
      className={`d-flex border border-4 ${
        props.disabled ? 'icon-border-muted text-muted' : 'text-success border-success'
      } rounded-circle align-items-center justify-content-center`}
      onClick={onClick}
      style={{
        margin: '0 3px',
        width: '40px',
        height: '40px',
      }}
    >
      <IconThumbsUp style={{ fontSize: '20px' }} />
      {/*{props.disabled ? 'disabled' : 'active'}*/}
    </div>
  );
};

const NoIcon = (props: IconButtonProps): JSX.Element => {
  const onClick = props.disabled ? undefined : props.onClick;
  return (
    <div
      className={`d-flex border border-4 ${
        props.disabled ? 'icon-border-muted text-muted' : 'text-danger border-danger'
      } rounded-circle align-items-center justify-content-center`}
      onClick={onClick}
      style={{
        margin: '0 3px',
        width: '40px',
        height: '40px',
      }}
    >
      <IconThumbsDown style={{ fontSize: '20px' }} />
    </div>
  );
};

interface IconButtonProps {
  disabled: boolean;
  onClick?: () => void;
}

const CompleteIcon = (props: IconButtonProps): JSX.Element => {
  const onClick = props.disabled ? undefined : props.onClick;
  return (
    <div onClick={onClick}>
      <RiCheckboxCircleLine className={'text-muted'} style={{ fontSize: '48px' }} />
      {/*{props.disabled ? 'disabled' : 'active'}*/}
    </div>
  );
};

const IncompleteIcon = (props: IconButtonProps): JSX.Element => {
  const onClick = props.disabled ? undefined : props.onClick;
  return (
    <div>
      <RiCheckboxBlankCircleLine
        className={'text-success'}
        onClick={onClick}
        style={{ fontSize: '48px' }}
      />
      {/*{props.disabled ? 'disabled' : 'active'}*/}
    </div>
  );
};

/**
 * A decision tree to determine which diagnosis regime detail records to create tasks for.
 */
const DiagnosisDecisionTree = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const { onStepChange } = props;

  const diagnosisRegimeDetailService = useInjection<DiagnosisRegimeDetailService>(
    TYPES.diagnosisRegimeDetailService,
  );
  const diagnosisRegimeService = useInjection<DiagnosisRegimeService>(TYPES.diagnosisRegimeService);
  const activeTreatmentService = useInjection<ActiveTreatmentService>(TYPES.activeTreatmentService);

  const [answers, setAnswers] = useState<DecisionTreeAnswers>({});
  const [currentStepNumber, setCurrentStepNumber] = useState<number>(1);
  const [stepHistory, setStepHistory] = useState<DiagnosisRegimeDetail[]>([]);
  const [drdByStep, setDrdByStep] = useState<Record<number, DiagnosisRegimeDetail>>({});
  const [stepsComplete, setStepsComplete] = useState<boolean>(false);
  const [fastForwardTo, setFastForwardTo] = useState<number>();
  const [isFastForwarded, setIsFastForwarded] = useState<boolean>(false);
  const [numSavedAnswers, setNumSavedAnswers] = useState<number>();
  const [treatmentProducts, setTreatmentProducts] = useState<Record<number, ProductChange>>({});
  const [diagnosisRegime, setDiagnosisRegime] = useState<DiagnosisRegime>();

  const addStepHistory = useCallback(
    (diagnosisRegimeDetail: DiagnosisRegimeDetail): void => {
      console.debug('addStepHistory');
      const sh = [...stepHistory];
      sh.push(diagnosisRegimeDetail);
      setStepHistory(sh);
    },
    [stepHistory],
  );

  const nextStep = useCallback(
    (
      answer: Answer,
      fastForwarding = false,
      //treatmentProducts: Record<number, DecisionTreeAnswers> = {},
    ): void => {
      console.debug('nextStep', answer);
      const currentDetail = drdByStep[currentStepNumber];

      // treatment products is an array or product changes
      const tp = treatmentProducts[currentDetail.id];

      const updatedAnswers = fastForwarding
        ? answers
        : {
            ...answers,
            ...{
              [String(currentDetail.id)]: {
                answer: answer,
                productCustomName: tp?.productCustomName,
                productId: tp?.productId,
                productDosage: tp?.productDosage,
                productLocation: tp?.productLocation,
                productRoute: tp?.productRoute,
              },
            },
          };
      setAnswers(updatedAnswers);

      // get all the completed steps that are completed and have an attached productId
      const eventsToCreate = stepHistory.filter(
        (step) =>
          updatedAnswers[String(step.id)].answer === Answer.Complete &&
          step.type === DiagnosisRegimeDetailType.Treatment,
      );

      // this is in case a regime does not have a last step defined
      // calculate last step
      const lastStep = drdByStep[currentStepNumber + 1] === undefined;

      const isComplete =
        lastStep ||
        currentDetail.lastStep ||
        (answer === Answer.Yes && currentDetail.ifYesStep === 0) ||
        (answer === Answer.No && currentDetail.ifNoStep === 0);

      onStepChange &&
        onStepChange({
          answers: updatedAnswers,
          completed: isComplete,
          currentStepNumber: currentStepNumber,
          currentStepId: currentDetail.id,
          events: eventsToCreate,
          status: isComplete ? TaskStatus.Completed : TaskStatus.Pending,
        });

      if (isComplete) {
        setStepsComplete(true);

        return;
      }

      // determine which step to go to next
      const step = ((): number => {
        if (answer === Answer.Yes) {
          if (currentDetail.ifYesStep === undefined) {
            throw Error(t('diagnosisDecisionTree|regimeDetailConditionalButTrueStepDefinedError'));
          }
          return currentDetail.ifYesStep;
        } else if (answer === Answer.No) {
          if (currentDetail.ifNoStep === undefined) {
            throw Error(t('diagnosisDecisionTree|regimeDetailConditionalButFalseStepDefinedError'));
          }
          return currentDetail.ifNoStep;
        } else {
          return currentStepNumber + 1;
        }
      })();

      // get that step from our step index
      const nextDetail = drdByStep[step];
      addStepHistory(nextDetail);
      setCurrentStepNumber(step);

      onStepChange &&
        onStepChange({
          answers: updatedAnswers,
          completed: stepsComplete,
          currentStepNumber: step,
          currentStepId: nextDetail.id,
          events: eventsToCreate,
          status: stepsComplete ? TaskStatus.Completed : TaskStatus.Pending,
        });
    },
    [
      addStepHistory,
      answers,
      currentStepNumber,
      drdByStep,
      stepsComplete,
      stepHistory,
      onStepChange,
      treatmentProducts,
      t,
    ],
  );

  const fastForward = useCallback((): void => {
    // for resuming a decision tree

    const step = stepHistory[stepHistory.length - 1];
    const answer = answers[step.id]?.answer;

    if (answer && !stepsComplete) {
      nextStep(answer, true);
    } else {
      setIsFastForwarded(true);
      setNumSavedAnswers(Object.keys(answers).length);
    }
  }, [answers, nextStep, stepHistory, stepsComplete]);

  const previousStep = (): void => {
    // create a copy of step history
    const sh = [...stepHistory];

    // if answer length matches saved answer length then we can't go back anymore
    if (Object.keys(answers).length === numSavedAnswers) {
      return;
    }

    console.debug('previousStep');

    // pop the last step (current step) from our copy of step history
    const currentDetail = sh.pop();
    if (!currentDetail) {
      return;
    }

    // this is in case a regime does not have a last step defined
    // calculate last step
    const lastStep = drdByStep[currentDetail.step + 1] === undefined;

    if (
      (lastStep ||
        currentDetail.lastStep ||
        currentDetail.ifYesStep === 0 ||
        currentDetail.ifNoStep === 0) &&
      stepsComplete
    ) {
      setStepsComplete(false);

      const updatedAnswers = { ...answers };
      delete updatedAnswers[String(currentDetail.id)];

      const eventsToCreate = stepHistory.filter(
        (step) =>
          updatedAnswers[String(step.id)]?.answer === Answer.Complete &&
          step.type === DiagnosisRegimeDetailType.Treatment,
      );

      props.onStepChange &&
        props.onStepChange({
          answers: updatedAnswers,
          completed: stepsComplete,
          currentStepNumber: currentStepNumber,
          currentStepId: currentDetail.id,
          events: eventsToCreate,
          status: stepsComplete ? TaskStatus.Completed : TaskStatus.Pending,
        });

      // reset the answer for the current step
      setAnswers(updatedAnswers);
      return;
    }

    // get the last step from step history
    const previousDetail = sh[sh.length - 1];

    const updatedPreviousDetailAnswers = { ...answers };
    delete updatedPreviousDetailAnswers[String(previousDetail.id)];

    const eventsToCreate = stepHistory.filter(
      (step) =>
        updatedPreviousDetailAnswers[String(step.id)]?.answer === Answer.Complete &&
        step.type === DiagnosisRegimeDetailType.Treatment,
    );

    // reset the answer for the last step
    setAnswers(updatedPreviousDetailAnswers);

    // save the modified step history
    setStepHistory(sh);

    // update the current step number
    setCurrentStepNumber(previousDetail.step);
    props.onStepChange &&
      props.onStepChange({
        answers: updatedPreviousDetailAnswers,
        completed: stepsComplete,
        currentStepNumber: previousDetail.step,
        currentStepId: previousDetail.id,
        events: eventsToCreate,
        status: stepsComplete ? TaskStatus.Completed : TaskStatus.Pending,
      });
  };

  const sortField = 'step';
  const sortDirection = 'asc';

  /**
   * Reset component state if prop.diagnosisRegimeId prop changes
   * Note: This should precede all other useEffect's
   */
  useEffect(() => {
    console.debug('props.diagnosisRegimeId change');
    setAnswers({});
    setCurrentStepNumber(1);
    //setStepsCompleted([]);
    setStepsComplete(false);
    setDrdByStep({});
    setStepHistory([]);
    setIsFastForwarded(false);
  }, [props.diagnosisRegimeId]);

  const query = useQuery(
    [QueryKey.DiagnosisRegimeDetails, 'list', sortField, sortDirection, props.diagnosisRegimeId],
    () =>
      diagnosisRegimeDetailService.list({
        sortField: sortField,
        sortDirection: sortDirection,
        diagnosisRegimeId: String(props.diagnosisRegimeId),
      }),
    {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnReconnect: false,
    },
  );

  useEffect(() => {
    console.debug('query.data change');

    if (!query.data || query.data.length === 0) {
      return;
    }

    // diagnosis regime details are loaded

    // map details to a step index
    const details: Record<number, DiagnosisRegimeDetail> = {};
    query.data.forEach((drd) => {
      details[Number(drd.step)] = drd;
    });
    if (details[1] === undefined) {
      throw Error(t('There is no step 1'));
    }
    setStepHistory([details[1]]);
    setDrdByStep(details);
  }, [query.data, t]);

  const queryDiagnosisRegime = useQuery(
    [QueryKey.DiagnosisRegimes, 'get', props.diagnosisRegimeId],
    () => diagnosisRegimeService.get(props.diagnosisRegimeId),
    {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnReconnect: false,
    },
  );

  useEffect(() => {
    if (queryDiagnosisRegime.data) {
      setDiagnosisRegime(queryDiagnosisRegime.data);
    }
  }, [queryDiagnosisRegime.data]);

  const queryActiveTreatments = useQuery<ActiveTreatment | undefined>(
    [QueryKey.ActiveTreatments, 'get', props.activeTreatmentId],
    () => activeTreatmentService.get(props.activeTreatmentId ?? 0),
    {
      enabled: !!props.activeTreatmentId,
      refetchInterval: 300000,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    console.debug('queryActiveTreatments.data changed');
    const data = queryActiveTreatments.data;

    if (!data) {
      return;
    }
    if (data.statuses) {
      setAnswers(data.statuses);
    }
    setFastForwardTo(data.currentStep);
  }, [queryActiveTreatments.data]);

  const details = query.data;

  /**
   * If we have an active treatment then we should restore the decision tree
   * to the state as defined in the active treatment record
   */
  useEffect(() => {
    if (isFastForwarded) {
      return;
    }
    console.debug('....will fast forward');
    // get the Diagnosis Regime Detail (step) that we are currently on.
    // (Starts at 1)
    const drd = drdByStep[currentStepNumber];

    // if we can't load the current step or there is no fast-forward to value
    // then we definitely need to abort
    if (!drd || !fastForwardTo) {
      return;
    }

    if (fastForwardTo !== drd.id) {
      fastForward();
    }
  }, [fastForward, isFastForwarded, fastForwardTo, drdByStep, currentStepNumber]);

  if (query.isLoading) {
    return <TablePlaceholder />;
  }

  if (query.isError) {
    return <AlertError message={t('common|animalRetrievalError')} />;
  }

  if (!query.data || query.data.length === 0) {
    return (
      <Alert variant={'warning'}>
        <IconWarning />{' '}
        {t(`diagnosisDecisionTree|emptyRegimeWarning`, {
          value: queryDiagnosisRegime.data?.name,
        })}
      </Alert>
    );
  }

  if (!details) {
    return <span>{t('No data')}</span>;
  }

  const maxStepNumber = Math.max(...details.map((d) => d.step));

  return (
    <>
      <ProgressBar
        className={'mb-3'}
        max={maxStepNumber + 1}
        now={stepsComplete ? maxStepNumber + 1 : currentStepNumber}
      />
      {details &&
        stepHistory.map((detail, index) => {
          // is the step (drd) we are rendering the current step?
          const isCurrentStep = currentStepNumber === detail.step;

          // is the step we are rendering the step 2nd to last (n-1).
          const isPreviousStep = ((): boolean => {
            // if this is the current step it is obviously not the previous step
            if (isCurrentStep || stepHistory[stepHistory.length - 2] === undefined) {
              return false;
            }
            // is this step the second to last step in step history?
            return detail.step === stepHistory[stepHistory.length - 2].step;
          })();

          const disableIconActions = ((): boolean => {
            if (isCurrentStep) {
              return false;
            }
            if (isPreviousStep && !stepsComplete) {
              return false;
            }
            return true;
          })();

          // has this step been completed already?
          const isComplete = answers[String(detail.id)]?.answer === Answer.Complete;
          const isYes = answers[String(detail.id)]?.answer === Answer.Yes;
          const isNo = answers[String(detail.id)]?.answer === Answer.No;
          const isAnswered = answers[String(detail.id)]?.answer !== undefined;

          const productCustomName = answers[String(detail.id)]?.productCustomName;
          const productDosage = answers[String(detail.id)]?.productDosage;
          const _productRoute = answers[String(detail.id)]?.productRoute;
          const _productLocation = answers[String(detail.id)]?.productLocation;
          const completedDate = answers[String(detail.id)]?.date;

          return (
            <div key={index}>
              <p style={{ fontSize: '11px', display: 'none' }}>
                currentStepNumber: {currentStepNumber}
                <br />
                isCurrentStep: {isCurrentStep ? 'Yes' : 'No'}
                <br />
                isPreviousStep: {isPreviousStep ? 'Yes' : 'No'}
                <br />
                isComplete: {isComplete ? 'Yes' : 'No'}
                <br />
                isYes: {isYes ? 'Yes' : 'No'}
                <br />
                isNo: {isNo ? 'Yes' : 'No'}
                <br />
                isAnswered: {isAnswered ? 'Yes' : 'No'}
                <br />
              </p>
              <div className={'d-flex'} key={index}>
                <div className={'me-3 justify-content-center'} style={{ width: '45px' }}>
                  {/* if this step has a yes (1) answer */}
                  {isYes && (
                    <YesIcon
                      // when enabled clicking the icon will take the user back to the previous step
                      disabled={props.disabled || disableIconActions}
                      // this button is only available on the last completed step
                      onClick={(): void => previousStep()}
                    />
                  )}
                  {/* if this step has a no (2) answer */}
                  {isNo && (
                    <NoIcon
                      // when enabled clicking the icon will take the user back to the previous step
                      disabled={props.disabled || disableIconActions}
                      // this button is only available on the last completed step
                      onClick={(): void => previousStep()}
                    />
                  )}
                  {/* if this step has a complete (3) answer */}
                  {isComplete && (
                    <CompleteIcon
                      // when enabled clicking the icon will take the user back to the previous step
                      disabled={props.disabled || disableIconActions}
                      // this button is only available on the last completed step
                      onClick={(): void => previousStep()}
                    />
                  )}
                  {/* Show the incomplete icon if this is the current step */}
                  {isCurrentStep && !isAnswered && (
                    <IncompleteIcon
                      // go to the next step if enabled
                      disabled={props.disabled || !!detail.ifYesStep || !!detail.ifNoStep}
                    />
                  )}
                </div>
                <div className={'me-3'}>
                  <strong className={'text-muted'} style={{ fontSize: '18px' }}>
                    <h3>{detail.step}.</h3>
                  </strong>
                </div>
                <div className={'flex-grow-1 w-100'}>
                  {detail.description}
                  {productDosage && (
                    <strong className={'text-muted'}>
                      <br />
                      Treated with <strong>{productDosage}</strong> mL of{' '}
                      <strong>{productCustomName}</strong> on{' '}
                      <strong>
                        <DateTime date={completedDate} hideTime={true} />{' '}
                      </strong>
                    </strong>
                  )}
                  {!detail.ifYesStep && !detail.ifNoStep && detail.datePlus !== undefined && (
                    <small>
                      <br />
                      {t('Date:')}{' '}
                      <strong>
                        <DateTime date={addDays(new Date(), detail.datePlus)} hideTime={true} />
                      </strong>
                    </small>
                  )}
                </div>
                {!disableIconActions && isAnswered && (
                  <div>
                    <Button
                      icon={IconUndo}
                      onClick={(): void => previousStep()}
                      variant={ButtonVariant.OutlinePrimary}
                    >
                      {t('Undo')}
                    </Button>
                  </div>
                )}
              </div>
              {isCurrentStep && (
                <div className={'d-flex my-3 justify-content-around'}>
                  {(detail.ifYesStep || detail.ifNoStep) && !isAnswered && (
                    <>
                      <div>
                        <ToggleButton
                          checked={answers[String(detail.id)]?.answer === Answer.Yes}
                          disabled={!isCurrentStep}
                          id={`dt-${detail.id}-condition-yes`}
                          name={'condition'}
                          onClick={(): void => nextStep(Answer.Yes)}
                          size={isCurrentStep ? 'lg' : 'sm'}
                          type={'radio'}
                          value={'1'}
                          variant={'outline-success'}
                        >
                          <IconThumbsUp className={'me-2'} />
                          {t('Yes')}
                        </ToggleButton>
                      </div>
                      <div>
                        <ToggleButton
                          checked={answers[String(detail.id)]?.answer === Answer.No}
                          disabled={!isCurrentStep}
                          id={`dt-${detail.id}-condition-no`}
                          name={'condition'}
                          onClick={(): void => nextStep(Answer.No)}
                          size={isCurrentStep ? 'lg' : 'sm'}
                          type={'radio'}
                          value={'2'}
                          variant={'outline-danger'}
                        >
                          <IconThumbsDown className={'me-2'} />
                          {t('No')}
                        </ToggleButton>
                      </div>
                    </>
                  )}
                  {detail.type === DiagnosisRegimeDetailType.Treatment && !isAnswered && (
                    <div className={'w-100 ms-5'}>
                      <DiagnosisTreatWithProduct
                        animalWeight={props.animalWeight}
                        diagnosisId={Number(diagnosisRegime?.diagnosisId)}
                        diagnosisRegimeDetailId={detail.id}
                        isCalf={props.isCalf}
                        onProductChange={(productChange): void => {
                          // we need to store the product change by step id
                          const newTreatmentProducts = { ...treatmentProducts };
                          newTreatmentProducts[detail.id] = productChange;
                          console.debug(
                            'DiagnosisDecisionTree',
                            detail.id,
                            Number(detail.id),
                            newTreatmentProducts,
                          );

                          setTreatmentProducts(newTreatmentProducts);
                        }}
                      />
                      <div className={'text-center'}>
                        <p>
                          Press the button below to indicate that you have treated this animal with
                          the selected product.
                        </p>
                        <Button
                          disabled={!isCurrentStep}
                          icon={IconComplete}
                          onClick={(): void => nextStep(Answer.Complete)}
                          size={'lg'}
                          variant={ButtonVariant.OutlinePrimary}
                        >
                          {t('Complete')}
                        </Button>
                        <p className={'text-muted mt-3'}>
                          {t('diagnosisDecisionTree|youCanResumeLaterText')}
                        </p>
                      </div>
                    </div>
                  )}
                  {detail.type === DiagnosisRegimeDetailType.WaitAndSee && !isAnswered && (
                    <div className={'w-100 ms-5'}>
                      <div className={'text-center'}>
                        <p>{t('diagnosisDecisionTree|waitAndSeeStepDescription')}</p>
                        <Button
                          disabled={!isCurrentStep}
                          icon={IconComplete}
                          onClick={(): void => nextStep(Answer.Complete)}
                          size={'lg'}
                          variant={ButtonVariant.OutlinePrimary}
                        >
                          {t('Complete')}
                        </Button>
                        <p className={'text-muted mt-3'}>
                          {t('diagnosisDecisionTree|youCanResumeLaterText')}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <hr />
            </div>
          );
        })}
      {stepsComplete && (
        <Alert className={'d-flex align-items-center justify-content-start'} variant={'success'}>
          <RiCheckboxCircleFill
            className={'text-success me-3 align-self-start'}
            style={{ fontSize: '48px' }}
          />
          <div>
            <p> {t('All done!')}</p>
          </div>
        </Alert>
      )}
    </>
  );
};

export default DiagnosisDecisionTree;
