// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { withTranslation } from 'react-i18next';

import ButtonCreate from '../../common/components/ButtonCreate';
import Page from '../../common/components/Page';
import TestDataTable from './TestDataTable';

/**
 * Routable component to list events.
 */
const TestDataPage = (): JSX.Element => {
  const filters: Record<string, string> = {};
  filters.order = '-testDate';

  return (
    <Page
      buttons={
        <ButtonCreate
          disabled
          label={'New Test Data'}
          onClick={(): void => {
            return;
          }}
        />
      }
      title={'Milk'}
    >
      <TestDataTable filters={filters} />
    </Page>
  );
};

export default withTranslation()(TestDataPage);
