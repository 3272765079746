// Copyright © 2024 CATTLEytics Inc.

import { groupBy } from 'lodash';

import { GroupedOption } from '../../../../common/components/GroupedCheckboxDropdown';
import { AnimalEventType } from '../../../../shared';

// Copyright © 2024 CATTLEytics Inc.
export function groupEventTypes(eventTypes: AnimalEventType[]): GroupedOption[] {
  const groups = Object.entries(
    groupBy(eventTypes, (item) => {
      return item.animalEventParentId;
    }),
  )
    .map((entry) => {
      const parentObject = entry[1][0].animalEventParent;
      if (parentObject === undefined || parentObject === null) {
        return null;
      }
      return {
        id: parentObject.id,
        label: parentObject.parentName,
        items: entry[1].map((item) => ({ id: item.id, label: item.name })),
      } as GroupedOption;
    })
    .filter((group) => group !== null) as GroupedOption[];

  return groups;
}
