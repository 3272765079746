// Copyright © 2023 CATTLEytics Inc.

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import ButtonCreate from '../../common/components/ButtonCreate';
import Page from '../../common/components/Page';
import HealthCheckModal from './HealthCheckModal';
import HealthCheckTable from './HealthCheckTable';

/**
 * Health check page.
 */
const HealthChecksPage = (): JSX.Element => {
  const { t } = useTranslation();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const filters: Record<string, string | undefined> = {
    pen: searchParams.get('pen') ? (searchParams.get('pen') as string) : undefined,
  };
  const [healthCheckModalVisible, setHealthCheckModalVisible] = useState<boolean>(false);

  return (
    <Page
      buttons={
        <ButtonCreate label={t('Create')} onClick={(): void => setHealthCheckModalVisible(true)} />
      }
      title={t('Health Checks')}
    >
      <HealthCheckTable filters={filters} />

      {healthCheckModalVisible && (
        <HealthCheckModal onClose={(): void => setHealthCheckModalVisible(false)} />
      )}
    </Page>
  );
};

export default HealthChecksPage;
