// Copyright © 2023 CATTLEytics Inc.

import { useQuery } from 'react-query';

import { api } from '../../../../common/utilities';
import { AnimalEventType, ApiResourceV1, QueryKey } from '../../../../shared';

type Props = {
  animalId: string;
};

type Return = {
  eventTypes: AnimalEventType[] | undefined;
  isLoading: boolean;
};

export const useGetEventTypesByAnimal = ({ animalId }: Props): Return => {
  const { data, isLoading } = useQuery<AnimalEventType[]>(
    [QueryKey.AnimalEventTypes, String(animalId)],
    () =>
      api('GET', ApiResourceV1.AnimalEventTypes, {
        params: {
          sortField: 'name',
          sortDirection: 'ASC',
          limit: '1000',
        },
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: true,
    },
  );

  return { eventTypes: data, isLoading } as const;
};
