// Copyright © 2023 CATTLEytics Inc.
import { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

import { dateFormat } from '../../shared';

export type DatePickerProps<
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined,
> = ReactDatePickerProps<CustomModifierNames, WithRange> & {
  validated?: boolean;
};

const DatePicker = <
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined,
>({
  selected,
  required,
  wrapperClassName,
  validated,
  ...props
}: DatePickerProps<CustomModifierNames, WithRange>): JSX.Element => {
  const isInvalid = useMemo(
    () => !!validated && required && !selected,
    [required, selected, validated],
  );

  const wrapperClasses = useMemo(
    () => `${wrapperClassName ?? ''} ${isInvalid ? 'is-invalid' : ''}`,
    [wrapperClassName, isInvalid],
  );

  return (
    <ReactDatePicker
      customInput={<Form.Control />}
      dateFormat={dateFormat}
      popperClassName="z-3"
      required={required}
      scrollableYearDropdown
      selected={selected}
      showPopperArrow={false}
      showYearDropdown
      wrapperClassName={wrapperClasses}
      {...props}
    />
  );
};

export default DatePicker;
