// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import Button, { ButtonVariant } from '../../common/components/Button';
import DataTable, { DataTableHeader, DataTableRow } from '../../common/components/DataTable';
import { Sort } from '../../common/enums';
import PenService from '../../common/services/penService';
import { IconView } from '../../common/utilities';
import { QueryKey } from '../../shared';
import { Pen } from '../../shared';

interface Props {
  /**
   * Key/value pairs to filter pen data table
   */
  filters?: Record<string, string | undefined>;

  /**
   * Number of events to show.
   */
  initialLimit?: number;

  /**
   * Number of events to skip.
   */
  initialOffset?: number;
}

/**
 * A filterable list of pens.
 */
const PenTable = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();
  const penService = useInjection<PenService>(TYPES.penService);
  const history = useHistory();

  const [limit, setLimit] = useState<number>(props.initialLimit ?? 25);
  const [offset, setOffset] = useState<number>(props.initialOffset ?? 0);
  const [sortField, setSortField] = useState<string>('name');
  const [sortDirection, setSortDirection] = useState<Sort>(Sort.Ascending);

  const cleanFilters: Record<string, string> = {};

  if (props.filters !== undefined) {
    Object.keys(props.filters).forEach((key) => {
      const propsFilters = props.filters as Record<string, string>;
      cleanFilters[key] = propsFilters[key] ?? '';
    });
  }

  const query = useQuery<Pen[]>(
    [QueryKey.Pens, limit, offset, sortField, sortDirection, cleanFilters],
    () =>
      penService.list({
        ...cleanFilters,
        ...{
          limit: String(limit),
          offset: String(offset),
          sortField: sortField,
          sortDirection: String(sortDirection),
        },
      }),
    { keepPreviousData: true },
  );

  if (query.isError) {
    return <AlertError message={'Something unexpected happened while retrieving pens.'} />;
  }

  const headers: DataTableHeader[] = [
    {
      name: 'name',
      label: t('penTable|nameLabel'),
      infoTooltip: t('penTable|nameInfoTooltip'),
      classNameMobile: 'col-12',
    },
    {
      name: 'description',
      label: t('penTable|descriptionLabel'),
      infoTooltip: t('penTable|descriptionInfoTooltip'),
      classNameMobile: 'col-12',
    },
    {
      name: 'totalAnimals',
      label: t('penTable|totalAnimalsLabel'),
      infoTooltip: t('penTable|totalAnimalsInfoTooltip'),
      classNameMobile: 'col-4',
    },
    {
      name: 'capacity',
      label: t('penTable|capacityLabel'),
      infoTooltip: t('penTable|capacityInfoTooltip'),
      classNameMobile: 'col-4',
    },
    {
      name: 'totalPregnant',
      label: t('penTable|totalPregnantLabel'),
      infoTooltip: t('penTable|totalPregnantInfoTooltip'),
      classNameMobile: 'col-4',
    },
    {
      name: 'totalOpen',
      label: t('penTable|totalOpenLabel'),
      infoTooltip: t('penTable|totalOpenInfoTooltip'),
      classNameMobile: 'col-4',
    },
    {
      name: 'totalDriedOff',
      label: t('penTable|totalDriedOffLabel'),
      infoTooltip: t('penTable|totalDriedOffInfoTooltip'),
      classNameMobile: 'col-4',
    },
    {
      name: 'averageDaysInMilk',
      label: t('penTable|averageDaysInMilkLabel'),
      infoTooltip: t('penTable|averageDaysInMilkInfoTooltip'),
      classNameMobile: 'col-4',
    },
    {
      name: 'totalLactationGroup1',
      label: t('penTable|totalLactationGroup1Label'),
      infoTooltip: t('penTable|totalLactationGroup1InfoTooltip'),
      classNameMobile: 'col-4',
    },
    {
      name: 'totalLactationGroup2',
      label: t('penTable|totalLactationGroup2Label'),
      infoTooltip: t('penTable|totalLactationGroup2InfoTooltip'),
      classNameMobile: 'col-4',
    },
    {
      name: 'totalLactationGroup3Plus',
      label: t('penTable|totalLactationGroup3PlusLabel'),
      infoTooltip: t('penTable|totalLactationGroup3PlusInfoTooltip'),
      classNameMobile: 'col-4',
    },

    { name: 'actions', sortable: false, label: t('penTable|actionsLabel'), hideMobile: true },
  ];

  const data: DataTableRow[] = !query.data
    ? []
    : query.data.map((pen) => ({
        id: String(pen.id),
        capacity: pen.capacity ? String(pen.capacity) : '-',
        name: pen.name,
        description: pen.description,
        totalAnimals: String(pen.totalAnimals ?? '-'),
        totalPregnant: String(pen.totalPregnant ?? '-'),
        totalOpen: String(pen.totalOpen ?? '-'),
        totalDried: String(pen.totalDried ?? '-'),
        averageDaysInMilk: String(pen.averageDaysInMilk ?? '-'),
        totalLactationGroup1: String(pen.totalLactationGroup1 ?? '-'),
        totalLactationGroup2: String(pen.totalLactationGroup2 ?? '-'),
        totalLactationGroup3Plus: String(pen.totalLactationGroup3Plus ?? '-'),
        actions: (
          <Link to={`/pens/${pen.id}`}>
            <Button className={'text-nowrap'} size={'sm'} variant={ButtonVariant.OutlinePrimary}>
              <IconView className={'me-1'} />
              {t('View Animals')}
            </Button>
          </Link>
        ),
      }));

  return (
    <DataTable
      className={'mt-3'}
      data={data}
      headers={headers}
      isLoading={query.isLoading}
      isPreviousData={query.isPreviousData}
      limit={limit}
      messageNoData={t('penTable|noPenMessage')}
      offset={offset}
      onLimitChange={(newLimit): void => setLimit(newLimit)}
      onOffsetChange={(newOffset): void => setOffset(newOffset)}
      onRowClick={(row): void => history.push(`/pens/${row.id}`)}
      onSortDirectionChange={(newSortDirection): void => setSortDirection(newSortDirection)}
      onSortFieldChange={(newSortField): void => setSortField(newSortField)}
      sortDirection={sortDirection}
      sortField={sortField}
    />
  );
};

export default PenTable;
