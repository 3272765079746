// Copyright © 2023 CATTLEytics Inc.

import { ButtonProps } from 'react-bootstrap';

import Button from '../../common/components/Button';
import { IconAddCircle } from '../utilities';

/**
 * Defines the input properties the this component.
 */
interface Props extends Pick<ButtonProps, 'size' | 'disabled' | 'onClick' | 'style'> {
  /**
   * Button text.
   */
  label?: string;
}

/**
 * A large create button.
 */
const ButtonCreate = (props: Props): JSX.Element => {
  return (
    <Button
      className={'text-nowrap'}
      disabled={props.disabled}
      onClick={props.onClick}
      size={props.size ?? 'lg'}
      style={{ display: 'flex', alignItems: 'center', margin: '0 auto', ...props.style }}
      variant={'primary'}
    >
      <IconAddCircle className={'me-1'} style={{ height: '100%' }} />
      {props.label ?? 'Create'}
    </Button>
  );
};

export default ButtonCreate;
