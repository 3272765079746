// Copyright © 2024 CATTLEytics Inc.

import { PropsWithChildren, useState } from 'react';
import { Form } from 'react-bootstrap';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { AnimalGroup, ApiResourceV1 } from '../../shared';
import { QueryKey } from '../../shared';
import { api } from '../utilities';

interface AutoCompleteProps {
  /**
   * Loading indicator.
   */
  busy?: boolean;

  /**
   * HTML ID attribute
   */
  id: string;

  /**
   * Whether to allow multiple group selection.
   *
   * Note: this also changes the parameters sent in the onSelect callback from
   * single item to array of items.
   */
  multiple?: boolean;

  /**
   * HTML name attribute
   */
  name?: string;

  /**
   * Set the typeahead placeholder text.
   */
  placeholder?: string;

  /**
   * If this field is required.
   */
  required?: boolean;

  /**
   * Initial selected option or options.
   */
  selected?: Option | Option[];

  /**
   * Whether the form this component is in has been validated.
   * This will show invalid messages if applicable.
   * Note this does not indicate a form is valid.
   */
  validated?: boolean;
}

/**
 * Component properties
 */
interface Props extends AutoCompleteProps {
  /**
   * Callback after animal group has been selected
   * @param AnimalGroup
   */
  onSelect: (animalGroup?: AnimalGroup | AnimalGroup[]) => void;
}

/**
 * Provides ability to select an animal group by name or ID.
 */
const AnimalGroupAutocomplete = (props: PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState<string>(
    props.selected ? String(props.selected) : '',
  );

  const limit = 10;

  const { data: animalGroups, isLoading: busy } = useQuery<AnimalGroup[]>(
    [QueryKey.AnimalGroups, searchQuery, limit],
    () =>
      api('GET', `${ApiResourceV1.AnimalGroups}`, {
        params: { search: searchQuery, limit: String(limit) },
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  // if selected property is not set then empty array,
  // else if selected property is an array use it,
  // else make selected property an array
  const selected = !props.selected
    ? []
    : Array.isArray(props.selected)
    ? props.selected
    : [props.selected];

  const required = props.required ?? false;

  // if the validated property is set and true (form validation was run),
  // and the field is required and the selection is empty
  const isInvalid = !!props.validated && required && selected.length === 0;

  // if the validated property is set and true (form validation was run),
  // and the field is not isInvalid
  const isValid = !!props.validated && !isInvalid;

  // The following input element is a way to trigger a javascript invalid
  // state so HTML5 form validation can be used since the input element
  // inside the typeahead component does not support this
  // if one or more items have been selected this input element will be
  // set to 1 thus satisfying the required validator
  const inputHelper = (
    <input
      aria-hidden={true}
      readOnly={true}
      required={required}
      style={{ display: 'none' }}
      tabIndex={-1}
      type={'text'}
      value={selected.length === 0 ? '' : '1'}
    />
  );

  return (
    <div className={`animal-group-autocomplete ${isInvalid ? 'is-invalid' : 'is-valid'}`}>
      {inputHelper}
      <AsyncTypeahead
        clearButton={true}
        id={props.id} // for styling only
        inputProps={{ required: isInvalid }}
        isInvalid={isInvalid}
        isLoading={busy || !!props.busy}
        isValid={isValid}
        labelKey={(option): string => {
          const animalGroup = option as AnimalGroup;
          return animalGroup.name;
        }}
        minLength={0}
        multiple={props.multiple}
        onChange={(selected): void => {
          const items =
            Array.isArray(selected) && selected.length > 0
              ? props.multiple
                ? (selected as AnimalGroup[])
                : (selected[0] as AnimalGroup)
              : undefined;

          props.onSelect(items);
        }}
        onSearch={(searchQuery: string): void => {
          setSearchQuery(searchQuery);
        }}
        options={animalGroups ?? []}
        placeholder={props.placeholder ?? t('animalGroupAutocomplete|placeholder')}
        renderMenuItemChildren={(option, { text }): JSX.Element => {
          const animalGroup = option as AnimalGroup;
          const label = animalGroup.name;

          return (
            <div
              className={'d-flex flex-row gap-4 justify-space-between align-items-center'}
              style={{ borderBottom: '1px #ccc solid', paddingBottom: '8px' }}
            >
              <div className={'d-block w-auto'}>
                <Highlighter search={text}>{label}</Highlighter>
                <p className={'text-muted'}>
                  <small>{animalGroup.description ?? '-'}</small>
                </p>
              </div>
            </div>
          );
        }}
        selected={selected}
      />
      <Form.Control.Feedback type={'invalid'}>
        {t('animalGroupAutocomplete|feedbackInvalid')}
      </Form.Control.Feedback>
    </div>
  );
};

export default AnimalGroupAutocomplete;
