// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useEffect, useState } from 'react';
import { Alert, Placeholder, PlaceholderButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { TYPES } from '../../../types';
import ButtonCreate from '../../common/components/ButtonCreate';
import Page from '../../common/components/Page';
import DiagnosisRegimeDetailService from '../../common/services/diagnosisRegimeDetailService';
import DiagnosisRegimeService from '../../common/services/diagnosisRegimeService';
import { IconWarning } from '../../common/utilities';
import { DiagnosisRegimeDetailType } from '../../shared';
import { QueryKey } from '../../shared/enums';
import DiagnosisRegimeDetailModal from './DiagnosisRegimeDetailModal';
import DiagnosisRegimeDetailTable from './DiagnosisRegimeDetailTable';

interface RouteParams {
  /**
   * The diagnosis regime identifier.
   */
  diagnosisRegimeId: string;
}

const DiagnosisRegimeManagePage = (): JSX.Element => {
  const { t } = useTranslation();

  const { diagnosisRegimeId: regimeId } = useParams<RouteParams>();

  const diagnosisRegimeService = useInjection<DiagnosisRegimeService>(TYPES.diagnosisRegimeService);
  const diagnosisRegimeDetailService = useInjection<DiagnosisRegimeDetailService>(
    TYPES.diagnosisRegimeDetailService,
  );

  const [diagnosisRegimeStepModalVisible, setDiagnosisRegimeStepModalVisible] =
    useState<boolean>(false);

  const [hasLastStep, setHasLastStep] = useState<boolean>(false);
  const [hasTreatments, setHasTreatments] = useState<boolean>(false);
  const [hasConditionalLastStep, setHasConditionalLastStep] = useState<boolean>(false);

  const queryDiagnosisRegime = useQuery(
    [QueryKey.DiagnosisRegimes, regimeId],
    () => diagnosisRegimeService.get(parseInt(regimeId)),
    { refetchInterval: 300000, refetchOnWindowFocus: false },
  );

  const queryDiagnosisRegimeDetailList = useQuery(
    [QueryKey.DiagnosisRegimeDetails, regimeId],
    () =>
      diagnosisRegimeDetailService.list({
        diagnosisRegimeId: String(regimeId),
      }),
    { keepPreviousData: true },
  );

  useEffect(() => {
    if (queryDiagnosisRegimeDetailList.data) {
      const data = queryDiagnosisRegimeDetailList.data ?? [];

      const lastSteps = data.filter((step) => step.lastStep);
      setHasLastStep(lastSteps.length > 0);

      const treatments = data.filter((step) => step.type === DiagnosisRegimeDetailType.Treatment);
      setHasTreatments(treatments.length > 0);

      if (data.length > 0) {
        const lastStep = data[data.length - 1];
        setHasConditionalLastStep(lastStep.type === DiagnosisRegimeDetailType.Conditional);
      }
    }
  }, [queryDiagnosisRegimeDetailList.data]);

  return (
    <Page
      breadcrumbs={[{ label: t('Diagnosis Regimes'), to: '/diagnosis-regimes' }]}
      buttons={
        queryDiagnosisRegime.isLoading ? (
          <PlaceholderButton style={{ width: '8rem' }} xs={2} />
        ) : (
          <ButtonCreate
            label={t('Add step')}
            onClick={(): void => {
              setDiagnosisRegimeStepModalVisible(true);
            }}
          />
        )
      }
      title={queryDiagnosisRegime.data ? queryDiagnosisRegime.data?.name : ''}
      titleLoading={queryDiagnosisRegime.isLoading}
      titlePlaceholder={
        <Placeholder animation={'glow'}>
          <Placeholder size={'lg'} style={{ width: '12rem' }} />
        </Placeholder>
      }
    >
      {!hasLastStep && (
        <Alert variant={'warning'}>
          <IconWarning /> {t('diagnosisRegimeManagePage|regimeLacksLastStepWarning')}
        </Alert>
      )}
      {!hasTreatments && (
        <Alert variant={'warning'}>
          <IconWarning /> {t('diagnosisRegimeManagePage|regimeLacksTreatmentsWarning')}
        </Alert>
      )}
      {hasConditionalLastStep && (
        <Alert variant={'warning'}>
          <IconWarning /> {t('diagnosisRegimeManagePage|regimeLastStepOptionalWarning')}
        </Alert>
      )}
      <DiagnosisRegimeDetailTable diagnosisRegimeId={Number(regimeId)} />
      {diagnosisRegimeStepModalVisible && (
        <DiagnosisRegimeDetailModal
          diagnosisRegimeId={parseInt(regimeId)}
          onClose={(): void => setDiagnosisRegimeStepModalVisible(false)}
        />
      )}
    </Page>
  );
};

export default DiagnosisRegimeManagePage;
