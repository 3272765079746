// Copyright © 2023 CATTLEytics Inc.

import React from 'react';

import { SettingsAnimals, SettingsLocalization } from '../entities/setting';

// @TODO rename to SettingsLocalizationContext
const SettingsContext = React.createContext<SettingsLocalization>({
  dateFormat: '',
  weightUnit: '',
  temperatureUnit: '',
  heightUnit: '',
  defaultUnits: '',
  language: '',
  timeFormat: '',
  timeZone: '',
  localTz: '',
  siteTz: '',
  preferredDateTimeStyle: 'absolute',
});

export const SettingsAnimalsContext = React.createContext<SettingsAnimals>({
  typicalWeight: '680',
  // TODO: add appropriate default value
  typicalCalfWeight: '500',
});

// @TODO rename to SettingsLocalizationContext
export default SettingsContext;
