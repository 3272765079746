// Copyright © 2023 CATTLEytics Inc.

import JsonApiPagination from './jsonApiPagination';

/**
 * @deprecated
 * @description Interface for JSON API parameters.
 * @export
 * @interface JsonApiQueryParams
 */
export default interface JsonApiQueryParams {
  /**
   * @description Indexer property
   * @memberof JsonApiQueryParams
   */
  [key: string]:
    | JsonApiPagination
    | string
    | string[]
    | Record<string, string>
    | Record<string, string[]>
    | undefined;

  /**
   * @description List of fields that should be returned for
   *  a given object.
   * @type {Record<string, string[]>}
   * @memberof JsonApiQueryParams
   */
  fields?: Record<string, string[]>;

  /**
   * @description The filters to pass to the JSON API route.
   * @type {Record<string, string>}
   * @memberOf JsonApiQueryParams
   */
  filter?: Record<string, string>;

  /**
   * @description The related data to include in the response.
   * @type {string[]}
   * @memberOf JsonApiQueryParams
   */
  include?: string[];

  /**
   * @description The page that should be returned from the API.
   * @type {JsonApiPagination}
   * @memberOf JsonApiQueryParams
   */
  page?: JsonApiPagination;

  /**
   * @description An array of fields to sort on. Use '-' to indicate direction.
   * @example ['createdDate'] or ['-modifiedDate','author']
   * @type {string[]}
   * @memberOf JsonApiQueryParams
   */
  sort?: string[];
}

/**
 * @description Determines if a parameter is a jsonApiQueryParams interface.
 * @const
 * @param {any} params
 * @return {boolean}
 */
export const isJsonApiQueryParams = (params: any): params is JsonApiQueryParams =>
  Object.keys(params).filter((key) => ['filter', 'include', 'page', 'sort', 'fields'].includes(key))
    .length > 0;
