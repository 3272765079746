// Copyright © 2023 CATTLEytics Inc.

import React, { FunctionComponent } from 'react';
import { Alert } from 'react-bootstrap';
import { FallbackProps } from 'react-error-boundary';

import Button from '../../common/components/Button';
import Page from '../../common/components/Page';

/**
 * Routable component for handling errors
 */
const ErrorPage: FunctionComponent<FallbackProps> = (props: FallbackProps): JSX.Element => {
  return (
    <Page title="Whoops!">
      <Alert variant={'danger'}>
        <p>
          <strong>Something went wrong.</strong>
        </p>
        <p>{props.error.message}</p>
        <p>
          <Button onClick={props.resetErrorBoundary} variant={'outline-danger'}>
            Try again
          </Button>
        </p>
      </Alert>
    </Page>
  );
};

export default ErrorPage;
