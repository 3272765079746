// Copyright © 2023 CATTLEytics Inc.

import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import React, { CSSProperties } from 'react';
import { Line } from 'react-chartjs-2';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props extends WithTranslation {
  className?: string;

  dataSetLabel?: string;

  labels: string[];

  style?: CSSProperties;

  values: string[];
}

/**
 * A chart of total births last year
 */
const ChartLineSimpleTiny = (props: React.PropsWithChildren<Props>): JSX.Element => {
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      y: {
        scaleLabel: {
          display: true,
        },
        ticks: {
          display: true,
        },
      },
      x: {
        scaleLabel: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  const data = {
    labels: props.labels,
    datasets: [
      {
        yAxisID: 'y',
        xAxisID: 'x',
        label: props.dataSetLabel,
        data: props.values,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };
  return <Line data={data} options={options} />;
};

export default withTranslation()(ChartLineSimpleTiny);
