// Copyright © 2023 CATTLEytics Inc.

import React, { Fragment, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { IconType } from 'react-icons';

import { IconCancel, IconCheck } from '../utilities';
import AlertErrorForModal from './AlertErrorForModal';
import Button, { ButtonVariant } from './Button';
import Modal, { Props as ModalProps } from './Modal';

/**
 * Properties for the @see Modal component.
 */
interface Props extends ModalProps {
  /**
   * The busy status of this component.
   */
  busy?: boolean;

  /**
   * Optional react-icon to prepend to the button text
   */
  cancelIcon?: IconType;

  /**
   * The label for the cancel button
   */
  cancelLabel?: string;

  /**
   * Callback when modal's Cancel button is clicked
   */
  cancelOnClick?: () => void;

  /**
   * The variant property for the Cancel button
   */
  cancelVariant?: ButtonVariant;

  /**
   * A message to display in an alert describing an error condition.
   */
  errorMessage?: string;

  /**
   * Optional react-icon to prepend to the button text
   */
  okIcon?: IconType;

  /**
   * The label for the Ok button
   */
  okLabel?: string;

  /**
   * Callback when modal's OK button is clicked
   */
  okOnClick?: () => void;

  /**
   * The variant property for the Ok button
   */
  okVariant?: ButtonVariant;
}

/**
 * Simple modal dialog.
 */
const ConfirmModal = (props: PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const cancelIcon = props.cancelIcon ? props.cancelIcon : IconCancel;
  const okIcon = props.okIcon ? props.okIcon : IconCheck;

  const okLabel = props.okLabel ? props.okLabel : t('OK');

  const cancelLabel = props.cancelLabel ? props.cancelLabel : t('Cancel');
  const okVariant = props.okVariant ? props.okVariant : ButtonVariant.Primary;
  const cancelVariant = props.cancelVariant ? props.cancelVariant : ButtonVariant.Secondary;

  return (
    <Modal
      footer={
        <Fragment>
          <Button
            className={'w-100 w-auto-sm'}
            icon={cancelIcon}
            label={cancelLabel}
            onClick={props.cancelOnClick}
            variant={cancelVariant}
          />
          <Button
            busy={props.busy}
            className={'w-100 w-auto-sm'}
            icon={okIcon}
            label={okLabel}
            onClick={props.okOnClick}
            variant={okVariant}
          />
        </Fragment>
      }
      onClose={props.onClose ?? props.cancelOnClick}
      size={'lg'}
      title={props.title}
      visible={props.visible}
    >
      {props.children}
      <AlertErrorForModal message={props.errorMessage} />
    </Modal>
  );
};

export default ConfirmModal;
