// Copyright © 2023 CATTLEytics Inc.

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const reverseString = (str: string): string => str.split('').reverse().join('');

export const capitalizeWords = (str: string): string =>
  str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
