// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { Form } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';

import { RoleEnum } from '../../shared';

/**
 * Component input properties.
 */
interface Props extends WithTranslation {
  /**
   * Accessibility label
   */
  ariaLabel?: string;

  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Form element name
   */
  name?: string;

  /**
   * Input change handler.
   * @param role
   */
  onChange: (role: RoleEnum) => void;

  /**
   * When add modal is closed
   */
  onCloseAddModal?: () => void;

  /**
   * Whether this field is required.
   */
  required?: boolean;

  /**
   * Input value.
   */
  value: string;
}

/**
 * Form select component for roles.
 */
const RoleSelect = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const items = [
    { value: 'user', label: 'User' },
    { value: 'site-admin', label: 'Site Administrator' },
  ];
  const value = props.value ?? undefined;

  return (
    <>
      <Form.Select
        aria-label={props.ariaLabel ?? 'Role'}
        className={props.className}
        name={props.name ?? 'role'}
        onChange={(e): void => props.onChange(e.target.value as RoleEnum)}
        required={props.required}
        value={value}
      >
        {items &&
          items.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
      </Form.Select>
      {props.required && (
        <Form.Control.Feedback type={'invalid'}>This field is required</Form.Control.Feedback>
      )}
    </>
  );
};

export default withTranslation()(RoleSelect);
