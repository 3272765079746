// Copyright © 2023 CATTLEytics Inc.

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useQuery } from 'react-query';

import Spinner from '../../common/components/Spinner';
import { api } from '../../common/utilities/api';
import { ReportInventory } from '../../shared';
import { QueryKey } from '../../shared/enums';

const MethaneChart = (): JSX.Element => {
  const [data, setData] = useState<ChartData<'bar'>>();

  const query = useQuery<Required<ReportInventory>[]>([QueryKey.ReportInventory], () =>
    api<Required<ReportInventory>[]>('GET', '/v1/report-inventory'),
  );

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Methane',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        min: 0,
        stacked: true,
      },
    },
  };

  useEffect(() => {
    if (!query.data) {
      return;
    }

    const labels: string[] = [];
    const methaneLactating: number[] = [];
    const methaneDry: number[] = [];
    const methaneHeifer: number[] = [];

    for (const row of query.data) {
      methaneLactating.push(row.methaneLactating);
      methaneDry.push(row.methaneDry);
      methaneHeifer.push(row.methaneHeifer);
      labels.push(row.date);
    }

    setData({
      labels,
      datasets: [
        {
          label: 'Methane Lactating',
          data: methaneLactating,
          backgroundColor: 'rgb(255, 99, 132)',
        },
        {
          label: 'Methane Dry',
          data: methaneDry,
          backgroundColor: 'rgb(75, 192, 192)',
        },
        {
          label: 'Methane Heifer',
          data: methaneHeifer,
          backgroundColor: 'rgb(53, 162, 235)',
        },
      ],
    });
  }, [query.data]);

  return (
    <>
      {!data && <Spinner />}
      {data && <Bar data={data} options={options} />}
    </>
  );
};

export default MethaneChart;
