// Copyright © 2023 CATTLEytics Inc.

export interface DecisionTreeAnswers {
  /**
   * Database saves this JSON key as string so we may as well not fight it.
   */
  [key: string]: {
    answer: DecisionTreeAnswer;
    date?: Date;
    drdId?: number;
    productCustomName?: string;
    productDosage?: number;
    productFactor?: number;
    productId?: number;
    productLocation?: string;
    productRoute?: string;
  };
}

export enum DecisionTreeAnswer {
  Yes = 1,
  No = 2,
  Complete = 3,
}
