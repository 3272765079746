// Copyright © 2023 CATTLEytics Inc.

import { format, parseISO } from 'date-fns';
import { useInjection } from 'inversify-react';
import React, { useEffect, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { TYPES } from '../../../../types';
import AnimalAutocomplete from '../../../animals/components/AnimalAutocomplete';
import Button from '../../../common/components/Button';
import { ButtonVariant } from '../../../common/components/Button';
import ButtonModal from '../../../common/components/ButtonModal';
import PenSelect from '../../../common/components/PenSelect';
import Required from '../../../common/components/Required';
import AnimalEvent from '../../../common/entities/animalEvent';
import Pen from '../../../common/entities/pen';
import { AnimalEventType as AnimalEventTypeEnum } from '../../../common/enums';
import AnimalEventService from '../../../common/services/animalEventService';
import { IconError, IconSave } from '../../../common/utilities';
import { Animal } from '../../../shared';
import { QueryKey } from '../../../shared/enums';

/**
 * Component input properties.
 */
interface Props {
  /**
   * Default animal to select.
   */
  animal?: Animal;

  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * ID of event to edit in this modal.
   */
  eventId?: number;

  /**
   * Callback when form is cancelled.
   */
  onCancel?: () => void;

  /**
   * Callback when form has been submitted successfully..
   */
  onSubmitSuccess?: () => void;

  /**
   * Default pen to select.
   */
  pen?: Pen;
}

interface Payload {
  [key: string]: any | undefined;
  animalEventTypeId: number;
  animalIds: number[];
  eventDateTime: string;
  eventDetail?: PayloadDetail[];
}

interface PayloadDetail {
  key: string;
  value: string;
}

/**
 * Form for moving animals between pens. For use in event modal component.
 */
const MoveForm = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const eventService = useInjection<AnimalEventService>(TYPES.animalEventService);

  const [showAddPenModal, setShowAddPenModal] = useState<boolean>(false);
  const [eventDateTime, setEventDateTime] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [fromPenId, setFromPenId] = useState<string>('');
  const [toPenId, setToPenId] = useState<string>('');
  const [animals, setAnimals] = useState<Animal[]>([]);
  const [validated, setValidated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const mutation = useMutation<AnimalEvent[] | undefined, unknown, Payload>(
    (payload) => {
      // if (props.eventId) {
      //   return eventService.patch(payload.id, payload);
      // } else {
      return eventService.post(payload);
      //}
    },
    {
      onSuccess: async () => {
        // Invalidate and re-fetch
        await queryClient.invalidateQueries(QueryKey.AnimalEvents);
      },
    },
  );

  const queryClient = useQueryClient();

  useEffect(() => {
    const invalidElements = document.querySelectorAll('input.form-control:invalid');
    if (invalidElements.length > 0) {
      invalidElements[0].closest('.form-group')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [validated]);

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    const payload: Payload = {
      animalIds: animals.map((animal) => animal.id),
      animalEventTypeId: AnimalEventTypeEnum.Move,
      eventDateTime: parseISO(eventDateTime).toISOString(),
      penId: toPenId,
    };

    setErrorMessage('');
    try {
      await mutation.mutateAsync(payload);
      if (props.onSubmitSuccess) {
        props.onSubmitSuccess();
      }
    } catch (err) {
      setErrorMessage(t('common|eventSaveError'));
    }
  };

  const fields = (
    <>
      <Form.Group className="form-group mb-3" controlId="formEventDate">
        <Form.Label>
          {t('Event Date')} <Required />
        </Form.Label>
        <Form.Control
          aria-label="Event Date"
          onChange={(event): void => setEventDateTime(event.target.value)}
          required={true}
          type={'date'}
          value={eventDateTime}
        />
        <Form.Control.Feedback type={'invalid'}>This field is required.</Form.Control.Feedback>
      </Form.Group>

      {/* Selecting the first animal will set the pen from dropdown */}
      <Form.Group className="form-group mb-3" controlId="formAnimals">
        <Form.Label>
          {t('Animals')} <Required />
        </Form.Label>
        <AnimalAutocomplete
          id={'animals'}
          multiple={true}
          name={'animalIds'}
          onSelect={(selected): void => {
            // since multiple is set we can assume we are going to either get undefined or Animal[]
            if (!selected || !Array.isArray(selected)) {
              // selected is undefined (can't actually not be an array because of multiple prop)
              setFromPenId('');
              return setAnimals([]);
            }

            // only set the from pen ID based on the first animal selected
            if (animals.length === 0) {
              setFromPenId(String(selected[0].currentPenId));
            }

            setAnimals(selected);
          }}
          penId={fromPenId ? fromPenId : undefined}
          required={true}
          selected={animals}
          validated={validated}
        />
      </Form.Group>
      <Form.Group className="form-group mb-3" controlId="formPenAutocomplete">
        <Form.Label>
          {t('From')} <Required />
        </Form.Label>
        <PenSelect
          filterIds={toPenId ? [toPenId] : []}
          onChange={(penId): void => setFromPenId(penId)}
          readOnly
          required={true}
          value={fromPenId}
        />
      </Form.Group>
      <Form.Group className="form-group mb-3" controlId="formToPen">
        <Form.Label>
          {t('To')} <Required />
        </Form.Label>
        <PenSelect
          filterIds={fromPenId ? [fromPenId] : []}
          firstItemDefault={true}
          onChange={(penId): void => setToPenId(penId)}
          onCloseAddModal={(): void => setShowAddPenModal(false)}
          required={true}
          showAddModal={showAddPenModal}
          value={toPenId}
        />
        <Form.Text className="text-muted">
          <span>{t('Choose a pen from the dropdown or')} </span>
          <Button onClick={(): void => setShowAddPenModal(true)} size={'sm'} variant={'link'}>
            {t('add pen')}
          </Button>
        </Form.Text>
      </Form.Group>
    </>
  );

  return (
    <Form noValidate={true} onSubmit={onFormSubmit} validated={validated}>
      {fields}
      {errorMessage ? (
        <Alert className="mt-4" variant="danger">
          <IconError className="me-2" />
          {errorMessage}
        </Alert>
      ) : null}
      <div className="modal-footer modal-footer-in-form">
        <ButtonModal
          disabled={mutation.isLoading}
          label={t('Cancel')}
          onClick={props.onCancel}
          type={'button'}
          variant={ButtonVariant.Secondary}
        />
        <ButtonModal
          busy={mutation.isLoading}
          disabled={mutation.isLoading}
          icon={IconSave}
          label={t('Save')}
          type="submit"
        />
      </div>
    </Form>
  );
};

export default MoveForm;
