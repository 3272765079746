// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { withTranslation } from 'react-i18next';

import Spinner from './Spinner';

/**
 * A component to use when uploading an image
 */
const ImageUploading = (): JSX.Element => {
  return (
    <div
      className={'text-muted border rounded d-flex justify-content-center align-items-center'}
      style={{ width: '150px', height: '150px' }}
    >
      <Spinner />
    </div>
  );
};

export default withTranslation()(ImageUploading);
