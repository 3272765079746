// Copyright © 2023 CATTLEytics Inc.

import 'chartjs-adapter-date-fns';

import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import Spinner from '../../common/components/Spinner';
import { api } from '../../common/utilities';
import { SccQuantity } from '../../shared';
import { QueryKey } from '../../shared';

const lineColors = [
  'rgb(255, 160, 160)', // PastelPink
  'rgb(152, 251, 152)', // PaleGreen
  'rgb(160, 160, 255)', //PastelPurple
  'rgb(255, 215, 0)', // Gold
  'rgb(255, 160, 122)', // LightSalmon
  'rgb(255, 105, 180)', // HotPink
  'rgb(135, 206, 235)', // SkyBlue
  'rgb(127, 255, 212)', // Aquamarine
  'rgb(160, 255, 255)', // PastelBlue
];

const SccQuantityChart = (): JSX.Element => {
  const [data, setData] = useState<ChartData<'line'>>();

  const { t } = useTranslation();

  const query = useQuery<Required<SccQuantity>[]>([QueryKey.SccQuantity], () =>
    api<Required<SccQuantity>[]>('GET', '/v1/scc-quantity'),
  );

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineController,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
  );

  useEffect(() => {
    if (!query.data) {
      return;
    }

    const allLabels: Date[] = []; // Store all months as labels
    const dataByLactationGroup: { [group: string]: { data: number[]; labels: Date[] } } = {};

    query.data.forEach((row) => {
      const { avgScc, lactationGroup, yearMonth } = row;

      if (!dataByLactationGroup[lactationGroup]) {
        dataByLactationGroup[lactationGroup] = { labels: [], data: [] };
      }

      dataByLactationGroup[lactationGroup].labels.push(yearMonth);
      allLabels.push(yearMonth); // Store all months
      dataByLactationGroup[lactationGroup].data.push(avgScc);
    });

    const orderedLabels = Array.from(new Set(allLabels)).sort();

    const datasets = Object.keys(dataByLactationGroup).map((group, index) => ({
      label: group,
      data: orderedLabels.map((label) =>
        dataByLactationGroup[group].labels.includes(label) //checks if there is data available for that specific month in the current group
          ? dataByLactationGroup[group].data[dataByLactationGroup[group].labels.indexOf(label)]
          : null,
      ),
      borderColor: lineColors[index % lineColors.length],
      backgroundColor: 'transparent',
    }));

    const chartData = {
      labels: orderedLabels,
      datasets: datasets,
    };

    setData(chartData);
  }, [query.data]);

  const options: ChartOptions<'line'> = {
    plugins: {
      legend: {
        labels: {
          filter: (legendItem: any): boolean => {
            return legendItem && legendItem.text !== 'null';
          },
        },
      },
      title: {
        display: true,
        text: t('Lactation Group'),
        font: {
          size: 18,
          weight: 'bold',
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'month',
          parser: 'yyyy-MM',
          displayFormats: {
            month: 'MMMM',
          },
        },
        grid: {
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: t('Average Somatic Cell Count'),
        },
        min: 0,
      },
    },
  };

  return (
    <>
      {!data && <Spinner />}
      {data && <Chart data={data} options={options} type="line" />}
    </>
  );
};

export default SccQuantityChart;
