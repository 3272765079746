// Copyright © 2024 CATTLEytics Inc.

import { ReactNode, useCallback, useContext } from 'react';
import { Card, Col, Container, OverlayTrigger, Popover, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import Button from '../../common/components/Button';
import AuthContext from '../../common/store/auth-context';
import { useSettingsContext } from '../../common/store/useSettingsContext';
import { api, IconCancel, IconCheck, IconInbox } from '../../common/utilities';
import {
  ApiResourceV1,
  getShiftDateString,
  HttpMethod,
  QueryKey,
  UserSchedule,
} from '../../shared';
import { RequestSwapStatus } from '../../shared/enums/requestSwapStatus';

export function PendingRequests(): JSX.Element {
  // hooks
  const queryClient = useQueryClient();
  const settings = useSettingsContext();
  const { t } = useTranslation();
  const auth = useContext(AuthContext);

  // check for pending requests
  const { data, isLoading } = useQuery<UserSchedule[]>(
    [QueryKey.UserSchedules, QueryKey.UserSchedulesPendingRequests],
    // TODO remove the /1/ because its an ID that is only used to bypass the /:id api requirement
    () => api(HttpMethod.Get, `${ApiResourceV1.UserSchedules}/1/requests`),
    {},
  );

  const { mutateAsync: swapAsync } = useMutation<
    Partial<UserSchedule> | undefined,
    unknown,
    { id: number; requestSwapId?: number; requestedSwapStatus?: RequestSwapStatus; withId?: number }
  >(
    ({ id, requestSwapId, requestedSwapStatus, withId }) => {
      if (id && withId) {
        return api(HttpMethod.Post, `${ApiResourceV1.UserSchedules}/${id}/swap-requests`, {
          body: { requestSwapId, requestedSwapStatus, withId },
        });
      } else {
        // one-way
        return api(HttpMethod.Post, `${ApiResourceV1.UserSchedules}/${id}/take-request`, {
          body: { requestedSwapStatus },
        });
      }
      return Promise.reject();
    },
    {
      onSuccess: async (data, _variables) => {
        if (data) {
          // TODO, update UI state.
        }
        queryClient.invalidateQueries(QueryKey.UserSchedules);
      },
    },
  );

  const onRejectSwap = useCallback(
    (scheduleId: number, withId: number | undefined) => {
      swapAsync({ id: scheduleId, requestedSwapStatus: RequestSwapStatus.Rejected, withId });
    },
    [swapAsync],
  );
  const onAcceptSwap = useCallback(
    (scheduleId: number, withId: number | undefined) => {
      swapAsync({ id: scheduleId, requestedSwapStatus: RequestSwapStatus.Approved, withId });
    },
    [swapAsync],
  );

  if (isLoading) {
    return <></>;
  }

  const validData = data ?? [];
  const popover = (
    <Popover
      className="shadow"
      id="popover-placement-top"
      style={{
        maxWidth: '500px',
        borderRadius: 10,
        boxShadow: '12px',
      }}
    >
      <Popover.Header
        as="h3"
        style={{ width: '500px', borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
      >
        {t('pendingRequests|titleLabel')}
      </Popover.Header>
      <Popover.Body className="overflow-auto" style={{ maxHeight: '480px', width: '500px' }}>
        {validData.map((schedule) => {
          const isTwoWaySwap = schedule.requestDirection !== 'take';

          return (
            <Card className="mb-2 bg-transparent" key={schedule.id} style={{}}>
              <Card.Header className=" bg-transparent border-0" style={{}}>
                <h3 style={{ textTransform: 'none' }}>
                  {t(
                    schedule.requestDirection === 'take'
                      ? 'pendingRequests|wantsTakeLabel'
                      : 'pendingRequests|wantsSwapLabel',
                    {
                      user: `${schedule.user?.firstName} ${schedule.user?.lastName}`,
                      direction: schedule.requestDirection,
                    },
                  )}
                </h3>
              </Card.Header>
              <Container className="mt-2 mb-2">
                <Row>
                  {isTwoWaySwap ? (
                    <>
                      <Col md={6}>
                        <Container className="text-center border border-secondary rounded-1 p-2">
                          <Row>
                            <Col md={12}>
                              <h5>{t('pendingRequests|takeLabel', { user: auth.firstName })}</h5>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <h6>{schedule.shift?.name}</h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {schedule.shift ? (
                                getShiftDateString(schedule.shift, [schedule], settings.timeZone)
                              ) : (
                                <></>
                              )}
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                      <Col md={6}>
                        <Container className="text-center border border-secondary rounded-1 p-2">
                          <Row>
                            <Col md={12}>
                              <h5>
                                {t('pendingRequests|giveLabel', { user: schedule.user?.firstName })}
                              </h5>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <h6>{schedule.swap?.shift?.name}</h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {schedule.swap?.shift ? (
                                getShiftDateString(
                                  schedule.swap.shift,
                                  [schedule.swap],
                                  settings.timeZone,
                                )
                              ) : (
                                <></>
                              )}
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                    </>
                  ) : (
                    <></>
                  )}
                  {!isTwoWaySwap ? (
                    <Col md={12}>
                      <Container className="text-center border border-secondary rounded-1 p-2">
                        <Row>
                          <Col>
                            <h6>{schedule.shift?.name}</h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {schedule.shift ? (
                              getShiftDateString(schedule.shift, [schedule], settings.timeZone)
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              </Container>
              <Card.Footer className="border-0 bg-transparent">
                <Stack className="justify-content-between" direction="horizontal" gap={2}>
                  <Button
                    className="flex-grow-1"
                    icon={IconCancel}
                    label={t('pendingRequests|rejectLabel')}
                    onClick={(): void => onRejectSwap(schedule.id, schedule.swap?.id)}
                    size="lg"
                    variant="secondary"
                  />
                  <Button
                    className="flex-grow-1"
                    icon={IconCheck}
                    label={t('pendingRequests|approveLabel')}
                    onClick={(): void =>
                      onAcceptSwap(
                        schedule.id,
                        schedule.requestDirection !== 'take' ? schedule.swap?.id : undefined,
                      )
                    }
                    size="lg"
                    variant="primary"
                  />
                </Stack>
              </Card.Footer>
            </Card>
          );
        })}
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      {validData.length > 0 && (
        <OverlayTrigger flip overlay={popover} placement="bottom-start" trigger="click">
          {({ ref, ...triggerHandler }): ReactNode => (
            <span ref={ref} {...triggerHandler} style={{ position: 'relative' }}>
              <Button
                icon={IconInbox}
                label={t('pendingRequests|buttonLabel', { count: data?.length })}
              />
            </span>
          )}
        </OverlayTrigger>
      )}
    </>
  );
}
