// Copyright © 2023 CATTLEytics Inc.

import React, { PropsWithChildren } from 'react';
import { Button as BootstrapButton, Spinner } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import { IconType } from 'react-icons';
import { useLocation } from 'react-router-dom';

/**
 * Enumeration for Button variant values
 */
export enum ButtonVariant {
  Danger = 'danger',
  Dark = 'dark',
  Info = 'info',
  Light = 'light',
  Link = 'link',
  OutlineDanger = 'outline-danger',
  OutlineDark = 'outline-dark',
  OutlineInfo = 'outline-info',
  OutlineLight = 'outline-light',
  OutlinePrimary = 'outline-primary',
  OutlineSecondary = 'outline-secondary',
  OutlineSuccess = 'outline-success',
  OutlineWarning = 'outline-warning',
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Warning = 'warning',
}

/**
 * Properties for the @see Button component.
 */
export interface Props {
  /**
   * Accessibility label for this button.
   */
  ariaLabel?: string;

  /**
   * Whether the button is busy.
   */
  busy?: boolean;

  /**
   * Additional classes for the button.
   */
  className?: string;

  /**
   * Whether the button is disabled.
   */
  disabled?: boolean;

  /**
   * Optional react-icon to prepend to the button text
   */
  icon?: IconType;

  /**
   * Text that will appear on the button
   */
  label?: string;

  /**
   * Optional button click handler.
   */
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

  /**
   * Button size
   */
  size?: 'xs' | 'sm' | 'lg';

  style?: React.CSSProperties;

  /**
   * Button title
   */
  title?: string;

  /**
   * Additional text that will be appended to the label tracking field.
   * label: props.label + '-' + props.trackingLabel
   */
  trackingLabel?: string;

  /**
   * Button type like "submit"
   */
  type?: 'button' | 'reset' | 'submit';

  /**
   * Button variant
   * @example primary, outline-primary
   */
  variant?: string;
}

/**
 * A button component with busy indicator and icon support.
 */
const Button = (props: PropsWithChildren<Props>): JSX.Element => {
  const location = useLocation();

  const Icon = props.icon as IconType;
  // set defaults if disabled and busy props are not defined
  const disabled = props.disabled !== undefined ? props.disabled : false;
  const busy = props.busy !== undefined ? props.busy : false;

  const label = props.label ?? props.children ?? '';

  const ariaLabel = props.ariaLabel ?? props.label;

  const trackingLabel = props.trackingLabel ? ' - ' + props.trackingLabel : '';

  return (
    <BootstrapButton
      aria-label={ariaLabel}
      className={`text-nowrap align-baseline ${props.className} ${
        props.size === 'xs' ? 'btn-xs' : ''
      }`}
      disabled={disabled || busy}
      onClick={(e): void => {
        if (!props.onClick) return;
        ReactGA.event({
          action: 'Button Click',
          category: location.pathname,
          label: `${props.label}${trackingLabel}`,
        });
        props.onClick(e);
      }}
      size={props.size !== 'xs' ? props.size : undefined}
      style={props.style}
      title={props.title}
      type={props.type ?? 'button'}
      variant={props.variant}
    >
      {props.busy ? (
        <>
          <Spinner animation="border" aria-hidden="true" as="span" role="status" size="sm" />
          <span className="visually-hidden">Loading...</span>
        </>
      ) : (
        <>
          {props.icon && (
            <Icon className={props.size === 'lg' ? 'me-2' : 'me-1'} style={{ height: '100%' }} />
          )}
          {label ?? ''}
        </>
      )}
    </BootstrapButton>
  );
};

export default Button;
