// Copyright © 2023 CATTLEytics Inc.

import { inject, injectable } from 'inversify';

import { TYPES } from '../../../types';
import Logger from '../../logger/logger';
import Session from '../entities/session';
import Api2Service from './api2Service';

const path = '/v1/auth';

/**
 * Service for managing user authentication
 */
@injectable()
export default class AuthService {
  private logger: Logger;

  private apiService: Api2Service;

  /**
   * Creates an instance of AuthService.
   *
   * @param logger Logger instance.
   * @param apiService API Service instance
   */
  constructor(
    @inject(TYPES.logger) logger: Logger,
    @inject(TYPES.api2Service) apiService: Api2Service,
  ) {
    this.logger = logger;
    this.apiService = apiService;
  }

  async get(id: number, params?: Record<string, string>): Promise<Session | undefined> {
    const result = await this.apiService.get<Session>(`${path}/${id}`, params);
    if (!result) {
      return undefined;
    }

    const data = result as Session;
    this.cast(data);
    return data;
  }

  async post(attributes: Record<string, string>): Promise<Session | undefined> {
    const result = await this.apiService.post<Session>(`${path}`, attributes);
    if (!result) {
      return undefined;
    }
    this.cast(result as Session);
    return result;
  }

  // reset password api, need to pass email? how to write properly?
  async resetPassword(attributes: Record<string, string | boolean>): Promise<null | undefined> {
    const result = await this.apiService.post<null>(`${path}/reset-password`, attributes);
    if (!result) {
      return undefined;
    }
    this.cast(result);
    return result;
  }

  // new password api
  async newPassword(attributes: Record<string, string | boolean>): Promise<null | undefined> {
    const result = await this.apiService.post<null>(`${path}/new-password`, attributes);
    if (!result) {
      return undefined;
    }
    this.cast(result);
    return result;
  }

  async delete(id: number): Promise<null> {
    return await this.apiService.delete(`${path}/${id}`);
  }

  async patch(
    id: number,
    attributes: Record<string, string | number | null>,
  ): Promise<Session | undefined> {
    // make sure record identifier is included
    attributes.id = id;

    const result = await this.apiService.patch(`${path}/${id}`, attributes);
    if (!result) {
      return undefined;
    }

    const data = result as Session;
    this.cast(data);
    return data;
  }

  /**
   * Casts fields into javascript types.
   * @param item
   * @return cast object
   */
  cast(item: Session): void {
    if (!item) {
      return;
    }
  }
}
