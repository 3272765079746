// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Badge, Placeholder, PlaceholderButton, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TbCell } from 'react-icons/tb';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { TYPES } from '../../../types';
import AnimalDiedBadge from '../../common/components/AnimalDiedBadge';
import AnimalMilkingStatusBadge from '../../common/components/AnimalMilkingStatusBadge';
import AnimalReferenceBadge from '../../common/components/AnimalReferenceBadge';
import AnimalReproductionStatusBadge from '../../common/components/AnimalReproductionStatusBadge';
import AnimalSoldBadge from '../../common/components/AnimalSoldBadge';
import Button from '../../common/components/Button';
import { ButtonVariant } from '../../common/components/Button';
import ButtonEdit from '../../common/components/ButtonEdit';
import ConfirmModal from '../../common/components/ConfirmModal';
import LightBoxModal from '../../common/components/LightBoxModal';
import Page from '../../common/components/Page';
import TitlePlaceholder from '../../common/components/placeholders/TitlePlaceholder';
import { AnimalEventType, AnimalReproductionStatus } from '../../common/enums';
import { AnimalStatus } from '../../common/enums/animalStatus';
import { useAnimalStatusLabel, useOpenModal } from '../../common/hooks';
import AnimalEventService from '../../common/services/animalEventService';
import AnimalService from '../../common/services/animalService';
import { IconRestore, IconTag } from '../../common/utilities';
import NotFoundPage from '../../misc/components/NotFoundPage';
import NotesCard from '../../notes/components/NotesCard';
import FertilityTable from '../../reproduction-management/components/repro-tables/FertilityTable';
import GeneticsTable from '../../reproduction-management/components/repro-tables/GeneticsTable';
import IndividualEcmChart from '../../reproduction-management/components/repro-tables/IndividualEcmChart';
import ReproChronicIssuesTable from '../../reproduction-management/components/repro-tables/ReproChronicIssuesTable';
import ReproInfoTable from '../../reproduction-management/components/repro-tables/ReproInfoTable';
import ReproRelativesTable from '../../reproduction-management/components/repro-tables/ReproRelativesTable';
import { QueryKey } from '../../shared';
import { Animal, AnimalEvent, AnimalMilkingStatus, nameTag } from '../../shared';
import TasksList from '../../tasks/components/TasksList';
import TestDataTable from '../../test-data/components/TestDataTable';
import AnimalCardEvents from './animal-card-events';
import AnimalImage from './AnimalImage';
import AnimalModal from './AnimalModal';
import AnimalReferencesTable from './AnimalReferencesTable';
import AnimalStats from './AnimalStats';
import CalfTable from './CalfTable';
import CardStatsPlaceholder from './CardStatsPlaceholder';
import AnimalChartMilkCombined from './charts/AnimalChartMilkCombined';

enum TabKeys {
  AnimalReferences = 'references',
  Calves = 'calves',
  Events = 'events',
  Health = 'health',
  HealthChecks = 'health-checks',
  MilkTestData = 'milk-test-data',
  Notes = 'notes',
  Repro = 'repro',
  Tasks = 'tasks',
}

const StatusToEventTypeMap: Record<string, AnimalEventType[]> = {
  [AnimalStatus.Dead]: [AnimalEventType.Died],
  [AnimalStatus.DeadAndSold]: [AnimalEventType.Died, AnimalEventType.Sold],
  [AnimalStatus.Sold]: [AnimalEventType.Sold],
};

interface RouteParams {
  /**
   * The animal ID to lookup.
   */
  animalId: string;

  /**
   * A target location on the page.
   */
  tabKey?: string;
}

const AnimalCard = (): JSX.Element => {
  const { animalId, tabKey } = useParams<RouteParams>();
  const history = useHistory();

  const { t } = useTranslation();

  const animalService = useInjection<AnimalService>(TYPES.animalService);
  const eventService = useInjection<AnimalEventService>(TYPES.animalEventService);
  const [animal, setAnimal] = useState<Animal | undefined>();
  const [lightBoxImageUrl, setLightBoxImageUrl] = useState<string>('');
  const [lightBoxModalVisible, setLightBoxModalVisible] = useState<boolean>(false);
  const [animalModalVisible, setAnimalModalVisible] = useState<boolean>(false);
  const [restoreErrorMessage, setRestoreErrorMessage] = useState('');

  const queryClient = useQueryClient();
  const tabsRef = useRef<HTMLDivElement>(null);
  const {
    closeModal: closeRestoreModal,
    open: restoreModalVisible,
    openModal: openRestoreModal,
  } = useOpenModal();
  // Required to run the query at least one time then stops request or re-fetches
  const refetchInterval = useMemo(() => !!animal && 300000, [animal]);

  const animalStatusLabel = useAnimalStatusLabel(animal);

  const queryAnimal = useQuery(
    [QueryKey.Animals, animalId],
    () => animalService.get(parseInt(animalId)),
    {
      enabled: !animalModalVisible,
      refetchInterval,
      refetchOnWindowFocus: false,
    },
  );

  const mutation = useMutation(
    async () => {
      if (animal?.status) {
        const events = await eventService.list({
          animalId: String(animal.id),
          animalEventTypeIds: StatusToEventTypeMap[animal.status].join(','),
          order: '-eventDateTime',
        });

        const filterEvents = Object.values(
          events.reduce<Record<number, AnimalEvent>>((prev, event) => {
            if (event.animalEventTypeId) {
              if (prev[event.animalEventTypeId]) {
                const prevEvent = prev[event.animalEventTypeId];
                if (prevEvent.modifiedDate < event.modifiedDate) {
                  return { ...prev, [event.animalEventTypeId]: event } as Record<
                    number,
                    AnimalEvent
                  >;
                }
              } else {
                return { ...prev, [event.animalEventTypeId]: event } as Record<number, AnimalEvent>;
              }
            }

            return prev;
          }, {} as Record<number, AnimalEvent>),
        );

        await Promise.all(filterEvents.map(async ({ id }) => await eventService.delete(id)));
      }
    },
    {
      onSuccess: async () => {
        // Invalidate to trigger re-fetch
        await queryClient.invalidateQueries(QueryKey.Animals);
      },
    },
  );

  const setRestoreError = useCallback(
    (error: unknown) => {
      console.error('This item failed to be restored', error);
      setRestoreErrorMessage(t('This animal could not be restored.'));
    },
    [setRestoreErrorMessage, t],
  );

  const requestRestore = useCallback(async () => {
    setRestoreErrorMessage('');
    await mutation.mutateAsync();
    closeRestoreModal();
    // Called invalidate here since vs in mutation success to prevent status
    // label update while modal is open
    await queryClient.invalidateQueries(QueryKey.Animals);
    await queryClient.invalidateQueries(QueryKey.AnimalEvents);
  }, [mutation, setRestoreErrorMessage, closeRestoreModal, queryClient]);

  const restoreAnimal = useCallback(async () => {
    try {
      await requestRestore();
    } catch (err) {
      setRestoreError(err);
    }
  }, [requestRestore, setRestoreError]);

  useEffect(() => {
    if (queryAnimal.data) {
      setAnimal(queryAnimal.data);
    }
  }, [queryAnimal.data]);

  const calfFilters: Record<string, string | undefined> = {
    reproductionStatus: [
      AnimalReproductionStatus.Bred,
      AnimalReproductionStatus.Open,
      AnimalReproductionStatus.Pregnant,
      AnimalReproductionStatus.Unknown,
    ].join(','),
    milkingStatus: [
      AnimalMilkingStatus.Lactating,
      AnimalMilkingStatus.Dry,
      AnimalMilkingStatus.Unknown,
    ].join(','),
    // for offspring, don't use calvesOnly filter as it only shows under 6 month old animals.
    calvesOnly: '0',
  };

  if (!queryAnimal.isFetching && !animal) {
    return <NotFoundPage />;
  }

  return (
    <Page
      breadcrumbTitle={animal ? nameTag(animal.primaryTag, animal.name) : ''}
      breadcrumbs={[{ label: 'Animals', to: '/animals/active' }]}
      buttons={
        queryAnimal.isLoading ? (
          <PlaceholderButton style={{ width: '8rem' }} xs={2} />
        ) : (
          <>
            {animal?.status === AnimalStatus.Active && (
              <ButtonEdit
                disabled={animal?.status !== AnimalStatus.Active}
                onClick={(): void => {
                  setAnimalModalVisible(true);
                }}
              />
            )}
            {animal?.status !== AnimalStatus.Active && (
              <Button
                className={'text-nowrap'}
                disabled={restoreModalVisible || mutation.isLoading}
                onClick={openRestoreModal}
                size={'lg'}
                style={{ display: 'flex', alignItems: 'center' }}
                variant={'primary'}
              >
                <IconRestore className={'me-1'} style={{ height: '100%' }} />
                {t('Restore')}
              </Button>
            )}
          </>
        )
      }
      documentTitle={animal ? nameTag(animal.primaryTag, animal.name) : ''}
      otherHeaderElement={
        <>
          {animal?.status === AnimalStatus.Active && (
            <>
              <div className={'d-flex mb-3 align-items-center'}>
                <AnimalReproductionStatusBadge
                  status={animal?.reproductionStatus as AnimalReproductionStatus}
                  style={{ fontSize: '18px', width: '100%' }}
                />
              </div>
              <div className={'d-flex mb-3 align-items-center'}>
                <AnimalMilkingStatusBadge
                  status={animal?.milkingStatus as AnimalMilkingStatus}
                  style={{ fontSize: '18px', width: '100%' }}
                />
              </div>
            </>
          )}
          {animal?.isReference && (
            <div className={'d-flex mb-3 align-items-center'}>
              <AnimalReferenceBadge style={{ fontSize: '18px', width: '100%' }} />
            </div>
          )}
          {(animal?.status === AnimalStatus.Dead ||
            animal?.status === AnimalStatus.DeadAndSold) && (
            <div className={'d-flex mb-3 align-items-center'}>
              <AnimalDiedBadge style={{ fontSize: '18px', width: '100%' }} />
            </div>
          )}
          {(animal?.status === AnimalStatus.Sold ||
            animal?.status === AnimalStatus.DeadAndSold) && (
            <div className={'d-flex mb-3 align-items-center'}>
              <AnimalSoldBadge style={{ fontSize: '18px', width: '100%' }} />
            </div>
          )}
          {animal && animal?.scc150kCount > 0 && (
            <div className={'d-flex mb-3 align-items-center'}>
              <Badge
                className={'bg-danger'}
                pill={true}
                style={{ fontSize: '18px', width: '100%' }}
              >
                <TbCell /> High SCC
              </Badge>
            </div>
          )}
        </>
      }
      title={
        animal ? (
          <span
            style={{
              color:
                animal?.status === AnimalStatus.Active && animal?.isReference !== true
                  ? ''
                  : '#999',
            }}
          >
            <IconTag className={'me-2'} style={{ fontSize: '24px' }} />
            {nameTag(animal?.primaryTag, animal?.name)}
          </span>
        ) : (
          ''
        )
      }
      titleLoading={queryAnimal.isLoading}
      titlePlaceholder={<TitlePlaceholder />}
    >
      {queryAnimal.isLoading && (
        <Placeholder animation={'glow'}>
          <Placeholder size={'lg'} style={{ width: '12rem' }} />
        </Placeholder>
      )}

      <div className={'d-sm-flex mb-3'}>
        <div className={'mb-3 me-4 d-flex justify-content-center d-sm-block'}>
          {!queryAnimal.isFetching && animal ? (
            <AnimalImage
              caption={animal.name}
              className={animal.imageUrlSigned && 'cursor-pointer'}
              fallbackText={animal.name ?? animal.primaryTag ?? ''}
              imageUrl={animal.imageUrlSigned}
              isCalf={animal.isCalf}
              onClick={(): void => {
                if (animal.imageUrlSigned) {
                  setLightBoxModalVisible(true);
                  setLightBoxImageUrl(animal.imageUrlSigned);
                }
              }}
            />
          ) : (
            <Placeholder animation={'glow'}>
              <Placeholder style={{ display: 'flex', width: '250px', height: '250px' }} />
            </Placeholder>
          )}
        </div>
        <div className={'w-100'}>
          {!animal || queryAnimal.isFetching ? (
            <CardStatsPlaceholder />
          ) : (
            <AnimalStats animal={animal} />
          )}
        </div>
      </div>
      <div ref={tabsRef} />
      <Tabs
        activeKey={tabKey}
        className="pt-2 mb-3"
        id={`animal-card-tabs`}
        mountOnEnter={true}
        onSelect={(k): void => {
          if (k) {
            history.push(`/animals/${animalId}/${k}`);
          }
        }}
        style={{
          zIndex: 1018,
          position: 'sticky',
          backgroundColor: 'var(--bs-body-bg)',
          top: '50px',
          borderBottom: '1px solid var(--bs-primary)',
        }}
      >
        <Tab eventKey={TabKeys.Events} title={t('animals|animalCard|tabs|events')}>
          <AnimalCardEvents animalId={animalId} />
        </Tab>
        {/* <Tab eventKey={TabKeys.HealthChecks} title={t('animals|animalCard|tabs|healthChecks')}>
          <AnimalHealthChecks
            animalId={Number(animalId)}
            type={animal?.isCalf ? 'calf' : 'other'}
          />
        </Tab> */}
        <Tab eventKey={TabKeys.AnimalReferences} title={t('animals|animalCard|tabs|references')}>
          <AnimalReferencesTable animalId={Number(animalId)} />
        </Tab>
        {animal?.genderId === 2 && !animal?.isCalf && (
          <Tab eventKey={TabKeys.MilkTestData} title={t('animals|animalCard|tabs|milkTestData')}>
            <AnimalChartMilkCombined animalId={Number(animalId)} style={{ maxHeight: '275px' }} />
            <TestDataTable className={'mt-3'} filters={{ animalId: animalId }} />
            <div style={{ height: '1000px' }}></div>
          </Tab>
        )}
        {!animal?.isCalf && animal?.genderId === 2 && (
          <Tab eventKey={TabKeys.Calves} title={t('animals|animalCard|tabs|offspring')}>
            <CalfTable damId={Number(animalId)} filters={calfFilters} hideFilters={true} />
          </Tab>
        )}
        <Tab eventKey={TabKeys.Tasks} title={t('animals|animalCard|tabs|tasks')}>
          <TasksList animal={animal} animalIds={[Number(animalId)]} displayButton={true} />
        </Tab>
        <Tab eventKey={TabKeys.Notes} title={t('animals|animalCard|tabs|notes')}>
          <NotesCard animalId={Number(animalId)} primaryTag={animal?.primaryTag} />
        </Tab>
        <Tab eventKey={TabKeys.Repro} title={t('animals|animalCard|tabs|repro')}>
          <ReproInfoTable animalId={Number(animalId)} />
          <GeneticsTable animalId={Number(animalId)} />
          <ReproRelativesTable animalId={Number(animalId)} tableType={'sisters'} />
          <ReproRelativesTable animalId={Number(animalId)} tableType={'calves'} />
          <FertilityTable animalId={Number(animalId)} />
          <ReproChronicIssuesTable animalId={Number(animalId)} />
          <IndividualEcmChart animalId={Number(animalId)} lactOrder={0} />
          <IndividualEcmChart animalId={Number(animalId)} lactOrder={1} />
        </Tab>
        {/* <Tab eventKey={TabKeys.Health} title={t('animals|animalCard|tabs|health')}>
          <AnimalHealthTable animalId={Number(animalId)} />
        </Tab> */}
      </Tabs>
      {animalModalVisible && (
        <AnimalModal editId={animalId} onClose={(): void => setAnimalModalVisible(false)} />
      )}
      {lightBoxModalVisible && (
        <LightBoxModal
          imageUrl={lightBoxImageUrl}
          onClose={(): void => setLightBoxModalVisible(false)}
        />
      )}
      <ConfirmModal
        cancelLabel={t('Cancel')}
        cancelOnClick={(): void => closeRestoreModal()}
        errorMessage={restoreErrorMessage}
        okLabel={t(`Yes, restore this animal`)}
        okOnClick={restoreAnimal}
        okVariant={ButtonVariant.Primary}
        title={t(`Restore this animal`)}
        visible={restoreModalVisible}
      >
        {t(`animalCard|changeAnimalStatusCheck`, { status: animalStatusLabel })}
      </ConfirmModal>
    </Page>
  );
};

export default AnimalCard;
