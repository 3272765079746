// Copyright © 2023 CATTLEytics Inc.

import React, { ElementType } from 'react';
import { Spinner as BootstrapSpinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

/**
 * Properties for the Spinner component.
 */
export interface Props {
  /**
   * Animation type
   */
  animation?: 'border' | 'grow';

  /**
   * Custom element type
   */
  as?: ElementType<any>;

  /**
   * Classes to pass to the spinner component
   */
  className?: string;

  /**
   * The size of the spinner: only option in react-bootstrap is sm
   */
  size?: 'sm';

  /**
   * Color variant
   */
  variant?: SpinnerVariant;
}

/**
 * Enumeration for Spinner variant values
 */
export enum SpinnerVariant {
  Danger = 'danger',
  Dark = 'dark',
  Info = 'info',
  Light = 'light',
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Warning = 'warning',
}

/**
 * Simple spinner wrapper
 */
const Spinner = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  const animation = props.animation ? props.animation : 'border';
  const variant = props.variant ? props.variant : SpinnerVariant.Secondary;

  return (
    <BootstrapSpinner
      animation={animation}
      as={props.as}
      className={props.className}
      role="status"
      size={props.size}
      variant={variant}
    >
      <span className="visually-hidden">{t('common|loading')}</span>
    </BootstrapSpinner>
  );
};

export default Spinner;
