// Copyright © 2023 CATTLEytics Inc.

/**
 * WARNING!
 *
 * DO NOT EDIT THIS FILE
 *
 * CHANGES WILL BE OVERWRITTEN!
 *
 * Make changes in packages/shared and run npm run copy-shared-to-packages
 */
export enum AnimalStatus {
  Active = 'active',
  AliveAndSold = 'alive-and-sold',
  Dead = 'dead',
  DeadAndSold = 'dead-and-sold',
  Sold = 'sold',
  Unknown = 'unknown',
}
