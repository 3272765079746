// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { TYPES } from '../../../types';
import { useGetEventTypes } from '../../animals/components/animal-card-events/hooks';
import DataTable, { DataTableHeader, DataTableRow } from '../../common/components/DataTable';
import Page from '../../common/components/Page';
import AnimalEventService, { SummaryByMonth } from '../../common/services/animalEventService';
import { range } from '../../shared';
import { QueryKey } from '../../shared/enums';

interface SummaryRow {
  [index: string]: string | number | number[];
  months: number[];
  name: string;
  total: number;
}

const headers: DataTableHeader[] = [
  { label: 'Event', name: 'name' },
  { label: 'Total', name: 'total' },
  { label: 'Jan', name: 'jan' },
  { label: 'Feb', name: 'feb' },
  { label: 'Mar', name: 'mar' },
  { label: 'Apr', name: 'apr' },
  { label: 'May', name: 'may' },
  { label: 'Jun', name: 'jun' },
  { label: 'Jul', name: 'jul' },
  { label: 'Aug', name: 'aug' },
  { label: 'Sep', name: 'sep' },
  { label: 'Oct', name: 'oct' },
  { label: 'Nov', name: 'nov' },
  { label: 'Dec', name: 'dec' },
];

/**
 * Routable component to show a table of animal event totals by month.
 */
const EventsSummaryByMonthPage = (): JSX.Element => {
  const { t } = useTranslation();

  const animalEventService = useInjection<AnimalEventService>(TYPES.animalEventService);
  const history = useHistory();

  const [dataRow, setDataRow] = useState<DataTableRow[]>([]);

  const [year, setYear] = useState<string>(String(new Date().getFullYear()));

  const query = useQuery<SummaryByMonth[]>(
    [QueryKey.AnimalEvents, year],
    () => animalEventService.summaryByMonth({ year: year }),
    { keepPreviousData: false },
  );

  const { eventTypes } = useGetEventTypes();

  useEffect(() => {
    if (query.data) {
      const rowByName: Record<string, SummaryRow> = {};
      for (const row of query.data) {
        if (rowByName[row.name] === undefined) {
          rowByName[row.name] = {
            name: row.name,
            total: 0,
            months: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          };
        }
        rowByName[row.name].total += Number(row.total);
        rowByName[row.name].months[row.month] = row.total;
      }

      const rows: DataTableRow[] = Object.keys(rowByName).map((key) => {
        const row = rowByName[key];
        return {
          name: String(row.name),
          total: String(row.total),
          jan: String(row.months[1]),
          feb: String(row.months[2]),
          mar: String(row.months[3]),
          apr: String(row.months[4]),
          may: String(row.months[5]),
          jun: String(row.months[6]),
          jul: String(row.months[7]),
          aug: String(row.months[8]),
          sep: String(row.months[9]),
          oct: String(row.months[10]),
          nov: String(row.months[11]),
          dec: String(row.months[12]),
        };
      });
      setDataRow(rows);
    }
  }, [query.data]);

  const years = range(2000, new Date().getFullYear());

  return (
    <Page
      breadcrumbTitle={t('Summary by Month')}
      breadcrumbs={[{ label: 'Events', to: '/events/all' }]}
      title={t('Events Summary by Month')}
    >
      <Row>
        <Col sm={6}>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{t('Year')}</Form.Label>
              <Form.Select
                aria-label={'Select year'}
                onChange={(e): void => setYear(e.target.value)}
                value={year}
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <DataTable
        data={dataRow}
        headers={headers}
        hidePaginationControls={true}
        isLoading={query.isLoading}
        onRowClick={(row): void => {
          if (row.name === 'ALL') {
            history.push(`/events/all/${year}`);
          } else {
            // find the eventTypeId associated with this event and send as param
            const rowEvent = eventTypes?.find((item) => {
              return item.name === row.name;
            });
            history.push(`/events/${rowEvent?.id}/${year}`);
          }
        }}
      />
    </Page>
  );
};

export default EventsSummaryByMonthPage;
