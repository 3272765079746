// Copyright © 2023 CATTLEytics Inc.

import { isEmpty } from 'lodash';
import React, { Fragment, ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import InfoTooltip from './InfoTooltip';

interface Props extends WithTranslation {
  /**
   * A value to compare against the value prop. Will be displayed: value / compareValue.
   */
  compareValue?: string;

  /**
   * Text to identify this data.
   */
  label: string;

  /**
   * Routable path to direct the user when value is clicked.
   */
  linkTo?: string;

  /**
   * When set an info icon will be displayed beside the label. On hover this text will be displayed.
   */
  tooltip?: string;

  /**
   * Where to show the info tooltip.
   */
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';

  /**
   * The data to display.
   */
  value?: string | number | ReactNode;
}

/**
 * Generic data cell component. Label over text with tooltip and comparison value.
 */
const DataCell = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const value =
    props.value &&
    String(props.value)
      .split('\n')
      .map((item, key) => (
        <Fragment key={key}>
          {isEmpty(item) ? '-' : item}
          <br />
        </Fragment>
      ));

  return (
    <div className={'mb-1'}>
      <strong style={{ fontSize: '0.8rem' }}>{props.label}</strong>
      {props.tooltip && (
        <InfoTooltip tooltip={props.tooltip} tooltipPlacement={props.tooltipPlacement} />
      )}
      <br />
      {props.children}

      {/* if no children and linkTo prop is set and the value is not empty, show the value*/}
      {!props.children && props.linkTo && !isEmpty(value) && <Link to={props.linkTo}>{value}</Link>}

      {/* if no children and linkTo prop is NOT set or value is empty*/}
      {!props.children && (!props.linkTo || isEmpty(value)) && (
        <span>{isEmpty(value) && value !== 0 ? '-' : value}</span>
      )}

      {!props.children && props.compareValue && (
        <>
          {' '}
          / <span className={'text-primary'}>{props.compareValue}</span>
        </>
      )}
    </div>
  );
};

export default withTranslation()(DataCell);
