// Copyright © 2024 CATTLEytics Inc.
import React, { useState } from 'react';
import { Col, Form, FormGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { useMutation } from 'react-query';

import AlertErrorForModal from '../common/components/AlertErrorForModal';
import Button from '../common/components/Button';
import Required from '../common/components/Required';
import { IconCaretRight } from '../common/utilities';
import { api } from '../common/utilities';
import { Auth } from '../shared';
import { ApiResourceV1, HttpMethod } from '../shared';
import { isValidEmail } from '../shared/utilities/emailValidation';
import { passwordValidation } from '../shared/utilities/passwordValidation';

interface Props {
  onComplete: () => void;
}
/**
 * Routable component to allow a user to sign up for a new site.
 */
export const SignUpForm = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [validated, setValidated] = useState<boolean>(false);

  // DO NOT commit the following lines unless commented out
  // enable the following lines for auto generating an email and populating the sign-up form
  // const [email, setEmail] = useState<string>(`${new Date().getTime()}@example.com`);
  // const [firstName, setFirstName] = useState<string>('John');
  // const [lastName, setLastName] = useState<string>('Smith');
  // const [password, setPassword] = useState<string>('Abcde12345');
  // const [passwordConfirm, setPasswordConfirm] = useState<string>('Abcde12345');
  // const [phone, setPhone] = useState<string>('12345678900');
  // const [siteName, setSiteName] = useState<string>('Test Dairy');

  // Uncomment and commit these lines:
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [siteName, setSiteName] = useState<string>('');

  const [passwordCheck, setPasswordCheck] = useState<boolean>(false);
  const [passwordConfirmCheck, setPasswordConfirmCheck] = useState<boolean>(false);
  const [passwordMatch, setPasswordMatch] = useState<boolean>(true);

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const phoneValid = isPossiblePhoneNumber(phone, 'CA');
    const valid = form.checkValidity() && passwordMatch && passwordCheck && phoneValid;

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }
    setErrorMessage('');
    try {
      await mutation.mutateAsync();
    } catch (err) {
      console.error('Sign up failed', err);
      setErrorMessage((err as Error).message);
    }
  };

  const mutation = useMutation<Auth | undefined>(
    () =>
      api(HttpMethod.Post, ApiResourceV1.SignUp, {
        body: {
          email: email,
          firstName: firstName,
          lastName: lastName,
          password: password,
          phone: phone,
          siteName: siteName,
        },
      }),
    {
      onSuccess: props.onComplete,
    },
  );

  return (
    <Form noValidate={true} onSubmit={onFormSubmit}>
      <Form.Group className="mb-3" controlId="siteName">
        <Form.Label>
          {t('signUpForm|siteNameLabel')} <Required />
        </Form.Label>
        <Form.Control
          isInvalid={siteName.length < 1 && validated}
          isValid={siteName.length >= 1 && validated}
          name={'siteName'}
          onChange={(e): void => setSiteName(e.target.value)}
          placeholder={t('signUpForm|siteNamePlaceholder')}
          required
          type={'text'}
          value={siteName}
        />
        <Form.Control.Feedback type={'invalid'}>
          {t('signUpForm|requiredField')}
        </Form.Control.Feedback>
      </Form.Group>
      <Row>
        <Col sm={6}>
          <Form.Group className="mb-3" controlId="firstName">
            <Form.Label>
              {t('signUpForm|firstNameLabel')} <Required />
            </Form.Label>
            <Form.Control
              isInvalid={firstName.length < 1 && validated}
              isValid={siteName.length >= 1 && validated}
              name={'firstName'}
              onChange={(e): void => setFirstName(e.target.value)}
              placeholder={t('signUpForm|firstNamePlaceholder')}
              required
              type="text"
              value={firstName}
            />
            <Form.Control.Feedback type={'invalid'}>
              {t('signUpForm|requiredField')}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className="mb-3" controlId="lastName">
            <Form.Label>
              {t('signUpForm|lastNameLabel')} <Required />
            </Form.Label>
            <Form.Control
              isInvalid={lastName.length < 1 && validated}
              isValid={siteName.length >= 1 && validated}
              name={'lastName'}
              onChange={(e): void => setLastName(e.target.value)}
              placeholder={t('signUpForm|lastNamePlaceholder')}
              required
              type="text"
              value={lastName}
            />
            <Form.Control.Feedback type={'invalid'}>
              {t('signUpForm|requiredField')}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Form.Group className="mb-3" controlId="auth.ControlUsername">
            <Form.Label>
              {t('signUpForm|emailLabel')} <Required />
            </Form.Label>
            <Form.Control
              isInvalid={!isValidEmail(email) && validated}
              isValid={isValidEmail(email) && validated}
              name={'email'}
              onChange={(e): void => setEmail(e.target.value)}
              placeholder={t('signUpForm|emailPlaceholder')}
              required
              type="email"
              value={email}
            />
            <Form.Control.Feedback type={'invalid'}>
              {t('signUpForm|requiredField')}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className="mb-3" controlId="signUpPhone">
            <Form.Label>
              {t('signUpForm|phoneLabel')} <Required />
            </Form.Label>
            <Form.Control
              isInvalid={!isPossiblePhoneNumber(phone, 'CA') && validated}
              isValid={isPossiblePhoneNumber(phone, 'CA') && validated}
              name={'phone'}
              onChange={(e): void => setPhone(e.target.value)}
              placeholder={t('signUpForm|phonePlaceholder')}
              type="text"
              value={phone}
            />
            <Form.Control.Feedback type={'invalid'}>
              {t('signUpForm|validPhoneNumberRequiredFeedback')}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <FormGroup className="form-group mb-3" controlId="auth.ControlPassword">
            <Form.Label>
              {t('signUpForm|passwordLabel')} <Required />
            </Form.Label>
            <Form.Control
              disabled={false}
              isInvalid={!passwordCheck && validated}
              isValid={passwordCheck && validated}
              name={'password'}
              onChange={(e): void => {
                setPassword(e.target.value);
                // regex to check if password contains 1 upper, 1 lower, and 1 number
                const check = passwordValidation(e.target.value);
                setPasswordCheck(check);
                // check if passwords match
                if (e.target.value === passwordConfirm) {
                  setPasswordMatch(true);
                } else {
                  setPasswordMatch(false);
                }
              }}
              placeholder={t('signUpForm|passwordPlaceholder')}
              required
              type={'password'}
              value={password}
            />
            <Form.Control.Feedback type={'invalid'}>
              {t('signUpForm|passwordRequirements')}
            </Form.Control.Feedback>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup className="form-group mb-3" controlId="signUp.ControlPassword">
            <Form.Label>
              {t('signUpForm|passwordConfirmLabel')} <Required />
            </Form.Label>
            <Form.Control
              disabled={false}
              isInvalid={(!passwordMatch || !passwordCheck) && validated}
              isValid={passwordMatch && passwordCheck && validated}
              name={'passwordConfirm'}
              onChange={(e): void => {
                setPasswordConfirm(e.target.value);
                // validation for re-enter input
                const check = passwordValidation(e.target.value);
                setPasswordConfirmCheck(check);
                // check if passwords match
                if (e.target.value === password) {
                  setPasswordMatch(true);
                } else {
                  setPasswordMatch(false);
                }
              }}
              placeholder={t('signUpForm|passwordConfirmPlaceholder')}
              required
              type={'password'}
              value={passwordConfirm}
            />
            <Form.Control.Feedback type={'invalid'}>
              {!passwordMatch
                ? t('signUpForm|passwordMatch')
                : !passwordConfirmCheck
                ? t('signUpForm|passwordRequirements')
                : ''}
            </Form.Control.Feedback>
          </FormGroup>
        </Col>
      </Row>
      <Form.Text>
        <p>{t('signUpForm|passwordRequirements')}</p>
      </Form.Text>

      <AlertErrorForModal message={errorMessage} />
      <div className={'d-grid gap-2'}>
        <Button
          busy={mutation.isLoading}
          disabled={mutation.isLoading}
          icon={IconCaretRight}
          label={t('signUpForm|continueButton')}
          size={'lg'}
          type={'submit'}
        />
      </div>
    </Form>
  );
};

export default SignUpForm;
