// Copyright © 2024 CATTLEytics Inc.

import { useContext, useMemo } from 'react';

import AuthContext from '../store/auth-context';
import { isSiteAdminOrAbove } from '../utilities';

export const useIsAuthUserSiteAdminOrAbove = (): boolean => {
  const auth = useContext(AuthContext);

  return useMemo(() => isSiteAdminOrAbove(auth), [auth]);
};
