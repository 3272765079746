// Copyright © 2024 CATTLEytics Inc.

import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import AlertError from '../../../common/components/AlertError';
import DataTable, { DataTableHeader, DataTableRow } from '../../../common/components/DataTable';
import { api } from '../../../common/utilities/api';
import { QueryKey } from '../../../shared';
import { ReportFertility } from '../../../shared';
import { ApiResourceV1 } from '../../../shared/enums/api';
import { HttpMethod } from '../../../shared/enums/http';

interface Props {
  /**
   * Animal id
   */
  animalId: number;
}

/**
 * A table component containing animal fertility data.
 */
const FertilityTable = (props: PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const query = useQuery<ReportFertility>([QueryKey.ReportFertility, props.animalId], () =>
    api<ReportFertility>(HttpMethod.Get, `${ApiResourceV1.ReportBredOutcome}/${props.animalId}`),
  );

  if (query.isError) {
    return <AlertError message={t('fertilityTable|fetchError')} />;
  }

  const headers: DataTableHeader[] = [
    {
      name: 'lactationNumber',
      label: t('fertilityTable|lactationNumberColumn'),
      classNameMobile: 'col-6',
    },
    {
      name: 'timesBred',
      label: t('fertilityTable|timesBredColumn'),
      classNameMobile: 'col-6',
      infoTooltip: t('fertilityTable|timesBredColumnTooltip'),
    },
    {
      name: 'timesPreg',
      label: t('fertilityTable|timesPregColumn'),
      classNameMobile: 'col-6',
      infoTooltip: t('fertilityTable|timesPregColumnTooltip'),
    },
    {
      name: 'breedingRatio',
      label: t('fertilityTable|breedingRatioColumn'),
      classNameMobile: 'col-6',
    },
    {
      name: 'aborts',
      label: t('fertilityTable|abortsColumn'),
      classNameMobile: 'col-6',
      infoTooltip: t('fertilityTable|abortsColumnTooltip'),
    },
  ];

  const data: DataTableRow[] = !query.data
    ? []
    : query.data.map((row: ReportFertility) => ({
        lactationNumber: row.lactationNumber.toString(),
        timesBred: row.timesBred?.toString(),
        timesPreg: row.timesPreg?.toString(),
        breedingRatio: row.breedingRatio?.toString(),
        aborts: row.aborts?.toString(),
      }));

  return (
    <div>
      <h2>{t('fertilityTable|header')}</h2>
      <DataTable
        data={data}
        headers={headers}
        hidePaginationControls={true}
        isLoading={query.isLoading}
        messageNoData={t('fertilityTable|messageNoData')}
      />
    </div>
  );
};

export default FertilityTable;
