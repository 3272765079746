// Copyright © 2023 CATTLEytics Inc.

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonCreate from '../../common/components/ButtonCreate';
import Page from '../../common/components/Page';
import DairyLogModal from './DairyLogModal';
import DairyLogsList from './DairyLogsList';

/**
 * Routable component to list logs (dairy logs).
 */
const DairyLogsPage = (): JSX.Element => {
  const { t } = useTranslation();

  const [DairyLogModalVisible, setDairyLogModalVisible] = useState<boolean>(false);

  return (
    <Page
      breadcrumbs={[{ label: 'Dairy Logs', to: '/dairy-logs' }]}
      buttons={
        <ButtonCreate label={t('Create')} onClick={(): void => setDairyLogModalVisible(true)} />
      }
      title={'Logs'}
    >
      <DairyLogsList />
      {DairyLogModalVisible && (
        <DairyLogModal onClose={(): void => setDairyLogModalVisible(false)} />
      )}
    </Page>
  );
};

export default DairyLogsPage;
