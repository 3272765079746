// Copyright © 2023 CATTLEytics Inc.

import { format } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { QueryKey } from '../../shared';
import { getEnv } from '../utilities';
import Button from './Button';
import { ReleaseModal } from './ReleaseModal';

/**
 * Common footer
 */
const Footer = (): JSX.Element => {
  const { t } = useTranslation();

  const [showReleaseNotesModal, setShowReleaseNotesModal] = useState<boolean>();

  const experimental = !!parseInt(getEnv('EXPERIMENTAL') ?? '0');

  const getApiVersion = async (): Promise<string> => {
    if (process.env.NODE_ENV === 'development') {
      return 'dev';
    }
    if (process.env.REACT_VERSION) {
      return 'rv-' + process.env.REACT_VERSION;
    }
    try {
      const response = await fetch(`${getEnv('API_ENDPOINT2')}/`, {
        headers: { 'X-Milkshake-Token': 'Milkshake' },
      });

      if (response.status !== 200) {
        return t('unknown');
      }
      return (await response.json()).version;
    } catch (err) {
      return t('unknown');
    }
  };

  const getWebVersion = async (): Promise<string> => {
    return getEnv('VERSION') ?? t('unknown');
  };

  const queryApiVersion = useQuery([QueryKey.ApiVersion], () => getApiVersion(), {
    cacheTime: 60_000,
    refetchOnWindowFocus: false,
  });

  const queryWebVersion = useQuery([QueryKey.WebVersion], () => getWebVersion(), {
    cacheTime: 60_000,
    refetchOnWindowFocus: false,
  });

  const apiVersion = queryApiVersion?.data;
  const webVersion = queryWebVersion?.data;

  return (
    <footer
      className={'mt-auto pt-5 text-center'}
      style={{ fontSize: '12px', color: '#666', lineHeight: '1.5rem' }}
    >
      <hr className={'bg-secondary'} style={{ height: '2px' }} />
      <p>
        {t('footer|copyrightNotice', {
          year: format(new Date(), 'yyyy'),
        })}
        <br />
        <Link to={'/privacy'}>{t('Privacy Policy')}</Link> | <Link to={'/terms'}>{t('Terms')}</Link>
        <br />
        Version: {webVersion && webVersion.length > 20
          ? webVersion.substring(0, 8)
          : webVersion} /{' '}
        {apiVersion && apiVersion.length > 20 ? apiVersion.substring(0, 8) : apiVersion}
        <Button
          className="align-baseline"
          onClick={(): void => setShowReleaseNotesModal(true)}
          size={'sm'}
          variant={'link'}
        >
          <small>{t('Release Notes')}</small>
        </Button>
        <br />
        {t('Experimental Features')}: <strong>{experimental ? t('Enabled') : t('Disabled')}</strong>
      </p>
      <ReleaseModal
        onClose={(): void => setShowReleaseNotesModal(false)}
        visible={showReleaseNotesModal}
      />
    </footer>
  );
};

export default Footer;
